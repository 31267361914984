import React, {Fragment} from 'react';
import Autocomplete from "@material-ui/lab/Autocomplete"
import { TextField } from "@material-ui/core"
const DropdownFilter = ({filterOption, filterMenu, keyParam, onFieldChange}) => {
    return(
        <Fragment>
            {filterMenu[keyParam].multiple ?
                <Autocomplete
                    options={filterMenu[keyParam].options}
                    getOptionLabel={(option) =>
                    option[filterMenu[keyParam].labelKey] }
                    id={keyParam}
                    value={ filterMenu[keyParam].options && filterMenu[keyParam].options.length && filterOption[keyParam] ? filterMenu[keyParam].options.filter(item => filterOption[keyParam].split(',').includes(item[filterMenu[keyParam].valueKey].toString())) : [] }
                    onChange={(e, value) => onFieldChange(keyParam, value.map(item => item[filterMenu[keyParam].valueKey]).join(',').toString())}
                    renderInput={(params) => ( <TextField {...params} label={filterMenu[keyParam].title} margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant="outlined" /> )}
                    multiple={true}
                />:
                <Autocomplete
                    options={filterMenu[keyParam].options}
                    getOptionLabel={(option) =>
                    option[filterMenu[keyParam].labelKey] }
                    id={keyParam}
                    value={ filterMenu[keyParam].options && filterMenu[keyParam].options.length && filterOption[keyParam] ? filterMenu[keyParam].options.find(item => item[filterMenu[keyParam].valueKey] === filterOption[keyParam]) : null }
                    onChange={(e, value) => onFieldChange(keyParam, value ? value[filterMenu[keyParam].valueKey]:null)}
                    renderInput={(params) => ( <TextField {...params} label={filterMenu[keyParam].title} margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant="outlined" /> )}
                    multiple={false}
                />
            }
        </Fragment>
    )
}
export default DropdownFilter;