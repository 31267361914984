import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { useSnackbar } from 'notistack';
import { Button, TableRow, TableCell, TextField, Paper, Table, TableHead, TableBody, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Checkbox, CircularProgress, Tooltip, IconButton } from '@material-ui/core';
import { EmptyCollection, DeletePopUp, PageLoader, Pagination, STableLoader } from '../../../shared_elements';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import { regexConstants } from '../../../constants/regEx';
import { globalDeleteService, globalGetService, globalPostService, globalPutService } from '../../../utils_v2/globalApiServices';
import { checkApiStatus } from '../../../utils_v2';
const missingHardwareObj = {
    description:'',
    ata:'',
    part_number:'',
    serial_number:'',
    remarks:''
}
const AddEditMissingHardware = ({params, toggleModalFn, addEditMissingHardware, getResponseBack}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false)
    const [error, setError] = useState({});
    const [missingHardware, setMissingHardware] = useState(addEditMissingHardware.data);
    const onFieldChange = (key, value) => {
      setMissingHardware({...missingHardware, [key]:value})
    }
    const onAddEditMissingHardware = () => {
        let validationInputs = {
            description:missingHardware?.description?.trim()?.length ? '':'Please enter Description',
        }
        if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
            setLoading(true);
            if(missingHardware.id){
                globalPutService(`technical/v2/workorder/${params.workOrderSlug}/powerplant-missing-hardware/${missingHardware.id}/`, missingHardware)
                .then(response => {
                    if(checkApiStatus(response)){
                        enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                        toggleModalFn(); getResponseBack();
                    }
                    setLoading(false);
                })
            }else{
                globalPostService(`technical/v2/workorder/${params.workOrderSlug}/powerplant-missing-hardware/`, {missing_hardware:[missingHardware]})
                .then(response => {
                    if(checkApiStatus(response)){
                        enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                        toggleModalFn(); getResponseBack();
                    }
                    setLoading(false);
                })
            }
        }else{
            setError(validationInputs)
        }
    }
    return(
        <Dialog
            open={addEditMissingHardware.modal}
            onClose={toggleModalFn}
            className='project-management-modal'
            maxWidth='md'
        >
            <DialogTitle id="scroll-dialog-title">
                {missingHardware.id ? 'Edit':'Add'} Missing Hardware
            </DialogTitle>
            <DialogContent dividers={true}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <TextField
                            required
                            name="description"
                            label="Description"
                            fullWidth
                            margin="normal"
                            inputProps={{maxLength: 250}}
                            value={missingHardware?.description||''}
                            onChange={(e) => onFieldChange('description', e.target.value)}
                            error={error?.description}
                            multiline
                            rows={3}
                            helperText={error?.description||''}
                            InputLabelProps={{shrink: true}}
                            onFocus={(e) => setError({...error,'description':''})}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <TextField
                            name="ata"
                            label="ATA"
                            fullWidth
                            margin="normal"
                            inputProps={{maxLength: 20}}
                            value={missingHardware?.ata||''}
                            onChange={(e) => onFieldChange('ata', e.target.value)}
                            error={error?.ata}
                            helperText={error?.ata||''}
                            InputLabelProps={{shrink: true}}
                            onFocus={(e) => setError({...error,'ata':''})}
                            variant='outlined'
                        />
                    </Grid>
                    
                    <Grid item md={4} xs={12}>
                        <TextField
                            name="part_number"
                            label="Part Number"
                            fullWidth
                            margin="normal"
                            inputProps={{maxLength: 20}}
                            value={missingHardware?.part_number||''}
                            onChange={(e) => onFieldChange('part_number', e.target.value)}
                            error={error?.part_number}
                            helperText={error?.part_number||''}
                            InputLabelProps={{shrink: true}}
                            onFocus={(e) => setError({...error,'part_number':''})}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <TextField
                            name="serial_number"
                            label="Serial Number"
                            fullWidth
                            margin="normal"
                            inputProps={{maxLength: 20}}
                            value={missingHardware?.serial_number||''}
                            onChange={(e) => onFieldChange('serial_number', e.target.value)}
                            error={error?.serial_number}
                            helperText={error?.serial_number||''}
                            InputLabelProps={{shrink: true}}
                            onFocus={(e) => setError({...error,'serial_number':''})}
                            variant='outlined'
                        />
                    </Grid>
                    
                    <Grid item xs={12}>
                        <TextField
                            name="remarks"
                            label="Remarks"
                            fullWidth
                            margin="normal"
                            inputProps={{maxLength: 250}}
                            value={missingHardware?.remarks||''}
                            onChange={(e) => onFieldChange('remarks', e.target.value)}
                            error={error?.remarks}
                            multiline
                            rows={4}
                            helperText={error?.remarks||''}
                            InputLabelProps={{shrink: true}}
                            onFocus={(e) => setError({...error,'remarks':''})}
                            variant='outlined'
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} onClick={toggleModalFn} color="primary" size="small">Cancel</Button>
                <Button disabled={isLoading} onClick={onAddEditMissingHardware} variant="contained" color="primary" size="small">
                    {isLoading ? <CircularProgress size={20} /> : 'SAVE' }
                </Button>
            </DialogActions>
        </Dialog>
    )
}
const MissingHardware = ({params, currentFormDetail, getCurrentFormDetail}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [filter, setFilter] = useState({});
    const [missingHardwareInfo, setMissingHardwareInfo] = useState({list:[1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1], pagination:{page:1, per_page:10, total:100}});
    const [addEditMissingHardware, setAddEditMissingHardware] = useState({data:null, modal:false});
    const [deleteMissingHardware, setDeleteMissingHardware] = useState({data:[], modal:false});
    useEffect(() => {
        getMissingHardwares({}, 'skeletonLoader');
    },[]);
    const getMissingHardwares = (query, loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true):setLoading(true);
        globalGetService(`technical/v2/workorder/${params.workOrderSlug}/powerplant-missing-hardware/`, query)
        .then(response => {
            if(checkApiStatus(response)){
                setMissingHardwareInfo(response.data.data)
            }
            loaderType === 'skeletonLoader' ? setSkeletonLoader(false):setLoading(false);
        })
    }
    const handleChange = (type, flag, id) => {
        if(type === 'single'){
            setDeleteMissingHardware({
                ...deleteMissingHardware,
                data: flag ? [...deleteMissingHardware.data, id]:deleteMissingHardware.data.filter(item =>  item !== id)
            })
        }else{
            let ids = missingHardwareInfo.list.map(item => item.id)
            setDeleteMissingHardware({
                ...deleteMissingHardware,
                data: flag ? ids:[]
            })
        }
    }
    const onDeleteMissingHardware = () => {
        setLoading(true);
        globalDeleteService(`technical/v2/workorder/${params.workOrderSlug}/powerplant-missing-hardware/`, {ids:deleteMissingHardware.data})
        .then(response => {
            if(checkApiStatus(response)){
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                setDeleteMissingHardware({modal:false,data:[]})
                getMissingHardwares({}, 'pageLoader');
                getCurrentFormDetail('pageLoader');
            }
            setLoading(false);
        })
    }
    const canEdit = currentFormDetail?.current_form?.permissions?.can_save;
    return(
        <div className='forms-table-layout'>
            { skeletonLoader ? <STableLoader count={10} />: 
                <>
                    <Paper className='forms-table-cta' square>
                        <Grid container spacing={1}>
                            <Grid item md={6}></Grid>
                            <Grid item md={6}>
                                { canEdit ?
                                    <ul className='list-inline'>
                                        { deleteMissingHardware?.data?.length ?
                                            <li className='list-inline-item'>
                                                <Button onClick={() => setDeleteMissingHardware({...deleteMissingHardware, modal:true})} color='secondary' size='small' variant='contained'>Delete</Button>
                                            </li>:null
                                        }
                                        <li className='list-inline-item'>
                                            <Button onClick={() => setAddEditMissingHardware({data:missingHardwareObj, modal:true})} color='primary' size='small' variant='contained'>Add Missing Hardware</Button>
                                        </li>
                                        <li className='list-inline-item' style={{display:'none'}}>
                                            <Button onClick={() => setAddEditMissingHardware({data:{}, modal:true})} color='primary' size='small' variant='contained'>Import</Button>
                                        </li>
                                        <li className='list-inline-item' style={{display:'none'}}>
                                            <Button onClick={() => setAddEditMissingHardware({data:{}, modal:true})} color='primary' size='small' variant='contained'>Export</Button>
                                        </li>
                                    </ul>:null
                                }
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper className='forms-table-content'>
                        <div style={{height: !missingHardwareInfo.list.length ? 'auto':`${window.innerHeight-290}px`,overflow:'auto'}}>
                            <Table className='mui-table-format' stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        { canEdit ?
                                            <TableCell style={{width:'40px'}}>
                                                <div style={{width:'40px'}}>
                                                <Checkbox
                                                    size='small'
                                                    checked={deleteMissingHardware?.data?.length ? deleteMissingHardware?.data?.length === missingHardwareInfo?.list.length :false}
                                                    onChange={(e) => handleChange('bulk', e.target.checked)}
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    disabled={!missingHardwareInfo?.list?.length}
                                                />
                                                </div>
                                            </TableCell>:null
                                        }
                                        <TableCell>Description</TableCell>
                                        <TableCell>ATA</TableCell>
                                        <TableCell>P/N</TableCell>
                                        <TableCell>S/N</TableCell>
                                        <TableCell>Remarks</TableCell>
                                        <TableCell align='right'>Action(s)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    { missingHardwareInfo.list.map((item,index) => 
                                        <TableRow>
                                            { canEdit ?
                                                <TableCell>
                                                    <div style={{width:'40px'}}>
                                                        <Checkbox
                                                            size='small'
                                                            checked={deleteMissingHardware.data.includes(item.id)}
                                                            onChange={(e) => handleChange('single', e.target.checked, item.id)}
                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                        />
                                                    </div>
                                                </TableCell>:null
                                            }
                                            <TableCell>
                                                <div style={{width:'380px'}}>{item?.description||'--'}</div>
                                            </TableCell>
                                            <TableCell>
                                                <div style={{width:'100px'}}>{item?.ata||'--'}</div>
                                            </TableCell>
                                            <TableCell>
                                                <div style={{width:'100px'}}>{item?.part_number||'--'}</div>
                                            </TableCell>
                                            <TableCell>
                                                <div style={{width:'100px'}}>{item?.serial_number||'--'}</div>
                                            </TableCell>
                                            <TableCell>
                                                <div style={{width:'380px'}}>{item?.remarks||'--'}</div>
                                            </TableCell>
                                            <TableCell align='right'>
                                                { canEdit ?
                                                    <ul className='list-inline' style={{width:'80px', float:'right'}}>
                                                        <li className='list-inline-item'>
                                                            <Tooltip title='Edit' arrow>
                                                                <IconButton size="small"><EditIcon onClick={() => setAddEditMissingHardware({modal:true, data:item})} fontSize='small' color='primary' /> </IconButton>
                                                            </Tooltip>
                                                        </li>
                                                        <li className='list-inline-item'>
                                                            <Tooltip title='Delete' arrow>
                                                                <IconButton size="small"> <DeleteOutlineIcon onClick={() => setDeleteMissingHardware({modal:true, data:[item.id]})} fontSize='small' color='secondary' /> </IconButton>
                                                            </Tooltip>
                                                        </li>
                                                    </ul>:null
                                                }
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </div>
                        <Pagination 
                            pagination={missingHardwareInfo.pagination}
                            onChangePage={(event, newPage) => getMissingHardwares({...filter, page:newPage+1, per_page: missingHardwareInfo.pagination.per_page}, 'pageLoader')}
                            onChangeRowsPerPage={(event) => getMissingHardwares({...filter, page:1, per_page: event.target.value}, 'pageLoader')}
                        />
                        { !missingHardwareInfo.list.length ? <EmptyCollection title="No records found" />:null }
                    </Paper>    
                </>
            }
            { addEditMissingHardware.modal ?
                <AddEditMissingHardware 
                    params={params}
                    addEditMissingHardware={addEditMissingHardware}
                    toggleModalFn={() => setAddEditMissingHardware({data:null, modal:false})}
                    getResponseBack={() => {getMissingHardwares({}, 'pageLoader');getCurrentFormDetail('pageLoader');}}
                />:null
            }
            { deleteMissingHardware.modal ?
                <DeletePopUp
                    modal={deleteMissingHardware.modal}
                    toggleModalFn={() => setDeleteMissingHardware({data:[], modal:false})}
                    title="Delete Missing Hardware(s)"
                    content={<h4>Are you sure you want to delete?</h4>}
                    deleteRecordFn={onDeleteMissingHardware}
                />:null
            }
            { isLoading ? <PageLoader /> :null }
        </div>
    )
}
export default withRouter(MissingHardware);
