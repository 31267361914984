import IssueDetail from './containers/IssueDetail';
import Issues from './containers/Issues';
import MilestoneDetail from './containers/MilestoneDetail';
import Milestones from './containers/Milestones';
import Stages from './containers/Stages';
import TaskDetail from './containers/TaskDetail';
import Tasks from './containers/Tasks';
import TimeSheet from './containers/TimeSheet';
import requireAuth from '../../utils/requireAuth'
import RoleBasedAccess from '../../utils/RoleBasedAccess'

export const taskManagementRoutes = [
    {
        path:'/technical/project/:project_slug/stages',
        component:requireAuth(RoleBasedAccess(Stages,['task_management', 'stages', 'R'])),
        key:'Stages'
    },
    {
        path:'/technical/project/:project_slug/milestones',
        component:requireAuth(RoleBasedAccess(Milestones,['task_management', 'milestone', 'R'])),
        key:'Milestones'
    },
    {
        path:'/technical/project/:project_slug/milestone/:slug',
        component:requireAuth(RoleBasedAccess(MilestoneDetail,['task_management', 'milestone', 'R'])),
        key:'MilestoneDetail'
    },
    {
        path:'/technical/project/:project_slug/tasks',
        component:requireAuth(RoleBasedAccess(Tasks,['task_management', 'tasks', 'R'])),
        key:'Tasks'
    },
    {
        path:'/technical/project/:project_slug/task/:slug',
        component:requireAuth(RoleBasedAccess(TaskDetail,['task_management', 'tasks', 'R'])),
        key:'TaskDetail'
    },
    {
        path:'/technical/project/:project_slug/issues',
        component:requireAuth(RoleBasedAccess(Issues,['task_management', 'issues', 'R'])),
        key:'Issues'
    },
    {
        path:'/technical/project/:project_slug/issue/:slug',
        component:requireAuth(RoleBasedAccess(IssueDetail,['task_management', 'issues', 'R'])),
        key:'IssueDetail'
    },
    {
        path:'/technical/project/:project_slug/time-sheet',
        component:requireAuth(RoleBasedAccess(TimeSheet,['task_management', 'issues', 'R'])),
        key:'TimeSheet'
    },
];