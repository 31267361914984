import React, { Fragment, useState  } from 'react';
import { useSnackbar } from 'notistack';
import { withRouter } from 'react-router';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CircularProgress, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, InputAdornment } from '@material-ui/core';
import { globalPostService } from '../../../utils_v2/globalApiServices'
import { checkApiStatus } from '../../../utils_v2';
import { trackActivity } from '../../../utils/mixpanel';
const ChangeExpenseStatus = ({params, toggleModalFn, expenseStatus, getResponseBack}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [expenseObj, setExpenseObj] = useState({approved_amount:expenseStatus.expense.amount ? expenseStatus.expense.amount:'', comment:'', status:null});
    const [error, setError] = useState({});
    const onSubmitChangeStatus = () => {
        let validationInputs = {
            type: expenseObj.status ? '':'Please select Status',
            approved_amount: expenseObj.status && expenseObj.status.label === 'Approve' ? expenseObj.approved_amount && (parseFloat(expenseObj.approved_amount) <= parseFloat(expenseStatus.expense.amount)) ?'':'Approved amount should be less than or equal to Expense amount': '',
            comment: expenseObj.status && expenseObj.status.label === 'Reject' ? expenseObj.comment ? '':'Please enter Rejection Reason' :''
        }
        if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
            let payload = {};
            if(expenseObj.status.label === 'Approve'){
                payload = {
                    ...payload,
                    approved_amount:expenseObj.approved_amount,
                    approved_comment:expenseObj.comment,
                    type:'Approve'
                }
            }else if(expenseObj.status.label === 'Reject'){
                payload = {
                    ...payload,
                    rejected_comment:expenseObj.comment,
                    type:'Reject'
                }
            }else if(expenseObj.status.label === 'Reopen'){
                payload = {
                    ...payload,
                    type:'Reopen'
                }
            }else{
                payload = {
                    ...payload,
                    type:'Paid'
                }
            }
            setLoading(true);
            globalPostService(`technical/project/${params.project_slug}/budgets/expenses/approveReject/${expenseStatus.expense.id}/`, payload)
            .then(response => {
                setLoading(false);
                if(checkApiStatus(response)){                
                    enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
                    toggleModalFn();
                    getResponseBack();
                    trackActivity('Expense Management', {
                        event_type: 'Expense Status Changed',
                        page_title: 'Change Expense Status',
                        project_id: params.project_slug, 
                        expense_id: expenseStatus.expense.id, 
                        status: expenseObj.status.label,
                        approved_amount: expenseObj.approved_amount,
                        comment: expenseObj.comment,
                        type: payload.type
                    });
                }else{
                    trackActivity('Expense Management', {
                        event_type: 'Expense Status Change Failed',
                        page_title: 'Change Expense Status',
                        project_id: params.project_slug, 
                        expense_id: expenseStatus.expense.id, 
                        status: expenseObj.status.label,
                        approved_amount: expenseObj.approved_amount,
                        comment: expenseObj.comment,
                        type: payload.type,
                        error: validationInputs,
                        error_message: response.data.message
                    });
                    enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
                }
            });
        }else{
            setError(validationInputs);
        }
        
    }
    console.log('expenseStatus',expenseStatus);
    return(
        <Fragment>
            <Dialog
                maxWidth="md"
                open={expenseStatus.modal}
                onClose={toggleModalFn}
                aria-labelledby="scroll-dialog-title"
                className='project-management-modal'
            >
                <DialogTitle id="scroll-dialog-title">
                    Change Status
                </DialogTitle>
                <DialogContent dividers={true}>
                    <div style={{width:'380px', padding:'20px auto'}}>
                        <Grid container spacing={1}>
                            <Grid item md={12}>
                                <Autocomplete
                                    options = {expenseStatus.expense && (expenseStatus.expense.status === 'Pending' || expenseStatus.expense.status === 'Reject') ? [{id:'1', label:'Approve'}, {id:'2', label:'Reject'}]: expenseStatus.expense.status ==="Paid" ? [{id:'0', label:'Reopen'},] : [{id:'3', label:'Paid'},{id:'0', label:'Reopen'},]}
                                    getOptionLabel={option => option.label}
                                    id="status"
                                    value={expenseObj.status ? expenseObj.status : null}
                                    onChange={(e, value) => setExpenseObj({...expenseObj, status:value})}
                                    renderInput={params => <TextField required error={error.type} helperText={error.type} onFocus={() => setError({...error, 'type':''})} {...params} label="Status" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
                                />
                            </Grid>
                            { expenseObj.status && (expenseObj.status.label == 'Approve' || expenseObj.status.label == 'Reject')  ?
                                <Fragment>
                                    { expenseObj.status && expenseObj.status.label === 'Approve' ?
                                        <Grid item md={12}>
                                            <TextField
                                                required
                                                id="approved_amount"
                                                label="Approved Amount"
                                                fullWidth
                                                margin="normal"
                                                value={expenseObj.approved_amount}
                                                InputLabelProps={{shrink: true}}
                                                onChange={(e) => setExpenseObj({...expenseObj, approved_amount:e.target.value})}
                                                variant='outlined'
                                                error={error.approved_amount} 
                                                onFocus={() => setError({...error, 'approved_amount':''})}
                                                helperText={error.approved_amount}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">{expenseStatus.expense.amount_currency.code}</InputAdornment>
                                                }}
                                            />
                                        </Grid>:null
                                    }
                                    
                                    <Grid item md={12}>
                                        <TextField
                                            required={expenseObj.status && (expenseObj.status.label === 'Approve')?false:true}
                                            id="reason"
                                            label={expenseObj.status && (expenseObj.status.label === 'Approve' ) ? 'Comment': 'Rejection Reason'}
                                            fullWidth
                                            margin="normal"
                                            value={expenseObj.comment}
                                            InputLabelProps={{shrink: true}}
                                            multiline
                                            maxRows={4}
                                            onChange={(e) => setExpenseObj({...expenseObj, comment:e.target.value})}
                                            error={error.comment} 
                                            helperText={error.comment}
                                            onFocus={() => setError({...error, 'comment':''})}
                                            variant='outlined'
                                        />
                                    </Grid>
                                </Fragment>:null
                            }
                            
                        </Grid>
                    </div>
                </DialogContent> 
                <DialogActions>
                    <Button disabled={isLoading} onClick={toggleModalFn} color="primary" size='small' variant='outlined'>
                        Cancel
                    </Button>
                    <Button disabled={isLoading} onClick={onSubmitChangeStatus} color="primary" size='small' variant='contained'>
                        {isLoading ? <CircularProgress size={24} /> : 'Submit'}
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}
export default withRouter(ChangeExpenseStatus);