import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { useSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, CircularProgress } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { fieldDateFormat, backendDateFormat, displayDateFormat } from '../../../constants';
import { checkApiStatus } from '../../../utils_v2';
import { globalPutService, globalPostService } from '../../../utils_v2/globalApiServices';
import { regexConstants } from '../../../constants/regEx';
import { capitalizeFirstLetter } from '../../../utils_v2';
import { fieldValidation } from '../../../utils_v2/formValidation';
import { errorCode, robberyObj } from '..';
import { trackActivity } from '../../../utils/mixpanel';
const RobberyCRU = ({params, toggleModalFn, addEditRobbery, getResponseBack}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isLoading, setLoading] = useState(false);
  const [robbery, setRobbery] = useState(addEditRobbery.robbery ? addEditRobbery.robbery:robberyObj)
  const [error, setError] = useState({});
  const onFieldChange = (e, keyParam, value) => {
    setRobbery({...robbery, [keyParam]:value});
  }
  const resetErrorKey = (key) => {
    setError({...error, [key]: ''});
  }
  const onAddEditRobbery = () => {
    let validationInputs={
      part_number:errorCode['part_number'][fieldValidation({...errorCode['part_numberObj'], fieldval: robbery.part_number})]
    }
    if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
      setLoading(true);
      if(addEditRobbery.robbery){
        globalPutService(`technical/workorder/${params.workOrderSlug}/robbery-list/${robbery.id}/`, robbery)
        .then(response => {
          setLoading(false);
          if(checkApiStatus(response)){
            enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
            toggleModalFn(); getResponseBack();
            trackActivity('Project Management', {
              event_type: 'Robbery Update Success',
              page_title: 'T009 Form',
              workOrder_slug: params.workOrderSlug ? params.workOrderSlug : '',
              robbery_id: robbery.id ? robbery.id : '',
              request_body: robbery ? robbery : {},
              success_msg: response.data.message
          });
          }else{
            enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
            trackActivity('Project Management', {
              event_type: 'Robbery Update Failed',
              page_title: 'T009 Form',
              workOrder_slug: params.workOrderSlug ? params.workOrderSlug : '',
              contact_id: robbery.id ? robbery.id : '',
              request_body: robbery ? robbery : {},
              error_source: 'Backend',
              error_msg: response.data.message
          });
          }
        })
      }else{
        globalPostService(`technical/workorder/${params.workOrderSlug}/robbery-list/`, robbery)
        .then(response => {
          setLoading(false);
          if(checkApiStatus(response)){
            enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
            toggleModalFn(); getResponseBack();
            trackActivity('Project Management', {
              event_type: 'Robbery Add Success',
              page_title: 'T009 Form',
              workOrder_slug: params.workOrderSlug ? params.workOrderSlug : '',
              request_body: robbery ? robbery : {},
              success_msg: response.data.message
          });
          }else{
            enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
            trackActivity('Project Management', {
              event_type: 'Robbery Add Failed',
              page_title: 'T009 Form',
              workOrder_slug: params.workOrderSlug ? params.workOrderSlug : '',
              request_body: robbery ? robbery : {},
              error_source: 'Backend',
              error_msg: response.data.message
          });
          }
        })
      }
    }else{
      setError(validationInputs)
      trackActivity('Project Management', {
        event_type: 'Robbery Add/Edit Failed',
        page_title: 'T006 Form',
        workOrder_slug: params.workOrderSlug ? params.workOrderSlug : '',
        request_body: robbery ? robbery : {},
        error_source: 'Frontend',
        error_details: validationInputs ? validationInputs : ''
    });
    }
  }
  return(
    <Dialog
      open={addEditRobbery.modal}
      onClose={toggleModalFn}
      className='project-management-modal'
    >
      <DialogTitle id="scroll-dialog-title">
        {capitalizeFirstLetter(addEditRobbery.mode)} Robbery
      </DialogTitle>
      <DialogContent dividers={true}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextField
              required
              id="part_number"
              label="Part Number"
              fullWidth
              margin="normal"
              inputProps={{maxLength: 20}}
              value={robbery.part_number ? robbery.part_number:''}
              onChange={(e, value) => onFieldChange(e, 'part_number', e.target.value)}
              error={error.part_number ? true:false }
              helperText={error.part_number ? error.part_number:''}
              InputLabelProps={{shrink: true}}
              onFocus={(e) => resetErrorKey('part_number')}
              variant='outlined'
            />
          </Grid>
          <Grid item xs={6}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                  margin="normal"
                  id="removal_date"
                  label="Removal Date"
                  format={fieldDateFormat}
                  fullWidth
                  InputLabelProps={{shrink: true}}
                  inputProps={{readOnly: true}}
                  clearable={true}
                  value={robbery.removal_date ? robbery.removal_date:null}
                  onChange={(data, value) => {onFieldChange(value, 'removal_date', data ? moment(data).format(backendDateFormat):data);}}
                  inputVariant='outlined'
                />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="part_description"
              label="Part Description"
              fullWidth
              margin="normal"
              inputProps={{maxLength: 30}}
              value={robbery.part_description ? robbery.part_description:''}
              onChange={(e, value) => onFieldChange(e, 'part_description', e.target.value)}
              error={error.part_description ? true:false }
              helperText={error.part_description ? error.part_description:''}
              InputLabelProps={{shrink: true}}
              variant='outlined'
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="part_location"
              label="Part Location"
              fullWidth
              margin="normal"
              inputProps={{maxLength: 20}}
              value={robbery.part_location ? robbery.part_location:''}
              onChange={(e, value) => onFieldChange(e, 'part_location', e.target.value)}
              error={error.part_location ? true:false }
              helperText={error.part_location ? error.part_location:''}
              InputLabelProps={{shrink: true}}
              variant='outlined'
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="reason_for_removal"
              label="Reason for Removal"
              fullWidth
              margin="normal"
              inputProps={{maxLength: 50}}
              value={robbery.reason_for_removal ? robbery.reason_for_removal:''}
              onChange={(e, value) => onFieldChange(e, 'reason_for_removal', e.target.value)}
              error={error.reason_for_removal ? true:false }
              helperText={error.reason_for_removal ? error.reason_for_removal:''}
              InputLabelProps={{shrink: true}}
              variant='outlined'
            />
          </Grid>
          <Grid item xs={6}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                  margin="normal"
                  id="date_verified_removed_from_aircraft"
                  label="Date Verified Removed From Aircraft"
                  format={fieldDateFormat}
                  fullWidth
                  InputLabelProps={{shrink: true}}
                  inputProps={{readOnly: true}}
                  clearable={true}
                  value={robbery.date_verified_removed_from_aircraft ? robbery.date_verified_removed_from_aircraft:null}
                  onChange={(data, value) => {onFieldChange(value, 'date_verified_removed_from_aircraft', data ? moment(data).format(backendDateFormat):data);}}
                  inputVariant='outlined'
                />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={6}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                  margin="normal"
                  id="date_verified_installed_on_aircraft"
                  label="Date Verified Installed On Aircraft"
                  format={fieldDateFormat}
                  fullWidth
                  InputLabelProps={{shrink: true}}
                  inputProps={{readOnly: true}}
                  clearable={true}
                  value={robbery.date_verified_installed_on_aircraft ? robbery.date_verified_installed_on_aircraft:null}
                  onChange={(data, value) => {onFieldChange(value, 'date_verified_installed_on_aircraft', data ? moment(data).format(backendDateFormat):data);}}
                  inputVariant='outlined'
                />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={6}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                  margin="normal"
                  id="date_verified_in_store_house"
                  label="Date Verified in Store House"
                  format={fieldDateFormat}
                  fullWidth
                  InputLabelProps={{shrink: true}}
                  inputProps={{readOnly: true}}
                  clearable={true}
                  value={robbery.date_verified_in_store_house ? robbery.date_verified_in_store_house:null}
                  onChange={(data, value) => {onFieldChange(value, 'date_verified_in_store_house', data ? moment(data).format(backendDateFormat):data);}}
                  inputVariant='outlined'
                />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="price"
              label="Price (USD)"
              fullWidth
              margin="normal"
              inputProps={{maxLength: 10}}
              value={robbery.price ? robbery.price:''}
              onChange={(e, value) =>{regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'price', e.target.value): e.preventDefault() } }
              error={error.price ? true:false }
              helperText={error.price ? error.price:''}
              InputLabelProps={{shrink: true}}
              onFocus={(e) => resetErrorKey('price')}
              variant='outlined'
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="wcard_number"
              label="Work Card No"
              fullWidth
              margin="normal"
              inputProps={{maxLength: 10}}
              value={robbery.wcard_number ? robbery.wcard_number:''}
              onChange={(e, value) => onFieldChange(e, 'wcard_number', e.target.value)}
              error={error.wcard_number ? true:false }
              helperText={error.wcard_number ? error.wcard_number:''}
              InputLabelProps={{shrink: true}}
              onFocus={(e) => resetErrorKey('wcard_number')}
              variant='outlined'
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="comments"
              label="Remarks"
              fullWidth
              multiline
              rows="4"
              margin="normal"
              inputProps={{maxLength: 50}}
              value={robbery.comments ? robbery.comments:''}
              onChange={(e, value) => onFieldChange(e, 'comments', e.target.value)}
              error={error.comments ? true:false }
              InputLabelProps={{shrink: true}}
              variant='outlined'
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleModalFn} color="primary" size='small'>Cancel</Button>
        <Button onClick={onAddEditRobbery} variant="contained" color="primary" size='small'>Save</Button>
      </DialogActions>
    </Dialog>
  )
}
export default withRouter(RobberyCRU);
