import React, { Component, Fragment } from 'react';
import { Row, Col } from 'reactstrap';
import { Link, withRouter } from 'react-router';
import { toastFlashMessage, getLocalStorageInfo } from '../../../../../utils';
import { ContentDrop } from '../../../Elements';
import { connect } from 'react-redux';
import { ListLoader } from '../../../../../shared';
import { imgStoragePath } from '../../../../../constants';
import exportIcon from '../../../../../shared/assets/img/export_icon.svg';
import { exportReport, importExcelApi, getTableSectionsApi } from '../apiServices';
class UploadExcel extends Component {
  constructor(props){
    super(props);
    this.state = {
      dragOver: false,
      files: {},
      pageLoader:false
    }
    this.exportReport = exportReport.bind(this);
    this.importExcelApi = importExcelApi.bind(this);
    this.getTableSectionsApi = getTableSectionsApi.bind(this);
  }
  onDragOver = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    this.setState({
      dragOver: true,
    });
  }

  onFocusOut = () => {
    this.setState({
      dragOver: false,
    })
  }
  onDrop = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    this.setState({
      dragOver: false,
    })
  }

  uploadExcel = (key, value) => {
    if (key == 'files') {
      if (getLocalStorageInfo().user.permission.records && getLocalStorageInfo().user.permission.records.is_saas) {
        let filesSize = 0
        for (let file of value) {
          filesSize += file.size
        }
        if (this.props.storageUsage.used + filesSize >= this.props.storageUsage.available) {
          this.props.buyLicence({
            flag: true,
            action: '/licenses/storage',
            content: 'The uploaded file(s) size is not available in storage.'
          })
          return;
        }
      }
      let files = ''
      Array.from(value).map(file => {
        if (['xls', 'xlsx', '.csv'].indexOf(file.name.substr(file.name.lastIndexOf('.') + 1).toLowerCase()) == -1) {
          files = files.length ? files + ', ' + file.name : file.name
        }
      })
      if (files != '') {
        this.setState(prevState => ({
          ...prevState,
          error: {
            ...prevState.error,
            [key]: files + ' are not allowed to upload.',
          },
        }));
        return
      }else{
        this.setState({ files: value})
      }
    }
  }

  removeAttachFiles = (name) => {
    let files = this.state.item.files;
    files = Array.from(files).filter( function(file){
        return file.name != name
    });
    this.setState(prevState => ({
      ...prevState,
      item: {
        ...prevState.item,
        files: files
      }
    }))
  }

  render(){
    const { closeTechForm, sectionId } = this.props;
    const { pageLoader, files} = this.state;
    return(
      <Fragment>
        { pageLoader ? <ListLoader /> : null}
        <div className="drop-excel" style={{ height: (window.innerHeight - 157) + 'px', overflow: 'auto', marginBottom: '20px' }}>
        <h4 className="sample">
          <img class="export-icn" src={exportIcon} style={{width:'18px', marginRight:'5px'}}/>
          <a onClick={() => this.props.exportReport()}>EXPORT REPORT</a>
        </h4>
        <ul className="list-inline flex-centered">
          <li className="list-inline-item dropable-zone"
            onDrop={(evt) => { evt.preventDefault(); evt.stopPropagation(); this.uploadExcel('files', evt.dataTransfer.files) }}
            onDragOver={(evt) => this.onDragOver(evt)}
            onDragLeave={() => this.onFocusOut()}
            style={this.state.dragOver ? { background: 'rgba(0,0,0,0.7)', width: '600px', height: '300px' } : { background: 'transparent', width: '600px', height: '300px' }} >
            <ContentDrop
              mutlipleEnabled={false}
              acceptExtensions={'.xls, .xlsx, .csv'}
              filesUpload={(evt) => this.uploadExcel('files', evt.target.files)}
              content="Drag & Drop Or Click here to choose Excel"
            />
          </li>
        </ul>
        {
          files[0] && files[0].name.length ?
          <div style={{position:'absolute', top:'175px', color:'#368FFB'}}>
            <div style={{width: '100%'}}>
            <p style={{fontSize: '14px'}}>{files[0] && files[0].name}</p>
          </div>
        </div>: null
        }
        <div className="notice-info">
              <div>
                <h6 style={{margin: '10px 10px 10px 15px',fontWeight: '600', fontSize: '14px'}}>Please follow the below instruction to use this function:</h6>
                <ul style={{paddingLeft: '20px'}}>
                  <li>
                    Export the MS EXCEL file using “Export Report” button in T011.
                  </li>
                  <li>Without editing the fields or the COLUMN HEADERS, start updating the PRS.</li>
                  <li>Once updated with the information, click “IMPORT FILE” and select the same file from the local drive to upload.</li>
                  <li>Maintain the same file on the local drive and update through the project.</li>
                  <li>During every IMPORT, the additional information on the Excel will get updated in T011 report on SPARTA.</li>
                </ul>
              </div>
              <div>
                <h6 style={{margin: '10px 10px 10px 15px',fontWeight: '600', fontSize: '14px'}}>Note:</h6>
                <ul style={{paddingLeft: '20px'}}>
                  <li>
                    DO use the exported PRS template ONLY. Any modification to the fields and / or the column header will cause a conflict with the system and the PRS will not get imported successfully.
                  </li>
                  <li>DO specify the finding under the “Description” field. This field is mandatory in each row item.</li>
                  <li>DO use "YYYY-MM-DD" as the date format.</li>
                  <li>DO use only option “1” or “2” to update the PRIORITY.</li>
                </ul>
              </div>
           </div>
      </div>
      <div className="submit-block">
          <input disabled={this.state.files[0] === undefined} type="submit" className="primary-btn" value="Import" onClick={() => this.importExcelApi(this.state.files)}/>
        <Link style={{marginLeft:'20px', color:'#3f51b5'}} onClick={closeTechForm}>CANCEL</Link>
      </div>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  storageUsage: state.sharedReducers.storageUsage,
});


export default connect(mapStateToProps, null)(withRouter(UploadExcel));
