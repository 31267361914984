import { globalGetService, globalExportService } from '../../globalServices';
import { getLocalStorageInfo, checkApiStatus, toastFlashMessage } from '../../utils';
import { trackActivity } from '../../utils/mixpanel';

export function getAnalyticResourceApi(props={}, query={}, loaderType){
  this.setState({[loaderType]: true});
  globalGetService(`technical/analytics/resource-tracker/`, query)
  .then(response => {
    this.setState({[loaderType]: false});
    if(checkApiStatus(response)){
      delete query.country_code
      this.setState({resourceDetail: response.data.data, filter: query })
    }
  })
}
export function getAnalyticProjectsApi(props={}, query={}, loaderType ){
  this.setState({[loaderType]: true});
  globalGetService(`technical/analytics/project-tracker/?list=true`, query)
  .then(response => {
    this.setState({[loaderType]: false});
    if(checkApiStatus(response)){
      delete query.page;
      delete query.per_page;
      delete query.list;
      delete query.country_code;
      this.setState({projectsDetail: response.data.data, filter:query,})
    }
  })
}
export function getAnalyticProjectsGrpApi(props={}, query={}, loaderType = ''){
  this.setState({[loaderType]: true});
  globalGetService(`technical/analytics/project-tracker/?list=false`, query)
  .then(response => {
    this.setState({[loaderType]: false});
    if(checkApiStatus(response)){
      this.setState({projectsGrph:response.data.data})
    }
  })
}
export function getProjectTrackerApi(props={}, query={}, contentType=''){
  let filterQuery = Object.assign({}, query);
  delete filterQuery.project_date
  if(query.project_date && query.project_date.startDate){
    filterQuery = {
      ...filterQuery,
      startDate:query.project_date.startDate
    }
  }
  if(query.project_date && query.project_date.endDate){
    filterQuery = {
      ...filterQuery,
      endDate:query.project_date.endDate
    }
  }
  if(!(getLocalStorageInfo().user.is_root_user || ( getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.project_tracker && getLocalStorageInfo().user.permission.technical.project_tracker.includes('GR')))){
    filterQuery  = {
      ...filterQuery,
      lessor_id:getLocalStorageInfo().defaultLessor.id
    }
  }
  if(contentType !== 'loadmore'){
    this.setState({[contentType]:true});
  }
  globalGetService(`technical/project-tracker/`, filterQuery)
  .then(response => {
    if(contentType !== 'loadmore'){
      this.setState({[contentType]:false});
    }
    if(checkApiStatus(response)){
      delete query.page;
      if(contentType === 'loadmore'){
        this.setState(prevState => ({
          ...prevState,
          projectTrackerDtls: {
            ...prevState.projectTrackerDtls,
            projects: [...prevState.projectTrackerDtls.projects, ...response.data.data.projects]
          },
          filter:query
        }))
      }else{
        this.setState({
          projectTrackerDtls: response.data.data,
          filter:query
        });
      }
    }
  })
}
export function exportProjectTrackerApi(props={}, file={}, filter){
  this.setState({pageLoader:true})
  globalGetService(`technical/project-tracker/`, {...filter, download:file.extension})
  .then(response => {
    this.setState({pageLoader:false})
    if(checkApiStatus(response)){
      toastFlashMessage('Exported file would be sent to registered e-mail', 'success')
      this.setState({
        pageLoader: false
      });
      trackActivity('Item Exported', {
        page_title: 'Analytics',
        tab_name: 'projects',   
        event_type: 'Export',
        event_desc: 'Exported Projects data from Project Tracker',
        extension: file.extension
      })
    }
  })
}
export function getProjectTrackerDetailApi(params={}, query={}){
  globalGetService(`technical/project-tracker/`, query)
  .then(response => {
    if(checkApiStatus(response.status)){

    }
  })
}


export function getProjectLeadApi(){
  globalGetService('technical/users/?role=lead')
  .then(response => {
    if(response.data.statusCode == 200){
      this.setState({
        projectLeadsList: response.data.data
      })
    }
  })
}
export function getProjectEngineersApi(){
  globalGetService('technical/users/?role=engineer')
  .then(response => {
    if(response.data.statusCode == 200){
      this.setState({
        projectEngineersList: response.data.data
      })
    }
  })
}
export function getRegionListApi(){
  globalGetService('console/regions/')
  .then(response => {
    if(response.data.statusCode == 200){
      this.setState({
        regionList:response.data.data.region_list
      })
    }
  })
}
export function exportResourceTrackerApi(query){
  this.setState({pageLoader:true})
  globalExportService('technical/analytics/resource-tracker/',{ download: query.extension})
  .then(response => {
    this.setState({pageLoader:false});
    toastFlashMessage('Exported file would be sent to registered e-mail', 'success')
    trackActivity('Item Exported', {
      page_title: 'Analytics',
      tab_name: 'resources',   
      event_type: 'Export',
      event_desc: 'Exported Resources data from Resource Tracker',
      extension: query.extension
    })
  })
}