import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { useSnackbar } from 'notistack';
import { Paper, TextField, Grid, Button, IconButton, Tooltip } from '@material-ui/core';
import { ReadPrerequisite } from '../../Elements'
import PrerequisiteContent from './PrerequisiteContent'
import StatsWidget from './StatsWidget'
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { regexConstants } from '../../../constants/regEx';
import EditIcon from '@material-ui/icons/Edit';
import { fieldDateFormat, backendDateFormat } from '../../../constants';
import { globalGetService, globalPutService } from '../../../utils_v2/globalApiServices';
import { checkApiStatus } from '../../../utils_v2';
import { PageLoader } from '../../../shared_elements';
const GeneralDetail = ({params, currentFormDetail, getCurrentFormDetail}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setLoading] = useState(false);
  const [isEditAble, setEditAble] = useState(false);
  const [generalDetail, setGeneralDetail] = useState({});
  const [error, setError] = useState({});
  const [statsSummary, setStatsSummary] = useState({});
  useEffect(() => {
    getGeneralDetails();
    getStatsSummary();
  }, []);
  const getStatsSummary = () => {
    globalGetService(`technical/v2/workorder/${params.workOrderSlug}/engine-gap-report/summary/`)
    .then(response => {
      if(checkApiStatus(response)){
        setStatsSummary(response.data.data);
      }
    })
  }
  const getGeneralDetails = () => {
    globalGetService(`technical/v2/workorder/${params.workOrderSlug}/engine-gap-report/general-details/`)
    .then(response => {
      if(checkApiStatus(response)){
        setGeneralDetail(response.data.data);
      }
    })
  }
  const onFieldChange = (key, value) => {
    setGeneralDetail({...generalDetail, [key]:value});
  }
  const onUpdateGeneralDetails = () => {
    let validationInputs = {
      report_date:generalDetail?.report_date ? moment(generalDetail.report_date).isValid() ? '':'Please enter valid Date':'Please enter Report Date',
      hsi_date:generalDetail?.hsi_date ? moment(generalDetail.hsi_date).isValid() ? '':'Please enter valid Date':'',
      report_issued_place:generalDetail?.report_issued_place?.trim()?.length ? '':'Please enter Report Issued Place',
    }
    if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
      setLoading(true)
      globalPutService(`technical/v2/workorder/${params.workOrderSlug}/engine-gap-report/general-details/`, generalDetail)
      .then(response => {
        if(checkApiStatus(response)){
          setEditAble(false);
          enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
          getCurrentFormDetail('pageLoader')
        }else{
            let tempElement = response.data.message.split('<br/>');
          enqueueSnackbar(<span>{tempElement.map((msg, index) => <span>{msg}<br/></span>)}</span>, 
            { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
        }
        setLoading(false)
      })
    }else{
      setError(validationInputs)
    }
  }
  const canEdit = currentFormDetail?.current_form?.permissions?.can_save;
  return(
    <>
      <StatsWidget statsSummary={statsSummary} />
      <Paper className="form-detail-card">
        <p className='edit-details'>
          <ReadPrerequisite content={<PrerequisiteContent />} />
          {canEdit && !isEditAble ? 
            <span className='edit-g'>
              <Tooltip title='Edit General Detail' arrow>
                <IconButton size="small"><EditIcon onClick={() => setEditAble(true)} fontSize='small' color='primary' /></IconButton>
              </Tooltip>
            </span>:null
          }
        </p>
        <div className='scrollable-content' style={{height: `${window.innerHeight- (isEditAble ? 312:272)}px`, overflow:'auto'}}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  disabled={!isEditAble}
                  margin="normal"
                  id="report_date"
                  label="Report Date"
                  format={fieldDateFormat}
                  fullWidth
                  required
                  disableFuture={true}
                  InputLabelProps={{shrink: true}}
                  value={generalDetail?.report_date||null}
                  error={error?.report_date}
                  helperText={error?.report_date||''}
                  onChange={(data, value) => {onFieldChange('report_date', data ? moment(data).format(backendDateFormat):data); setError({...error, 'report_date':''})}}
                  inputVariant="outlined"
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                disabled={!isEditAble}
                id="report_issued_place"
                label="Report Issued Place"
                fullWidth
                required
                margin="normal"
                value={generalDetail?.report_issued_place||''}
                error={error?.report_issued_place}
                helperText={error?.report_issued_place||''}
                InputLabelProps={{shrink: true}}
                inputProps={{maxLength: 50}}
                onChange={(e) => onFieldChange('report_issued_place', e.target.value)}
                onFocus={(e) => setError({...error, 'report_issued_place':''})}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                disabled={!isEditAble}
                id="hsi_tsn"
                label="TSN at HSI"
                fullWidth
                margin="normal"
                value={generalDetail.hsi_tsn === null || generalDetail.hsi_tsn === undefined ? '':generalDetail.hsi_tsn}
                InputLabelProps={{shrink: true}}
                inputProps={{maxLength: 10}}
                onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange('hsi_tsn', e.target.value): e.preventDefault()}}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                disabled={!isEditAble}
                id="hsi_csn"
                label="CSN at HSI"
                fullWidth
                margin="normal"
                value={generalDetail.hsi_csn === null || generalDetail.hsi_csn === undefined ? '':generalDetail.hsi_csn}
                InputLabelProps={{shrink: true}}
                inputProps={{maxLength: 10}}
                onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange('hsi_csn', e.target.value) : e.preventDefault() }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  disabled={!isEditAble}
                  margin="normal"
                  id="hsi_date"
                  label="HSI Date"
                  format={fieldDateFormat}
                  fullWidth
                  minDate={generalDetail.asset_dom ? moment(generalDetail.asset_dom): moment().subtract(30, 'years')}
                  disableFuture={true}
                  InputLabelProps={{shrink: true}}
                  value={generalDetail?.hsi_date||null}
                  error={error?.hsi_date}
                  helperText={error?.hsi_date||''}
                  onChange={(data, value) => {onFieldChange('hsi_date', data ? moment(data).format(backendDateFormat):data); setError({...error, 'hsi_date':''})}}
                  inputVariant="outlined"
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </div>
        { isEditAble ?
          <div className='actions-cta'>
            <ul className='list-inline'>
              <li className='list-inline-item'>
                <Button onClick={onUpdateGeneralDetails} size='small' color='primary' variant='contained'>Save</Button>
              </li>
              <li className='list-inline-item'>
                <Button onClick={() => {setError({});setEditAble(false);getGeneralDetails()}} size='small' color='primary' variant='outlined'>Cancel</Button>
              </li>
            </ul>
          </div>:null
        }
        { isLoading ? <PageLoader />:null}
      </Paper>
    </>
  )
}
export default withRouter(GeneralDetail);