import React from 'react';
import { getLocalStorageInfo } from '../../../utils';
class TextNumberField extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      value: ''
    }
  }
  componentDidMount(){
    this.setState({
      value: this.props.value? this.props.value: null
    })
  }
  componentWillReceiveProps(nextProps){
    if(this.props.value != nextProps.value){
      this.setState({
        value: nextProps.value
      })
    }
  }
  updateForm = (value) => {

    this.setState({
      value: value,
     // error: ''
    });
    if(this.props.typingOnchange!=null && this.props.typingOnchange){
      this.props.updateForm(value);
    }

  }
  onBlur = () => {
    if(this.props.typingOnchange == null){
      this.props.updateForm(this.state.value);
      }
  }
  numberOnly(evt){
    if(['e','E','+','-','^','"',"'"].includes(evt.key) && ['hsi_csn','hsi_tsn'].includes(this.props?.label)){
      evt.preventDefault()
    }
  }
  onFocus=()=>{
    if(this.props.focusOnchange != null){
      this.props.updateForm(this.state.value);
      }
  }
  render(){
    let userInfo= getLocalStorageInfo().user
    const { type, isDisabled, className  } = this.props;
    if(this.state.type == ''){
      return null
    }
    return(
      <div>
        <div className="form-group-edit">
          <label className={className?className:"label"}>{this.props.children}</label>
          { type == 'view' ? <h6>{this.props.value ? this.props.value:'--'}</h6> :
            <input
              type="number"
              placeholder= {this.props.placeholder}
              onKeyDown={(evt)=> this.numberOnly(evt)}
              value={this.state.value?this.state.value:this.state.value===0?0:''}
              onChange={(evt) => this.updateForm(evt.target.value)}
              onBlur={this.onBlur}
              disabled = {isDisabled? isDisabled :false }
              max= {this.props.max}
              min = {this.props.min}
            />
          }
        </div>
        {this.props.error != '' ? <h6 className="error-msg" >{this.props.error}</h6> : null}
      </div>
    )
  }
}
export default TextNumberField;
