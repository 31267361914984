import { createStore, compose, applyMiddleware } from 'redux';
import { saveStore } from '../store/globalStore'
import thunk from 'redux-thunk';
import rootReducer from '../reducers';
import 'regenerator-runtime/runtime';
import { composeWithDevTools } from 'redux-devtools-extension';

export default function configureStore() {
  const store = createStore(rootReducer, {}, composeWithDevTools(
    applyMiddleware(thunk)
  ));
  saveStore(store)
  return store;
}
