import axiosInstance from "./apiInterceptor"
// GET API Call
export const globalGetService = (url, params) => {
  return new Promise(function(resolve, reject) {
    axiosInstance({
      method: "GET",
      url: url,
      params: params,
    }).then((response) => {
      resolve(response)
    })
  })
}
// POST API Call
export const globalPostService = (url, data, isMultipart = false) => {
  return new Promise(function(resolve, reject) {
    let axiosConfig = {
      method: "POST",
      url: url,
      data: data,
    }
    if (isMultipart) {
      axiosConfig = {
        ...axiosConfig,
        headers: {
          "Content-type": "multipart/form-data",
        },
      }
    }
    axiosInstance(axiosConfig).then((response) => {
      resolve(response)
    })
  })
}
// PUT API Call
export const globalPutService = (url, data) => {
  return new Promise(function(resolve, reject) {
    axiosInstance({
      method: "PUT",
      url: url,
      data: data,
    }).then((response) => {
      resolve(response)
    })
  })
}
// DELETE API Call
export const globalDeleteService = (url, data) => {
  return new Promise(function(resolve, reject) {
    axiosInstance({
      method: "DELETE",
      url: url,
      data: data,
    }).then((response) => {
      resolve(response)
    })
  })
}

export const globalExportService = (url, params={}) => {
  return new Promise(function(resolve, reject){
    axiosInstance({
      method: 'GET',
      url: url,
      responseType: 'blob',
      params: params && Object.keys(params).length ? {...params,timestamp: new Date().getTime()} : {timestamp: new Date().getTime()}
    })
    .then(response => {
      resolve(response.data);
    })
    .catch(error => {
      reject(error)
    })
  })
}



export const globalFileUploadService = (url, data, config) => {
  return new Promise(function(resolve, reject) {
    axiosInstance.post(url, data, config).then(function(response) {
      resolve(response)
    })
  })
}

export const globalFileUploadPutService = (url, data, config) => {
  return new Promise(function(resolve, reject) {
    axiosInstance.put(url, data, config).then(function(response) {
      resolve(response)
    })
  })
}
export const globalDownloadService = (url, data) => {
  return new Promise(
    function(resolve, reject){
      axiosInstance({
        method: 'POST',
        url: url,
        data: data,
        responseType: 'blob',
      })
      .then(response => {
        resolve(response.data);
      })
    }
  )
}
