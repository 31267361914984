import React, { Fragment, useState, useEffect } from 'react'
import { withRouter } from 'react-router';
import { useSnackbar } from "notistack"
import moment from 'moment';
import { Grid, TextField, Paper } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import MomentUtils from '@date-io/moment';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { getProjectWorkOrdersApi, getWorkOrderFormsApi, getAllStagesApi, getAllMilestoneApi, getPlatformConstantsApi } from "../apiService"
import { globalGetService, globalPutService } from '../../../utils_v2/globalApiServices';
import { backendDateFormat, fieldDateFormat } from "../../../constants"
import { checkApiStatus, onCheckPermission, getLocalStorageInfo } from "../../../utils_v2"
import { taskAndIssueSelfPermission } from '../';
const TaskInformation = ({params, taskDetail, permission, cardType}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const [task, setTask] = useState(taskDetail);
    const [taskIssueUsers, setTaskIssueUsers] = useState([]);
    const [stages, setStages] = useState([]);
    const [milestones, setMilestone] = useState([]);
    const [workOrders, setWorkOrders] = useState([]);
    const [projectForms, setProjectForm] = useState([]);
    const [pltConstants, setPltConstants] = useState([]);
    useEffect(() => {
        getTaskIssueUsers({role:cardType === 'issue' ?'issue_users':'task_users'});
        getProjectWorkOrders();
        getAllStages();
        getAllMilestone();
        getPlatformConstants();
        if(taskDetail.work_order){
            getWorkOrderForms(taskDetail.work_order)
        }
    }, []);
    const getWorkOrderForms = (data) => {
        globalGetService(`technical/project/${params.project_slug}/workorders/${data.slug}/asset-type/${data.asset_type}/forms/`)
        .then(response => {
          if(checkApiStatus(response)){
            setProjectForm(response.data.data)
          }
        });
    }
    const onEditTask = (keyParam, value) => {
        let payload = Object.assign({},task)
        payload = {
            ...payload,
            [keyParam]:value
        }
        delete payload['created_by']
        delete payload['updated_by']
        if(keyParam === 'start_date' || keyParam === 'end_date'){
            if(payload.start_date && payload.end_date){
                if(moment(payload.end_date).isSameOrAfter(payload.start_date)){
                    onUpdateTaskInfo(payload, keyParam, value)
                }else{
                    enqueueSnackbar('Difference b/w Start Date and End Date should be greater then or equal to 0 day(s)', { variant: "error", anchorOrigin: { horizontal: "right", vertical: "top" }})
                }                
            }else{
                onUpdateTaskInfo(payload, keyParam, value)    
            }
        }else if(keyParam === 'work_order'){
            onUpdateTaskInfo({...payload, form:null}, keyParam, value);
            setTask({...task, form:null})
        }else{
            onUpdateTaskInfo(payload, keyParam, value)
        }
        
    }
    const onUpdateTaskInfo = (payload, keyParam, value) => {
        globalPutService(`technical/project/${params.project_slug}/${cardType === 'issue'?'issue':'tasks'}/${taskDetail.id}/`,payload)
        .then(response => {
            if(checkApiStatus(response)){
                setTask({...task, [keyParam]:value})
                enqueueSnackbar(response.data.message, { variant: "success", anchorOrigin: { horizontal: "right", vertical: "top" }})
            }else{
                enqueueSnackbar(response.data.message, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "top" }})
            }
        });
    }
    const getTaskIssueUsers = (query) => {
        globalGetService(`technical/users/`, {...query,project_slug:params.project_slug})
        .then(response => {
            if(checkApiStatus(response)){
                setTaskIssueUsers(response.data.data);
            }
        })
    }
    const getProjectWorkOrders = () => {
        getProjectWorkOrdersApi(params).then((response) => {
            if (checkApiStatus(response)) {
            setWorkOrders(response.data.data)
            }
        })
    }
    
    const getAllStages = () => {
        getAllStagesApi(params, {view:'all', fields:'id,name'}).then((response) => {
            if (checkApiStatus(response)) {
            setStages(response.data.data)
            }
        })
    }
    const getPlatformConstants = () => {
        getPlatformConstantsApi({constant_types:['priority','severity','milestone_status']}).then((response) => {
            if (checkApiStatus(response)) {
            setPltConstants(response.data.data)
            }
        })
    }

    const getAllMilestone = () => {
        getAllMilestoneApi(params, {view:'all', fields:'id,name'}).then((response) => {
            if (checkApiStatus(response)) {
            setMilestone(response.data.data)
            }
        })
    }
    const canEdit = taskAndIssueSelfPermission(cardType === 'issue' ? 'issues':'tasks','U', task, permission?.update?.value||null)
    return(
        <Paper className='task-management-information'>
            <h6>Task Information</h6>
            <Grid container spacing={1}>
                { cardType === 'issue' ?
                    <Grid item xs={4}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                disabled={!canEdit}
                                margin="normal"
                                id="due_date"
                                label="Due Date"
                                format={fieldDateFormat}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ readOnly: true }}
                                value={task.due_date}
                                onChange={(data, value) => { onEditTask( "due_date", data ? moment(data).format(backendDateFormat) : data ) }}
                                inputVariant="outlined"
                                clearable
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>:
                    <Fragment>
                        <Grid item xs={4}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    disabled={!canEdit}
                                    margin="normal"
                                    id="start_date"
                                    label="Start Date"
                                    format={fieldDateFormat}
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ readOnly: true }}
                                    value={task.start_date}
                                    onChange={(data, value) => { onEditTask( "start_date", data ? moment(data).format(backendDateFormat) : data ) }}
                                    inputVariant="outlined"
                                    clearable
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={4}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    disabled={!canEdit}
                                    margin="normal"
                                    id="end_date"
                                    label="End Date"
                                    format={fieldDateFormat}
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ readOnly: true }}
                                    value={task.end_date}
                                    onChange={(data, value) => { onEditTask( "end_date", data ? moment(data).format(backendDateFormat) : data ) }}
                                    inputVariant="outlined"
                                    clearable
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Fragment>
                }
                
                <Grid item xs={4}>
                    <Autocomplete
                        disabled={!canEdit}
                        options={taskIssueUsers}
                        getOptionLabel={(option) => option.name}
                        id="follower"
                        value={task.follower?task.follower:null}
                        onChange={(e, value) => onEditTask("follower", value)}
                        renderInput={(params) => ( <TextField {...params} label="Follower" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant="outlined" /> )}
                    />
                </Grid>
                { cardType === 'issue' ?
                    <Grid item xs={4}>
                        <Autocomplete
                            disabled={!canEdit}
                            options={taskIssueUsers}
                            getOptionLabel={(option) => option.name}
                            id="assign"
                            value={task.assign?task.assign:null}
                            onChange={(e, value) => onEditTask("assign", value)}
                            renderInput={(params) => ( <TextField {...params} label="Assign" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant="outlined" /> )}
                        />
                    </Grid>:
                    <Grid item xs={4}>
                        <Autocomplete
                            disabled={!canEdit}
                            options={taskIssueUsers}
                            getOptionLabel={(option) => option.name}
                            id="owner"
                            disableClearable
                            value={task.owner?task.owner:null}
                            onChange={(e, value) => onEditTask("owner", value)}
                            renderInput={(params) => ( <TextField {...params} label="Owner" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant="outlined" /> )}
                        />
                    </Grid>
                }
                <Grid item xs={4}>
                    <Autocomplete
                        disabled={!canEdit}
                        options={pltConstants.filter(item => item.type === 'priority')}
                        getOptionLabel={(option) => option.label}
                        id="priority"
                        value={task.priority?task.priority:null}
                        onChange={(e, value) => onEditTask("priority", value)}
                        renderInput={(params) => ( <TextField {...params} label="Priority" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant="outlined" /> )}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Autocomplete
                        disabled={!canEdit}
                        options={pltConstants.filter(item => item.type === 'severity')}
                        getOptionLabel={(option) => option.label}
                        id="severity"
                        value={task.severity?task.severity:null}
                        onChange={(e, value) => onEditTask("severity", value)}
                        renderInput={(params) => ( <TextField {...params} label="Severity" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant="outlined" /> )}
                    />
                </Grid>
                {onCheckPermission('task_management','stages','R') ?
                    <Grid item xs={4}>
                        <Autocomplete
                            disabled={!canEdit}
                            options={stages}
                            getOptionLabel={(option) => option.name}
                            id="project_stage"
                            value={task.project_stage?task.project_stage:null}
                            onChange={(e, value) => onEditTask("project_stage", value)}
                            renderInput={(params) => ( <TextField {...params} label="Stage" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant="outlined" /> )}
                        />
                    </Grid>:null
                }
                
                {onCheckPermission('task_management','milestone','R') ?
                    <Grid item xs={4}>
                        <Autocomplete
                            disabled={!canEdit}
                            options={milestones}
                            getOptionLabel={(option) => option.name}
                            id="milestone"
                            value={task.project_milestone?task.project_milestone:null}
                            onChange={(e, value) => onEditTask("project_milestone", value)}
                            renderInput={(params) => ( <TextField {...params} label="Milestone" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant="outlined" /> )}
                        />
                    </Grid>:null
                }
                <Grid item xs={4}>
                    <Autocomplete
                        disabled={!canEdit}
                        options={workOrders}
                        getOptionLabel={(option) => option.unique_name}
                        id="work_order"
                        value={task.work_order?task.work_order:null}
                        onChange={(e, value) => onEditTask("work_order", value)}
                        renderInput={(params) => ( <TextField {...params} label="Work Order" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant="outlined" /> )}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Autocomplete
                        disabled={!canEdit}
                        options={projectForms}
                        getOptionLabel={(option) => option.name}
                        id="form"
                        value={task.form?task.form:null}
                        onChange={(e, value) => onEditTask("form", value)}
                        renderInput={(params) => ( <TextField {...params} label="Form" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant="outlined" /> )}
                    />
                </Grid>
            </Grid>
        </Paper>
    )
}
export default withRouter(TaskInformation);