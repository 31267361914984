import React, { useEffect, useState } from 'react';
import MajorComponent from '../components/MajorComponent';
import RegHistorycomp from '../components/RegHistorycomp';
import Specs from '../components/Specs';
import MaintainanceHistoryComp from '../components/MaintainanceHistoryComp';
import AvionicsComp from '../components/AvionicsComp';
import Weight from '../components/Weight';
import { useLocation, useNavigate } from 'react-router-dom';
import { Skeleton } from '@material-ui/lab';
const components = [
  { label: 'Major Components', component: MajorComponent },
  { label: 'Registration History', component: RegHistorycomp },
  { label: 'Specs', component: Specs },
  { label: 'Weights', component: Weight },
  { label: 'Full Maintenance History', component: MaintainanceHistoryComp },
  { label: 'Avionics', component: AvionicsComp }
];

const Navs = ({ params, getCurrentFormDetail, currentFormDetail }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const component = params.type === 'engine' ? components.filter(tab => tab.label !== 'Avionics' && tab.label !== 'Weights') : components;
  useEffect(() => {
    const tabName = component[activeIndex]?.label.replace(/\s+/g, '_');
    window.history.replaceState(null, '', `?tab=${tabName}`);
  }, [activeIndex]);

  const handleTabChange = (index) => {
    setActiveIndex(index);
  };

  const ActiveComponent = component[activeIndex].component;

  return (
    <div>
      <div className='tech-specs-nav'>
        {component.map((tab, index) => (
          <div
            style={{ cursor: 'pointer' }}
            key={index}
            className={`tech-nav-link ${activeIndex === index ? 'active' : ''}`}
            onClick={() => handleTabChange(index)}
          >
            {tab.label}
          </div>
        ))}
      </div>

      <div className='tab-content'>
        <ActiveComponent currentFormDetail={currentFormDetail} getCurrentFormDetail={getCurrentFormDetail} />
      </div>
    </div>
  );
};

export default Navs;
