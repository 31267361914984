import React, {Fragment, useState, useEffect} from 'react';
import { withRouter } from 'react-router';
import { useSnackbar } from 'notistack';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { read, utils, writeFileXLSX } from 'xlsx';
import { Paper, Button, Dialog, Grid, TextField, DialogActions, DialogContent, DialogTitle, Table, TableHead, TableBody, TableCell, CircularProgress, TableRow, Tabs, Tab } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { globalGetService, globalPostService } from '../../../utils_v2/globalApiServices';
import { checkApiStatus, isPositiveInteger, onCheckPermission } from '../../../utils_v2';
import { regexConstants } from '../../../constants/regEx';
import { Link } from 'react-router';
const ImportTemplate = ({params, importTemplate, toggleModalFn, getResponseBack})  => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [tabIndex, setTabIndex] = useState(0);
    const [modal, setModal] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [importCrud, setImportCrud] = useState({template:null});
    const [templates, setTemplates] = useState([])
    const [pres, setPres] = useState([]);
    const [error, setError] = useState([]);
    const getFormTemplates = (query) => {
        globalGetService(`technical/templates/?asset_type=${params.type}`, {type:'T002'})
        .then(response => {
            if(checkApiStatus(response)){
                setTemplates(response.data.data.list)
            }
        })
    }
    const onSpartaTemplate = (value) => {
        if(value === 1){
            setLoading(true);
            globalGetService(`technical/workorder/${params.workOrderSlug}/data_plate_section/?asset_type=${params.type}`)
            .then(response => {
                setLoading(false);
                if(checkApiStatus(response)){
                    setPres(response.data.data.map((item,index) => {return { name:item.name, description:item?.description, max_limit:'', physical_order:item?.physical_order} }))
                }else{

                }
            })
        }
        
    }
    const onChangeTemplate = (template) => {
        setImportCrud({template});
        setLoading(true);
        if(template){
            (async() => {
                const f = await (await fetch(template.file_path)).arrayBuffer();
                const wb = read(f); // parse the array buffer
                const ws = wb.Sheets[wb.SheetNames[0]]; // get the first worksheet
                const data = utils.sheet_to_json(ws,{blankRows: false}); // generate objects
                setPres(data); // update state
                setLoading(false);
            })();
        }else{
            setPres([]);
            setLoading(false);
        }
    }
    const onFieldChange = (e, keyParam, value, index) => {
        setPres(pres.map((item, itemIndex) =>  itemIndex !== index ? item: {...item, [keyParam]: value}))
    }
    const onRemove = (index) => {
        setPres(pres.filter((item, itemIndex) => itemIndex !== index))
    }
    const onAddNewRow = () => {
        setPres([...pres, {name: '', description: '', limit: ''}])
    }
    const onSetupTemplate = () => {
        // Validations for Template Import;
        let isValid = true, templateError=[];
        templateError = pres.map(item => {
          return {
            name: item.name && item.name.trim().length ? '':'Please enter Name',
            max_limit: item.max_limit ? isPositiveInteger(item.max_limit) ? '':'Please enter valid Max Limit' :'',
            physical_order:''
          }
        });
        templateError.map(data => {
            if(!Object.keys(data).every((k) => { return data[k] === ''})){
                isValid = false
            }
        });
        if(isValid){
            // Check for duplication in array by name
            let isDuplicate = false;
            pres.map((item, index) => {
                if(pres.findIndex((item1) => item1.name.toLowerCase() === item.name.toLowerCase()) !== index){
                    isDuplicate = true;
                }
            });
            if(!isDuplicate){
                setLoading(true);
                globalPostService(`technical/v2/workorder/${params.workOrderSlug}/sections/importSections/?asset_type=${params.type}`, {type:'T002', sections: pres.map((item,index)=> {return {...item,id:index+1, max_limit: item.max_limit? item.max_limit : '', physical_order: item.physical_order ?item.physical_order: ''  }})})
                .then(response => {
                    setLoading(false);
                    if(checkApiStatus(response)){
                        enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
                        getResponseBack();
                        setModal(false);
                    }else{
                        enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
                    }
                })
            }else{
                enqueueSnackbar('Duplicate Section Name', { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
            }
            
        }else{
            enqueueSnackbar('Missing mandatory fields', { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
            setError(templateError)
        }
    }
    console.log(pres.length, 'length')
    return(
        <Fragment>
            <div className='import-form-template'>
                <h4>There is no section mapped to this form right now</h4>
                <p>Please choose the template to map the section</p>
                <Button onClick={() => {getFormTemplates(); setModal(true)}} color='primary' variant='outlined' size='small'>Import Template</Button>
            </div>
            {modal ?
                <Dialog
                    open={modal}
                    onClose={() => {setModal(false)}}
                    className='project-management-modal'
                    maxWidth={false}
                >
                    <DialogTitle id="scroll-dialog-title">
                        Import Template
                    </DialogTitle>
                    <DialogContent dividers={true} >
                        <div style={{width:'780px', minHeight:'400px'}}>
                            <Paper>
                                <Tabs
                                    value={tabIndex}
                                    onChange={(event, newValue) => {setPres([]);setTabIndex(newValue);onSpartaTemplate(newValue);setImportCrud({template:null})}}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="fullWidth"
                                >
                                    <Tab label={<span>Custom Template</span>} />
                                    <Tab label={<span>Sparta Template</span>} />
                                </Tabs>
                            </Paper>
                            { tabIndex === 0 ?

                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={6}>
                                        <Autocomplete
                                            options = {templates}
                                            getOptionLabel={option => option.name}
                                            id="template"
                                            value={importCrud.template}
                                            onChange={(e, value) => onChangeTemplate(value)}
                                            renderInput={params => <TextField required {...params} label="Select Template" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
                                        />
                                    </Grid>
                                    { onCheckPermission('project_management', 'template','C') ?
                                    <Grid item sm={6} style={{padding:'25px 0px 0px 7px'}} >
                                            or &nbsp;
                                        <Link to='/technical/form/templates/'>
                                            <Button variant='contained' color='primary'>
                                                Add New Template
                                            </Button>
                                        </Link>
                                    </Grid>
                                    :null}

                                </Grid>:null
                            }
                            <Grid container spacing={1}>
                            {
                                 isLoading ?
                                     <Grid item sm={12}>
                                        <div style={{textAlign:'center', marginTop:'45px'}} > 
                                         <CircularProgress size={40} />
                                        </div>
                                    </Grid> : null
                             }
                                <Grid item md={12}>
                                    { pres.length ?
                                        <Table className='mui-table-format'>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Name<sup>*</sup></TableCell>
                                                    <TableCell>Description</TableCell>
                                                    <TableCell>Picture(s) Limit</TableCell>
                                                    <TableCell>Section Order</TableCell>
                                                    <TableCell>Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                { /* generate row for each president */
                                                    pres.map((pres,index) => (
                                                        <TableRow>
                                                            <TableCell>
                                                                <TextField
                                                                    required
                                                                    id={`name_${index}`}
                                                                    fullWidth
                                                                    margin="none"
                                                                    value={pres.name}
                                                                    onChange={(e) => onFieldChange(e, 'name', e.target.value, index)}
                                                                    error={error && error[index] &&  error[index].name}
                                                                    helperText={error && error[index] &&  error[index].name}
                                                                    InputLabelProps={{shrink: true}}
                                                                    variant='outlined'
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <TextField
                                                                    required
                                                                    id={`description_${index}`}
                                                                    fullWidth
                                                                    margin="none"
                                                                    value={pres.description}
                                                                    onChange={(e) => onFieldChange(e, 'description', e.target.value, index)}
                                                                    InputLabelProps={{shrink: true}}
                                                                    variant='outlined'
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <div style={{width:'100px'}}>
                                                                    <TextField
                                                                        required
                                                                        id={`max_limit_${index}`}
                                                                        fullWidth
                                                                        margin="none"
                                                                        value={pres.max_limit}
                                                                        onChange={(e) => {regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'max_limit', e.target.value, index): e.preventDefault()}}
                                                                        error={error && error[index] &&  error[index].max_limit}
                                                                        helperText={error && error[index] &&  error[index].max_limit}
                                                                        InputLabelProps={{shrink: true}}
                                                                        variant='outlined'
                                                                    />
                                                                </div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <div style={{width:'100px'}}>
                                                                    <TextField
                                                                        required
                                                                        id={`physical_order_${index}`}
                                                                        fullWidth
                                                                        margin="none"
                                                                        value={pres.physical_order}
                                                                        onChange={(e) => {regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'physical_order', e.target.value, index): e.preventDefault()}}
                                                                        error={error && error[index] &&  error[index].physical_order}
                                                                        helperText={error && error[index] &&  error[index].physical_order}
                                                                        InputLabelProps={{shrink: true}}
                                                                        variant='outlined'
                                                                    />
                                                                </div>
                                                            </TableCell>
                                                            <TableCell>
                                                                {pres.length !== 1 ?
                                                                    <DeleteOutlineIcon color='secondary' onClick={() => onRemove(index)} />:null
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                }
                                            </TableBody>
                                        </Table>:null
                                    }
                                </Grid>
                            </Grid>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={isLoading} onClick={() => {setModal(false)}} color="primary" size='small' variant='outlined'>Cancel</Button>
                        { pres.length ?
                            <Button disabled={isLoading} onClick={onSetupTemplate} color="primary" size='small' variant='contained'>
                                {isLoading ? <CircularProgress size={20} /> : 'Proceed'}
                            </Button>:null
                        }
                    </DialogActions>
                </Dialog>:null    
            }
        </Fragment>
    )
}
export default withRouter(ImportTemplate);