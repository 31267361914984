import React, { Fragment } from 'react';
import { Grid } from '@material-ui/core';
import JSONViewer from 'react-json-viewer';
import LabelValueCard from './LabelValueCard';
const RevisionAddCard = ({item}) => {
  return(
    <Fragment>
      <Grid container spacing={3}>
        <LabelValueCard md={6} label='Created at' value={item.timestamp} />
        <LabelValueCard md={6} label='Created by' value={item.user && item.user.name ? item.user.name:'--'} />
      </Grid>
      <div className="edit-detail-table">
        <JSONViewer json={item.changes}></JSONViewer>
      </div>
    </Fragment>
  )
}
export default RevisionAddCard;
