import axiosInstance from '../utils/httpInterceptors';
import staticAxiosInst from '../utils/splhttpInterceptor';
import signUpInterceptor from '../utils/signUpInterceptor';
export const globalGetService = (url, params) => {
  return new Promise(
    function(resolve, reject){
      axiosInstance({
        method: 'GET',
        url: url,
        params: params && Object.keys(params).length ? {...params,timestamp: new Date().getTime()} : {timestamp: new Date().getTime()}
      })
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error)
      })
    }
  )
}
export const globalPostService = (url, data) => {
  return new Promise(
    function(resolve, reject){
      axiosInstance({
        method: 'POST',
        url: url,
        data: data,
        params: { timestamp: new Date().getTime() }
      })
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error)
      })
    }
  )
}
export const globalPutService = (url, data) => {
  return new Promise(
    function(resolve, reject){
      axiosInstance({
        method: 'PUT',
        url: url,
        data: data,
        params: { timestamp: new Date().getTime() }
      })
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error)
      })
    }
  )
}
export const globalDeleteService = (url, data) => {
  return new Promise(
    function(resolve, reject){
      axiosInstance({
        method: 'DELETE',
        url: url,
        data: data,
        params: { timestamp: new Date().getTime() }
      })
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error)
      })
    }
  )
}

export const globalExportService = (url, params={}) => {
  return new Promise(function(resolve, reject){
    axiosInstance({
      method: 'GET',
      url: url,
      responseType: 'blob',
      params: params && Object.keys(params).length ? {...params,timestamp: new Date().getTime()} : {timestamp: new Date().getTime()}
    })
    .then(response => {
      resolve(response.data);
    })
    .catch(error => {
      reject(error)
    })
  })
}
export const globalDownloadService = (url, data) => {
  return new Promise(
    function(resolve, reject){
      axiosInstance({
        method: 'POST',
        url: url,
        data: data,
        responseType: 'arraybuffer',
        params: { timestamp: new Date().getTime() }
      })
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error)
      })
    }
  )
}
export const globalUnlimitedDownloadService = (url, data) => {
  return new Promise(
    function(resolve, reject){
      staticAxiosInst({
        method: 'POST',
        url: url,
        data: data,
        responseType: 'arraybuffer',
        params: { timestamp: new Date().getTime() }
      })
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error)
      })
    }
  )
}
export const globalDownloadGetService = (url, params) => {
  return new Promise(
    function(resolve, reject){
      axiosInstance({
        method: 'GET',
        url: url,
        params: params,
        responseType: 'arraybuffer',
        params: params && Object.keys(params).length ? {...params,timestamp: new Date().getTime()} : {timestamp: new Date().getTime()}
      })
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error)
      })
    }
  )
}
export const globalUnlimitedDownloadGetService = (url, params) => {
  return new Promise(
    function(resolve, reject){
      staticAxiosInst({
        method: 'GET',
        url: url,
        params: params && Object.keys(params).length ? {...params,timestamp: new Date().getTime()} : {timestamp: new Date().getTime()},
        responseType: 'arraybuffer',
      })
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error)
      })
    }
  )
}
export const globalFileUploadService = (url, data, config, params) => {
  return new Promise(
    function(resolve, reject){
      axiosInstance({
        method: 'POST',
        url: url,
        data: data,
        params: params && Object.keys(params).length ? {...params,timestamp: new Date().getTime()} : {timestamp: new Date().getTime()},
        config: config
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(error => {
        reject(error)
      })
    }
  )
}
export const globalFileUploadPutService = (url, data, config, params) => {
  return new Promise(
    function(resolve, reject){
      axiosInstance({
        method: 'PUT',
        url: url,
        data: data,
        params: params && Object.keys(params).length ? {...params,timestamp: new Date().getTime()} : {timestamp: new Date().getTime()},
        config: config
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(error => {
        reject(error)
      })
    }
  )
}
export const globalStaticExportService = (url, params ={}) => {
  return new Promise(function(resolve, reject) {
    staticAxiosInst({
      method: 'GET',
      url: url,
      responseType: 'blob',
      params: params && Object.keys(params).length ? {...params,timestamp: new Date().getTime()} : {timestamp: new Date().getTime()}
    })
    .then(response => {
      resolve(response.data);
    })
    .catch(error => {
      reject(error)
    })
  })
}

export const globalStaticDownloadService = (url, data, params) => {
  return new Promise(
    function(resolve, reject){
      staticAxiosInst({
        method: 'POST',
        url: url,
        data: data,
        responseType: 'arraybuffer',
        params: params && Object.keys(params).length ? {...params,timestamp: new Date().getTime()} : {timestamp: new Date().getTime()}
      })
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error)
      })
    }
  )
}

export const globalStaticDownloadGetService = (url, params) => {
  return new Promise(
    function(resolve, reject){
      staticAxiosInst({
        method: 'GET',
        url: url,
        params: params && Object.keys(params).length ? {...params,timestamp: new Date().getTime()} : {timestamp: new Date().getTime()},
        responseType: 'arraybuffer',
      })
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error)
      })
    }
  )
}


export const globalStaticPostService = (url, data, params) => {
  return new Promise(
    function(resolve, reject){
      staticAxiosInst({
        method: 'POST',
        url: url,
        data: data,
        params: params && Object.keys(params).length ? {...params,timestamp: new Date().getTime()} : {timestamp: new Date().getTime()}
      })
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error)
      })
    }
  )
}
export const globalStaticPutService = (url, data, params) => {
  return new Promise(
    function(resolve, reject){
      staticAxiosInst({
        method: 'PUT',
        url: url,
        data: data,
        params: params && Object.keys(params).length ? {...params,timestamp: new Date().getTime()} : {timestamp: new Date().getTime()}
      })
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error)
      })
    }
  )
}
export const globalStaticGetService = (url, params) => {
  return new Promise(
    function(resolve, reject){
      staticAxiosInst({
        method: 'GET',
        url: url,
        params: params && Object.keys(params).length ? {...params,timestamp: new Date().getTime()} : {timestamp: new Date().getTime()}
      })
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error)
      })
    }
  )
}
export const globalSignUpGetService = (url, params) => {
  return new Promise(
    function(resolve, reject){
      signUpInterceptor({
        method: 'GET',
        url: url,
        params: params && Object.keys(params).length ? {...params,timestamp: new Date().getTime()} : {timestamp: new Date().getTime()}
      })
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error)
      })
    }
  )
}
export const globalSignUpPostService = (url, data, params) => {
  return new Promise(
    function(resolve, reject){
      signUpInterceptor({
        method: 'POST',
        url: url,
        data: data,
        params: params && Object.keys(params).length ? {...params,timestamp: new Date().getTime()} : {timestamp: new Date().getTime()}
      })
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error)
      })
    }
  )
}
