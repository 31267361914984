import React, { Component } from 'react';
import { imgStoragePath } from '../../constants';
export default class ToastList extends Component {
  constructor(props){
    super(props);
  }
  render() {
    return(
      <div className="Message">
        <div className={this.props.toast.toastType}>
          <div className="Message-icon">
            <img src={imgStoragePath + (this.props.toast.toastType == 'success'? "toast_check.png" :"toast_warn.png")} alt="info-status"/>
          </div>
          <div className="Message-body">
            <p dangerouslySetInnerHTML={{ __html: this.props.toast.message }}></p>
            <img src={imgStoragePath+"toast_cross.png"} onClick={this.props.clearToast}  alt="close"/>
          </div>
        </div>
      </div>
    )
  }
}
