import React, { Fragment } from 'react';
const AircraftModelStep = ({goToStep, stepId}) => {
    return(
        <Fragment>
            <ul className='list-unstyled frame-position-cta right-position'>
                <li className={stepId === 'c3e7f6d7-a3f4-49d6-9e6b-1b94517c871f'? 'active':''} onClick={()=> goToStep('c3e7f6d7-a3f4-49d6-9e6b-1b94517c871f', 'Top-Left')}>Top-Left</li>
                <li className={stepId === '4ba34e22-f25f-46d1-bec0-066bcda9349f'? 'active':''} onClick={()=> goToStep('4ba34e22-f25f-46d1-bec0-066bcda9349f', 'Top-Right')}>Top-Right</li>
                <li className={stepId === '9e2eb2d6-626f-4884-bb99-d6759b73efc5'? 'active':''} onClick={()=> goToStep('9e2eb2d6-626f-4884-bb99-d6759b73efc5', 'Bottom-Left')}>Bottom-Left</li>
                <li className={stepId === '3daefa7f-df39-421d-8ed2-fe126f0e5e4b'? 'active':''} onClick={()=> goToStep('3daefa7f-df39-421d-8ed2-fe126f0e5e4b', 'Bottom-Right')}>Bottom-Right</li>
                <li className={stepId === '57686463-2379-4786-ac4d-d242f523e997'? 'active':''} onClick={()=> goToStep('57686463-2379-4786-ac4d-d242f523e997', 'Right-Inward')}>Right Inward</li>
                <li className={stepId === '94aaa6bd-cae6-433d-9cc5-4e52486b6a5a'? 'active':''} onClick={()=> goToStep('94aaa6bd-cae6-433d-9cc5-4e52486b6a5a', 'Left-Inward')}>Left Inward</li>
            </ul>
            <ul className='list-inline frame-position-cta bottom-position'>
                <li onClick={()=> goToStep('bbc50053-9204-4edb-b9be-791e9cd1ecdc', 'Left')} className={`list-inline-item ${stepId === 'bbc50053-9204-4edb-b9be-791e9cd1ecdc' ? 'active':''}`}>Left</li>
                <li onClick={()=> goToStep('2033a87b-b248-47eb-a99f-28fbe6ec2f0d', 'Front')} className={`list-inline-item ${stepId === '2033a87b-b248-47eb-a99f-28fbe6ec2f0d' ? 'active':''}`}>Front</li>
                <li onClick={()=> goToStep('10e5d5b2-6785-461a-885a-bab03ded36f2', 'Right')} className={`list-inline-item ${stepId === '10e5d5b2-6785-461a-885a-bab03ded36f2' ? 'active':''}`}>Right</li>
                <li onClick={()=> goToStep('6dc0b84f-4848-47b9-955c-686016be070e', 'Back')} className={`list-inline-item ${stepId === '6dc0b84f-4848-47b9-955c-686016be070e' ? 'active':''}`}>Back</li>
                <li onClick={()=> goToStep('95084994-2845-47bc-b7f9-854528547c81', 'Top')} className={`list-inline-item ${stepId === '95084994-2845-47bc-b7f9-854528547c81' ? 'active':''}`}>Top</li>
                <li onClick={()=> goToStep('5337d1ef-d874-46e2-a15b-bd30531fb5c2', 'Bottom')} className={`list-inline-item ${stepId === '5337d1ef-d874-46e2-a15b-bd30531fb5c2' ? 'active':''}`}>Bottom</li>
            </ul>
        </Fragment>
    )
}
export default AircraftModelStep;