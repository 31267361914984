import * as React from 'react';
import { Tooltip, Box, Popper } from '@material-ui/core';
import moment from 'moment'
import infoIcon from '../../../../../shared/assets/img/info_icon_new.png';

export default function MoreInfoPopper({issueDetail}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = 'simple-popper';
  return (
    <>
      <Tooltip title={
          <Box className="popper-box" sx={{ bgcolor: '#ffffff' }}>
                <p>Created On: <span>{moment.utc(issueDetail.created_at).format('DD-MMM-YYYY HH:MM A')} UTC</span></p>
                <p>Last Updated On: <span>{moment.utc(issueDetail.updated_at).format('DD-MMM-YYYY HH:MM A')} UTC</span></p>
          </Box>
        } arrow>
            <img src={infoIcon} width="13" alt="Icon" onClick={handleClick}/>
      </Tooltip>

    </>
  );
}
