import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Button, TableRow, TableCell, TextField, Paper, Table, TableHead, TableBody, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Checkbox, CircularProgress, Tooltip, IconButton } from '@material-ui/core';
import { EmptyCollection, DeletePopUp, PageLoader, Pagination, STableLoader } from '../../../shared_elements';
import FilterComponent from '../../../shared_elements/filter_component'
import { ProjectDetailTab } from '../components'
import { globalDeleteService, globalGetService, globalPostService } from '../../../utils_v2/globalApiServices';
import { checkApiStatus, onCheckPermission } from '../../../utils_v2';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import RestrictedAccess from '../../../shared_elements/components/RestrictedAccess';
const ProjectUserHd = ({type, handleChange, bulkFlag}) => {
    return(
        <TableHead>
            <TableRow>
                <TableCell style={{width:'40px'}}>
                    <div style={{width:'40px'}}>
                        <Checkbox
                            size='small'
                            checked={bulkFlag}
                            onChange={(e) => handleChange('bulk', e.target.checked)}
                        />
                    </div>
                </TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Role</TableCell>
                {type !== 'add' ?<TableCell align='right'>Action(s)</TableCell>:null}
            </TableRow>
        </TableHead>
    )
}
const ProjectUserList = ({item, type, onEdit, onRemove, selectedUsers, handleChange}) => {
    return(
        <TableRow>
            <TableCell style={{width:'40px'}}>
                <div style={{width:'40px'}}>
                    <Checkbox
                        size='small'
                        checked={type === 'add' ? selectedUsers.includes(item.user_id.id):selectedUsers.includes(item.id)}
                        onChange={(e) => handleChange(e.target.checked)}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                </div>
            </TableCell>
            <TableCell>{item?.user_id?.name||'--'}</TableCell>
            <TableCell>
                <div style={{width:'220px'}}>{item?.user_id?.email||'--'}</div>
            </TableCell>
            <TableCell>{item?.sparta_group?.name||'--'}</TableCell>
            {type !== 'add' ?
                <TableCell align='right'>
                    { onCheckPermission('project_management', 'users','D') ?
                    <Tooltip title='Remove User' arrow>
                        <IconButton size="small">
                            <DeleteOutlineIcon onClick={onRemove} fontSize='small' color='secondary' /> 
                        </IconButton>
                    </Tooltip>
                    : null}
                </TableCell>:null
            }
        </TableRow>
    )
}
const AddUserToProject = ({params, addEditUsers, toggleModalFn, getResponseBack}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [isFetching, setFetching] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [search, setSearch] = useState('')
    const [usersInfo, setUsersInfo] = useState([]);
    const [noAcess, setNoAcess] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    useEffect(() => {
        getUserList({});
    },[]);
    const getUserList = (query) => {
        setFetching(true)
        globalGetService(`technical/v2/project/${params.project_slug}/users/dropdown/`,query)
        .then(response => {
            if(checkApiStatus(response)){
                setUsersInfo(response.data.data)
            }
            setFetching(false)
        })
    }
    const handleChange = (type, flag, id) => {
        if(type === 'bulk'){
            setSelectedUsers(flag ? usersInfo.map((item)=> item.user_id.id):[])
        }else{
            setSelectedUsers(flag ? [...selectedUsers, id]:selectedUsers.filter(item => item !== id))
        }
    }
    const onAddUsers = () => {
        setLoading(true);
        globalPostService(`technical/v2/project/${params.project_slug}/users/`, {user_ids:selectedUsers})
        .then(response => {
            if(checkApiStatus(response)){
                toggleModalFn(); getResponseBack();
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
            }
            setLoading(false);
        })
    }
    return(
        <Dialog
            open={addEditUsers.modal}
            onClose={toggleModalFn}
            className='project-management-modal'
            maxWidth='md'
        >
            <DialogTitle id="scroll-dialog-title">
                Add Users
            </DialogTitle>
            <DialogContent dividers={true}>
                <div style={{width:'640px'}}>
                    <div className="form-detail-card" style={{marginBottom:'8px'}}>
                        <TextField
                            placeholder='Search by User Name or Email'
                            name="name"
                            fullWidth
                            margin="none"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            InputLabelProps={{shrink: true}}
                            variant='outlined'
                        />
                    </div>
                    <Paper>
                        <div style={{height: !usersInfo.length ? 'auto':`${window.innerHeight-240}px`,overflow:'auto'}}>
                            <Table className='mui-table-format' stickyHeader>
                                <ProjectUserHd type='add' handleChange={handleChange} bulkFlag={usersInfo?.length ? usersInfo.length === selectedUsers.length:false} />
                                {(() => {
                                    let users = search?.trim()?.length ? usersInfo.filter((user) => user.user_id.name.toLowerCase().includes(search.toLocaleLowerCase()) || user.user_id.email.toLowerCase().includes(search.toLocaleLowerCase())):usersInfo;
                                    return(
                                        <TableBody>
                                            { users.map((item) => 
                                                <ProjectUserList 
                                                    type='add'
                                                    selectedUsers={selectedUsers}
                                                    item={item}
                                                    handleChange={(flag) => handleChange('single',flag, item.user_id.id)}
                                                />
                                            )}
                                            {isFetching ?
                                                <TableRow>
                                                    <TableCell align='center' colSpan={4}>
                                                        <div style={{ padding: '200px 0' }}>Loading...</div>
                                                    </TableCell>
                                                </TableRow> : users?.length ? null :
                                                <TableRow>
                                                    <TableCell align='center' colSpan={4}>
                                                        <div style={{ padding: '15px 0' }}>No Records Found</div>
                                                    </TableCell>
                                                </TableRow>
                                            }
                                        </TableBody>
                                    )
                                })()}
                            </Table>
                        </div>
                    </Paper>
                </div>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} onClick={toggleModalFn} color="primary" size="small">Cancel</Button>
                {selectedUsers?.length ?
                    <Button disabled={isLoading} onClick={onAddUsers} variant="contained" color="primary" size="small">
                        {isLoading ? <CircularProgress size={20} /> : 'SAVE' }
                    </Button>:null
                }
            </DialogActions>
        </Dialog>
    )
}
const ProjectUsers = ({params}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [filter, setFilter] = useState({});
    const [usersInfo, setUsersInfo] = useState({list:[], pagination:null});
    const [addEditUsers, setAddEditUsers] = useState({modal:false})
    const [deleteUsers, setDeleteUsers] = useState({data:[], modal:false});
    const [noAcess, setNoAcess] = useState(false);
    useEffect(() => {
        getUsersInfo({}, 'skeletonLoader');
        getProjectDetails()
    },[]);
    const handleChange = (type, flag, id) => {
        if(type === 'bulk'){
            setDeleteUsers({...deleteUsers, data:flag ? usersInfo?.list.map((item)=> item.id):[]})
        }else{
            setDeleteUsers({...deleteUsers, data: flag ? [...deleteUsers.data, id]:deleteUsers.data.filter(item => item !== id)})
        }
        
    }
    const getUsersInfo = (query, loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true):setLoading(true);
        globalGetService(`technical/v2/project/${params.project_slug}/users/`, query)
        .then(response => {
            if(checkApiStatus(response)){
                setUsersInfo(response.data.data);
                delete query.page;
                delete query.per_page;
                setFilter(query);
            }
            loaderType === 'skeletonLoader' ? setSkeletonLoader(false):setLoading(false);
        })
    }
    const getProjectDetails = (loaderType) => {
        globalGetService(`technical/project/${params.project_slug}/`)
        .then(res => {
            if(res.data.statusCode == 404){
                setNoAcess(true)
            }
        })
    }
    const onDeleteUsers = () => {
        setLoading(true);
        globalDeleteService(`technical/v2/project/${params.project_slug}/users/`, {ids:deleteUsers.data})
        .then(response => {
            if(checkApiStatus(response)){
                setDeleteUsers({modal:false, data:[]});
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
                getUsersInfo({}, 'pageLoader');
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
            }
            setLoading(false);
        });
    }
    const filterOptions = {
        'user_name':{
            'inputType': 'text',
            'title': 'Name'
        },
    }
    return(
        <section className='projects-management-apps projects-management-section'>
            {noAcess ? null :<ProjectDetailTab tabIndex={'project-users'} handleTabChange={() => console.log('')} />}
            { skeletonLoader ? <STableLoader count={4} />: noAcess? 
            <RestrictedAccess />
            :
                <>
                    <Paper style={{padding:'5px 10px'}} square>
                        <Grid container spacing={1}>
                            <Grid item md={8}>
                                <FilterComponent 
                                    filter={filter}
                                    filterMenu={filterOptions}
                                    getResponseBack={(applyFilter) => getUsersInfo({...applyFilter, page:1, per_page:usersInfo.pagination.per_page}, 'pageLoader')}
                                />
                            </Grid>
                            <Grid item md={4}>
                                <ul className='list-inline' style={{float:'right'}}>
                                    { deleteUsers?.data?.length &&  onCheckPermission('project_management', 'users','D') ? 
                                        <li className='list-inline-item'>
                                            <Button onClick={() => setDeleteUsers({...deleteUsers, modal:true})} color='secondary' size='small' variant='contained'>Remove User(s)</Button>
                                        </li>:null
                                    }
                                    { onCheckPermission('project_management', 'users','C') ?
                                    <li className='list-inline-item'>
                                        <Button onClick={() => setAddEditUsers({modal:true})} color='primary' size='small' variant='contained'>Add User</Button>
                                    </li>
                                    : null}
                                </ul>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper>
                    <div style={{height: !usersInfo.list.length ? 'auto':`${window.innerHeight-210}px`,overflow:'auto'}}>
                            <Table className='mui-table-format' stickyHeader>
                                <ProjectUserHd handleChange={handleChange} bulkFlag={usersInfo?.list?.length ?usersInfo.list.length === deleteUsers.data.length :false}/>
                                <TableBody>
                                    { usersInfo.list.map((item) => 
                                        <ProjectUserList 
                                            item={item}
                                            onRemove={() =>setDeleteUsers({modal:true, data:[item.id]})}
                                            selectedUsers={deleteUsers.data} 
                                            handleChange={(flag) => handleChange('single',flag, item.id)}
                                        />
                                    )}
                                </TableBody>
                            </Table>
                        </div>
                        <Pagination 
                            pagination={usersInfo.pagination}
                            onChangePage={(event, newPage) => getUsersInfo({...filter, page:newPage+1, per_page: usersInfo.pagination.per_page}, 'pageLoader')}
                            onChangeRowsPerPage={(event) => getUsersInfo({...filter, page:1, per_page: event.target.value}, 'pageLoader')}
                        />
                        { !usersInfo.list.length ? <EmptyCollection title="No records found" />:null }
                    </Paper>
                </>
            }
            { addEditUsers.modal ?
                <AddUserToProject 
                    addEditUsers={addEditUsers}
                    toggleModalFn={() => setAddEditUsers({modal:false})}
                    params={params}
                    getResponseBack={() => getUsersInfo({}, 'pageLoader')}
                />:null
            }
            { deleteUsers.modal ?
                <DeletePopUp
                    modal={deleteUsers.modal}
                    toggleModalFn={() => setDeleteUsers({data:[], modal:false})}
                    title="Remove User(s)"
                    content={<h4>Are you sure you want to remove?</h4>}
                    deleteRecordFn={onDeleteUsers}
                    confirmText={'Remove'}
                />:null
            }
            { isLoading ? <PageLoader />: null }
        </section>
    )
}
export default ProjectUsers;