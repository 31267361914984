import React from 'react';
import { imgStoragePath } from '../../../../../constants';
import editIcon from '../../../../../shared/assets/img/edit_icon.svg';
import viewIcon from '../../../../../shared/assets/img/view_icon.svg';
const FindingList = (props) => {
  return(
    <tr>
      <td>{props.index + 1}</td>
      <td>{props.item.finding_type ? props.item.finding_type:'--'}</td>
      <td>{props.item.remarks ? props.item.remarks: '--'}</td>
      <td>{props.item.classifications ?props.item.classifications :'--' }</td>
      <td>{props.item.category ?props.item.category :'--' }</td>
      <td>{props.item.repair_type ?props.item.repair_type :'--' }</td>
      <td className="finding-action-block">
        {
        props.formInfo && props.formInfo.current_form && props.formInfo.current_form.permissions.can_save ?
            <img style={{ cursor: 'pointer' }} onClick={props.findingCrudFn}  width="12" src={( editIcon )} alt="img"/>
        :null}
         { props.formInfo && props.formInfo.current_form && props.formInfo.current_form.permissions.can_save ?
           <img onClick={props.toggleDelModal} style={{ marginLeft: '22px', cursor: 'pointer'}} width="10" src={imgStoragePath + "del_icon.png"} alt="img"/>:null
         }

      </td>
    </tr>
  )
}
export default FindingList;
