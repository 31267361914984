import React from 'react'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'
import { Row, Col, Link, browserHistory } from 'react-router'
import { connect } from 'react-redux';
import { imgStoragePath } from '../../constants';
import { getLocalStorageInfo } from '../../utils'
class LicenceModal extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      showLicences: false
    }
  }

  componentDidMount(){

  }

  render(){
    return(
      <Modal id="buyLicenseModal" size="lg" style={{ minWidth: '500px' }} isOpen={false} centered={true} toggle={() => console.log('Hi')} >
        <img className="close-btn" src={imgStoragePath+'black_close.png'}  onClick={() => this.props.toggleLicence()} />
        <ModalBody>

        </ModalBody>
      </Modal>
    )
  }
}



export default connect(null, null)(LicenceModal)
