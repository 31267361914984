import React from 'react';
import { useSnackbar } from 'notistack';
import {DropzoneArea} from 'material-ui-dropzone';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { trackActivity } from '../../../utils/mixpanel';
const UploadPictures = ({uploadPictures, onUpload, toggleModalFn, generalPictures}) => {
    const [files, setFiles] = React.useState([]);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const handleFileUpload = (params) => {
        if (files.length > 0) {
            const section = generalPictures.list.find(section => section.id === uploadPictures.sectionId);
            if (section) {
                const maxLimit = parseInt(section.max_limit);
                if (files.length > maxLimit) {
                    enqueueSnackbar(`Max Limit of image to section is ${maxLimit?maxLimit:'reached'}`, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
                    trackActivity('Project Management', {
                        event_type: 'General Pictures Upload Failed',
                        page_title: 'T001 Form',
                        workOrder_slug: params.workOrderSlug ? params.workOrderSlug : '',
                        aircraft_section_id: uploadPictures.sectionId ? uploadPictures.sectionId : '',
                        section_name: uploadPictures.sectionName ? uploadPictures.sectionName : '',
                        error_msg: 'Max Limit of image to section is reached',
                        max_img_limit: maxLimit ? maxLimit : ''
                      })
                    return;
                }
            }
            onUpload(files);
        }
        toggleModalFn();
    };
    const handleDropzoneChange = (uploadedFiles) => {
      setFiles(uploadedFiles);
    };
    const section = generalPictures.list.find(section => section.id === uploadPictures.sectionId);
    const maxLimit = (section.max_limit);
    return(
        <Dialog
            open={uploadPictures.modal}
            onClose={toggleModalFn}
        >
            <DialogTitle id="scroll-dialog-title">
                Upload {uploadPictures.mode === 'file'?'Pictures':'Folder'}
            </DialogTitle>
            <DialogContent dividers={true}>
                <div style={{width:'380px'}}>
                    <DropzoneArea
                        filesLimit={maxLimit?maxLimit:30}
                        maxFileSize={104857600}
                        showPreviewsInDropzone={false}
                        useChipsForPreview={true}
                        acceptedFiles={['.png, .jpg, .jpeg , .heif , .heic']}
                        showPreviews={ true }
                        dropzoneText={ uploadPictures.mode === 'file' ? <p style={{fontSize:'13px'}}>Drag & Drop Documents<br/> OR <br/> Click Here<br/> </p>:<p>Drag & Drop folder here </p>}
                        dropzoneClass="drag-drop-cnt"
                        maxWidth="sm"
                        showAlerts={['info','error']}
                        alertSnackbarProps={{anchorOrigin: {vertical:'top', horizontal:'right'}}}
                        clearOnUnmount={true}
                        onChange={handleDropzoneChange} 
                    />
                </div>
                <span className='files-upload-limit' > Note : Max files upload limit is {maxLimit?maxLimit:30} in single time.</span>
            </DialogContent>
            <DialogActions>
                <Button onClick={toggleModalFn} color="primary" size='small' variant='outlined'>Cancel</Button>
                <Button onClick={handleFileUpload}  disabled={files.length > 0 ? false :true }color="primary" size='small' variant='contained'>Proceed</Button>
            </DialogActions>
        </Dialog>
    )
}
export default UploadPictures;