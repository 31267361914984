import React, { Component } from 'react';
import moment from 'moment';
import PopoverLeadInfo from '../Projects/components/PopoverLeadInfo';
import { ToolTipHover } from '../../../shared';
import { imgStoragePath } from '../../../constants';
export default class  SecondaryTechHeaderRH extends Component {
  render(){
    return(
      <ul className="list-inline flex-centered">
        <li className="list-inline-item lesse-block">
          <h6>Lessee</h6>
          <img id="lessee-tip" className="lessee-img" src={ this.props?.projectInfo?.lessee?.logo ? `${this.props.projectInfo.lessee.logo}` : imgStoragePath+'flight_common_icon.png'} alt="img"/>

        </li>
        <li className="list-inline-item">
          <h6>Project Start Date</h6>
          <p>{this.props?.projectInfo?.start_date ? moment(this.props.projectInfo.start_date).format('MMM DD, YYYY') : '--'}</p>
        </li>
        <li className="list-inline-item">
          <h6>Project End Date</h6>
          <p> { this.props?.projectInfo?.expected_end_date ? moment(this.props.projectInfo.expected_end_date).format('MMM DD, YYYY') : '--'}</p>
        </li>
      </ul>
    )
  }
}
