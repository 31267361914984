import React from 'react';
import {Paper} from '@material-ui/core';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
require('highcharts/modules/exporting')(Highcharts);
const UserStatsChart = ({graphDataUserStats}) => {
  return(
    <Paper>
      <HighchartsReact
        highcharts={Highcharts}
        options={graphDataUserStats}
      />
    </Paper>
  )
}
export default UserStatsChart;
