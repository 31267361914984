import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import {
  DeleteModal,
  PrerequisiteWrapper,
  EditFormBar,
  SecondaryTechHeaderRH,
  SecondaryTechHeaderMid,
  FormHeader,
  DownloadedLogs
} from '../../../Elements';
import { getFormDetailsAc, updateFormStatusAc } from '../../T001/actionCreators';
import * as actions from '../actions';
import { imgStoragePath } from '../../../../../constants';
import { downLoadTechEngineFormAc } from '../../../../../shared/actionCreators'
import { GenralDetail02Wrapper, AddItemWrapper, DataList, AccessoriesList, BulkOperation } from '../components';
import { fetchpowerPlantDetailsAc, updatepowerPlantDetailsAc, deletePowerPlantDetAc } from '../actionCreators';
import { ExportFiles, MSNSwitcherHeader, ListLoader, TableUIComp } from '../../../../../shared';
import { accessoriesData, additionalData } from '../../AddFormObjects';
import { checkValidDate, checkString } from '../../../../../formValidator';
import { trackActivity } from '../../../../../utils/mixpanel';
import { getLocalStorageInfo } from '../../../../../utils';
class InspectionFormTE002 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '1',
      preReqModal: false,
      delCompModal: false,
      deleteComponentIndex: '',
      deleteComponentTitle: '',
      deleteCompoId: '',
      deleteSection: '',
      downloadFormLogs: false,
      error: {}
    }
  }
  componentDidMount() {
    this.props.getFormDetails(2, 'TE002');
    this.props.fetchpowerPlantDetailsFn();
  }
  toggleDownloadedLogs = () => {
    this.setState(prevState => ({
      ...prevState,
      downloadFormLogs: !prevState.downloadFormLogs
    }))
  }
  togglePrerequisiteModal = () => {
    this.setState({
      preReqModal: !this.state.preReqModal,
    });
  }

  toggleComDelModal = (title = null, index = null, compoId = null, section = null) => {
    this.setState({
      delCompModal: !this.state.delCompModal,
      deleteComponentTitle: title,
      deleteComponentIndex: index,
      deleteCompoId: compoId,
      deleteSection: section
    });
  }

  scrollToBottom(value) {
    if (value == 'hardware') {
      this.hardwareEnd.scrollIntoView({ behavior: 'smooth' });
    } else if (value == 'additional') {
      this.additionalEnd.scrollIntoView({ behavior: 'smooth' });
    } else {
      this.accessoriesEnd.scrollIntoView({ behavior: 'smooth' });
    }

  }



  updatepowerPlantDetails = (powerPlantInvDet) => {
    const validateNewInput = {
      date_of_inspection: checkValidDate({ value: powerPlantInvDet.general_details.date_of_inspection, required: true, minLength: '', maxLength: '', message: 'Please enter valid date' }),
      place_of_inspection: checkString({ value: powerPlantInvDet.general_details.place_of_inspection, required: true, minLength: '', maxLength: '', message: 'Please enter Place Of Inspection' }),
      report_date: checkValidDate({ value: powerPlantInvDet.general_details.report_date, required: true, minLength: '', maxLength: '', message: 'Please enter valid date' }),
      report_issued_place: checkString({ value: powerPlantInvDet.general_details.report_issued_place, required: true, minLength: '', maxLength: '', message: 'Please enter Report Issued Place' }),
    };

    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      this.setState({
        error: {}
      });
      this.props.updatepowerPlantDetailsFn(powerPlantInvDet);
    } else {
      this.setState({
        error: validateNewInput
      });
      trackActivity('TE002', {
        event_type: 'Save Powerplant Inventory Failed',
        page_title: 'Powerplant Inventory List',
        workorder_slug: this.props.params.workOrderSlug ? this.props.params.workOrderSlug : '',
        form_slug: this.props.params.formSlug ? this.props.params.formSlug : '',
        error_source: 'Frontend',
        error_type: 'Validation',
        error_details: validateNewInput ? validateNewInput : '',
        asset_type: this.props.params.type ? this.props.params.type : '',
      })
    }
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  updateDetailForm = (data) => {
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        [data.type]: ''
      }
    }));
    this.props.updateForm(data);

  }

  trackTabChangeActivity = (type, event, tab) => {
    trackActivity('TE002', { event_type: type, page_title: 'Powerplant Inventory List', event_desc: event, tab_name: tab })
  }

  render() {
    const { addSideBar, powerPlantInvDet, powerPlantLoader, techExportLoader, formStatusLoader, formInfo } = this.props;
    if (!Object.keys(powerPlantInvDet).length) {
      return false
    }
    return (
      <div className="technical-inspection-cn">
        <MSNSwitcherHeader
          SecodaryHeaderRH={<SecondaryTechHeaderRH projectInfo={this.props.formInfo.project} />}
          SecondaryHeaderMid={<SecondaryTechHeaderMid projectInfo={this.props.formInfo.project} />}
          activeAsset={this.props.formInfo.asset}
          projectSlug={Object.keys(this.props.formInfo).length ? this.props.formInfo.project.slug : ''}
        />
        <FormHeader
          formInfo={this.props.formInfo}
          saveButton={<Link style={powerPlantLoader ? { pointerEvents: 'none', opacity: '0.5' } : { pointerEvents: 'auto', opacity: '1' }} onClick={() => this.updatepowerPlantDetails(powerPlantInvDet)} className="primary-btn"> {powerPlantLoader ? 'Processing...' : 'Save'} </Link>}
          exportReport={<ExportFiles exportFile={(fileType) => this.props.exportReport('get-powerplant-accessories', 'ESN_' + this.props.formInfo.asset.esn + '_' + this.props.formInfo.current_form.name, fileType)} exportName={'Export Report '} files={[{ img: 'pdf_icon.png', label: 'PDF', type: 'pdf' }]} />}
          updateFormStatus={(data) => this.props.updateFormStatus(data)}
          toggleDownloadedLogs={this.toggleDownloadedLogs}
        />
        <div className="technical-inspection-ee">
          <h4 className="tech-heading-block flex-centered">
            General Details
            <span onClick={() => { this.togglePrerequisiteModal(); trackActivity('TE002', { event_type: 'Clicked', page_title: 'Powerplant Inventory List', item_type: 'Read Prerequisite' }) }} className="pre-requisite flex-centered">

              <img src={imgStoragePath + "read_pre_icon.png"} alt="img" className="blue-img" />
              Read Prerequisite
            </span>
          </h4>
          <GenralDetail02Wrapper
            accessoriesDetails={powerPlantInvDet.general_details}
            updateForm={this.updateDetailForm}
            error={this.state.error}
          />
        </div>
        <div className="form-te002-cn">
          {formStatusLoader ||
            techExportLoader ?
            <ListLoader />
            : null
          }
          <div className="edit-view-tech-spec">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '1' })}
                  onClick={() => { this.toggle('1'); this.trackTabChangeActivity('Tab Clicked', 'Clicked on Accessories List Tab', 'Accessories List') }}
                >
                  Accessories List
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '2' })}
                  onClick={() => { this.toggle('2'); this.trackTabChangeActivity('Tab Clicked', 'Clicked on Additional Data Tab', 'Additional Data') }}
                >
                  Additional Data
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '3' })}
                  onClick={() => { this.toggle('3'); this.trackTabChangeActivity('Tab Clicked', 'Clicked on Missing Hardware Tab', 'Missing Hardware') }}
                >
                  Missing Hardware
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              {this.state.activeTab == '0' ?
                <Row>
                  <Col sm="12" className="text-right">
                    <span className="nav-item" style={{ float: 'right' }}>
                      <button className="btn btn-primary btn-sm add-new" onClick={() => this.props.addItemSideBar('')}>Add Item</button>
                    </span>
                  </Col>
                </Row> : null
              }
              <TabPane tabId="1">
                <div>
                  <TableUIComp
                    noStickyHeader={true}
                    hover={false}
                    tableHeight="auto"
                    tableBulkOp={ formInfo?.current_form?.permissions?.can_save?<BulkOperation addText="+Add Item" toggleSidebar={() => { this.props.addAccessories(accessoriesData); this.scrollToBottom('accessories') }} export={false} />: false}
                    filter={false}
                    theads={[{ label: 'ATA', sortKey: '' }, { label: 'Description', sortKey: '' }, { label: 'Delivery', sortKey: '', child: [{ label: 'P/N' }, { label: 'S/N' }] }, { label: 'Installed', sortKey: '', child: [{ label: 'P/N' }, { label: 'S/N' }] },
                    { label: 'TSN', sortKey: '' }, { label: 'CSN', sortKey: '' }, { label: 'Remarks', sortKey: '' }, { label: 'Action', sortKey: '' }]}
                    bulkOperation={false}
                    pagination={[]}
                    content={powerPlantInvDet.accessories_list.map((accessories, index) => <AccessoriesList userInfo={getLocalStorageInfo().user} key={index} index={index} content={accessories} updateForm={this.props.updateForm} toggleComDelModal={() => this.toggleComDelModal('Are you sure to delete?', index, accessories.id, 'accessories_list')} section={'accessories_list'} deleteSectionData={this.props.deleteSectionData} />)}
                    recordsFound={powerPlantInvDet.accessories_list.length ? false : true}
                  />
                </div>
                <div ref={el => { this.accessoriesEnd = el; }} />
              </TabPane>
              <TabPane tabId="2">
                <TableUIComp
                  noStickyHeader={true}
                  hover={false}
                  tableBulkOp={ formInfo?.current_form?.permissions?.can_save? <BulkOperation addText="+Add Item" toggleSidebar={() => { this.props.addAdditional(additionalData); this.scrollToBottom('additional') }} port={false} />: false}
                  filter={false}
                  tableHeight="auto"
                  theads={[{ label: 'Item No', sortKey: '' }, { label: 'Description', sortKey: '' }, { label: 'P/N', sortKey: '' }, { label: 'S/N', sortKey: '' },
                  { label: 'Remarks', sortKey: '' }, { label: 'Action', sortKey: '' }]}
                  bulkOperation={false}
                  pagination={[]}
                  content={powerPlantInvDet.additional_data.map((dataAction, index) => <DataList userInfo={getLocalStorageInfo().user} key={index} index={index} content={dataAction} updateForm={this.props.updateForm} toggleComDelModal={() => this.toggleComDelModal('Are you sure to delete?', index, dataAction.id, 'additional_data')} section={'additional_data'} deleteSectionData={this.props.deleteSectionData} />)}
                  recordsFound={powerPlantInvDet.additional_data.length ? false : true}
                />
                <div ref={el => { this.additionalEnd = el; }} />
              </TabPane>
              <TabPane tabId="3">
                <TableUIComp
                  noStickyHeader={true}
                  hover={false}
                  tableBulkOp={ formInfo?.current_form?.permissions?.can_save? <BulkOperation addText="+Add Item" toggleSidebar={() => { this.props.addHardware(additionalData); this.scrollToBottom('hardware') }} export={false} />: false}
                  filter={false}
                  tableHeight="auto"
                  theads={[{ label: 'ATA', sortKey: '' }, { label: 'Description', sortKey: '' }, { label: 'P/N', sortKey: '' }, { label: 'S/N', sortKey: '' },
                  { label: 'Remarks', sortKey: '' }, { label: 'Action', sortKey: '' }]}
                  bulkOperation={false}
                  pagination={[]}
                  content={powerPlantInvDet.missing_hardware.map((missingHardware, index) => <DataList userInfo={getLocalStorageInfo().user} key={index} index={index} toggleComDelModal={() => this.toggleComDelModal('Are you sure to delete?', index, missingHardware.id, 'missing_hardware')} content={missingHardware} updateForm={this.props.updateForm} section={'missing_hardware'} deleteSectionData={this.props.deleteSectionData} />)}
                  recordsFound={powerPlantInvDet.missing_hardware.length ? false : true}
                />
                <div ref={el => { this.hardwareEnd = el; }} />
              </TabPane>

            </TabContent>
            <EditFormBar title="Add Item" toggleEditSideBar={this.props.addItemSideBar} isOpen={addSideBar} style={addSideBar ? { width: '600px' } : { width: '0px' }}>
              <AddItemWrapper />
              <div className="editSideBar-footer">
                <button className="btn btn-primary" onClick={() => ''}>Save</button>
                <button className="btn btn-default" onClick={() => this.props.addItemSideBar('')}>Cancel</button>
              </div>
            </EditFormBar>
          </div>
        </div>

        <DeleteModal isOpen={this.state.preReqModal}
          toggle={() => this.togglePrerequisiteModal()}
          title={'Prerequisite'} className="modal-tform-delete-pictures modal-dialog-centered pre-requsite-modal">
          <PrerequisiteWrapper form={'te002'} />
        </DeleteModal>

        <DeleteModal isOpen={this.state.delCompModal}
          toggle={() => this.toggleComDelModal('')}
          title={this.state.deleteComponentTitle}
          className="modal-tform-delete-pictures modal-dialog-centered">
          <ul className="list-inline">
            <li className="list-inline-item">
              <button type="button" className="btn btn-danger" onClick={() => { this.toggleComDelModal(''); this.props.removepowerPlantDet(this.state.deleteComponentIndex, this.state.deleteCompoId, this.state.deleteSection) }}>Yes</button>
            </li>
            <li className="list-inline-item">
              <button type="button" className="btn btn-primary" onClick={() => this.toggleComDelModal('')}>No</button>
            </li>
          </ul>
        </DeleteModal>
        <EditFormBar
          titleContent={<div><h3 style={{ textTransform: 'capitalize' }}>Downloaded Logs</h3></div>}
          toggleEditSideBar={this.toggleDownloadedLogs}
          isOpen={this.state.downloadFormLogs}
          style={this.state.downloadFormLogs ? { width: '400px' } : { width: '0px' }}
        >
          {this.state.downloadFormLogs ?
            <DownloadedLogs logs={formInfo.current_form.download_report} />
            : null
          }
        </EditFormBar>
        {
          powerPlantLoader ?
            <ListLoader /> : null}

      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  formInfo: state.FormT001Reducer.formInfo,
  addSideBar: state.FormTE002Reducer.addSideBar,
  powerPlantInvDet: state.FormTE002Reducer.powerPlantInvDet,
  powerPlantLoader: state.FormTE002Reducer.powerPlantLoader,
  techExportLoader: state.sharedReducers.techExportLoader,
  formStatusLoader: state.FormT001Reducer.formStatusLoader
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getFormDetails: (type, application) => dispatch(getFormDetailsAc(ownProps, type, application)),
    fetchpowerPlantDetailsFn: () => dispatch(fetchpowerPlantDetailsAc(ownProps)),
    updateFormStatus: (data) => dispatch(updateFormStatusAc(ownProps, data, 2)),
    exportReport: (url, name, fileType) => dispatch(downLoadTechEngineFormAc(url, ownProps.params.workOrderSlug, fileType, name)),
    removepowerPlantDet: (compoIndex, compoId, section) => dispatch(deletePowerPlantDetAc(ownProps, compoIndex, compoId, section)),
    updatepowerPlantDetailsFn: (data) => dispatch(updatepowerPlantDetailsAc(ownProps, data)),

    addItemSideBar: () => dispatch({
      type: actions.ADD_ITEM_BAR,
      payload: {}
    }),
    updateForm: (data) => dispatch({
      type: actions.UPDATE_TE002_FORM,
      payload: data
    }),
    addAccessories: (accessoriesData) => dispatch({
      type: actions.ADD_DYNAMIC_ACCESSORIES,
      payload: accessoriesData
    }),
    addAdditional: (addtionlaData) => dispatch({
      type: actions.ADD_DYNAMIC_ADDITIONAL,
      payload: addtionlaData
    }),
    addHardware: (hardwareData) => dispatch({
      type: actions.ADD_DYNAMIC_HARDWARE,
      payload: hardwareData
    }),

  }
}
export default connect(mapStateToProps, mapDispatchToProps)(InspectionFormTE002);
