
import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import { imgStoragePath } from '../../constants'
import { Row, Col } from 'reactstrap';
export default class DeploymentMessage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            deploymentFlag: false
        }
    }
    render() {
        const { deploymentFlag } = this.state
        let marginVal = this.props.margin
        return (
            <>
                {
                    deploymentFlag ?
                        this.props.row ?
                            <Row style={this.props.margin ? { marginTop: marginVal } : null}>
                                <Col md="12">
                                    <p className="deployment-info">
                                        {/* <span >SPARTA will be under heavy maintenance activity from <h6>23rd Dec UTC 18:30 hrs to 25th Dec UTC 18:30 hrs </h6>. Upload functionalities will be impacted during these days. Apologies for the inconvenience caused.</span> */}
                                        <span>Please be informed that the hosting server provider for SPARTA has scheduled a maintenance activity from 11 July 2024, 21:00 (UTC) to 12 July 2024, 09:00 (UTC). During this period, access to SPARTA may be intermittent.</span>
                                        <span >
                                            <img width="13" onClick={() => { this.setState({ deploymentFlag: false }) }} src={imgStoragePath + "toast_cross.png"} alt="Close Icon"/>
                                        </span>
                                    </p>
                                </Col>
                            </Row>
                            :
                            <Grid item sm="12">
                                <p className="deployment-info">
                                    {/* <span >SPARTA will be under heavy maintenance activity from <h6>23rd Dec UTC 18:30 hrs to 25th Dec UTC 18:30 hrs </h6>. Upload functionalities will be impacted during these days. Apologies for the inconvenience caused.</span> */}
                                    <span>Please be informed that the hosting server provider for SPARTA has scheduled a maintenance activity from 11 July 2024, 21:00 (UTC) to 12 July 2024, 09:00 (UTC). During this period, access to SPARTA may be intermittent.</span>
                                    <span >
                                        <img width="13" onClick={() => { this.setState({ deploymentFlag: false }) }} src={imgStoragePath + "toast_cross.png"} alt="Close Icon"/>
                                    </span>
                                </p>
                            </Grid>
                        :
                        null}
            </>
        )
    }
}