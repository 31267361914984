import React, { Component } from 'react';
import { Link } from 'react-router';
import { imgStoragePath } from '../../../../constants';

export default class AddUserType extends Component{
  constructor(props){
    super(props);
  }
  render(){
    const { selectedUserIds } = this.props;
    return(
      <div className="add-user-types">
        <input type="text" placeholder="Search..." onChange={(evt) => this.props.searchUser(evt.target.value)}/>
        <div className="sidebar-form" style={{ height: (window.innerHeight - 120) + 'px', padding:'0px 25px 85px 23px'}}>
          <ul className="list-unstyled">
            { this.props.userList && this.props.userList.length ?
              this.props.userList.map((user,index) =>
              <li key={index} className="flex-centered" onClick = {() => this.props.selectedUser(user.id)} >
                {
                  user.profile_pic ?
                  <img className="user-img" src={user.profile_pic} alt="" />
                  :  <img className="user-img" src={imgStoragePath+ 'user.png'} alt="" />

                }
                <span>{user.name ? user.name : '--'}</span>
                {
                  selectedUserIds && selectedUserIds.includes(user.id) ?
                  <img className="checkbox-img" src={imgStoragePath + 'select_user_checked.png'} alt="img"/>
                  : <b className="unselected-checkbox"></b>
                }

              </li>
            ) : <li className="flex-centered">No result found</li>}
          </ul>
        </div>
        <div className="edit-sidebar-footer">
          {
            this.props.actionType == 'addEngineerToForm' ?
              <div className="add-eng-to-forms"><label><input type="checkbox" disabled={this.props.selectedUserIds.length ? '' : 'disabled'} onClick={(evt) => this.props.addToAllFormStatus(evt.target.checked)}/><span style={{marginLeft:'-6px'}}>Assign to all Forms</span></label></div>
            :null
          }
          <ul className="list-inline">
            <li className="list-inline-item">
              {
                this.props.actionType == 'change' ?
                  <button disabled={this.props.selectedUserIds.length ? '' : 'disabled'} onClick={() => {this.props.changeUser();this.props.toggleEditSideBar();}} type="submit" className="btn btn-primary">Save</button>
                :null
              }
              {
                this.props.actionType == 'addEngineer' ?
                  <button disabled={this.props.selectedUserIds.length ? '' : 'disabled'} onClick={() => {this.props.addEngineers(this.props.selectedUserIds, 2, null);this.props.toggleEditSideBar();}} type="submit" className="btn btn-primary">Save</button>
                :null
              }
              {
                this.props.actionType == 'addLead' ?
                  <button disabled={this.props.selectedUserIds.length ? '' : 'disabled'} onClick={() => {this.props.addLeads(this.props.selectedUserIds, 1, null);this.props.toggleEditSideBar();}} type="submit" className="btn btn-primary">Save</button>
                :null
              }
              {
                this.props.actionType == 'addCurrentLessee' ?
                  <button disabled={this.props.selectedUserIds.length ? '' : 'disabled'} onClick={() => {this.props.addLeads(this.props.selectedUserIds, 3, 1);this.props.toggleEditSideBar();}} type="submit" className="btn btn-primary">Save</button>
                :null
              }
              {
                this.props.actionType == 'addNextLessee' ?
                  <button disabled={this.props.selectedUserIds.length ? '' : 'disabled'} onClick={() => {this.props.addLeads(this.props.selectedUserIds, 3, 2);this.props.toggleEditSideBar();}} type="submit" className="btn btn-primary">Save</button>
                :null
              }
              {
                this.props.actionType == 'addMember' ?
                  <button disabled={this.props.selectedUserIds.length ? '' : 'disabled'} onClick={() => {this.props.addLeads(this.props.selectedUserIds, 3, null);this.props.toggleEditSideBar();}} type="submit" className="btn btn-primary">Save</button>
                :null
              }
              {
                this.props.actionType == 'addEngineerToForm' ?
                  <button disabled={this.props.selectedUserIds.length ? '' : 'disabled'} onClick={() => {this.props.addEngineerToForm(this.props.selectedUserIds, this.props.formSlug, this.props.projectSlug, this.props.addToAllForm,this.props.workAssetType);this.props.toggleEditSideBar();}} type="submit" className="btn btn-primary">Save</button>
                :null
              }
            </li>
            <li className="list-inline-item">
              <Link onClick={this.props.toggleEditSideBar}> Cancel</Link>
            </li>
           </ul>
        </div>
      </div>
    )
  }
}
