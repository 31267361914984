import React, { Component } from 'react';
import {  Row, Col } from 'reactstrap';
import PlacesAutocomplete from 'react-places-autocomplete';
import { TextInputField,DateInputField, GetInputField } from '../../../Elements';
import { geocodeByAddress, geocodeByPlaceId, getLatLng,
} from 'react-places-autocomplete';
import { getLocalStorageInfo } from '../../../../../utils';

export default class GenralDetail01Wrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      borescopeDetails: {props},
    }
  }

  handleChange = (address, keyParam) => {
    this.props.updateForm({type: keyParam, value: address, sectionType: 'genDetails'})
  };

  handleSelect = (address, keyParam) => {
    let location = geocodeByAddress(address);
    this.props.updateForm({type: keyParam, value: address, sectionType: 'genDetails'})
    location.then(results =>
      getLatLng(results[0]))
      .then(latLng =>
        this.setState(prevState => ({
          ...prevState,
          project: {
            ...prevState.project,
            location: address,
            lat: latLng['lat'],
            lang: latLng['lng'],
          }

        })))
      .catch(error => console.error('Error', error));
    location.then(results =>
      results[0]
    ).then(country => {
      let place = country['address_components'].filter((data, index) => data['types'][0] == 'country');
      this.setState(prevState => ({
        ...prevState,
        project: {
          ...prevState.project,
          country: place.length ? place[0]['long_name'] : address
        }
      }))
    })
      .catch(error => console.error('Error', error));

    location.then(results =>
      results[0]
    ).then(country => {
      let place = country['address_components'].filter((data, index) => data['types'][0] == 'country');
      this.setState(prevState => ({
        ...prevState,
        project: {
          ...prevState.project,
          country_code: place.length ? place[0]['short_name'] : address
        }
      }))
    }

    )
      .catch(error => console.error('Error', error));
  };

  render() {
  const { borescopeDetails, error } = this.props;
  let userInfo = getLocalStorageInfo().user
  return(
    <div className="general-detail-block" style={{background: '#fff'}}>
      <Row>
       <Col sm="4" className="dropdown-margin" style={{marginBottom: '40px'}}>
           {
            GetInputField(
              'dropdown',
              <label className="for-label">Type of Report <sup className="reqStar"> * </sup></label>,
              { type: 'edit',
                value: borescopeDetails.type_of_report,
                error: error && error.type_of_report ? error.type_of_report : '',
                labelKey:'label',
                valueKey: 'value',
                options: [{label: 'Witness', value: 1},{label: 'Review', value: 2}],
                updateForm: (value) => this.props.updateForm({type: 'type_of_report', value: value, sectionType: 'genDetails'})
              }
            )
          }
         </Col>
         {/* <Col sm="4"  style={{marginBottom: '40px'}}>
           {
            GetInputField(
              'string',
              <label className="for-label">Place of Inspection <sup className="reqStar"> * </sup></label>,
              { type: 'edit',
              value: borescopeDetails.place_of_inspection,
              error: error && error.place_of_inspection ? error.place_of_inspection : '',
              updateForm: (value) => this.props.updateForm({type: 'place_of_inspection', value: value, sectionType:'genDetails'})
              }
            )
          }
         </Col> */}
        <Col md="4" style={{ marginBottom: '40px' }}>
          {
            userInfo?.permission['technical']['projects']?.indexOf('C') != '-1' ?
            <><label className="for-label">Place of Inspection <sup className="reqStar"> * </sup></label>
          <PlacesAutocomplete
            value={borescopeDetails.place_of_inspection}
            error={error && error.place_of_inspection ? error.place_of_inspection : ''}
            onChange={(address) => this.handleChange(address, 'place_of_inspection')}
            onSelect={(address) => this.handleSelect(address, 'place_of_inspection')}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
              <div style={{ position: 'relative' }}>
                <input
                  {...getInputProps({
                    placeholder: 'Search Location',
                    className: 'location-search-input',
                  })}
                  style={{width: '100%', borderBottom: '1px solid #d7d7d7'}}
                />
                { borescopeDetails.place_of_inspection ?
                  <div className="autocomplete-dropdown-container" style={{ position: 'absolute', top: '38px', width: '100%', zIndex: 100 }}>
                    {loading && <div>Loading...</div>}
                    {suggestions.map(suggestion => {
                      const className = suggestion.active
                        ? 'suggestion-item--active'
                        : 'suggestion-item';
                      const style = suggestion.active
                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div> : null
                }
              </div>
            )}
          </PlacesAutocomplete>
          </>
          :
          GetInputField(
            'string',
            <label className="for-label">Place of Inspection</label>,
            { type: 'edit',
             value: borescopeDetails?.place_of_inspection,
             error: error && error.place_of_inspection ? error.place_of_inspection : '',
             updateForm: (value) => this.props.updateForm({ type:'place_of_inspection', value: value, sectionType: 'genDetails'})
            }
          )
              }
          <h6 className="error-msg" >{error.place_of_inspection}</h6>
        </Col>

         <Col sm="4"  style={{marginBottom: '40px'}}>
           {
            GetInputField(
              'string',
              <label className="for-label">AMM Revision No <sup className="reqStar"> * </sup></label>,
              { type: 'edit',
               value: borescopeDetails.amm_revision_number,
               error: error && error.amm_revision_number ? error.amm_revision_number : '',
               updateForm: (value) => this.props.updateForm({ type:'amm_revision_number', value: value, sectionType: 'genDetails'})
              }
            )
          }
         </Col>

         <Col sm="4"  style={{marginBottom: '40px'}}>
           {
            GetInputField(
              'date',
              <label className="for-label">AMM Revision Date<sup className="reqStar"> * </sup></label>,
              { type: 'edit',
                value: borescopeDetails.amm_revision_date,
                error: error && error.amm_revision_date ? error.amm_revision_date : '',
                updateForm: (value) => this.props.updateForm({type: 'amm_revision_date', value: value, sectionType: 'genDetails'})
              }
            )
          }
         </Col>

         <Col sm="4"  style={{marginBottom: '40px'}}>
           {
            GetInputField(
              'date',
              <label className="for-label">Date of Inspection From<sup className="reqStar"> * </sup></label>,
              { type: 'edit',
                value: borescopeDetails.date_of_inspection_from,
                error: error && error.date_of_inspection_from ? error.date_of_inspection_from : '',
                updateForm: (value) => this.props.updateForm({ type: 'date_of_inspection_from', value: value, sectionType: 'genDetails'})
              }
            )
          }
         </Col>

         <Col sm="4"  style={{marginBottom: '40px'}}>
           {
            GetInputField(
              'date',
              <label className="for-label">Date of Inspection To</label>,
              { type: 'edit',
              value: borescopeDetails.date_of_inspection_to,
              minDate: borescopeDetails.date_of_inspection_from,
              error: error && error.date_of_inspection_to ? error.date_of_inspection_to : '',
              updateForm: (value) => this.props.updateForm({type: 'date_of_inspection_to', value: value, sectionType: 'genDetails'})
              }
            )
          }
         </Col>

         <Col sm="4"  style={{marginBottom: '40px'}}>
           {
            GetInputField(
              'date',
              <label className="for-label">Report Date<sup className="reqStar"> * </sup></label>,
              { type: 'edit',
               value: borescopeDetails.report_date,
               error: error && error.report_date ? error.report_date : '',
               updateForm: (value) => this.props.updateForm({ type: 'report_date', value: value, sectionType: 'genDetails'})
              }
            )
          }
         </Col>

         <Col sm="4"  style={{marginBottom: '40px'}}>
         {
            userInfo?.permission['technical']['projects']?.indexOf('C') != '-1' ?
            <>
          <label className="for-label">Report Issued Place<sup className="reqStar"> * </sup></label>
          <PlacesAutocomplete
            value={borescopeDetails.report_issued_place}
            error={error && error.report_issued_place ? error.report_issued_place : ''}
            onChange={(address) => this.handleChange(address, 'report_issued_place')}
            onSelect={(address) => this.handleSelect(address, 'report_issued_place')}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
              <div style={{ position: 'relative' }}>
                <input
                  {...getInputProps({
                    placeholder: 'Search Location',
                    className: 'location-search-input',
                  })}
                  style={{width: '100%', borderBottom: '1px solid #d7d7d7'}}
                />
                { borescopeDetails.report_issued_place ?
                  <div className="autocomplete-dropdown-container" style={{ position: 'absolute', top: '38px', width: '100%', zIndex: 100 }}>
                    {loading && <div>Loading...</div>}
                    {suggestions.map(suggestion => {
                      const className = suggestion.active
                        ? 'suggestion-item--active'
                        : 'suggestion-item';
                      const style = suggestion.active
                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div> : null
                }
              </div>
            )}
          </PlacesAutocomplete>
          </>
          :
          GetInputField(
            'string',
            <label className="for-label">Report Issued Place</label>,
            { type: 'edit',
             value: borescopeDetails?.report_issued_place,
             error: error && error.report_issued_place ? error.report_issued_place : '',
             updateForm: (value) => this.props.updateForm({ type:'report_issued_place', value: value, sectionType: 'genDetails'})
            }
          )
              }
         </Col>
      </Row>
    </div>
    );
  }
}
