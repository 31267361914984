import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { ContentDrop, ImageCard, SortableImageCard, ImageListRow } from '../../../Elements';
import { SortableContainer } from 'react-sortable-hoc';

class BoroInspectionPictures extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dragOver: false
		}
	}

	hideOverlay = () => {

	}

	onDragOver = (evt) => {
		evt.stopPropagation();
		evt.preventDefault();
		this.setState({
			dragOver: true,
		});
	}

	onFocusOut = () => {
		this.setState({
			dragOver: false,
		})
	}

	onDrop = (evt) => {
		evt.stopPropagation();
		evt.preventDefault();
		this.setState({
			dragOver: false,
		})
	}

	render() {
		const { inspectionPicture, userInfo } = this.props;
		return (
			<div className={'inspection-pictures ' + this.props.classCustom} onDrop={(evt) => this.onDrop(evt)} onDragOver={(evt) => this.onDragOver(evt)} onDragLeave={() => this.onFocusOut()}>
				<Row>
					<Col className="image-row" key="" md="12">
						<ul className="list-inline image-list-block">
							{
								inspectionPicture.map((pictureData, index) =>
									<SortableImageCard
										key={index}
										index={index}
										picture={pictureData}
										toggleDelModal={() => this.props.toggleDelImageModal('Are you sure to delete?', pictureData.id, index)}
										editImage={() => this.props.editImageFn({ flag: true, data: { image: pictureData.image, id: pictureData.id } })}
										openSlider={() => this.props.openSlider(inspectionPicture, index)}
										// // changePicture= {(files) => this.uploadLopaImage(files)}
										options={userInfo.permission['technical']['projects'].indexOf('C') != '-1'? ['E', 'R', 'D', 'V'] : ['V']}
										updateForm={(description, value) => this.props.updateForm({ type: 'description', value: value, sectionType: 'inspectionPicture', index: index })}
										// hideOverLay = {this.hideOverlay}
										// customStyle = {this.props.classCustom ?  {height: '250px', overflow: 'hidden'}: {height: 'auto', overflow: 'auto'}}
										classCustom={this.props.classCustom}

									/>
								)
							}
							{
							userInfo.permission['technical']['projects'].indexOf('C') != '-1'?
							<li className="list-inline-item dropable-zone"
								onDrop={(evt) => { evt.preventDefault(); this.props.uploadImageFn(evt.dataTransfer.files) }}
								style={this.state.dragOver
									? { background: 'rgba(0,0,0,0.7)', verticalAlign: 'top' } : { background: 'transparent', verticalAlign: 'top' }}
							>
								<ContentDrop
									acceptExtensions={'image/*'}
									mutlipleEnabled={true}
									filesUpload={(e) => { e.preventDefault(); this.props.uploadImageFn(e.target.files) }}
								/>
								<span className="img-info-msg">
									Recommended Picture dimension 400 X 400 Pixels
								</span>
							</li>
							: null}
						</ul>
					</Col>
				</Row>
			</div>
		)
	}
}

export default SortableContainer(BoroInspectionPictures);
