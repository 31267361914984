import { userAssetReducer } from '../User';
 import { DashboardReducer } from '../applications/dashboards';
 import {
   PorjectsReducer,
   FormT001Reducer,
   FormT002Reducer,
   FormT003Reducer,
   FormT004Reducer,
   FormT005Reducer,
   FormT006Reducer,
   FormT007Reducer,
   FormT008Reducer,
   FormT009Reducer,
   FormT011Reducer,
   FormTE001Reducer,
   FormTE002Reducer,
   FormTE003Reducer,
   FormTE004Reducer,
   FormTE005Reducer,
   FormTE006Reducer,
   TrackerReducer,
   ProgramPlannerReducer,
   FormReviewReducer

 } from '../applications/technicalInspection';
import { sharedReducers } from '../shared';
import globalSearchReducer  from '../shared/GlobalSearch/reducer';
import { combineReducers } from 'redux';
const rootReducer = combineReducers({
  userAssetReducer,
  sharedReducers,
  globalSearchReducer,
  PorjectsReducer,
  FormT001Reducer,
  FormT002Reducer,
  FormT003Reducer,
  FormT004Reducer,
  FormT005Reducer,
  FormT006Reducer,
  FormT007Reducer,
  FormT008Reducer,
  FormT009Reducer,
  FormT011Reducer,
  FormTE001Reducer,
  FormTE002Reducer,
  FormTE003Reducer,
  FormTE004Reducer,
  FormTE005Reducer,
  FormTE006Reducer,
  DashboardReducer,
  TrackerReducer,
  ProgramPlannerReducer,
  FormReviewReducer,
});
export default rootReducer;
