import React from 'react';
import { imgStoragePath } from '../../../../../constants';
import editIcon from '../../../../../shared/assets/img/edit_icon.svg';
import viewIcon from '../../../../../shared/assets/img/view_icon.svg';
import deleteIcon from '../../../../../shared/assets/img/delete_icon.svg';
const List = (props) => {
  return (
    <tr className={ props.selectedIds.includes(props.data.id) ? 'selected-row': ''}>
      { props.formInfo && props.formInfo.current_form && props.formInfo.current_form.permissions.can_save ?
        <td className="check-opt">
          <input checked={props.selectedIds.includes(props.data.id)} onChange={(e) => props.selectItem(e)} type="checkbox" />
        </td>:null
      }
      <td>{props.data.full_name ? props.data.full_name : '--'}</td>
      <td>{props.data.email ? props.data.email : '--' }</td>
      <td>{props.data.title ? props.data.title  : '--'}</td>
      <td>{ props.data.phone_number ? props.data.phone_number: '--'}</td>
      <td>{ props.data.fax ? props.data.fax: '--'}</td>
      <td className="crud-block">
        <ul className="list-inline table-action-col">
          <li className="list-inline-item" onClick={() => props.rowActivity('view')}> <img src={viewIcon} width="22" /></li>
          { props.formInfo && props.formInfo.current_form && props.formInfo.current_form.permissions.can_save ?
            <li className="list-inline-item" onClick={() => props.rowActivity('edit')}> <img src={editIcon} width="20" /></li>:null
          }
          {
            props.formInfo && props.formInfo.current_form && props.formInfo.current_form.permissions.can_save ?
            <li className="list-inline-item" onClick={() => props.rowActivity('delete')}> <img src={deleteIcon} width="15" /></li>:null
          }
        </ul>
      </td>
    </tr>
  )
}
export default List;
