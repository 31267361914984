import React, { Component } from 'react';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import { imgStoragePath } from '../../../../constants'
import InfoIcon from '../../../../shared/assets/img/light_info_icon.svg';
class StatusDetails extends Component{
  constructor(props){
    super(props)
    this.state = {
      popoverOpen: false
    }
  }

  togglePopover = () =>{
    this.setState({
      popoverOpen: !this.state.popoverOpen
    })
  }

  render(){
    return(
      <div className="status-details" style={{ display : 'inline' }}>
        <img style={{ position: 'relative', marginLeft : '5px' }} width="20" src={InfoIcon} id="status_detail" onClick={() => this.togglePopover()}/>
        <Popover className="status-details-popover" placement="bottom" isOpen={this.state.popoverOpen} target="status_detail" toggle={() => this.togglePopover()}>
            <PopoverHeader>COR Rating</PopoverHeader>
            <PopoverBody>
              <ul>
                <li>1 - Very Poor</li>
                <li>2 - Poor</li>
                <li>3 - Good</li>
                <li>4 - Very Good</li>
                <li>5 - Excellent</li>
              </ul>
            </PopoverBody>
        </Popover>
      </div>
    )
  }
}

export default StatusDetails
