import React, { Fragment, useState } from "react"
import { withRouter } from 'react-router';
import { useSnackbar } from "notistack"
import { DropzoneArea } from 'material-ui-dropzone';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Tooltip, } from "@material-ui/core"
import { KeyboardDatePicker, MuiPickersUtilsProvider, } from "@material-ui/pickers"
import { backendDateFormat, fieldDateFormat } from "../../../constants"
import MomentUtils from "@date-io/moment"
import { issueForm } from "..";
import { Autocomplete } from "@material-ui/lab";
import moment from "moment";
import { globalGetService, globalPostService} from "../../../utils_v2/globalApiServices";
import { checkApiStatus, onCheckPermission } from "../../../utils_v2";
import { trackActivity } from '../../../utils/mixpanel';
const fileTypes = [ ".GIF", ".PDF", ".DOC", ".DOCX", ".XLSX", ".TXT", ".XLS", "image/*", ]
const AddIssue = ({ params, addEditIssue, issuesUsers, pltConstants, stages,  workOrders, milestones, toggleModalFn, getResponseBack}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [isLoading, setLoading] = useState(false);
  const [formData, setFormData] = useState(addEditIssue.issue ? addEditIssue.issue:issueForm)
  const [projectForm, setProjectForm] = useState([])
  const [error, setError] = useState([{}])
  const onFieldChange = (keyParam, value) => {
    if (keyParam == "work_order") {
      if(value){
        globalGetService(`technical/project/${params.project_slug}/workorders/${value.slug}/asset-type/${value.asset_type}/forms/`)
        .then(response => {
          if(checkApiStatus(response)){
            setProjectForm(response.data.data)
          }
        });
        setFormData({ ...formData, [keyParam]: value})
      }else{
        setProjectForm([]);
        setFormData({ ...formData, [keyParam]: value, form:null})
      }
      
    }else{
      setFormData({ ...formData, [keyParam]: value})
    }
  }
  const onRestErrorKey = (keyParam) => {
    setError({ ...error, [keyParam]: "" })
  }
  const onAddEditIssue = () => {
    let validationInputs = {
      name:formData.name ? '':'Please enter Name'
    }
    if ( Object.keys(validationInputs).every((k, v) => { return validationInputs[k] === "" }) ) {
      let addFormData = new FormData()
      Object.keys(formData).map((item) => {
        if (typeof formData[item] == "object" && item != "files") {
          addFormData.append(item, JSON.stringify(formData[item]))
        } else if (item === "files") {
          let index = 1
          for (let file of formData.files) {
            addFormData.append("file_" + index, file)
            index++
          }
        } else {
          addFormData.append(item, formData[item])
        }
      });
      setLoading(true);
      globalPostService( `technical/project/${params.project_slug}/issue/`, addFormData, true )
      .then((response) => {
        setLoading(false);
        if(checkApiStatus(response)) {
          enqueueSnackbar(response.data.message, { variant: "success", anchorOrigin: { horizontal: "right", vertical: "top" }, })
          toggleModalFn();getResponseBack();
          trackActivity('Task Management', {
            event_type: 'Add Issue Success',
            page_title: 'Add Issue',
            project_slug: params.project_slug,
            request_body: addFormData,
            response_msg: response.data.message
          })
        }else{
          enqueueSnackbar(response.data.message, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "top" }, })
          trackActivity('Task Management', {
            event_type: 'Add Issue Failed',
            page_title: 'Add Issue',
            project_slug: params.project_slug,
            request_body: addFormData,
            error_source: 'Backend',
            error_message: response.data.message
          })
        }
      })
    }else{
      setError(validationInputs)
      trackActivity('Task Management', {
        event_type: 'Add Issue Failed',
        page_title: 'Add Issue',
        project_slug: params.project_slug,
        error_source: 'Frontend',
        error: validationInputs ? validationInputs : ''
      });
    }
  }
  return (
    <Fragment>
      <Dialog
        className="project-management-modal"
        open={addEditIssue.modal}
        onClose={toggleModalFn}
        maxWidth={"sm"}
      >
        <DialogTitle>{addEditIssue.issue ? 'Edit':'Add'} Issue</DialogTitle>
        <DialogContent dividers={true}>
            <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                    <TextField
                      id="name"
                      fullWidth
                      required
                      label="Name"
                      margin="normal"
                      inputProps={{ maxLength: 255 }}
                      value={formData.name}
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      onChange={(e, value) => onFieldChange( "name", e?.target.value?.trim()?.length ? e.target.value: '')}
                      error={error.name ? true : false}
                      helperText={error.name ? error.name : ""}
                      onFocus={() => onRestErrorKey("name")}
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <TextField
                      id="description"
                      fullWidth
                      multiline
                      minRows={3}
                      label="Description"
                      margin="normal"
                      value={formData.description}
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      onChange={(e, value) => onFieldChange("description", e.target.value) }
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                          margin="normal"
                          id="due_date"
                          label="Due Date"
                          format={fieldDateFormat}
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          value={formData.due_date?formData.due_date:null}
                          onChange={(data, value) => { onFieldChange( "due_date", data ? moment(data).format(backendDateFormat) : data ) }}
                          inputVariant="outlined"
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Autocomplete
                        options={issuesUsers}
                        getOptionLabel={(option) => option.name}
                        id="assign"
                        value={formData.assign}
                        onChange={(e, value) => onFieldChange("assign", value)}
                        renderInput={(params) => ( <TextField onFocus={() => onRestErrorKey("assign")} error={error.assign ? true : false} helperText={error.assign ? error.assign : ""} {...params} label="Assign" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant="outlined" /> )}
                    />
                    </Grid>
                    <Grid item xs={12} md={6}>
                    <Autocomplete
                        options={issuesUsers}
                        getOptionLabel={(option) => option.name}
                        id="follower"
                        value={formData.follower}
                        onChange={(e, value) => onFieldChange("follower", value)}
                        renderInput={(params) => ( <TextField {...params} label="Follower" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant="outlined" /> )}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Autocomplete
                        options={pltConstants.filter(item => item.type === 'priority')}
                        getOptionLabel={(option) => option.label}
                        id="priority"
                        value={formData.priority}
                        onChange={(e, value) => onFieldChange("priority", value)}
                        renderInput={(params) => ( <TextField {...params} label="Priority" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant="outlined" /> )}
                    />
                    </Grid>
                    <Grid item xs={12} md={6}>
                    <Autocomplete
                        options={pltConstants.filter(item => item.type === 'severity')}
                        getOptionLabel={(option) => option.label}
                        id="severity"
                        value={formData.severity}
                        onChange={(e, value) => onFieldChange("severity", value)}
                        renderInput={(params) => ( <TextField {...params} label="Severity" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant="outlined" /> )}
                    />
                </Grid>
                { onCheckPermission('task_management','stages','R') ?
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                        options={stages}
                        getOptionLabel={(option) => option.name}
                        id="project_stage"
                        value={formData.project_stage}
                        onChange={(e, value) => onFieldChange("project_stage", value)}
                        renderInput={(params) => ( <TextField {...params} label="Stage" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant="outlined" /> )}
                    />
                  </Grid>:null
                }
                { onCheckPermission('task_management','milestone','R') ?
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                        options={milestones}
                        getOptionLabel={(option) => option.name}
                        id="milestone"
                        value={formData.project_milestone}
                        onChange={(e, value) => onFieldChange("project_milestone", value)}
                        renderInput={(params) => ( <TextField {...params} label="Milestone" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant="outlined" /> )}
                    />
                  </Grid>:null
                }
                <Grid item xs={12} md={6}>
                    <Autocomplete
                        options={workOrders}
                        getOptionLabel={(option) => option.unique_name}
                        id="work_order"
                        value={formData.work_order}
                        onChange={(e, value) => onFieldChange("work_order", value)}
                        renderInput={(params) => ( <TextField {...params} label="Work Order" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant="outlined" /> )}
                    />
                    </Grid>
                    <Grid item xs={12} md={6}>
                    <Autocomplete
                        options={projectForm}
                        getOptionLabel={(option) => option.name}
                        id="form"
                        value={formData.form}
                        onChange={(e, value) => onFieldChange("form", value)}
                        renderInput={(params) => ( <TextField {...params} label="Form" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant="outlined" /> )}
                    />
                    </Grid>
                    <Grid item xs={12} md={12}>
                    <DropzoneArea
                        filesLimit={10}
                        showPreviewsInDropzone={false}
                        maxFileSize={52428800}
                        useChipsForPreview={true}
                        showPreviews={ true }
                        dropzoneText={<p>Drag & Drop Attachments Or Click Here </p>}
                        dropzoneClass="full-w-drag-drop-zone"
                        maxWidth="sm"
                        alertSnackbarProps={{anchorOrigin: {vertical:'top', horizontal:'right'}}}
                        showAlerts={['error', 'info']}
                        onChange={(files) => onFieldChange('files', files)}
                    />
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions className="dialogActions">
          <Button disabled={isLoading} color="primary" variant="outlined" size="small" onClick={toggleModalFn}>Cancel</Button>
          <Button disabled={isLoading} color="primary" variant="contained" size="small" onClick={onAddEditIssue}>
            {isLoading ? <CircularProgress size={24} /> : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
export default withRouter(AddIssue)
