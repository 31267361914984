import React from "react"
import ReactDOM from "react-dom"
import configureStore from "./store/configureStore"
import { Provider } from "react-redux"
import { SnackbarProvider } from "notistack"
import { Router, Route, IndexRoute, browserHistory } from "react-router"
import { projectsRoutes } from "./projects_management/routes"
import {
	ProjectCorForm,
	CreateTechnicalEngine,
	InspectionFormT005,
	InspectionFormT007,
	InspectionFormT008,
	ProjectTracker,
	CorTracker,
	ManPowerTracker,
	ProgramScheduler,
	ProgramTracker,
	ReviewT005,
	ReviewT007,
 } from './applications/technicalInspection';
import {AnalyticTracker,ProjectsAnalyticTracker, ResourcesAnalyticTracker  } from './applications/tracker/containers';
import { InspectionFleetDb } from './applications/dashboards';
import { PageNotFound, NoAccessApps } from './shared';
import Faqs from './shared/components/Faqs';
import FeaturesList from './shared/components/FeaturesList';
import App from './App';
import TechnicalAssetReport from './TechnicalAssetReport';
import requireAuth from './utils/requireAuth';
import RoleBasedAccess from './utils/RoleBasedAccess';

import LoggedUserRed from './utils/LoggedUserRed';
import { Onboarding } from './phase2/auth';
import Tags from './User/containers/Tags';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
const store = configureStore();
const theme = createTheme({
  typography: {
    fontFamily: "Conv_IBMPlexSans-Regular",
  },
})

ReactDOM.render(
	<div>
		<Provider store={store}>
			<ThemeProvider theme={theme}>
				<SnackbarProvider maxSnack={3}>
					<Router history={browserHistory}>
						<Route path="/" component={App}>
							<IndexRoute component={requireAuth(InspectionFleetDb)}/>
							<Route path="/login" component={LoggedUserRed(Onboarding)} />
							<Route path="/select-lessor" component={requireAuth(Onboarding)} />
							<Route path="/restricted-access/:type" component={requireAuth(NoAccessApps)} />
							<Route path="/technical/project/create-engine/:project_slug" component={requireAuth(CreateTechnicalEngine)}/>
							<Route path="/technical/project/:project_slug/engine/edit/:workOrderSlug" component={requireAuth(CreateTechnicalEngine)}/>
							<Route path="/technical/project/view/:project_slug/project-cor-form" component={requireAuth(ProjectCorForm)} />
							<Route path="/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/t005" component={requireAuth(InspectionFormT005)} />
							<Route path="/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/t007" component={requireAuth(InspectionFormT007)} />
							{/* <Route path="/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/t008" component={requireAuth(InspectionFormT008)} /> */}
							<Route path="/:type/:aircraft_slug/technical/review/:workOrderSlug/forms/:formSlug/t005" component={requireAuth(ReviewT005)} />
							<Route path="/:type/:aircraft_slug/technical/review/:workOrderSlug/forms/:formSlug/t007" component={requireAuth(ReviewT007)} />
							<Route path="/technical/projects/tracker" component={requireAuth(RoleBasedAccess(ProjectTracker,['project_management','tracker','R'] ))} />
							<Route path="/technical/cor/tracker" component={requireAuth(CorTracker)} />
							<Route path="/technical/man-power/tracker" component={requireAuth(ManPowerTracker)} />
							<Route path="/fleet/technical/dashboard" component={requireAuth(InspectionFleetDb)} />
							<Route path="/users/tags" component={requireAuth(Tags)} />
							<Route path="/faqs" component={requireAuth(Faqs)} />
							<Route path="/whats-new" component={requireAuth(FeaturesList)} />
							<Route path="/analytics" component={requireAuth(AnalyticTracker)} />
							<Route path="/analytics/projects" component={requireAuth(RoleBasedAccess(ProjectsAnalyticTracker,['project_management','analytics','R']))} />
							<Route path="/analytics/resources" component={requireAuth(RoleBasedAccess(ResourcesAnalyticTracker,['project_management','analytics','R']))} />
							<Route path="/technical/program-planner" component={requireAuth(ProgramScheduler)} />
							<Route path="/technical/program-planner/create/program" component={requireAuth(ProgramScheduler)} />
							<Route path="/technical/program-planner/assets" component={requireAuth(ProgramScheduler)} />
							<Route path="/technical/program-planner/assets/program/:programId" component={requireAuth(ProgramScheduler)} />
							<Route path="/technical/program-planner/program/:programId" component={requireAuth(ProgramScheduler)} />
							<Route path="/technical/program-planner/program/:programId/details" component={requireAuth(ProgramScheduler)} />
							<Route path="/technical/program-planner/program/:programId/tracker" component={requireAuth(ProgramTracker)} />
							{ projectsRoutes.map((route) =>
								<Route key={route.key} path={route.path} component={route.component} />
							)}
						</Route>
						<Router path="/techical/asset/report/:shareKey" component={TechnicalAssetReport}/>
						<Route path='*' exact={true} component={PageNotFound} />
					</Router>
				</SnackbarProvider>
			</ThemeProvider>
		</Provider>
	</div>,
	document.querySelector('.root')
);
