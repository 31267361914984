import React, { Component } from 'react';
import {  Row, Col } from 'reactstrap';
import OwnerClient from './OwnerClient';
import StatusRadio from './StatusRadio';
import PlacesAutocomplete from 'react-places-autocomplete';
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from 'react-places-autocomplete';
import { TextInputField,DateInputField, GetInputField } from '../../Elements';

export default class EngineCurrentDetails  extends Component {
  constructor(props) {
    super(props);
    this.state = {
      asset: {
        location : '',
        latitude: '',
        longitude: '',
      }
    };
  }

  updateForm = () => {
  }
  handleChange = address => {
   this.props.updateForm({type: 'location', value:address, sectionType: 'EngineCurrentDetails', index:this.props.index})
  };
  handleSelect = address => {
    let location =  geocodeByAddress(address);

    location.then(results =>
         getLatLng(results[0])
         )
      .then(latLng => {
        let goeLoc=latLng;
        this.setState(prevState => ({
          ...prevState,
          asset: {
            ...prevState.asset,
            location : address,
            latitude: goeLoc['lat'],
            longitude: goeLoc['lng'],
          }
        }));
       this.props.updateForm({type: 'location', value:address, sectionType: 'EngineCurrentDetails', index:this.props.index})
       this.props.updateForm({type: 'lat', value:goeLoc['lat'], sectionType: 'EngineCurrentDetails', index:this.props.index})
       this.props.updateForm({type: 'lang', value:goeLoc['lng'], sectionType: 'EngineCurrentDetails', index:this.props.index})
      })
      .catch(error => console.error('Error', error));

      location.then(results =>
        results[0]
        ).then(country => {
          let place = country['address_components'].filter((data, index) =>  data['types'][0] == 'country');
          this.setState(prevState => ({
            ...prevState,
              asset: {
              ...prevState.asset,
                country: place.length ? place[0]['long_name']:address
              }
            }));
            this.props.updateForm({type: 'country', value:place.length ? place[0]['long_name']:address, sectionType: 'EngineCurrentDetails', index:this.props.index})
        }

        )
        .catch(error => console.error('Error', error));
    };


  render() {
  const { content,error,aircraftType,engineType } = this.props;
  return(
    <div className="general-detail-block" style={{background: '#fff'}}>
     <Row  style={{margin: '0px 15px'}}>
      <Row>
      {this.props.esn ?
        <Col sm="4"  style={{marginBottom: '20px'}}>
         {
            GetInputField(
              'string',
              <label className="for-label">Engine Serial Number<sup className="reqStar"> * </sup></label>,
              { type: 'edit',
              value: content.esn,
              error: error.esn,
              updateForm: (value) => this.props.updateForm({type: 'esn', value:value, sectionType: 'EngineCurrentDetails', index:this.props.index})  }
            )
          }
         </Col>:
          <Col sm="4"  style={{marginBottom: '20px'}}>
          {
          GetInputField(
           'dropdown',
           <label className="for-label">Engine Serial Number<sup className="reqStar"> * </sup></label>,
           { type: 'edit',
             value: content.engine_slug,
             error: error.esn,
             options: this.props.esnList.engines,
             labelKey: 'esn',
             isDisabled: this.props.editFeature?true:false,
             valueKey: 'slug',
             updateForm: (value) => {
               this.props.updateForm({type: 'slug', value:value, sectionType: 'EngineCurrentDetails', index:this.props.index});
               if(value !== undefined && value !== null && value !== ''){
                 this.props.fetchEngine(value)

             }}
           }
        )
        }
       </Col>
        }
         <Col sm="4"  style={{marginBottom: '20px'}}>
           {
            GetInputField(
              'string',
              <label className="for-label">Aircraft MSN </label>,
              {type: 'edit',
               value: content.aircraft_msn,
               error:  '',
               updateForm: (value) => this.props.updateForm({type: 'aircraft_msn', value:value, sectionType: 'EngineCurrentDetails', index:this.props.index})
              }
            )
          }
         </Col>

         <Col sm="4"  style={{marginBottom: '20px'}}>
         {
            GetInputField(
              'string',
              <label className="for-label">Aircraft Reg </label>,
              { type: 'edit',
              value: content.aircraft_registration,
              error:  '',
              updateForm: (value) => this.props.updateForm({type: 'aircraft_registration', value:value, sectionType: 'EngineCurrentDetails', index:this.props.index})
              }
            )
          }
         </Col>
         <Col sm="4"  style={{marginBottom: '20px'}}>
           {
         GetInputField(
            'dropdown',
            <label className="for-label">Aircraft Model</label>,
            { type: 'edit',
              value: content.aircraft_type_id,
              error: '',
              options: aircraftType,
              labelKey: 'name',
              valueKey: 'id',
              updateForm: (value) => this.props.updateForm({type: 'aircraft_type_id', value:value, sectionType: 'EngineCurrentDetails', index:this.props.index})
            }
         )
         }
        </Col>
        <Col sm="4"  style={{marginBottom: '20px'}}>
           {
         GetInputField(
            'dropdown',
            <label className="for-label">Engine Type<sup className="reqStar"> * </sup></label>,
            { type: 'edit',
              value: content.engine_type_id,
              error: error.engine_type_id,
              options: engineType,
              labelKey: 'name',
              isDisabled: this.props.editFeature?true:this.props.esn ?false:true,
              valueKey: 'id',
              updateForm: (value) => this.props.updateForm({type: 'engine_type_id', value:value, sectionType: 'EngineCurrentDetails', index:this.props.index})
            }
         )
         }
        </Col>
        {/* {this.props.esn ?
        <Col sm="4"  style={{marginBottom: '20px'}}>
         {
            GetInputField(
              'string',
              <label className="for-label">Engine Serial Number<sup className="reqStar"> * </sup></label>,
              { type: 'edit',
              value: content.esn,
              error: error.esn,
              updateForm: (value) => this.props.updateForm({type: 'esn', value:value, sectionType: 'EngineCurrentDetails', index:this.props.index})  }
            )
          }
         </Col>:
          <Col sm="4"  style={{marginBottom: '20px'}}>
          {
          GetInputField(
           'dropdown',
           <label className="for-label">Engine Serial Number<sup className="reqStar"> * </sup></label>,
           { type: 'edit',
             value: content.engine_slug,
             error: error.esn,
             options: this.props.esnList.engines,
             labelKey: 'esn',
             isDisabled: this.props.editFeature?true:false,
             valueKey: 'slug',
             updateForm: (value) => {
               this.props.updateForm({type: 'slug', value:value, sectionType: 'EngineCurrentDetails', index:this.props.index});
               if(value !== undefined && value !== null && value !== ''){
                 this.props.fetchEngine(value)

             }}
           }
        )
        }
       </Col>
        } */}
         <Col sm="4"  style={{marginTop: '50px'}}>

         <OwnerClient value={content.owned_by_type} updateForm = {this.props.updateForm}/>

           {/* {
            GetInputField(
              'string',
              null,
              { type: 'edit',
              value: content.owned_by,
              error:  '',
              updateForm: (value) => this.props.updateForm({type: 'owned_by', value:value, sectionType: 'EngineCurrentDetails', index:this.props.index})
             }
            )
          } */}
         </Col>
         <Col sm="4"  style={{marginBottom: '20px'}}>
           {
            GetInputField(
              'string',
              <label className="for-label">Workorder No</label>,
              { type: 'edit',
              value: content.work_order_number,
              error:  '',
              updateForm: (value) => this.props.updateForm({type: 'work_order_number', value:value, sectionType: 'EngineCurrentDetails', index:this.props.index})
             }
            )
          }
         </Col>
         <Col sm="4"  style={{marginBottom: '20px'}}>
           {
            GetInputField(
              'string',
              <label className="for-label">Engine Operated By</label>,
              { type: 'edit',
              value: content.operated_by,
              error:  '',
              updateForm: (value) => this.props.updateForm({type: 'operated_by', value:value, sectionType: 'EngineCurrentDetails', index:this.props.index})
             }
            )
          }
         </Col>
         <Col sm="4"  style={{marginBottom: '20px'}}>

          <label style={{fontSize: '13px'}} className="for-label">Location</label>
            {
              window.google !== undefined && window.google !== null ?
              <PlacesAutocomplete
                    value={content.location ? content.location :''}
                    onChange={this.handleChange}
                    onSelect={this.handleSelect}
                  >
                  {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div style={{position: 'relative',borderBottom: '1px solid #d7d7d7'}}>
                      <input
                        {...getInputProps({
                          placeholder: 'Search Location',
                          className: 'location-search-input',
                        })}
                        style={{width:'100%'}}
                      />
                      {
                        content.location ?
                        <div className="autocomplete-dropdown-container" style={{position: 'absolute', top: '38px', width: '100%', zIndex: 100}}>
                        {loading && <div>Loading...</div>}
                        {suggestions.map(suggestion => {
                          const className = suggestion.active
                            ? 'suggestion-item--active'
                            : 'suggestion-item';
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div> :  null
                      }

                    </div>
                  )}
                </PlacesAutocomplete>
              :GetInputField(
                'string',
                "",
                { type: 'edit',
                value: content.location,
                error:  '',
                updateForm: (value) => this.props.updateForm({type: 'location', value:value, sectionType: 'EngineCurrentDetails', index:this.props.index})
               }
              )
            }
         </Col>
         <Col md="4" style={{marginBottom: '20px'}}>
           {
             this.props.esn ?
             GetInputField(
               'string',
               <label className="for-label">Country <sup className="reqStar"> * </sup></label>,
               { type: 'edit',
                 isDisabled: !this.state.create_msn,
                 error: error.country,
                 value: content.country,
                 isDisabled: this.props.editFeature?true:this.props.esn ?false:true,
                 updateForm: (value) => this.props.updateForm({type: 'country', value:value, sectionType: 'EngineCurrentDetails', index:this.props.index})
               }
           ):null}
         </Col>
      </Row>

      <Row style={{marginTop:'20px'}}>
      <Col sm="3"  style={{marginBottom: '20px'}}>
           {
            GetInputField(
              'string',
              <label className="for-label">TSN<sup className="reqStar"> * </sup></label>,
              { type: 'edit',
              value: content.tsn,
              error:   error.tsn,
              updateForm: (value) => this.props.updateForm({type: 'tsn', value:value, sectionType: 'EngineCurrentDetails', index:this.props.index})

            }
            )
          }
         </Col>
         <Col sm="3"  style={{marginBottom: '20px'}}>
           {
            GetInputField(
              'string',
              <label className="for-label">CSN<sup className="reqStar"> * </sup></label>,
              { type: 'edit',
              value: content.csn,
              error:  error.csn,
              updateForm: (value) => this.props.updateForm({type: 'csn', value:value, sectionType: 'EngineCurrentDetails', index:this.props.index})
             }
            )
          }
         </Col>
         <Col sm="3"  style={{marginBottom: '20px'}}>
           {
            GetInputField(
              'string',
              <label className="for-label">TSLSV</label>,
              { type: 'edit',
              value: content.tslv,
              error:  error.tslv,
              updateForm: (value) => this.props.updateForm({type: 'tslv', value:value, sectionType: 'EngineCurrentDetails', index:this.props.index})
              }
            )
          }
         </Col>
         <Col sm="3"  style={{marginBottom: '20px'}}>
           {
            GetInputField(
              'string',
              <label className="for-label">CSLSV</label>,
              { type: 'edit',
              value: content.cslv,
              error: error.cslv,
              updateForm: (value) => this.props.updateForm({type: 'cslv', value:value, sectionType: 'EngineCurrentDetails', index:this.props.index})
             }
            )
          }
         </Col>
         <Col sm="3"  style={{marginBottom: '20px'}}>
           {
            GetInputField(
              'string',
              <label className="for-label">TSR</label>,
              { type: 'edit',
              value: content.tsr,
              error:  error.tsr,
              updateForm: (value) => this.props.updateForm({type: 'tsr', value:value, sectionType: 'EngineCurrentDetails', index:this.props.index})
             }
            )
          }
         </Col>
         <Col sm="3"  style={{marginBottom: '20px'}}>
           {
            GetInputField(
              'string',
              <label className="for-label">CSR</label>,
              { type: 'edit',
              value: content.csr,
              error:  error.csr,
              updateForm: (value) => this.props.updateForm({type: 'csr', value:value, sectionType: 'EngineCurrentDetails', index:this.props.index})
             }
            )
          }
         </Col>
         <Col sm="3"  style={{marginBottom: '20px'}}>
           {
            GetInputField(
              'string',
              <label className="for-label">TSO</label>,
              { type: 'edit',
              value: content.tso,
              error:   error.tso,
              updateForm: (value) => this.props.updateForm({type: 'tso', value:value, sectionType: 'EngineCurrentDetails', index:this.props.index})
              }
            )
          }
         </Col>
         <Col sm="3"  style={{marginBottom: '20px'}}>
           {
            GetInputField(
              'string',
              <label className="for-label">CSO</label>,
              { type: 'edit',
              value: content.cso,
              error: error.cso,
              updateForm: (value) => this.props.updateForm({type: 'cso', value:value, sectionType: 'EngineCurrentDetails', index:this.props.index})
             }
            )
          }
         </Col>
         <Col sm="3"  style={{marginBottom: '20px'}}>
           {
            GetInputField(
              'string',
              <label className="for-label">TSHSI</label>,
              { type: 'edit',
              value: content.tshi,
              error:   error.tshi,
              updateForm: (value) => this.props.updateForm({type: 'tshi', value:value, sectionType: 'EngineCurrentDetails', index:this.props.index})
              }
            )
          }
         </Col>
          <Col sm="3"  style={{marginBottom: '20px'}}>
           {
            GetInputField(
              'string',
              <label className="for-label">CSHSI</label>,
              { type: 'edit',
              value: content.cshi,
              error:  error.cshi,
              updateForm: (value) => this.props.updateForm({type: 'cshi', value:value, sectionType: 'EngineCurrentDetails', index:this.props.index})
              }
            )
          }
         </Col>
      </Row>
      <Row style={{marginTop:'20px',width:'40%'}}>
      <Col>
       <label className="for-label"  style={{marginBottom: '20px'}}>Status</label>
           <StatusRadio value={content.engine_status} updateForm = {this.props.updateForm}/>
        </Col>
      </Row>
      <Row style={{marginTop:'20px'}}>
      <Col sm="3"  style={{marginBottom: '20px'}}>
           {
         GetInputField(
            'dropdown',
            <label className="for-label">Installed Position</label>,
            { type: 'edit',
              value: content.install_position,
              error: '',
              options:this.props.techConstants.filter(item => item.type=="engine_position"),
              labelKey: 'label',
              valueKey: 'value',
              updateForm: (value) => this.props.updateForm({type: 'install_position', value:value, sectionType: 'EngineCurrentDetails', index:this.props.index})
            }
         )
         }
        </Col>
         <Col sm="3"  style={{marginBottom: '20px'}}>
           {
            GetInputField(
              'string',
              <label className="for-label">MPA Test Margin</label>,
              { type: 'edit',
              value: content.mpa_test_margin,
              error:  '',
              updateForm: (value) => this.props.updateForm({type: 'mpa_test_margin', value:value, sectionType: 'EngineCurrentDetails', index:this.props.index})
             }
            )
          }
         </Col>
         <Col sm="3"  style={{marginBottom: '20px'}}>
           {
            GetInputField(
              'string',
              <label className="for-label">LLP Status</label>,
              { type: 'edit',
              value: content.llp_status_sheet,
              error:  '',
              updateForm: (value) => this.props.updateForm({type: 'llp_status_sheet', value:value, sectionType: 'EngineCurrentDetails', index:this.props.index})
              }
            )
          }
         </Col>
         <Col sm="3"  style={{marginBottom: '20px'}}>
           {
            GetInputField(
              'string',
              <label className="for-label">AD Status</label>,
              { type: 'edit',
              value: content.ad_status,
              error:  '',
              updateForm: (value) => this.props.updateForm({type: 'ad_status', value:value, sectionType: 'EngineCurrentDetails', index:this.props.index})
              }
            )
          }
         </Col>
         <Col sm="3"  style={{marginBottom: '20px'}}>
           {
            GetInputField(
              'string',
              <label className="for-label">SB Status</label>,
              { type: 'edit',
              value: content.sb_status,
              error:  '',
              updateForm: (value) => this.props.updateForm({type: 'sb_status', value:value, sectionType: 'EngineCurrentDetails', index:this.props.index})
             }
            )
          }
         </Col>
          <Col sm="3"  style={{marginBottom: '20px'}}>
           {
            GetInputField(
              'string',
              <label className="for-label">Engine Lease Return Condition</label>,
              { type: 'edit',
              value: content.lease_return_standard,
              error:  '',
              updateForm: (value) => this.props.updateForm({type: 'lease_return_standard', value:value, sectionType: 'EngineCurrentDetails', index:this.props.index})
              }
            )
          }
         </Col>
         <Col sm="3"  style={{marginBottom: '20px'}}>
           {
            GetInputField(
              'string',
              <label className="for-label">ETOPS</label>,
              { type: 'edit',
              value: content.etops,
              error:  '',
              updateForm: (value) => this.props.updateForm({type: 'etops', value:value, sectionType: 'EngineCurrentDetails', index:this.props.index})
             }
            )
          }
         </Col>

      </Row>
    </Row>
    </div>
    );
  }
}
