import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { useSnackbar } from 'notistack';
import { Button, TableRow, TableCell, TextField, Paper, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Checkbox, FormControlLabel, CircularProgress, Tooltip, IconButton } from '@material-ui/core';
import { EmptyCollection, DeletePopUp, PageLoader, CkEditorText, STableLoader, Pagination } from '../../../shared_elements';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import { globalDeleteService, globalGetService, globalPostService, globalPutService } from '../../../utils_v2/globalApiServices';
import { checkApiStatus } from '../../../utils_v2';
const AddEditSection = ({params, toggleModalFn, addEditSection, getResponseBack}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false)
    const [error, setError] = useState({});
    const [section, setSection] = useState(addEditSection.data);
    const onFieldChange = (key, value) => {
        setSection({...section, [key]:value})
    }
    const onAddEditSection = () => {
        let validationInputs = {
            title:section?.title?.trim()?.length ? '':'Please enter Title',
        }
        if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
            setLoading(true);
            if(section.id){
                globalPutService(`technical/v2/workorder/${params.workOrderSlug}/mr-claim/sections/`, [section])
                .then(response => {
                    if(checkApiStatus(response)){
                        enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                        toggleModalFn(); getResponseBack();
                    }
                    setLoading(false);
                })
            }else{
                globalPutService(`technical/v2/workorder/${params.workOrderSlug}/mr-claim/sections/`, [section])
                .then(response => {
                    if(checkApiStatus(response)){
                        enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                        toggleModalFn(); getResponseBack();
                    }
                    setLoading(false);
                })
            }
        }else{
            setError(validationInputs)
        }
    }
    return(
        <Dialog
            open={addEditSection.modal}
            onClose={toggleModalFn}
            className='project-management-modal'
            maxWidth='md'
        >
            <DialogTitle id="scroll-dialog-title">
                {section.id ? 'Edit':'Add'} Section
            </DialogTitle>
            <DialogContent dividers={true}>
                <Grid container spacing={1}>
                    <Grid item md={12}>
                        <TextField
                            id="title"
                            label="Title"
                            fullWidth
                            required
                            margin="normal"
                            value={section?.title||''}
                            error={error?.title}
                            helperText={error?.title||''}
                            InputLabelProps={{shrink: true}}
                            inputProps={{maxLength: 50}}
                            onChange={(e) => onFieldChange('title', e.target.value)}
                            onFocus={(e) => setError({...error,'title':''})}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs='12'>
                        <div className='content-editor'>
                            <label>Description</label>
                            <CkEditorText
                                disabled={false}
                                htmlData={section?.content||''}
                                onBlurData={(data) => onFieldChange('content', data)}
                            />
                        </div>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} onClick={toggleModalFn} color="primary" size="small">Cancel</Button>
                <Button disabled={isLoading} onClick={onAddEditSection} variant="contained" color="primary" size="small">
                    {isLoading ? <CircularProgress size={20} /> : 'SAVE' }
                </Button>
            </DialogActions>
        </Dialog>
    )
}
const Sections = ({params, currentFormDetail, getCurrentFormDetail}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [filter, setFilter] = useState({});
    const [sectionsInfo, setSectionsInfo] = useState({list:[], pagination:null});
    const [addEditSection, setAddEditSection] = useState({data:null, modal:false});
    const [deleteSection, setDeleteSection] = useState({data:[], type:'', modal:false});
    useEffect(() => {
        getSectionsInfo({}, 'skeletonLoader');
    },[]);
    const getSectionsInfo = (query, loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true):setLoading(true);
        globalGetService(`technical/v2/workorder/${params.workOrderSlug}/mr-claim/sections/`, query)
        .then(response => {
            if(checkApiStatus(response)){
                setSectionsInfo(response.data.data);
            }
            loaderType === 'skeletonLoader' ? setSkeletonLoader(false):setLoading(false);
        })
    }
    const handleChange = (type, flag, id) => {
        if(type === 'single'){
            setDeleteSection({
                ...deleteSection,
                type:'bulk',
                data: flag ? [...deleteSection.data, id]:deleteSection.data.filter(item =>  item !== id)
            })
        }else{
            let ids = sectionsInfo.list.map(item => item.id)
            setDeleteSection({
                ...deleteSection,
                type:'bulk',
                data: flag ? ids:[]
            })
        }
    }
    const onDeleteSection = () => {
        setLoading(true);
        globalDeleteService(`technical/v2/workorder/${params.workOrderSlug}/mr-claim/sections/`,{ids:deleteSection.data})
        .then(response => {
            if(checkApiStatus(response)){
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                setDeleteSection({modal:false, type:'', data:[]})
                getSectionsInfo({per_page:sectionsInfo?.pagination?.per_page||10}, 'pageLoader');
                getCurrentFormDetail('pageLoader');
            }
            setLoading(true);
        })
    }
    const canEdit = currentFormDetail?.current_form?.permissions?.can_save;
    return(
        <div className='forms-table-layout engine-te006-sections'>
            { skeletonLoader ? <STableLoader count={5} />: 
                <>
                    { canEdit ?
                        <div className='flex-centered' style={{marginBottom:'10px'}}>
                            { sectionsInfo?.list?.length ?
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            size='small'
                                            checked={deleteSection?.data?.length && deleteSection.type !== 'single' ? deleteSection?.data?.length === sectionsInfo?.list.length :false}
                                            onChange={(e) => handleChange('bulk', e.target.checked)}
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                    }
                                    label="Select All"
                                />:null
                            }
                            <ul className='list-inline' style={{marginLeft:'auto'}}>
                                { deleteSection?.data?.length && deleteSection.type !== 'single' ?
                                    <li className='list-inline-item'>
                                        <Button onClick={() => setDeleteSection({...deleteSection, modal:true})} color='secondary' variant='contained' size='small'>Delete</Button>
                                    </li>:null
                                }
                                <li className='list-inline-item'>
                                    <Button disabled={(deleteSection?.data?.length && deleteSection.type === 'bulk')} onClick={() => setAddEditSection({modal:true, data:{title:'', content:''}})} color='primary' variant='contained' size='small'>Add Section</Button>
                                </li>
                            </ul>
                        </div>:null
                    }
                    <Paper square style={{height: `${window.innerHeight- 290}px`, overflow:'auto', padding:'5px 10px'}}>
                        { !sectionsInfo.list.length ? <EmptyCollection title="No records found" />:null }   
                        { sectionsInfo?.list.map((item, index) =>
                            <Paper className='sections-content'>
                                <div className='section-header'>
                                    <Grid container spacing={1} alignItems='center'>
                                        <Grid item xs={10}>
                                            <div className='flex-centered'>
                                                {canEdit ?
                                                    <Checkbox
                                                        size='small'
                                                        checked={deleteSection.data.includes(item.id) && deleteSection.type === 'bulk'}
                                                        onChange={(e) => handleChange('single', e.target.checked, item.id)}
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />:null
                                                }
                                                <h4>{item?.title||''}</h4>
                                            </div>
                                        </Grid>
                                        <Grid item xs={2}>
                                            { canEdit ?
                                                <ul className='list-inline'>
                                                    { !(deleteSection?.data?.length && deleteSection.type === 'bulk') ?
                                                        <>
                                                            <li className='list-inline-item'>
                                                                <Tooltip title='Edit' arrow>
                                                                    <IconButton size="small"> <EditIcon onClick={() => setAddEditSection({modal:true, data:item})} fontSize='small' color='primary' /> </IconButton>
                                                                </Tooltip>
                                                            </li>
                                                            <li className='list-inline-item'>
                                                                <Tooltip title='Delete' arrow>
                                                                    <IconButton size="small"> <DeleteOutlineIcon onClick={() => setDeleteSection({modal:true, type:'single', data:[item.id]})} fontSize='small' color='secondary' /> </IconButton>
                                                                </Tooltip>
                                                            </li>
                                                        </>:null
                                                    }
                                                </ul>:null
                                            }
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className='section-body'>
                                    <div className='content-editor'>
                                        <CkEditorText
                                            disabled={true}
                                            htmlData={item?.content||''}
                                        />
                                    </div>
                                </div>
                            </Paper>
                        )}
                    </Paper>
                    <Paper square>
                        <Pagination 
                            pagination={sectionsInfo.pagination}
                            onChangePage={(event, newPage) => getSectionsInfo({...filter, page:newPage+1, per_page: sectionsInfo.pagination.per_page}, 'pageLoader')}
                            onChangeRowsPerPage={(event) => getSectionsInfo({...filter, page:1, per_page: event.target.value}, 'pageLoader')}
                        />
                    </Paper>
                </>
            }
            { addEditSection.modal ?
                <AddEditSection 
                    params={params}
                    addEditSection={addEditSection}
                    toggleModalFn={() => setAddEditSection({data:null, modal:false})}
                    getResponseBack={() => {getSectionsInfo({page:sectionsInfo?.pagination?.page||1, per_page:sectionsInfo?.pagination?.per_page||10}, 'pageLoader'); getCurrentFormDetail('pageLoader');}}
                />:null
            }
            { deleteSection.modal ?
                <DeletePopUp
                    modal={deleteSection.modal}
                    toggleModalFn={() => setDeleteSection({data:[], type:'', modal:false})}
                    title="Delete Section(s)"
                    content={<h4>Are you sure you want to delete?</h4>}
                    deleteRecordFn={onDeleteSection}
                />:null
            }
            { isLoading ? <PageLoader />: null }
        </div>
    )
}
export default withRouter(Sections);