import React, { useState, useCallback, useEffect } from 'react';
import { withRouter } from 'react-router';
import { isMobile } from 'react-device-detect';
import { Tabs, Tab, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, CircularProgress, Paper } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import { globalGetService, globalPostService } from '../../../utils_v2/globalApiServices';
import { checkApiStatus, onCheckFormPermission } from '../../../utils_v2';
import { useSnackbar } from 'notistack';
import { PageLoader } from '../../../shared_elements';

const TechSpecsConfig = ({ params, getResponseBack, disablebtn, currentFormDetail }) => {
  const [formSubmitLoader, setFormSubmitLoader] = useState(false);
  const [techSpecs, setTechSpecs] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [modal, setModal] = useState(false);
  const [isLoading, setLoading] = useState(false)
  const [manufactures, setManufactures] = useState([])
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  useEffect(() => {
    if (modal) {
      getTechSpecsConfig();
      setTabIndex(0);
    }
    getManufacturer()
  }, [modal]);

  const getTechSpecsConfig = () => {
    setLoading(true)
    globalGetService(`technical/workorder/${params.workOrderSlug}/interior-technical-specs/`)
      .then(response => {
        if (checkApiStatus(response)) {
          setTechSpecs(response.data.data);
          setLoading(false)
        }
      })
  };

  const saveTechSpecsConfigApi = () => {
    setFormSubmitLoader(true);
    globalPostService(`technical/workorder/${params.workOrderSlug}/interior-technical-specs/`, techSpecs)
      .then(response => {
        if (checkApiStatus(response)) {
          setFormSubmitLoader(false);
          setModal(false)
          getResponseBack()
          enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        } else {
          enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      })
  };
  const getManufacturer = () => {
    globalGetService('console/manufacturers/')
      .then(response => {
        if (checkApiStatus(response)) {
          setManufactures(response.data.data)
        }
      });
  }

  const onFieldChange = (e, keyParams, value, index, specsIndex) => {
    setTechSpecs(prevTechSpecs =>
      prevTechSpecs.map((data, dataIndex) =>
        dataIndex === index ? {
          ...data,
          seat_specs: data.seat_specs.map((specData, specDataIndex) =>
            specsIndex === specDataIndex ? {
              ...specData,
              answers: {
                ...specData.answers,
                [keyParams]: value
              }
            } : specData
          )
        } : data
      )
    );
  };

  const toggleModalFn = () => {
    setModal(prevModal => !prevModal);
  };

  const changeTab = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <>
      <Button
        onClick={!disablebtn ? toggleModalFn : ''}
        style={{ color: !disablebtn ? 'white' : 'rgba(255,255,255,0.4)', border: !disablebtn ? 'groove white' : 'groove rgba(255,255,255,0.4)', cursor: disablebtn ? 'not-allowed' : '', fontSize: '12px' }}
        variant='outlined'
        size='small'
      >
        Technical Specification
      </Button>
      <Dialog
        open={modal}
        className='project-management-modal'
        // onClose={toggleModalFn}
        aria-labelledby="scroll-dialog-title"
        maxWidth='lg'
      >
        <DialogTitle id="scroll-dialog-title">
          Technical Specification
        </DialogTitle>
        <DialogContent dividers={true} style={{ padding: '10px', width: '1170px', minHeight: '400px' }}>
          {modal &&
            <div>
              <Paper style={{ padding: '4px' }}>
                <Tabs
                  value={tabIndex}
                  onChange={changeTab}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons={isMobile ? 'on' : 'auto'}
                >
                  {techSpecs.map((item, index) =>
                    <Tab key={index} label={item.name} />
                  )}
                </Tabs>
              </Paper>
              <Paper style={{ padding: '4px' }} >
                {techSpecs.map((item, index) =>
                  index === tabIndex &&
                  <div key={index} style={{ padding: '5px' }}>
                    {item.seat_specs.map((specs, specsIndex) =>
                      <div className="t008-specs-modal" key={specsIndex}>
                        {console.log('manufactures', specs.answers.manufacturer)}

                        <a style={{ color: "#3f51b5", fontFamily: "Conv_IBMPlexSans-SemiBold", fontSize: '14px' }}>{specs.name}</a>
                        <Grid container spacing={3} style={{ marginBottom: '2px' }}>
                          <Grid item xs={12} md={4}>
                            <TextField
                              id="part_number"
                              label="Part Number"
                              fullWidth
                              disabled={!onCheckFormPermission(currentFormDetail)}
                              margin="normal"
                              variant='outlined'
                              value={specs.answers.part_number || ''}
                              onChange={(e) => onFieldChange(e, 'part_number', e.target.value, index, specsIndex)}
                              InputLabelProps={{ shrink: true }}
                              inputProps={{ maxLength: 150 }}
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <TextField
                              id="serial_number"
                              label="Serial Number"
                              fullWidth
                              disabled={!onCheckFormPermission(currentFormDetail)}
                              margin="normal"
                              variant='outlined'
                              value={specs.answers.serial_number || ''}
                              onChange={(e) => onFieldChange(e, 'serial_number', e.target.value, index, specsIndex)}
                              InputLabelProps={{ shrink: true }}
                              inputProps={{ maxLength: 150 }}
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <Autocomplete
                              disabled={!onCheckFormPermission(currentFormDetail)}
                              options={manufactures}
                              getOptionLabel={option => option.name}
                              id="manufacturer"
                              value={specs.answers.manufacturer ? manufactures.find(item => item.name === specs.answers.manufacturer) : null}
                              onChange={(e, value) => onFieldChange(e, 'manufacturer', value ? value.name : value, index, specsIndex)}
                              renderInput={params => <TextField variant='outlined' {...params} label="Manufacturer" margin="normal" fullWidth InputLabelProps={{ shrink: true }} />}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    )}
                  </div>
                )}
              </Paper>
            </div>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleModalFn} size='small' color="primary" variant="outlined">Cancel</Button>
          {onCheckFormPermission(currentFormDetail) ?
            <Button size='small' disabled={formSubmitLoader} variant="contained" onClick={saveTechSpecsConfigApi} color="primary">
              {formSubmitLoader ? <CircularProgress color="#ffffff" size={24} /> : 'Save'}
            </Button> : null}
        </DialogActions>
      </Dialog>
      {isLoading ? <PageLoader /> : null}
    </>
  );
};

export default withRouter(TechSpecsConfig);
