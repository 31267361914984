import React, { Fragment, useEffect, useState } from "react"
import { withRouter } from 'react-router';
import { useSnackbar } from "notistack"
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Tooltip, } from "@material-ui/core"
import { KeyboardDatePicker, MuiPickersUtilsProvider, } from "@material-ui/pickers";
import { backendDateFormat, fieldDateFormat } from "../../../constants";
import MomentUtils from "@date-io/moment";
import { Autocomplete } from "@material-ui/lab";
import moment from "moment";
import { globalGetService, globalPostService } from "../../../utils_v2/globalApiServices";
import { checkApiStatus } from "../../../utils_v2";
import { timeLogForm } from "..";
import { trackActivity } from '../../../utils/mixpanel';
const AddEditTimeLog = ({params, addEditTimeLog, toggleModalFn, getResponseBack}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [formData, setFormData] = useState(timeLogForm);
    const [error, setError] = useState({});
    const onAddEditTimeLog = () => {
        globalPostService(`technical/project/${params.project_slug}/timesheet/`, formData)
        .then(response => {
            if(checkApiStatus(response)){
                enqueueSnackbar(response.data.message, { variant: "success", anchorOrigin: { horizontal: "right", vertical: "top" }})
                toggleModalFn();getResponseBack();
                trackActivity('Task Management', {
                    event_type: 'Add TimeLog Success',
                    page_title: 'Add TimeLog',
                    project_slug: params.project_slug,
                    request_body: formData ? formData : [],
                    response_msg: response.data.message
                  })
            }else{
                enqueueSnackbar(response.data.message, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "top" }})
                trackActivity('Task Management', {
                    event_type: 'Add TimeLog Failed',
                    page_title: 'Add TimeLog',
                    project_slug: params.project_slug,
                    request_body: formData ? formData : '',
                    error_source: 'Backend',
                    error_message: response.data.message
                  })
            }
        })
    }
    const onFieldChange = (keyParam, value) => {
        setFormData({...formData, [keyParam]:value});
    }
    const onRestErrorKey = (key) => {
        setError({...error, [key]:''});
    }
    return(
        <Dialog
            className="project-management-modal"
            open={addEditTimeLog.modal}
            onClose={toggleModalFn}
            maxWidth={"sm"}
        >
            <DialogTitle>{addEditTimeLog.issue ? 'Edit':'Add'} Time Log</DialogTitle>
            <DialogContent dividers={true}>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={12}>
                        <TextField
                            id="log_task"
                            fullWidth
                            required
                            label="Task/Issue"
                            margin="normal"
                            inputProps={{ maxLength: 255 }}
                            value={formData.name}
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            onChange={(e, value) => onFieldChange("log_task", e.target.value)}
                            error={error.log_task ? true : false}
                            helperText={error.log_task ? error.log_task : ""}
                            onFocus={() => onRestErrorKey("log_task")}
                        />
                    </Grid>
                    
                    <Grid item xs={12} md={6}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                required
                                margin="normal"
                                id="date"
                                label="Date"
                                format={fieldDateFormat}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={formData.date?formData.date:null}
                                onChange={(data, value) => { onFieldChange( "date", data ? moment(data).format(backendDateFormat) : data ) }}
                                inputVariant="outlined"
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Autocomplete
                            options={[]}
                            getOptionLabel={(option) => option.name}
                            id="user"
                            value={formData.assign}
                            onChange={(e, value) => onFieldChange("user", value)}
                            renderInput={(params) => ( <TextField required onFocus={() => onRestErrorKey("user")} error={error.user ? true : false} helperText={error.user ? error.user : ""} {...params} label="User" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant="outlined" /> )}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Autocomplete
                            options={[]}
                            getOptionLabel={(option) => option.name}
                            id="user"
                            value={formData.assign}
                            onChange={(e, value) => onFieldChange("user", value)}
                            renderInput={(params) => ( <TextField onFocus={() => onRestErrorKey("user")} error={error.user ? true : false} helperText={error.user ? error.user : ""} {...params} label="Billing Type" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant="outlined" /> )}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            id="hours"
                            fullWidth
                            required
                            label="Daily Log"
                            margin="normal"
                            inputProps={{ maxLength: 255 }}
                            value={formData.hours}
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            onChange={(e, value) => onFieldChange("hours", e.target.value)}
                            error={error.hours ? true : false}
                            helperText={error.hours ? error.hours : ""}
                            onFocus={() => onRestErrorKey("hours")}
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <div style={{display:'flex', alignItems:'center'}}>
                            <div>
                                <ul className="list-inline">
                                    <li className="list-inline-item" style={{width:'80px'}}>
                                        <Autocomplete
                                            options={[{label:'01',value:'01'},{label:'02',value:'02'}]}
                                            getOptionLabel={(option) => option.label}
                                            id="user"
                                            value={formData.assign}
                                            onChange={(e, value) => onFieldChange("user", value)}
                                            renderInput={(params) => ( <TextField {...params} margin="none" fullWidth InputLabelProps={{ shrink: true }} variant="outlined" /> )}
                                        />
                                    </li>
                                    <li className="list-inline-item" style={{width:'80px'}}>
                                        <Autocomplete
                                            options={[{label:'01',value:'01'},{label:'02',value:'02'}]}
                                            getOptionLabel={(option) => option.label}
                                            id="user"
                                            value={formData.assign}
                                            onChange={(e, value) => onFieldChange("user", value)}
                                            renderInput={(params) => ( <TextField {...params} margin="none" fullWidth InputLabelProps={{ shrink: true }} variant="outlined" /> )}
                                        />
                                    </li>
                                    <li className="list-inline-item" style={{width:'80px'}}>
                                        <Autocomplete
                                            options={[{label:'am',value:'am'},{label:'pm',value:'pm'}]}
                                            getOptionLabel={(option) => option.label}
                                            id="user"
                                            value={formData.assign}
                                            onChange={(e, value) => onFieldChange("user", value)}
                                            renderInput={(params) => ( <TextField {...params} margin="none" fullWidth InputLabelProps={{ shrink: true }} variant="outlined" /> )}
                                        />
                                    </li>
                                </ul>
                            </div>
                            <div>To</div>
                            <div>
                                <ul className="list-inline">
                                    <li className="list-inline-item" style={{width:'80px'}}>
                                        <Autocomplete
                                            options={[{label:'01',value:'01'},{label:'02',value:'02'}]}
                                            getOptionLabel={(option) => option.label}
                                            id="user"
                                            value={formData.assign}
                                            onChange={(e, value) => onFieldChange("user", value)}
                                            renderInput={(params) => ( <TextField {...params} margin="none" fullWidth InputLabelProps={{ shrink: true }} variant="outlined" /> )}
                                        />
                                    </li>
                                    <li className="list-inline-item" style={{width:'80px'}}>
                                        <Autocomplete
                                            options={[{label:'01',value:'01'},{label:'02',value:'02'}]}
                                            getOptionLabel={(option) => option.label}
                                            id="user"
                                            value={formData.assign}
                                            onChange={(e, value) => onFieldChange("user", value)}
                                            renderInput={(params) => ( <TextField {...params} margin="none" fullWidth InputLabelProps={{ shrink: true }} variant="outlined" /> )}
                                        />
                                    </li>
                                    <li className="list-inline-item" style={{width:'80px'}}>
                                        <Autocomplete
                                            options={[{label:'am',value:'am'},{label:'pm',value:'pm'}]}
                                            getOptionLabel={(option) => option.label}
                                            id="user"
                                            value={formData.assign}
                                            onChange={(e, value) => onFieldChange("user", value)}
                                            renderInput={(params) => ( <TextField {...params} margin="none" fullWidth InputLabelProps={{ shrink: true }} variant="outlined" /> )}
                                        />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <TextField
                            id="notes"
                            fullWidth
                            multiline
                            minRows={3}
                            label="Notes"
                            margin="normal"
                            value={formData.notes}
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            onChange={(e, value) => onFieldChange("notes", e.target.value) }
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions className="dialogActions">
                <Button disabled={isLoading} color="primary" variant="outlined" size="small" onClick={toggleModalFn}>Cancel</Button>
                <Button disabled={isLoading} color="primary" variant="contained" size="small" onClick={onAddEditTimeLog}>
                    {isLoading ? <CircularProgress size={24} /> : "Save"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default withRouter(AddEditTimeLog);