import * as actions from '../actions';
import { robberyObj } from '../../AddFormObjects'
import { globalGetService, globalPutService, globalPostService, globalDeleteService, globalExportService } from '../../../../../globalServices/';
import { toastFlashMessage, removeEmptyKey, downloadFileType } from '../../../../../utils';
import { getFormDetailsAc } from '../../T001/actionCreators';
import { browserHistory } from 'react-router';
import { trackActivity } from '../../../../../utils/mixpanel';
export const robberyListAc = (props, queryParam = {}) => {
  return (dispatch) => {
    dispatch(triggerLoader(true));
    globalGetService('technical/workorder/' + props.params.workOrderSlug + '/robbery-list/', queryParam)
      .then(response => {
        if (response.data.statusCode == 200) {
          dispatch({
            type: actions.ROBBERY_LIST,
            payload: response.data.data
          });
        }
        dispatch(triggerLoader(false));
      })
  }
}

export const robberyViewCrudAc = (props, robberyCrud, id = null) => {
  return (dispatch) => {
    if (robberyCrud.type == 'add') {
      dispatch({
        type: actions.TOGGLE_SIDE_BAR_T009,
        payload: { type: 'add', data: robberyObj, flag: robberyCrud.flag }
      })
    } else {
      dispatch(triggerLoader(true));
      globalGetService('technical/workorder/' + props.params.workOrderSlug + '/robbery-list/' + robberyCrud.data.id + '/', {})
        .then(response => {
          dispatch(triggerLoader(false));
          if (response.data.statusCode == 200) {
            trackActivity('T009', {
              event_type: 'Robbery Viewed',
              page_title: 'Robbery List',
              workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
              form_slug: props.params.formSlug ? props.params.formSlug : '',
              item_id: robberyCrud.data.id ? robberyCrud.data.id : '',
              asset_type: props.params.type ? props.params.type : '',
              aircraft_slug: props.params.aircraft_slug ? props.params.aircraft_slug : ''
            })
            dispatch({
              type: actions.TOGGLE_SIDE_BAR_T009,
              payload: { flag: true, data: response.data.data, type: robberyCrud.type }
            })
          }
        })
    }
  }
}

export const robberyAddEditAc = (props, data, type) => {
  delete data['work_order'];
  data = {
    ...data,
    work_order: props.params.workOrderSlug
  }
  return (dispatch) => {
    dispatch(triggerLoader(true));
    if (type == 'add') {
      globalPostService('technical/workorder/' + props.params.workOrderSlug + '/robbery-list/', data)
        .then(response => {
          dispatch(triggerLoader(false));
          if (response.data.statusCode == 200) {
            toastFlashMessage(response.data.message, 'success');
            trackActivity('T009', {
              event_type: 'Robbery Added',
              page_title: 'Robbery List',
              workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
              form_slug: props.params.formSlug ? props.params.formSlug : '',
              item_id: data.id ? data.id : '',
              asset_type: props.params.type ? props.params.type : '',
              aircraft_slug: props.params.aircraft_slug ? props.params.aircraft_slug : '',
              request_body: data ? data : '',
              response_body: response.data.data ? response.data.data : ''
            })
            dispatch({
              type: actions.ADD_ROBBERY,
              payload: response.data.data
            })
          } else {
            trackActivity('T009', {
              event_type: 'Add Robbery Failed',
              page_title: 'Robbery List',
              workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
              form_slug: props.params.formSlug ? props.params.formSlug : '',
              asset_type: props.params.type ? props.params.type : '',
              aircraft_slug: props.params.aircraft_slug ? props.params.aircraft_slug : '',
              request_body: data ? data : '',
              error_msg: response.data.message ? response.data.message : '',
              error_source: 'Backend'
            })
          }
        })
    } else {
      globalPutService('technical/workorder/' + props.params.workOrderSlug + '/robbery-list/' + data.id + '/', data)
        .then(response => {
          dispatch(triggerLoader(false));
          if (response.data.statusCode == 200) {
            toastFlashMessage(response.data.message, 'success');
            trackActivity('T009', {
              event_type: 'Robbery Edited',
              page_title: 'Robbery List',
              workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
              form_slug: props.params.formSlug ? props.params.formSlug : '',
              item_id: data.id,
              asset_type: props.params.type ? props.params.type : '',
              aircraft_slug: props.params.aircraft_slug ? props.params.aircraft_slug : '',
              request_body: data ? data : '',
            })
            dispatch({
              type: actions.UPDATE_ROBBERY,
              payload: response.data.data
            })
          } else {
            trackActivity('T009', {
              event_type: 'Robbery Update Failed',
              page_title: 'Robbery List',
              workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
              form_slug: props.params.formSlug ? props.params.formSlug : '',
              item_id: data.id,
              asset_type: props.params.type ? props.params.type : '',
              aircraft_slug: props.params.aircraft_slug ? props.params.aircraft_slug : '',
              request_body: data ? data : '',
              error_msg: response.data.message ? response.data.message : '',
              error_source: 'Backend'
            })
          }
        })
    }
    dispatch(getFormDetailsAc(props));
  }
}

export const robberyDeleteAc = (props, ids, type) => {
  let location = browserHistory.getCurrentLocation();
  location = {
    ...location,
    query: {
      page: 1
    }
  }
  return (dispatch) => {
    dispatch(triggerLoader(true));
    globalDeleteService('technical/workorder/' + props.params.workOrderSlug + '/robbery-list/', { ids: ids })
      .then(response => {
        dispatch(triggerLoader(false));
        if (response.data.statusCode == 200) {
          toastFlashMessage(response.data.message, 'success');
          trackActivity('T009', {
            event_type: 'Robbery Deleted',
            page_title: 'Robbery List',
            workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
            form_slug: props.params.formSlug ? props.params.formSlug : '',
            item_id: ids ? ids : '',
            type: type ? type : '',
            asset_type: props.params.type ? props.params.type : '',
            aircraft_slug: props.params.aircraft_slug ? props.params.aircraft_slug : '',
          })
          dispatch({
            type: actions.DELETE_ROBBERY,
            payload: ids
          });
          if (type == 'bulk') {
            browserHistory.push(location);
            dispatch(robberyListAc(props));
          }
        }
        dispatch(getFormDetailsAc(props));
      })
  }
}

export const robberyListExportAc = (props, fileType = null, name, query = {}) => {
  return (dispatch) => {
    dispatch(triggerLoader(true));
    globalExportService('technical/workorder/' + props.params.workOrderSlug + '/robbery-list/', { ...query, download: fileType })
      .then(response => {
        dispatch(triggerLoader(false));
        downloadFileType(response, name + '.', fileType)
        trackActivity('T009', {
          event_type: 'Rerort Exported',
          page_title: 'Robbery List',
          workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
          form_slug: props.params.formSlug ? props.params.formSlug : '',
          file_extension: fileType ? fileType : '',
          file_name: name ? name : ''
        })
      })
  }
}

export const triggerLoader = (flag) => {
  return (dispatch) => {
    dispatch({
      type: actions.ROBBERY_LOADER,
      payload: flag
    });
  }
}
