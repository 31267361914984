import React from "react";
import {Chip} from '@material-ui/core';
const IssueCountCard = ({prsCategories}) => {
    return(
        <ul className="list-inline">
            <li className="list-inline-item">
                <Chip size='small' label={`Total: ${prsCategories?.items?.total || 0}`} style={{background:'#a2a2a2'}} />
            </li>
            <li className="list-inline-item">
                <Chip size='small' label={`Open: ${prsCategories?.items?.open || 0}`} style={{background:'#4b82de', color:'#fff'}} />
            </li>
            <li className="list-inline-item">
                <Chip size='small' label={`Closed: ${prsCategories?.items?.closed || 0}`} style={{background:'#269b2b', color:'#fff'}} />
            </li>
            <li className="list-inline-item">
                <Chip size='small' label={`Reopen: ${prsCategories?.items?.reopen || 0}`} style={{background:'#e86642', color:'#fff'}} />
            </li>
            <li className="list-inline-item">
                <Chip size='small' label={`Unresolved: ${prsCategories?.items?.unresolved || 0}`} style={{background:'#dbcc28', color:'#fff'}} />
            </li>
        </ul>
    )
}
export default IssueCountCard;