import React, { useState, Fragment } from 'react';
import { withRouter } from 'react-router';
import { Paper, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Tooltip, TextField } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {SortableContainer, SortableElement, sortableHandle} from 'react-sortable-hoc';
import {arrayMoveImmutable} from 'array-move';
const SortableItem = SortableElement(({subSection, index, children}) => 
    <ExpansionPanel key={index}>
        <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id={`section${index}`}
            key={index}
        >
            { subSection.name }
        </ExpansionPanelSummary>
        {children}
    </ExpansionPanel>
);
const SortableList = SortableContainer(({items, children}) => {
    return (
        <Fragment>
            {items.map((subSection, index) => (
                <SortableItem key={`item-${index}`} sortIndex={index} index={index} subSection={subSection} children={children} />
            ))}
        </Fragment>
    );
});
const DocumentSubSection = ({params, children}) => {
    const [section, setSection] = useState(section)
    const onSortEnd = ({oldIndex, newIndex}) => {
        setSection({...section, sub_sections:arrayMoveImmutable(section.sub_sections, oldIndex, newIndex)});
    };
    return(
        <Paper>
            <SortableList children={children} helperClass='sortableHelper' items={section.sub_sections} onSortEnd={onSortEnd} />
        </Paper>
    )
}
export default withRouter(DocumentSubSection);