import React from 'react';
import moment from 'moment';
import NumericLabel from 'react-pretty-numbers';
import { TableRow, TableCell, Tooltip, IconButton, Checkbox } from '@material-ui/core';
import EditIcon from "@material-ui/icons/Edit"
import AdjustIcon from '@material-ui/icons/Adjust';
import DeleteIcon from "@material-ui/icons/Delete"
import { displayDateFormatShort } from '../../../constants';
import { getCurrencyFormat } from '../../../utils_v2';
export default function List({currentFormDetail, onSelectRow, deleteRobberies, item, onEdit, onDelete}){
  return(
    <TableRow hover tabIndex={-1}>
      {currentFormDetail?.current_form?.permissions?.can_save ?
       <TableCell>
         <Checkbox checked={deleteRobberies.robberies.includes(item.id)} onChange={(e) => onSelectRow(e.target.checked)} color='primary' size='small' />
       </TableCell>:null}
      <TableCell style={{position:'relative'}}>
        { !item.removal_date ?
          <p style={{position:'absolute', top:0, left:'8px'}}>
            <Tooltip title="No Track of Removal Date" arrow>
              <span className="highter" id="highligther-203"></span>
            </Tooltip>
          </p>:null
        }
        {item.part_number ? item.part_number:'--'}

      </TableCell>
      <TableCell>{item.part_description ? item.part_description:'--'}</TableCell>
      <TableCell>{item.part_location ? item.part_location:'--'}</TableCell>
      <TableCell>{item.removal_date ? moment(item.removal_date).format(displayDateFormatShort):'--'}</TableCell>
      <TableCell>{item.reason_for_removal ? item.reason_for_removal:'--'}</TableCell>
      <TableCell>{item.date_verified_removed_from_aircraft ?  moment(item.date_verified_removed_from_aircraft).format(displayDateFormatShort):'--'}</TableCell>
      <TableCell>{item.date_verified_in_store_house ? moment(item.date_verified_in_store_house).format(displayDateFormatShort):'--'}</TableCell>
      <TableCell>{item.date_verified_installed_on_aircraft ? moment(item.date_verified_installed_on_aircraft).format(displayDateFormatShort) :'--'}</TableCell>
      <TableCell>{item.price ? <NumericLabel params={getCurrencyFormat()}>{item.price}</NumericLabel> :'--'}</TableCell>
      <TableCell>{item.wcard_number ? item.wcard_number :'--'}</TableCell>
      <TableCell>{item.comments ? item.comments :'--'}</TableCell>
      <TableCell className="actions-cell">
       {currentFormDetail?.current_form?.permissions?.can_save ?
         <div style={{width:'70px', float:'right'}}>
          <Tooltip title="Edit">
            <IconButton size="small" color="primary" onClick={onEdit} >
              <EditIcon color="primary" fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton size="small" color="primary" onClick={onDelete} >
              <DeleteIcon color='secondary' fontSize="small" />
            </IconButton>
          </Tooltip>
        </div>: null }
      </TableCell>
    </TableRow>
  )
}
