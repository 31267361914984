import React, { Component } from 'react';
import { Link } from 'react-router';
import moment from 'moment';
import { Row, Col, Button, Popover, PopoverBody} from 'reactstrap';
import { imgStoragePath, displayDateFormat } from '../../../../constants';
import {  getLocalStorageInfo } from '../../../../utils';
import editIcon from '../../../../shared/assets/img/edit_icon.svg';
import { FieldCol, FieldGroup, FieldLabel, FieldTextarea } from '../../Elements_V';

export default class AssetPopoverText extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      popoverOpen: false,
      modalTitle: '',
      modal: false,
      delModal: false,
      type:'view',
      remarksText:null
    };
  }

  toggle() {
		this.setState({
      type:'view',
      remarksText:null,
      popoverOpen: !this.state.popoverOpen,

    });
  }

  toggleEdit = () =>{
    this.setState({
        type: this.state.type=='edit'?'view':'edit'
   });
  }

  updateForm = (type, value) => {
    this.setState(prevState => ({
      ...prevState,
      remarksText:value
    }))
  }


  render() {
    const {  tagetId, placement, project } = this.props;
    
    return (
        <span>
       <span>
        {
          // <img width="12" className="img_eye" id={tagetId} onClick={this.toggle} src={ imgStoragePath +'eye_color.png'} alt="img" style={{ display: 'inline-block', marginRight: '10px',width:'14px',marginTop:'-4px',cursor:'pointer'}}/>
        }
        <span width="12" className="img_eye" id={tagetId} onClick={this.toggle} style={{ display: 'inline-block', marginRight: '10px',color: '#3f51b5',marginTop:'-4px',cursor:'pointer'}}>Read More</span>
        <Popover placement ={placement} isOpen={this.state.popoverOpen} target={tagetId} toggle={this.toggle}>
            <PopoverBody>
                <div className="project-tracker-popover">
                {
                  getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.project_tracker && getLocalStorageInfo().user.permission.technical.project_tracker.includes('U') ?
                <img width="12" className="img_eye" onClick={this.toggleEdit} src={editIcon} alt="img" style={this.state.type=='edit'?{display:'none'}:{ display: 'inline-block',marginTop:'5px', float:'right',width:'12px',cursor:'pointer'}}/>:null
                }
                <Row>
                    <FieldCol md="12">
                    <FieldGroup className="form-group">
                        <FieldLabel className="label"> Remarks </FieldLabel>
                        <FieldTextarea
                        value={this.state.remarksText !=null ?this.state.remarksText:project.project_remarks}
                        type={this.state.type}
                        keyParam="remarks"
                        updateField={this.updateForm}
                        />
                    </FieldGroup>
                    </FieldCol>
                    </Row>
                    { this.state.type !== 'view' ?
                    <div className="submit-block">
                    <Link className="primary-btn" onClick={() => {this.props.updateRemarks(project.slug,this.state.remarksText); this.toggle()}} >Save</Link>
                    <Link style={{marginLeft:'10px', color:'#3f51b5'}} onClick={() => this.toggle()} >CANCEL</Link>
                    </div> : null }
                </div>
            </PopoverBody>
        </Popover>
				</span>

     </span>

    );
  }
}
