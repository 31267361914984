import React from 'react';
import { Link, browserHistory, withRouter } from 'react-router';
import {ClickAwayListener,Grow, Paper, Popper, MenuItem, MenuList} from '@material-ui/core/';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useSnackbar } from 'notistack';
const FormsDropDown = ({params, formInfo}) => {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
        }
        setOpen(false);
    };
    const onFormClick =(form, event)=>{
        if(form?.have_engineers){
            handleClose(event);
            browserHistory.push(`/${params.type}/${params.aircraft_slug}/technical/${params.workOrderSlug}/forms/${form.slug}/${form.name}`);
        }
        else{
            enqueueSnackbar('Project Engineer is not assigned to this Form.', {variant: "error", anchorOrigin: {horizontal: "right", vertical: "top"}});
        }
    }
    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
        event.preventDefault();
        setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
        anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);
    return (
        <div>
            <div>
            <span
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                className="flex-centered"
                style={{cursor:'pointer'}}
            >
                {formInfo?.current_form.name}: {formInfo?.current_form.title} <ArrowDropDownIcon fontSize='small' />
            </span>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                    <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                        <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                            { formInfo.list.map((form, index) => 
                                <MenuItem style={formInfo?.current_form.id === form.id ?{background:'#d6d6d6'}:{} } key={index} onClick={(event) => {onFormClick(form, event)}}>
                                    <p style={{fontSize:'11px'}}>
                                        {form.name}<br/>{form.title}
                                    </p>
                                </MenuItem>
                            )}
                        </MenuList>
                    </ClickAwayListener>
                    </Paper>
                </Grow>
                )}
            </Popper>
            </div>
        </div>
    );
}
export default withRouter(FormsDropDown)