import * as actions from '../actions';
import { globalGetService, globalPutService, globalPostService, globalDeleteService, globalFileUploadService } from '../../../../../globalServices/';
import { toastFlashMessage, getLocalStorageInfo, getDataUri, dataURItoBlob } from '../../../../../utils';
import moment from 'moment';
import { trackActivity } from '../../../../../utils/mixpanel';
import { usageDetailAc } from '../../../../../shared/actionCreators'
import { getFormDetailsAc } from '../../T001/actionCreators';
const userInfo = getLocalStorageInfo();
const dissemblyObj = {
	title: " ",
	remarks: " ",
	finding_pictures: []
}

export const fetchTableInspectionAc = (props) => {
	return (dispatch) => {
		dispatch(triggerLoader(true));
		globalGetService(`/technical/engine-workorder/${props.params.workOrderSlug}/table-inspections/`, {})
			.then(response => {
				dispatch(triggerLoader(false));
				if (response.data.statusCode == 200) {
					dispatch({
						type: actions.TABLE_INSPECTION_DETAILS,
						payload: response.data.data
					})
				}
			})
	}
}

export const updateTableInspectionAc = (props, data) => {
	return (dispatch) => {
		dispatch(triggerLoader(true));
		globalPostService(`/technical/engine-workorder/${props.params.workOrderSlug}/table-inspections/`, data)
			.then(response => {
				dispatch(triggerLoader(false));
				if (response.data.statusCode == 200) {
					toastFlashMessage(response.data.message, 'success');
					trackActivity('TE005', {
						event_type: 'Table Inspection Details Saved',
						page_title: 'Table Inspection',
						workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
						form_slug: props.params.formSlug ? props.params.formSlug : '',
						request_body: data ? data : '',
						response_body: response.data.data ? response.data.data : '',
						asset_type: props.params.type ? props.params.type : ''
					})
					dispatch({
						type: actions.TABLE_INSPECTION_DETAILS,
						payload: response.data.data
					})
					dispatch(getFormDetailsAc(props, 2, 'TE005'))
				} else {
					trackActivity('TE005', {
						event_type: 'Save Table Inspection Details Failed',
						page_title: 'Table Inspection',
						workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
						form_slug: props.params.formSlug ? props.params.formSlug : '',
						request_body: data ? data : '',
						error_msg: response.data.message ? response.data.message : '',
						error_source: 'Backend',
						asset_type: props.params.type ? props.params.type : ''
					})
				}
			})
	}
}

export const GenUrlAc = (props, files, type) => {
	return (dispatch) => {
		dispatch(triggerLoader(true));
		var formData = new FormData();
		if (type == 'attachment') {
			for (let i = 0; i < files.length; i++) {
				let file = files[i];
				if (!(file.type.match('application/pdf.*') || file.type.match('application/vnd.ms-excel.*') || file.type.match('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet.*'))) {
					toastFlashMessage(file.name + ' is not Valid Format', 'error');
					continue;
				}
				formData.append('file', file, file.name);
			}
			formData.append('shop_visit', true);
		} else {
			let file = [];
			for (let i = 0; i < files.length; i++) {
				let file = files[i];
				if (!file.type.match('image.*')) {
					toastFlashMessage(file.name + ' is not Valid Format', 'error');
					continue;
				}
				formData.append('files[]', file, file.name);
			}

		}

		var config = {
			onUploadProgress: function (progressEvent) {
				var percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
				// dispatch({
				//   type: actions.PROGRESS_PERCENTAGE,
				//   payload: percentage
				// });
			}
		}
		globalFileUploadService('/technical/engine-workorder/' + props.params.workOrderSlug + '/file-upload/', formData, config)
			.then(response => {
				dispatch(triggerLoader(false));
				if (response.data.statusCode == 200) {
					if (type == 'attachment') {
						dispatch({
							type: actions.SV_UPLOAD_FILE,
							payload: { attachment: response.data.url, name: response.data.name, created_at: moment(), size: response.data.size, user: { id: userInfo.user.id, name: userInfo.user.name, profile_pic: userInfo.user.profile_pic } }
						})
						trackActivity('TE005', {
							event_type: 'File Uploaded',
							page_title: 'Table Inspection',
							workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
							form_slug: props.params.formSlug ? props.params.formSlug : '',
							file_name: files[0].name ? files[0].name : '',
							file_type: files[0].type ? files[0].type : '',
							request_body: formData ? formData : ''
						})
					} else {
						for (let i = 0; i < response.data.data.length; i++) {
							dispatch({
								type: actions.UPDATE_DISS_ASSEM_FIELD,
								payload: { 'image': response.data.data[i].url, sectionType: 'dis_finding', description: '' }
							})
						}
					}
				}
				dispatch(usageDetailAc())
			})
	}
}

export const deleteShopVisitAc = (props, id, type, index) => {
	return (dispatch) => {
		if (type == 'llp_details') {
			if (!id) {
				dispatch({
					type: actions.LLP_DELETE,
					payload: index
				});
			} else {
				dispatch(triggerLoader(true));
				globalDeleteService(`technical/engine-workorder/${props.params.workOrderSlug}/llp-details/${id}/`, {})
					.then(response => {
						dispatch(triggerLoader(false));
						if (response.data.statusCode == 200) {
							dispatch({
								type: actions.LLP_DELETE,
								payload: index
							});
							trackActivity('TE005', {
								event_type: 'LLP Finding Deleted',
								page_title: 'Table Inspection',
								workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
								form_slug: props.params.formSlug ? props.params.formSlug : '',
								item_id: id ? id : '',
								response_body: response.data.data ? response.data.data : '',
								asset_type: props.params.type ? props.params.type : ''
							})
						}
					})
			}
		} else if (type == 'dissasembly') {
			dispatch(triggerLoader(true));
			globalDeleteService(`technical/engine-workorder/${props.params.workOrderSlug}/disassembly/${id}/`, {})
				.then(response => {
					dispatch(triggerLoader(false));
					if (response.data.statusCode == 200) {
						dispatch({
							type: actions.DIS_ASSEM_DELETE,
							payload: index
						});
						trackActivity('TE005', {
							event_type: 'Disassembly Findings Deleted',
							page_title: 'Table Inspection',
							workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
							form_slug: props.params.formSlug ? props.params.formSlug : '',
							item_id: id ? id : '',
							response_body: response.data.data ? response.data.data : '',
							asset_type: props.params.type ? props.params.type : ''
						})
					}
				})
		} else {
			if (!id) {
				dispatch({
					type: actions.DELETE_SV_ATTACHMENT,
					payload: index
				});
			} else {
				dispatch(triggerLoader(true));
				globalDeleteService(`technical/shop-visit-attachment/${id}/delete/`, {})
					.then(response => {
						dispatch(triggerLoader(false));
						if (response.data.statusCode == 200) {
							dispatch({
								type: actions.DELETE_SV_ATTACHMENT,
								payload: index
							});
							trackActivity('TE005', {
								event_type: 'File Deleted',
								page_title: 'Table Inspection',
								workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
								form_slug: props.params.formSlug ? props.params.formSlug : '',
								item_id: id ? id : '',
								response_body: response.data.data ? response.data.data : '',
								asset_type: props.params.type ? props.params.type : ''
							})
						}
					})
			}
			toastFlashMessage('Shop visit attachment deleted successfully', 'success');
		}
	}
}

export const AddEditDisAssemblyAc = (props, dataObj) => {
	console.log(dataObj.data)
	return (dispatch) => {
		dispatch(triggerLoader(true));
		globalPostService('/technical/engine-workorder/' + props.params.workOrderSlug + '/disassembly/', { data: dataObj.data })
			.then(response => {
				dispatch(triggerLoader(false));
				if (response.data.statusCode == 200) {
					if (dataObj.data.id) {
						dispatch({
							type: actions.UPDATE_DISS_ASSEM_DATA,
							payload: response.data.data
						});
					} else {
						dispatch({
							type: actions.ADD_DISS_ASSEM_DATA,
							payload: response.data.data
						});
					}
					toastFlashMessage(response.data.message, 'success');
					trackActivity('TE005', {
						event_type: 'Disassebly Findings Added',
						page_title: 'Table Inspection',
						item_id: dataObj.data.id,
						workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
						form_slug: props.params.formSlug ? props.params.formSlug : '',
						request_body: dataObj.data ? dataObj.data : '',
						response_body: response.data.data ? response.data.data : '',
						asset_type: props.params.type ? props.params.type : ''
					})
				} else {
					trackActivity('TE005', {
						event_type: 'Disassebly Findings Add Failed',
						page_title: 'Table Inspection',
						workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
						form_slug: props.params.formSlug ? props.params.formSlug : '',
						request_body: dataObj.data ? dataObj.data : '',
						response_body: response.data.data ? response.data.data : '',
						asset_type: props.params.type ? props.params.type : '',
						error_msg: response.data.message ? response.data.message : '',
						error_source: 'Backend'
					})
				}
			})
	}
}

export const editViewDisAssemBlkAc = (props, id, type) => {
	return (dispatch) => {
		dispatch(triggerLoader(true));
		globalGetService(`/technical/engine-workorder/${props.params.workOrderSlug}/disassembly/${id}/`, {})
			.then(response => {
				dispatch(triggerLoader(false));
				if (response.data.statusCode == 200) {
					dispatch({
						type: actions.VIEW_DISS_ASSEM_DATA,
						payload: { data: response.data.data, flag: true, type: type }
					})
					trackActivity('TE005', {
						event_type: 'Disassebly Findings Edited',
						page_title: 'Table Inspection',
						item_id: id ? id : '',
						workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
						form_slug: props.params.formSlug ? props.params.formSlug : '',
						type: type ? type : '',
						response_body: response.data.data ? response.data.data : '',
						asset_type: props.params.type ? props.params.type : ''
					})
				}
			})
	}
}

export const saveEditedImageAc = (props, imageId, image, index, crudObj) => {
	var formData = new FormData();
	return (dispatch) => {
		dispatch(triggerLoader(true));
		dataURItoBlob(image).then(editedImage => {
			formData.append('file', editedImage);
			globalPostService(`technical/engine-workorder/${props.params.workOrderSlug}/disassembly-picture/${imageId}/`, formData)
				.then(response => {
					dispatch(triggerLoader(false));
					if (response.data.statusCode == 200) {
						dispatch({
							type: actions.SAVE_EDITED_DA_IMG,
							payload: { image: response.data.url, imageId: imageId, index: index }
						});
						dispatch({
							type: actions.EDIT_DA_IMAGE,
							payload: { flag: false, data: {} }
						});
					}
					dispatch(editViewDisAssemBlkAc(props, crudObj.data.id, crudObj.type))
					toastFlashMessage(response.data.message, 'success');
				})
		})
	}
}

export const deletePicAc = (props, id, index) => {
	return (dispatch) => {
		if (!id) {
			dispatch({
				type: actions.DELETE_DA_IMAGE,
				payload: index
			});
		} else {
			dispatch(triggerLoader(true));
			globalDeleteService(`technical/engine-workorder/${props.params.workOrderSlug}/disassembly-picture/${id}/delete/`, {})
				.then(response => {
					dispatch(triggerLoader(false));
					if (response.data.statusCode == 200) {
						dispatch({
							type: actions.DELETE_DA_IMAGE,
							payload: index
						});
						toastFlashMessage(response.data.message, 'success');
						trackActivity('TE005', {
							event_type: 'Image Deleted',
							page_title: 'Table Inspection',
							workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
							form_slug: props.params.formSlug ? props.params.formSlug : '',
							item_id: id ? id : '',
							item_index: index ? index : '',
							response_body: response.data.data ? response.data.data : '',
							asset_type: props.params.type ? props.params.type : ''
						})
					}
				})
		}
	}
}
export const editPlatePicAc = (props, data) => {
	return (dispatch) => {
		dispatch(triggerLoader(true));
		let fileInfo = Object.assign({}, data);
		globalGetService(`technical/engine-workorder/${props.params.workOrderSlug}/form/${props.params.formSlug}/get-pictures-url/`, { pic_id: data.data.id })
			.then(response => {
				if (response.data.statusCode == 200) {
					fileInfo = {
						...fileInfo,
						image: response.data.data
					}
					dispatch({
						type: actions.EDIT_DA_IMAGE,
						payload: { flag: true, data: fileInfo }
					})
					trackActivity('TE005', {
						event_type: 'Plate Image Updated',
						page_title: 'Table Inspection',
						workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
						form_slug: props.params.formSlug ? props.params.formSlug : '',
						pic_id: data.data.id ? data.data.id : '',
						response_body: response.data.data ? response.data.data : '',
						asset_type: props.params.type ? props.params.type : ''
					})
				}
				dispatch(triggerLoader(false));
			})
	}
}

export const triggerLoader = (flag) => {
	return (dispatch) => {
		dispatch({
			type: actions.TI_LOADER,
			payload: flag
		});
	}
}
