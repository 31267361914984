import React, {useState} from 'react';
import { useSnackbar } from 'notistack';
import { withRouter } from 'react-router';
import { Button, Dialog, DialogContent, DialogTitle, DialogActions, CircularProgress } from '@material-ui/core';
import {DropzoneArea} from 'material-ui-dropzone';
import { globalGetService, globalPostService } from '../../../utils_v2/globalApiServices';
import { checkApiStatus, onCheckFormPermission } from '../../../utils_v2';
const AssetLopa = ({params, getCurrentFormDetail, currentFormDetail}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [isFetching, setFetching] = useState(false);
    const [modal,setModal] = useState(false);
    const [coverImageInfo, setCoverImageInfo] = useState({});
    const [files,setFiles] = useState([]);
    const getCoverImage = () => {
        setFetching(true)
        globalGetService(`technical/workorder/${params.workOrderSlug}/inspection-cover-image/?asset_type=${params.type}`)
        .then(response => {
            if(checkApiStatus){
                setCoverImageInfo(response.data.data);
                setModal(true);
            }
            setFetching(false)
        })
    }
    const onUploadCoverImage = ({}) => {
        let formData = new FormData();    
        formData.append('file', files[0]);
        setLoading(true);
        globalPostService(`technical/workorder/${params.workOrderSlug}/inspection-cover-image/?asset_type=${params.type}`,formData)
        .then(response => {
            if(checkApiStatus(response)){
                setModal(false);
                getCurrentFormDetail()
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
            }
            setLoading(false);
        })
    }
    const handleDropzoneChange = (uploadedFiles) => {
        setFiles(uploadedFiles);
    };
    return(
        <> 
            {onCheckFormPermission(currentFormDetail) ?
            <Button disabled={isFetching} onClick={getCoverImage} color='primary' size='small' variant='contained'>
                {isFetching ? <CircularProgress size={24}/>:'Cover Image'}
            </Button>:null}
            {modal ?
                <Dialog
                    open={modal}
                    onClose={() => setModal(false)}
                    className='project-management-modal'
                    maxWidth='md'
                >
                    <DialogTitle id="scroll-dialog-title"> Cover Image </DialogTitle>    
                    <DialogContent dividers={true}>
                        <div style={{width:'380px'}}>
                            {coverImageInfo?.lopa_image_url?.trim()?.length ?
                                <div style={{position:'relative'}}>
                                    <img style={{width:'380px', maxHeight:'200px'}} src={coverImageInfo.lopa_image_url} alt=''/>
                                </div>:
                                null
                            }
                            <p style={{fontSize:'13px', fontWeight:'500', fontFamily:'Conv_IBMPlexSans-Medium'}}>{coverImageInfo?.lopa_image_url ? 'Change Picture':'Upload Picture'}</p>
                            <DropzoneArea
                                filesLimit={1}
                                maxFileSize={104857600}
                                showPreviewsInDropzone={false}
                                useChipsForPreview={true}
                                acceptedFiles={['.png, .jpg, .jpeg, .heif, .heic']}
                                showPreviews={ true }
                                dropzoneText={  <p style={{fontSize:'13px'}}>Drag & Drop file<br/> OR <br/> Click Here<br/> </p>}
                                dropzoneClass="drag-drop-cnt"
                                maxWidth="sm"
                                showAlerts={['info','error']}
                                alertSnackbarProps={{anchorOrigin: {vertical:'top', horizontal:'right'}}}
                                clearOnUnmount={true}
                                onChange={handleDropzoneChange} 
                            />
                        </div>
                        <span className='files-upload-limit' > Note : only image format.</span>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={isLoading} onClick={() => setModal(false)} color='primary' size="small" variant="outlined">Cancel</Button>
                        {files?.length ?
                            <Button disabled={isLoading} onClick={onUploadCoverImage} color='primary' size="small" variant="contained">
                                {isLoading ? <CircularProgress size={24} />:'SAVE'}
                            </Button>:null
                        }
                    </DialogActions>
                </Dialog>:null
            }
        </>
    )
}
export default withRouter(AssetLopa);