import React, { Fragment } from 'react';
import { Row, Col } from 'reactstrap';
import { FieldCol, FieldGroup, FieldSelect } from '../../../Elements_V';
import CountUp from 'react-countup';
const HeaderCountCards = (props) => {
    const { data } = props;
    // if(data && Object.keys(data).length){
    //   const counters = document.querySelectorAll('.counter');
    //   const speed = 1;
    //   counters.forEach(counter => {
    //     const updateCount = () => {
    //       var target = +counter.getAttribute('data-target') > 0 ? +counter.getAttribute('data-target') : 0;
    //       var count = +counter.innerText;
    //       const increment = target > 0 ? (target / speed) : 0;
    //       if(increment > 0){
    //         if(count < target) {
    //           counter.innerText = Math.ceil(count + increment);
    //           setTimeout(updateCount, 1);
    //         }else{
    //           // count.innerText = target;
    //           console.log('wg', target + increment)
    //           counter.innerText = Math.ceil(target + increment);
    //           setTimeout(updateCount, 1);
    //         }
    //       }
    //     }
    //     updateCount();
    //   })
    // }
    return(
        <div className="t011-header-count">
          { data && Object.keys(data).length ?
            <Row>
              <Col md="2">
                <div className="folder-card">
                  <div className="folder-info vl color-blue">
                    <h4 className="counter" data-target={data.total}><CountUp end={data.total} start={0} duration={data.total < 100 ? 0.75 : 2}/></h4>
                    <p>Total No. Of Items</p>
                  </div>
                </div>
              </Col>
              <Col md="2">
                <div className="folder-card">
                  <div className="folder-info vl color-orange">
                    <h4 className="counter" data-target={data.open}><CountUp end={data.open} start={0} duration={data.open < 100 ? 0.75 : 2}/></h4>
                    <p>Total No. Of Open Items</p>
                  </div>
                </div>
              </Col>
              <Col md="2">
                <div className="folder-card">
                  <div className="folder-info vl color-green">
                    <h4 className="counter" data-target={data.closed}><CountUp end={data.closed} start={0} duration={data.closed < 100 ? 0.75 : 2}/></h4>
                    <p>Total No. Of Closed Items</p>
                  </div>
                </div>
              </Col>
              <Col md="2">
                <div className="folder-card">
                  <div className="folder-info vl color-brown">
                    <h4 className="counter" data-target={data.reopen}><CountUp end={data.reopen} start={0} duration={data.reopen < 100 ? 0.75 : 2}/></h4>
                    <p>Total No. Of Reopen Items</p>
                  </div>
                </div>
              </Col>
            </Row> : null
          }
        </div>
    )
  }
export default HeaderCountCards;
