import React, { Fragment, useState, useEffect } from 'react'
import { withRouter } from 'react-router';
import { useSnackbar } from "notistack";
import moment from 'moment';
import { Grid, Menu, MenuItem, TextField} from '@material-ui/core';
import { globalPutService } from '../../../utils_v2/globalApiServices';
import { checkApiStatus, onCheckPermission, getLocalStorageInfo } from '../../../utils_v2';
import { getPlatformConstantsApi } from "../apiService";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { displayDateFormatShort } from '../../../constants';
import { trackActivity } from '../../../utils/mixpanel';
import { taskAndIssueSelfPermission } from '../';
const TaskStatus = ({canEdit, item, cardType, changeStatus}) => {
    const [statusDropdown, setPltConstants] = useState([]);
    useEffect(() => {
        getPlatformConstants();
    }, []);
    const getPlatformConstants = () => {
        getPlatformConstantsApi({constant_types:[cardType === 'issue'?'issue_status':'task_status']}).then((response) => {
            if (checkApiStatus(response)) {
                setPltConstants(response.data.data)
            }
        })
    }
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClick = (status) => {
        handleClose();
        changeStatus(status)
    };
    return(
        <Fragment>
            <span onClick={(event) => setAnchorEl(event.currentTarget)} className={`milestone-status ${item.status.label.toLowerCase()}`}>
            <span className="flex-centered">
                {item.status.label}
                {canEdit ? <ArrowDropDownIcon fontSize="small" />:null}
            </span>
            </span>
            { canEdit ?
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    >
                    {statusDropdown.map((item) =>
                        <MenuItem onClick={() => handleClick(item)}>{item.label}</MenuItem>
                    )}
                </Menu>:null
            }

        </Fragment>
    )
}
const TaskTitle = ({params, taskDetail, permission, cardType,getResponseBack}) => {
    const userInfo = getLocalStorageInfo();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const [task, setTask] = useState(taskDetail);
    const [error, setError] = useState({});
    let payload = Object.assign({},task)
    delete payload['created_by']
    delete payload['updated_by']
    const onEditTask = (key, message) => {
        if(payload.name){
            globalPutService(`technical/project/${params.project_slug}/${cardType === 'issue' ? 'issue':'tasks'}/${taskDetail.id}/`,payload)
            .then(response => {
                if(checkApiStatus(response)){
                    enqueueSnackbar(response.data.message, { variant: "success", anchorOrigin: { horizontal: "right", vertical: "top" }})
                    getResponseBack();
                    trackActivity('Task Management', {
                        event_type: 'Task/Issue Update Success',
                        page_title: 'Task/Issue Update',
                        project_slug: params.project_slug,
                        task_detail_id: taskDetail.id ? taskDetail.id : '',
                        card_type: cardType === 'issue' ? 'issue':'task',
                        request_body: payload ? payload : '',
                        response_msg: response.data.message
                      });
                }else{
                    enqueueSnackbar(response.data.message, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "top" }});
                    trackActivity('Task Management', {
                        event_type: 'Task/Issue Update Failed',
                        page_title: 'Task/Issue Update',
                        project_slug: params.project_slug,
                        task_detail_id: taskDetail.id ? taskDetail.id : '',
                        card_type: cardType === 'issue' ? 'issue':'task',
                        request_body: payload ? payload : '',
                        error_source:'Backend',
                        error_msg: response.data.message
                      });
                }
            });
        }else{
            setError({...error, [key]:message})
            // enqueueSnackbar('This field is mandatory', { variant: "error", anchorOrigin: { horizontal: "right", vertical: "top" }});
            trackActivity('Task Management', {
                event_type: 'Task/Issue Update Failed',
                page_title: 'Task/Issue Update',
                project_slug: params.project_slug,
                task_detail_id: taskDetail.id ? taskDetail.id : '',
                card_type: cardType === 'issue' ? 'issue':'task',
                request_body: payload ? payload : '',
                error_source: 'Frontend',
                error_msg: 'This field is mandatory'
              });
        }

    }
    const onChangeStatus = (status) => {
        let payload = Object.assign({},task)
        delete payload['created_by']
        delete payload['updated_by']
        payload= {
            ...payload,
            status:status
        }
        globalPutService(`technical/project/${params.project_slug}/${cardType === 'issue' ? 'issue':'tasks'}/${taskDetail.id}/status-update/`,payload)
        .then(response => {
            if(checkApiStatus(response)){
                enqueueSnackbar(response.data.message, { variant: "success", anchorOrigin: { horizontal: "right", vertical: "top" }})
                setTask({...task,status})
            }else{
                enqueueSnackbar(response.data.message, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "top" }})
            }
        });
    }
    return(
        <div className='task-management-tittle'>
            <Grid item xs={12}>
                <TextField
                    disabled={!taskAndIssueSelfPermission(cardType === 'issue' ? 'issues':'tasks','U', task, permission?.update?.value||null)}
                    variant="outlined"
                    value={task.name}
                    onChange={(e) => setTask({...task, name:e.target.value})}
                    onBlur={() => onEditTask('name', 'Please enter the Name')}
                    onFocus={() => setError({...error,name:''})}
                    fullWidth
                    error={error?.name}
                    helperText={error?.name||''}
                />
            </Grid>
            <ul className='list-inline'>
                <li className='list-inline-item'>Created By: {task.created_by && task.created_by.name?task.created_by.name:'--'} </li>
                <li className='list-inline-item'>|</li>
                <li className='list-inline-item'>{task.created_at ? moment(task.created_by).format(displayDateFormatShort):''} </li>
                <li className='list-inline-item'>|</li>
                {task.status?
                    <li className='list-inline-item'>
                        <TaskStatus
                            cardType={cardType}
                            changeStatus={onChangeStatus}
                            canEdit={taskAndIssueSelfPermission(cardType === 'issue' ? 'issues':'tasks','STS', task, permission?.status?.value||null)}
                            item={task}
                        />
                    </li>:null
                }
            </ul>
        </div>
    )

}
export default withRouter(TaskTitle);
