import React, { useEffect, useState} from 'react';
import { withRouter } from 'react-router';
import { useSnackbar } from 'notistack';
import { Paper, TextField, Grid, Button, IconButton, Tooltip } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider,KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { CkEditorText, PageLoader } from '../../../shared_elements';
import { ReadPrerequisite } from '../../Elements'
import EditIcon from '@material-ui/icons/Edit';
import PrerequisiteContent from './PrerequisiteContent'
import { fieldDateFormat, backendDateFormat } from '../../../constants';
import { globalGetService, globalPutService } from '../../../utils_v2/globalApiServices';
import { checkApiStatus } from '../../../utils_v2';
const GeneralDetail = ({currentFormDetail, params, getCurrentFormDetail}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setLoading] = useState(false);
  const [isEditAble, setEditAble] = useState(false);
  const [generalDetail, setGeneralDetail] = useState({});
  const [error, setError] = useState({});
  useEffect(() => {
    getGeneralDetails();
  }, []);
  const getGeneralDetails = () => {
    globalGetService(`technical/v2/workorder/${params.workOrderSlug}/borescope-general-info/`)
    .then(response => {
      if(checkApiStatus(response)){
        setGeneralDetail(response.data.data);
      }else{
        if(response.data.statusCode === 404){
          setGeneralDetail({type_of_report:null, place_of_inspection:'', amm_revision_number:'', amm_revision_date:null, date_of_inspection_from:null, date_of_inspection_to:null, report_date:null, report_issued_place:''})
        }
      }
    })
  }
  const onUpdateGeneralDetails = () => {
    let validationInputs = {
      type_of_report:generalDetail?.type_of_report ? '':'Please select Type of Report',
      place_of_inspection:generalDetail?.place_of_inspection?.trim()?.length ? '':'Please enter Place of Inspection',
      amm_revision_number:generalDetail?.amm_revision_number?.trim()?.length ? '':'Please enter AMM Revision No',
      amm_revision_date:generalDetail?.amm_revision_date ? moment(generalDetail.amm_revision_date).isValid() ? '':'Please enter valid Date' :'Please enter AMM Revision Date',
      date_of_inspection_from:generalDetail?.date_of_inspection_from ? moment(generalDetail.date_of_inspection_from).isValid() ? '':'Please enter valid Date':'Please enter Date of Inspection From',
      date_of_inspection_to:generalDetail?.date_of_inspection_to ? moment(generalDetail.date_of_inspection_to).isValid() ? '':'Please enter valid Date':'',
      report_date:generalDetail?.report_date ? moment(generalDetail.report_date).isValid() ? '':'Please enter valid Date':'Please enter Report Date',
      report_issued_place:generalDetail?.report_issued_place?.trim()?.length ? '':'Please enter Report Issued Place',
    }
    if((generalDetail?.date_of_inspection_from && moment(generalDetail.date_of_inspection_from).isValid()) && (generalDetail?.date_of_inspection_to && moment(generalDetail.date_of_inspection_to).isValid())){
      if(generalDetail?.date_of_inspection_from > generalDetail?.date_of_inspection_to){
        validationInputs = {
          ...validationInputs,
          date_of_inspection_from: 'From Date should be less then To Date'
        }
      }
    }
    if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
      setLoading(true);
      globalPutService(`technical/v2/workorder/${params.workOrderSlug}/borescope-general-info/`, generalDetail)
      .then(response => {
        if(checkApiStatus(response)){
          enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
          setEditAble(false);
          getCurrentFormDetail('pageLoader');
        }
        setLoading(false);
      })
    }else{
      setError(validationInputs);
    }
  }
  const onFieldChange = (key, value) => {
    setGeneralDetail({...generalDetail, [key]:value});
  }
  const canEdit = currentFormDetail?.current_form?.permissions?.can_save;
  return(
    <Paper className="form-detail-card">
      <p className='edit-details'>
        <ReadPrerequisite content={<PrerequisiteContent />} />
        {canEdit && !isEditAble ? 
          <span className='edit-g'>
            <IconButton size="small">
              <Tooltip title='Edit General Detail' arrow><EditIcon onClick={() => setEditAble(true)} fontSize='small' color='primary' /></Tooltip>
            </IconButton></span>:null
        }
      </p>
      <div className='scrollable-content' style={{height: `${window.innerHeight- (isEditAble ? 265:225)}px`, overflow:'auto'}}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={4}>
            <Autocomplete
              disabled={!isEditAble}
              options = {[{label: 'Witness', value: 1},{label: 'Review', value: 2}]}
              getOptionLabel={option => option.label}
              id="type_of_report"
              value={generalDetail?.type_of_report||null}
              onChange={(e, value) => { onFieldChange('type_of_report', value); setError({...error,'type_of_report':''}) }}
              renderInput={params => <TextField required error={error?.type_of_report} helperText={error?.type_of_report||''} {...params} label="Type of Report" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant="outlined" />}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={!isEditAble}
              id="place_of_inspection"
              label="Place of Inspection"
              fullWidth
              required
              margin="normal"
              inputProps={{maxLength: 50}}
              value={generalDetail?.place_of_inspection||''}
              error={error?.place_of_inspection}
              helperText={error?.place_of_inspection||''}
              InputLabelProps={{shrink: true}}
              onChange={(e) => onFieldChange('place_of_inspection', e.target.value)}
              onFocus={() => setError({...error,'place_of_inspection':''})}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={!isEditAble}
              id="amm_revision_number"
              label="AMM Revision No"
              fullWidth
              required
              inputProps={{maxLength: 20}}
              margin="normal"
              value={generalDetail?.amm_revision_number||''}
              error={error?.amm_revision_number}
              helperText={error?.amm_revision_number||''}
              InputLabelProps={{shrink: true}}
              onChange={(e) => onFieldChange('amm_revision_number', e.target.value)}
              onFocus={() => setError({...error,'amm_revision_number':''})}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                  disabled={!isEditAble}
                  margin="normal"
                  id="amm_revision_date"
                  label="AMM Revision Date"
                  format={fieldDateFormat}
                  fullWidth
                  required
                  InputLabelProps={{shrink: true}}
                  value={generalDetail?.amm_revision_date||null}
                  error={error?.amm_revision_date}
                  helperText={error?.amm_revision_date||''}
                  onChange={(data, value) => {onFieldChange('amm_revision_date', data ? moment(data).format(backendDateFormat):data); setError({...error,'amm_revision_date':''})}}
                  inputVariant="outlined"
                />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} md={4}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                  disabled={!isEditAble}
                  margin="normal"
                  id="date_of_inspection_from"
                  label="Date of Inspection From"
                  format={fieldDateFormat}
                  fullWidth
                  required
                  InputLabelProps={{shrink: true}}
                  value={generalDetail?.date_of_inspection_from||null}
                  error={error?.date_of_inspection_from}
                  helperText={error?.date_of_inspection_from||''}
                  onChange={(data, value) => {onFieldChange('date_of_inspection_from', data ? moment(data).format(backendDateFormat):data); setError({...error,'date_of_inspection_from':''})}}
                  inputVariant="outlined"
                />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} md={4}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                  disabled={!isEditAble}
                  margin="normal"
                  id="date_of_inspection_to"
                  label="Date of Inspection To"
                  format={fieldDateFormat}
                  fullWidth
                  InputLabelProps={{shrink: true}}
                  error={error?.date_of_inspection_to}
                  helperText={error?.date_of_inspection_to||''}
                  value={generalDetail?.date_of_inspection_to||null}
                  onChange={(data, value) => {onFieldChange('date_of_inspection_to', data ? moment(data).format(backendDateFormat):data); setError({...error,'date_of_inspection_to':''})}}
                  inputVariant="outlined"
                />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} md={4}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                  disabled={!isEditAble}
                  margin="normal"
                  id="report_date"
                  label="Report Date"
                  format={fieldDateFormat}
                  fullWidth
                  required
                  InputLabelProps={{shrink: true}}
                  value={generalDetail?.report_date||null}
                  error={error?.report_date}
                  helperText={error?.report_date||''}
                  onChange={(data, value) => {onFieldChange('report_date', data ? moment(data).format(backendDateFormat):data); setError({...error,'report_date':''})}}
                  inputVariant="outlined"
                />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              disabled={!isEditAble}
              id="report_issued_place"
              label="Report Issued Place"
              fullWidth
              required
              margin="normal"
              inputProps={{maxLength: 50}}
              value={generalDetail?.report_issued_place||''}
              error={error?.report_issued_place}
              helperText={error?.report_issued_place||''}
              InputLabelProps={{shrink: true}}
              onChange={(e) => onFieldChange('report_issued_place', e.target.value)}
              onFocus={() => setError({...error,'report_issued_place':''})}
              variant="outlined"
            />
          </Grid>
          <Grid item xs='12'>
            <div className='content-editor'>
              <label>Additional Information</label>
              <CkEditorText
                  disabled={!isEditAble}
                  htmlData={generalDetail?.additional_information||''}
                  onBlurData={(data) => onFieldChange('additional_information', data)}
              />
            </div>
          </Grid>
          <Grid item xs='12'>
            <div className='content-editor'>
              <label>Recommendations</label>
              <CkEditorText
                  disabled={!isEditAble}
                  htmlData={generalDetail?.recommendation||''}
                  onBlurData={(data) => onFieldChange('recommendation', data)}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      { isEditAble ?
        <div className='actions-cta'>
          <ul className='list-inline'>
            <li className='list-inline-item'>
              <Button onClick={onUpdateGeneralDetails} size='small' color='primary' variant='contained'>Save</Button>
            </li>
            <li className='list-inline-item'>
              <Button onClick={() => {setEditAble(false);getGeneralDetails(); setError({})}} size='small' color='primary' variant='outlined'>Cancel</Button>
            </li>
          </ul>
        </div>:null
      }
      { isLoading ? <PageLoader />:null }
    </Paper>
  )
}

export default withRouter(GeneralDetail);