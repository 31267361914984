import React, { Component } from 'react';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import { imgStoragePath } from '../../../../constants'
import { browserHistory } from 'react-router'
import InfoIcon from '../../../../shared/assets/img/light_info_icon.svg';
import { trackActivity } from '../../../../utils/mixpanel';
class StatusDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      popoverOpen: false
    }
  }
  togglePopover = () => {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    })
  }

  render() {
    const location = Object.assign({}, browserHistory.getCurrentLocation());
    return (
      <div className="status-details">
        <img width="18" src={InfoIcon} id="status_detail" onClick={() => { this.togglePopover(); trackActivity('Project List', { page_title: 'Project Detail', event_type: 'Clicked', item_type: 'Status Detail Info Icon' }) }} />
        <Popover className="status-details-popover" placement="bottom" isOpen={this.state.popoverOpen} target="status_detail" toggle={() => this.togglePopover()}>
          <PopoverHeader>Project Status</PopoverHeader>
          <PopoverBody>
            <ul>
              <li> <span className="genral-info status-info"></span><b>Upcoming</b>
                <ul style={{ marginLeft: '5px' }}>
                  <li> <span className="initiated status-info"></span>Initiated - Project is set up in the system in advance, but the project is not yet started, applicable to all planned projects.</li>
                </ul>
              </li>
              <li> <span className="genral-info status-info"></span><b>Ongoing</b>
                <ul style={{ marginLeft: '5px' }}>
                  <li> <span className="launched status-info"></span>Launched / In Progress - Project is set up in the system in advance, but the project is not yet started, applicable to all planned projects. </li>
                  <li> <span className="draft status-info"></span>Draft - Project is completed by Rep. and with PM for final QC.</li>
                  <li> <span className="review status-info"></span>Review - Completed report issued to client for review and feedback.</li>
                  <li> <span className="approved status-info"></span>Approved</li>
                </ul>
              </li>
              {location.pathname != '/technical/projects/tracker' ? <li> <span className="published status-info"></span><b>Published</b></li> : null}
              <li> <span className="overdue status-info"></span><b>Overdue</b> - Project end date which has crossed the due date</li>
            </ul>
          </PopoverBody>
        </Popover>
      </div>
    )
  }
}

export default StatusDetails
