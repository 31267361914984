import React, { Component } from 'react';
import ShopVisitView from "./ShopVisitView";
import EngineShopList from "./EngineShopList";
import { NoRecordFound, TableUIComp } from '../../../../shared';
import { Link } from 'react-router';
import BulkOperationShopVisit from "./BulkOperationShopVisit";
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid } from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
export default class ShopVisitDetails extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { content, error } = this.props;
        return (
            <div className="general-detail-block" style={{ background: '#fff' }}>
                <Grid container>
                    <Grid item xs={12} md={12} sm={12}>
                        <Button style={{ float: "right" }} variant="contained" color="primary" size='small' onClick={() => this.props.addShopVisit()}>+ Add Shop Visit </Button>
                    </Grid>
                </Grid>
                <AccordionDetails>
                </AccordionDetails>
                {content.shop_visits && content.shop_visits.length ? content.shop_visits.map((shopDetails, index) =>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" style={{ borderBottom: '1px solid #a8aab1' }} >
                            <h3 style={{ fontSize: '18px', marginBottom: '10px', color: '#2f8cff' }}>ShopVisit #{index + 1}</h3>
                        </AccordionSummary>
                        <AccordionDetails style={{ background: '#efefef' }} >
                            <div style={{ marginBottom: '80px' }}>
                                <div style={{ border: '1px solid #eeeeee', padding: '20px', }}>
                                    <ShopVisitView
                                        type={'edit'}
                                        shopVisit={shopDetails}
                                        index={index}
                                        toggleDelModal={this.props.toggleDelModal}
                                        svError={this.props.svError}
                                        updateForm={this.props.updateForm}
                                    />
                                    <TableUIComp
                                        noStickyHeader={true}
                                        hover={true}
                                        tableBulkOp={<BulkOperationShopVisit GenUrlFn={this.props.GenUrlFn} index={index} addText="Create" addComponent={() => ''} />}
                                        filter={false}
                                        tableHeight="auto"
                                        theads={[{ label: 'File Name', sortKey: '' }, { label: 'Description', sortKey: '' }, { label: 'Uploaded on', sortKey: '' }, { label: 'Uploaded by', sortKey: '' }, { label: 'Action', sortKey: '' }]}
                                        bulkOperation={false}
                                        pagination={false}
                                        content={shopDetails.attachments && shopDetails.attachments.length ? shopDetails.attachments.map((contentDetails, childIndex) => <EngineShopList index={childIndex} shopVisit={contentDetails} key={index} parentIndex={index} updateForm={this.props.updateForm} toggleDelModal={this.props.toggleDelModal} />) : null}
                                        recordsFound={shopDetails.attachments && shopDetails.attachments.length ? false : true}
                                    />
                                </div>

                            </div>
                        </AccordionDetails>
                    </Accordion>
                ) : <NoRecordFound />}


            </div>
        );
    }
}
