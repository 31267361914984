import * as actions from '../actions';
import { globalGetService, globalPutService, globalExportService, globalStaticGetService } from '../../../globalServices';
import { downloadFileType, getLocalStorageInfo } from '../../../utils';
const applicationWidget = {
  technical: 'console/dashboard/technical-details/',
  contacts: 'console/dashboard/contracts-details/',
  maintenance: 'console/dashboard/maintenance-details/',
  records: 'console/dashboard/records-details/'
}

export const technicalProjectsWidgetAc = (props) => {
  return (dispatch) => {
    globalGetService('technical/project-dashboard/', {})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.TECHNICAL_PROJECT_WIDGET,
          payload: response.data.data
        })
      }
    })
  }
}

export const technicalProjectLocationtAc = (props,queryParams={}) => {
  return (dispatch) => {
    globalGetService('technical/project-dashboard/project-location/', queryParams)
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.TECHNICAL_PROJECT_LOCATION,
          payload: response.data.data
        })
      }
    })
  }
}

export const technicalLocationsAc = (props) => {
  return (dispatch) => {
    globalGetService('technical/project-dashboard/locations/', {})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.TECHNICAL_LOCATION,
          payload: response.data.data
        })
      }
    })
  }
}


export const technicalInspectionTypeAc = (props) => {

  return (dispatch) => {
    globalGetService('technical/inspection-types/', {})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.TECHNICAL_INSPECTION_TYPE,
          payload: response.data.data
        })
      }
    })
  }
}

export const technicalAircraftAc = (props) => {
  return (dispatch) => {
    globalGetService('console/aircraft-types/', {})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type:actions.TECHNICAL_AIRCRAFT,
          payload: response.data.data
        })
      }
    })
  }
}
export const technicalDbFindingsAc = (props, queryParams={}) => {
  return (dispatch) => {
    globalGetService('technical/project-dashboard/inspection-findings/', queryParams)
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.TECHNICAL_INSPECTION_FINDINGS,
          payload: response.data.data
        })
      }
    })
  }
}
export const technicalLastAssetsAc = () => {
  return (dispatch) => {
    globalGetService('technical/project-dashboard/project-assets/', {})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.TECHNICAL_PROJECT_ASSETS,
          payload: response.data.data
        })
      }
    })
  }
}
