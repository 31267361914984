import React, { useState } from "react";
import { Paper, Button, TextField, Grid, Table, TableCell, TableHead, TableBody, TableRow, Tooltip, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CancelIcon from '@material-ui/icons/Cancel';
const ComponentSection = ({onFieldChange, sectionError, section, sectionIndex, onAddRemoveFields, onAddRemoveSection, onResetError}) => {
    return(
        <Paper className="component-fields-section">
            <div className='fields-section-hd'>
                <div style={{width:'80%'}}>
                    <TextField
                        id='title'
                        fullWidth
                        margin="none"
                        InputLabelProps={{shrink: true}}
                        value={section.title}
                        onChange={(e) => onFieldChange(e.target.value, 'title', sectionIndex)}
                        onFocus={() => onResetError('section')}
                        variant='outlined'
                    />
                </div>
                <ul className='list-inline'>
                    <li className='list-inline-item'>
                        <Tooltip title='Remove' arrow>
                            <CancelIcon onClick={() => onAddRemoveSection('remove', sectionIndex)} color='secondary' fontSize='small' />
                        </Tooltip>
                    </li>
                </ul>
            </div>
            <div className='fields-section-body'>
                <div style={{marginBottom:'10px', textAlign:'right'}}>
                    <Button onClick={() => onAddRemoveFields('add', sectionIndex)} color='primary' size='small' variant='outlined'>Add Question</Button>
                </div>
                <Table className='mui-table-format'>
                    <TableHead>
                        <TableRow>
                            <TableCell>Label<sup>*</sup></TableCell>
                            <TableCell>Value</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {section.question_answers.map((question, questionIndex) => 
                            <TableRow>
                                <TableCell>
                                    <TextField
                                        id={`label_${questionIndex}`}
                                        fullWidth
                                        margin="none"
                                        error={sectionError?.questionsError?.length && sectionError?.questionsError[questionIndex] && sectionError?.questionsError[questionIndex]?.label}
                                        helperText={sectionError?.questionsError?.length && sectionError?.questionsError[questionIndex] && sectionError?.questionsError[questionIndex]?.label ? sectionError.questionsError[questionIndex].label:''}
                                        InputLabelProps={{shrink: true}}
                                        value={question.label}
                                        onChange={(e) => onFieldChange(e.target.value, 'label', sectionIndex, questionIndex)}
                                        onFocus={() => onResetError('label',questionIndex)}
                                        variant='outlined'
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        id={`value_${questionIndex}`}
                                        fullWidth
                                        margin="none"
                                        InputLabelProps={{shrink: true}}
                                        value={question.value}
                                        onChange={(e) => onFieldChange(e.target.value, 'value', sectionIndex, questionIndex)}
                                        variant='outlined'
                                    />
                                </TableCell>
                                <TableCell>
                                    <Tooltip title='Remove' arrow>
                                        <CancelIcon onClick={() => onAddRemoveFields('remove', sectionIndex, questionIndex)} color='secondary' fontSize='small' />
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                {sectionError?.isDuplicate ? <p className="error-msg">{sectionError?.isDuplicate}</p>:null}
            </div>
        </Paper>
    )
}
export default ComponentSection;