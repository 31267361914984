import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { useSnackbar } from 'notistack';
import { read, utils } from 'xlsx';
import {DropzoneArea} from 'material-ui-dropzone';
import Alert from '@material-ui/lab/Alert';
import { Button, TableRow, TableCell, TextField, Paper, Table, TableHead, TableBody, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Checkbox, CircularProgress, Tooltip, IconButton } from '@material-ui/core';
import { EmptyCollection, DeletePopUp, PageLoader, Pagination, STableLoader } from '../../../shared_elements';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import StatsWidget from './StatsWidget';
import { globalDeleteService, globalGetService, globalPostService, globalPutService } from '../../../utils_v2/globalApiServices';
import { checkApiStatus } from '../../../utils_v2';
import { statusColorCode } from '../'
const recordsObj = {
    status:'',
    description:'',
    remarks:'',
    comments:'',
    ref_document:'',
    operator_comments:''
}
const status = [
    {
        "value": 2,
        "label": "Closed",
    },
    {
        "value": 1,
        "label": "Open",
    },
    {
        "value": 3,
        "label": "Unresolved",
    }
]
const ImportRecords = ({params, getResponseBack}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [modal, setModal] = useState(false)
    const [importRecords, setImportRecords] = useState([]);
    const onFieldChange = (key, value, index) => {
        setImportRecords(importRecords.map((item, itemIndex) => itemIndex === index ? {...item, [key]:value}:item))
    }
    const onFilesDrop = (files) => {
        if (files && files.length > 0) {
            setLoading(true);
            const file = files[0];
            if (file instanceof Blob) {
                const reader = new FileReader();
                reader.onload = function (e) {
                    const buffer = e.target.result;
                    const wb = read(new Uint8Array(buffer));
                    const ws = wb.Sheets[wb.SheetNames[0]];
                    const data = utils.sheet_to_json(ws, { blankRows: false });
                    setLoading(false);
                    if(data.length === 0){
                        enqueueSnackbar("Invalid Data", { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
                        return
                    }
                    let updatedData = data.map(item=>{
                        return {
                            description: item['Description']?item['Description']:'',
                            remarks: item['Remarks']?item['Remarks']:'',
                            status: {value:1, label:'Open'},
                            comments: item['Comments/Notes']?item['Comments/Notes']:'',
                            ref_document: item['Ref Documents']?item['Ref Documents']:'',
                            operator_comments: item['Operator Comments'] ? item['Operator Comments']:'',
                            engine_gap_report_section: null,
                            engine_work_order: null,
                            engine_gap_report_section:2,
                        }
                     });
                     console.log('updatedData',updatedData);
                     setImportRecords(updatedData);
                };
                reader.readAsArrayBuffer(file);
            } else {
                console.error("Invalid file object. Expected a Blob.");
            }
        }
    };
    const onRemoveImportRow = (index) => {
        setImportRecords(importRecords.filter((item, itemIndex) => itemIndex !== index));
    }
    const onImportRecords = () => {
        setLoading(true);
        globalPostService(`technical/v2/workorder/${params.workOrderSlug}/engine-gap-report/record-details/`, {records:importRecords})
        .then(response => {
            if(checkApiStatus(response)){
                setModal(false); setImportRecords([]);
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                getResponseBack();
            }
            setLoading(false);
        })
    }
    return(
        <>
            <Button onClick={() => setModal(true)} color='primary' size='small' variant='contained'>Import</Button>
            { modal ?
                <Dialog
                    open={modal}
                    onClose={() => {setModal(false);setImportRecords([])}}
                    className='project-management-modal'
                    fullScreen
                >
                    <DialogTitle id="scroll-dialog-title">
                        Import Records Details
                    </DialogTitle>
                    <DialogContent dividers={true}>
                        <div style={{minWidth:'440px'}}>
                            <div>
                                <p style={{textAlign:'right'}}>
                                    <a style={{textDecoration:'underline'}} href='https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/templates/TE003_Record_Details_Template.xlsx' download>Download Sample Template</a>
                                </p>
                                <DropzoneArea
                                    acceptedFiles={['.xls, .xlsx']}
                                    filesLimit={1}
                                    maxFileSize={10428800}
                                    showPreviewsInDropzone={false}
                                    useChipsForPreview={false}
                                    showPreviews={false}
                                    dropzoneText={<p style={{fontSize:'13px'}}>Drag & Drop Documents<br/> OR <br/> Click Here<br/> </p>}
                                    dropzoneClass="drag-drop-cnt"
                                    maxWidth="sm"
                                    showAlerts={['info','error']}
                                    alertSnackbarProps={{anchorOrigin: {vertical:'top', horizontal:'right'}}}
                                    clearOnUnmount={true}
                                    onChange={(files) => onFilesDrop(files)}
                                />
                                <Alert severity="info">Note: Please download "Sample Template", edit the template in Excel and upload the same for further customization.</Alert>
                            </div>
                            { importRecords?.length ?
                                <div>
                                    <Table className='mui-table-format'>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Description</TableCell>
                                                <TableCell>Remarks</TableCell>
                                                <TableCell>Comment/Notes</TableCell>
                                                <TableCell>Ref Documents</TableCell>
                                                <TableCell>Operator Comments</TableCell>
                                                <TableCell align='right'>Action(s)</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            { importRecords.map((item, index) => 
                                                <TableRow>
                                                    <TableCell>
                                                        <TextField
                                                            name={`description_${index}`}
                                                            fullWidth
                                                            margin="none"
                                                            inputProps={{maxLength: 250}}
                                                            value={item?.description||''}
                                                            onChange={(e) => onFieldChange('description', e.target.value, index)}
                                                            InputLabelProps={{shrink: true}}
                                                            variant='outlined'
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            name={`remarks_${index}`}
                                                            fullWidth
                                                            margin="none"
                                                            inputProps={{maxLength: 250}}
                                                            value={item?.remarks||''}
                                                            onChange={(e) => onFieldChange('remarks', e.target.value, index)}
                                                            InputLabelProps={{shrink: true}}
                                                            variant='outlined'
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            name={`comments_${index}`}
                                                            fullWidth
                                                            margin="none"
                                                            inputProps={{maxLength: 250}}
                                                            value={item?.comments||''}
                                                            onChange={(e) => onFieldChange('comments', e.target.value, index)}
                                                            InputLabelProps={{shrink: true}}
                                                            variant='outlined'
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            name={`ref_document_${index}`}
                                                            fullWidth
                                                            margin="none"
                                                            inputProps={{maxLength: 250}}
                                                            value={item?.ref_document||''}
                                                            onChange={(e) => onFieldChange('ref_document', e.target.value, index)}
                                                            InputLabelProps={{shrink: true}}
                                                            variant='outlined'
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            name={`operator_comments_${index}`}
                                                            fullWidth
                                                            margin="none"
                                                            inputProps={{maxLength: 250}}
                                                            value={item?.operator_comments||''}
                                                            onChange={(e) => onFieldChange('operator_comments', e.target.value, index)}
                                                            InputLabelProps={{shrink: true}}
                                                            variant='outlined'
                                                        />
                                                    </TableCell>
                                                    <TableCell align='right'>
                                                        <Tooltip title='Delete' arrow>
                                                            <IconButton size="small"> <DeleteOutlineIcon onClick={() => onRemoveImportRow(index)} fontSize='small' color='secondary' /> </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </div>:null
                            }
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={isLoading} onClick={() => {setModal(false); setImportRecords([])}} color="primary" size="small">Cancel</Button>
                        <Button disabled={isLoading || !importRecords?.length} onClick={onImportRecords} variant="contained" color="primary" size="small">
                            {isLoading ? <CircularProgress size={20} /> : 'SAVE' }
                        </Button>
                    </DialogActions>
                </Dialog>:null
            }
        </>
    )
}
const AddEditRecords = ({params, toggleModalFn, addEditRecords, getResponseBack}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false)
    const [error, setError] = useState({});
    const [records, setRecords] = useState(addEditRecords.data);
    const [pltConstants, setPltConstants] = useState([]);
    useEffect(() => {
        getPltConstants();
    },[]);

    const getPltConstants = () => {
        globalPostService(`console/get-constants/`, {constant_types:["engine_gap_report_status"]})
        .then(response => {
            if(checkApiStatus(response)){
                setPltConstants(response.data.data)
            }
        })
    }
    const onFieldChange = (key, value) => {
      setRecords({...records, [key]:value})
    }
    const onAddEditRecords = () => {
        let validationInputs = {
            status:records?.status?.label ? '':'Please select Status',
            description:records?.description?.trim()?.length ? '':'Please enter Description'
        }
        if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
            setLoading(true);
            if(records.id){
                globalPutService(`technical/v2/workorder/${params.workOrderSlug}/engine-gap-report/record-details/${records.id}/`, records)
                .then(response => {
                    if(checkApiStatus(response)){
                        enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                        toggleModalFn(); getResponseBack();
                    }
                    setLoading(false);
                })
            }else{
                globalPostService(`technical/v2/workorder/${params.workOrderSlug}/engine-gap-report/record-details/`, {records:[records]})
                .then(response => {
                    if(checkApiStatus(response)){
                        enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                        toggleModalFn(); getResponseBack();
                    }
                    setLoading(false);
                })
            }
        }else{
            setError(validationInputs);
        }
    }
    return(
        <Dialog
            open={addEditRecords.modal}
            onClose={toggleModalFn}
            className='project-management-modal'
            maxWidth='md'
        >
            <DialogTitle id="scroll-dialog-title">
                {records.id ? 'Edit':'Add'} Records Detail
            </DialogTitle>
            <DialogContent dividers={true}>
                <Grid container spacing={1}>
                    
                    <Grid item xs={12}>
                        <TextField
                            required
                            name="description"
                            label="Description"
                            fullWidth
                            margin="normal"
                            inputProps={{maxLength: 250}}
                            value={records?.description||''}
                            onChange={(e) => onFieldChange('description', e.target.value)}
                            error={error?.description}
                            multiline
                            rows={3}
                            helperText={error?.description||''}
                            InputLabelProps={{shrink: true}}
                            onFocus={(e) => setError({...error,'description':''})}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Autocomplete
                            options = {pltConstants}
                            getOptionLabel={option => option.label}
                            name='status'
                            value={records?.status||null}
                            onChange={(e, data) => onFieldChange('status', data)}
                            renderInput={params => <TextField required error={error?.status} helperText={error?.status||''} onFocus={() => setError({...error, 'status':''})} {...params} label="Status" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined'/>}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="remarks"
                            label="Remarks"
                            fullWidth
                            margin="normal"
                            inputProps={{maxLength: 250}}
                            value={records?.remarks||''}
                            onChange={(e) => onFieldChange('remarks', e.target.value)}
                            multiline
                            rows={3}
                            InputLabelProps={{shrink: true}}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="comments"
                            label="Comments/Notes"
                            fullWidth
                            margin="normal"
                            inputProps={{maxLength: 250}}
                            value={records?.comments||''}
                            onChange={(e) => onFieldChange('comments', e.target.value)}
                            multiline
                            rows={3}
                            InputLabelProps={{shrink: true}}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="ref_document"
                            label="Ref Documents"
                            fullWidth
                            margin="normal"
                            inputProps={{maxLength: 250}}
                            value={records?.ref_document||''}
                            onChange={(e) => onFieldChange('ref_document', e.target.value)}
                            multiline
                            rows={3}
                            InputLabelProps={{shrink: true}}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="operator_comments"
                            label="Operator Comments"
                            fullWidth
                            margin="normal"
                            inputProps={{maxLength: 250}}
                            value={records?.operator_comments||''}
                            onChange={(e) => onFieldChange('operator_comments', e.target.value)}
                            multiline
                            rows={3}
                            InputLabelProps={{shrink: true}}
                            variant='outlined'
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} onClick={toggleModalFn} color="primary" size="small">Cancel</Button>
                <Button disabled={isLoading} onClick={onAddEditRecords} variant="contained" color="primary" size="small">
                    {isLoading ? <CircularProgress size={20} /> : 'SAVE' }
                </Button>
            </DialogActions>
        </Dialog>
    )
}
const RecordDetail = ({params, currentFormDetail, getCurrentFormDetail}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [filter, setFilter] = useState({});
    const [recordsDetailsInfo, setRecordsDetailsInfo] = useState({list:[], pagination:null});
    const [addEditRecords, setAddEditRecords] = useState({data:null, modal:false});
    const [deleteRecords, setDeleteRecords] = useState({data:[], modal:false});
    const [statsSummary, setStatsSummary] = useState({});
    useEffect(() => {
        getRecordDetails({}, 'skeletonLoader');
        getStatsSummary();
    },[]);
    const getStatsSummary = () => {
        globalGetService(`technical/v2/workorder/${params.workOrderSlug}/engine-gap-report/summary/`, {section:1})
        .then(response => {
          if(checkApiStatus(response)){
            setStatsSummary(response.data.data);
          }
        })
    }
    const getRecordDetails = (query, loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true):setLoading(true);
        globalGetService(`technical/v2/workorder/${params.workOrderSlug}/engine-gap-report/record-details/`, query)
        .then(response => {
            if(checkApiStatus(response)){
                setRecordsDetailsInfo(response.data.data)
            }
            loaderType === 'skeletonLoader' ? setSkeletonLoader(false):setLoading(false);
        })
    }
    const handleChange = (type, flag, id) => {
        if(type === 'single'){
            setDeleteRecords({
                ...deleteRecords,
                data: flag ? [...deleteRecords.data, id]:deleteRecords.data.filter(item =>  item !== id)
            })
        }else{
            let ids = recordsDetailsInfo.list.map(item => item.id)
            setDeleteRecords({
                ...deleteRecords,
                data: flag ? ids:[]
            })
        }
    }
    const onDeleteRecords = () => {
        setLoading(true);
        globalDeleteService(`technical/v2/workorder/${params.workOrderSlug}/engine-gap-report/record-details/`,{ids:deleteRecords.data})
        .then(response => {
            if(checkApiStatus(response)){
                setDeleteRecords({modal:false, data:[]});
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                getRecordDetails({}, 'pageLoader');
                getCurrentFormDetail('pageLoader');
                getStatsSummary();
            }
            setLoading(false);
        })
    }
    const canEdit = currentFormDetail?.current_form?.permissions?.can_save;
    return(
        <div className='forms-table-layout'>
            { skeletonLoader ? <STableLoader count={8} />:
                <>
                    <StatsWidget statsSummary={statsSummary} />
                    <Paper className='forms-table-cta' square>
                        <Grid container spacing={1}>
                            <Grid item md={6}></Grid>
                            <Grid item md={6}>
                                { canEdit ?
                                    <ul className='list-inline'>
                                        { deleteRecords?.data?.length ?
                                            <li className='list-inline-item'>
                                                <Button onClick={() => setDeleteRecords({...deleteRecords, modal:true})} color='secondary' size='small' variant='contained'>Delete</Button>
                                            </li>:null
                                        }
                                        <li className='list-inline-item'>
                                            <Button onClick={() => setAddEditRecords({data:recordsObj, modal:true})} color='primary' size='small' variant='contained'>Add Records Detail</Button>
                                        </li>
                                        <li className='list-inline-item'>
                                            <ImportRecords params={params} getResponseBack={() => {getRecordDetails({}, 'pageLoader');getCurrentFormDetail('pageLoader'); getStatsSummary()}} />
                                        </li>
                                    </ul>:null
                                }
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper className='forms-table-content'>
                        <div style={{height: !recordsDetailsInfo.list.length ? 'auto':`${window.innerHeight-337}px`,overflow:'auto', paddingRight:'5px'}}>
                            <Table className='mui-table-format' stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        { canEdit ?
                                            <TableCell style={{width:'40px'}}>
                                                <div style={{width:'40px'}}>
                                                    <Checkbox
                                                        size='small'
                                                        checked={deleteRecords?.data?.length ? deleteRecords?.data?.length === recordsDetailsInfo?.list.length :false}
                                                        onChange={(e) => handleChange('bulk', e.target.checked)}
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                        disabled={!recordsDetailsInfo?.list?.length}
                                                    />
                                                </div>
                                            </TableCell>:null
                                        }
                                        <TableCell>Item No</TableCell>
                                        <TableCell>Description</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Remarks</TableCell>
                                        <TableCell>Comments/Notes</TableCell>
                                        <TableCell>Ref Documents</TableCell>
                                        <TableCell>Operator Comments</TableCell>
                                        <TableCell align='right'>Action(s)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    { recordsDetailsInfo.list.map((item, index) => 
                                        <TableRow key={index}>
                                            { canEdit ?
                                                <TableCell>
                                                    <div style={{width:'40px'}}>
                                                        <Checkbox
                                                            size='small'
                                                            checked={deleteRecords.data.includes(item.id)}
                                                            onChange={(e) => handleChange('single', e.target.checked, item.id)}
                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                        />
                                                    </div>
                                                </TableCell>:null
                                            }
                                            <TableCell>
                                                <div style={{width:'80px'}}>{ (recordsDetailsInfo?.pagination?.per_page||10) *(recordsDetailsInfo.pagination.page-1) + (index+1)}</div>
                                            </TableCell>
                                            <TableCell>
                                                <div style={{width:'220px'}}>{item?.description||'--'}</div>
                                            </TableCell>
                                            <TableCell>
                                                <div style={{width:'100px'}}>
                                                    {item?.status?.label ?
                                                        <span className={`gap-report-status`} style={{background: statusColorCode[item.status.value]}}>{item.status.label}</span>:null
                                                    }
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div style={{width:'220px'}}>{item?.remarks||'--'}</div>
                                            </TableCell>
                                            <TableCell>
                                                <div style={{width:'220px'}}>{item?.comments||'--'}</div>
                                            </TableCell>
                                            <TableCell>
                                                <div style={{width:'220px'}}>{item?.ref_document||'--'}</div>
                                            </TableCell>
                                            <TableCell>
                                                <div style={{width:'220px'}}>{item?.operator_comments||'--'}</div>
                                            </TableCell>
                                            <TableCell align='right'>
                                                { canEdit ?
                                                    <ul className='list-inline' style={{width:'80px', float:'right'}}>
                                                        <li className='list-inline-item'>
                                                            <Tooltip title='Edit' arrow>
                                                                <IconButton size="small"><EditIcon onClick={() =>setAddEditRecords({modal:true, data:item})} fontSize='small' color='primary' /> </IconButton>
                                                            </Tooltip>
                                                        </li>
                                                        <li className='list-inline-item'>
                                                            <Tooltip title='Delete' arrow>
                                                                <IconButton size="small"> <DeleteOutlineIcon onClick={() => setDeleteRecords({modal:true, data:[item.id]})} fontSize='small' color='secondary' /> </IconButton>
                                                            </Tooltip>
                                                        </li>
                                                    </ul>:null
                                                }
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </div>
                        <Pagination 
                            pagination={recordsDetailsInfo.pagination}
                            onChangePage={(event, newPage) => getRecordDetails({...filter, page:newPage+1, per_page: recordsDetailsInfo.pagination.per_page}, 'pageLoader')}
                            onChangeRowsPerPage={(event) => getRecordDetails({...filter, page:1, per_page: event.target.value}, 'pageLoader')}
                        />
                        { !recordsDetailsInfo.list.length ? <EmptyCollection title="No records found" />:null }
                    </Paper>    
                </>
            }
            
            { addEditRecords.modal ?
                <AddEditRecords 
                    params={params}
                    addEditRecords={addEditRecords}
                    toggleModalFn={() => setAddEditRecords({data:null, modal:false})}
                    getResponseBack={() => {getRecordDetails({}, 'pageLoader');getCurrentFormDetail('pageLoader');getStatsSummary()}}
                />:null
            }
            { deleteRecords.modal ?
                <DeletePopUp
                    modal={deleteRecords.modal}
                    toggleModalFn={() => setDeleteRecords({data:[], modal:false})}
                    title="Delete Records Detail(s)"
                    content={<h4>Are you sure you want to delete?</h4>}
                    deleteRecordFn={onDeleteRecords}
                />:null
            }
            { isLoading ? <PageLoader /> :null }
        </div>
    )
}
export default withRouter(RecordDetail);