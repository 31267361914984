import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchReviewDataAc, fetchFormsFeedbackAc, addIssuesAc, fetchIssueDetailAc, changeIssueStatusAc, updateCommentsAc, addCommentsAc, notifyClientAc } from '../actionCreators';
import { imgStoragePath } from "../../../../../constants";
import { ReviewIssueDrawer, ReviewHeader, IssueDetail } from "../components";
import { MSNSwitcherHeader, ListLoader } from "../../../../../shared";
import { TOGGLE_DETAILS, TOOGLE_ADD_ISSUES, TOOGLE_ISSUES_LIST, TOOGLE_EDIT_ISSUES } from '../actions';
import { SecondaryTechHeaderMid, SecondaryTechHeaderRH, EditFormBar } from "../../../Elements";
import { getFormDetailsAc } from "../../T001/actionCreators";
import AddIssues from '../components/AddIssues';
import EditIssues from '../components/EditIssues';
import { trackActivity } from '../../../../../utils/mixpanel';
class ReviewT003 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      document_section_id: '',
      editIssue: '',
    };
  }
  componentDidMount() {
    this.props.fetchReviewData();
    this.props.getFormDetails();
    this.props.fetchFormsFeedback();
    trackActivity('ReviewT003', {
      event_type: 'Page Visited',
      page_title: 'ReviewT003'
    })
  }
  toggleComments = (id) => {
    this.setState({
      document_section_id: id ? id : ''
    });
    this.props.toggleComment();
  };

  toggleEditIssues = () => {
    this.props.toggleEditIssue();
  };

  updateIssues = (value) => {
    this.props.reviewIssueStatusChange(this.state.editIssue.location_slug, value, 'edit');
  }

  toggleEdits = (e, issue, status) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({
      editIssue: issue,
    });
    this.props.toggleEditIssue();
  };

  toggleIssues = () => {
    this.props.toggleIssue();
  };

  toggleDetail = (issue) => {
    issue ? this.props.reviewFeedBackDetail(issue.location_slug) : this.props.toggleDetails();
  };

  deleteStatus = (e, issue, status) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.reviewIssueStatusChange(issue.location_slug, status, 'delete');
  };

  updateComments = (value) => {
    let data = {
      description: value,
      form_id: this.props.formInfo.current_form.id,
      document_section_id: this.state.document_section_id,
    }
    this.props.addIssue(data);
  }

  changeStatus = (e, issue, status) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.reviewIssueStatusChange(issue.location_slug, status);
  };
  changeStatusDetails = (e, location_slug, status) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.reviewIssueStatusChange(location_slug, status, 'details');
  };

  updateCommentStatus = (e, location_slug, commentId) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.updateCommentStatus(location_slug, commentId);
  }


  reviewUIComp = (issue, id) => {

    if (issue.order_number == null || issue.order_number == undefined) {
      return (
        <span onClick={() => this.toggleComments(id)} className="comment-icon" >
          <img src={imgStoragePath + "cmnt_active.svg"} className="blue-icon" alt="" />
          <img src={imgStoragePath + "cmnt-inactive.svg"} className="grey-icon" alt="" />
        </span>
      )
    } else {
      return (
        <span onClick={() => this.toggleIssues()} className="issue-number" >
          <span>#{issue.order_number}</span>
        </span>
      )
    }
  }

  render() {
    const { t003ReviewList, notifyFlag } = this.props;
    const statusImage = {
      1: 'tick_color.png',
      2: 'na_color.png',
      3: 'cross_color.png'
    };
    return (
      <div className="technical-review-model">
        {Object.keys(this.props.formInfo).length ?
          <MSNSwitcherHeader
            SecodaryHeaderRH={<SecondaryTechHeaderRH projectInfo={this.props.formInfo.project} />}
            SecondaryHeaderMid={<SecondaryTechHeaderMid projectInfo={this.props.formInfo.project} activeAsset={this.props.formInfo.asset} />}
            activeAsset={this.props.formInfo.asset}
            projectSlug={Object.keys(this.props.formInfo).length ? this.props.formInfo.project.slug : ''}
          /> : null
        }
        <ReviewHeader
          formInfo={this.props.formInfo}
          notifyFlag={notifyFlag}
          notifyClient={this.props.notifyClient}
          workOrderSlug={this.props.params.workOrderSlug}
        />
        {this.props.commentsLoader ? <ListLoader /> : null}
        <div className="form-t003">
          {t003ReviewList.map((section, sectionIndex) =>
            <div style={{ marginBottom: '30px' }}>
              <h3>{section.name}</h3>
              <div className="form-t003-section">
                {section.sub_sections.map((audits, auditIndex) =>
                  <div className="audit-cn">
                    <h5>{audits.name}</h5>
                    <table>
                      {audits.questions.map((question, index) =>
                        <tr>
                          <td>{question.name}</td>
                          <td>{question.answer.remarks}</td>
                          <td><img src={imgStoragePath + statusImage[question.answer.value]} alt="" /></td>
                          <td className="comments-wrapper">{this.reviewUIComp(question.issue, question.id)}</td>
                        </tr>
                      )}
                    </table>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <a href="javascript:void(0)" className="view-issues-btn" onClick={() => this.toggleIssues()} >
          <img src={imgStoragePath + 'icon_CommentDash.svg'} alt="" />
        </a>
        <EditFormBar
          titleContent={<div><h3 style={{ textTransform: 'capitalize' }}> {'Post Issues'} </h3></div>}
          toggleEditSideBar={() => this.toggleComments()}
          isOpen={this.props.toggleComments}
          style={this.props.toggleComments ? { width: '400px' } : { width: '0px' }}>
          {this.props.toggleComments ?
            <AddIssues
              updateComments={this.updateComments}
              toggleEditSideBar={() => this.toggleComments()}
            /> : null
          }
        </EditFormBar>
        <EditFormBar
          titleContent={<div><h3 style={{ textTransform: 'capitalize' }}> {'Edit Issue'} </h3></div>}
          toggleEditSideBar={() => this.toggleEditIssues()}
          isOpen={this.props.toggleEditIssues}
          style={this.props.toggleEditIssues ? { width: '400px' } : { width: '0px' }}>
          {this.props.toggleEditIssues ?
            <EditIssues
              updateComments={this.updateIssues}
              issueData={this.state.editIssue}
              toggleEditSideBar={() => this.toggleEditIssues()}
            /> : null
          }
        </EditFormBar>
        <EditFormBar
          titleContent={<div><h3 style={{ textTransform: 'capitalize' }}> {'Issues List'} </h3></div>}
          toggleEditSideBar={() => this.toggleIssues()}
          isOpen={this.props.toggleIssues}
          style={this.props.toggleIssues ? { width: '400px' } : { width: '0px' }}>
          {this.props.toggleIssues ?
            <ReviewIssueDrawer
              issueList={this.props.reviewFeedbacksList}
              toggleEditSideBar={() => this.toggleComments()}
              toggleDetailBar={this.toggleDetail}
              changeStatus={this.changeStatus}
              deleteStatus={this.deleteStatus}
              editStatus={this.toggleEdits}
            /> : null}
        </EditFormBar>
        <EditFormBar
          titleContent={<div><h3 style={{ textTransform: 'capitalize' }}> {'Issues Discussion'} </h3></div>}
          toggleEditSideBar={() => this.toggleDetail()}
          isOpen={this.props.toggleDetail}
          style={this.props.toggleDetail ? { width: '400px' } : { width: '0px' }}>
          {this.props.toggleDetail ?
            <IssueDetail
              issueDetailInfo={this.props.issueDetailInfo}
              toggleEditSideBar={() => this.toggleDetail()}
              toggleSideBars={() => this.toggleIssues()}
              addComments={this.props.addComments}
              changeStatus={this.changeStatusDetails}
              updateCommentStatus={this.updateCommentStatus}
            />
            : null}
        </EditFormBar>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  t003ReviewList: state.FormReviewReducer.t003ReviewList,
  reviewFeedbacksList: state.FormReviewReducer.reviewFeedbacksList,
  formInfo: state.FormT001Reducer.formInfo,
  toggleComments: state.FormReviewReducer.toggleComments,
  toggleIssues: state.FormReviewReducer.toggleIssues,
  toggleDetail: state.FormReviewReducer.toggleDetail,
  toggleEditIssues: state.FormReviewReducer.toggleEditIssues,
  issueDetailInfo: state.FormReviewReducer.issueDetailInfo,
  commentsLoader: state.FormReviewReducer.commentsLoader,
  notifyFlag: state.FormReviewReducer.notifyFlag
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchReviewData: () => dispatch(fetchReviewDataAc(ownProps, 't003')),
    fetchFormsFeedback: () => dispatch(fetchFormsFeedbackAc(ownProps, 't003')),
    reviewFeedBackDetail: (locationSlug) => dispatch(fetchIssueDetailAc(ownProps, locationSlug, 't003')),
    getFormDetails: () => dispatch(getFormDetailsAc(ownProps)),
    reviewIssueStatusChange: (locationSlug, status, flag = '') => dispatch(changeIssueStatusAc(ownProps, locationSlug, status, 't003', flag)),
    addIssue: (data) => dispatch(addIssuesAc(ownProps, data, 't003')),
    notifyClient: () => dispatch(notifyClientAc(ownProps)),
    addComments: (location_slug, message) => dispatch(addCommentsAc(location_slug, message)),
    updateCommentStatus: (locationSlug, commentID) => dispatch(updateCommentsAc(ownProps, locationSlug, commentID)),
    toggleComment: () => dispatch({
      type: TOOGLE_ADD_ISSUES,
      payload: {}
    }),
    toggleIssue: () => dispatch({
      type: TOOGLE_ISSUES_LIST,
      payload: {}
    }),
    toggleEditIssue: () => dispatch({
      type: TOOGLE_EDIT_ISSUES,
      payload: {}
    }),
    toggleDetails: () => dispatch({
      type: TOGGLE_DETAILS,
      payload: {}
    }),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ReviewT003);
