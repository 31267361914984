import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { Paper, Table, TableBody, TableHead, TableCell,  TableRow } from '@material-ui/core';
import { useTableStyle } from '../../utils/materialStyles';
const STableLoader = ({count}) => {
  const classes = useTableStyle();
  return(
    <Paper>
      <Table
        className={classes.table}
        aria-labelledby="tableTitle"
        stickyHeader={true}
        size='medium'
      >
        <TableHead className="mui-table">
          <TableRow>
            {Array(count).fill(0).map((item, index) =>
              <TableCell align="left">
                <Skeleton variant="rect" animation='wave' height={24} />
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
        {Array(6).fill(0).map((row, index) =>
          <TableRow key={index}>
            {Array(count).fill(0).map((item, index) =>
              <TableCell align="left">
                <Skeleton variant="rect" animation='wave' height={34} />
              </TableCell>
            )}
          </TableRow>
        )}
        </TableBody>
      </Table>
    </Paper>
  )
}
export default STableLoader;
