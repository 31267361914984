import React from "react";
import {Paper} from '@material-ui/core';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/offline-exporting')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);
const BudgetExpenseBarChart = ({project, allocationVsExpense}) => {
    const chartOptions = {
        chart: {
            type: 'column'
        },
        title: {
            text: 'Allocation Vs Expense',
            align: 'left'
        },
        plotOptions: {
            series: {
                grouping: false,
                borderWidth: 0
            }
        },
        legend: {
            enabled: false
        },
        tooltip: {
            shared: false,
            backgroundColor: "rgba(255,255,255,1)",
            headerFormat: '<span style="font-size: 15px">{point.point.name}</span><br/>',
            pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}<b>{point.y} </b><br/>',
            
        },
        xAxis: {
            type: 'category',
            labels: {
                useHTML: true,
                animate: true
            }
        },
        yAxis: [{
            title: {
                text: project.currency ? 'Amount in ' + project.currency:''
            },
            showFirstLabel: false
        }],
        series: [{
            color: 'rgba(158, 159, 163, 0.5)',
            pointPlacement: -0.2,
            linkedTo: 'main',
            data: allocationVsExpense.series_allocation?allocationVsExpense.series_allocation:[],
            name: 'Allotment '+(project.currency?project.currency:'') +':',
        }, {
            name: 'Expense '+(project.currency?project.currency:' ') +':',
            id: 'main',
            dataSorting: {
                enabled: true,
                matchByName: true
            },
            data: allocationVsExpense.series_expense ? allocationVsExpense.series_expense:[]
        }],
        credits: {
            enabled: false
        },
        exporting: {
            enabled: false
            // buttons: {
            //   contextButton: {
            //     menuItems: ['downloadPNG', 'downloadSVG'],
            //   },
            // },
        }
    }
    return(
        <Paper>
            <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions}
            />
        </Paper>
    )
}
export default BudgetExpenseBarChart;