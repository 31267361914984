import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router';
import { TextInputField, DateInputField, GetInputField } from '../../../Elements';
import moment from 'moment';
import { imgStoragePath, displayDateFormat } from '../../../../../constants';


export default class ShopvisitFileList extends Component {
  constructor(props) {
    super(props);
  }


  updateForm = () => {

  }
  render() {
    const { shopVisit, error, index, detailErr, userInfo } = this.props;
    let type = 'edit';
    return (
      <tr className="shopvisit-acctacment-row">
        <td style={{ maxWidth: '300px' }}>{shopVisit.name ? shopVisit.name : '--'}</td>
        <td >
          <div>
            <TextInputField
              value={shopVisit.description}
              type={'edit'}
              keyParam="description"
              error={''}
              updateForm={(value) => this.props.updateForm({ type: 'description', value: value, sectionType: 'attachment', index: this.props.index })}
            />
          </div>
        </td>
        <td>
          {shopVisit.updated_at ? moment(shopVisit.created_at).format(displayDateFormat) : '--'}
        </td>
        <td>
          <div>
            <img className="profile-pic" src={shopVisit.user.profile_pic} alt="img" />
            <span>{shopVisit.user.name}</span>
          </div>
        </td>
        <td className="download-block">
          <Link target="_blank" to={shopVisit.attachment}>
            <img width="12" className="" src={imgStoragePath + "import_images_icons.png"} onClick={this.props.toggleComDelModal} alt="Download Icon" />
          </Link>
          {
            userInfo?.permission['technical']['projects']?.indexOf('D') != '-1' ?
              <img width="12" onClick={() => this.props.toggleDelModal(shopVisit.id, 'attachment', index)} src={imgStoragePath + "delete_red.png"} alt="Delete Icon" />
              : null}
        </td>
      </tr>
    )
  }
}
