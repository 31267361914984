import React, { useState, useEffect } from "react";
import { useSnackbar } from 'notistack';
import { withRouter } from "react-router";
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, Grid, Button, TextField, Dialog, DialogContent, DialogTitle, DialogActions, Tooltip, CircularProgress } from '@material-ui/core';
import { Pagination, STableLoader, PageLoader, EmptyCollection, DeletePopUp, AutocompleteCreatable } from "../../../shared_elements";
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { globalDeleteService, globalGetService, globalPostService, globalPutService } from "../../../globalServices";
import { checkApiStatus, onCheckFormPermission } from '../../../utils_v2';
import InfoIcon from '@material-ui/icons/Info';
import SpecsImport from "./SpecsImport";
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
const specsobj = {
    category: '',
    description: '',
    value_1: '',
    value_2: '',
    source_document: '',
    value_1_guidance_notes: '',
    value_2_guidance_notes: ''
}
const SpecsHd = ({ currentFormDetail }) => {
    return (
        <TableHead>
            <TableRow>
                <TableCell>Category</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Value 1</TableCell>
                <TableCell>Value 2</TableCell>
                <TableCell>Source Document</TableCell>
                {onCheckFormPermission(currentFormDetail) ? <TableCell align="right">Action(s)</TableCell> : null}
            </TableRow>
        </TableHead>
    )
}
const SpecsList = ({ item, onEdit, onDelete, currentFormDetail }) => {
    const [showMore, setShowMore] = useState({});
    const toggleComment = (field) => {
        setShowMore({
            ...showMore,
            [field]: !showMore[field],
        });
    };
    return (
        <TableRow>
            <TableCell>
                <div>{item?.category || '--'}</div>
            </TableCell>
            <TableCell>
                {item?.description && item?.description?.length > 30 ? (
                    <div style={{ width: '200px' }} >
                        {showMore[item?.field] && showMore[item?.field] ? (
                            <span> {item?.description}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleComment(item.field); }}  >  less </a>
                            </span>
                        ) : (
                            <span> {item.description.substring(0, 25)}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleComment(item.field); }}  > ..more </a>
                            </span>
                        )}
                    </div>
                ) :
                    <div style={{ width: '115px' }}>{item?.description || '--'}</div>
                }
            </TableCell>
            <TableCell>
                {item?.value_1 && item?.value_1?.length > 30 ? (
                    <div style={{ width: '200px' }} >
                        {showMore[item?.field] && showMore[item?.field] ? (
                            <span> {item?.value_1}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleComment(item.field); }}  >  less </a>
                            </span>
                        ) : (
                            <span> {item.value_1.substring(0, 25)}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleComment(item.field); }}  > ..more </a>
                            </span>
                        )}
                    </div>
                ) :
                    <div style={{ width: '115px' }}>{item?.value_1 || '--'}</div>
                }
            </TableCell>
            <TableCell>
                {item?.value_2 && item?.value_2?.length > 30 ? (
                    <div style={{ width: '200px' }} >
                        {showMore[item?.field] && showMore[item?.field] ? (
                            <span> {item?.value_2}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleComment(item.field); }}  >  less </a>
                            </span>
                        ) : (
                            <span> {item.value_2.substring(0, 25)}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleComment(item.field); }}  > ..more </a>
                            </span>
                        )}
                    </div>
                ) :
                    <div style={{ width: '115px' }}>{item?.value_2 || '--'}</div>
                }
            </TableCell>
            <TableCell>
                {item?.source_document && item?.source_document?.length > 30 ? (
                    <div style={{ width: '200px' }} >
                        {showMore[item?.field] && showMore[item?.field] ? (
                            <span> {item?.source_document}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleComment(item.field); }}  >  less </a>
                            </span>
                        ) : (
                            <span> {item.source_document.substring(0, 25)}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleComment(item.field); }}  > ..more </a>
                            </span>
                        )}
                    </div>
                ) :
                    <div style={{ width: '115px' }}>{item?.source_document || '--'}</div>
                }
            </TableCell>
            {onCheckFormPermission(currentFormDetail) ?
                <TableCell align="right">
                    <ul className="list-inline" style={{ width: '60px', float: 'right' }}>
                        <li className="list-inline-item hover-inline" onClick={onEdit}>
                            <Tooltip title='Edit' style={{ cursor: 'pointer' }} arrow><EditIcon color="primary" fontSize="small" /></Tooltip>
                        </li>
                        <li className="list-inline-item hover-inline" onClick={onDelete}>
                            <Tooltip title='Delete' style={{ cursor: 'pointer' }} arrow><DeleteOutlineIcon color="secondary" fontSize="small" /></Tooltip>
                        </li>
                    </ul>
                </TableCell> : null
            }
        </TableRow>
    )
}
const AddEditSpecs = ({ params, addEditSpec, toggleModalFn, getResponseBack }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [spec, setSpec] = useState(addEditSpec.data);
    const [error, setError] = useState({});
    const onFieldChange = (key, value) => {
        setSpec({ ...spec, [key]: value.trimStart() })
    }
    const onAddEditSpecs = () => {
        let validationInputs = {
            category: spec.category ? "" : 'Please enter Category',
        }
        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
            const payload = Object.assign({}, (!spec.id ? { 'specs': [spec] } : spec))
            setLoading(true);
            if (spec.id) {
                globalPutService(`technical/workorder/${params.workOrderSlug}/t014/specs/${spec.id}/?type=T014&asset_type=${params.type}`, payload)
                    .then(response => {
                        if (checkApiStatus(response)) {
                            enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                            toggleModalFn(); getResponseBack();
                        }
                        setLoading(false);
                    })
            } else {
                globalPostService(`technical/workorder/${params.workOrderSlug}/t014/specs/?type=T014&asset_type=${params.type}`, payload)
                    .then(response => {
                        if (checkApiStatus(response)) {
                            enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                            toggleModalFn(); getResponseBack();
                        }
                        setLoading(false);
                    })
            }
        } else {
            setError(validationInputs)
        }
    }
    const isFieldEmpty = () => {
        if(spec.id){
            return Object.entries(spec).filter(([key]) => key !== 'id').some(([_, value]) => value !== '' && value !== null);        
        }else{
            return Object.values(spec).some(value => value !== '' && value !== null);
        }
    };
    return (
        <Dialog
            open={addEditSpec.modal}
            onClose={toggleModalFn}
            className='project-management-modal'
            maxWidth="md"
        >
            <DialogTitle id="scroll-dialog-title">
                {spec?.id ? 'Edit ' : 'Add '} Specs
            </DialogTitle>
            <DialogContent dividers={true}>
                <Grid container spacing={1}>
                    <Grid item md={4}>
                        <TextField
                            required
                            name='category'
                            label='Category'
                            value={spec?.category || ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('category', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 255 }}
                            variant='outlined'
                            error={error?.category ? true : false}
                            helperText={error?.category || ''}
                            onFocus={() => setError({ ...error, 'category': '' })}
                        />
                    </Grid>

                    <Grid item md={4}>
                        <TextField
                            name='description'
                            label='Description'
                            value={spec?.description || ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('description', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 255 }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={4}>
                        <TextField
                            name='value_1'
                            label={
                                <div>Value 1
                                    {spec?.value_1_guidance_notes ?
                                        <Tooltip title={spec?.value_1_guidance_notes} placement="bottom" >
                                            <InfoIcon fontSize="small" style={{ verticalAlign: 'bottom', marginLeft: '3px' }} />
                                        </Tooltip> : null}
                                </div>
                            }
                            value={spec?.value_1 || ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('value_1', e.target.value)}
                            InputLabelProps={{ shrink: true, style: { pointerEvents: "auto" } }}
                            variant='outlined'
                            placeholder={spec.value_1_guidance_notes}
                            inputProps={{ maxLength: 255 }}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <TextField
                            name='value_2'
                            label={
                                <div>Value 2
                                    {spec?.value_2_guidance_notes ?
                                        <Tooltip title={spec?.value_2_guidance_notes} placement="bottom" >
                                            <InfoIcon fontSize="small" style={{ verticalAlign: 'bottom', marginLeft: '3px' }} />
                                        </Tooltip> : null}
                                </div>
                            }
                            value={spec?.value_2 || ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('value_2', e.target.value)}
                            InputLabelProps={{ shrink: true, style: { pointerEvents: "auto" } }}
                            variant='outlined'
                            placeholder={spec.value_2_guidance_notes}
                            inputProps={{ maxLength: 255 }}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <TextField
                            name='source_document'
                            label='Source Document'
                            value={spec?.source_document || ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('source_document', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 255 }}
                            variant='outlined'
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} onClick={toggleModalFn} color='primary' size='small' variant='outlined'>Cancel</Button>
                <Button disabled={isLoading || !isFieldEmpty()} onClick={onAddEditSpecs} color='primary' size='small' variant='contained'>
                    {isLoading ? <CircularProgress size={24} /> : 'Save'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
const Specs = ({ params, getCurrentFormDetail, currentFormDetail }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [filter, setFilter] = useState({});
    const [specInfo, setSpecInfo] = useState({ list: [], pagination: null });
    const [addEditSpec, setAddEditSpec] = useState({ modal: false, data: null });
    const [deleteSpec, setDeleteSpec] = useState({ modal: false, data: null });
    useEffect(() => {
        getSpec('skeletonLoader');
    }, []);
    const getSpec = (query,loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true) : setLoading(true)
        globalGetService(`technical/workorder/${params.workOrderSlug}/t014/specs?type=T014&asset_type=${params.type}`,query)
            .then(response => {
                if (checkApiStatus(response)) {
                    setSpecInfo(response.data.data)
                    getCurrentFormDetail()
                }
                loaderType === 'skeletonLoader' ? setSkeletonLoader(false) : setLoading(false)
            })
    }
    const onDeleteSpec = () => {
        setLoading(true);
        globalDeleteService(`technical/workorder/${params.workOrderSlug}/t014/specs/${deleteSpec?.data?.id}/?type=T014&asset_type=${params.type}`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setDeleteSpec({ modal: false, data: null });
                    enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                    getSpec({}, 'pageLoader');
                }
                setLoading(false);
            })
    }
    return (
        <>
            <div className="tech-specs-content">
                {skeletonLoader ? <STableLoader count={8} /> :
                    <div style={{ padding: '10px' }}>
                        <Paper square style={{ padding: '4px 8px' }}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item md={9}></Grid>
                                <Grid item md={3}>
                                    {onCheckFormPermission(currentFormDetail) ?
                                        <ul className="list-inline" style={{ float: 'right' }}>
                                            <li className="list-inline-item">
                                                <SpecsImport getResponseBack={() => getSpec({})} />
                                            </li>
                                            <li className="list-inline-item">
                                                <Button onClick={() => setAddEditSpec({ modal: true, data: specsobj })} color="primary" size='small' variant="contained"><AddOutlinedIcon style={{ fontSize: '16px' }} />ADD</Button>
                                            </li>
                                        </ul> : null
                                    }
                                </Grid>
                            </Grid>
                        </Paper>
                        <Paper style={{ maxHeight: window.innerHeight - 235 + 'px', overflow: "scroll" }}>
                            <Table className='mui-table-format' stickyHeader>
                                <SpecsHd currentFormDetail={currentFormDetail} />
                                <TableBody>
                                    {specInfo?.list?.map((item, index) =>
                                        <SpecsList
                                            key={index}
                                            item={item}
                                            currentFormDetail={currentFormDetail}
                                            onEdit={() => setAddEditSpec({ modal: true, data: item })}
                                            onDelete={() => setDeleteSpec({ modal: true, data: item })}
                                        />
                                    )}
                                </TableBody>
                            </Table>
                        </Paper>
                        {specInfo?.pagination ?
                            <Paper>
                                <Pagination
                                    pagination={specInfo.pagination}
                                    onChangePage={(event, newPage) => getSpec({ ...filter, page: newPage + 1, per_page: specInfo.pagination.per_page }, 'pageLoader')}
                                    onChangeRowsPerPage={(event) => getSpec({ ...filter, page: 1, per_page: event.target.value }, 'pageLoader')}
                                />
                            </Paper>
                            : null
                        }
                        <Paper>
                            {!specInfo?.list?.length ? <div style={{ textAlign: 'center' }}><EmptyCollection title='No records found' /></div> : null}
                        </Paper>
                    </div>
                }
            </div>
            {addEditSpec.modal ?
                <AddEditSpecs
                    params={params}
                    addEditSpec={addEditSpec}
                    toggleModalFn={() => setAddEditSpec({ modal: false, data: null })}
                    getResponseBack={() => getSpec({}, 'pageLoader')}
                /> : null
            }
            {deleteSpec.modal ?
                <DeletePopUp
                    modal={deleteSpec.modal}
                    title='Delete Spec'
                    content={`Are you sure, you want to Delete?`}
                    toggleModalFn={() => setDeleteSpec({ modal: false, data: null })}
                    deleteRecordFn={onDeleteSpec}
                /> : null
            }
            {isLoading ? <PageLoader /> : null}
        </>

    )
}
export default withRouter(Specs);