import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { DbBackCom, TechnicalLocations, AssetWidget } from '../components';
import { FieldCol, FieldGroup, FieldLabel, FieldSelect } from '../../technicalInspection/Elements_V';
import { imgStoragePath, projectStatus } from '../../../constants';
import { getLocalStorageInfo } from '../../../utils';
import RoleBasedAccess from '../../../utils/RoleBasedAccess'
import { trackActivity } from '../../../utils/mixpanel';
import { technicalProjectsWidgetAc, technicalAircraftAc, technicalLocationsAc, technicalDbFindingsAc, technicalProjectLocationtAc, technicalInspectionTypeAc, technicalLastAssetsAc } from '../actionCreators';
import '../assets/styles/dashboard.scss';
import DeploymentMessage from '../../../shared/components/DeploymentMessage';
class InspectionFleetDb extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formQuery: {},
      locQuery: {},
      height: 440
    };
  }
  componentDidMount() {
    this.props.technicalProjectsWidget();
    this.props.technicalLocation();
    this.props.technicalAircraft();
    this.props.technicalInspectionType();
    this.props.technicalInspectionFindings({});
    this.props.technicalProjectLocation({});
    this.props.technicalLastAssets();
    trackActivity('Dashboard', {
      event_type: 'Page Visited',
      page_title: 'Technical Dashboard'
    })
  }
  updateForm = (type, value) => {
    let data = Object.assign({}, this.state.formQuery);
    let newData = {
      ...data,
      [type]: value
    }
    if (type == 'location') {
      if (value == 'All' || value == '') {
        delete newData['location'];
      }
    }
    if (type == 'aircraft_type') {
      if (value == '') {
        delete newData['aircraft_type'];
      }
    }
    this.setState({
      formQuery: newData
    });

    this.props.technicalInspectionFindings(newData);
  }
  updateLocForm = (type, value) => {
    let data = Object.assign({}, this.state.locQuery);
    let newData = {
      ...data,
      [type]: value
    }
    this.setState({
      locQuery: newData
    });
    this.props.technicalProjectLocation(newData);
  }
  getStyleClass = (id) => {
    switch (id) {
      case 1:
        return 'atrweb flex-center model';
      case 23:
        return 'heliweb flex-center model';
      case 4:
        return 'narro-with-winglet flex-center model';
      case 5:
        return 'narro-without-winglet flex-center model';
      case 2:
        return 'q400 flex-center model';
      case 10:
        return 'three-engine flex-center model';
      case 8:
        return 'wide-four-engine-with-winglet flex-center model';
      case 9:
        return 'wide-four-engine-without-winglet flex-center model';
      case 6:
        return 'wide-two-engine-with-winglet text-center model';
      case 7:
        return 'wide-two-engine-without-winglet flex-center model';
      case 11:
        return 'crj flex-center model';
      case 12:
        return 'crj flex-center model';
    }
  }
  render() {
    const { loactionsList, airCraftTypeList, technicalProjectWidget, dbAssetLoader, inspectionData, technicalLocationData, technicalInspectionData, technicalProjectAssets } = this.props;
    const projectWidget = [
      { type: 'all', url: '/technical/projects?project_status=all', title: 'TOTAL PROJECTS', classes: 'widget-cards  marginRight  flex-centered total-count' },
      { type: 'active', url: '/technical/projects?project_status=ongoing', title: 'ACTIVE PROJECTS', classes: 'widget-cards marginRight marginLeft flex-centered on-lease' },
      { type: 'upcoming', url: '/technical/projects?project_status=upcoming', title: 'UPCOMING PROJECTS', classes: 'widget-cards marginRight marginLeft flex-centered off-lease' },
      { type: 'overdue', url: '/technical/projects?project_status=overdue', title: 'OVERDUE PROJECTS', classes: 'widget-cards marginRight marginLeft flex-centered on-ground' },
      { type: 'completed', url: '/technical/projects?project_status=completed', title: 'COMPLETED PROJECTS', classes: 'widget-cards marginLeft flex-centered written-off' }
    ];
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: technicalProjectAssets.length <= 2 ? technicalProjectAssets.length : 3,
      slidesToScroll: 1,
      variableWidth: true,
      adaptiveHeight: true,
    };
    return (
      <div className="fleet-technical-db">
        <DeploymentMessage />
        <DbBackCom pageTitle="Projects Management Dashboard" />
        <Row className="assets-row" style={{ marginLeft: '5px', marginRight: '6px' }}>
          {projectWidget.map((project, index) =>
            <AssetWidget
              className={project.classes}
              statusGrpTitle={project.title}
              value={technicalProjectWidget[project.type]}
              url={project.url}
              dbAssetLoader={dbAssetLoader}
            />
          )}
        </Row>
        <Row className="technical-manpower-info">
          {
            getLocalStorageInfo().user.permission.technical && !getLocalStorageInfo().user.permission.technical.is_saas && getLocalStorageInfo().user.type.value == 1 ?
              <Col md="6">
                <Row>
                  {[{ title: 'Total MANPOWER', value: technicalProjectWidget.total_manpower }, { title: 'Engaged MANPOWER', value: technicalProjectWidget.engaged_manpower }].map((stats, index) =>
                    <Col md="6">
                      <div className="manpower-widget flex-centered" style={{ cursor: "alias !important" }}>
                        <p>
                          <h5>{stats.value}</h5>
                          <h6>{stats.title}</h6>
                        </p>
                      </div>
                    </Col>
                  )}
                </Row>
              </Col>
              : null
          }
          <Col md="6">
            <table>
              <tr>
                <td>
                  <h5>{technicalProjectWidget.total_assets}</h5>
                  <h6>Total Work Orders</h6>
                </td>
                <td>
                  <img src={imgStoragePath + "aircraft_circle.png"} alt=" " />
                </td>
                <td>
                  <h5>{technicalProjectWidget.active_assets}</h5>
                  <h6>Total Active Work Orders</h6>
                </td>
              </tr>
            </table>
          </Col>
        </Row>
        <Row style={{ marginBottom: '20px' }}>
          <Col md="12">
            <div className="projects-location">
              <Row style={{ padding: '4px 5px 1px 0' }}>
                <Col md="5">
                  <h4>Location of Projects</h4>
                </Col>
                {technicalInspectionData ?
                  <FieldCol md="4">
                    <FieldGroup className="select-contract-block">
                      <FieldSelect
                        placeholder='Inspection Type'
                        keyParam="inspection_type"
                        value={this.state.locQuery.inspection_type ? this.state.locQuery.inspection_type : ''}
                        type={'edit'}
                        styleName={''}
                        options={technicalInspectionData}
                        updateField={this.updateLocForm}
                        labelKey="name"
                        valueKey="id"
                      />
                    </FieldGroup>
                  </FieldCol> : null
                }
                <FieldCol md="3">
                  <FieldGroup className="select-contract-block">
                    <FieldSelect
                      placeholder='Project'
                      keyParam="project_status"
                      value={this.state.locQuery.project_status ? this.state.locQuery.project_status : ''}
                      type={'edit'}
                      styleName={''}
                      options={projectStatus}
                      updateField={this.updateLocForm}
                      labelKey="label"
                      valueKey="value"
                    />
                  </FieldGroup>
                </FieldCol>
              </Row>
              <Row className="assetLocation">
                <Col md="12">
                  {
                    inspectionData.aircraft_type && inspectionData.aircraft_type.image ?
                      <TechnicalLocations
                        markerList={technicalLocationData}
                        height={this.state.height} />
                      : null
                  }
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  airCraftTypeList: state.DashboardReducer.airCraftTypeList,
  loactionsList: state.DashboardReducer.loactionsList,
  inspectionData: state.DashboardReducer.inspectionData,
  technicalProjectWidget: state.DashboardReducer.technicalProjectWidget,
  dbAssetLoader: state.DashboardReducer.dbAssetLoader,
  technicalLocationData: state.DashboardReducer.technicalProjectLocation,
  technicalInspectionData: state.DashboardReducer.technicalInspectionData,
  technicalProjectAssets: state.DashboardReducer.technicalProjectAssets
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    technicalProjectsWidget: () => dispatch(technicalProjectsWidgetAc()),
    technicalLocation: () => dispatch(technicalLocationsAc()),
    technicalInspectionType: () => dispatch(technicalInspectionTypeAc()),
    technicalProjectLocation: (query) => dispatch(technicalProjectLocationtAc(ownProps.params, query)),
    technicalAircraft: () => dispatch(technicalAircraftAc()),
    technicalInspectionFindings: (query) => dispatch(technicalDbFindingsAc(ownProps.params, query)),
    technicalLastAssets: () => dispatch(technicalLastAssetsAc())
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(InspectionFleetDb, ['project_management', 'dashboard', 'R']));
