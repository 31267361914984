import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
class GoogleLocationField extends React.Component {
  constructor(props) {
    super(props);
    this.state = { address: this.props.location };
  }
  componentDidMount(){
    this.setState({address: this.props.location})
  }
  handleChange = address => {
    if (!address) {
      this.setState({ address: '' });
      this.props.onFieldChange('', 'location', '');
    } else {
      this.setState({ address });
    }
  };
  
  handleSelect = address => {
    this.setState({address});
    this.props.onFieldChange('', 'location', address);
    geocodeByAddress(address)
    .then(results => {
      let place = results[0]['address_components'].filter(data => data['types'][0] === 'country');
      let country = place.length ?  place[0]['long_name']: address;
      let country_code = place.length ?  place[0]['short_name']: address;
      this.props.onFieldChange('', 'country', country);
      this.props.onFieldChange('', 'country_code', country_code);
      getLatLng(results[0])
      .then(latLng => {
        this.props.onFieldChange('', 'lat', latLng['lat']);
        this.props.onFieldChange('', 'lang', latLng['lng']);
      })
    })
    .catch(error => console.error('Error', error));    
  };

  render() {
    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <TextField
                required={this.props.required}
                id="location"
                label={this.props.label ? this.props.label : 'Location'}
                fullWidth
                margin="normal"
                value={''}
                error={this.props.error}
                onFocus={this.props.onFocus}
                helperText={this.props.helperText}
                InputLabelProps={{shrink: true}}
                {...getInputProps({
                    placeholder: 'Search Places ...',
                    className: 'location-search-input',
                })}
                variant='outlined'
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}
export default GoogleLocationField;