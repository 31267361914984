import React, { Component, Fragment } from 'react';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { Button } from '@material-ui/core';
import { videoTypes, imageMedia, audioTypes } from '../../../../../constants';
import { getLocalStorageInfo } from '../../../../../utils';
class FilePreview extends Component {
  constructor(props){
    super(props);
  }
  render(){
    const { fileInfo, thumbnail } = this.props;
    return(
      <Fragment>
        { fileInfo ?
          <div className="file-preview-blk">
            {['pdf', 'csv', 'xls', 'xlsx', 'doc', 'docx', 'ppt', 'pptx', ...imageMedia, ...videoTypes].includes(fileInfo.extension.toLowerCase()) ?
              <Fragment>
                {fileInfo.extension.toLowerCase() === 'pdf' ?
                  <div>
                    <object type="text/html" data={`https://sparta-aims.ams3.digitaloceanspaces.com/others/tools/pdf.js/web/viewer.html?file=${fileInfo.path}`} style={{width: '100%', height: window.innerHeight - 100}}>
                      <param name="type" value="text/html" />
                    </object>
                  </div>:null
                }
                { ['csv', 'xls', 'xlsx', 'doc', 'docx', 'ppt', 'pptx'].includes(fileInfo.extension.toLowerCase()) ?
                  <div>
                    <iframe src={'https://view.officeapps.live.com/op/embed.aspx?src=' + decodeURIComponent(fileInfo.path)} width='100%' height={window.innerHeight} frameborder='0'></iframe>
                  </div>:null
                }
                { imageMedia.includes(fileInfo.extension.toLowerCase()) ?
                  <div style={{textAlign:'center'}}>
                    <img style={{margin:'0 auto', maxHeight:window.innerHeight, maxWidth: window.innerWidth}} src={decodeURIComponent(fileInfo.path)} alt="" />
                  </div>:null
                }
                { videoTypes.includes(fileInfo.extension.toLowerCase()) ?
                  <div style={{textAlign:'center', width:'100%', margin:'0 auto'}}>
                    <video controls width='80%'>
                      <source src={decodeURIComponent(fileInfo.path)} type="video/mp4" />
                      Sorry, your browser doesn't support embedded videos.
                    </video>
                  </div>:null
                }
              </Fragment>:
              <div className="not-supported-preview" style={{textAlign:'center'}}>
                <h4>This file type is not supported by the native Viewer.</h4>
                <p>It may be possible to export the file to your local workstation and open it with another application</p>
                <a href={decodeURIComponent(fileInfo.path)} download><Button color="primary" variant="contained">Export</Button></a>
              </div>
            }
          </div>:null
        }
      </Fragment>
    )
  }
}
export default FilePreview;
