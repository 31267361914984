import React, { useState, useEffect, Fragment } from 'react';
import { useSnackbar } from 'notistack';
import { withRouter } from 'react-router';
import { TextField, Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableHead, TableBody, TableCell, CircularProgress, TableRow } from '@material-ui/core';
import {DropzoneArea} from 'material-ui-dropzone';
import { read, utils } from 'xlsx';
import { GetApp } from '@material-ui/icons';
import { checkApiStatus ,downloadFileType} from '../../../../../utils_v2';
import { globalPostService,globalExportService } from '../../../../../utils_v2/globalApiServices';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
const ImportSections = ({params, toggleModalFn, importSections}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [pres, setPres] = useState([]);
    const onFieldChange = (e, keyParam, value, index) => {
        setPres(pres.map((item, itemIndex) =>  itemIndex !== index ? item: {...item, [keyParam]: value}))
    }
    const onRemove = (index) => {
        setPres(pres.filter((item, itemIndex) => itemIndex !== index))
    }
    const onFilesDrop = (files) => {
        if (files && files.length > 0) {
            setLoading(true);
            const file = files[0];
            if (file instanceof Blob) {
                const reader = new FileReader();
                reader.onload = function (e) {
                const buffer = e.target.result;
                const wb = read(new Uint8Array(buffer));
                const ws = wb.Sheets[wb.SheetNames[0]];
                const data = utils.sheet_to_json(ws, { blankRows: false });
                setLoading(false);
                if(data.length === 0){
                    enqueueSnackbar("Invalid Data", { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
                }
                
                let updatedData = data.map(item=>{
                   return {
                    description: item['Description'],
                    acumen_remarks: item['Remarks'],
                    status: item['Status'],
                    comments: item['Comments/Notes'],
                    ref_document: item['Ref Documents'],
                    operator_comments: item['Operator Comments'],
                    engine_gap_report_section: null,
                    engine_work_order: null,
                    engine_gap_report_section:1,
                    part_number: null,
                    serial_number: null,
                    cycles_remaining: null,
                    }
                })
                setPres(updatedData);
                };
                reader.readAsArrayBuffer(file);
            } else {
                console.error("Invalid file object. Expected a Blob.");
            }
        }
    };
    const onImportSections = () => {
        // Validations for Template Import;
        setLoading(true);
        globalPostService(`technical/engine-workorder/${params.workOrderSlug}/import-gap-report/`, pres)
        .then(response => {
            setLoading(false);
            if(checkApiStatus(response)){
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
                window.location.reload();
                toggleModalFn();
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
            }
        })
    }
    return(
        <Fragment>
            <Dialog
                open={importSections.modal}
                onClose={toggleModalFn}
                className='project-management-modal'
                maxWidth='lg'
            >
                <DialogTitle id="scroll-dialog-title">
                    Import Record Details
                </DialogTitle>
                <DialogContent dividers={true}>
                    <div style={{width:"828px", height:"378px"}}>
                        <Grid container spacing={1}>
                            <Grid item md={12}>
                                <DropzoneArea
                                    acceptedFiles={['.xls, .xlsx']}
                                    filesLimit={1}
                                    maxFileSize={10428800}
                                    showPreviewsInDropzone={false}
                                    useChipsForPreview={false}
                                    showPreviews={false}
                                    dropzoneText={<p style={{fontSize:'13px'}}>Drag & Drop Documents<br/> OR <br/> Click Here<br/> </p>}
                                    dropzoneClass="drag-drop-cnt"
                                    maxWidth="sm"
                                    showAlerts={['info','error']}
                                    alertSnackbarProps={{anchorOrigin: {vertical:'top', horizontal:'right'}}}
                                    clearOnUnmount={true}
                                    onChange={(files) => onFilesDrop(files)}
                                />
                                <div>
                                    { pres.length ?
                                        <Table className='mui-table-format'>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Description<sup>*</sup></TableCell>
                                                    <TableCell>Remarks</TableCell>
                                                    <TableCell>Status</TableCell>
                                                    <TableCell>Comments/Notes</TableCell>
                                                    <TableCell>Ref Documents</TableCell>
                                                    <TableCell>Operator Comments</TableCell>
                                                    <TableCell>Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                { /* generate row for each president */
                                                    pres.map((pres,index) => (
                                                        <TableRow>
                                                            <TableCell>
                                                                <div style={{width:'200px'}}>
                                                                    { pres['description'] ? 
                                                                        <TextField
                                                                            required
                                                                            id={`name_${index}`}
                                                                            fullWidth
                                                                            margin="none"
                                                                            value={pres['description']}
                                                                            onChange={(e) => onFieldChange(e, 'description', e.target.value, index)}
                                                                            InputLabelProps={{shrink: true}}
                                                                            variant='outlined'
                                                                        />:null
                                                                    }
                                                                </div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <div style={{width:'200px'}}>
                                                                    { pres['acumen_remarks'] ?
                                                                        <TextField
                                                                            required
                                                                            id={`acumen_remarks${index}`}
                                                                            fullWidth
                                                                            margin="none"
                                                                            value={pres['acumen_remarks']}
                                                                            onChange={(e) => onFieldChange(e, 'acumen_remarks', e.target.value, index)}
                                                                            InputLabelProps={{shrink: true}}
                                                                            variant='outlined'
                                                                        />:null
                                                                    }
                                                                </div>
                                                            </TableCell>
                                                            <TableCell>
                                                                { pres['status'] ?
                                                                    <div style={{width:'200px'}}>
                                                                        <TextField
                                                                            required
                                                                            id={`status_${index}`}
                                                                            fullWidth
                                                                            margin="none"
                                                                            value={pres['status']}
                                                                            onChange={(e) => {onFieldChange(e, 'status', e.target.value, index)}}
                                                                            InputLabelProps={{shrink: true}}
                                                                            variant='outlined'
                                                                        />
                                                                    </div>:null
                                                                }
                                                            </TableCell>
                                                            <TableCell>
                                                                <div style={{width:'200px'}}>
                                                                        <TextField
                                                                            required
                                                                            id={`comments_${index}`}
                                                                            fullWidth
                                                                            margin="none"
                                                                            value={pres['comments']?pres['comments']:''}
                                                                            onChange={(e) => {onFieldChange(e, 'comments', e.target.value, index)}}
                                                                            InputLabelProps={{shrink: true}}
                                                                            variant='outlined'
                                                                        />
                                                                    </div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <div style={{width:'200px'}}>
                                                                        <TextField
                                                                            required
                                                                            id={`ref_document${index}`}
                                                                            fullWidth
                                                                            margin="none"
                                                                            value={pres['ref_document']?pres['ref_document']:''}
                                                                            onChange={(e) => {onFieldChange(e, 'ref_document', e.target.value, index)}}
                                                                            InputLabelProps={{shrink: true}}
                                                                            variant='outlined'
                                                                        />
                                                                    </div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <div style={{width:'200px'}}>
                                                                        <TextField
                                                                            required
                                                                            id={`operator_comments_${index}`}
                                                                            fullWidth
                                                                            margin="none"
                                                                            value={pres['operator_comments']?pres['operator_comments']:''}
                                                                            onChange={(e) => {onFieldChange(e, 'operator_comments', e.target.value, index)}}
                                                                            InputLabelProps={{shrink: true}}
                                                                            variant='outlined'
                                                                        />
                                                                    </div>
                                                            </TableCell>
                                                            <TableCell>
                                                                {pres.length !== 1 ?
                                                                    <DeleteOutlineIcon color='secondary' onClick={() => onRemove(index)} />:null
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                }
                                            </TableBody>
                                        </Table>:null
                                    }
                                </div>
                            </Grid>

                            <div>
                            <p style={{ fontSize: '13px', fontStyle: 'italic', color: '#717171' }}>Note: Please download "Sample Template", edit the template in Excel and upload the same for further customization. </p>
                            <p className='download-sample-temp' onClick={() =>
                                window.open('https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/templates/TE003_Record_Details_Sample_Template.xls','_blank')
                                 } > <GetApp /> Download Sample Template</p>
                            </div>
                        </Grid>
                    </div>
                    
                </DialogContent>
                <DialogActions>
                    <Button disabled={isLoading} onClick={toggleModalFn} color="primary" size='small' variant='outlined'>Cancel</Button>
                    { pres.length ?
                        <Button disabled={isLoading} onClick={onImportSections} color="primary" size='small' variant='contained'>
                            {isLoading ? <CircularProgress size={20} /> : 'Save'}
                        </Button>:null
                    }
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}
export default withRouter(ImportSections)