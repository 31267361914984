import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import closeIcon from '../../../../../shared/assets/img/close_icon.svg';
export default function BulkOperationMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [anchorEl3, setAnchorEl3] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClose1 = () => {
    setAnchorEl1(null);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const handleClose3 = () => {
    setAnchorEl3(null);
  };
  return (
    <ul className="list-inline">
        <li className="list-inline-item">
            <img src={closeIcon} onClick={() => props.closeBulkOps()} alt="Close Icon" style={{cursor:'pointer'}}/>
        </li>
        <li className="list-inline-item">
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={(event) => setAnchorEl1(event.currentTarget)}>
                Assignee
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl1}
                keepMounted
                open={Boolean(anchorEl1)}
                onClose={handleClose1}
            >
            {
              props.users.map(user =>
                <MenuItem onClick={() => {
                    handleClose1()
                    setTimeout(() => props.assignGapIds(user), 1000)
                  }}>{user.name}</MenuItem>
              )
            }
            </Menu>
        </li>
        <li className="list-inline-item">
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={(event) => setAnchorEl3(event.currentTarget)}>
                Status
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl3}
                keepMounted
                open={Boolean(anchorEl3)}
                onClose={handleClose3}
            >
                {
                  [{label:'Open',value:0},{label:'Closed',value:1}, {label:'Reopen',value:2}].map(priority =>
                      <MenuItem onClick={(event) => {
                          handleClose3(event)
                          setTimeout(() => props.bulkOpsApi('status', priority.value), 1000)
                        }}>{priority.label}</MenuItem>
                  )
                }
            </Menu>
        </li>
        <li className="list-inline-item">
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={(event) => setAnchorEl2(event.currentTarget)}>
                Priority
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl2}
                keepMounted
                open={Boolean(anchorEl2)}
                onClose={handleClose2}
            >
                {
                  [{label:'None',value:0},{label:'P1',value:1},{label:'P2',value:2}].map(priority =>
                      <MenuItem  onClick={(event) => {
                          handleClose2(event)
                          setTimeout(() => props.bulkOpsApi('priority', priority.value), 1000)
                        }}>{priority.label}</MenuItem>
                  )
                }
            </Menu>
        </li>
        <li className="list-inline-item">
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                Category
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
            {
              props.modules.map(mod =>
                <MenuItem onClick={() => {
                    handleClose()
                    setTimeout(() => props.moveGapIds(mod), 1000)
                  }}>{mod.name}</MenuItem>
              )
            }
            </Menu>
        </li>
        <li className="list-inline-item">
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={() => props.deleteMultiple()}>
                Delete
            </Button>
        </li>
    </ul>
  );
}
