import React, { Fragment, useState, useEffect } from "react"
import { browserHistory } from 'react-router'
import { TaskManagementWrapper, TaskIssueLoader, TaskTitle,TaskDescription,TaskInformation,CommentBox, Attachments, TaskIssues} from "../components"
import { PageLoader } from '../../../shared_elements';
import { Tab, Tabs, Paper } from "@material-ui/core"
import Skeleton from '@material-ui/lab/Skeleton';
import { globalDeleteService, globalGetService, globalPostService, globalPutService } from "../../../utils_v2/globalApiServices"
import { checkApiStatus, onCheckPermission } from "../../../utils_v2";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { trackActivity } from '../../../utils/mixpanel';
const queryString = require('query-string');
const TaskDetail = ({ params, location, toggleModalFn, rowId }) => {
  const [skeletonLoader, setSkeletonLoader] = useState(true);
  const [taskLoader, setTaskLoader] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [tabIndex, setTabIndex] = useState( onCheckPermission('task_management','task_comment','R') ? 'comments' : onCheckPermission('task_management','task_document','R') ? 'documents': onCheckPermission('task_management','issues','R')? 'issues':'');
  const [tasks, setTasks] = useState([]);
  const [taskDetail, setTaskDetail] = useState({});
  console.log('tabIndex',tabIndex);
  useEffect(() => {
    getTaskDetail(params.slug, 'skeletonLoader');
    getTasksData({view: 'all', fields:'id,name,created_by', ...location.query},'taskLoader')
    trackActivity('Task Management', {
      event_type: 'Page Visited',
      page_title: 'Task Detail',
    });
  }, []);
  const getTaskDetail = (id, loaderType) => {
    if(loaderType === 'skeletonLoader'){ setSkeletonLoader(true) }else{ setLoading(true) }
    globalGetService(`technical/project/${params.project_slug}/tasks/${id}/`)
    .then(response => {
      if(checkApiStatus(response)){
        setTaskDetail(response.data.data)
      }
      if(loaderType === 'skeletonLoader'){ setSkeletonLoader(false) }else{ setLoading(false) }
    })
  }
  const getTasksData = (query) => {
    if(taskLoader){
      setTaskLoader(true)
    }
    globalGetService( `technical/project/${params.project_slug}/tasks/`, query )
    .then(response => {
      setTaskLoader(false)
      if(checkApiStatus(response)){
        setTasks(response.data.data)
      }
    })
  }
  const updateTaskUrl = (id) => {
    let location = browserHistory.getCurrentLocation()
    location = {
      ...location,
      pathname: `/technical/project/${params.project_slug}/task/${id}`
    }
    browserHistory.push(location);
    getTaskDetail(id,'skeletonLoader');
  }
  const backToList = () => {
    let location = browserHistory.getCurrentLocation()
    location = {
      ...location,
      pathname: `/technical/project/${params.project_slug}/tasks`
    }
    browserHistory.push(location);
  }
  return (
    <section className="projects-management-apps projects-management-section">
        <TaskManagementWrapper>
          <div className="task-management-detail" style={{height:`${window.innerHeight-140}px`}}>
            <div className="task-management-sidebar" style={{height:`${window.innerHeight-140}px`}}>
              {taskLoader ? <Skeleton variant="rect" width={'100%'} height={window.innerHeight-140} />:
                <Fragment>
                  {tasks.map((task) =>
                    <div onClick={()=>updateTaskUrl(task.id)} className={`task-management-sidebar-card ${task.id == params.slug?'active':''}`}>
                      <h5>{task.name}</h5>
                      <p>{task.created_by && task.created_by.name ? task.created_by.name:''}</p>
                    </div>
                  )}
                </Fragment>
              }
            </div>
            <div style={{height:`${window.innerHeight-140}px`}} className="task-detail-section">
              { skeletonLoader ? <TaskIssueLoader />:
                <Fragment>
                  <h4 onClick={() => backToList()} className="flex-centered back-to-list">
                    <ArrowBackIcon  fontSize="small" color="primary" />
                    <span>Tasks</span>
                  </h4>
                  <TaskTitle
                    taskDetail={taskDetail?.task||{}}
                    permission={taskDetail?.permission||{}}
                    getResponseBack={()=> getTasksData({view: 'all', fields:'id,name,created_by', ...location.query})}

                  />
                  <TaskDescription
                    taskDetail={taskDetail?.task||{}}
                    permission={taskDetail?.permission||{}}
                  />
                  <TaskInformation
                    taskDetail={taskDetail?.task||{}}
                    permission={taskDetail?.permission||{}}
                  />
                  <Paper style={{marginBottom:'10px'}}>
                    <Tabs
                        value={tabIndex}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={(event, newValue)=> setTabIndex(newValue)}
                        aria-label="disabled tabs example"
                    >
                      { onCheckPermission('task_management','task_comment','R') ?
                        <Tab label="Comment" value='comments' />:null
                      }
                      { onCheckPermission('task_management','task_document','R') ?
                        <Tab label="Document" value='documents' />:null
                      }
                      { onCheckPermission('task_management','issues','R') ?
                        <Tab label="Issues" value='issues' />:null
                      }
                    </Tabs>
                  </Paper>
                  { onCheckPermission('task_management','task_comment','R') && tabIndex === 'comments' ? <CommentBox /> : null }
                  { onCheckPermission('task_management','task_document','R') && tabIndex === 'documents' ? <Attachments /> : null }
                  { onCheckPermission('task_management','issues','R') && tabIndex === 'issues' ? <TaskIssues taskDetail={taskDetail?.task||{}} permission={taskDetail?.permission||{}} /> : null }
                </Fragment>
              }
            </div>
          </div>
        </TaskManagementWrapper>
        { isLoading ? <PageLoader />:null }
    </section>
  )
}
export default TaskDetail
