import React, { useState, useEffect } from "react";
import { useSnackbar } from 'notistack';
import { withRouter } from "react-router";
import moment from "moment";
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, Grid, Button, TextField, Dialog, DialogContent, DialogTitle, DialogActions, Tooltip, CircularProgress } from '@material-ui/core';
import { STableLoader, PageLoader, EmptyCollection, DeletePopUp } from "../../../shared_elements";
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { globalDeleteService, globalGetService, globalPostService, globalPutService } from "../../../globalServices";
import { checkApiStatus, onCheckFormPermission } from '../../../utils_v2';
import { backendDateFormat, displayDateFormatShort, fieldDateFormat } from "../../../constants";
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
const reghistoryobj = {
    country: '',
    registration_number: '',
    start_date: null,
    end_date: null
}
const RegHistoryHd = ({ currentFormDetail }) => {
    return (
        <TableHead>
            <TableRow>
                <TableCell>Country of Registration</TableCell>
                <TableCell>Registration No.</TableCell>
                <TableCell>Start Date</TableCell>
                <TableCell>End Date</TableCell>
                {onCheckFormPermission(currentFormDetail) ? <TableCell align="right">Action(s)</TableCell> : null}
            </TableRow>
        </TableHead>
    )
}
const RegHistoryList = ({ item, onEdit, onDelete, currentFormDetail }) => {
    const [showMore, setShowMore] = useState({});
    const toggleComment = (field) => {
        setShowMore({
            ...showMore,
            [field]: !showMore[field],
        });
    };
    return (
        <TableRow>
            <TableCell>
                {item?.country && item?.country?.length > 30 ? (
                    <div style={{ width: '240px' }} >
                        {showMore[item?.field] && showMore[item?.field] ? (
                            <span> {item?.country}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleComment(item.field); }}  >  less </a>
                            </span>
                        ) : (
                            <span> {item.country.substring(0, 25)}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleComment(item.field); }}  > ..more </a>
                            </span>
                        )}
                    </div>
                ) :
                    <div style={{ width: '115px' }}>{item?.country || '--'}</div>
                }
            </TableCell>
            <TableCell>
                {item?.registration_number && item?.registration_number?.length > 30 ? (
                    <div style={{ width: '240px' }} >
                        {showMore[item?.field] && showMore[item?.field] ? (
                            <span> {item?.registration_number}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleComment(item.field); }}  >  less </a>
                            </span>
                        ) : (
                            <span> {item.registration_number.substring(0, 25)}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleComment(item.field); }}  > ..more </a>
                            </span>
                        )}
                    </div>
                ) :
                    <div style={{ width: '115px' }}>{item?.registration_number || '--'}</div>
                }
            </TableCell>
            <TableCell>
                <div style={{ width: '115px' }}>{item?.start_date ? moment(item?.start_date).format(displayDateFormatShort) : '--'}</div>
            </TableCell>
            <TableCell>
                <div style={{ width: '115px' }}>{item?.end_date ? moment(item?.end_date).format(displayDateFormatShort) : '--'}</div>
            </TableCell>
            {onCheckFormPermission(currentFormDetail) ?
                <TableCell align="right">
                    <ul className="list-inline" style={{ width: '80px', float: 'right' }}>
                        <li className="list-inline-item hover-inline" onClick={onEdit}>
                            <Tooltip title='Edit' style={{ cursor: 'pointer' }} arrow><EditIcon color="primary" fontSize="small" /></Tooltip>
                        </li>
                        <li className="list-inline-item hover-inline" onClick={onDelete}>
                            <Tooltip title='Delete' style={{ cursor: 'pointer' }} arrow><DeleteOutlineIcon color="secondary" fontSize="small" /></Tooltip>
                        </li>
                    </ul>
                </TableCell> : null
            }
        </TableRow>
    )
}
const AddEditRegHistory = ({ params, addEditRegHistory, toggleModalFn, getResponseBack }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [regHistory, setRegHistory] = useState(addEditRegHistory.data);
    const [error, setError] = useState({});
    const onFieldChange = (key, value) => {
        setRegHistory({ ...regHistory, [key]: value.trimStart() })
    }
    const onAddEditRegHistory = () => {
        let validationInputs = {};
        validationInputs = {
            ...validationInputs,
            end_date: moment(regHistory.end_date).isValid() ? moment(regHistory.end_date).isValid() && moment(regHistory.end_date).isSameOrAfter(moment(regHistory.start_date)) ? '' : "End date should be equal or greater than Start date" : '',
        }
        if (regHistory.end_date && !moment(regHistory.end_date).isValid()) {
            validationInputs = {
                ...validationInputs,
                end_date: 'Enter valid End date'
            }
        }
        if (regHistory.start_date && !moment(regHistory.start_date).isValid()) {
            validationInputs = {
                ...validationInputs,
                start_date: 'Enter valid Start date'
            }
        }
        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
            const payload = Object.assign({}, regHistory)
            setLoading(true);
            if (regHistory.id) {
                globalPutService(`technical/workorder/${params.workOrderSlug}/t014/reg-history/${regHistory.id}/?type=T014&asset_type=${params.type}`, payload)
                    .then(response => {
                        if (checkApiStatus(response)) {
                            enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                            toggleModalFn(); getResponseBack();
                        }
                        setLoading(false);
                    })
            } else {
                globalPostService(`technical/workorder/${params.workOrderSlug}/t014/reg-history/?type=T014&asset_type=${params.type}`, payload)
                    .then(response => {
                        if (checkApiStatus(response)) {
                            enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                            toggleModalFn(); getResponseBack();
                        }
                        setLoading(false);
                    })
            }
        } else {
            setError(validationInputs)
        }
    }
    const isFieldEmpty = () => {
        if(regHistory.id){
            return Object.entries(regHistory).filter(([key]) => key !== 'id').some(([_, value]) => value !== '' && value !== null);        
        }else{
            return Object.values(regHistory).some(value => value !== '' && value !== null);
        }
    };

    return (
        <Dialog
            open={addEditRegHistory.modal}
            onClose={toggleModalFn}
            className='project-management-modal'
            maxWidth="md"
        >
            <DialogTitle id="scroll-dialog-title">
                {regHistory?.id ? 'Edit ' : 'Add '} Registration History
            </DialogTitle>
            <DialogContent dividers={true}>
                <Grid container spacing={1}>
                    <Grid item md={6}>
                        <TextField
                            name='country'
                            label='Country of Registration'
                            value={regHistory?.country || ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('country', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                            inputProps={{ maxLength: 255 }}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            name='registration_number'
                            label='Registration No.'
                            value={regHistory?.registration_number || ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('registration_number', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                            inputProps={{ maxLength: 255 }}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                margin="normal"
                                name="start_date"
                                label="Start Date"
                                format={fieldDateFormat}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                maxDate={moment()}
                                minDate={moment().subtract(25, 'years')}
                                value={regHistory?.start_date || null}
                                onChange={(data, value) => { onFieldChange('start_date', moment(data).format(backendDateFormat)); setError({ ...error, 'start_date': '' }) }}
                                inputVariant='outlined'
                                error={error.start_date ? true : false}
                                helperText={error.start_date || ''}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item md={6}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                margin="normal"
                                name="end_date"
                                label="End Date"
                                format={fieldDateFormat}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                minDate={moment().subtract(25, 'years')}
                                value={regHistory?.end_date || null}
                                onChange={(data, value) => { onFieldChange('end_date', moment(data).format(backendDateFormat)); setError({ ...error, 'end_date': '' }) }}
                                inputVariant='outlined'
                                error={error.end_date ? true : false}
                                helperText={error.end_date || ''}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} onClick={toggleModalFn} color='primary' size='small' variant='outlined'>Cancel</Button>
                <Button disabled={isLoading || !isFieldEmpty()} onClick={onAddEditRegHistory} color='primary' size='small' variant='contained'>
                    {isLoading ? <CircularProgress size={24} /> : 'Save'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
const RegHistory = ({ params, getCurrentFormDetail, currentFormDetail }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [filter, setFilter] = useState({});
    const [regHistoryInfo, setRegHistoryInfo] = useState({ list: [{ country: 'India', registration: "engine" }], pagination: null });
    const [addEditRegHistory, setAddEditRegHistory] = useState({ modal: false, data: null });
    const [deleteRegHistory, setDeleteRegHistory] = useState({ modal: false, data: null });
    useEffect(() => {
        getRegHistory('skeletonLoader');
    }, []);
    const getRegHistory = (loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true) : setLoading(true)
        globalGetService(`technical/workorder/${params.workOrderSlug}/t014/reg-history?type=T014&asset_type=${params.type}`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setRegHistoryInfo(response.data)
                    getCurrentFormDetail()
                }
                loaderType === 'skeletonLoader' ? setSkeletonLoader(false) : setLoading(false)
            })
    }
    const onDeleteRegHistory = () => {
        setLoading(true);
        globalDeleteService(`technical/workorder/${params.workOrderSlug}/t014/reg-history/${deleteRegHistory.data.id}/?type=T014&asset_type=${params.type}`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setDeleteRegHistory({ modal: false, data: null });
                    enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                    getRegHistory({}, 'pageLoader');
                }
                setLoading(false);
            })
    }
    return (
        <>
            <div className="tech-specs-content">
                {skeletonLoader ? <STableLoader count={8} /> :
                    <div style={{ padding: '10px' }}>
                        <Paper square style={{ padding: '4px 8px' }}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item md={9}></Grid>
                                <Grid item md={3}>
                                    <ul className="list-inline" style={{ float: 'right' }}>
                                        {onCheckFormPermission(currentFormDetail) ?
                                            <li className="list-inline-item">
                                                <Button onClick={() => setAddEditRegHistory({ modal: true, data: reghistoryobj })} color="primary" size='small' variant="contained"><AddOutlinedIcon style={{ fontSize: '16px' }} />ADD</Button>
                                            </li> : null
                                        }
                                    </ul>
                                </Grid>
                            </Grid>
                        </Paper>
                        <Paper style={{ maxHeight: window.innerHeight - 185 + 'px', overflow: "scroll" }}>
                            <Table className='mui-table-format' stickyHeader>
                                <RegHistoryHd currentFormDetail={currentFormDetail} />
                                <TableBody>
                                    {regHistoryInfo.data?.map((item, index) =>
                                        <RegHistoryList
                                            currentFormDetail={currentFormDetail}
                                            key={index}
                                            item={item}
                                            onEdit={() => setAddEditRegHistory({ modal: true, data: item })}
                                            onDelete={() => setDeleteRegHistory({ modal: true, data: item })}
                                        />
                                    )}
                                </TableBody>
                            </Table>
                        </Paper>
                        <Paper>
                            {!regHistoryInfo?.data?.length ? <div style={{ textAlign: 'center' }}><EmptyCollection title='No records found' /></div> : null}
                        </Paper>
                    </div>
                }
            </div>
            {addEditRegHistory.modal ?
                <AddEditRegHistory
                    params={params}
                    addEditRegHistory={addEditRegHistory}
                    toggleModalFn={() => setAddEditRegHistory({ modal: false, data: null })}
                    getResponseBack={() => getRegHistory({}, 'pageLoader')}
                /> : null
            }
            {deleteRegHistory.modal ?
                <DeletePopUp
                    modal={deleteRegHistory.modal}
                    title='Delete Reg History'
                    content={`Are you sure, you want to Delete?`}
                    toggleModalFn={() => setDeleteRegHistory({ modal: false, data: null })}
                    deleteRecordFn={onDeleteRegHistory}
                /> : null
            }
            {isLoading ? <PageLoader /> : null}
        </>

    )
}
export default withRouter(RegHistory);