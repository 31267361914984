import React, { useState } from 'react';
import { browserHistory } from 'react-router';
import moment from 'moment';
import PopoverItem from './PopoverItem';
import PopoverLeadInfo from './PopoverLeadInfo';
import { ToolTipHover } from '../../../../shared';
import { displayDateFormatShort, imgStoragePath } from '../../../../constants';
import { getLocalStorageInfo } from '../../../../utils';
import {isMobile} from 'react-device-detect';
import editIcon from '../../../../shared/assets/img/edit_icon.svg';
import viewIcon from '../../../../shared/assets/img/view_icon.svg';
const List = (props) => {
  const [expand, toggleExpand] = useState(false)
  return (
    <tr style={{cursor: 'pointer'}}>

      <td onClick={() => { browserHistory.push('/technical/project/view/'+props.data.slug) }}>
        <div className="project-info">
          <h6 style={{fontSize:'9px'}}>
           #{props.data.project_number}
          </h6>
          <h4>
            {props.data.name}
          </h4>
          <p id={'project'+ props.data['id']}><span style={{width: props.data.status && props.data.status.label == "Completed" ? "100%": props.data.progress+'%'}}></span></p>
          <ToolTipHover placement="top" tagetId={ 'project'+ props.data['id']}>
          {props.data.status && props.data.status.label == "Completed" ? "100%": props.data.progress+'%'}
          </ToolTipHover>
        </div>
      </td>
      <td onClick={() => {
          if(!isMobile){
            browserHistory.push('/technical/project/view/'+props.data.slug)
          }
        }}>
        {
          !expand ?
          <p style={{width:'70px'}}>
            {
              props.data && props.data.assets && props.data.assets.length  ?
                props.data.assets.map((asset, assetIndex) =>
                  assetIndex < 4 ? <span style={{background: '#d9d9d9', padding: '5px 3px', borderRadius: '2px', marginTop: '4px', display: 'block', fontSize: '12px', textAlign: 'center'}}>{asset.asset_type == '1' ? 'MSN ' + asset.msn : asset.asset_type == '2' ? 'ESN ' + asset.esn : asset.asset_type == '3' ? 'APU ' + asset.serial_number : asset.asset_type == '4' ? 'LG ' + asset.serial_number : asset.asset_type == '5' ? 'PROPELLER ' + asset.serial_number :'' } </span> : null
                )
              : null
            }
            {
              props.data && props.data.assets && props.data.assets.length > 4 ?
                <span style={{cursor: 'pointer', color: '#4051b5', marginTop: '10px', display: 'block'}} onClick={(event) => {
                    event.preventDefault()
                    event.stopPropagation()
                    toggleExpand(true)
                  }}>{`& ${props.data.assets.length - 4} More`}</span>
              :null
            }
            </p>
          :<p style={{width:'70px'}}>
            {
              props.data && props.data.assets && props.data.assets.length  ?
                props.data.assets.map((asset, assetIndex) =>
                  <span style={{background: '#d9d9d9', padding: '5px 3px', borderRadius: '2px', marginTop: '4px', display: 'block', fontSize: '12px', textAlign: 'center'}}>{asset.asset_type == '1' ? 'MSN ' + asset.msn : asset.asset_type == '2' ? 'ESN ' + asset.esn : asset.asset_type == '3' ? 'APU ' + asset.serial_number : asset.asset_type == '4' ? 'LG ' + asset.serial_number : asset.asset_type == '5' ? 'PROPELLER ' + asset.serial_number :'' } </span>
                )
              : null
            }
            <span style={{cursor: 'pointer', color: '#4051b5', marginTop: '10px', display: 'block'}} onClick={(event) => {
                event.preventDefault()
                event.stopPropagation()
                toggleExpand(false)
              }}>View Less</span>
            </p>
        }
      </td>
      <td onClick={() => { browserHistory.push('/technical/project/view/'+props.data.slug) }}>{props.data.location ? props.data.location : '--'}</td>
      <td onClick={() => { browserHistory.push('/technical/project/view/'+props.data.slug) }}>
        <div className="operator-info">
          { props.data.lessee && props.data.lessee.logo != '' ?
            <div>
              <img src={props.data.lessee.logo} alt="" id={'lesse-'+props.data.id} />
              <ToolTipHover placement="right" tagetId={'lesse-'+props.data.id}>
                {props.data.lessee.name}
              </ToolTipHover>
            </div>
            :'--'
          }
        </div>
      </td>
      <td onClick={() => { browserHistory.push('/technical/project/view/'+props.data.slug) }}>{`${props.data.start_date ? moment(props.data.start_date).format(displayDateFormatShort): '--'} to ${props.data.expected_end_date ? moment(props.data.expected_end_date).format(displayDateFormatShort): '--'}`}</td>
      {/* <td style={{width:'132px'}} onClick={() => { browserHistory.push('/technical/project/view/'+props.data.slug) }}>{props.data.expected_end_date ? moment(props.data.expected_end_date).format(displayDateFormatShort): '--'}</td> */}
      <td onClick={() => { browserHistory.push('/technical/project/view/'+props.data.slug) }}>
        <div className="lead-info">
          { props.data.leads.length > 1 ?
            <ul className="list-inline multi-leads flex-centered">
              { props.data.leads.map((lead, index) =>
               index <= 1 ?
                <li key={index} className={"list-inline-item " + index}>
                  <img src={lead.profile_pic ? lead.profile_pic : imgStoragePath + 'user.png'} alt="" id={'img-'+index+'-'+props.data.id} onClick = {(e) =>  { e.stopPropagation() ; props.getUserDetailFn(lead.slug, true)}}/>
                  <ToolTipHover placement="top" tagetId={'img-'+index+'-'+props.data.id}>
                   {lead.name}
                  </ToolTipHover>
                </li> : null
              )}
              { props.data.leads.filter(leads => leads).length > 2 ?
                <li  className="list-inline-item" >
                  <PopoverLeadInfo
                    placement = 'top'
                    tagetId={'Popover-img-'+props.data.id}
                    leadInfo = {props.data.leads}
                    getUserDetailFn = {props.getUserDetailFn}
                  />
                </li>
                : null
              }
            </ul>
            : props.data.leads.length  == 1 ?
            <ul className="list-inline">
              <li className="list-inline-item">
                { props.data.leads.map((data, index) =>
                      <span key={index} className="flex-centered" onClick = {(e) =>  { e.stopPropagation() ; props.getUserDetailFn(data.slug, true)}}>
                        { data.profile_pic ?
                          <div>
                            <img src={data.profile_pic} alt="logo" id={'img-'+props.data.id} />
                         </div>:<img src={imgStoragePath + 'user.png'} alt="logo"/>
                        }
                        { data.name ?
                          <span style={{marginLeft: '10px'}}> {data.name} </span>
                          : null
                        }
                      </span>
                   )
                }
              </li>
            </ul>: null
          }
        </div>
      </td>
      {/* <td onClick={() => { browserHistory.push('/technical/project/view/'+props.data.slug) }}>
        { props.data.status && props.data.status.label ?  props.data.status && props.data.status.label : '--'}
        {
          props.data.archive_status === 1 ?
          <span className="archived-project">Archived</span>
          :null
        }
      </td> */}
      <td className="crud-block">
        <ul className="list-inline table-action-col">
          <li className="list-inline-item" onClick={() => { browserHistory.push('/technical/project/view/'+props.data.slug) }}> <img src={viewIcon} width="22" /></li>
          { getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.projects && getLocalStorageInfo().user.permission.technical.projects.includes('U') && props.data.status.id != 0 && props.data.archive_status == 0 && props.data.status.id != 7 ?
            <li className="list-inline-item" onClick={(e) =>{e.preventDefault();props.rowActivity('edit')} }>
              <img src={editIcon} width="20" />
            </li>:null
          }
        </ul>
      </td>
    </tr>
  )
}

export default List;
