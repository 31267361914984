import React from 'react';
const EditCurrencyNote = ({}) => {
    return(
        <div className='budget-alert-note'>
            <p>
                Please update the Project Currency and Amount to enable Budget Management 
            </p>
        </div>
    )
}
export default EditCurrencyNote;