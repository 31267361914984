import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { Paper, Tooltip, IconButton, Button } from '@material-ui/core';
import { globalGetService, globalPostService, globalPutService } from '../../../utils_v2/globalApiServices';
import { checkApiStatus, getLocalStorageInfo } from '../../../utils_v2';
import { ShopVisitCRU } from '../../te004/components'
import EditIcon from '@material-ui/icons/Edit';
import { PageLoader, EmptyCollection, STableLoader } from '../../../shared_elements';
const ShopVisitCard = ({params, currentFormDetail, getCurrentFormDetail}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setLoading] = useState(false);
  const [skeletonLoader, setSkeletonLoader] = useState(false);
  const [isEditAble, setEditAble] = useState(false)
  const [shopVisit, setShopVisit] = useState({});
  const [error, setError] = useState({});
  const [deleteFiles, setDeleteFiles] = useState([])
  useEffect(() => {
    getShopVisit('skeletonLoader');
  },[]);
  const getShopVisit = (loaderType) => {
    loaderType === 'skeletonLoader' ? setSkeletonLoader(true):setLoading(true);
    globalGetService(`technical/v2/workorder/${params.workOrderSlug}/table-inspections/latest-shop-visit/`)
    .then(response => {
      if(checkApiStatus(response)){
        setShopVisit(response.data.data)
      }else{
        // enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
      }
      loaderType === 'skeletonLoader' ? setSkeletonLoader(false):setLoading(false);
    })
  }
  const onAddEditShopVisit = () => {
    let validationInputs = {
      shop_visit_number:shopVisit?.shop_visit_number?.trim()?.length ? '':'Please enter Shop Visit Number',
      date_of_removal:shopVisit?.date_of_removal ? moment(shopVisit.date_of_removal).isValid() ? '':'Please enter valid Date':'',
      induction_date:shopVisit?.induction_date ? moment(shopVisit.induction_date).isValid() ? '':'Please enter valid Date':'',
      arc_date:shopVisit?.arc_date ? moment(shopVisit.arc_date).isValid() ? '':'Please enter valid Date':'',
      date_of_reinstallation:shopVisit?.date_of_reinstallation ? moment(shopVisit.date_of_reinstallation).isValid() ? '':'Please enter valid Date':'',
    }
    if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
      setLoading(true);
      globalPutService(`technical/v2/workorder/${params.workOrderSlug}/table-inspections/latest-shop-visit/`, shopVisit)
      .then(response => {
        if(checkApiStatus(response)){
          if(deleteFiles.length){
            onDeleteAttachments(response.data.message)
          }else{
            enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
            setEditAble(false);
          }
        }else{
          enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
        }
        setLoading(false);
      })
    }else{
      setError(validationInputs)
    }
  }
  const onDeleteAttachments = (message) => {
    globalPostService(`technical/v2/workorder/${params.workOrderSlug}/file-remove/`,{ paths:deleteFiles,model:'shop-visit'})
    .then(response => {
      if(checkApiStatus(response)){
        enqueueSnackbar(message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
        setDeleteFiles([]);
        setEditAble(false);
      }
    })
  }
  const onFieldChange = (key, value) => {
    setShopVisit({...shopVisit, [key]:value})
  }
  const uploadAttachments = (files) => {
    if(files.length < 11){
      let formData = new FormData();
      formData.append('shop_visit', true);
      for(let i=0;i<files.length;i++){
        formData.append('files[]', files[i])
      }
      
      setLoading(true)
      globalPostService(`technical/engine-workorder/${params.workOrderSlug}/file-upload/`, formData)
      .then(response => {
        if(checkApiStatus(response)){
          const user = getLocalStorageInfo()?.user || null
          let attachment = response?.data?.data.map((item) => {
            return {
              attachment:item.url,
              name:item.name,
              created_at:moment(),
              user:{id:user?.id||null, name:user?.name||''},
              description:''
            }
          })
          setShopVisit({...shopVisit, attachments:[...shopVisit.attachments, ...attachment]})
        }
        setLoading(false);
      })
    }else{
      enqueueSnackbar('Exceed max files upload limit', { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
    }
    
  }
  const onRemoveAttachment = (attachment) => {
    let filePath = decodeURIComponent(attachment.data.attachment);
    let newpath = filePath.split('https://ams3.digitaloceanspaces.com/sparta-aims/').join('').split('?Signature=')[0];
    setShopVisit({...shopVisit, attachments:shopVisit.attachments.filter((item,itemIndex) => itemIndex !== attachment.index)});
    setDeleteFiles([...deleteFiles,newpath]);
  }

  const onChangeAttachmentDesc = (key, value, index) => {
    setShopVisit({...shopVisit, attachments:shopVisit.attachments.map((item,itemIndex) => itemIndex !== index ? item : {
      ...item, description:value
    })})
  }
  const canEdit = currentFormDetail?.current_form?.permissions?.can_save;
  let te004Slug =currentFormDetail?.list.find(item => item?.id == 15)?.slug
  return(
    <Paper>
        { skeletonLoader ? <STableLoader count={5} />: 
          <>
            <p className='flex-centered' style={{padding:'1px 15px', borderBottom:'1px solid #f1f1f1', minHeight:'29px'}}>
              <Link style={{fontSize:'11px', color:'#3f51b5', textDecoration:'underline'}} to={`/${params.type}/${params.aircraft_slug}/technical/${params.workOrderSlug}/forms/${te004Slug?te004Slug:params.formSlug}/te004?tabType=shop-visits`}>Visit All Shop Visits</Link>
              {Object.keys(shopVisit).length ?
                <>
                  {canEdit && !isEditAble ? 
                    <span className='edit-g' style={{marginLeft:'auto'}}>
                      <IconButton size="small">
                        <Tooltip title='Edit Last Shop Visit' arrow><EditIcon onClick={() => setEditAble(true)} fontSize='small' color='primary' /></Tooltip>
                      </IconButton></span>:null
                  }
                </>:null
              }
            </p>
            <div style={{height: `${window.innerHeight- (isEditAble ? 265:225)}px`, overflow:'auto', padding: '0 15px'}}>
              { !Object.keys(shopVisit).length ?
                <div style={{textAlign:'center'}}><EmptyCollection title='No Last Shop Visit Available' /></div>:null
              }
              {Object.keys(shopVisit).length ? 
                  <ShopVisitCRU 
                    mode={isEditAble ? 'edit':''}
                    currentFormDetail={currentFormDetail}
                    shopVisitCrud={shopVisit} 
                    onFieldChange={onFieldChange}
                    error={error}
                    onResetError={(key) => setError({...error,[key]:''})}
                    uploadAttachments={uploadAttachments}
                    onChangeAttachmentDesc={onChangeAttachmentDesc}
                    onRemoveAttachment={onRemoveAttachment}
                  />:null
              }
            </div>
            { isEditAble ?
              <div style={{padding:'4px 15px'}}>
                <ul className='list-inline'>
                  <li className='list-inline-item'>
                    <Button onClick={onAddEditShopVisit} size='small' color='primary' variant='contained'>Save</Button>
                  </li>
                  <li className='list-inline-item'>
                    <Button onClick={() => {setEditAble(false); setError({});getShopVisit('pageLoader')}} size='small' color='primary' variant='outlined'>Cancel</Button>
                  </li>
                </ul>
              </div>:null
            } 
          </>
        }
        { isLoading ? <PageLoader/>:null }
    </Paper>
  )
}
export default withRouter(ShopVisitCard);