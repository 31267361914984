import React from "react";
import { withRouter } from "react-router";
const QuickIssueList = ({params, item, updateIssueUrl}) => {
    return(
        <div className={`quick-issue ${params.id === item.id ? 'active':''}`} onClick={updateIssueUrl}>
            <span className={`status-vertical`} style={{background:item.status.value === 0 ? '#4b82de': item.status.value ===1 ? '#269b2b':item.status.value ===2 ? '#e86642':'#dbcc28'}}></span>
            <h5>{item?.title || '--'}</h5>
            <span className="assignee">Assigned: {item?.assignee?.name || 'Unassigned'}</span>
        </div>
    )
}
export default withRouter(QuickIssueList);