import * as actions from '../actions';
import { globalGetService, globalPutService, globalPostService, globalDeleteService } from '../../../../../globalServices/';
import { toastFlashMessage } from '../../../../../utils';
const urlEndPoint = {
  t001: '/general-pictures/',
  t002: '/data-plate-pictures/',
  t003: '/document-sections/',
  t004: '/audit-report-sections/',
  t005: '/technical-specification-sections/',
  t006: '/contacts/',
  t007: '/inspection-finding-sections/',
  t009: '/robbery-list/'
}

export const triggeLoader = (flag) => {
  return (dispatch) => {
    dispatch({
      type: actions.COMMENTS_LOADER,
      payload: flag
    })
  }
}

export const auditSectionInfoAc = (props, data) => {
  return (dispatch) => {
    dispatch(triggeLoader(true));
    globalGetService(`technical/workorder/${props.params.workOrderSlug}/section/${data.id}/questions/`, {})
      .then(response => {
        dispatch(triggeLoader(false));
        if (response.data.statusCode == 200) {
          dispatch({
            type: actions.FORMS_REVIEW_FEEDBACKS,
            payload: { flag: true, title: response.data.data.length > 1 ? data.name : response.data.data[0].name, sectionId: data.id, type: 'edit', data: response.data.data }
          })
        }
      })
  }
}

export const fetchReviewDataAc = (props, formType = 't001') => {
  return (dispatch) => {
    dispatch(triggeLoader(true));
    globalGetService('technical/workorder/' + props.params.workOrderSlug + urlEndPoint[formType], { for_review: true })
      .then(response => {
        dispatch(triggeLoader(false));
        if (response.data.statusCode == 200) {
          dispatch({
            type: actions.FORMS_REVIEW_MODEL_DATA,
            formType,
            payload: response.data.data
          })
        }
      })
  }
}

export const fetchFormsFeedbackAc = (props, formType = 't001') => {
  return (dispatch) => {
    globalGetService('technical/' + props.params.type + '/workorder/' + props.params.workOrderSlug + '/forms/' + props.params.formSlug + '/review/', {})
      .then(response => {
        if (response.data.statusCode == 200) {
          dispatch({
            type: actions.FORMS_REVIEW_FEEDBACKS,
            payload: response.data.data
          })
        }
      })
  }
}

export const addCommentsAc = (location_slug, data) => {
  return (dispatch) => {
    dispatch(triggeLoader(true));
    globalPostService('technical/review/' + location_slug + '/comment/', { message: data })
      .then(response => {
        dispatch(triggeLoader(false));
        if (response.data.statusCode == 200) {
          dispatch({
            type: actions.REVIEW_ADD_COMMENT,
            payload: response.data.data
          })
          dispatch({
            type: actions.NOTIFY_CLIENT,
            payload: true
          })
        }
      })
  }
}

export const notifyClientAc = (props) => {
  return (dispatch) => {
    dispatch(triggeLoader(true));
    globalGetService('/technical/workorder/' + props.params.workOrderSlug + '/form/' + props.params.formSlug + '/notify-user/', {})
      .then(response => {
        dispatch(triggeLoader(false));
        if (response.data.statusCode == 200) {
          toastFlashMessage(response.data.message, 'success');
          dispatch({
            type: actions.NOTIFY_CLIENT,
            payload: false
          })
        }
      })
  }
}


export const updateCommentsAc = (props, location_slug, id) => {
  return (dispatch) => {
    dispatch(triggeLoader(true));
    globalPutService('technical/review/' + location_slug + '/comment/' + id + '/', { status: 1 })
      .then(response => {
        dispatch(triggeLoader(false));
        if (response.data.statusCode == 200) {
          dispatch({
            type: actions.REVIEW_UPDATE_COMMENT,
            payload: response.data.data
          })
          dispatch({
            type: actions.NOTIFY_CLIENT,
            payload: true
          })
        }
      })
  }
}


export const addIssuesAc = (props, data, formType) => {
  return (dispatch) => {
    dispatch(triggeLoader(true));
    globalPostService('/technical/aircraft/workorder/' + props.params.workOrderSlug + '/forms/' + props.params.formSlug + '/review/', data)
      .then(response => {
        dispatch(triggeLoader(false));
        dispatch({
          type: actions.ISSUES_CRUD,
          payload: { flag: false, data: { qoute: '', description: '', object_id: '', issue_type: 3, form_id: 4 } }
        })
        if (response.data.statusCode == 200) {
          dispatch({
            type: actions.ADD_ISSUES_REVIEW,
            payload: response.data.data
          })
          dispatch({
            type: actions.TOOGLE_ISSUES_LIST,
            payload: {}
          })
          dispatch({
            type: actions.NOTIFY_CLIENT,
            payload: true
          })
          dispatch(
            fetchReviewDataAc(props, formType)
          )

        }
      })
  }
}


export const fetchIssueDetailAc = (props, locationSlug, formType = 't001') => {
  return (dispatch) => {
    dispatch(triggeLoader(true));
    globalGetService('technical/' + props.params.type + '/workorder/' + props.params.workOrderSlug + '/forms/' + props.params.formSlug + '/review/' + locationSlug, {})
      .then(response => {
        dispatch(triggeLoader(false));
        if (response.data.statusCode == 200) {
          dispatch({
            type: actions.FORMS_REVIEW_FEEDBACKS_DETAIL,
            payload: response.data.data
          })
          dispatch({
            type: actions.TOGGLE_DETAILS,
            payload: {}
          })
        }
      })
  }
}

export const changeIssueStatusAc = (props, locationSlug, status, formType = 't001', flag) => {
  let query = {};
  if (flag == 'details' && status == 1) {
    query = { resolve_all: true }
    return (dispatch) => {
      dispatch(triggeLoader(true));
      globalGetService('technical/' + props.params.type + '/workorder/' + props.params.workOrderSlug + '/forms/' + props.params.formSlug + '/review/' + locationSlug + '/', query)
        .then(response => {
          dispatch(triggeLoader(false));
          if (response.data.statusCode == 200) {
            dispatch({
              type: actions.NOTIFY_CLIENT,
              payload: true
            })
            if (flag == 'details') {
              dispatch({
                type: actions.FORMS_REVIEW_FEEDBACKS_DETAIL,
                payload: response.data.data
              });
              dispatch(fetchFormsFeedbackAc(props))
            } else {
              dispatch({
                type: actions.UPDATE_FORMS_REVIEW_FEEDBACKS,
                payload: response.data.data
              });
            }
            toastFlashMessage(response.data.message, 'success');
          }
        })
    }
  } else if (flag == 'delete') {
    return (dispatch) => {
      dispatch(triggeLoader(true));
      globalDeleteService('technical/' + props.params.type + '/workorder/' + props.params.workOrderSlug + '/forms/' + props.params.formSlug + '/review/' + locationSlug + '/', {})
        .then(response => {
          dispatch(triggeLoader(false));
          if (response.data.statusCode == 200) {
            dispatch({
              type: actions.NOTIFY_CLIENT,
              payload: true
            })
            if (flag == 'delete') {
              dispatch({
                type: actions.FORMS_REVIEW_FEEDBACKS_DELETE,
                payload: locationSlug
              });
              dispatch(fetchFormsFeedbackAc(props))
            } else {
              dispatch({
                type: actions.UPDATE_FORMS_REVIEW_FEEDBACKS,
                payload: response.data.data
              });
            }
            toastFlashMessage(response.data.message, 'success');
          }
        })
    }
  } else if (flag == 'edit') {
    return (dispatch) => {
      dispatch(triggeLoader(true));
      globalPutService('technical/' + props.params.type + '/workorder/' + props.params.workOrderSlug + '/forms/' + props.params.formSlug + '/review/' + locationSlug + '/', { description: status })
        .then(response => {
          dispatch(triggeLoader(false));
          if (response.data.statusCode == 200) {
            dispatch({
              type: actions.NOTIFY_CLIENT,
              payload: true
            })
            dispatch({
              type: actions.UPDATE_EDIT_ISSUES,
              payload: response.data.data
            });
            if (flag == 'edit') {
              dispatch({
                type: actions.UPDATE_FORMS_REVIEW_FEEDBACKS,
                payload: response.data.data
              });
              dispatch(fetchFormsFeedbackAc(props))
            } else {
              dispatch({
                type: actions.UPDATE_FORMS_REVIEW_FEEDBACKS,
                payload: response.data.data
              });
            }
            toastFlashMessage(response.data.message, 'success');
          }
        })
    }
  } else {
    return (dispatch) => {
      dispatch(triggeLoader(true));
      globalPutService('technical/' + props.params.type + '/workorder/' + props.params.workOrderSlug + '/forms/' + props.params.formSlug + '/review/' + locationSlug + '/', { status: status })
        .then(response => {
          dispatch(triggeLoader(false));
          if (response.data.statusCode == 200) {
            dispatch({
              type: actions.NOTIFY_CLIENT,
              payload: true
            })
            if (flag == 'details') {
              dispatch({
                type: actions.FORMS_REVIEW_FEEDBACKS_DETAIL,
                payload: response.data.data
              });
              dispatch(fetchFormsFeedbackAc(props))
            } else {
              dispatch({
                type: actions.UPDATE_FORMS_REVIEW_FEEDBACKS,
                payload: response.data.data
              });
            }
            toastFlashMessage(response.data.message, 'success');
          }
        })
    }
  }
}
