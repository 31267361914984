import React, { useEffect, useState } from "react";
import { useSnackbar } from 'notistack';
import { Paper, Grid, Button } from "@material-ui/core";
import { BudgetWrapper, AllocationList, AddEditAllocation } from '../components';
import { TableListComp, EmptyCollection, PageLoader, DeletePopUp } from '../../../shared_elements';
import FilterComponent from '../../../shared_elements/filter_component'
import { STableLoader } from '../../../shared_elements/loaders';
import { globalGetService, globalDeleteService, globalExportService } from '../../../utils_v2/globalApiServices'
import { checkApiStatus, downloadFileType, onCheckPermission } from '../../../utils_v2'
import { allocationHd, allocationFilterOptions } from '../';
import { trackActivity } from '../../../utils/mixpanel';
const BudgetAllocation = ({params}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(true)
    const [modal, setModal] = useState(false);
    const [filter, setFilter] = useState({});
    const [project, setProject] = useState({});
    const [budgetId,setBudgetId] = useState(null);
    const [deleteBudget,setDeleteBudget] = useState({modal:false, budget:null});
    const [budgetAllocations, setBudgetAllocation] = useState({list:[]})
    const [categories, setCategories] = useState([]);
    useEffect(() => {
        getAllocationList({page:1, per_page:20}, 'skeletonLoader');
        getProjectDetail();
        trackActivity('Expense Management', {
            event_type: 'Page Visited',
            page_title: 'Budget Allocation',
        });
        getCategories();
    }, []);
    const getCategories = () => {
        globalGetService(`technical/budget/categories/`)
        .then(response => {
            if(checkApiStatus(response)){
                setCategories(response.data.data);
            }
        });
    }
    const getProjectDetail = () => {
        setLoading(true);
        globalGetService(`technical/project/${params.project_slug}/`,{fields:'work_orders,archive_status,id,name,status,start_date,expected_end_date,currency,budget_amount'})
        .then(response => {
            setLoading(false);
            if(checkApiStatus(response)){
                setProject(response.data.data);
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
            }
        });
    }
    const getAllocationList = (query={}, loaderType) => {
        if(loaderType === 'skeletonLoader'){ setSkeletonLoader(true) }else{ setLoading(true); }
        let queryParams = Object.assign({}, query);
        delete queryParams.asset;
        if(query?.asset?.trim()?.length){
            let assetInfo = query.asset.split('-')
            queryParams = {
                ...queryParams,
                asset_id:assetInfo[0],
                asset_type:assetInfo[1]
            }
        }
        globalGetService(`technical/project/${params.project_slug}/budgets/`,queryParams)
        .then(response => {
            if(checkApiStatus(response)){
                setBudgetAllocation(response.data.data);
                delete query.page
                delete query.per_page
                setFilter(query);
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
            }
            if(loaderType === 'skeletonLoader'){ setSkeletonLoader(false) }else{ setLoading(false); }
        })
    }
    const onExportAllocation = (fileName, extension) => {
        let queryParams = Object.assign({}, filter);
        delete queryParams.asset;
        if(filter?.asset?.trim()?.length){
            let assetInfo = filter.asset.split('-')
            queryParams = {
                ...queryParams,
                asset_id:assetInfo[0],
                asset_type:assetInfo[1]
            }
        }
        enqueueSnackbar('Allocation Report Downloading ...', { variant: 'info',anchorOrigin:{horizontal:'right', vertical:'bottom'}})
        globalExportService(`technical/project/${params.project_slug}/budgets/`,{download:'xls',...queryParams})
        .then(response => {
            downloadFileType(response, 'Budget_Allocation.', 'xls')
            closeSnackbar();
            enqueueSnackbar('Allocation Report Downloaded', { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'bottom'}})
            trackActivity('Project Management', {
                event_type: 'File Exported',
                page_title: 'Budget Allocation',
                project_slug: params.project_slug ? params.project_slug : '',
                file_name: 'Budget_Allocation',
                file_extension: 'xls',
                success_msg: response.data.message
            })
        });
    }
    const onDeleteBudgetApi = () => {
        setLoading(true);
        globalDeleteService(`technical/project/${params.project_slug}/budgets/${deleteBudget.budget.id}/`)
        .then(response => {
            setLoading(false);
            if(checkApiStatus(response)){
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
                setDeleteBudget({modal:false, budget:null});
                getAllocationList({page:budgetAllocations.pagination.page,per_page:budgetAllocations.pagination.per_page}, 'pageLoader');
                trackActivity('Project Management', {
                    event_type: 'Delete Budget Success',
                    page_title: 'Budget Allocation',
                    project_slug: params.project_slug ? params.project_slug : '',
                    id: deleteBudget.budget.id,
                    file_extension: 'xls',
                    success_msg: response.data.message
                })
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
            }
        })
    }
    let filterOptions = Object.assign({}, allocationFilterOptions);
    if(project?.work_orders?.length){
        let workOrderOptions = project.work_orders.map((wk) => wk.asset).map((item) => {return {...item, unique_id:`${item.id}-${item.type}`}})
        filterOptions = {
            ...filterOptions,
            asset:{
                ...filterOptions.asset,
                options:workOrderOptions
            }
        }
    }
    if(categories.length){
        filterOptions = {
            ...filterOptions,
            category:{
                ...filterOptions.category,
                options:categories
            }
        }
    }
    return (
        <section className='projects-management-apps projects-management-section'>
            <BudgetWrapper project={project} getResponseBack={() => getProjectDetail()}>
                <div>
                    <Paper square className="filter-cta">
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={9}>
                                <FilterComponent
                                    filter={filter}
                                    filterMenu={filterOptions}
                                    getResponseBack={(applyFilter) => getAllocationList({...applyFilter, page:1, per_page:budgetAllocations.pagination.per_page}, 'pageLoader')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                { project && project.currency ?
                                    <ul className="list-inline cta-list">
                                        { onCheckPermission('expense_management', 'budget_allocation', 'C') ?
                                            <li className="list-inline-item">
                                                <Button color="primary" variant="contained" size="small" onClick={() => {setModal(true); trackActivity('Expense Management', {page_title: 'Budget Allocation', event_type:'Clicked on Add Allocation Button'})}}>Add Allocation</Button>
                                            </li>
                                            : null
                                        }
                                        { onCheckPermission('expense_management', 'budget_allocation', 'EXP') && budgetAllocations?.list?.length ?
                                            <li className="list-inline-item">
                                                <Button onClick={() => onExportAllocation('Budget_Allocation','xls')} color="primary" variant="outlined" size="small">Export</Button>
                                            </li>:null
                                        }
                                    </ul>:null
                                }
                            </Grid>
                        </Grid>
                    </Paper>
                </div>
                { skeletonLoader ? <STableLoader count={6} /> :
                    <Paper>
                        <TableListComp
                            style={{height: !budgetAllocations.list.length ? 'auto':`${window.innerHeight-290}px`,overflow:'auto'}}
                            heads={allocationHd}
                            data={ budgetAllocations.list && budgetAllocations.list.map((item,index) =>
                                <AllocationList
                                    project={project}
                                    key={index}
                                    index={index}
                                    item={item}
                                    onEditBudget={() => {setBudgetId(item.id);setModal(true)}}
                                    onDeleteBudget={() => setDeleteBudget({modal:true, budget:item})}
                                />
                            )}
                            noRecord={ budgetAllocations.list.length ? null:
                                <EmptyCollection
                                title="No records found"
                                />
                            }
                            pagination={budgetAllocations.pagination}
                            onChangePage={(event, newPage) => getAllocationList({page:newPage+1, per_page: budgetAllocations.pagination.per_page}, 'pageLoader')}
                            onChangeRowsPerPage={(event) => getAllocationList({page:1, per_page: event.target.value}, 'pageLoader')}
                        />
                    </Paper>
                }
            </BudgetWrapper>
            { modal ?
                <AddEditAllocation
                    project={project}
                    modal={modal}
                    id={budgetId}
                    budgets={budgetAllocations.list ? budgetAllocations.list:[]}
                    toggleModalFn={() => {setModal(false);setBudgetId(null)}}
                    getResponseBack={() => getAllocationList({page:budgetAllocations.pagination.page,per_page:budgetAllocations.pagination.per_page}, 'pageLoader')}
                /> :null
            }
            <DeletePopUp
                modal={deleteBudget.modal}
                toggleModalFn={() => setDeleteBudget({modal:false, budget:null})}
                title="Delete Budget Allocation"
                content={<h4>Are you sure you want to delete?</h4>}
                deleteRecordFn={onDeleteBudgetApi}
            />
            { isLoading ? <PageLoader /> : null }
        </section>
    );
}
export default BudgetAllocation;
