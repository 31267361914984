import React, { Fragment, useState ,useEffect} from 'react';
import { withRouter } from 'react-router';
import { useSnackbar } from 'notistack';
import { Button, Dialog, Grid, DialogActions, DialogContent, DialogTitle, Tooltip, CircularProgress } from '@material-ui/core';
import {SortableContainer, SortableElement, sortableHandle} from 'react-sortable-hoc';
import { FormSkeletonLoader } from '../../Elements';
import {arrayMoveImmutable} from 'array-move';
import { globalPutService } from '../../../utils_v2/globalApiServices';
import { checkApiStatus } from '../../../utils_v2';
import SortIcon from '@material-ui/icons/Sort';
import { trackActivity } from '../../../utils/mixpanel';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { globalPostService } from '../../../globalServices';
const DragHandle = sortableHandle(() => <span style={{paddingRight:'10px', display:'inline-block', cursor:'move'}}><DragIndicatorIcon /></span>);
const SortableItem = SortableElement(({value}) => <div className='sort-section-list flex-centered'><DragHandle /> <span>{value.name}</span></div>);
const SortableList = SortableContainer(({items}) => {
    return (
        <Grid item md={12} >
            {items.map((value, index) => (
                <SortableItem key={`item-${index}`} sortIndex={index} index={index} value={value} />
            ))}
        </Grid>
    );
});
const SortItems = ({params, toolTipContent, itemType, sectionId, documentCheckList, getResponseBack}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [formLoader, setFormLoader] = useState(true);
    const [sections, setSections] = useState([]);
    const [modal, setModal] = useState(false);
    const onSortEnd = ({oldIndex, newIndex}) => {
        setSections(arrayMoveImmutable(sections, oldIndex, newIndex));
    };
    useEffect(() => {
        const timer = setTimeout(() => {
          setFormLoader(false);
        }, 3000);
    }, []);
    const getSections = () => {
        if(itemType === 'section'){
            setSections(documentCheckList.map(section => {return{name:section.name,id:section.id}} ))
        }else{
            setSections(documentCheckList.find(section=>section.id === sectionId).sub_sections.map(subSection => {return{name:subSection.name,id:subSection.id}} ))
        }
    }
    const updateSectionsSort = () => {
        setLoading(true);
        if(itemType === 'section'){
            globalPostService(`technical/v2/workorder/${params.workOrderSlug}/workbook/sort/sections/?asset_type=${params.type}`, sections.map((item,index)=> {return {...item,order:index+1}}))
            .then(response => {
                setLoading(false);
                if(checkApiStatus(response)){
                    enqueueSnackbar(itemType === 'section' ? 'Sections display order updated Successfully' : 'Sub-Section sorting updated successfully', { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
                    getResponseBack();setModal(false);setSections([]);
                    window.location.reload()
                }else{
                    enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
                }
            })
        }
        else{
            globalPostService(`technical/v2/workorder/${params.workOrderSlug}/workbook/sort/sections/${sectionId}/sub-section/?asset_type=${params.type}`, sections.map((item,index)=> {return {...item,order:index+1}}))
            .then(response => {
                setLoading(false);
                if(checkApiStatus(response)){
                    enqueueSnackbar(itemType === 'section' ? 'Sections display order updated Successfully' : 'Sub-Section sorting updated successfully', { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
                    getResponseBack();setModal(false);setSections([]);
                    window.location.reload()
                }else{
                    enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
                }
            })
        }
    }
    return(
        <Fragment>
            <Tooltip title={toolTipContent?toolTipContent:'Sort Section'} arrow><SortIcon onClick={(e) => { e.stopPropagation(); setModal(true); getSections()}} color='primary' /></Tooltip>
            {modal ? 
                <Dialog
                    open={modal}
                    onClose={(e) => {e.stopPropagation();setModal(false)}}
                    className='project-management-modal section-sorting-modal'
                >
                    <DialogTitle id="scroll-dialog-title">
                        {toolTipContent}
                    </DialogTitle>
                    <DialogContent dividers={true}>
                    {formLoader ?  <div style={{ minHeight: '380px', width:'380px'}}>  <FormSkeletonLoader /> </div> : (
                        <div className='form-section-sorting-modal'>
                            <Grid container spacing={1}>
                                <SortableList helperClass='sortableHelper' items={sections} onSortEnd={onSortEnd} useDragHandle />
                            </Grid>
                        </div>
                    )}
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={isLoading} onClick={(e)=> { e.stopPropagation();setModal(false)}} color="primary" size='small' variant='outlined'>Cancel</Button>
                        <Button disabled={isLoading} onClick={(e)=>{ e.stopPropagation(); updateSectionsSort()}} color="primary" size='small' variant='contained'>
                        {isLoading ? <CircularProgress size={20} /> : 'Save'}
                        </Button>
                    </DialogActions>
                </Dialog>:null
            }   
        </Fragment>
    )
}
export default withRouter(SortItems);