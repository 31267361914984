import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { useSnackbar } from 'notistack';
import { Button, TableRow, TableCell, TextField, Paper, Table, TableHead, TableBody, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Checkbox, CircularProgress, Tooltip, IconButton } from '@material-ui/core';
import { EmptyCollection, DeletePopUp, PageLoader, Pagination, STableLoader } from '../../../shared_elements';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import SettingsInputSvideoIcon from '@material-ui/icons/SettingsInputSvideo';
import { regexConstants } from '../../../constants/regEx';
import { globalDeleteService, globalGetService, globalPostService, globalPutService } from '../../../utils_v2/globalApiServices';
import { checkApiStatus } from '../../../utils_v2';
const accessoryObj = {
    description:'',
    ata:'',
    delivery_part_number:'',
    delivery_serial_number:'',
    installed_part_number:'',
    installed_serial_number:'',
    tsn:null,
    csn:null,
    remarks:''
}
const ConfigDefaultComponents = ({defaultComponents, toggleModalFn, getResponseBack, params}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false)
    const onConfigDefaultComponents = () => {
        globalPostService(`technical/v2/workorder/${params.workOrderSlug}/powerplant-accessories/`, {accessories:defaultComponents.data})
        .then(response => {
            if(checkApiStatus(response)){
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                toggleModalFn(); getResponseBack();
            }
            setLoading(false);
        })
    }
    return(
        <Dialog
            open={defaultComponents.modal}
            onClose={toggleModalFn}
            className='project-management-modal'
            maxWidth='md'
        >
            <DialogTitle id="scroll-dialog-title">
                Config Default Accessories List
            </DialogTitle>   
            <DialogContent dividers={true}>
                <div style={{minWidth:'420px'}}>
                    <Grid container spacing={1}>
                        <Grid item md={12}>
                            <Table className='mui-table-format'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>ATA</TableCell>
                                        <TableCell>Description</TableCell>
                                        <TableCell>Delivery Part Number</TableCell>
                                        <TableCell>Installed Part Number</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    { defaultComponents.data.map((item) => 
                                        <TableRow>
                                            <TableCell>{item?.ata||'--'}</TableCell>
                                            <TableCell>{item?.description ||'--'}</TableCell>
                                            <TableCell>{item?.delivery_part_number||'--'}</TableCell>
                                            <TableCell>{item?.installed_part_number||'--'}</TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                            { !defaultComponents.data.length ? <EmptyCollection title="No records found" />:null }
                        </Grid>
                    </Grid>
                </div>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} onClick={toggleModalFn} color="primary" size="small">Cancel</Button>
                <Button disabled={isLoading} onClick={onConfigDefaultComponents} variant="contained" color="primary" size="small">
                    {isLoading ? <CircularProgress size={20} /> : 'SAVE' }
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const AddEditAccessory = ({params, toggleModalFn, addEditAccessory, getResponseBack}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false)
    const [error, setError] = useState({});
    const [accessory, setAccessory] = useState(addEditAccessory.data);
    const onFieldChange = (key, value) => {
      setAccessory({...accessory, [key]:value})
    }
    const onAddEditAccessory = () => {
        let validationInputs = {
            description:accessory?.description?.trim()?.length ? '':'Please enter Description',
        }
        if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
            setLoading(true);
            if(accessory.id){
                globalPutService(`technical/v2/workorder/${params.workOrderSlug}/powerplant-accessories/${accessory.id}/`, accessory)
                .then(response => {
                    if(checkApiStatus(response)){
                        enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                        toggleModalFn(); getResponseBack();
                    }
                    setLoading(false);
                })
            }else{
                globalPostService(`technical/v2/workorder/${params.workOrderSlug}/powerplant-accessories/`, {accessories:[accessory]})
                .then(response => {
                    if(checkApiStatus(response)){
                        enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                        toggleModalFn(); getResponseBack();
                    }
                    setLoading(false);
                })
            }
        }else{
            setError(validationInputs)
        }
    }
    return(
        <Dialog
            open={addEditAccessory.modal}
            onClose={toggleModalFn}
            className='project-management-modal'
            maxWidth='md'
        >
            <DialogTitle id="scroll-dialog-title">
                {accessory.id ? 'Edit':'Add'} Accessory
            </DialogTitle>
            <DialogContent dividers={true}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <TextField
                            required
                            name="description"
                            label="Description"
                            fullWidth
                            margin="normal"
                            inputProps={{maxLength: 250}}
                            value={accessory?.description||''}
                            onChange={(e) => onFieldChange('description', e.target.value)}
                            error={error?.description}
                            multiline
                            rows={3}
                            helperText={error?.description||''}
                            InputLabelProps={{shrink: true}}
                            onFocus={(e) => setError({...error,'description':''})}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <TextField
                            name="ata"
                            label="ATA"
                            fullWidth
                            margin="normal"
                            inputProps={{maxLength: 20}}
                            value={accessory?.ata||''}
                            onChange={(e) => onFieldChange('ata', e.target.value)}
                            error={error?.ata}
                            helperText={error?.ata||''}
                            InputLabelProps={{shrink: true}}
                            onFocus={(e) => setError({...error,'ata':''})}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <TextField
                            name="delivery_part_number"
                            label="Delivery P/N"
                            fullWidth
                            margin="normal"
                            inputProps={{maxLength: 20}}
                            value={accessory?.delivery_part_number||''}
                            onChange={(e) => onFieldChange('delivery_part_number', e.target.value)}
                            error={error?.delivery_part_number}
                            helperText={error?.delivery_part_number||''}
                            InputLabelProps={{shrink: true}}
                            onFocus={(e) => setError({...error,'delivery_part_number':''})}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <TextField
                            name="delivery_serial_number"
                            label="Delivery S/N"
                            fullWidth
                            margin="normal"
                            inputProps={{maxLength: 20}}
                            value={accessory?.delivery_serial_number||''}
                            onChange={(e) => onFieldChange('delivery_serial_number', e.target.value)}
                            error={error?.delivery_serial_number}
                            helperText={error?.delivery_serial_number||''}
                            InputLabelProps={{shrink: true}}
                            onFocus={(e) => setError({...error,'delivery_serial_number':''})}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <TextField
                            name="installed_part_number"
                            label="Installed P/N"
                            fullWidth
                            margin="normal"
                            inputProps={{maxLength: 20}}
                            value={accessory?.installed_part_number||''}
                            onChange={(e) => onFieldChange('installed_part_number', e.target.value)}
                            error={error?.installed_part_number}
                            helperText={error?.installed_part_number||''}
                            InputLabelProps={{shrink: true}}
                            onFocus={(e) => setError({...error,'installed_part_number':''})}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <TextField
                            name="installed_serial_number"
                            label="Installed S/N"
                            fullWidth
                            margin="normal"
                            inputProps={{maxLength: 20}}
                            value={accessory?.installed_serial_number||''}
                            onChange={(e) => onFieldChange('installed_serial_number', e.target.value)}
                            error={error?.installed_serial_number}
                            helperText={error?.installed_serial_number||''}
                            InputLabelProps={{shrink: true}}
                            onFocus={(e) => setError({...error,'installed_serial_number':''})}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <TextField
                            name="tsn"
                            label="TSN"
                            fullWidth
                            margin="normal"
                            inputProps={{maxLength: 10}}
                            value={accessory?.tsn||''}
                            onChange={(e, value) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange('tsn', e.target.value): e.preventDefault()}}
                            error={error?.tsn}
                            helperText={error?.tsn||''}
                            InputLabelProps={{shrink: true}}
                            onFocus={(e) => setError({...error,'tsn':''})}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <TextField
                            name="csn"
                            label="CSN"
                            fullWidth
                            margin="normal"
                            inputProps={{maxLength: 10}}
                            value={accessory?.csn||''}
                            onChange={(e, value) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange('csn', e.target.value) : e.preventDefault() }}
                            error={error?.csn}
                            helperText={error?.csn||''}
                            InputLabelProps={{shrink: true}}
                            onFocus={(e) => setError({...error,'csn':''})}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="remarks"
                            label="Remarks"
                            fullWidth
                            margin="normal"
                            inputProps={{maxLength: 250}}
                            value={accessory?.remarks||''}
                            onChange={(e) => onFieldChange('remarks', e.target.value)}
                            error={error?.remarks}
                            multiline
                            rows={4}
                            helperText={error?.remarks||''}
                            InputLabelProps={{shrink: true}}
                            onFocus={(e) => setError({...error,'remarks':''})}
                            variant='outlined'
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} onClick={toggleModalFn} color="primary" size="small">Cancel</Button>
                <Button disabled={isLoading} onClick={onAddEditAccessory} variant="contained" color="primary" size="small">
                    {isLoading ? <CircularProgress size={20} /> : 'SAVE' }
                </Button>
            </DialogActions>
        </Dialog>
    )
}
const AccessoriesList = ({params, currentFormDetail, getCurrentFormDetail}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [filter, setFilter] = useState({});
    const [accessoriesInfo, setAccessoriesInfo] = useState({list:[], pagination:null});
    const [addEditAccessory, setAddEditAccessory] = useState({data:null, modal:false});
    const [deleteAccessory, setDeleteAccessory] = useState({data:[], modal:false});
    const [defaultComponents, setDefaultComponents] = useState({modal:false, data:[]});
    useEffect(() => {
        getAccessories({}, 'skeletonLoader');
    },[]);
    const getAccessories = (query, loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true):setLoading(true);
        globalGetService(`technical/v2/workorder/${params.workOrderSlug}/powerplant-accessories/`, query)
        .then(response => {
            if(checkApiStatus(response)){
                setAccessoriesInfo(response.data.data);
            }
            loaderType === 'skeletonLoader' ? setSkeletonLoader(false):setLoading(false);
        })
    }
    const handleChange = (type, flag, id) => {
        if(type === 'single'){
            setDeleteAccessory({
                ...deleteAccessory,
                data: flag ? [...deleteAccessory.data, id]:deleteAccessory.data.filter(item =>  item !== id)
            })
        }else{
            let ids = accessoriesInfo.list.map(item => item.id)
            setDeleteAccessory({
                ...deleteAccessory,
                data: flag ? ids:[]
            })
        }
    }
    const onDeleteAccessory = () => {
        setLoading(true);
        globalDeleteService(`technical/v2/workorder/${params.workOrderSlug}/powerplant-accessories/`,{ids:deleteAccessory.data})
        .then(response => {
            if(checkApiStatus(response)){
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                setDeleteAccessory({modal:false, data:[]})
                getAccessories({}, 'pageLoader');
                getCurrentFormDetail('pageLoader');
            }
            setLoading(true);
        })
    }
    const getDefaultComponents = () => {
        setLoading(true)
        globalGetService(`technical/v2/workorder/${params.workOrderSlug}/powerplant-accessories-config/`)
        .then(response => {
            if(checkApiStatus(response)){
                setDefaultComponents({modal:true, data: response?.data?.data?.list||[]})
            }
            setLoading(false)
        })
    }
    const canEdit = currentFormDetail?.current_form?.permissions?.can_save;
    return(
        <div className='forms-table-layout'>
            { skeletonLoader ? <STableLoader count={10} />: 
                <>
                    <Paper className='forms-table-cta' square>
                        <Grid container spacing={1}>
                            <Grid item md={6}></Grid>
                            <Grid item md={6}>
                                { canEdit ?
                                    <ul className='list-inline flex-centered'>
                                        { deleteAccessory?.data?.length ?
                                            <li className='list-inline-item'>
                                                <Button onClick={() => setDeleteAccessory({...deleteAccessory, modal:true})} color='secondary' size='small' variant='contained'>Delete</Button>
                                            </li>:null
                                        }
                                        { !accessoriesInfo.list.length && accessoriesInfo.config_exist ?
                                            <li className='list-inline-item'>
                                                <Tooltip title='Config Default Accessories List' arrow><SettingsInputSvideoIcon style={{cursor:'pointer'}} onClick={getDefaultComponents} fontSize='small' color='primary' /></Tooltip>
                                            </li>:null
                                        }
                                        <li className='list-inline-item'>
                                            <Button onClick={() => setAddEditAccessory({data:accessoryObj, modal:true})} color='primary' size='small' variant='contained'>Add Accessory</Button>
                                        </li>
                                        <li className='list-inline-item' style={{display:'none'}}>
                                            <Button onClick={() => setAddEditAccessory({data:{}, modal:true})} color='primary' size='small' variant='contained'>Import</Button>
                                        </li>
                                        <li className='list-inline-item' style={{display:'none'}}>
                                            <Button onClick={() => setAddEditAccessory({data:{}, modal:true})} color='primary' size='small' variant='contained'>Export</Button>
                                        </li>
                                    </ul>:null
                                }
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper className='forms-table-content'>
                        <div style={{height: !accessoriesInfo.list.length ? 'auto':`${window.innerHeight-290}px`,overflow:'auto', paddingRight:'5px'}}>
                            <Table className='mui-table-format' stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        { canEdit ?
                                            <TableCell>
                                                <Checkbox
                                                    size='small'
                                                    checked={deleteAccessory?.data?.length ? deleteAccessory?.data?.length === accessoriesInfo?.list.length :false}
                                                    onChange={(e) => handleChange('bulk', e.target.checked)}
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    disabled={!accessoriesInfo?.list?.length}
                                                />
                                            </TableCell>:null
                                        }
                                        <TableCell>Description</TableCell>
                                        <TableCell>ATA</TableCell>
                                        <TableCell>Delivery P/N</TableCell>
                                        <TableCell>Delivery S/N</TableCell>
                                        <TableCell>Installed P/N</TableCell>
                                        <TableCell>Installed S/N</TableCell>
                                        <TableCell>TSN</TableCell>
                                        <TableCell>CSN</TableCell>
                                        <TableCell>Remarks</TableCell>
                                        <TableCell align='right'>Action(s)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    { accessoriesInfo.list.map((item,index) => 
                                        <TableRow>
                                            { canEdit ?
                                                <TableCell>
                                                    <div style={{width:'40px'}}>
                                                        <Checkbox
                                                            size='small'
                                                            checked={deleteAccessory.data.includes(item.id)}
                                                            onChange={(e) => handleChange('single', e.target.checked, item.id)}
                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                        />
                                                    </div>
                                                </TableCell>:null
                                            }
                                            <TableCell>
                                                <div style={{width:'220px'}}>{item?.description||'--'}</div>
                                            </TableCell>
                                            <TableCell>
                                                <div style={{width:'80px'}}>{item?.ata||'--'}</div>
                                            </TableCell>
                                            <TableCell>
                                                <div style={{width:'80px'}}>{item?.delivery_part_number||'--'}</div>
                                            </TableCell>
                                            <TableCell>
                                                <div style={{width:'80px'}}>{item?.delivery_serial_number||'--'}</div>
                                            </TableCell>
                                            <TableCell>
                                                <div style={{width:'80px'}}>{item?.installed_part_number||'--'}</div>
                                            </TableCell>
                                            <TableCell>
                                                <div style={{width:'80px'}}>{item?.installed_serial_number||'--'}</div>
                                            </TableCell>
                                            <TableCell>
                                                <div style={{width:'80px'}}>{item?.tsn||'--'}</div>
                                            </TableCell>
                                            <TableCell>
                                                <div style={{width:'80px'}}>{item?.csn||'--'}</div>
                                            </TableCell>
                                            <TableCell>
                                                <div style={{width:'220px'}}>{item?.remarks||'--'}</div>
                                            </TableCell>
                                            <TableCell align='right'>
                                                { canEdit ?
                                                    <ul className='list-inline' style={{width:'80px', float:'right'}}>
                                                        <li className='list-inline-item'>
                                                            <Tooltip title='Edit' arrow>
                                                                <IconButton size="small"><EditIcon onClick={() => setAddEditAccessory({modal:true, data:item})} fontSize='small' color='primary' /> </IconButton>
                                                            </Tooltip>
                                                        </li>
                                                        <li className='list-inline-item'>
                                                            <Tooltip title='Delete' arrow>
                                                                <IconButton size="small"> <DeleteOutlineIcon onClick={() => setDeleteAccessory({modal:true, data:[item.id]})} fontSize='small' color='secondary' /> </IconButton>
                                                            </Tooltip>
                                                        </li>
                                                    </ul>:null
                                                }
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </div>
                        <Pagination 
                            pagination={accessoriesInfo.pagination}
                            onChangePage={(event, newPage) => getAccessories({...filter, page:newPage+1, per_page: accessoriesInfo.pagination.per_page}, 'pageLoader')}
                            onChangeRowsPerPage={(event) => getAccessories({...filter, page:1, per_page: event.target.value}, 'pageLoader')}
                        />
                        { !accessoriesInfo.list.length ? <EmptyCollection title="No records found" />:null }
                    </Paper>
                </>
            }
            { defaultComponents.modal ? 
                <ConfigDefaultComponents 
                    defaultComponents={defaultComponents}
                    toggleModalFn={() => setDefaultComponents({modal:false, data:[]})}
                    getResponseBack={() => {getAccessories({}, 'pageLoader'); getCurrentFormDetail('pageLoader');}}
                    params={params}
                />:null
            }
            { addEditAccessory.modal ?
                <AddEditAccessory 
                    params={params}
                    addEditAccessory={addEditAccessory}
                    toggleModalFn={() => setAddEditAccessory({data:null, modal:false})}
                    getResponseBack={() => {getAccessories({}, 'pageLoader'); getCurrentFormDetail('pageLoader');}}
                />:null
            }
            { deleteAccessory.modal ?
                <DeletePopUp
                    modal={deleteAccessory.modal}
                    toggleModalFn={() => setDeleteAccessory({data:[], modal:false})}
                    title="Delete Accessory(s)"
                    content={<h4>Are you sure you want to delete?</h4>}
                    deleteRecordFn={onDeleteAccessory}
                />:null
            }
            { isLoading ? <PageLoader />: null }
        </div>
    )
}
export default withRouter(AccessoriesList);