import React, { Fragment, useState } from 'react';
import moment from 'moment';
import { browserHistory, Link, withRouter } from 'react-router';
import { useSnackbar } from 'notistack';
import NumericLabel from 'react-pretty-numbers';
import { CircularProgress, Chip, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField,IconButton } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { regexConstants } from '../../constants/regEx';
import { displayDateFormatShort } from '../../constants';
import { globalPostService } from '../../utils_v2/globalApiServices'
import { checkApiStatus, onCheckPermission } from '../../utils_v2'
import { globalCurrency } from '../budget_management';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import EditIcon from '@material-ui/icons/Edit';
import { budgetAccessGroup, checkBudgetPermission } from '../budget_management'
import { trackActivity } from '../../utils/mixpanel';
const ProjectBasicDetail = ({project, params, totalExpense, getResponseBack}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [modal, setModal] = useState(false);
    const [currencyObj, setCurrencyObj] = useState({currency_code:'', budget_amount:null});
    const [error, setError] = useState({});
    const onSubmit = () => {
        let validationInputs = {
            currency_code:currencyObj.currency_code ? '':'Please select Currency',
        }
        if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
            globalPostService(`technical/project/${params.project_slug}/budgets/updateCurrency/`, {currency_code :currencyObj.currency_code, budget_amount:currencyObj.budget_amount ? Number(currencyObj.budget_amount):null})
            .then(response => {
                if(checkApiStatus(response)){                
                    enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
                    setCurrencyObj({currency_code:'', budget_amount:null});
                    setModal(false);
                    getResponseBack()
                }else{
                    enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
                }
            });
        }else{
            setError(validationInputs);
        }
    }
    const backUrl = JSON.parse(localStorage.getItem('lisitng_url'))
    return(
        <div className='project-basic-detail'>
            <Grid container spacing={1} alignItems='center'>
                <Grid item md='6'>
                    <div className='project-back-info' style={{display:'flex', alignItems:"center"}}>
                        {/* <Link to='/technical/projects?project_status=all'> */}
                            <IconButton onClick={() => browserHistory.push(backUrl? backUrl : '/technical/projects?project_status=all')} color='primary' style={{ padding: "16px 4px 16px 0px"}}>
                                <ArrowBackIcon style={{fontSize:"1.25rem"}} />
                            </IconButton>
                        {/* </Link>     */}
                        <div>
                            <span style={{ fontSize: "11px", fontFamily: 'Conv_IBMPlexSans-Medium' }}>{"Project Number : " + project?.project_number}
                                <span style={{ marginLeft: '17px' }}>
                                    {moment(project.start_date).isValid()  ? moment(project.start_date).format(displayDateFormatShort) : 'TBD'}<span style={{ padding: '0 3px' }}>-</span>
                                    {moment(project.expected_end_date).isValid() ? moment(project.expected_end_date).format(displayDateFormatShort) : "TBD"}
                                </span>
                            </span>
                            <h4 className='flex-centered'>
                                <span>{project.name ? project.name : ''}
                                    {project.status && project.status.label ?
                                        <li className='list-inline-item'>
                                            <span style={{ marginLeft: '15px' }} className={`project-status ${project.archive_status === 1 ? 'archived' : project.status.label.toLowerCase()}`}>{project.archive_status === 1 ? 'Archived' : project.status.label}</span>
                                        </li> : null
                                    }
                                </span>
                            </h4>
                        </div>
                    </div>
                </Grid>
                <Grid item md='6'>
                    <ul className='list-inline flex-centered project-currency'>
                        <li className='list-inline-item'>
                            <Chip size="small" label={`Currency: ${project.currency?project.currency:'--'}`} />
                        </li>
                        { checkBudgetPermission(budgetAccessGroup) ?
                            <Fragment>
                                <li className='list-inline-item'>
                                    <Chip size="small" label={<Fragment>Budget Amount: <NumericLabel params={{justification:'L',precision:2,commafy:true}}>{project.budget_amount?project.budget_amount:0}</NumericLabel></Fragment>} />
                                </li>
                                { totalExpense ?
                                    <li className='list-inline-item'>
                                        <Chip size="small" label={<Fragment>Total Expense: <NumericLabel params={{justification:'L',precision:2,commafy:true}}>{totalExpense}</NumericLabel></Fragment>} />
                                    </li>:null
                                }
                                {
                                     onCheckPermission('expense_management', 'budget_allocation', 'C') ||    onCheckPermission('expense_management', 'budget_allocation', 'U') ?
                                     <li className='list-inline-item'>
                                    <EditIcon style={{cursor:'pointer'}} onClick={() => {setCurrencyObj({currency_code:project.currency, budget_amount:project.budget_amount}); setModal(true); trackActivity('Project Detail',{ event_type: 'Item Clicked',
                                        page_title: 'Project Detail Edit Icon',}) }} color='primary' fontSize='small' />
                                </li>
                                    : null}
                            </Fragment>:null
                        }
                    </ul>
                </Grid>
            </Grid>
            { modal ?
                <Fragment>
                    <Dialog
                        maxWidth="md"
                        open={modal}
                        onClose={() => {setCurrencyObj({currency_code:'', budget_amount:null}); setModal(false)}}
                        aria-labelledby="scroll-dialog-title"
                        className='project-management-modal'
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"Edit Project Currency"}
                        </DialogTitle>
                        <DialogContent dividers={true}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Autocomplete
                                        options = {globalCurrency}
                                        getOptionLabel={option => option.code}
                                        id="currency_code"
                                        value={currencyObj.currency_code ? globalCurrency.find(item => item.code === currencyObj.currency_code) : null}
                                        onChange={(e, value) => setCurrencyObj({...currencyObj, currency_code:value?value.code:value})}
                                        renderInput={params => <TextField required error={error.currency_code} helperText={error.currency_code} onFocus={() => setError({...error, currency_code:''})} {...params} label="Currency" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        id="budget_amount"
                                        label="Amount"
                                        fullWidth
                                        margin="normal"
                                        value={currencyObj.budget_amount ? currencyObj.budget_amount : null}
                                        InputLabelProps={{shrink: true}}
                                        inputProps={{maxLength: 10}}
                                        onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? setCurrencyObj({...currencyObj, budget_amount:e.target.value}) : e.preventDefault()}}
                                        error={error.currencyObj}
                                        helperText={error.currencyObj}
                                        onFocus={() => setError({...error, currencyObj:''})}
                                        variant='outlined'
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => {setCurrencyObj({currency_code:project.currency, budget_amount:project.budget_amount}); setModal(false);setError({})}} color="primary" size='small' variant='outlined'>
                                Cancel
                            </Button>
                            <Button onClick={onSubmit} color="primary" size='small' variant='contained'>
                                Submit
                            </Button>
                        </DialogActions>
                    </Dialog>  
                </Fragment>:null
            }    
        </div>
    )
}
export default withRouter(ProjectBasicDetail);