import * as actions from '../actions';
const crud = {
  flag: false,
  data: {},
  type: ''
}
export default function( state = {
  tableInspectionDetails: {},
  toggleSvFlag: false,
  disAssemblyCrud: crud,
  tblInpLoader: false,
  findngImgCrud: crud,
  svErr: {}
}, action ){
  switch (action.type) {
    case actions.TABLE_INSPECTION_DETAILS:
      return{
        ...state,
        tableInspectionDetails: action.payload
      }
    case actions.UPDATE_GEN_IP_FIELDS:
      return{
        ...state,
        tableInspectionDetails : {
          ...state.tableInspectionDetails,
          general_details: {
            ...state.tableInspectionDetails.general_details,
            [action.payload.type]: action.payload.value
          }
        }
      }

    case actions.UPDATE_SV_IP_FIELDS:
      return{
        ...state,
        tableInspectionDetails : {
          ...state.tableInspectionDetails,
          shop_visit: {
            ...state.tableInspectionDetails.shop_visit,
            [action.payload.type]: action.payload.value
          }
        },
        svErr: {
          ...state.svErr,
          [action.payload.type]: ''
        }
      }

    case actions.LLP_IP_UPDATE:
    return {
      ...state,
      tableInspectionDetails: {
        ...state.tableInspectionDetails,
        llp_details: [...state.tableInspectionDetails.llp_details].map((llpDetail, llpIndex) =>  llpIndex == action.payload.index ? {
          ...llpDetail,
          [action.payload.type]: action.payload.value
        } : llpDetail)
      }
    }
    case actions.UPDATE_SV_DETAIL_UPDATE:

    return{
      ...state,
      tableInspectionDetails : {
        ...state.tableInspectionDetails,
        shop_visit_details: {
          ...state.tableInspectionDetails.shop_visit_details,
          [action.payload.type]: action.payload.value
        }
      }
    }
   case actions.SV_UPLOAD_FILE:
   return{
    ...state,
    tableInspectionDetails : {
      ...state.tableInspectionDetails,
      shop_visit: {
        ...state.tableInspectionDetails.shop_visit,
        attachments : state.tableInspectionDetails.shop_visit && state.tableInspectionDetails.shop_visit.attachments && state.tableInspectionDetails.shop_visit.attachments.length ? [action.payload, ...state.tableInspectionDetails.shop_visit.attachments] : [action.payload]
      }
    },


  }
  case actions.UPDATE_SV_DESC:
  return{
    ...state,
    tableInspectionDetails : {
      ...state.tableInspectionDetails,
      shop_visit: {
        ...state.tableInspectionDetails.shop_visit,
        attachments : [...state.tableInspectionDetails.shop_visit.attachments].map((attachment, index) => index == action.payload.index ?  {
          ...attachment,
          [action.payload.type] : action.payload.value
        }: attachment
        )
      }
    }
  }
  case actions.ADD_LLP_DETAILS:
  return {
    ...state,
    tableInspectionDetails: {
      ...state.tableInspectionDetails,
      llp_details: [...state.tableInspectionDetails.llp_details,action.payload]
    }
  }
  case actions.DELETE_SV_ATTACHMENT:

  return{
    ...state,
    tableInspectionDetails : {
      ...state.tableInspectionDetails,
      shop_visit: {
        ...state.tableInspectionDetails.shop_visit,
        attachments : [...state.tableInspectionDetails.shop_visit.attachments].filter((attachment, index) => index != action.payload )
      }
    }
  }
  case actions.TOGGLE_SV:
    return{
      ...state,
      toggleSvFlag: action.payload
    }
  case actions.LLP_DELETE:
  return {
    ...state,
    tableInspectionDetails: {
      ...state.tableInspectionDetails,
      llp_details: [...state.tableInspectionDetails.llp_details].filter((llpDet, index) => index != action.payload)
    }
  }
  case actions.DISS_ASSEM_SIDEBAR:
   return {
     ...state,
     disAssemblyCrud: action.payload
   }

  case actions.SORT_PICTURES_TE005:
      return{
        ...state,
        disAssemblyCrud: {
          ...state.disAssemblyCrud,
          data: {
            ...state.disAssemblyCrud.data,
            finding_pictures : action.payload.map((inspDetail, index)  =>   index >= 0 ? {
            ...inspDetail,
            order : index+1
          } : inspDetail )
        }
        }
      }
  case actions.UPDATE_DISS_ASSEM_FIELD:
  if(action.payload.sectionType == "dis_finding"){
    return {
      ...state,
      disAssemblyCrud : {
        ...state.disAssemblyCrud,
        data: {
          ...state.disAssemblyCrud.data,
          finding_pictures: [...state.disAssemblyCrud.data.finding_pictures, action.payload]
        }
      }
    };
  }
  else if(action.payload.sectionType == "findingPicture"){
      return {
        ...state,
        disAssemblyCrud : {
          ...state.disAssemblyCrud,
          data: {
            ...state.disAssemblyCrud.data,
            finding_pictures: [...state.disAssemblyCrud.data.finding_pictures].map((findingPic, index) => index == action.payload.index ? {
              ...findingPic,
              [action.payload.type]: action.payload.value
            }: findingPic)
          }
        }
      };
    }else{
      return {
        ...state,
        disAssemblyCrud : {
          ...state.disAssemblyCrud,
          data: {
            ...state.disAssemblyCrud.data,
            [action.payload.type]: action.payload.value
          }
        }
      };
    }
  case actions.ADD_SV_WORKSCOPE_ITEM:
  const item = {
    moduleNo: '',
    moduleDescription: '',
    initialLevel: '',
    finalLevel:''
  }

  return{
    ...state,
    tableInspectionDetails : {
      ...state.tableInspectionDetails,
      general_details: {
        ...state.tableInspectionDetails.general_details,
        workscope_modules: [...state.tableInspectionDetails.general_details.workscope_modules].map((majorMod,index) =>
        index == action.payload.index ?
        { ...majorMod, subModule: majorMod.subModule.map((sb, childIndex) =>
          childIndex == action.payload.childIndex ? {...sb, items: [...sb.items, item]} :sb
        )}:
        majorMod
      )}
    }
  }

  case actions.ADD_SV_WORKSCOPE_SUBMODULE:
  const subModule = {
    name:'',
    items:[
      {
        moduleNo: '',
        moduleDescription: '',
        initialLevel: '',
        finalLevel:''
      }
    ]
  }

  return{
    ...state,
    tableInspectionDetails : {
      ...state.tableInspectionDetails,
      general_details: {
        ...state.tableInspectionDetails.general_details,
        workscope_modules: [...state.tableInspectionDetails.general_details.workscope_modules].map((majorMod,index) =>
        index == action.payload.index ?
        { ...majorMod, subModule: [...majorMod.subModule, subModule] } :
        majorMod
        )
      }
    }
  }

  case actions.ADD_SV_WORKSCOPE_MODULE:
    const majorModule = {
      name: '',
      subModule: [
        {
          name:'',
          items:[
            {
              moduleNo: '',
              moduleDescription: '',
              initialLevel: '',
              finalLevel:''
            }
          ]
        }
      ]
    }

    return{
      ...state,
      tableInspectionDetails : {
        ...state.tableInspectionDetails,
        general_details: {
          ...state.tableInspectionDetails.general_details,
          workscope_modules: [...state.tableInspectionDetails.general_details.workscope_modules, majorModule]
        }
      }
    }

  case actions.DEL_SV_WORKSCOPE_ITEM:
    const workscope_modules = Object.assign([], state.tableInspectionDetails.general_details.workscope_modules);
    if(workscope_modules[action.payload.index].subModule.length==1 && workscope_modules[action.payload.index].subModule[action.payload.childIndex].items.length==1){
      workscope_modules.splice(action.payload.index,1);
      return{
        ...state,
        tableInspectionDetails : {
          ...state.tableInspectionDetails,
          general_details: {
            ...state.tableInspectionDetails.general_details,
            workscope_modules: workscope_modules
          }
        }
      }
    }
    if(workscope_modules[action.payload.index].subModule[action.payload.childIndex].items.length==1){
      workscope_modules[action.payload.index].subModule.splice(action.payload.childIndex,1);
      return{
        ...state,
        tableInspectionDetails : {
          ...state.tableInspectionDetails,
          general_details: {
            ...state.tableInspectionDetails.general_details,
            workscope_modules: workscope_modules
          }
        }
      }
    }

    return{
      ...state,
      tableInspectionDetails : {
        ...state.tableInspectionDetails,
        general_details: {
          ...state.tableInspectionDetails.general_details,
          workscope_modules: [...state.tableInspectionDetails.general_details.workscope_modules].map((majorMod,index) =>
          index == action.payload.index ?
          { ...majorMod, subModule: majorMod.subModule.map((sb, childIndex) => {
            var subLength=[...sb.items];
            return childIndex == action.payload.childIndex ? {...sb, ...sb.items.splice(action.payload.itemIndex,1)} :sb;
          })}:
          majorMod
        )}
      }
    }

  case actions.UPDATE_SV_WORK_SCOPE:
    switch (action.payload.type) {
      case "majorModule":
        return{
          ...state,
          tableInspectionDetails : {
            ...state.tableInspectionDetails,
            general_details: {
              ...state.tableInspectionDetails.general_details,
              workscope_modules: [...state.tableInspectionDetails.general_details.workscope_modules].map((majorMod,index) => index == action.payload.index ? {...majorMod,[action.payload.key]:action.payload.value}:majorMod)}
          }
        }

      case "childModule":
        return{
          ...state,
          tableInspectionDetails : {
            ...state.tableInspectionDetails,
            general_details: {
              ...state.tableInspectionDetails.general_details,
              workscope_modules: [...state.tableInspectionDetails.general_details.workscope_modules].map((majorMod,index) => index == action.payload.index ?
              {
                ...majorMod,
                subModule:majorMod.subModule.map((childModule,childIndex) => childIndex==action.payload.childIndex?
                {
                  ...childModule,
                  [action.payload.key]:action.payload.value
                }:childModule)}
                :majorMod)
          }
        }
      }

      case "itemModule":
        return{
          ...state,
          tableInspectionDetails : {
            ...state.tableInspectionDetails,
            general_details: {
              ...state.tableInspectionDetails.general_details,
              workscope_modules: [...state.tableInspectionDetails.general_details.workscope_modules].map((majorMod,index) => index==action.payload.index?
              {...majorMod,
                subModule:majorMod.subModule.map((childModule,childIndex) => childIndex==action.payload.childIndex ?
                { ...childModule,
                  items:childModule.items.map((itemData,itemIndex) => itemIndex==action.payload.itemIndex?
                  {...itemData,[action.payload.key]:action.payload.value
                  }:itemData)
                }:childModule)
              }:majorMod)
          }
        }
      }
      default:
      return   state
    }
    case actions.ADD_DISS_ASSEM_DATA:
      return {
        ...state,
        tableInspectionDetails: {
          ...state.tableInspectionDetails,
          disassembly_findings: [...state.tableInspectionDetails.disassembly_findings, action.payload]
        },
        disAssemblyCrud: crud
      }
    case actions.VIEW_DISS_ASSEM_DATA:
      return {
        ...state,
        disAssemblyCrud: action.payload
      }
    case actions.UPDATE_DISS_ASSEM_DATA:
    return {
      ...state,
      tableInspectionDetails: {
        ...state.tableInspectionDetails,
        disassembly_findings: [...state.tableInspectionDetails.disassembly_findings].map(findingData =>  findingData.id == action.payload.id ?
         action.payload : findingData)
      },
      disAssemblyCrud: crud
    }
   case actions.DIS_ASSEM_DELETE:
    return {
      ...state,
      tableInspectionDetails: {
        ...state.tableInspectionDetails,
        disassembly_findings: [...state.tableInspectionDetails.disassembly_findings].filter((disAssItem, index) =>  index != action.payload )
      },
    }
   case actions.TI_LOADER:
    return {
      ...state,
      tblInpLoader: action.payload
    }
   case actions.EDIT_DA_IMAGE:
    return {
      ...state,
      findngImgCrud: action.payload
    }
   case actions.SAVE_EDITED_DA_IMG:

   return {
    ...state,
    disAssemblyCrud : {
      ...state.disAssemblyCrud,
      data: {
        ...state.disAssemblyCrud.data,
        finding_pictures: [...state.disAssemblyCrud.data.finding_pictures].map((findingPic, index) => index == action.payload.index ? {
          ...findingPic,
          image: action.payload.image
        }: findingPic)
      }
    }
  }

   case actions.DELETE_DA_IMAGE:

   return {
    ...state,
    disAssemblyCrud: {
      ...state.disAssemblyCrud,
      data: {
        ...state.disAssemblyCrud.data,
        finding_pictures : [...state.disAssemblyCrud.data.finding_pictures].filter((findingData, index) => index != action.payload)

      }
    }
  }
  case actions.UPDATE_SV_ERR:
    return {
      ...state,
      svErr: action.payload
    }
    default:
      return state;
  }
}
