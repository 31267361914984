import * as actions from '../actions';
import { globalGetService, globalPutService, globalPostService, globalDeleteService, globalFileUploadService } from '../../../../../globalServices/';
import { toastFlashMessage } from '../../../../../utils';
import { getFormDetailsAc } from '../../T001/actionCreators';
import { trackActivity } from '../../../../../utils/mixpanel';
const lopaData = {
	"lopa_remarks": "",
	"lopa_image_url": ""
}
export const addEditConfigAc = (props, data, type, flag) => {
	return (dispatch) => {
		dispatch(triggerLoader(true));
		globalPostService('/technical/workorder/' + props.params.workOrderSlug + '/interior-inspection-config/', data)
			.then(response => {
				dispatch(triggerLoader(false));
				if (response.data.statusCode == 200) {
					dispatch({
						type: actions.UPDATE_CONFIG,
						payload: { data: response.data.data, flag: flag, type: type }
					});
				}
				toastFlashMessage(response.data.message, 'success');
				trackActivity('T008', {
					event_type: 'Seat Configuration Setup',
					page_title: 'Window Seat Mapping',
					workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
					form_slug: props.params.formSlug ? props.params.formSlug : '',
					seat_type: data[0].seat_type ? data[0].seat_type : '',
					item_id: data[0].id ? data[0].id : '',
					starting_row: data[0].starting_row ? data[0].starting_row : '',
					end_row: data[0].end_row ? data[0].end_row : '',
					asset_type: props.params.type ? props.params.type : '',
					aircraft_slug: props.params.aircraft_slug ? props.params.aircraft_slug : '',
				})
				dispatch(triggerLoader(false));
				dispatch(getFormDetailsAc(props));
			});
	}
}

// export const configureViewCrudAc = (props, data) => {

// 	return (dispatch) => {
// 	if(data.type == 'add'){
// 		dispatch({
// 			type: actions.ADD_CONFIG,
// 			payload: data
// 		});
// 	}else{
// 		dispatch(triggerLoader(true));
// 		globalGetService('/technical/workorder/'+props.params.workOrderSlug+'/interior-inspection-config/', {})
// 		.then(response => {
// 			dispatch(triggerLoader(false));
// 			if(response.data.statusCode == 200){
// 				dispatch({
// 					type: actions.ADD_CONFIG,
// 					payload: {data: response.data.data, type: data.type, flag: true}
// 				});
// 			}
// 			// dispatch(triggerLoader(false));
// 		});
// 		}
// 	}
// }

export const getSeatingArrangementAc = (props) => {
	return (dispatch) => {
		dispatch(triggerLoader(true));
		globalGetService('/technical/workorder/' + props.params.workOrderSlug + '/interior-inspection-config/', {})
			.then(response => {
				dispatch(triggerLoader(false));
				if (response.data.statusCode == 200) {
					let tempData = response.data.data
					if (Object.keys(response.data.data).length && response.data.data.interior_config_details && response.data.data.interior_config_details.length) {
						tempData = {
							...tempData,
							interior_config_details: tempData.interior_config_details.map((data, interiorIndex) =>
								data
								// {
								// 	data = {...data, columns: data.columns.reverse().map((colItem, index) => colItem.reverse().map((col, colIndex)=> col) )}
								// 	return data
								// }
							)
						}
						setTimeout(() => dispatch({
							type: actions.GET_CONFIG,
							payload: tempData
						}), 1000)
					}
				}
				// dispatch(triggerLoader(false));
			});
	}
}

export const configureListAc = (props, data) => {
	return (dispatch) => {
		if (data.type == 'add') {
			dispatch({
				type: actions.ADD_CONFIG,
				payload: data
			});
		} else {
			dispatch(triggerLoader(true));
			globalGetService('/technical/workorder/' + props.params.workOrderSlug + '/interior-inspection-config/', {})
				.then(response => {
					dispatch(triggerLoader(false));
					if (response.data.statusCode == 200) {
						dispatch({
							type: actions.ADD_CONFIG,
							payload: response.data.data
						});
					}
					// dispatch(triggerLoader(false));
				});
		}
	}
}

export const addEditLopaAc = (props, data) => {
	return (dispatch) => {
		if (data.type == 'add') {
			dispatch({
				type: actions.TOGGLE_LOPA_SIDEBAR,
				payload: { data: lopaData, flag: data.flag, type: 'add' }
			})
		} else {
			dispatch(triggerLoader(true));
			globalGetService('/technical/workorder/' + props.params.workOrderSlug + '/inspection-lopa-image/', {})
				.then(response => {
					dispatch(triggerLoader(false));
					if (response.data.statusCode == 200) {
						dispatch({
							type: actions.TOGGLE_LOPA_SIDEBAR,
							payload: { data: response.data.data, type: data.type, flag: true }
						});
						trackActivity('T008', {
							event_type: 'Opened Lopa View/Edit',
							page_title: 'Window Seat Mapping',
							workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
							form_slug: props.params.formSlug ? props.params.formSlug : '',
							asset_type: props.params.type ? props.params.type : '',
							aircraft_slug: props.params.aircraft_slug ? props.params.aircraft_slug : '',
							type: data.type ? data.type : '',
							request_body: data ? data : ''
						})
					}
					// dispatch(triggerLoader(false));
				});
		}
	}
}

export const updateLopaAc = (props, data, file) => {
	var formData = new FormData();
	formData.append('remarks', data.lopa_remarks);
	formData.append('file', file[0]);
	var config = {
		onUploadProgress: function (progressEvent) {
			var percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
			// dispatch({
			//   type: actions.PROGRESS_PERCENTAGE,
			//   payload: percentage
			// });
		}
	}
	return (dispatch) => {
		dispatch(triggerLoader(true));
		globalFileUploadService('/technical/workorder/' + props.params.workOrderSlug + '/inspection-lopa-image/', formData, config)
			.then(response => {
				dispatch(triggerLoader(false));
				if (response.data.statusCode == 200) {
					dispatch({
						type: actions.TOGGLE_LOPA_SIDEBAR,
						payload: { data: '', flag: false, type: '' }
					})
					toastFlashMessage(response.data.message, 'success');
					dispatch(getSeatingArrangementAc(props))
					dispatch(getFormDetailsAc(props));
					trackActivity('T008', {
						event_type: 'Lopa Updated',
						page_title: 'Window Seat Mapping',
						workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
						form_slug: props.params.formSlug ? props.params.formSlug : '',
						asset_type: props.params.type ? props.params.type : '',
						aircraft_slug: props.params.aircraft_slug ? props.params.aircraft_slug : '',
						file_names: file ? file : '',
						request_body: formData ? formData : ''
					})
				}
			})
	}
}

export const toggleEditAddTechSpecAc = (props, data) => {
	return (dispatch) => {
		if (data.type == 'add') {
			dispatch({
				type: actions.TOGGLE_LOPA_SIDEBAR,
				payload: data
			})
		} else {

		}
	}
}

export const toggleEditAddDetailAc = (props, data, seatwindowArr) => {
	return (dispatch) => {
		if (data.type == 'add') {
			dispatch({
				type: actions.TOGGLE_DETAIL_SIDEBAR,
				payload: { flag: false, data: [], type: 'add' }
			})
		} else {
			dispatch(triggerLoader(true));
			if (data.itemtype == 'seat') {
				globalGetService('technical/interior/seat-finding-questions/', {})
					.then(response => {
						dispatch(triggerLoader(false));
						if (response.data.statusCode == 200) {
							dispatch({ type: actions.TOGGLE_DETAIL_SIDEBAR, payload: { flag: true, data: response.data.data, type: 'edit' } })
							dispatch(getSeatFindingAc(props, seatwindowArr))
						}
					})
			} else {
				globalGetService('technical/interior/window-finding-questions/', {})
					.then(response => {
						dispatch(triggerLoader(false));
						if (response.data.statusCode == 200) {
							dispatch({
								type: actions.TOGGLE_DETAIL_SIDEBAR,
								payload: { flag: true, data: response.data.data, type: 'edit' }
							})
							dispatch(getWindowFindingAc(props, seatwindowArr))
						}
					})
			}
		}
	}
}

export const getSeatFindingAc = (props, data) => {
	return (dispatch) => {
		dispatch(triggerLoader(true));
		globalPostService('technical/workorder/' + props.params.workOrderSlug + '/get-seat-findings/', { seats: data })
			.then(response => {
				dispatch(triggerLoader(false));
				if (response.data.statusCode == 200) {
					dispatch({
						type: actions.FINDING_ARR_UPDATE_AC,
						payload: response.data.data
					})
				}
				dispatch(getFormDetailsAc(props));
			})
	}
}

export const getWindowFindingAc = (props, data) => {
	return (dispatch) => {
		dispatch(triggerLoader(true));
		globalPostService('technical/workorder/' + props.params.workOrderSlug + '/get-window-findings/', { windows: data })
			.then(response => {
				dispatch(triggerLoader(false));
				if (response.data.statusCode == 200) {
					dispatch({
						type: actions.FINDING_ARR_UPDATE_AC,
						payload: response.data.data
					})
				}
				dispatch(getFormDetailsAc(props));
			})
	}
}

export const toggleTechSpecSideBrAc = (props, data) => {
	return (dispatch) => {
		if (data.type == 'add') {
			dispatch({
				type: actions.TOGGLE_TECH_SIDEBAR,
				payload: data
			})
		} else {
			dispatch(triggerLoader(true));
			globalGetService('technical/workorder/' + props.params.workOrderSlug + '/interior-technical-specs/', {})
				.then(response => {
					dispatch(triggerLoader(false));
					if (response.data.statusCode == 200) {
						dispatch({
							type: actions.TOGGLE_TECH_SIDEBAR,
							payload: { flag: true, data: response.data.data, type: 'edit' }
						})
					}
				})
		}
	}
}

export const updateTechSpecAc = (props, data) => {
	console.log('data', data);
	return (dispatch) => {
		dispatch(triggerLoader(true));
		globalPostService('technical/workorder/' + props.params.workOrderSlug + '/interior-technical-specs/', data)
			.then(response => {
				dispatch(triggerLoader(false));
				if (response.data.statusCode == 200) {
					dispatch({
						type: actions.UPDATE_TECH_SIDEBAR,
						payload: { flag: false, data: response.data.data, type: 'edit' }
					})
					toastFlashMessage(response.data.message, 'success');
					trackActivity('T008', {
						event_type: 'Technical Specs Updated',
						page_title: 'Window Seat Mapping',
						workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
						form_slug: props.params.formSlug ? props.params.formSlug : '',
						asset_type: props.params.type ? props.params.type : '',
						aircraft_slug: props.params.aircraft_slug ? props.params.aircraft_slug : '',
						request_body: data ? data : ''
					})
				}
				dispatch(getFormDetailsAc(props));
			})
	}
}

export const seatFindingUpdateAc = (props, data) => {
	return (dispatch) => {
		dispatch(triggerLoader(true));
		globalPostService('technical/workorder/' + props.params.workOrderSlug + '/add-seat-findings/', data)
			.then(response => {
				dispatch(triggerLoader(false));
				if (response.data.statusCode == 200) {
					dispatch({
						type: actions.TOGGLE_DETAIL_SIDEBAR,
						payload: { flag: false, data: response.data.data, type: 'edit' }
					})
					toastFlashMessage(response.data.message, 'success');
					dispatch({ type: actions.FINDING_ARR_UPDATE_AC, payload: { findings: [], status: 1 } })
					dispatch({
						type: actions.SEAT_ARR_UPDATE_AC,
						payload: []
					})
					dispatch(getFindingsAc(props));
					trackActivity('T008', {
						event_type: 'Seat Findings Updated',
						page_title: 'Window Seat Mapping',
						workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
						form_slug: props.params.formSlug ? props.params.formSlug : '',
						asset_type: props.params.type ? props.params.type : '',
						aircraft_slug: props.params.aircraft_slug ? props.params.aircraft_slug : '',
						request_body: data ? data : ''
					})
				}
				dispatch(getFormDetailsAc(props));
			})
	}
}

export const windowFindingUpdateAc = (props, data) => {
	return (dispatch) => {
		dispatch(triggerLoader(true));
		globalPostService('technical/workorder/' + props.params.workOrderSlug + '/add-window-findings/', data)
			.then(response => {
				dispatch(triggerLoader(false));
				if (response.data.statusCode == 200) {
					dispatch({
						type: actions.TOGGLE_DETAIL_SIDEBAR,
						payload: { flag: false, data: response.data.data, type: 'edit' }
					})
					toastFlashMessage(response.data.message, 'success');
					trackActivity('T008', {
						event_type: 'Window Findings Updated',
						page_title: 'Window Seat Mapping',
						workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
						form_slug: props.params.formSlug ? props.params.formSlug : '',
						asset_type: props.params.type ? props.params.type : '',
						aircraft_slug: props.params.aircraft_slug ? props.params.aircraft_slug : '',
						request_body: data ? data : ''
					})
					dispatch({
						type: actions.FINDING_ARR_UPDATE_AC,
						payload: { findings: [], status: 1 }
					})
					dispatch({
						type: actions.WINDOW_ARR_UPDATE_AC,
						payload: []
					})
					dispatch(getFindingsAc(props));
				}
				dispatch(getFormDetailsAc(props));
			})
	}
}

export const getFindingsAc = (props) => {
	return (dispatch) => {
		globalGetService('/technical/workorder/' + props.params.workOrderSlug + '/seat-window-inspection-detail/', {})
			.then(response => {
				if (response.data.statusCode == 200) {
					dispatch({
						type: actions.ALL_EXISTING_FINDINGS,
						payload: response.data.data
					});

				}
				// dispatch(triggerLoader(false));
			});
	}
}

export const diableSeatAc = (props, data) => {
	return (dispatch) => {
		dispatch(triggerLoader(true));
		globalPostService('technical/workorder/' + props.params.workOrderSlug + '/change-seat-status/', data)
			.then(response => {
				dispatch(triggerLoader(false));
				if (response.data.statusCode == 200) {
					dispatch({
						type: actions.TOGGLE_DETAIL_SIDEBAR,
						payload: { flag: false, data: [], type: 'add' }
					})
					toastFlashMessage(response.data.message, 'success');
					dispatch({
						type: actions.SEAT_ARR_UPDATE_AC,
						payload: []
					})
					dispatch(getFindingsAc(props));
					trackActivity('T008', {
						event_type: 'Seat Status Updated',
						page_title: 'Window Seat Mapping',
						workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
						form_slug: props.params.formSlug ? props.params.formSlug : '',
						asset_type: props.params.type ? props.params.type : '',
						aircraft_slug: props.params.aircraft_slug ? props.params.aircraft_slug : '',
						request_body: data ? data : ''
					})
				}
				dispatch(getFormDetailsAc(props));
			})
	}
}

export const deleteConfigAc = (props, interiorInsId) => {
	return (dispatch) => {
		dispatch(triggerLoader(true));
		globalDeleteService('/technical/workorder/' + props.params.workOrderSlug + '/interior-section/' + interiorInsId + '/delete-interior-section/', {})
			.then(response => {
				dispatch(triggerLoader(false));
				if (response.data.statusCode == 200) {
					// dispatch({
					// 	type: actions.UPDATE_CONFIG,
					// 	payload: {data: response.data.data, flag: flag, type: type}
					// });
				}
				toastFlashMessage(response.data.message, 'success');
				dispatch(getSeatingArrangementAc(props))
				dispatch(triggerLoader(false));
				dispatch(getFormDetailsAc(props));
				trackActivity('T008', {
					event_type: 'Config Deleted',
					page_title: 'Window Seat Mapping',
					workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
					form_slug: props.params.formSlug ? props.params.formSlug : '',
					asset_type: props.params.type ? props.params.type : '',
					aircraft_slug: props.params.aircraft_slug ? props.params.aircraft_slug : '',
					interior_ins_id: interiorInsId ? interiorInsId : ''
				})
			});
	}
}

export const triggerLoader = (flag) => {
	return (dispatch) => {
		dispatch({
			type: actions.PAX_SEATING_LOADER,
			payload: flag
		});
	}
}
