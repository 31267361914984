import React, { Fragment } from 'react';
import { Link, browserHistory, withRouter } from 'react-router';
import {ClickAwayListener,Grow, Paper, Popper, MenuItem, MenuList} from '@material-ui/core/';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
const BulkOpsMenu = ({params, label, labelKey, valueKey, prsBulkFieldUpdate, options}) => {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event, item) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
        anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);
    return (
        <div>
            <span
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                className="blk-ops-menu flex-centered"
                style={{cursor:'pointer'}}
            >
                {label} <ArrowDropDownIcon fontSize='small' />
            </span>
            <Popper open={open} anchorOrigin="top" anchorPosition="top" elevation={12} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                    <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                        <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                            <div style={{maxHeight:'280px', overflow:'auto'}}>
                                { options.map((item, index) => 
                                    <MenuItem key={index} onClick={(event) => {handleClose(event,item); prsBulkFieldUpdate(item[valueKey])}}>
                                        <p style={{fontSize:'11px'}}>
                                            {item[labelKey]}
                                        </p>
                                    </MenuItem>
                                )}
                            </div>
                        </MenuList>
                    </ClickAwayListener>
                    </Paper>
                </Grow>
                )}
            </Popper>
        </div>
    );
}
export default withRouter(BulkOpsMenu)