import React, { Component } from 'react';
import { Col, Row } from "reactstrap";
import moment from "moment";
import {imgStoragePath} from "../../../../../constants";
import { localTimeFn } from '../../../../../utils'
import { ContextMenuProvider, Separator } from 'react-contexify';

export default class IssueList extends Component {
    constructor(props) {
      super(props);
      this.toggle = this.toggle.bind(this);

    }

    toggle=(e)=> {
      e.stopPropagation();
      e.preventDefault();
    }


    render() {
    
    return (
        <li key={this.props.index} onClick={this.props.issueDetail} className={`issue_${this.props.issue.order_number}`}>
            <Row>
              {
                this.props.issue.status == 1 ?
                <Col md="12">
                  <span className="issued-date" style={{color: '#7ed321'}}>Resolved on  {this.props.issue.action_date ? `${localTimeFn(this.props.issue.action_date)}` : null} UTC
                    {
                      this.props.issue.action_performed_by && this.props.issue.action_performed_by.name ?
                      ` by ${this.props.issue.action_performed_by.name}`
                      :''
                    }
                  </span>
                </Col>
                :null
              }
                <Col md="2" className="text-center">
                    <span className="issue-order-no" style={this.props.issue.status == 1 ? {background: '#7ed321'} : {}}>
                      <b>#{this.props.issue.order_number}</b>
                    </span>
                </Col>
                <Col md="10">
                    <h3>{this.props.issue.user? this.props.issue.user.name : null}</h3>
                    <span className="issued-date">Posted on  {this.props.issue.created_at ? `${localTimeFn(this.props.issue.created_at)}` : null} UTC</span>
                    <p>{this.props.issue.description? this.props.issue.description : null}</p>
                    { (this.props.issue.status == 0) ?
                       <span>
                        <a href="javascript:void(0)" className="resolve-issue" onClick={(e) => this.props.changeStatus(e, this.props.issue, 1)}>RESOLVE</a>
                       {/* <ContextMenuProvider id={'issues_more_actions_'+this.props.issue.order_number} event="onClick">
                        <i onContextMenu={(e)=>this.toggle(e)} className="fa fa-ellipsis-v"style={{position:'absolute',top:'9px',right:'2px'}} aria-hidden="true"></i></ContextMenuProvider>*/}
                        </span>:
                        <span className="resolved-issue">
                            <img src={imgStoragePath+'resolved.svg'} alt="" />
                        </span>
                    }
                     { (this.props.issue.status == 0) ?<span style={{float:'right'}}><a href="javascript:void(0)" style={{color:'#3f51b5'}} onClick={(e) => this.props.editStatus(e, this.props.issue, 1)}>Edit </a><a href="javascript:void(0)" style={{marginLeft:'5px',color:'#dc3545'}} onClick={(e) => this.props.deleteStatus(e, this.props.issue, 1)}>Delete</a></span>:null}
                </Col>
            </Row>
        </li>

    )
 }
}
