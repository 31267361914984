import React from 'react'
import { imgStoragePath } from '../../../../../constants';
import { ToolTipHover } from '../../../../../shared';
import editIcon from '../../../../../shared/assets/img/edit_icon.svg';
import viewIcon from '../../../../../shared/assets/img/view_icon.svg';

export default function({question, changeRecordReviews, changeFormIntroduction, questionIndex, formInfo}){
  return(
    <li>
      <p style={{marginRight:'40px'}}>
      {question.title}
      <img  className="info-icon"  id={'formInfo'+questionIndex} src={imgStoragePath+"inv_info_icon.png"} alt="img" width="12"/>
     </p> <img className="edit-img"
        onClick={() =>  {question.show_new_model == 1 ? changeRecordReviews('recordReviews',  question, questionIndex) : changeFormIntroduction('', question, questionIndex)}}
        src={ formInfo.current_form?.permissions?.can_save ?editIcon: viewIcon} alt="img" width="12"
      />
      <ToolTipHover placement="right" tagetId={'formInfo'+questionIndex}>
        Please maintain format to preserve text formatting in reports
      </ToolTipHover>
    </li>
  )
}
