import { checkApiStatus } from "../../utils_v2"
import { globalGetService, globalPostService } from "../../utils_v2/globalApiServices"
export const getProjectDetailApi = (params, query={}) => {
  return new Promise(function(resolve, reject){
    globalGetService(`technical/project/${params.project_slug}/`, query).then((response) => {
      if (checkApiStatus(response)) {
        resolve(response)
      }
    })
  })
}

export const getAllStagesApi = (params, query={}) => { 
  return new Promise(function(resolve, reject){
    globalGetService(`technical/project/${params.project_slug}/stages/`, query).then((response) => {
      if (checkApiStatus(response)) {
        resolve(response)
      }
    })
  })
}


export const getProjectWorkOrdersApi = (params, query={}) => {
  return new Promise(function(resolve, reject){
    globalGetService(`technical/project/${params.project_slug}/workorders/`, query).then((response) => {
      if (checkApiStatus(response)) {
        resolve(response)
      }
    })
  })
}



export const getPlatformConstantsApi = (data) => {
  return new Promise(function(resolve, reject) {
    globalPostService(`console/get-constants/`, data).then((response) => {
      if (checkApiStatus(response)) {
        resolve(response)
      }
    })
  })
}


export const getWorkOrderFormsApi = (params, query={}) => {
  return new Promise(function(resolve, reject){
    globalGetService(`technical/project/${params.project_slug}/workorders/forms/`, query).then((response) => {
      if (checkApiStatus(response)) {
        resolve(response)
      }
    })
  })
}

export const getAllFormsApi = () => {
  return new Promise(function(resolve, reject) {
    globalGetService(`technical/forms/?both=true`).then((response) => {
      if (checkApiStatus(response)) {
        resolve(response)
      }
    })
  })
}

export const getAllMilestoneApi = (params, query = {}) => {
  return new Promise(function(resolve, reject) {
    globalGetService( `technical/project/${params.project_slug}/milestones/`, query )
    .then((response) => {
      if (checkApiStatus(response)) {
        resolve(response)
      }
    })
  })
}

