import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { useSnackbar } from 'notistack';
import { Paper, TextField, Grid, Button, IconButton, Tooltip } from '@material-ui/core';
import { ReadPrerequisite } from '../../Elements'
import PrerequisiteContent from './PrerequisiteContent'
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import EditIcon from '@material-ui/icons/Edit';
import { fieldDateFormat, backendDateFormat } from '../../../constants';
import { globalGetService, globalPutService } from '../../../utils_v2/globalApiServices';
import { checkApiStatus } from '../../../utils_v2';
import { PageLoader } from '../../../shared_elements';
const GeneralDetail = ({currentFormDetail, params, getCurrentFormDetail}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setLoading] = useState(false);
  const [isEditAble, setEditAble] = useState(false);
  const [generalDetail, setGeneralDetail] = useState({});
  const [error, setError] = useState({});
  useEffect(() => {
    getGeneralDetails();
  }, []);
  const getGeneralDetails = () => {
    globalGetService(`technical/v2/workorder/${params.workOrderSlug}/powerplant-general-info/`)
    .then(response => {
      if(checkApiStatus(response)){
        setGeneralDetail(response.data.data);
      }
    })
  }
  const onUpdateGeneralDetails = () => {
    let validationInputs = {
      place_of_inspection:generalDetail?.place_of_inspection?.trim()?.length ? '':'Please enter Place of Inspection',
      date_of_inspection:generalDetail?.date_of_inspection ? moment(generalDetail.date_of_inspection).isValid() ? '':'Please enter valid Date':'Please enter Date of Inspection',
      report_date:generalDetail?.report_date ? moment(generalDetail.report_date).isValid() ? '':'Please enter valid Date':'Please enter Report Date',
      report_issued_place:generalDetail?.report_issued_place?.trim()?.length ? '':'Please enter Report Issued Place',
    }
    if((generalDetail?.report_date && moment(generalDetail.report_date).isValid()) && (generalDetail?.date_of_inspection && moment(generalDetail.date_of_inspection).isValid())){
      if(generalDetail?.report_date <  generalDetail?.date_of_inspection){
        validationInputs = {
          ...validationInputs,
          date_of_inspection: 'Date of Inspection should be less then or equal to Report Date'
        }
      }
    }
    if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
      setLoading(true);
      globalPutService(`technical/v2/workorder/${params.workOrderSlug}/powerplant-general-info/`, generalDetail)
      .then(response => {
        if(checkApiStatus(response)){
          enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
          setEditAble(false);
          getCurrentFormDetail('pageLoader');
        }else{
          enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
        }
        setLoading(false);
      })
    }else{
      setError(validationInputs)
    }
  }
  const onFieldChange = (key, value) => {
    setGeneralDetail({...generalDetail, [key]:value});
  }
  const canEdit = currentFormDetail?.current_form?.permissions?.can_save; 
  return(
    <Paper className="form-detail-card">
      <p className='edit-details'>
        <ReadPrerequisite content={<PrerequisiteContent />} />
        {canEdit && !isEditAble ? 
          <span className='edit-g'>
            <IconButton size="small">
                <Tooltip title='Edit General Detail' arrow><EditIcon onClick={() => setEditAble(true)} fontSize='small' color='primary' /></Tooltip>
            </IconButton></span>:null
        }
      </p>
      <div className='scrollable-content' style={{height: `${window.innerHeight- (isEditAble ? 265:225)}px`, overflow:'auto'}}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={!isEditAble}
              name="place_of_inspection"
              label="Place of Inspection"
              fullWidth
              required
              margin="normal"
              inputProps={{maxLength: 50}}
              value={generalDetail?.place_of_inspection||''}
              onChange={(e) => onFieldChange('place_of_inspection', e.target.value)}
              error={error?.place_of_inspection}
              helperText={error?.place_of_inspection||''}
              InputLabelProps={{shrink: true}}
              onFocus={(e) => setError({...error,'place_of_inspection':''})}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                  disabled={!isEditAble}
                  margin="normal"
                  name="date_of_inspection"
                  label="Date of Inspection"
                  format={fieldDateFormat}
                  fullWidth
                  required
                  InputLabelProps={{shrink: true}}
                  value={generalDetail?.date_of_inspection||null}
                  error={error?.date_of_inspection}
                  helperText={error?.date_of_inspection||''}
                  onChange={(data, value) => {onFieldChange('date_of_inspection', data ? moment(data).format(backendDateFormat):data); setError({...error,'date_of_inspection':''})}}
                  inputVariant="outlined"
                />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} md={4}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                disabled={!isEditAble}
                margin="normal"
                id="report_date"
                label="Report Date"
                format={fieldDateFormat}
                fullWidth
                required
                InputLabelProps={{shrink: true}}
                value={generalDetail?.report_date||null}
                error={error?.report_date}
                helperText={error?.report_date||''}
                onChange={(data, value) => {onFieldChange('report_date', data ? moment(data).format(backendDateFormat):data); setError({...error,'report_date':''})}}
                inputVariant="outlined"
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={!isEditAble}
              id="report_issued_place"
              label="Report Issued Place"
              fullWidth
              required
              margin="normal"
              value={generalDetail?.report_issued_place||''}
              error={error?.report_issued_place}
              helperText={error?.report_issued_place||''}
              InputLabelProps={{shrink: true}}
              inputProps={{maxLength: 50}}
              onChange={(e) => onFieldChange('report_issued_place', e.target.value)}
              onFocus={(e) => setError({...error,'report_issued_place':''})}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </div>
      { isEditAble ?
        <div className='actions-cta'>
          <ul className='list-inline'>
            <li className='list-inline-item'>
              <Button onClick={onUpdateGeneralDetails} size='small' color='primary' variant='contained'>Save</Button>
            </li>
            <li className='list-inline-item'>
              <Button onClick={() => {setEditAble(false);getGeneralDetails(); setError({})}} size='small' color='primary' variant='outlined'>Cancel</Button>
            </li>
          </ul>
        </div>:null
      }
      { isLoading ? <PageLoader />:null}
    </Paper>
  )
}
export default withRouter(GeneralDetail);