import React, { Fragment } from 'react';
import { TableRow, TableCell, Tooltip, IconButton } from '@material-ui/core';
import {  } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import AddEditFinding from './AddEditFinding';
const FindingList = ({item, currentFormDetail, reset3DToInitial, getFindingResponse, findingSections, fabrikUrl, pictorialFindings, editCoOrdinates, toggleFindingModal, findingModal, toggleDeleteModal, stepId, stepType}) => {
    return(
        <TableRow hover tabIndex={-1}>
            <TableCell>{item.id}</TableCell>
            <TableCell>{item.section && item.section.name ? item.section.name:'--'}</TableCell>
            <TableCell>{item.finding_type ? item.finding_type:'--'}</TableCell>
            <TableCell>{item.damage_limit ? item.damage_limit:'--'}</TableCell>
            <TableCell>{item.classifications ? item.classifications:'--'}</TableCell>
            <TableCell className="actions-cell" style={{width: '120px'}}>
                <Tooltip title="View" arrow>
                    <IconButton size="small" color="primary">
                        <VisibilityIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                { currentFormDetail && currentFormDetail.current_form && currentFormDetail.current_form.permissions && currentFormDetail.current_form.permissions.can_save ?
                    <Fragment>
                        <AddEditFinding 
                            mode="edit"
                            stepId={stepId}
                            stepType={stepType}
                            id={item.id} 
                            reset3DToInitial={reset3DToInitial} 
                            getFindingResponse={getFindingResponse} 
                            findingSections={findingSections}
                            sectionId={item.section.id}
                            fabrikUrl={fabrikUrl}
                            pictorialFindings={pictorialFindings}
                            editCoOrdinates={editCoOrdinates}
                            toggleFindingModal={toggleFindingModal}
                            findingModal={findingModal}
                        />
                        <Tooltip title="Delete" arrow>
                            <IconButton size="small" color="primary">
                                <DeleteOutlineIcon onClick={toggleDeleteModal} fontSize="small" color="secondary" />
                            </IconButton>
                        </Tooltip>
                    </Fragment>:null
                }
            </TableCell>
        </TableRow>
    )
}
export default FindingList;