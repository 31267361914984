import React, { Component } from 'react';
import CKEditor from "react-ckeditor-component";
import { getLocalStorageInfo } from '../../utils';
import { onCheckPermission } from '../../utils_v2';
export default class CKeditor extends Component{
  constructor(props){
    super(props);
  }
  shouldComponentUpdate(nextProps, nextState){
    if(nextProps.content != undefined && this.props.content != nextProps.content)
    {
      return true;
    }else{
      return false;
    }
  }

  setDefaults = (ev) =>{
    var dialogName = ev.data.name;
    var dialogDefinition = ev.data.definition;

    if(dialogName === 'table') {
      var infoTab = dialogDefinition.getContents('info');
      var cellSpacing = infoTab.get('txtCellSpace');
      cellSpacing['default'] = "5";
      var cellPadding = infoTab.get('txtCellPad');
      cellPadding['default'] = "5";
      var border = infoTab.get('txtBorder');
      border['default'] = "0";
    }
  }

  render(){
    const ckEvents = {...this.props.events, dialogDefinition: this.setDefaults}
    let userInfo = getLocalStorageInfo().user
    return(

        onCheckPermission('project_management','workorder','C') ?
      <CKEditor
        events={ckEvents}
        content={this.props.content}
        style={this.props.style}
        className={this.props.className}
        scriptUrl='https://sparta-aims.ams3.digitaloceanspaces.com/others/tools/CK-Editor/ckeditor.js'
        config={
          {
            'format_tags' : 'p;h1;h2;h3;h4;h5;h6;pre;address;div',
            'toolbar': [
              { name: 'document', items: [ 'Source' ] },
              { name: 'spellchecker', items: [ 'Scayt' ] },
          		{ name: 'clipboard', items: [ 'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', 'Undo', 'Redo' ] },
              { name: 'styles', items: [ 'Format', 'Font', 'FontSize' ] },
          		{ name: 'colors', items: [ 'TextColor', 'BGColor' ] },
          		{ name: 'editing', items: [ 'Find', 'Replace', 'spellchecker' ] },
          		'/',
          		{ name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline', 'Strike', 'CopyFormatting', 'RemoveFormat' ] },
          		{ name: 'paragraph', items: [ 'NumberedList', 'BulletedList', 'Outdent', 'Indent', 'Blockquote', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', 'BidiLtr', 'BidiRtl' ] },
          		{ name: 'links', items: [ 'Link', 'Unlink', 'Anchor' ] },
          		{ name: 'insert', items: [ 'Table', 'HorizontalRule', 'SpecialChar', 'PageBreak' ] },
            ],
            'toolbarGroups': [
              { name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
          		{ name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
          		{ name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
          		{ name: 'forms', groups: [ 'forms' ] },
          		'/',
          		{ name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
          		{ name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
          		{ name: 'links', groups: [ 'links' ] },
          		{ name: 'insert', groups: [ 'insert' ] },
          		'/',
          		{ name: 'styles', groups: [ 'styles' ] },
          		{ name: 'colors', groups: [ 'colors' ] },
          		{ name: 'tools', groups: [ 'tools' ] },
          		{ name: 'others', groups: [ 'others' ] },
          		{ name: 'about', groups: [ 'about' ] }
            ],
            'extraPlugins' : ['justify' , 'scayt'],
            'disableNativeSpellChecker': false,
            'scayt_autoStartup': true
          }
        }
      />
      :
      <div style={{paddingLeft:'5px', background:'#fff'}} dangerouslySetInnerHTML={{__html:this.props?.content ? this.props.content : '--'}} />
    )
  }
}
