import React, { Fragment, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { withRouter } from 'react-router'
import { CircularProgress, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, InputAdornment, Tooltip } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { AutocompleteCreatable } from '../../../shared_elements'
import { globalGetService, globalPostService, globalPutService } from '../../../utils_v2/globalApiServices'
import { checkApiStatus } from '../../../utils_v2';
import { regexConstants } from '../../../constants/regEx';
import { allocationObj } from '../';
import { trackActivity } from '../../../utils/mixpanel';
const AddEditAllocation = ({params, getResponseBack, toggleModalFn, modal, id, budgets, project}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [allocationCrud, setAllocation] = useState(allocationObj);
    const [categories, setCategories] = useState([]);
    const [error, setError] = useState({});
    useEffect(() => {
        getCategories();
    }, []);
    let assets = [];
    for (const workorder of project.work_orders) {
        let wo_asset={
            'id':workorder.asset.id,
            'type':workorder.asset.type,
            'name':workorder.asset.name
        }
//        let name=''
//        if (workorder.asset.type==1){
//            name='A/C :'+workorder.asset.aircraft_type + ' - ' +workorder.asset.msn;
//        }else if (workorder.asset.type==2){
//            name='EN :'+workorder.asset.engine_type + ' - ' +workorder.asset.esn;
//        }else if (workorder.asset.type==3){
//            name='APU: '+workorder.asset.apu_type + ' - ' +workorder.asset.serial_number;
//        }else if (workorder.asset.type==4){
//            name='LG: '+workorder.asset.lg_position + ' - ' +workorder.asset.serial_number;
//        }
//        console.log('name',name)
        assets.push(wo_asset)
    }

    const getCategories = () => {
        globalGetService(`technical/budget/categories/`)
        .then(response => {
            if(checkApiStatus(response)){
                setCategories(response.data.data);
                if(id){
                    setAllocation(budgets.find(item => item.id === id));
                }
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
            }
        });
    }
    const onFieldChange = (e, keyParam, value) => {
        if(keyParam === 'category'){
            setAllocation({
                ...allocationCrud,
                [keyParam]: value,
                sub_category: null,
            });
        }else{
            setAllocation({
                ...allocationCrud,
                [keyParam]: value
            });
        }
    }
    const onResetErrorKey = (key) => {
        setError({
            ...error,
            [key]:''
        })
    }
    const onAddEditAllocation = (type, opsType) => {
        let validationInputs = {
            category:allocationCrud.category ? '':'Please select Allocation Category',
            sub_category:allocationCrud.sub_category ? '':'Please select Allocation Sub Category',
            alloted_amount:allocationCrud.alloted_amount ? '':'Please enter Budgeted Amount',
        }
        if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
            setLoading(true);
            let payload = Object.assign({}, allocationCrud);
            payload = {
                ...payload,
                alloted_amount:Number(payload.alloted_amount),
                variance:Number(payload.variance)
            }
            if(id){
                globalPutService(`technical/project/${params.project_slug}/budgets/`,payload)
                .then(response => {
                    setLoading(false);
                    if(checkApiStatus(response)){
                        toggleModalFn();
                        enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                        getResponseBack();
                        trackActivity('Expense Management', {
                            event_type: 'Updated Budget Allocation',
                            page_title: 'Budget Allocation',
                            project_id: project.id,
                            project_name: project.name,
                            project_slug: params.project_slug,
                            request_body: payload
                          });
                    }else{
                        trackActivity('Expense Management', {
                            event_type: 'Update Budget Allocation Failed',
                            page_title: 'Budget Allocation',
                            project_id: project.id,
                            project_name: project.name,
                            project_slug: params.project_slug,
                            error_source: 'Backened',
                            request_body: payload,
                            error_message: response.data.message
                          });
                        enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
                    }
                });
            }else{
                globalPostService(`technical/project/${params.project_slug}/budgets/`,payload)
                .then(response => {
                    setLoading(false);
                    if(checkApiStatus(response)){
                        enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}}) 
                        if(type === 'single'){
                            toggleModalFn();
                        }else{
                            setAllocation(allocationObj);
                        }
                        getResponseBack();
                        trackActivity('Expense Management', {
                            event_type: 'Added Budget Allocation',
                            page_title: 'Budget Allocation',
                            project_id: project.id,
                            project_name: project.name,
                            project_slug: params.project_slug,
                            request_body: payload
                          });
                    }else{
                        trackActivity('Expense Management', {
                            event_type: 'Add Budget Allocation Failed',
                            page_title: 'Budget Allocation',
                            project_id: project.id,
                            project_name: project.name,
                            project_slug: params.project_slug,
                            request_body: payload,
                            error_source: 'Backend',
                            error_message: response.data.message
                          });
                        enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
                    }
                });
            }   
        }else{
            setError(validationInputs);
            trackActivity('Expense Management', {
                event_type: 'Add Budget Allocation Failed',
                page_title: 'Budget Allocation',
                project_name: project.name,
                project_slug: params.project_slug,
                error: validationInputs ? validationInputs : '',
                error_source: 'Frontend'
              });
        }
    }

    return(
        <Fragment>
            <Dialog
                maxWidth="md"
                open={modal}
                onClose={toggleModalFn}
                aria-labelledby="scroll-dialog-title"
                className='project-management-modal'
            >
                <DialogTitle id="scroll-dialog-title">
                    {id ? 'Edit':'Add'} Budget Allocation
                </DialogTitle>
                <DialogContent dividers={true}>
                    <form>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={4}>
                                <Autocomplete
                                    options = {assets}
                                    getOptionLabel={option => option.name}
                                    id="asset"
                                    value={allocationCrud.asset ? allocationCrud.asset : null}
                                    onChange={(e, value) => onFieldChange(e, 'asset', value)}
                                    renderInput={params => <TextField {...params} label="Asset" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
                                />

                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <AutocompleteCreatable
                                    options={categories}
                                    paramsKey='category'
                                    optionKey='name'
                                    label='Allocation Category'
                                    value={allocationCrud.category ? allocationCrud.category : null}
                                    onFieldChange={(e, paramsKey, newValue) => onFieldChange(e, 'category', newValue)}
                                    resetErrorKey={() => onResetErrorKey('category')}
                                    error={error.category}
                                    helperText={error.category}
                                    required={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <AutocompleteCreatable
                                    options = {allocationCrud.category && allocationCrud.category.id ? categories.find(category => category.id === allocationCrud.category.id).sub_categories : []}
                                    paramsKey='sub_category'
                                    optionKey='name'
                                    label='Allocation Sub-Category'
                                    value={allocationCrud.sub_category ? allocationCrud.sub_category : null}
                                    onFieldChange={(e, paramsKey, newValue) => onFieldChange(e, 'sub_category', newValue)}
                                    resetErrorKey={() => onResetErrorKey('sub_category')}
                                    error={error.sub_category}
                                    helperText={error.sub_category}
                                    required={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    id="alloted_amount"
                                    label="Budgeted Amount"
                                    fullWidth
                                    margin="normal"
                                    error={error.alloted_amount} 
                                    helperText={error.alloted_amount}
                                    value={allocationCrud.alloted_amount ? allocationCrud.alloted_amount : ''}
                                    InputLabelProps={{shrink: true}}
                                    inputProps={{maxLength: 10}}
                                    onChange={(e) => { regexConstants.numberWithDot4.test(e.target.value) ? onFieldChange(e, 'alloted_amount', e.target.value) : e.preventDefault()}}
                                    onFocus={() => onResetErrorKey('alloted_amount')}
                                    variant='outlined'
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">{project.currency ? project.currency:''}</InputAdornment>
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="variance"
                                    label="Variance"
                                    fullWidth
                                    margin="normal"
                                    value={allocationCrud.variance ? allocationCrud.variance : ''}
                                    InputLabelProps={{shrink: true}}
                                    onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) && e.target.value <= 100 ? onFieldChange(e, 'variance', e.target.value) : e.preventDefault()}}
                                    variant='outlined'
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    id="remarks"
                                    label="Remarks"
                                    fullWidth
                                    margin="normal"
                                    value={allocationCrud.remarks ? allocationCrud.remarks : ''}
                                    InputLabelProps={{shrink: true}}
                                    inputProps={{maxLength: 255}}
                                    onChange={(e) => onFieldChange(e, 'remarks', e.target.value)}
                                    variant='outlined'
                                    multiline
                                    maxRows={3}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button disabled={isLoading} onClick={toggleModalFn} color="primary" variant='outlined' size='small'>
                        Cancel
                    </Button>
                    { !id ?
                        <Button disabled={isLoading} onClick={() => onAddEditAllocation('more')} color="primary" variant='contained' size='small'>
                            {isLoading ? 
                                <CircularProgress size={20} />:'Save & Add More'
                            }
                        </Button>:null
                    }                    
                    <Button disabled={isLoading} onClick={() => onAddEditAllocation('single')} color="primary" variant='contained' size='small'>
                        {isLoading ? 
                            <CircularProgress size={20} />:'Save'
                        }
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}
export default withRouter(AddEditAllocation);