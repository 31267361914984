import React, {useState, useEffect} from 'react';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Paper, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress, Grid, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { fieldDateFormat, backendDateFormat } from '../../../constants';
import { regexConstants } from '../../../constants/regEx';
import { globalGetService, globalPostService, globalPutService } from '../../../utils_v2/globalApiServices';
import { checkApiStatus } from '../../../utils_v2';
import { createEngine } from '../';
const AddEngineWorkOrder = ({modal, toggleModalFn}) => {
    const [isLoading, setLoading] = useState(false);
    const [engine, setEngine] = useState(createEngine);
    const [enginesList, setEnginesList] = useState([]);
    const [engineTypes, setEngineTypes] = useState([]);
    const [aircraftTypes, setAircraftTypes] = useState([]);
    const [forms, setForms] = useState([]);
    const [pltConstants, setPltConstants] = useState([]);
    const [error, setError] = useState({});
    useEffect(() => {
        getEngines();
        getEngineForms();
        getPlatformConstants();
        getAircraftTypes();
        getEngines();
    },[])
    const onFieldChange = (key, value) => {
        setEngine({...engine, [key]:value});
    }
    const getEngineDetails = () => {
        globalGetService(`technical/engine/aDBQsY/details`)
        .then(response => {
            if(checkApiStatus(response)){
                let result = response.data.data;
                let obj = {
                    slug:'',
                    engine_type_id:result?.engine_type_id||null,
                    aircraft_msn:result?.aircraft_msn||'',
                    aircraft_registration:result?.aircraft_registration||'',
                    aircraft_type_id:result?.aircraft_type_id||null,
                    owned_by_type:result?.owned_by_type||null,
                    location:result?.location||'',
                    country:result?.country||'',
                    tsn:result?.tsn||'',
                    csn:result?.tsn||'',
                    engine_status:result?.engine_status||null,
                    install_position:result?.install_position||null,
                    engine_manufacturing_date:result?.engine_manufacturing_date||null,
                    date_of_installation:result?.date_of_installation||null
                }
                setEngine(obj);
            }
        })
    }
    const getEngines = () => {
        globalGetService(`console/engine-esn-list/`,{ switcher:true})
        .then(response => {
            if(checkApiStatus(response)){
                setEnginesList(response.data.data.engines);
            }
        })
    }
    const getEngineTypes = () => {
        globalGetService(`console/engine-types/`)
        .then(response => {
            if(checkApiStatus(response)){
                setEngineTypes(response.data.data.engineTypes)
            }
        })
    }
    const getAircraftTypes = () => {
        globalGetService(`console/aircraft-types/`)
        .then(response => {
            if(checkApiStatus(response)){
                setAircraftTypes(response.data.data.aircraftTypes);
            }
        })
    }
    const getEngineForms = () => {
        globalGetService(`technical/forms/`,{form_type:2})
        .then(response => {
            if(checkApiStatus(response)){
                setForms(response.data.data);
            }
        })
    }
    const getPlatformConstants = () => {
        globalPostService(`console/get-constants/`, {constant_types:['engine_position']})
        .then(response => {
            if(checkApiStatus(response)){
                setPltConstants(response.data.data)
            }
        })
    }
    const onAddEngine = () => {
        let validationInputs = {
            esn:engine?.esn?.trim()?.length ? '':'Please enter Serial Number',
            engine_type_id:engine?.engine_type_id ? '':'Please select Engine Type',
            country:engine.create_esn ? engine?.country?.trim()?.length ? '':'Please enter Country':'',
            engine_manufacturing_date:engine?.engine_manufacturing_date ? '':'Please enter Manufacturing Date',
            tsn:engine?.tsn?.trim()?.length ? '':'Please enter TSN',
            csn:engine?.csn?.trim()?.length ? '':'Please enter CSN',
        }
        if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
            globalPostService(``)
            .then(response => {
                if(checkApiStatus(response)){

                }
            })
        }else{
            setError(validationInputs);
        }
    }
    return(
        <Dialog
            maxWidth="md"
            open={modal}
            onClose={toggleModalFn}
            className='project-management-modal'
        >
            <DialogTitle id="scroll-dialog-title">
                Add Engine
            </DialogTitle>
            <DialogContent dividers={true}>
                <Grid container spacing={1}>
                    <Grid item md={4}></Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item md={12}>
                        <RadioGroup row aria-label="position" name="position" defaultValue="top">
                            <FormControlLabel value="link" control={<Radio checked={engine.create_esn === false} onChange={() => setEngine({...createEngine, create_esn:false})} color="primary" />} label="Link Existing ESN" />
                            <FormControlLabel value="create" control={<Radio checked={engine.create_esn === true} onChange={() => setEngine({...createEngine, create_esn:true})} color="primary" />} label="Create New ESN" />
                        </RadioGroup>
                    </Grid>
                    <Grid item md={4}>
                        { engine.create_esn ?
                            <TextField
                                required
                                name="esn"
                                label="Engine Serial Number"
                                fullWidth
                                margin="normal"
                                value={engine?.esn||''}
                                InputLabelProps={{shrink: true}}
                                onChange={(e) => onFieldChange('esn',e.target.value)}
                                error={error?.esn}
                                helperText={error?.esn||''}
                                onFocus={() => setError({...error, 'esn':''})}
                                variant='outlined'
                            />:
                            <Autocomplete
                                options = {enginesList}
                                getOptionLabel={option => option.unique_name}
                                id="esn"
                                value={null}
                                onChange={(e,value) => onFieldChange('esn', value)}
                                renderInput={params => <TextField required {...params} label="Serial Number" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
                            />
                        }
                    </Grid>
                    { engine.create_esn ?
                        <Grid item md={4}>
                            {(() => {
                                let optionValue = engineTypes.length && engine?.engine_type_id ? engineTypes.find(item => item.id === engine.engine_type_id) :null
                                return(
                                    <Autocomplete
                                        options = {engineTypes}
                                        getOptionLabel={option => option.name}
                                        name="engine_type_id"
                                        value={optionValue}
                                        onChange={(e,value) => {onFieldChange('engine_type_id', value?value.id:null); setError({...error,'engine_type_id':''})}}
                                        renderInput={params => <TextField required error={error?.engine_type_id} helperText={error?.engine_type_id||''} {...params} label="Engine Type" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
                                    />
                                )
                            })()}
                            
                        </Grid>:null    
                    }
                    <Grid item md={4}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                margin="normal"
                                required
                                name="engine_manufacturing_date"
                                label="Manufacturing Date"
                                format={fieldDateFormat}
                                fullWidth
                                clearable={true}
                                disableFuture
                                InputLabelProps={{shrink: true}}
                                value={engine?.engine_manufacturing_date||null}
                                onChange={(data, value) => {onFieldChange(value, 'engine_manufacturing_date', data ? moment(data).format(backendDateFormat):data); setError({...error,'engine_manufacturing_date':''})}}
                                error={error?.engine_manufacturing_date}
                                helperText={error?.engine_manufacturing_date||''}
                                inputVariant='outlined'
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item md={4}>
                        <TextField
                            required
                            name="tsn"
                            label="TSN"
                            fullWidth
                            margin="normal"
                            value={engine?.tsn||''}
                            InputLabelProps={{shrink: true}}
                            inputProps={{maxLength: 10}}
                            onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange('tsn', e.target.value) : e.preventDefault()}}
                            error={error?.tsn}
                            helperText={error?.tsn||''}
                            onFocus={() => setError({...error, 'tsn':''})}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={4}>
                        <TextField
                            required
                            name="csn"
                            label="CSN"
                            fullWidth
                            margin="normal"
                            value={engine?.csn||''}
                            InputLabelProps={{shrink: true}}
                            inputProps={{maxLength: 10}}
                            onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange('csn', e.target.value) : e.preventDefault()}}
                            error={error?.csn}
                            helperText={error?.csn||''}
                            onFocus={() => setError({...error, 'csn':''})}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={4}>
                        <TextField
                            required
                            name="country"
                            label="Country"
                            fullWidth
                            margin="normal"
                            value={engine?.country||''}
                            InputLabelProps={{shrink: true}}
                            onChange={(e) => onFieldChange('country',e.target.value)}
                            error={error?.country}
                            helperText={error?.country||''}
                            onFocus={() => setError({...error, 'country':''})}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={4}>
                        <TextField
                            name="aircraft_msn"
                            label="A/C Serial Number"
                            fullWidth
                            margin="normal"
                            value={engine?.aircraft_msn||''}
                            InputLabelProps={{shrink: true}}
                            onChange={(e) => onFieldChange('aircraft_msn',e.target.value)}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={4}>
                        <TextField
                            name="aircraft_registration"
                            label="A/C Registration"
                            fullWidth
                            margin="normal"
                            value={engine?.aircraft_registration||''}
                            InputLabelProps={{shrink: true}}
                            onChange={(e) => onFieldChange('aircraft_registration',e.target.value)}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={4}>
                        {(() => {
                            let optionValue = aircraftTypes?.length && engine?.aircraft_type_id ? aircraftTypes.find(item => item.id === engine.aircraft_type_id) :null
                            return(
                                <Autocomplete
                                    options = {aircraftTypes}
                                    getOptionLabel={option => option.name}
                                    name="aircraft_type_id"
                                    value={optionValue}
                                    onChange={(e,value) => onFieldChange('aircraft_type_id', value?value.id:null)}
                                    renderInput={params => <TextField {...params} label="A/C Model" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
                                />
                            )
                        })()}
                        
                    </Grid>
                    <Grid item md={4}>
                        {(() => { 
                            let options = [{label:'On Wing', value:1}, {label:'In Shop', value:2}, {label:'In Storage', value:3}]
                            let optionValue = engine?.engine_status ? options.find(item => item.value === engine?.engine_status):null
                            return(
                                <Autocomplete
                                    options = {options}
                                    getOptionLabel={option => option.label}
                                    name="engine_status"
                                    value={optionValue}
                                    onChange={(e,value) => onFieldChange('engine_status', value?value.value:null)}
                                    renderInput={params => <TextField {...params} label="Status" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
                                />
                            )
                        })()}
                    </Grid>
                    <Grid item md={4}>
                        {(() => { 
                            let options = [{label:'Owner', value:1}, {label:'Client', value:2}];
                            let optionValue = engine?.owned_by_type ? options.find(item => item.value === engine.owned_by_type) :null
                            return(
                                <Autocomplete
                                    options = {options}
                                    getOptionLabel={option => option.label}
                                    name="owned_by_type"
                                    value={optionValue}
                                    onChange={(e,value) => onFieldChange('owned_by_type', value?value.value:null)}
                                    renderInput={params => <TextField {...params} label="Ownership" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
                                />
                            )
                        })()}
                    </Grid>
                    <Grid item md={4}>
                        <TextField
                            name="location"
                            label="Location"
                            fullWidth
                            margin="normal"
                            value={engine?.location||''}
                            InputLabelProps={{shrink: true}}
                            onChange={(e) => onFieldChange('location',e.target.value)}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={4}>
                        {(() => {
                            let options = pltConstants.filter(item => item.type === 'engine_position');
                            let optionValue = options?.length && engine?.install_position ? options.find(item => item.value === engine.install_position) :null
                            return(
                                <Autocomplete
                                    options = {options}
                                    getOptionLabel={option => option.label}
                                    name="install_position"
                                    value={optionValue}
                                    onChange={(e,value) => onFieldChange('install_position', value?value.value:null)}
                                    renderInput={params => <TextField {...params} label="Position" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
                                />
                            ) 
                        })()}
                    </Grid>
                    <Grid item md={4}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                margin="normal"
                                name="date_of_installation"
                                label="Date of Installation"
                                format={fieldDateFormat}
                                fullWidth
                                clearable={true}
                                InputLabelProps={{shrink: true}}
                                value={engine?.date_of_installation||null}
                                onChange={(data, value) => {onFieldChange(value, 'date_of_installation', data ? moment(data).format(backendDateFormat):data);}}
                                inputVariant='outlined'
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item md={12}>
                        <Autocomplete
                            multiple
                            options = {forms}
                            getOptionLabel={option => option.name}
                            name="forms"
                            value={engine?.forms||[]}
                            onChange={(e,value) => onFieldChange('forms', value)}
                            renderInput={params => <TextField required {...params} label="Forms" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} color="primary" onClick={toggleModalFn} size="small">Cancel</Button>
                <Button disabled={isLoading} onClick={onAddEngine} variant="contained" color="primary" size="small">
                    {isLoading ? <CircularProgress size={20} /> : 'Save'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default AddEngineWorkOrder;