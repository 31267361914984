import React from 'react'
import { Link } from 'react-router'
import appConfig from '../../config'
import restrictIcon from '../../shared/assets/img/restrict_icon.svg'
export default function RestrictedAccess() {
    return (
        <div>
            <div>
            <div style={{position: 'fixed', top: '50%', left: '54%', transform: 'translate(-50%, -58%)', color: '#000000', textAlign: 'center'}}>
              Sorry, You do not have access to this.<br/>
              Please contact to Admin for access
            </div>
          </div>
        </div>
    )
}
