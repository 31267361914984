import React, { Component } from 'react';
import { Link } from 'react-router';
import { Row, Col } from 'reactstrap';
import moment from 'moment';
import { TextInputField, GetInputField } from'../../../Elements';
import { NoRecordFound  } from '../../../../../shared';
import { checkString, checkEmail, checkValidDate, checkFloat, checkIntNumber } from '../../../../../formValidator';
import { imgStoragePath, displayDateFormat } from '../../../../../constants';

export default class AddEditShopvisit extends Component {
  constructor(props){
    super(props);
    this.state = {
      shopVisit:{},
      error: {}
    }
  }

	generateUrl = (e) => {
    if(e.target.files && e.target.files.length){
      this.props.generateUrl(e.target.files);
    }
	}


  updateShopVisit = (e) => {
		e.preventDefault();
		const validateNewInput = {
      shop_visit_number: checkString({value: this.props.shopVisitCrud.data.shop_visit_number, required: true, minLength: '', maxLength: '', message: 'Please enter Shop Visit Number'}),
      date_of_removal: checkValidDate({value: this.props.shopVisitCrud.data.date_of_removal, required: false, minLength: '', maxLength: '', message: 'Please select valid Date of Removal'}),
			tsn: checkFloat({value: this.props.shopVisitCrud.data.tsn, required: false, minLength: '', maxLength: '', message: 'Please enter valid tsn'}),
      csn: checkIntNumber({value: this.props.shopVisitCrud.data.csn, required: false, minLength: '', maxLength: '', message: 'Please enter valid csn'}),
      tslsv: checkFloat({value: this.props.shopVisitCrud.data.tslsv, required: false, minLength: '', maxLength: '', message: 'Please enter valid tslsv'}),
      cslsv: checkIntNumber({value: this.props.shopVisitCrud.data.cslsv, required: false, minLength: '', maxLength: '', message: 'Please enter valid cslsv'}),
      tsr: checkFloat({value: this.props.shopVisitCrud.data.tsr, required: false, minLength: '', maxLength: '', message: 'Please enter valid tsr'}),
			csr: checkIntNumber({value: this.props.shopVisitCrud.data.csr, required: false, minLength: '', maxLength: '', message: 'Please enter valid csr'}),
			tso: checkFloat({value: this.props.shopVisitCrud.data.tso, required: false, minLength: '', maxLength: '', message: 'Please enter valid tso'}),
      cso: checkIntNumber({value: this.props.shopVisitCrud.data.cso, required: false, minLength: '', maxLength: '', message: 'Please enter valid cso'}),
      tshi: checkFloat({value: this.props.shopVisitCrud.data.tshi, required: false, minLength: '', maxLength: '', message: 'Please enter valid tshi'}),
      cshi: checkIntNumber({value: this.props.shopVisitCrud.data.cshi, required: false, minLength: '', maxLength: '', message: 'Please enter valid cshi'}),
      induction_date: checkValidDate({value: this.props.shopVisitCrud.data.induction_date, required: false, minLength: '', maxLength: '', message: 'Please select valid Induction Date'}),
      arc_date: checkValidDate({value: this.props.shopVisitCrud.data.arc_date, required: false, minLength: '', maxLength: '', message: 'Please select valid Arc Date'}),
      date_of_reinstallation: checkValidDate({value: this.props.shopVisitCrud.data.date_of_reinstallation, required: false, minLength: '', maxLength: '', message: 'Please select valid Date of Reinstallation'}),
		};

		if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
			this.props.updateShopVisit({data: this.props.shopVisitCrud.data, type: this.props.shopVisitCrud.type, flag: !this.props.shopVisitCrud.flag});
		}else{
			this.props.updateShopVisitErr(validateNewInput);
		}
  }
  render(){
	const { error } = this.state;
		let { shopVisitCrud, shopVisitErr } = this.props;
		const { type } = this.props.shopVisitCrud;
		let headerHeight = document.body.contains(document.getElementById('sidebar-header')) ? document.getElementById('sidebar-header').clientHeight : 0 ;
		let shopVisit = shopVisitCrud.data;
		return(
      <div>
        <form onSubmit={(e) => this.updateShopVisit(e)}>
          <div className="sidebar-form"
           style={{ height: '90vh'}}
           >
            <Row>
              <Col md="3" style={{marginBottom: '20px'}}>
                {
                  GetInputField(
                    'string',
                    <label className="for-label">Shop Visit Number {type != 'view'?<sup className="reqStar"> * </sup>:null}</label>,
                    { type: type,
                      value: shopVisit.shop_visit_number,
                      error: shopVisitErr.shop_visit_number,
                      updateForm: (value) => this.props.updateForm('shop_visit_number', value)
                    }
                  )
                }
              </Col>
              <Col md="3" style={{marginBottom: '20px'}}>
                {
                  GetInputField(
                    'date',
                    <label className="for-label">Date of Removal </label>,
                    { type: type,
                      value:shopVisit.date_of_removal,
                      error: shopVisitErr.date_of_removal,
                      updateForm: (value) =>  this.props.updateForm('date_of_removal', value)
                    }
                  )
                }
              </Col>
              <Col md="3" style={{marginBottom: '20px'}}>
                {
                  GetInputField(
                    'string',
                    <label className="for-label">Reason of Removal </label>,
                    { type: type,
                      value:shopVisit.reason_for_removal,
                      error: '',
                      updateForm: (value) =>  this.props.updateForm('reason_for_removal', value)
                    }
                  )
                }
              </Col>
              <Col md="3" style={{marginBottom: '20px'}}>
                {
                  GetInputField(
                    'string',
                    <label className="for-label">Shop Facility </label>,
                    { type: type,
                      value:shopVisit.shop_facility,
                      error: '',
                      updateForm: (value) =>  this.props.updateForm('shop_facility', value)
                    }
                  )
                }
              </Col>
              <Col md="3" style={{marginBottom: '20px'}}>
                {
                  GetInputField(
                    'string',
                    <label className="for-label">TSN </label>,
                    { type: type,
                      value:shopVisit.tsn,
                      error: shopVisitErr.tsn,
                      updateForm: (value) =>  this.props.updateForm('tsn', value==''?null:value)
                    }
                  )
                }
              </Col>
              <Col md="3" style={{marginBottom: '20px'}}>
                {
                  GetInputField(
                    'string',
                    <label className="for-label">CSN </label>,
                    { type: type,
                      value:shopVisit.csn,
                      error: shopVisitErr.csn,
                      updateForm: (value) =>  this.props.updateForm('csn', value==''?null:value)
                    }
                  )
                }
              </Col>
              <Col md="3" style={{marginBottom: '20px'}}>
                {
                  GetInputField(
                    'string',
                    <label className="for-label">TSLSV </label>,
                    { type: type,
                      value:shopVisit.tslsv,
                      error: shopVisitErr.tslsv,
                      updateForm: (value) =>  this.props.updateForm('tslsv', value==''?null:value)
                    }
                  )
                }
              </Col>
              <Col md="3" style={{marginBottom: '20px'}}>
                {
                  GetInputField(
                    'string',
                    <label className="for-label">CSLSV </label>,
                    { type: type,
                      value:shopVisit.cslsv,
                      error: shopVisitErr.cslsv,
                      updateForm: (value) =>  this.props.updateForm('cslsv', value==''?null:value)
                    }
                  )
                }
              </Col>
              <Col md="3" style={{marginBottom: '20px'}}>
                {
                  GetInputField(
                    'string',
                    <label className="for-label">TSR </label>,
                    { type: type,
                      value:shopVisit.tsr,
                      error: shopVisitErr.tsr,
                      updateForm: (value) =>  this.props.updateForm('tsr', value==''?null:value)
                    }
                  )
                }
              </Col>

              <Col md="3" style={{marginBottom: '20px'}}>
                {
                  GetInputField(
                    'string',
                    <label className="for-label">CSR </label>,
                    { type: type,
                      value:shopVisit.csr,
                      error: shopVisitErr.csr,
                      updateForm: (value) =>  this.props.updateForm('csr', value==''?null:value)
                    }
                  )
                }
              </Col>

              <Col md="3" style={{marginBottom: '20px'}}>
                {
                  GetInputField(
                    'string',
                    <label className="for-label">TSO </label>,
                    { type: type,
                      value:shopVisit.tso,
                      error: shopVisitErr.tso,
                      updateForm: (value) =>  this.props.updateForm('tso', value==''?null:value)
                    }
                  )
                }
              </Col>

              <Col md="3" style={{marginBottom: '20px'}}>
                {
                  GetInputField(
                    'string',
                    <label className="for-label">CSO </label>,
                    { type: type,
                      value:shopVisit.cso,
                      error: shopVisitErr.cso,
                      updateForm: (value) =>  this.props.updateForm('cso', value==''?null:value)
                    }
                  )
                }
              </Col>

              <Col md="3" style={{marginBottom: '20px'}}>
                {
                  GetInputField(
                    'string',
                    <label className="for-label">TSHSI </label>,
                    { type: type,
                      value:shopVisit.tshi,
                      error: shopVisitErr.tshi,
                      updateForm: (value) =>  this.props.updateForm('tshi', value==''?null:value)
                    }
                  )
                }
              </Col>

              <Col md="3" style={{marginBottom: '20px'}}>
                {
                  GetInputField(
                    'string',
                    <label className="for-label">CSHSI </label>,
                    { type: type,
                      value:shopVisit.cshi,
                      error: shopVisitErr.cshi,
                      updateForm: (value) =>  this.props.updateForm('cshi', value==''?null:value)
                    }
                  )
                }
              </Col>

              <Col md="3" style={{marginBottom: '20px'}}>
                {
                  GetInputField(
                    'date',
                    <label className="for-label">Engine Induction Date </label>,
                    { type: type,
                      value:shopVisit.induction_date,
                      error: shopVisitErr.induction_date,
                      updateForm: (value) =>  this.props.updateForm('induction_date', value)
                    }
                  )
                }
              </Col>

              <Col md="3" style={{marginBottom: '20px'}}>
                {
                  GetInputField(
                    'string',
                    <label className="for-label">Engine Workscope </label>,
                    { type: type,
                      value:shopVisit.final_workscope,
                      error: '',
                      updateForm: (value) =>  this.props.updateForm('final_workscope', value)
                    }
                  )
                }
              </Col>

              <Col md="3" style={{marginBottom: '20px'}}>
                {
                  GetInputField(
                    'string',
                    <label className="for-label">Engine Build Target </label>,
                    { type: type,
                      value:shopVisit.build_target,
                      error: '',
                      updateForm: (value) =>  this.props.updateForm('build_target', value)
                    }
                  )
                }
              </Col>

              <Col md="3" style={{marginBottom: '20px'}}>
                {
                  GetInputField(
                    'string',
                    <label className="for-label">Test Run EGT Margin </label>,
                    { type: type,
                      value:shopVisit.test_run_egt_margin,
                      error: '',
                      updateForm: (value) =>  this.props.updateForm('test_run_egt_margin', value)
                    }
                  )
                }
              </Col>


							<Col md="3" style={{marginBottom: '20px'}}>
                {
                  GetInputField(
                    'string',
                    <label className="for-label">LLP Status </label>,
                    { type: type,
                      value:shopVisit.llp_status,
                      error: '',
                      updateForm: (value) =>  this.props.updateForm('llp_status', value)
                    }
                  )
                }
              </Col>

              <Col md="3" style={{marginBottom: '20px'}}>
                {
                  GetInputField(
                    'date',
                    <label className="for-label">ARC Date ( EASA/FAA) </label>,
                    { type: type,
                      value:shopVisit.arc_date,
                      error: shopVisitErr.arc_date,
                      updateForm: (value) =>  this.props.updateForm('arc_date', value)
                    }
                  )
                }
              </Col>

              <Col md="3" style={{marginBottom: '20px'}}>
                {
                  GetInputField(
                    'string',
                    <label className="for-label">TAT </label>,
                    { type: type,
                      value:shopVisit.total_air_temperature,
                      error: '',
                      updateForm: (value) =>  this.props.updateForm('total_air_temperature', value)
                    }
                  )
                }
              </Col>

              <Col md="3" style={{marginBottom: '20px'}}>
                {
                  GetInputField(
                    'date',
                    <label className="for-label">Date of Reinstallation </label>,
                    { type: type,
                      value:shopVisit.date_of_reinstallation,
                      error: shopVisitErr.date_of_reinstallation,
                      updateForm: (value) =>  this.props.updateForm('date_of_reinstallation', value)
                    }
                  )
                }
              </Col>
            </Row>

							<Row className="escalation-block">
								<h5 className="escalation-heading">

									<Link className="add-attachment" style={{ cursor: 'pointer'}}> + ADD ATTACHMENT
										<input type="file" onChange = {(e) =>  this.generateUrl(e)}
                    accept=".xls,.xlsx,application/pdf"
                    onClick = {(e) => e.target.value = null}
										/>
									</Link>
								</h5>

								{
									shopVisit.attachments && shopVisit.attachments.length ?
									<table>
									<thead>
										<tr>
											<th style={{width: '200px'}}>File Name</th>
											<th>Description</th>
											<th>Uploaded on</th>
											<th>Uploaded by</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										{
											shopVisit.attachments.map((attachmentData, index) =>
												<tr>
												<td>{attachmentData.name ?attachmentData.name.length >22 ?attachmentData.name.substring(0,19) + '...' : attachmentData.name :'--' }</td>
												<td>
													<input
														type="text"
														value= {attachmentData.description}
														onChange = {(e) => this.props.updateDescription(index, 'description', e.target.value)}
														/>
													</td>
													<td>
                             { moment(attachmentData.created_at).format(displayDateFormat)}
													</td>
												<td>
													<div>
														<img className="profile-pic" src={attachmentData.user.profile_pic} alt="img"/>
														<span>{attachmentData.user.name}</span>
													</div>
												</td>
												<td>
                        <Link  target="_blank" to = {attachmentData.attachment}>
                           <img width="12" className="" src={imgStoragePath+"import_images_icons.png"} onClick={this.props.toggleComDelModal}/>
                        </Link>
													<img width="12" onClick = {() => this.props.toggleDelModal('', attachmentData.id, 'attachment', index)} className="" src={imgStoragePath+"delete_red.png"}/>

												</td>
											</tr>
											)
										}
									</tbody>
								</table> :
								<Col md="12">
										<NoRecordFound style={{ backgroundColor: '#f7f7f7', margin: 0}}/>
								</Col>
								}
						</Row>
          </div>
            <div className="edit-sidebar-footer">
              <ul className="list-inline">
                <li className="list-inline-item">
                  <button type="submit"
                  disabled={this.props.shopVisitLoader}
                  type="submit"
                  className="btn btn-primary">{this.props.shopVisitLoader ? 'Processing...':'Save'}</button>
                </li>
                <li className="list-inline-item">
                  <Link onClick={this.props.toggleEditSideBar}> Cancel</Link>
                </li>
               </ul>
            </div>
        </form>
      </div>
    )
  }
}
