import * as actions from '../actions';
export default function(state={
  genAllSections:[],
  generalPictures :[],
  pagination:{},
  pictureSegregation:{},
  moveImages: {
    flag: false,
    imageIds: [],
    sectionId: '',
    sections: []
  },
  importGnPicCrud: {
    flag: false,
    data: {},
    selected: []
  },
  importPicSeg: {
    flag:false,
    data: {},
    selected: []
  },
  editGenPicCrud: {
    flag:false,
    data: {}
  },
  genPicLoader: false,
  genSortLoader: {},
  formInfo: {},
  formStatusLoader: false,
  aiWaitTimer: false,
  formLoader:false
}, action ){
switch (action.type) {
  case actions.ALL_GEN_SECTIONS:
    return {
      ...state,
      genAllSections: action.payload,
    }
  case actions.FORM_GENPICTURES:
    return {
      ...state,
      generalPictures: action.payload.list,
      pagination:action.payload.pagination
    }
  case actions.SORT_GEN_IMAGES:
    return {
      ...state,
      generalPictures: state.generalPictures.map((item) => item.id == action.payload.listId ? {...item,pictures: action.payload.sortedPictures} : item)
    }
  case actions.GET_FORM_INFO:
    return {
      ...state,
      formInfo: action.payload
    }
  case actions.SET_FORM_LOADER:
    return {
      ...state,
      formLoader: action.payload
    }
  case actions.UPDATE_FORM_GENPICTURES:
    return {
      ...state,
      generalPictures: state.generalPictures.map(item => item.id == action.payload.id ? {...item, pictures: [...item.pictures, ...action.payload.pictures]}  :item)
    }
  case actions.IMPORT_GEN_PIC:
    return {
      ...state,
      importGnPicCrud: action.payload
    }
  case actions.EDIT_GEN_IMAGE:
    return {
      ...state,
      editGenPicCrud: action.payload
    }
  case actions.ADD_IMPORT_GEN_PIC:
    return {
      ...state,
      importGnPicCrud: {
        ...state.importGnPicCrud,
        data: {
          ...state.importGnPicCrud.data,
          pictures: [...state.importGnPicCrud.data.pictures, ...action.payload.pictures]
        }
      }
    }
  case actions.UPDATE_IMPORT_GEN_PIC:
    if(state.importGnPicCrud.selected.includes(action.payload.id)){
      return {
        ...state,
        importGnPicCrud: {
          ...state.importGnPicCrud,
          selected: state.importGnPicCrud.selected.filter(id => id != action.payload.id)
        }
      }
    }else{
      return {
        ...state,
        importGnPicCrud: {
          ...state.importGnPicCrud,
          selected: [...state.importGnPicCrud.selected, action.payload.id]
        }
      }
    }
  case actions.EDIT_GEN_PIC:
    return {
      ...state,
      generalPictures: state.generalPictures.map(section => section.id == action.payload.aircraft_section ? {...section, pictures: section.pictures.map(picture => picture.id == action.payload.id ? action.payload : picture)} :section)
    }
  case actions.REMOVE_GEN_PIC:
    return {
      ...state,
      generalPictures: state.generalPictures.map(section => section.id == action.payload.sectionId ? { ...section , pictures : section.pictures.filter(picture => picture.id != action.payload.picId )} :section)
    }
  case actions.DELETE_GENPICTURE:
    return{
      ...state,
      generalPictures: state.generalPictures.map((parent, index) => index == action.payload.parentIndex ? {...parent, images: parent.images.filter((child, childIndex) => childIndex != action.payload.childIndex)} : parent)
    }
  case actions.GEN_PIC_LOADER:
    return {
      ...state,
      genPicLoader: action.payload
    }
  case actions.GEN_SORT_LOADER:
    return {
      ...state,
      genSortLoader: action.payload
    }
  case actions.TOGGLE_FORM_STATUS_LOADER:
    return {
      ...state,
      formStatusLoader: action.payload
    }
  case actions.FORM_PICTURE_SEGREGATION:
    return {
      ...state,
      pictureSegregation: action.payload
    }
  case action.IMPORT_PICTURE_SEGREGATION:
    return {
      ...state,
      importPicSeg: {
        ...state.importPicSeg,
        selected: [...state.importPicSeg.selected, action.payload]
      }
    }
  case actions.SELECT_SEG_IMG:
    return {
      ...state,
      moveImages: {
        ...state.moveImages,
        imageIds: action.payload.checked ? [...state.moveImages.imageIds, action.payload.id]: state.moveImages.imageIds.filter(picId => picId != action.payload.id)
      }
    }
  case actions.SELECT_MOVE_SECTION:
    return {
      ...state,
      moveImages: {
        ...state.moveImages,
        sectionId: action.payload.sectionId
      }
    }
  case actions.TOGGLE_MOVE_SECTION:
    if(action.payload.flag && action.payload.picId){
      return {
        ...state,
        moveImages: {
          ...state.moveImages,
          imageIds: action.payload.picId,
          flag: action.payload.flag
        }
      }
    }else{

      return {
        ...state,
        moveImages: {
          ...state.moveImages,
          imageIds: action.payload.flag ? state.moveImages.imageIds:[],
          sectionId: action.payload.flag ? state.moveImages.sectionId: '',
          flag: action.payload.flag
        }
      }
    }
  case actions.AI_WAIT_TIMER:
    return {
      ...state,
      aiWaitTimer: action.payload.flag
    }
  default:
    return state;
  }
}
