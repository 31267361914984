import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  addCommentsAc,
  changeIssueStatusAc,
  fetchFormsFeedbackAc,
  fetchIssueDetailAc,
  fetchReviewDataAc,
  updateCommentsAc,
  addIssuesAc,
  notifyClientAc
} from "../actionCreators";
import { getFormDetailsAc } from "../../T001/actionCreators";
import { imgStoragePath } from "../../../../../constants";
import { MSNSwitcherHeader, ListLoader } from "../../../../../shared";
import { TOGGLE_DETAILS, TOOGLE_ADD_ISSUES, TOOGLE_ISSUES_LIST, SELECT_REVIEW_TEXT, EMPTY_SELECT_REVIEW_TEXT, ISSUES_CRUD, TOOGLE_EDIT_ISSUES } from '../actions';
import { SecondaryTechHeaderMid, SecondaryTechHeaderRH, EditFormBar } from "../../../Elements";
import { ReviewIssueDrawer, ReviewHeader, IssueDetail } from '../components';
import AddIssues from '../components/AddIssues';
import { isMobile } from 'react-device-detect';
import EditIssues from '../components/EditIssues';
import { trackActivity } from '../../../../../utils/mixpanel';

class ReviewT004 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      objectId: '',
      label: '',
      style: false,
      selectedText: '',
      editIssue: '',
    }
  }
  componentDidMount() {
    this.props.fetchReviewData();
    this.props.fetchFormsFeedback();
    this.props.getFormDetails();
    trackActivity('ReviewT004', {
      event_type: 'Page Visited',
      page_title: 'ReviewT004'
    })
  }
  toggleComments = (id, label) => {
    this.setState({
      objectId: id ? id : '',
      label: label ? label : ''
    });
    this.props.toggleComment();
  };
  toggleIssues = () => {
    this.props.toggleIssue();
  };

  toggleDetail = (issue) => {
    issue ? this.props.reviewFeedBackDetail(issue.location_slug) : this.props.toggleDetails();
    if (document.querySelector(`.issue-number-${issue.id}`).parentElement.nodeName.toLowerCase() != 'span') {
      window.scrollTo({ left: document.querySelector(`.issue-number-${issue.id}`).parentElement.offsetLeft, top: document.querySelector(`.issue-number-${issue.id}`).parentElement.offsetTop - 150, behavior: 'smooth' })
    } else {
      window.scrollTo({ left: document.querySelector(`.issue-number-${issue.id}`).offsetLeft, top: document.querySelector(`.issue-number-${issue.id}`).offsetTop - 150, behavior: 'smooth' })
    }
  };

  updateComments = (value) => {
    let data = {
      description: value,
      label: this.state.label,
      obj_id: this.state.objectId,
      form_id: this.props.formInfo.current_form.id,
    }
    this.props.addIssue(data);
  }

  toggleEditIssues = () => {
    this.props.toggleEditIssue();
  };

  toggleEdits = (e, issue, status) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({
      editIssue: issue,
    });
    this.props.toggleEditIssue();
  };

  deleteStatus = (e, issue, status) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.reviewIssueStatusChange(issue.location_slug, status, 'delete');
  };

  changeStatus = (e, issue, status) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.reviewIssueStatusChange(issue.location_slug, status);
  };

  changeStatusDetails = (e, location_slug, status) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.reviewIssueStatusChange(location_slug, status, 'details');
  };

  updateIssues = (value) => {
    this.props.reviewIssueStatusChange(this.state.editIssue.location_slug, value, 'edit');
  }

  updateCommentStatus = (e, location_slug, commentId) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.updateCommentStatus(location_slug, commentId);
  }

  readTheAnswer = (answers, key) => {
    let answer = answers.filter(answer => answer.label == key);
    if (answer.length) {
      return answer[0].value
    }
  }

  reviewUIComp = (issue = {}, id, label) => {
    if (issue.order_number == null || issue.order_number == undefined) {
      return (
        <span onClick={() => this.toggleComments(id, label)} className="comment-icon" >
          <img src={imgStoragePath + "cmnt_active.svg"} className="blue-icon" alt="" />
          <img src={imgStoragePath + "cmnt-inactive.svg"} className="grey-icon" alt="" />
        </span>

      )
    } else {
      return (
        <span onClick={() => document.querySelector(`.issue_${issue.order_number}`).click()} className={`issue-number issue-number-${issue.id}`} >
          <span>#{issue.order_number}</span>
        </span>
      )
    }
  }

  selectText = (index, objectId, evt) => {
    this.props.unSelectText('<span style="color: red">' + this.props.selectedText + '</span>', this.props.selectedText)
    this.setState({
      style: false,
      objectId
    })
    if (window.getSelection().toString().length) {
      this.setState({
        objectId,
        style: true,
        selectedText: window.getSelection().toString()
      })
      this.props.selectText(index, window.getSelection().toString())
    }

  }

  render() {
    const { t004ReviewList, notifyFlag, reviewFeedbacksList } = this.props;
    let qa1_prefill = t004ReviewList && t004ReviewList.list && t004ReviewList.list.inspection_qa ? t004ReviewList.list.inspection_qa[0].prefill : null
    let qa1_prefill_1 = t004ReviewList && t004ReviewList.list && t004ReviewList.list.inspection_qa ? t004ReviewList.list.inspection_qa[1].prefill : null
    let qa1_prefill_2 = t004ReviewList && t004ReviewList.list && t004ReviewList.list.inspection_qa ? t004ReviewList.list.inspection_qa[2].prefill : null
    let qa1_prefill_3 = t004ReviewList && t004ReviewList.list && t004ReviewList.list.inspection_qa ? t004ReviewList.list.inspection_qa[3].prefill : null
    if (qa1_prefill != null && reviewFeedbacksList.length && reviewFeedbacksList.filter(item => item.issue_type == 3 && item.inspection_qa_id == 1).length) {
      reviewFeedbacksList.map(item => {
        if (item.issue_type == 3 && item.inspection_qa_id == 1) {
          qa1_prefill = qa1_prefill.replace(item.quote, `<span class="existing-issue">${item.quote}<span class="issue-number issue-number-${item.id}" onclick="document.querySelector('.issue_${item.order_number}').click()"><span>#${item.order_number}</span></span></span>`)
        }
        return item
      })
    }
    if (qa1_prefill_1 != null && reviewFeedbacksList.length && reviewFeedbacksList.filter(item => item.issue_type == 3 && item.inspection_qa_id == 2).length) {
      reviewFeedbacksList.map(item => {
        if (item.issue_type == 3 && item.inspection_qa_id == 2) {
          qa1_prefill_1 = qa1_prefill_1.replace(item.quote, `<span class="existing-issue">${item.quote}<span class="issue-number issue-number-${item.id}" onclick="document.querySelector('.issue_${item.order_number}').click()"><span>#${item.order_number}</span></span></span>`)
        }
        return item
      })
    }
    if (qa1_prefill_2 != null && reviewFeedbacksList.length && reviewFeedbacksList.filter(item => item.issue_type == 3 && item.inspection_qa_id == 3).length) {
      reviewFeedbacksList.map(item => {
        if (item.issue_type == 3 && item.inspection_qa_id == 3) {
          qa1_prefill_2 = qa1_prefill_2.replace(item.quote, `<span class="existing-issue">${item.quote}<span class="issue-number issue-number-${item.id}" onclick="document.querySelector('.issue_${item.order_number}').click()"><span>#${item.order_number}</span></span></span>`)
        }
        return item
      })
    }
    if (qa1_prefill_3 != null && reviewFeedbacksList.length && reviewFeedbacksList.filter(item => item.issue_type == 3 && item.inspection_qa_id == 4).length) {
      reviewFeedbacksList.map(item => {
        if (item.issue_type == 3 && item.inspection_qa_id == 4) {
          qa1_prefill_3 = qa1_prefill_3.replace(item.quote, `<span class="existing-issue">${item.quote}<span class="issue-number issue-number-${item.id}" onclick="document.querySelector('.issue_${item.order_number}').click()"><span>#${item.order_number}</span></span></span>`)
        }
        return item
      })
    }
    return (
      <div className="technical-review-model">
        {
          isMobile ?
            <div
              style={{
                color: '#8a6d3b',
                background: '#fcf8e3',
                border: '1px solid #faebcc',
                position: 'fixed',
                top: '150px',
                right: '0px',
                zIndex: '90',
                width: '100%',
                padding: '5px',
                textAlign: 'center',
                fontSize: '10px'
              }}
            >
              Please Note: The Review option is not available on SPARTA on Touch Screen devices such as iPads, Tablets and Mobile phones. Please use SPARTA on Laptop or Desktop devices for using the Review function. Please contact support team at sparta@acumenaviation.ie for more details
            </div>
            : null
        }
        <button className="primary-btn"
          style={this.state.style ? { position: 'fixed', top: '160px', left: '75px' } : { display: 'none' }}
          onClick={() => this.props.addEditIssue({ flag: true, data: { qoute: '', description: '', object_id: '', issue_type: 3, form_id: 4 } })}
        >
          <i className="fa fa-plus-circle" style={{ marginRight: '5px' }}></i>
          Add Issue
        </button>
        {Object.keys(this.props.formInfo).length ?
          <MSNSwitcherHeader
            SecodaryHeaderRH={<SecondaryTechHeaderRH projectInfo={this.props.formInfo.project} />}
            SecondaryHeaderMid={<SecondaryTechHeaderMid projectInfo={this.props.formInfo.project} activeAsset={this.props.formInfo.asset} />}
            activeAsset={this.props.formInfo.asset}
            projectSlug={Object.keys(this.props.formInfo).length ? this.props.formInfo.project.slug : ''}
          /> : null
        }
        <ReviewHeader
          formInfo={this.props.formInfo}
          notifyFlag={notifyFlag}
          notifyClient={this.props.notifyClient}
          workOrderSlug={this.props.params.workOrderSlug}
        />
        {this.props.commentsLoader ? <ListLoader /> : null}
        <div className="form-t004">

          <ul className="list-unstyled survey-info">
            <li>
              <h2>Place of Survey</h2>
              <p>{t004ReviewList && t004ReviewList.list && t004ReviewList.list.place_of_survey ? t004ReviewList.list.place_of_survey : '--'}</p>
            </li>
            <li>
              <h2>Performed By</h2>
              <p>{t004ReviewList && t004ReviewList.list && t004ReviewList.list.performed_by_user ? t004ReviewList.list.performed_by_user : '--'}</p>
            </li>
            <li>
              <h2>Date of Survey</h2>
              <p>{t004ReviewList && t004ReviewList.list && t004ReviewList.list.place_of_survey ? t004ReviewList.list.date_of_survey : '--'}</p>
            </li>
            <li>
              <h2>Cover Photo</h2>
              <img width='250px' src={t004ReviewList && t004ReviewList.list && t004ReviewList.list.cover_photo ? t004ReviewList.list.cover_photo : ''} alt="" />
            </li>
          </ul>
          <div className="table-of-content">
            <h1 className="text-center"><span>Table of Content</span></h1>
            <div className="table-content-wrapper">
              <div className="table-content-single">
                <ul className="list-unstyled">
                  <li>
                    1. Summary
                    <ul className="list-unstyled">
                      <li>
                        1.1 Brief Summary
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div className="table-content-single">
                <ul className="list-unstyled">
                  <li>
                    2. Aircraft History and Status
                    <ul className="list-unstyled">
                      <li>
                        2.1 Technical Status
                      </li>
                      <li>
                        2.2 Aircraft General History
                      </li>
                      <li>
                        2.3 Major Assemblies
                      </li>
                      <li>
                        2.4 Galleys and Lavatories
                      </li>
                      <li>
                        2.5 Seats
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div className="table-content-single">
                <ul className="list-unstyled">
                  <li>
                    3. Aircraft Inspection Summary
                    <ul className="list-unstyled">
                      <li>
                        3.1 Technical Status
                      </li>
                      <li>
                        3.2 Aircraft General History
                      </li>
                      <li>
                        3.3 Major Assemblies
                      </li>
                      <li>
                        3.4 Galleys and Lavatories
                      </li>
                      <li>
                        3.5 Seats
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div className="table-content-single">
                <ul className="list-unstyled">
                  <li>
                    4. Aircraft Physical Inspection
                    <ul className="list-unstyled">
                      <li>
                        4.1 Technical Status
                      </li>
                      <li>
                        4.2 Aircraft General History
                      </li>
                      <li>
                        4.3 Major Assemblies
                      </li>
                      <li>
                        4.4 Galleys and Lavatories
                      </li>
                      <li>
                        4.5 Seats
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="detail-block">
            <div className="detail-single">
              <h1 className="detail-title">1. {t004ReviewList && t004ReviewList.list && t004ReviewList.list.inspection_qa ? t004ReviewList.list.inspection_qa[0].title : ''}
                {
                  // reviewFeedbacksList.length ?
                  //   reviewFeedbacksList.filter(item => item.issue_type == 3 && item.inspection_qa_id == 1).length ?
                  //     <span className="issues-group-count" onClick={() => this.toggleIssues()}>{reviewFeedbacksList.filter(item => item.issue_type == 3 && item.inspection_qa_id == 1).length}</span>
                  //   :null
                  // :null
                }
              </h1>
              <div className="detail-inner" onMouseUp={(evt) => this.selectText(0, t004ReviewList.list.inspection_qa[0].id, evt)}>
                <div dangerouslySetInnerHTML={{ __html: qa1_prefill }} />
              </div>
            </div>
            <div className="detail-single">
              <h1 className="detail-title">2.{t004ReviewList && t004ReviewList.list && t004ReviewList.list.inspection_qa ? t004ReviewList.list.inspection_qa[1].title : ''}</h1>
              <div className="detail-inner" onMouseUp={() => this.selectText(1, t004ReviewList.list.inspection_qa[1].id)}>
                <div dangerouslySetInnerHTML={{ __html: qa1_prefill_1 }} />
              </div>
            </div>
            <div className="detail-single">
              <h1 className="detail-title">3. {t004ReviewList && t004ReviewList.list && t004ReviewList.list.inspection_qa ? t004ReviewList.list.inspection_qa[2].title : ''}</h1>
              <div className="detail-inner" onMouseUp={() => this.selectText(2, t004ReviewList.list.inspection_qa[2].id)}>
                <div dangerouslySetInnerHTML={{ __html: qa1_prefill_2 }} />
              </div>
            </div>
            <div className="detail-single physical-conditions">
              <h1 className="detail-title">4. Aircraft Inspection - Physical Condition</h1>
              {t004ReviewList && t004ReviewList.list && t004ReviewList.list.aircraft_sections && t004ReviewList.list.aircraft_sections.length ?
                t004ReviewList.list.aircraft_sections.map((data, index) =>
                  <div>
                    <table style={{ marginBottom: '15px' }}>
                      <tr>
                        <th className="physical-part">{data.name}</th>
                        <th>Poor</th>
                        <th>Average</th>
                        <th>Good</th>
                        <th>Remarks</th>
                      </tr>
                      {data.physical_questions && data.physical_questions.length ?
                        data.physical_questions.map((questions, index) =>
                          <tr>
                            <td>{questions.label}</td>
                            <td className="comments-wrapper">{questions.answer.value == 1 ? <img src={imgStoragePath + "red_check.png"} alt="" /> : ''} {this.reviewUIComp(questions.issue_list[0].issue, questions.id, questions.label + '_poor')}</td>
                            <td className="comments-wrapper">{questions.answer.value == 2 ? <img src={imgStoragePath + "yellow_check.png"} alt="" /> : ''} {this.reviewUIComp(questions.issue_list[1].issue, questions.id, questions.label + '_average')}</td>
                            <td className="comments-wrapper">{questions.answer.value == 3 ? <img src={imgStoragePath + "green_check.png"} alt="" /> : ''} {this.reviewUIComp(questions.issue_list[2].issue, questions.id, questions.label + '_good')}</td>
                            <td className="comments-wrapper">{questions.answer.remarks ? questions.remarks : '--'}{this.reviewUIComp(questions.issue_list[3].issue, questions.id, questions.label + '_remarks')}</td>
                          </tr>
                        ) : null}
                    </table>
                    {data.specs && Object.keys(data.specs).length ?
                      <table style={{ marginBottom: '15px' }}>
                        <tr>
                          <td className="physical-part">{data.name}</td>
                          {Object.keys(data.specs).map((key, mntIndex) => data.specs[key].is_fitted == (this.readTheAnswer(data.answers, 'is_fitted') ? "true" : "false") ?
                            <td className="comments-wrapper">{data.specs[key].label}:{this.readTheAnswer(data.answers, key) ? this.readTheAnswer(data.answers, key) : '--'}{this.reviewUIComp(data.specs[key].issue, data.id, key)}</td> : null
                          )}
                        </tr>
                      </table> : null}
                  </div>
                ) : null}
              <p className="additional-remarks">Additional Remark : Not inspected because of no access during the physical inspection.</p>
            </div>
            <div className="detail-single">
              <h1 className="detail-title">5. {t004ReviewList && t004ReviewList.list && t004ReviewList.list.inspection_qa ? t004ReviewList.list.inspection_qa[3].title : ''}</h1>
              <div className="detail-inner" onMouseUp={() => this.selectText(3, t004ReviewList.list.inspection_qa[3].id)}>
                <div dangerouslySetInnerHTML={{ __html: qa1_prefill_3 }} />
              </div>
            </div>
          </div>
        </div>
        <a href="javascript:void(0)" className="view-issues-btn" onClick={() => this.toggleIssues()} >
          <img src={imgStoragePath + 'icon_CommentDash.svg'} alt="" />
        </a>
        <EditFormBar
          titleContent={<div><h3 style={{ textTransform: 'capitalize' }}> {'Post Issues'} </h3></div>}
          toggleEditSideBar={() => this.toggleComments()}
          isOpen={this.props.toggleComments}
          style={this.props.toggleComments ? { width: '400px' } : { width: '0px' }}>
          {this.props.toggleComments ?
            <AddIssues
              updateComments={this.updateComments}
              toggleEditSideBar={() => this.toggleComments()}
            /> : null
          }
        </EditFormBar>
        <EditFormBar
          titleContent={<div><h3 style={{ textTransform: 'capitalize' }}> {'Post Issues'} </h3></div>}
          toggleEditSideBar={() => this.props.addEditIssue({ flag: false, data: { qoute: '', description: '', object_id: '', issue_type: 3, form_id: 4 } })}
          isOpen={this.props.markedIssue.flag}
          style={this.props.markedIssue.flag ? { width: '400px' } : { width: '0px' }}>
          {this.props.markedIssue.flag ?
            <AddIssues
              updateComments={(comment) => { this.props.addIssue({ quote: this.state.selectedText, description: comment, inspection_qa_id: this.state.objectId, issue_type: 3, form_id: 4 }); this.setState({ style: false }) }}
              toggleEditSideBar={() => this.props.addEditIssue({ flag: false, data: { qoute: '', description: '', object_id: '', issue_type: 3, form_id: 4 } })}
            /> : null
          }
        </EditFormBar>
        <EditFormBar
          titleContent={<div><h3 style={{ textTransform: 'capitalize' }}> {'Edit Issue'} </h3></div>}
          toggleEditSideBar={() => this.toggleEditIssues()}
          isOpen={this.props.toggleEditIssues}
          style={this.props.toggleEditIssues ? { width: '400px' } : { width: '0px' }}>
          {this.props.toggleEditIssues ?
            <EditIssues
              updateComments={this.updateIssues}
              issueData={this.state.editIssue}
              toggleEditSideBar={() => this.toggleEditIssues()}
            /> : null
          }
        </EditFormBar>
        <EditFormBar
          titleContent={<div><h3 style={{ textTransform: 'capitalize' }}> {'Issues List'} </h3></div>}
          toggleEditSideBar={() => this.toggleIssues()}
          isOpen={this.props.toggleIssues}
          style={this.props.toggleIssues ? { width: '400px' } : { width: '0px' }}>
          {this.props.reviewFeedbacksList.length >= 0 ?
            <ReviewIssueDrawer
              issueList={this.props.reviewFeedbacksList}
              toggleEditSideBar={() => this.toggleComments()}
              toggleDetailBar={(issue) => this.toggleDetail(issue)}
              changeStatus={this.changeStatus}
              deleteStatus={this.deleteStatus}
              editStatus={this.toggleEdits}
            /> : null}
        </EditFormBar>
        <EditFormBar
          titleContent={<div><h3 style={{ textTransform: 'capitalize' }}> {'Issues Discussion'} </h3></div>}
          toggleEditSideBar={() => this.toggleDetail(this.props.issueDetailInfo)}
          isOpen={this.props.toggleDetail}
          style={this.props.toggleDetail ? { width: '400px' } : { width: '0px' }}>
          {this.props.toggleDetail ?
            <IssueDetail
              issueDetailInfo={this.props.issueDetailInfo}
              toggleEditSideBar={() => this.toggleDetail(this.props.issueDetailInfo)}
              toggleSideBars={() => this.toggleIssues()}
              addComments={this.props.addComments}
              changeStatus={this.changeStatusDetails}
              updateCommentStatus={this.updateCommentStatus}
            />
            : null}
        </EditFormBar>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  t004ReviewList: state.FormReviewReducer.t004ReviewList,
  selectedText: state.FormReviewReducer.selectedText,
  markedIssue: state.FormReviewReducer.markedIssue,
  formInfo: state.FormT001Reducer.formInfo,
  toggleComments: state.FormReviewReducer.toggleComments,
  toggleIssues: state.FormReviewReducer.toggleIssues,
  toggleEditIssues: state.FormReviewReducer.toggleEditIssues,
  toggleDetail: state.FormReviewReducer.toggleDetail,
  issueDetailInfo: state.FormReviewReducer.issueDetailInfo,
  reviewFeedbacksList: state.FormReviewReducer.reviewFeedbacksList,
  commentsLoader: state.FormReviewReducer.commentsLoader,
  notifyFlag: state.FormReviewReducer.notifyFlag
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchReviewData: () => dispatch(fetchReviewDataAc(ownProps, 't004')),
    fetchFormsFeedback: () => dispatch(fetchFormsFeedbackAc(ownProps, 't004')),
    reviewFeedBackDetail: (locationSlug) => dispatch(fetchIssueDetailAc(ownProps, locationSlug, 't004')),
    reviewIssueStatusChange: (locationSlug, status, flag = '') => dispatch(changeIssueStatusAc(ownProps, locationSlug, status, 't004', flag)),
    getFormDetails: () => dispatch(getFormDetailsAc(ownProps)),
    notifyClient: () => dispatch(notifyClientAc(ownProps)),
    addComments: (location_slug, message) => dispatch(addCommentsAc(location_slug, message)),
    updateCommentStatus: (locationSlug, commentID) => dispatch(updateCommentsAc(ownProps, locationSlug, commentID)),
    addIssue: (data) => dispatch(addIssuesAc(ownProps, data, 't004')),
    toggleComment: () => dispatch({
      type: TOOGLE_ADD_ISSUES,
      payload: {}
    }),
    toggleIssue: () => dispatch({
      type: TOOGLE_ISSUES_LIST,
      payload: {}
    }),
    toggleDetails: () => dispatch({
      type: TOGGLE_DETAILS,
      payload: {}
    }),
    toggleEditIssue: () => dispatch({
      type: TOOGLE_EDIT_ISSUES,
      payload: {}
    }),
    selectText: (index, selectedText) => dispatch({
      type: SELECT_REVIEW_TEXT,
      payload: {
        index, selectedText
      }
    }),
    unSelectText: (selection, unSelection) => dispatch({
      type: EMPTY_SELECT_REVIEW_TEXT,
      payload: {
        selection,
        unSelection
      }
    }),
    addEditIssue: (data) => dispatch({
      type: ISSUES_CRUD,
      payload: data
    })
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ReviewT004);
