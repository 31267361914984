import React, { Component } from 'react';
import { Link, browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import { Button, TablePagination , Paper,Chip} from '@material-ui/core';

import classnames from 'classnames';
import {
  DeleteModal,
  PrerequisiteWrapper,
  PrerequisiteSubWrapper,
  EditFormBar,
  SecondaryTechHeaderRH,
  SecondaryTechHeaderMid,
  FormHeader,
  DownloadedLogs
} from '../../../Elements';
import { getFormDetailsAc, updateFormStatusAc } from '../../T001/actionCreators';
import * as actions from '../actions';
import { imgStoragePath , backendDateFormat} from '../../../../../constants';
import { downLoadTechEngineFormAc } from '../../../../../shared/actionCreators'
import { GenralDetail03Wrapper, DataList, BulkOperation, LlpDetails, ItemCards } from '../components';
import { ExportFiles, MSNSwitcherHeader, ListLoader, TableUIComp } from '../../../../../shared';
import { fetchGapReportDetailsAc, updateGapReportDetailsAc, removeGapReportDetAc } from '../actionCreators';
import { recordsData } from '../../AddFormObjects';
import { checkValidDate, checkString, checkIntNumber } from '../../../../../formValidator';
import { sGlobalConstantAc } from '../../../../../shared/actionCreators'
import { removeEmptyKey , toastFlashMessage, getLocalStorageInfo, checkApiStatus} from '../../../../../utils';
import { trackActivity } from '../../../../../utils/mixpanel';
import AddIcon from '@material-ui/icons/Add';

import moment from 'moment';
import ImportSections from '../components/ImportSections';
import ImportLlpSections from '../components/ImportLlpSections';
import { globalGetService, globalPutService } from '../../../../../globalServices';
import AddItemModal from '../components/AddItemModal';
class InspectionFormTE003 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      preReqModal: false,
      preReqListModal: false,
      delCompModal: false,
      deleteComponentIndex: '',
      deleteComponentTitle: '',
      deleteCompoId: '',
      deleteSection: '',
      downloadFormLogs: false,
      error: {},
      importSections:{modal:false},
      importLppSections:{modal:false},
      recordsDetails:{},
      recordsPagination:{per_page:'20', page:1},
      llpPagination:{per_page:'20', page:1},
      llpDetails:{}
    }
  }
  componentDidMount() {
    // let location = browserHistory.getCurrentLocation();
    // location = {
    //   ...location,
    //   query: {
    //     ...location.query,
    //     'per_page': 20,
    //       'page': 1
    //   }
    // }
    // browserHistory.push(location);
    this.props.getFormDetails(2, 'TE003');
    this.props.fetchGapReportFn();
    this.getRecordsDetailsList();
    this.getLlpDetailsList();
    this.props.fetchTechConstant({ constant_types: ['engine_gap_report_status'] });
  }
  toggleDownloadedLogs = () => {
    this.setState(prevState => ({
      ...prevState,
      downloadFormLogs: !prevState.downloadFormLogs
    }))
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }
  toggleComDelModal = (title = null, index = null, compoId = null, section = null) => {
    this.setState({
      delCompModal: !this.state.delCompModal,
      deleteComponentTitle: title,
      deleteComponentIndex: index,
      deleteCompoId: compoId,
      deleteSection: section
    });
  }

  getRecordsDetailsList =(query)=>{
    this.setState({listLoader:true})
    globalGetService(`technical/v2/engine-workorder/${this.props.params.workOrderSlug}/gap-report/?section_id=1`,query)
    .then(response=>{
      this.setState({listLoader:false})
      if(checkApiStatus(response)){
        this.setState({recordsDetails:response.data.data})
      }
    })
  }
  getLlpDetailsList =(query)=>{
    this.setState({listLoader:true})
    globalGetService(`technical/v2/engine-workorder/${this.props.params.workOrderSlug}/gap-report/?section_id=2`,query)
    .then(response=>{
      this.setState({listLoader:false})
      if(checkApiStatus(response)){
        this.setState({llpDetails:response.data.data})
      }
    })
  }
  updateGapReportDetails = (gapReportInvDet) => {
    let validateNewInput = {};
    let presentDate= moment().format(backendDateFormat)
    validateNewInput = Object.keys(gapReportInvDet).map(dataKey => {
      if (dataKey == 'general_details') {
        return (
          [dataKey] = [{
            ...validateNewInput,
            report_date: checkValidDate({ value: gapReportInvDet.general_details.report_date, required: true, minLength: '', maxLength: '', message: 'Please enter valid date' }),
            report_issued_place: checkString({ value: gapReportInvDet.general_details.report_issued_place, required: true, minLength: '', maxLength: '', message: 'Please enter Report Issued Place' }),
          }]
        )
      } else if (dataKey == 'list') {
        return (
          [dataKey] = gapReportInvDet.list.map((gapData, compIndex) => gapData ?
            gapData.gap_reports.map((compData, index) => compData ? {
              cycles_remaining: checkIntNumber({ value: compData.cycles_remaining, required: false, minLength: '', maxLength: '', message: 'Please enter valid data' }),
              description: checkString({ value: compData.description, required: true, minLength: "", maxLength: "", message: "Please enter description" })
            } : compData
            ) : gapData
          )
        )
      }
    })
    validateNewInput = removeEmptyKey(validateNewInput);

    let validationNewFlag = [];
    validateNewInput.map((validateInput, index) => index == 0 ?
      validateInput.map((innerArray) => Object.keys(innerArray).map((k) => innerArray[k] === '' ? validationNewFlag.push(true) : validationNewFlag.push(false))
      ) : index == 2 ?
        validateInput.map((innerArray) => innerArray.map(subInnerAray => Object.keys(subInnerAray).every((k) => { subInnerAray[k] === '' ? validationNewFlag.push(true) : validationNewFlag.push(false) })
        )
        ) : validationNewFlag.push(true)
    );

    if (validationNewFlag.filter(validData => validData == false).length == 0) {
        if(gapReportInvDet?.general_details?.hsi_date < gapReportInvDet?.validation_details?.esn_manufacturing_date){
          toastFlashMessage('HSI date can not be less than ESN Manufacturing date', 'error')
        }
        else if(gapReportInvDet?.general_details?.hsi_date > presentDate ){
          toastFlashMessage('HSI date can not be greater than Present date', 'error')
         }
        else if(gapReportInvDet?.general_details?.hsi_tsn >gapReportInvDet?.validation_details?.tsn ){
           toastFlashMessage('TSN at HSI should be less than the TSN of Engine', 'error')
         }
        else if(gapReportInvDet?.general_details?.hsi_csn >gapReportInvDet?.validation_details?.csn ){
           toastFlashMessage('CSN at HSI should be less than the CSN of Engine', 'error')
         }
         else{
           this.props.updateGapReportDetailsFn(gapReportInvDet);
         }
   
    } else {
      this.props.errFn(validateNewInput)
      trackActivity('TE003', {
        event_type: 'Save Gap Report Failed',
        page_title: 'Gap Report',
        workorder_slug: this.props.params.workOrderSlug ? this.props.params.workOrderSlug : '',
        form_slug: this.props.params.formSlug ? this.props.params.formSlug : '',
        error_source: 'Frontend',
        error_type: 'Validation',
        error_details: validateNewInput ? validateNewInput : '',
        asset_type: this.props.params.type ? this.props.params.type : '',
      })
    }

  }

  togglePrerequisiteModal = () => {
    this.setState({
      preReqModal: !this.state.preReqModal,
    });
  }
  togglePreList = () => {
    this.setState({
      preReqListModal: !this.state.preReqListModal,
    });
  }

  scrollToBottom() {
    this.te003End.scrollIntoView({ behavior: 'smooth' });
  }

  updateForm = (obj) => {
    if(["hsi_tsn","hsi_csn" ].includes(obj.type) ){
      if(obj.value=="" || obj.value==null){
        obj={
        ...obj,
        value:null
       }
     }
     else{
       obj={
         ...obj,
         value:parseFloat(parseFloat(obj.value).toFixed(2))
        }
      }
     
      this.props.updateForm(obj);
    }
    else{
      this.props.updateForm(obj);
      this.props.clearErr({ type: obj.type, sectionType: obj.sectionType, index: obj.index ? obj.index : 0 });
    }
  }
  changePerPage =(perPage, tabIndex)=>{
    if(tabIndex == 1){
      let pagination= this.state.recordsPagination
      pagination={
        ...pagination,
        'per_page': perPage,
        'page': 1
      }
      this.setState({recordsPagination:pagination})
      this.getRecordsDetailsList(pagination)
    }
    else{
      let pagination= this.state.llpPagination
      pagination={
        ...pagination,
        'per_page': perPage,
        'page': 1
      }
      this.setState({llpPagination:pagination})
      this.getLlpDetailsList(pagination)
    }
  }
  changePagination  =(page, tabIndex)=>{
    if(tabIndex == 1){
      let pagination= this.state.recordsPagination
      pagination={
        ...pagination,
        page:page
      }
      this.setState({recordsPagination:pagination})
      this.getRecordsDetailsList(pagination)
    }
    else{
      let pagination= this.state.llpPagination
      pagination={
        ...pagination,
        page:page
      }
      this.setState({llpPagination:pagination})
      this.getLlpDetailsList(pagination)
    }
  }

  updateRowDetails =(data)=>{
    globalPutService(`technical/v2/engine-workorder/${this.props.params.workOrderSlug}/gap-report/${data?.id}/`, data)
    .then(response=>{
      if(checkApiStatus(response)){
        toastFlashMessage('Details updated successfully', 'success')
      }
    })
  }

  onChangetabDetails =(tabIndex,data)=>{
    const {type, value,index}= data
    if(tabIndex === 1){
      this.setState(prevState=>({
          ...prevState,
          recordsDetails:{
            ...prevState.recordsDetails,
            list:[...prevState.recordsDetails?.list].map((gapReport, itemIndex)=>
            index === itemIndex ?{
              ...gapReport,
              [type]:value
            }: gapReport
            )
          }
      }))
      setTimeout(()=>{
        this.updateRowDetails(this.state.recordsDetails?.list[index])
      },400)
      }
      else{
        this.setState(prevState=>({
          ...prevState,
          llpDetails:{
            ...prevState.llpDetails,
            list:[...prevState.llpDetails.list].map((llpdata, itemIndex)=>
            index === itemIndex ?{
              ...llpdata,
              [type]:value
            }: llpdata
            )
          }
        }))
        setTimeout(()=>{
          this.updateRowDetails(this.state.llpDetails?.list[index])
        },400)
      }
  }
  addDataItem =(tabIndex)=>{
    this.setState({addItem:{modal:true, tabIndex:tabIndex}})
  }
  trackTabChangeActivity = (type, event, tab) => {
    trackActivity('TE003', { event_type: type, page_title: 'Gap Report', event_desc: event, tab_name: tab })
  }

  render() {
    const { error, gapReportInvDet, gepReportLoader, techConstants, techExportLoader, formStatusLoader, formInfo } = this.props;
    const {importSections, importLppSections, recordsDetails, llpDetails, addItem}= this.state
    console.log('addItem',addItem);
    return (
      <div className="technical-inspection-cn">
        <MSNSwitcherHeader
          SecodaryHeaderRH={<SecondaryTechHeaderRH projectInfo={this.props.formInfo.project} />}
          SecondaryHeaderMid={<SecondaryTechHeaderMid projectInfo={this.props.formInfo.project} />}
          activeAsset={this.props.formInfo.asset}
          projectSlug={Object.keys(this.props.formInfo).length ? this.props.formInfo.project.slug : ''}
        />
        <FormHeader
          formInfo={this.props.formInfo}
          saveButton={<Link style={gepReportLoader ? { pointerEvents: 'none', opacity: '0.5' } : { pointerEvents: 'auto', opacity: '1' }} onClick={() => this.updateGapReportDetails(gapReportInvDet)} className="primary-btn"> {gepReportLoader ? 'Processing...' : 'Save'} </Link>}
          exportReport={<ExportFiles exportFile={(fileType) => this.props.exportReport('gap-report-sections', 'ESN_' + this.props.formInfo.asset.esn + '_' + this.props.formInfo.current_form.name, fileType)} exportName={'Export Report '} files={[{ img: 'pdf_icon.png', label: 'PDF', type: 'pdf' },{img: 'excel_icon.png', label: 'Excel', type: 'xls'}]} />}
          updateFormStatus={(data) => this.props.updateFormStatus(data)}
          toggleDownloadedLogs={this.toggleDownloadedLogs}
        />
        <div className="technical-inspection-ee" style={{ margin: "0px"}} >
          <span>
          <h4 className="tech-heading-block flex-centered" style={{ marginTop: "20px",width: "25%" ,display:"inline-flex" }}>
            General Details
            <span onClick={() => { this.togglePrerequisiteModal(); trackActivity('TE003', { event_type: 'Clicked', page_title: 'Gap Report', item_type: 'Read Prerequisite' }) }} className="pre-requisite flex-centered">

              <img src={imgStoragePath + "read_pre_icon.png"} alt="img" className="blue-img" />
              Read Prerequisite
            </span>
          </h4>


          <ul className="list-inline" style={{display:"inline" }}>
                <li className="list-inline-item">
                    <Chip size='small' label={`Total: ${gapReportInvDet?.summary?.total_items || 0}`} style={{background:'#a2a2a2'}} />
                </li>
                <li className="list-inline-item">
                    <Chip size='small' label={`Open: ${gapReportInvDet?.summary?.open_items || 0}`} style={{background:'#4b82de', color:'#fff'}} />
                </li>
                <li className="list-inline-item">
                    <Chip size='small' label={`Closed: ${gapReportInvDet?.summary?.closed_items || 0}`} style={{background:'#269b2b', color:'#fff'}} />
                </li>
                <li className="list-inline-item">
                    <Chip size='small' label={`Reopen: ${gapReportInvDet?.summary?.reopen_items || 0}`} style={{background:'#e86642', color:'#fff'}} />
                </li>
                <li className="list-inline-item">
                    <Chip size='small' label={`Unresolved: ${gapReportInvDet?.summary?.unresolved_items || 0}`} style={{background:'#dbcc28', color:'#fff'}} />
                </li>
            </ul>
            </span>

          {gapReportInvDet.general_details ?
            <GenralDetail03Wrapper
              validation_details={gapReportInvDet?.validation_details}
              gapReportDetails={gapReportInvDet.general_details}
              updateForm={this.updateForm}
              props={this.props}
              error={error && error[0] ? error[0][0] : ''}
            /> : null}
        </div>
        <div className="form-te003-cn">
          {
            techExportLoader ?
              <ListLoader />
              : null
          }

          {llpDetails.list ?
            <div className="edit-view-tech-spec">
              <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab == 1 })}
                      onClick={() => { this.toggle(1);  }}
                    >
                      Record Details
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab ==2 })}
                      onClick={() => { this.toggle(2); }}
                    >
                      LLP Details
                    </NavLink>
                  </NavItem>
              </Nav>
                  {this.state.activeTab == 1 ?
                    <TabPane tabId={1}>
                      <TableUIComp
                        noStickyHeader={true}
                        hover={true}
                        tableBulkOp={ formInfo?.current_form?.permissions?.can_save?<>
                           <BulkOperation importFn={()=> this.setState({importSections:{modal:true}})} importSection={true} addText="+ Add Item" addComponent={() => { this.addDataItem(1) }} export={false} />
                           </>: false}
                        filter={false}
                        tableScroll='none'
                        tableHeight="auto"
                        theads={[{ label: 'Item No', sortKey: '' }, { label: 'Description', sortKey: '' }, { label: 'Remarks', sortKey: '' }, { label: 'Status', sortKey: '' },
                        { label: 'Comments/Notes', sortKey: '' }, { label: 'Ref Documents', sortKey: '' }, { label: 'Operator Comments', sortKey: '' }, { label: 'Action', sortKey: '' }]}
                        bulkOperation={false}
                        content={recordsDetails?.list?.map((gapReport, index) => <DataList userInfo={getLocalStorageInfo().user } srNo = {(recordsDetails?.pagination.per_page * recordsDetails?.pagination.page) - recordsDetails?.pagination.per_page +index +1 } key={index} index={index} content={gapReport} techConstants={techConstants} updateForm={(data)=>this.onChangetabDetails(1, data)} toggleComDelModal={() => this.toggleComDelModal('Are you sure to delete?', index, gapReport.id, 1)} section={1} deleteSectionData={this.props.deleteSectionData} />)}
                        recordsFound={recordsDetails?.list?.length ? false : true}
                  />
                  <Paper>
                  <TablePagination
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    component="div"
                    count={recordsDetails?.pagination?.total}
                    rowsPerPage={recordsDetails?.pagination?.per_page}
                    page={recordsDetails?.pagination?.page - 1}
                    backIconButtonProps={{ 'aria-label': 'previous page' }}
                    nextIconButtonProps={{ 'aria-label': 'next page' }}
                    onChangePage={(event, newPage) => this.changePagination(newPage + 1, 1)}
                    onChangeRowsPerPage={(event) => this.changePerPage(event.target.value, 1)}
                  />
                </Paper>
                </TabPane>
                 :
                this.state.activeTab == 2 ?
                  <TabPane tabId={2}>
                    <TableUIComp
                      noStickyHeader={true}
                      hover={true}
                      tableHeight="auto"
                      tableScroll='none'
                      tableBulkOp={
                        formInfo?.current_form?.permissions?.can_save ? <>
                          <BulkOperation importFn={() => this.setState({ importLppSections: { modal: true } })} importSection={true} addText="+ Add Item" addComponent={() => { this.addDataItem(2) }} export={false} />
                        </>
                          : false}
                      filter={false}
                      theads={[{ label: 'Item No', sortKey: '' }, { label: 'Description', sortKey: '' }, { label: 'P/N', sortKey: '' }, { label: 'S/N', sortKey: '' }, { label: 'Cycle Remaining', sortKey: '' },
                      { label: 'Remarks', sortKey: '' }, { label: 'Status', sortKey: '' }, { label: 'Comments/Notes', sortKey: '' }, { label: 'Ref Documents', sortKey: '' }, { label: 'Operator Comments', sortKey: '' }, { label: 'Action', sortKey: '' }]}
                      bulkOperation={false}
                      pagination={[]}
                      content={llpDetails?.list?.map((gapReport, index) => <LlpDetails userInfo={getLocalStorageInfo().user} srNo = {(llpDetails?.pagination.per_page * llpDetails?.pagination.page) - llpDetails?.pagination.per_page +index +1 } key={index} index={index} content={gapReport} techConstants={techConstants} error={error && error[2] ? error[2][1][index] : ''} toggleComDelModal={() => this.toggleComDelModal('Are you sure to delete?', index, gapReport.id, 2)} updateForm={(data)=>this.onChangetabDetails(2, data)} section={2} deleteSectionData={this.props.deleteSectionData} />)}
                      recordsFound={llpDetails?.list?.length ? false : true}
                    />
                    <Paper>
                    <TablePagination
                      rowsPerPageOptions={[10, 20, 50, 100]}
                      component="div"
                      count={llpDetails?.pagination.total}
                      rowsPerPage={llpDetails?.pagination.per_page}
                      page={llpDetails?.pagination.page - 1}
                      backIconButtonProps={{ 'aria-label': 'previous page' }}
                      nextIconButtonProps={{ 'aria-label': 'next page' }}
                      onChangePage={(event, newPage) => this.changePagination(newPage + 1, 2)}
                      onChangeRowsPerPage={(event) => this.changePerPage(event.target.value, 2)}
                    />
                    </Paper>
                  </TabPane> :
                      null
                }
                <div ref={el => { this.te003End = el; }} />
            </div>
            : null
          }
        </div>
        <DeleteModal isOpen={this.state.preReqModal}
          toggle={() => this.togglePrerequisiteModal()}
          title={'Prerequisite'} className="modal-tform-delete-pictures modal-dialog-centered pre-requsite-modal">
          <PrerequisiteWrapper form={'te003'} togglePreagain={this.togglePreList} />
        </DeleteModal>

        <DeleteModal isOpen={this.state.preReqListModal}
          toggle={() => this.togglePreList()}
          title={'Documents required for engine assessment - Check List'} className="modal-tform-delete-pictures modal-dialog-centered pre-requsite-modal">
          <PrerequisiteSubWrapper />
        </DeleteModal>


        <DeleteModal isOpen={this.state.delCompModal}
          toggle={() => this.toggleComDelModal('')}
          title={this.state.deleteComponentTitle}
          className="modal-tform-delete-pictures modal-dialog-centered">
          <ul className="list-inline">
            <li className="list-inline-item">
              <button type="button" className="btn btn-danger" onClick={() => { this.toggleComDelModal(''); this.props.removeGapReportDet(this.state.deleteComponentIndex, this.state.deleteCompoId, this.state.deleteSection) }}>Yes</button>
            </li>
            <li className="list-inline-item">
              <button type="button" className="btn btn-primary" onClick={() => this.toggleComDelModal('')}>No</button>
            </li>
          </ul>
        </DeleteModal>
        {gapReportInvDet && gapReportInvDet.list ? gapReportInvDet.list.map((data) => {

          return (
            <div></div>
          )
        }) : null}
        <EditFormBar
          titleContent={<div><h3 style={{ textTransform: 'capitalize' }}>Downloaded Logs</h3></div>}
          toggleEditSideBar={this.toggleDownloadedLogs}
          isOpen={this.state.downloadFormLogs}
          style={this.state.downloadFormLogs ? { width: '400px' } : { width: '0px' }}
        >
          {this.state.downloadFormLogs ?
            <DownloadedLogs logs={formInfo.current_form.download_report} />
            : null
          }
        </EditFormBar>
        {
          gepReportLoader || this.state?.listLoader?
            <ListLoader /> : null}
            {
              addItem?.modal ?
              <AddItemModal 
              getResponseBack= {addItem?.tabIndex == 1? this.getRecordsDetailsList: this.getLlpDetailsList}
              workSlug ={this.props.params.workOrderSlug}
              techConstants={techConstants}
              addItem={addItem} 
              toggleModalFn={()=>this.setState({addItem:{modal:false}})}
              />
              : null
            }
             { importSections.modal?
                    <ImportSections 
                        importSections={importSections}
                        toggleModalFn={() => this.setState({importSections:{modal:false}})}
                        getResponseBack={() => console.log('hi')}
                    />:null
                }
                {
                  importLppSections.modal ?
                  <ImportLlpSections 
                  importLppSections={importLppSections}
                  toggleModalFn={() => this.setState({importLppSections:{modal:false}})}
                  getResponseBack={() => console.log('hi')}
                  />
                  : null
                }
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  formInfo: state.FormT001Reducer.formInfo,
  itemSideBar: state.FormTE003Reducer.itemSideBar,
  gapReportInvDet: state.FormTE003Reducer.gapReportInvDet,
  gepReportLoader: state.FormTE003Reducer.gepReportLoader,
  techConstants: state.sharedReducers.techConstants,
  techExportLoader: state.sharedReducers.techExportLoader,
  error: state.FormTE003Reducer.error,
  formStatusLoader: state.FormT001Reducer.formStatusLoader

});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getFormDetails: (type, application) => dispatch(getFormDetailsAc(ownProps, type, application)),
    fetchGapReportFn: () => dispatch(fetchGapReportDetailsAc(ownProps)),
    updateFormStatus: (data) => dispatch(updateFormStatusAc(ownProps, data, 2)),
    exportReport: (url, name, fileType) => dispatch(downLoadTechEngineFormAc(url, ownProps.params.workOrderSlug, fileType, name)),
    fetchTechConstant: (constantTypes) => dispatch(sGlobalConstantAc(constantTypes)),
    removeGapReportDet: (compoIndex, compoId, section) => dispatch(removeGapReportDetAc(ownProps, compoIndex, compoId, section)),
    updateGapReportDetailsFn: (data) => dispatch(updateGapReportDetailsAc(ownProps, data)),
    itemEditSideBar: () => dispatch({
      type: actions.ITEM_SIDE_BAR,
      payload: {}
    }),
    addData: (ID) => dispatch({
      type: actions.ADD_DYNAMIC_TE003_DATA,
      payload: { id: ID, data: recordsData }
    }),
    updateForm: (data) => dispatch({
      type: actions.UPDATE_TE003_FORM,
      payload: data
    }),
    errFn: (errMsg) => dispatch({
      type: actions.ERROR_MESSAGE_03,
      payload: errMsg
    }),
    clearErr: (obj) => dispatch({
      type: actions.CLEAR_ERR_MESSGAE_03,
      payload: obj
    }),


  }
}
export default connect(mapStateToProps, mapDispatchToProps)(InspectionFormTE003);
