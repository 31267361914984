import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { Row, Col } from 'reactstrap';
import moment from 'moment';
import {
  DeleteModal,
  PrerequisiteWrapper,
  EditFormBar,
  SecondaryTechHeaderRH,
  SecondaryTechHeaderMid,
  FormHeader,
  ImageAnnotator,
  DownloadedLogs
} from '../../../Elements';
import CKeditor from "../../../../../shared/CKEditor";
import { downLoadTechEngineFormAc } from '../../../../../shared/actionCreators'
import { imgStoragePath, displayDateFormat } from '../../../../../constants';
import { GeneralDetail, ShopvisitList, AddEditShopvisit } from '../components';
import { ExportFiles, MSNSwitcherHeader, ListLoader, TableUIComp } from '../../../../../shared';
import { toggleAddEditShopvisitAc, fetchshopVisitDetailsAc, UpdateShopVisitDetailsAc, updateShopVisitAc, GenUrlAc, deleteShopVisitAc } from '../actionCreators';
import { getFormDetailsAc, updateFormStatusAc } from '../../T001/actionCreators';
import { UPDATE_GENERAL_DETLS_FORM, UPDATE_SHOPVISIT_FORM, UPDATE_DESCRIPTION, UPDATE_SHOP_VISIT_ERR, GENERAL_ERR_UPDATE } from '../actions';
import { checkString, checkEmail, checkValidDate, checkFloat, checkIntNumber } from '../../../../../formValidator';
import { trackActivity } from '../../../../../utils/mixpanel';
import { getLocalStorageInfo } from '../../../../../utils';

class InspectionFormTE004 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      delModal: false,
      deleteType: '',
      deleteId: '',
      deleteIndex: '',
      preReqModal: false,
      downloadFormLogs: false
    }
  }
  componentDidMount() {
    this.props.getFormDetails(2, 'TE004');
    this.props.fetchshopVisitDetailsFn();
  }
  toggleDownloadedLogs = () => {
    this.setState(prevState => ({
      ...prevState,
      downloadFormLogs: !prevState.downloadFormLogs
    }))
  }
  togglePrerequisiteModal = () => {
    this.setState({
      preReqModal: !this.state.preReqModal,
    });
  }

  updateCkeditor = (evt, type, sectionType) => {
    var newContent = evt.editor.getData();
    this.props.updateGenDetailForm({ type: type, value: newContent, sectionType: sectionType })
  }

  toggleDelModal = (title = null, id = null, type = null, index) => {
    this.setState({
      delModal: !this.state.delModal,
      deleteType: type,
      deleteId: id,
      deleteIndex: index
    });
  }

  updateShopVisitDetailsFn = () => {
    const validateNewInput = {
      report_issued_place: checkString({ value: this.props.shopVisitDetails.details.report_issued_place, required: true, minLength: '', maxLength: '', message: 'Please enter Report Issued Place' }),
      report_date: checkValidDate({ value: this.props.shopVisitDetails.details.report_date, required: true, minLength: '', maxLength: '', message: 'Please select valid Report Date' }),
    };
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      this.props.updateShopVisitDetailsFn(this.props.shopVisitDetails);
    } else {
      this.props.updateShopVisitDetErr(validateNewInput);
    }
  }
  render() {
    const { shopVisitLoader, shopVisitCrud, shopVisitDetails, techExportLoader, formStatusLoader, formInfo } = this.props;
    if (!Object.keys(shopVisitDetails).length) {
      return false
    }
    let userInfo = getLocalStorageInfo().user
    return (
      <div className="technical-inspection-cn">
        <MSNSwitcherHeader
          SecodaryHeaderRH={<SecondaryTechHeaderRH projectInfo={this.props.formInfo.project} />}
          SecondaryHeaderMid={<SecondaryTechHeaderMid projectInfo={this.props.formInfo.project} />}
          activeAsset={this.props.formInfo.asset}
          projectSlug={Object.keys(this.props.formInfo).length ? this.props.formInfo.project.slug : ''}
        />
        <FormHeader
          formInfo={this.props.formInfo}
          saveButton={<Link onClick={() => this.updateShopVisitDetailsFn(this.props.boroscopeInspDet)} className="primary-btn">  {shopVisitLoader ? 'Processing...' : 'Save'}  </Link>}
          exportReport={<ExportFiles exportFile={(fileType) => this.props.exportReport('shop-visit-details', 'ESN_' + this.props.formInfo.asset.esn + '_' + this.props.formInfo.current_form.name, fileType)}
            exportName={'Export Report '}
            files={[{ img: 'pdf_icon.png', label: 'Evaluation Report', type: 'pdf' }, { img: 'pdf_icon.png', label: 'Executive Report', type: 'executive' }, { img: 'pdf_icon.png', label: 'Advisory Report', type: 'advisory' }]} />}
          updateFormStatus={(data) => this.props.updateFormStatus(data)}
          toggleDownloadedLogs={this.toggleDownloadedLogs}
        />
        <div className="technical-inspection-ee">
          <div className="sv-gen-detail">
            {formStatusLoader ||
              techExportLoader ?
              <ListLoader />
              : null
            }
            <h4 className="tech-heading-block flex-centered">
              General Details
              <span onClick={() => { this.togglePrerequisiteModal(); trackActivity('TE004', { event_type: 'Clicked', page_title: 'Borescope Inspection', item_type: 'Read Prerequisite' }) }} className="pre-requisite flex-centered">
                <img src={imgStoragePath + "read_pre_icon.png"} alt="img" className="blue-img" />
                Read Prerequisite
              </span>
            </h4>
            <GeneralDetail
              generalDetails={shopVisitDetails.details}
              updateForm={this.props.updateGenDetailForm}
              detailErr={this.props.detailErr}
            />
          </div>
          <div className="shopvisit-inner-block">
            <h4 className="tech-heading-block flex-centered">
              Background/Documents Reviewed
            </h4>

            <Row>
              <Col md="6">
                <h6>Background</h6>
                <CKeditor
                  events={{ "change": (e) => this.updateCkeditor(e, 'background', 'details') }}
                  content={shopVisitDetails.details.background}
                />
              </Col>
              <Col md="6">
                <h6>Documents Reviewed</h6>
                <CKeditor
                  events={{ "change": (e) => this.updateCkeditor(e, 'documents_reviewed', 'details') }}
                  content={shopVisitDetails.details.documents_reviewed}
                />
              </Col>
            </Row>
          </div>
          <div className="sv-gen-detail">
            <h4 className="tech-heading-block flex-centered ">
              Shop Visits
              {
                userInfo.permission['technical']['projects'].indexOf('C') != '-1'?
                <Link onClick={() => this.props.toggleAddEditShopvisit({ data: {}, flag: true, type: 'add' })} className="add-shop-visit" style={{ cursor: 'pointer', color: '#3f51b5' }}>+ Add Shop Visit</Link>
           :null   }
            </h4>
            {
              shopVisitDetails && shopVisitDetails.shop_visits.length ?
                shopVisitDetails.shop_visits.map((shopVisitData, key) =>
                  <ShopvisitList
                    userInfo={getLocalStorageInfo().user}
                    toggleAddEditShopvisit={this.props.toggleAddEditShopvisit}
                    shopVisitData={shopVisitData}
                    toggleDelModal={this.toggleDelModal} />
                ) : null
            }

          </div>

          <div className="shopvisit-inner-block">
            <h4 className="tech-heading-block flex-centered">
              Executive Summary
            </h4>
            <Row>
              <Col md="6">
                <h6>Observation</h6>
                <CKeditor
                  events={{ "change": (e) => this.updateCkeditor(e, 'executive_summary_observation', 'details') }}
                  content={shopVisitDetails.details.executive_summary_observation}
                />
              </Col>
              <Col md="6">
                <h6>Recommendation</h6>
                <CKeditor
                  events={{ "change": (e) => this.updateCkeditor(e, 'executive_summary_recommendation', 'details') }}
                  content={shopVisitDetails.details.executive_summary_recommendation}
                />
              </Col>
            </Row>
          </div>
          <div className="shopvisit-inner-block">
            <h4 className="tech-heading-block flex-centered">
              Advisory Report
            </h4>
            <Row>
              <Col md="6">
                <h6>Observation</h6>
                <CKeditor
                  events={{ "change": (e) => this.updateCkeditor(e, 'advisory_report_observation', 'details') }}
                  content={shopVisitDetails.details.advisory_report_observation}
                />
              </Col>
              <Col md="6">
                <h6>Recommendation</h6>
                <CKeditor
                  events={{ "change": (e) => this.updateCkeditor(e, 'advisory_report_recommendation', 'details') }}
                  content={shopVisitDetails.details.advisory_report_recommendation}
                />
              </Col>
            </Row>
          </div>
        </div>
        {/* <div className="form-te004-cn">
            TE001
        </div> */}
        <EditFormBar
          titleContent={<div><h3 style={{ textTransform: 'capitalize' }}> {shopVisitCrud.type + ' Shop Visit'} </h3></div>}
          toggleEditSideBar={() => this.props.toggleAddEditShopvisit({ type: 'add', flag: false, data: {} })}
          isOpen={shopVisitCrud.flag}
          style={shopVisitCrud.flag ? { width: '900px' } : { width: '0px' }}
        >
          <div>
            {shopVisitCrud.flag ?
              <AddEditShopvisit
                shopVisitCrud={shopVisitCrud}
                toggleEditSideBar={() => this.props.toggleAddEditShopvisit({ type: 'add', flag: false, data: {} })}
                updateShopVisit={this.props.updateShopVisit}
                generateUrl={this.props.generateUrl}
                updateForm={this.props.updateShopvisitParams}
                updateDescription={this.props.updateDescription}
                toggleDelModal={this.toggleDelModal}
                updateShopVisitErr={this.props.updateShopVisitErr}
                shopVisitErr={this.props.shopVisitErr}
                shopVisitLoader={shopVisitLoader}
              /> : null}
          </div>
        </EditFormBar>

        <DeleteModal isOpen={this.state.delModal} toggle={() => this.toggleDelModal('')} title="Are you sure to delete ?" className="modal-tform-delete-pictures modal-dialog-centered">
          <ul className="list-inline">
            <li className="list-inline-item">
              <button type="button" className="btn btn-danger" onClick={() => { this.toggleDelModal(''); this.props.deleteItems(this.state.deleteId, this.state.deleteType, this.state.deleteIndex) }}>Yes</button>
            </li>
            <li className="list-inline-item">
              <button type="button" className="btn btn-primary" onClick={() => this.toggleDelModal('')}>No</button>
            </li>
          </ul>
        </DeleteModal>
        {/* pre requiste modal */}
        <DeleteModal isOpen={this.state.preReqModal}
          toggle={() => this.togglePrerequisiteModal()}
          title={'Prerequisite'} className="modal-tform-delete-pictures modal-dialog-centered pre-requsite-modal">
          <PrerequisiteWrapper form={'te004'} />
        </DeleteModal>
        <EditFormBar
          titleContent={<div><h3 style={{ textTransform: 'capitalize' }}>Downloaded Logs</h3></div>}
          toggleEditSideBar={this.toggleDownloadedLogs}
          isOpen={this.state.downloadFormLogs}
          style={this.state.downloadFormLogs ? { width: '400px' } : { width: '0px' }}
        >
          {this.state.downloadFormLogs ?
            <DownloadedLogs logs={formInfo.current_form.download_report} />
            : null
          }
        </EditFormBar>
        {
          shopVisitLoader ?
            <ListLoader /> : null
        }
      </div>

    )
  }
}
const mapStateToProps = (state) => ({
  formInfo: state.FormT001Reducer.formInfo,
  shopVisitLoader: state.FormTE004Reducer.shopVisitLoader,
  shopVisitCrud: state.FormTE004Reducer.shopVisitCrud,
  shopVisitDetails: state.FormTE004Reducer.shopVisitDetails,
  shopVisitErr: state.FormTE004Reducer.shopVisitErr,
  techExportLoader: state.sharedReducers.techExportLoader,
  detailErr: state.FormTE004Reducer.detailErr,
  formStatusLoader: state.FormT001Reducer.formStatusLoader
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getFormDetails: (type, application) => dispatch(getFormDetailsAc(ownProps, type, application)),
    exportReport: (url, name, fileType) => dispatch(downLoadTechEngineFormAc(url, ownProps.params.workOrderSlug, fileType, name)),
    toggleAddEditShopvisit: (data) => dispatch(toggleAddEditShopvisitAc(ownProps, data)),
    updateFormStatus: (data) => dispatch(updateFormStatusAc(ownProps, data, 2)),
    fetchshopVisitDetailsFn: () => dispatch(fetchshopVisitDetailsAc(ownProps)),
    updateGenDetailForm: (obj) => dispatch({
      type: UPDATE_GENERAL_DETLS_FORM,
      payload: obj
    }),
    updateShopVisitDetailsFn: (data) => dispatch(UpdateShopVisitDetailsAc(ownProps, data)),
    updateShopVisit: (data) => dispatch(updateShopVisitAc(ownProps, data)),
    generateUrl: (url) => dispatch(GenUrlAc(ownProps, url)),
    updateShopvisitParams: (key, value) => dispatch({
      type: UPDATE_SHOPVISIT_FORM,
      payload: { key: key, value: value }
    }),
    updateDescription: (index, key, value) => dispatch({
      type: UPDATE_DESCRIPTION,
      payload: { index: index, key: key, value: value }
    }),
    deleteItems: (id, type, index) => dispatch(deleteShopVisitAc(ownProps, id, type, index)),
    updateShopVisitErr: (errData) => dispatch({
      type: UPDATE_SHOP_VISIT_ERR,
      payload: errData
    }),
    updateShopVisitDetErr: (errData) => dispatch({
      type: GENERAL_ERR_UPDATE,
      payload: errData
    }),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(InspectionFormTE004);
