import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { Tabs, Tab } from '@material-ui/core';
const STabsLoader = ({count=1}) => {
  return(
    <Tabs
      indicatorColor="primary"
      textColor="primary"
      variant="normal"
    >
      {Array(count).fill(0).map((tab, index) =>
        <Tab key={index} label={<Skeleton variant="rect" width={136} animation='wave' height={24} />} />
      )}
    </Tabs>
  )
}
export default STabsLoader;
