import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter  } from 'reactstrap';
import { Link, browserHistory } from 'react-router';
import { EditFormBar, TextInputField, AircraftBluePrints, FormHeader, SecondaryTechHeaderRH, SecondaryTechHeaderMid, ImageAnnotator, DownloadedLogs, ImageSlider } from '../../../Elements';
import { MSNSwitcherHeader, ListLoader, ExportFiles, LicenceModal } from '../../../../../shared';
import { imgStoragePath } from '../../../../../constants';
import { getFormDetailsAc, updateFormStatusAc } from '../../T001/actionCreators';
import { editFindingImageSaveAc, fetchFindingSectionsAc, auditFindingDetailAc , deleteFindingAc, auditSectionInfoAc, findingAddEditAc, deleteImageAc, editImageAc, changePictureAc, editFindingSaveAc, editFindingImageAc  } from '../actionCreators';
import { AddEditFinding } from '../../T004/components';
import { downLoadTechFormAc, usageDetailAc } from '../../../../../shared/actionCreators'
import EditPictorialFinding from '../components/EditPictorialFinding';
import { AUDITS_FINDING_INFO, AUDITS_SECTIONS_INFO, EDIT_FINDING, UPDATE_IMAGE_IN_EDIT } from '../actions';
import { BUY_LICENCE } from '../../../../../shared/actions'
import { getLocalStorageInfo } from '../../../../../utils'
import 'tui-image-editor/dist/tui-image-editor.css';
import ImageEditor from '@toast-ui/react-image-editor';
import RestrictedAccess from '../../../../../shared_elements/components/RestrictedAccess';
import InvalidUrl from '../../../../../shared_elements/components/InvalidUrl';
class InspectionFormT007 extends Component {
  constructor(props){
    super(props);
    this.state = {
      editSideBar: true,
      inspectionSidebar: false,
      auditReportsCrud: {},
      editImage: '',
      downloadFormLogs: false,
      modalSlider:false,
      fileName:'',
      fileType:'',
      sliderIndex: 0,
      imageSlider: {
        images: [],
        startIndex: 0
      },
    }
  }

  editorRef = React.createRef();

  componentDidMount(){
    this.props.fetchFindingSection();
    this.props.getFormDetails(1, 'T007');
    if(getLocalStorageInfo().user.permission.records && getLocalStorageInfo().user.permission.records.is_saas){
			this.props.usageDetail()
		}
    this.props.closeEditSidebar({flag: false, title: '', sectionId: '', type: 'edit', data: {}})
  }

  editFindingImageSave = (data, type, fileArr, sectionId, files) =>{
    if(getLocalStorageInfo().user.permission.records && getLocalStorageInfo().user.permission.records.is_saas){
      let filesSize = 0
      for(let file of files){
        filesSize += file.size
      }
      if(this.props.storageUsage.used + filesSize >= this.props.storageUsage.available){
        this.props.buyLicence({
          flag: true,
          action: '/licenses/storage',
          content: 'The uploaded file(s) size is not available in storage.'
        })
        return;
      }
    }
    this.props.editFindingImageSave(data, type, fileArr, sectionId)
  }

  componentWillReceiveProps(nextProps){
    this.setState(prevState => ({
      ...prevState,
      auditReportsCrud: nextProps.auditReportsCrud
    }))
  }
  toggleDownloadedLogs = () => {
    this.setState(prevState => ({
      ...prevState,
      downloadFormLogs: !prevState.downloadFormLogs
    }))
  }
  getAnnotatedImage = () =>{
    const editorInstance = this.editorRef.current.getInstance();
    var blob = editorInstance.toDataURL();
    setTimeout(() => {
      this.setState(prevState => ({
        ...prevState,
        editedImage: blob
      }))
      this.props.editFindingSave(blob, this.props.findingCrud.data.imageId)
    }, 1000)
  }

  indexToggleFn = () => {
    this.setState(prevState => ({
      ...prevState,
      indexStatus: !prevState.indexStatus
    }))
  }

  editInspection = () => {
    this.setState(prevState =>({
      ...prevState,
      inspectionSidebar: !prevState.inspectionSidebar,
      editSideBarTitle: 'Edit Image',
    }));
  }

  openSlider = (plates, startIndex,title) => {
    let items = [];
    let position=0;

    plates.map(plate => {
        items.push({
          original: plate.image,
          thumbnail: plate.image,
          title:'',
          id:plate.id,
        })

    });

    items.map((item,index) => {
     if(item.id==plates[startIndex].id){
     position=index;
     }
    });

    this.setState(prevState => ({
      ...prevState,
      modalSlider: !prevState.modalSlider,
      sliderIndex: position,
      imageSlider: {
        ...prevState.imageSlider,
        images: items,
        startIndex: position
      }
    }));
  }

  toggleSlider = () => {
    this.setState({
      modalSlider: !this.state.modalSlider,
      modalTitle: '',
    });
  }

  render(){
    const { findingSections, formInfo, auditFindingCrud, findingCrud, findingLoader, formStatusLoader, formLoader } = this.props;
    console.log('formInfo',formInfo);
    let editorRef = React.createRef();
    const { auditReportsCrud } = this.state;
    var settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1 ,
      slidesToScroll: 1,

    };
    if(window.innerWidth <= 767.98){
      var settingsSecond = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1 ,
        slidesToScroll: 1
      };
    }else{
      var settingsSecond = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 2 ,
        slidesToScroll: 1
      };
    }
    let customClass = '';
    if(Object.keys(findingSections).length && findingSections.list.length){
      switch(findingSections.aircraft_model.blueprint.id){
        case 1 :
        customClass = 'model-two'
        break;
        case 2 :
          customClass = 'model-three'
          break;
        case 3:
          customClass = 'model-four'
          break;
        case 4:
         customClass = 'model-eight'
          break;
        case 5:
          customClass = 'model-eight'
         break;
        case 6:
         customClass = 'model-one'
         break;
        case 7:
          customClass = 'model-one'
          break;
        case 8:
         customClass = 'model-nine'
        break;
        case 9:
          customClass = 'model-nine'
        break;
        case 10:
          customClass='model-seven'
        break;
        case 11:
          customClass ='model-eleven'
        case 12:
          customClass ='model-two'
        break;
        case 13:
          customClass ='model-two'
        break;
        default:
          customClass ='model-one'
      }
    }
    const assetTypes = {
      1: 'MSN',
      3: 'APU',
      4: 'LG',
      5: 'PROPELLER'
    }
    let formViewPermission = formInfo?.list?.length && formInfo?.list?.find(form => form.slug == formInfo?.current_form?.slug)
    let formType =  window.location?.pathname?.toUpperCase()?.includes(formInfo?.current_form?.name)
    console.log('this.props', formInfo);

    return(
      <div style={formViewPermission && formType?{}:{backgroundColor:'#fff', marginLeft:"40px"}} className="technical-inspection-cn">
        {formViewPermission && formType ?
        <MSNSwitcherHeader
          SecodaryHeaderRH = {<SecondaryTechHeaderRH projectInfo={formInfo.project}/>}
          SecondaryHeaderMid = {<SecondaryTechHeaderMid projectInfo={formInfo.project} activeAsset={formInfo.asset}/>}
          activeAsset={formInfo.asset}
          projectSlug = {Object.keys(this.props.formInfo).length ? this.props.formInfo.project.slug: ''}
        />: null}
        {formViewPermission && formType?
        <FormHeader
          formInfo={formInfo}
          exportReport={ <ExportFiles exportFile={(fileType) => this.props.exportReport('inspection-finding-sections',assetTypes[formInfo.asset.asset_type]+'_'+(formInfo.asset.msn ? formInfo.asset.msn : formInfo.asset.serial_number),fileType)} exportName={'Export Report '} files={[{img: 'pdf_icon.png', label: 'PDF', type: 'pdf'},{img:'docx_exp_icon.png', label: 'Document', type:'docx'}]} />}
          updateFormStatus={(data) => this.props.updateFormStatus(data)}
          toggleDownloadedLogs = {this.toggleDownloadedLogs}
         />: null}
         { formStatusLoader ||this.props.techExportLoader || formLoader  ? null : 
         formType ? formViewPermission  ?
        <div className="form-t004-cn">
          { getLocalStorageInfo().user.account_type === 'Acumen' && formInfo?.asset?.asset_type === 1 && (formInfo?.asset?.aircraft_type === 'A320' || formInfo?.asset?.aircraft_type === 'A320-CEO') ?
            <span onClick={()=>browserHistory.push(`/${this.props.params.type}/${this.props.params.aircraft_slug}/technical/${this.props.params.workOrderSlug}/forms/${this.props.params.formSlug}/v_t007`)} style={{position:'absolute', top:'166px', left:'82px', border:'1px solid #fff', padding:'5px 10px', borderRadius:'5px', color:'#fff', cursor:'pointer'}}>Switch To 3D View</span>:null
          }
          
          <div className="indicater-detail-block">
            { Object.keys(findingSections).length && findingSections.list.length ?
              <ul className="list-unstyled indicater-block">
                <li>
                  <span className="poor"></span> Findings ({findingSections['list'].reduce((total, sectionList) => total + sectionList.finding_count, 0)})
                </li>
              </ul>
              :null
            }
          </div>
          <div className={customClass} >
            { Object.keys(findingSections).length && findingSections.list.length ?
              <div className="model-image" >
                <img className="model-image" src={imgStoragePath + findingSections.aircraft_model.blueprint.image} alt="img" />
                { findingSections['list'].map((item, index) =>{

                  return  <AircraftBluePrints
                  bluePrintId={findingSections.aircraft_model.blueprint.id}
                  auditSectionInfo={() => this.props.auditSectionInfo(item)}
                  key={index}
                  sectionDetail={item}
                  formInfo={formInfo}
                />
                }

                )}
              </div>
              :null
            }
          </div>
        </div>
        : 
        Object.keys(formInfo).length ?
        <RestrictedAccess /> : <ListLoader drawer="primary-nav" />
        : formInfo?.list?.length || formInfo.error =='404'? <InvalidUrl
        getResponseBack={()=>browserHistory.push(`/technical/project/view/${formInfo?.project?.slug}`) } /> : 
        //  formInfo.error =='404'?  <RestrictedAccess /> :
        <ListLoader drawer="primary-nav" />
      }
      {formLoader ?<ListLoader drawer="primary-nav" />: null}
        <EditFormBar
          titleContent={ <div><h3>{auditReportsCrud.flag ? auditReportsCrud.title:''}</h3></div>}
          toggleEditSideBar={() => this.props.dispatch({type: AUDITS_SECTIONS_INFO, payload: {type: '', flag: false, data:{}}})}
          isOpen={auditReportsCrud.flag}
          style={auditReportsCrud.flag ? {width: '840px'} : {width: '0px'}}
          >
          { this.props.techExportLoader || formStatusLoader || findingLoader ?<ListLoader drawer="primary-nav" />:null}
          { auditReportsCrud.flag ?
            <EditPictorialFinding
              formInfo={formInfo}
              auditsInfo={auditReportsCrud.data}
              toggleEditSideBar={() => this.props.dispatch({type: AUDITS_SECTIONS_INFO, payload: {type: '', flag: false, data:{}}})}
              findingCrudFn={this.props.findingCrudFn}
              deleteFindingFn = { (findingId) =>  this.props.deleteFindingFn(findingId, auditReportsCrud.sectionId)}
              />:null
          }
        </EditFormBar>
        <EditFormBar
          titleContent={ <div><h3> {auditFindingCrud.type == 'add' ? 'Add' : 'Edit'}  Findings - {auditFindingCrud.flag && auditReportsCrud.title ? auditReportsCrud.title:''} </h3></div>}
          toggleEditSideBar={() => this.props.dispatch({type: AUDITS_FINDING_INFO, payload: {type: '', flag: false, data: {}}})}
          isOpen={auditFindingCrud.flag}
          style={auditFindingCrud.flag ? {width: '840px', overflow:'auto'} : {width: '0px'}}
          >
          { auditFindingCrud.flag ?
            <AddEditFinding
              sectionId = {auditFindingCrud.sectionId}
              title={auditReportsCrud.title}
              auditFindingCrud={auditFindingCrud}
              toggleEditSideBar={() => this.props.dispatch({type: AUDITS_FINDING_INFO, payload: {type: '',flag: false, data: {}}})}
              addEditFinding = {this.props.addEditFindingFn}
              editImage = {this.props.editImageFn}
              changePicture = {this.props.changePicture}
              deleteImgFn = {this.props.deleteImgFn}
              editFindingImage= {this.props.editFindingImage}
              editFindingImageSave = {this.editFindingImageSave}
              addFindingImageSave = {this.props.addFindingImageSave}
              findingLoader = {findingLoader}
              formInfo={formInfo}
              openSlider={this.openSlider}
              />:null
          }
        </EditFormBar>
        <EditFormBar
           titleContent={ <div><h3>Edit Findings{findingCrud.flag && findingCrud.title ? findingCrud.title:''} </h3></div>}
           toggleEditSideBar={() => this.props.dispatch({type: EDIT_FINDING, payload: {title: '', flag: false, imageUrl: ''}})}
           isOpen={findingCrud.flag}
           style={findingCrud.flag ? {width: '840px'} : {width: '0px'}}
         >
           { findingCrud.flag ?
             <ImageEditor
               ref={this.editorRef}
               includeUI={{
                 loadImage: {
                   path: findingCrud.data.imageUrl,
                   name: 'SampleImage',
                 },
                 initMenu: 'filter',
                 uiSize: {
                   width: '800px',
                   height: '700px',
                 },
                 menuBarPosition: 'bottom',
               }}
               cssMaxHeight={500}
               cssMaxWidth={700}
               selectionStyle={{
                 cornerSize: 20,
                 rotatingPointOffset: 70,
               }}
               usageStatistics={false}
             />
             :null
           }
           <div className="edit-sidebar-footer" style={findingCrud.flag ? {width: '840px', position: 'fixed', bottom: '0px', right: '0px',left: (window.innerWidth - 840) + 'px'} : {width: '0px', left: window.innerWidth + 'px'}}>
             <ul className="list-inline">
               <li className="list-inline-item">
                 <button disabled={this.props.findingLoader} onClick={() => this.getAnnotatedImage()} type="submit" className="btn btn-primary">{ this.props.findingLoader ? 'Processing...' : 'Save'}</button>
               </li>
               <li className="list-inline-item">
                 <Link onClick={() => this.props.dispatch({type: EDIT_FINDING, payload: {title: '', flag: false, imageUrl: ''}})}> Cancel</Link>
               </li>
             </ul>
           </div>
         </EditFormBar>
         <EditFormBar
           titleContent= { <div><h3 style={{ textTransform: 'capitalize'}}>Downloaded Logs</h3></div>}
           toggleEditSideBar={this.toggleDownloadedLogs}
           isOpen={this.state.downloadFormLogs}
           style={this.state.downloadFormLogs ? {width: '400px'} : {width: '0px'}}
           >
           {this.state.downloadFormLogs ?
             <DownloadedLogs logs={formInfo.current_form.download_report} />
             :null
           }
         </EditFormBar>
         {
           this.props.buyLicenceModal.flag ?
           <LicenceModal
             planId="2"
             toggleLicence={() => this.props.buyLicence({
               flag: false,
               action: '',
               content: ''
             })}
             licenceModal={this.props.buyLicenceModal}
             />
           :null
         }
         <Modal isOpen={this.state.modalSlider} toggle={this.toggleSlider} className="modal-tform-pictures">
           <ModalHeader toggle={this.toggleSlider}>{this.state.imageSlider.images.length ? this.state.imageSlider.images[this.state.sliderIndex].title:''}</ModalHeader>
           <ModalBody>
             {
               this.state.modalSlider ?
               <ImageSlider getCurrentIndex={this.getCurrentIndex} imageSlider={this.state.imageSlider} />:null
             }

           </ModalBody>
         </Modal>
       </div>
    )
  }
}

const mapStateToProps = (state) => ({
  formInfo: state.FormT001Reducer.formInfo,
  formLoader: state.FormT001Reducer.formLoader,
  findingSections: state.FormT007Reducer.findingSections,
  auditFindingCrud: state.FormT007Reducer.auditFindingCrud,
  auditReportsCrud: state.FormT007Reducer.auditReportsCrud,
  findingCrud: state.FormT007Reducer.findingCrud,
  findingLoader: state.FormT007Reducer.findingLoader,
  techExportLoader:state.sharedReducers.techExportLoader,
  formStatusLoader: state.FormT001Reducer.formStatusLoader,
  buyLicenceModal: state.sharedReducers.buyLicenceModal,
  storageUsage: state.sharedReducers.storageUsage,
});
const mapDispatchToProps = (dispatch,ownProps) =>{
  return{
    getFormDetails: (type, application) => dispatch(getFormDetailsAc(ownProps, type, application)),
    updateFormStatus: (data) => dispatch(updateFormStatusAc(ownProps, data)),
    fetchFindingSection: () => dispatch(fetchFindingSectionsAc(ownProps)),
    findingCrudFn: (type, data, sectionId) => dispatch(auditFindingDetailAc(ownProps, type, data, sectionId)),
    auditSectionInfo: (data) => dispatch(auditSectionInfoAc(ownProps, data)),
    addEditFindingFn: (data, type, fileArr, sectionId) => dispatch(findingAddEditAc(ownProps, data, type, fileArr, sectionId)),
    editFindingImageSave: (data, type, fileArr, sectionId) => dispatch(editFindingImageSaveAc(ownProps, data, type, fileArr, sectionId)),
    addFindingImageSave: (data) => dispatch({
      type: UPDATE_IMAGE_IN_EDIT,
      payload: data
    }),
    exportReport:(url,name,fileType) => dispatch(downLoadTechFormAc(url,ownProps.params.workOrderSlug,fileType,name)),
    deleteImgFn: (picId,  picIndex, type) => dispatch(deleteImageAc(ownProps, picId, picIndex, type)),
    deleteFindingFn: ( findingId, sectionId) => dispatch(deleteFindingAc(ownProps, findingId, sectionId)),
    editImageFn: (picId) => dispatch(editImageAc(ownProps, picId )),
    changePicture: (picId, file, type) => dispatch(changePictureAc(ownProps, picId, file, type)),
    editFindingImage: (data) => dispatch(editFindingImageAc(ownProps, data)),
    editFindingSave: (image, imageId) => dispatch(editFindingSaveAc(ownProps.params, image, imageId)),
    usageDetail: () => dispatch(usageDetailAc()),
    buyLicence: (modalObj) => dispatch({
      type: BUY_LICENCE,
      payload: modalObj
    }),
    closeEditSidebar: (data) => dispatch({
      type: AUDITS_SECTIONS_INFO,
      payload: data
    })
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(InspectionFormT007);
