import React, {Fragment} from 'react';
import moment from "moment"
import MomentUtils from "@date-io/moment"
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from "@material-ui/pickers"
import { backendDateFormat, fieldDateFormat } from "../../constants"
const DateFilter = ({filterOption, filterMenu, keyParam, onFieldChange}) => {
    return(
        <Fragment>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                    label={filterMenu[keyParam].title}
                    format={fieldDateFormat}
                    margin="normal"
                    id={keyParam}
                    fullWidth
                    placeholder={filterMenu[keyParam].title}
                    InputLabelProps={{ shrink: true }}
                    value={filterOption[keyParam] ? filterOption[keyParam]:null}
                    onChange={(data, value) => onFieldChange(keyParam, data ? moment(data).format(backendDateFormat):data)}
                    inputVariant="outlined"
                />
            </MuiPickersUtilsProvider>
        </Fragment>
    )
}
export default DateFilter;