import React, { useEffect, useState, Fragment } from 'react';
import { useSnackbar } from 'notistack';
import { withRouter } from 'react-router';
import { Table, TableBody, TableRow, TableCell, Button, Paper, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Chip, Tooltip } from '@material-ui/core';
import AssignEngineer from './AssignEngineer';
import { CkEditorText, DeletePopUp, PageLoader } from '../../../shared_elements';
import { globalGetService, globalPostService, globalExportService } from '../../../utils_v2/globalApiServices'
import { checkApiStatus, downloadFileType, onCheckPermission, dateTransformAllSet, getLocalStorageInfo } from '../../../utils_v2'
import EditIcon from '@material-ui/icons/Edit';
import { globalDeleteService } from '../../../globalServices';
import { projectsSelfPermission } from '..';
const FormT012 = ({params, project}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [t012Info, setT012Info] = useState({});
    const [deleteEngModal, setDeleteEngModal] = useState({});
    const [rejectionInfo, setRejectionInfo] = useState({modal:false, remarks:''})
    const [engineerAssigned, setAssignedEngineer] = useState({modal:false, selectedEngineer:[], assign_to_all:false})
    useEffect(() => {
        getT012FormInfo();
    },[]);

    const getT012FormInfo = () => {
        globalGetService(`technical/project/${params.project_slug}/misc-form/`)
        .then((response) => {
            if (checkApiStatus(response)) {
                setT012Info(response.data.data);
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
            }
        });
    }
    const onSelectEngineers = (flag, user) => {
        if(flag){
            setAssignedEngineer({...engineerAssigned, selectedEngineer:[...engineerAssigned.selectedEngineer, user.user_id.id]});
        }else{
          setAssignedEngineer({...engineerAssigned, selectedEngineer:engineerAssigned.selectedEngineer.filter(userId => userId !== user.user_id.id)});
        }
      }
    const updateT012FormInfo = () => {
        if(t012Info.title && t012Info.title.trim().length){
            setLoading(true);
            globalPostService(`technical/project/${params.project_slug}/misc-form/`, {title:t012Info.title, comment:t012Info.comments})
            .then((response) => {
                getT012FormInfo()
                setLoading(false);
                if (checkApiStatus(response)) {
                    enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
                }else{
                    enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
                }
            });
        }else{
            enqueueSnackbar('Please enter the Title of the Form', { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
        }
    }
    const exportT012FormInfo = () => {
        setLoading(true);
        globalExportService(`technical/project/${params.project_slug}/misc-form/`, { download: 'pdf' })
        .then((response) => {
            setLoading(false);
            downloadFileType(response, 'Project_T012.', 'pdf')
            enqueueSnackbar('T012 Form Report exported successfully', { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
        });
    }
    const onAssignEngineer = () => {
        setLoading(true);
        globalPostService(`technical/project/${params.project_slug}/assign-user/`, {assign_to_all:false,user_ids:engineerAssigned.selectedEngineer})
        .then((response) => {
            setLoading(false);
            if (checkApiStatus(response)) {
                setAssignedEngineer({engineers:engineerAssigned?.engineers, modal:false, selectedEngineer:[], assign_to_all:false});
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
                getT012FormInfo();
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
            }
        });
    }
    const changeT012FormStatus = (data) => {
        setLoading(true);
        globalPostService(`technical/project/${params.project_slug}/status-update/`, data)
        .then((response) => {
            setLoading(false);
            if (checkApiStatus(response)) {
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
                if(data.status === 3){
                    setRejectionInfo({modal:false, remarks:''})
                }
                getT012FormInfo();
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
            }
        });
    }
    const launchT012Form = () => {
        setLoading(true);
        globalGetService(`technical/project/${params.project_slug}/launch/`)
        .then((response) => {
            setLoading(false);
            if (checkApiStatus(response)) {
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                getT012FormInfo();
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
            }
        });
    }
    const onFieldChange = (e, keyParam, value) => {
        setT012Info({...t012Info, [keyParam]:value})
    }
    const onEditEnginner = (data) => {
        setLoading(true);
        globalGetService(`technical/v2/project/${params.project_slug}/users/dropdown/`, { user_type: 'workorder' })
            .then(response => {
                if (checkApiStatus(response)) {
                    setAssignedEngineer({ ...data, engineers: response.data.data });
                }
                setLoading(false);
            })
    }
    const onDeleteUser = (item)=>{
        setLoading(true)
        globalDeleteService(`technical/project/${params.project_slug}/assign-user/`,{user_id:item?.id})
        .then(response => {
            if (checkApiStatus(response)) {
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                setDeleteEngModal({});
                getT012FormInfo();
            }
            else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
            }
            setLoading(false);
        })
    }
    console.log('t012Info',t012Info?.status?.id == 1);
    console.log('project',project == 2);
    return(
        <Paper className='form-t012-section'>
            {t012Info?.status?.id === 1 && onCheckPermission('project_management', 'workorder', 'U') ? <Button onClick={launchT012Form} style={{float:'right'}} color='primary' size='small' variant='contained'>Launch</Button>: null }
            <div className='form-t012-header'>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell style={{width:'330px'}}>
                                <div style={{width:'90%'}}>
                                    <p>Form Type</p>
                                    <TextField
                                        disabled={t012Info?.permissions?.can_save ? false : true}
                                        id="title"
                                        fullWidth
                                        margin="none"
                                        value={t012Info?.title||''}
                                        InputLabelProps={{shrink: true}}
                                        inputProps={{maxLength: 50}}
                                        onChange={(e) => onFieldChange(e, 'title', e.target.value)}
                                        variant='outlined'
                                    />
                                </div>
                            </TableCell>
                            <TableCell>
                                <div>
                                    <p>Status</p>
                                    <h4>{t012Info?.status?.label||''}</h4>
                                </div>
                            </TableCell>
                            <TableCell>
                                <div>
                                    <p>Last Activity</p>
                                    <h4>{t012Info.updated_at ? dateTransformAllSet(t012Info.updated_at):''}</h4>
                                </div>
                            </TableCell>
                            <TableCell style={{width:'500px'}}>
                                <div>
                                    <p>Form User</p>
                                    <h4>
                                          { t012Info.engineer && t012Info.engineer.length ?
                                            <Fragment><span>{t012Info.engineer.map((item, index) =>
                                                // onCheckPermission('project_management', 'users', 'D') ?
                                                t012Info?.permissions?.can_save && projectsSelfPermission('form','U', project, project?.formPermission?.update?.value||null)  && (project?.status?.id && ![0,7,8].includes(project.status.id))
                                               || (((project?.permission?.update?.value == 1 && getLocalStorageInfo()?.user?.id == project?.created_by?.id) || project?.permission?.update?.value == 2) && t012Info?.status?.id == 1) && [1,2].includes(project.status.id)
                                                ?
                                                    <span><Chip size='small' label={<Tooltip title={item?.name} arrow placement='top'>
                                                        <span style={{ fontSize: '11px' }}>{item?.name}</span>
                                                    </Tooltip>
                                                    } style={{ background: '#e7e7e7', margin: '0px 4px 4px 0px', maxWidth: '206px' }} onDelete={() => setDeleteEngModal({ data: item, modal: true })} /></span>
                                                    :
                                                    <span><Chip size='small' label={<Tooltip title={item?.name} arrow placement='top'>
                                                        <span style={{ fontSize: '11px' }}>{item?.name}</span>
                                                    </Tooltip>
                                                    } style={{background:'#e7e7e7', margin:'0px 4px 4px 0px',  maxWidth:'206px'}}/></span>

                                        )} { t012Info?.permissions?.can_save || (((project?.permission?.update?.value == 1 && getLocalStorageInfo()?.user?.id == project?.created_by?.id) || project?.permission?.update?.value == 2) && t012Info?.status?.id == 1) && [1,2].includes(project.status.id) ?
                                             <EditIcon onClick={() => onEditEnginner({...engineerAssigned, selectedEngineer:t012Info?.engineer.map(item=> item?.id ), modal:true})} style={{fontSize:'15px', cursor:'pointer'}} color="primary" /> :null}</span></Fragment>: 
                                             t012Info?.permissions?.can_save  || (((project?.permission?.update?.value == 1 && getLocalStorageInfo()?.user?.id == project?.created_by?.id) || project?.permission?.update?.value == 2) && t012Info?.status?.id == 1) ? <span style={{cursor:'pointer', color:"#3f51b5", textDecoration:'underline'}} className="user-info"onClick={() => onEditEnginner({...engineerAssigned, modal:true})}>Assign</span>:null
                                        }
                                    </h4>
                                </div>
                            </TableCell>
                            <TableCell>
                                { t012Info?.permissions?.can_export ?
                                    <div>
                                        <Button onClick={exportT012FormInfo} color='primary' size='small' variant='outlined'>Export</Button>
                                    </div>:null
                                }
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </div>
            <div className='form-t012-body'>
                <CkEditorText
                    disabled={t012Info?.permissions?.can_save ? false : true}
                    htmlData={t012Info?.comments||''}
                    onChangeData={(data) => onFieldChange('', 'comments', data)}
                />
            </div>
            <div className='form-t012-footer'>
                { t012Info && Object.keys(t012Info).length ?
                    <ul className='list-inline'>
                        { t012Info?.permissions?.can_save ?
                            <li className='list-inline-item'>
                                <Button onClick={updateT012FormInfo} color='primary' size='small' variant='contained'>SAVE</Button>
                            </li>:null
                        }
                        { t012Info?.permissions?.can_submit ?
                            <li className='list-inline-item'>
                                <Button onClick={() => changeT012FormStatus({status:5})} color='primary' size='small' variant='contained'>Submit</Button>
                            </li>:null
                        }
                        { t012Info?.permissions?.can_accept ?
                            <Fragment>
                                <li className='list-inline-item'>
                                    <Button onClick={() => changeT012FormStatus({status:6})} color='primary' size='small' variant='contained'>Accept</Button>
                                </li>
                                <li className='list-inline-item'>
                                    <Button onClick={() => setRejectionInfo({modal:true, remarks:''})} color='secondary' size='small' variant='contained'>Reject</Button>
                                </li>
                            </Fragment>:null
                        }
                        { t012Info?.permissions?.can_publish ?
                            <li className='list-inline-item'>
                                <Button onClick={() => changeT012FormStatus({status:7})} color='primary' size='small' variant='contained'>Publish</Button>
                            </li>:null
                        }
                    </ul>:null
                }

            </div>
            {
                deleteEngModal?.modal ?
                <DeletePopUp
                modal={deleteEngModal.modal}
                toggleModalFn={() => setDeleteEngModal({modal:false, data:null})}
                title={`Remove Form User`}
                content={<h4>Are you sure you want to remove?</h4>}
                deleteRecordFn={()=>onDeleteUser(deleteEngModal?.data)} />
                : null
            }
            { engineerAssigned.modal ?
                <AssignEngineer
                    form='t012'
                    users={project.engineers ? project.engineers:[]}
                    engineerAssigned={engineerAssigned}
                    toggleModalFn={() => setAssignedEngineer({modal:false, selectedEngineer:[], assign_to_all:false})}
                    selectProjectEngineerFn={(flag, user) => onSelectEngineers(flag, user)}
                    onSelectAllForms ={(flag) => setAssignedEngineer({...engineerAssigned, assign_to_all:flag})}
                    assignUserToForm={onAssignEngineer}
                />:null
            }
            <Dialog
                maxWidth={'md'}
                open={rejectionInfo.modal}
                onClose={() => setRejectionInfo({modal:false, remarks:''})}
                aria-labelledby="scroll-dialog-title"
                className='project-management-modal'
            >
                <DialogTitle id="scroll-dialog-title">
                    Are you sure you want to reject?
                </DialogTitle>
                <DialogContent dividers={true}>
                    <div style={{width:'380px'}}>
                        <TextField
                            disabled={t012Info.permissions && t012Info.permissions?.can_reject ? false:true}
                            id="remarks"
                            label="Reason of Rejection"
                            fullWidth
                            margin="normal"
                            value={rejectionInfo.remarks}
                            multiline
                            maxRows={6}
                            InputLabelProps={{shrink: true}}
                            inputProps={{maxLength: 250}}
                            onChange={(e) => setRejectionInfo({modal:true, remarks:e.target.value})}
                            variant='outlined'
                        />
                        <div style={{textAlign:'center', marginTop:'10px'}}>
                            <ul className='list-inline'>
                                <li className='list-inline-item'>
                                    <Button onClick={() => setRejectionInfo({modal:false, remarks:''})} color='primary' size='small' variant='outlined'>Cancel</Button>
                                </li>
                                <li className='list-inline-item'>
                                    <Button disabled={rejectionInfo.remarks.trim().length === 0} onClick={() => changeT012FormStatus({status:3, remarks:rejectionInfo.remarks})} color='secondary' size='small' variant='contained'>Reject</Button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
            { isLoading ? <PageLoader />:null }
        </Paper>
    )
}
export default withRouter(FormT012);
