import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { useSnackbar } from 'notistack';
import {DropzoneArea} from 'material-ui-dropzone';
import { Button, TextField, Paper, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Checkbox, FormControlLabel, CircularProgress, Tooltip, IconButton } from '@material-ui/core';
import { EmptyCollection, DeletePopUp, PageLoader, Pagination, STableLoader, CkEditorText } from '../../../shared_elements';
import { EditImageDialog, ImageSlider } from '../../Elements';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import { globalDeleteService, globalGetService, globalPostService } from '../../../utils_v2/globalApiServices';
import { checkApiStatus, dataURItoBlob } from '../../../utils_v2';
const disassemblyObj = {
    title:'',
    remarks:'',
    files:[]
}
const UploadFindings = ({params, uploadFindings, toggleModalFn, getResponseBack}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false)
    const [files, setFiles] = useState([]);
    const onUploadPictures = () => {
        let formData = new FormData();
        for(let i=0;i < files.length;i++){
            formData.append(`files_${i+1}`, files[i])
        }
        setLoading(true);
        globalPostService(`technical/v2/workorder/${params.workOrderSlug}/table-inspections/disassembly-findings/${uploadFindings.data.id}/`, formData)
        .then(response => {
            if(checkApiStatus(response)){
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                toggleModalFn(); getResponseBack();
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
            }
            setLoading(false);
        })
    }
    return(
        <Dialog
            open={uploadFindings.modal}
            onClose={toggleModalFn}
            className='project-management-modal'
            maxWidth='md'
        >
            <DialogTitle id="scroll-dialog-title">
                Upload Finding(s)
            </DialogTitle>
            <DialogContent dividers={true}>
                <Grid container spacing={1}>
                    <Grid item md={12}>
                        <div>
                            <DropzoneArea
                                filesLimit={10}
                                acceptedFiles={['image/*']}
                                showPreviewsInDropzone={false}
                                useChipsForPreview={true}
                                showPreviews={ true }
                                dropzoneText="Drag & Drop Pictures Here or Click"
                                dropzoneClass="drag-drop-cnt"
                                maxWidth="sm"
                                onChange={(files) => setFiles(files)}
                                showAlerts={['error', 'info']}
                                alertSnackbarProps={{anchorOrigin: {vertical:'top', horizontal:'right'}}}
                            />
                        </div>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} onClick={toggleModalFn} color="primary" size="small">Cancel</Button>
                {files?.length ?
                    <Button disabled={isLoading} onClick={onUploadPictures} variant="contained" color="primary" size="small">
                        {isLoading ? <CircularProgress size={20} /> : 'Upload' }
                    </Button>:null
                }
            </DialogActions>
        </Dialog>
    )
}
const AddEditDisassemblyFind = ({params, toggleModalFn, addEditDisassembly, getResponseBack}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false)
    const [error, setError] = useState({});
    const [disassembly, setDisassembly] = useState(addEditDisassembly.data);
    const onFieldChange = (key, value) => {
      setDisassembly({...disassembly, [key]:value})
    }
    const onAddEditDisassembly = () => {
        let validationInputs = {
            title:disassembly?.title?.trim()?.length ? '':'Please enter Title',
        }
        if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
            setLoading(true);
            globalPostService(`technical/v2/workorder/${params.workOrderSlug}/table-inspections/disassembly-findings/`, disassembly)
            .then(response => {
                if(checkApiStatus(response)){
                    if(disassembly?.files?.length){
                        onUploadPictures(disassembly.files, response.data.data.id, response.data.message)
                    }else{
                        enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                        toggleModalFn(); getResponseBack();
                        setLoading(false);
                    }
                }else{
                    setLoading(false);
                }
            })
        }else{
            setError(validationInputs);
        }
    }
    const onUploadPictures = (files, id, message) => {
        let formData = new FormData();
        for(let i=0;i < files.length;i++){
            formData.append(`files_${i+1}`, files[i])
        }
        setLoading(true);
        globalPostService(`technical/v2/workorder/${params.workOrderSlug}/table-inspections/disassembly-findings/${id}/`, formData)
        .then(response => {
            if(checkApiStatus(response)){
                enqueueSnackbar(message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                toggleModalFn(); getResponseBack();
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
            }
            setLoading(false);
        })
    }
    return(
        <Dialog
            open={addEditDisassembly.modal}
            onClose={toggleModalFn}
            className='project-management-modal'
            maxWidth='md'
        >
            <DialogTitle id="scroll-dialog-title">
                {disassembly.id ? 'Edit':'Add'} Disassembly Finding
            </DialogTitle>
            <DialogContent dividers={true}>
                <div style={{width:'580px'}}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                name="title"
                                label="Title"
                                fullWidth
                                margin="normal"
                                inputProps={{maxLength: 100}}
                                value={disassembly?.title||''}
                                onChange={(e) => onFieldChange('title', e.target.value)}
                                error={error?.title}
                                helperText={error?.title||''}
                                InputLabelProps={{shrink: true}}
                                onFocus={(e) => setError({...error,'title':''})}
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <div className='content-editor'>
                                <label>Description</label>
                                <CkEditorText
                                    htmlData={disassembly?.remarks||''}
                                    onBlurData={(data) => onFieldChange('remarks', data)}
                                />
                            </div>
                        </Grid>
                        { !disassembly.id ?
                            <Grid item md={12}>
                                <div>
                                    <DropzoneArea
                                        filesLimit={10}
                                        acceptedFiles={['image/*']}
                                        showPreviewsInDropzone={false}
                                        useChipsForPreview={true}
                                        showPreviews={ true }
                                        dropzoneText="Drag & Drop Pictures Here or Click"
                                        dropzoneClass="drag-drop-cnt"
                                        maxWidth="sm"
                                        onChange={(files) => setDisassembly({...disassembly, files:files})}
                                        showAlerts={['error', 'info']}
                                        alertSnackbarProps={{anchorOrigin: {vertical:'top', horizontal:'right'}}}
                                    />
                                </div>
                            </Grid>:null
                        }
                    </Grid>
                </div>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} onClick={toggleModalFn} color="primary" size="small">Cancel</Button>
                <Button disabled={isLoading} onClick={onAddEditDisassembly} variant="contained" color="primary" size="small">
                    {isLoading ? <CircularProgress size={20} /> : 'SAVE' }
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const DisassemblyPicture = ({finding, updateSlider, onEdit, onDelete, onEditDescription, canEdit, disabled, deleteBulkPictures, handlePictureChange}) => {
    const [desc, setDesc] = useState(finding?.description||'')
    return(
        <li className="list-inline-item sort-picture-item inspection-picture" style={{width: '225px', height:'246px', zIndex:9, marginBottom:'20px'}}>
            <div className="dropped-pic-card" style={{backgroundSize:'cover',backgroundImage: "url(" + finding.image + ")"}}>
                { canEdit && !disabled ?
                    <span style={{position:'absolute', top:0, left:0, zIndex:11, background:'#fff', border:'1px solid #d7d7d7'}}>
                        <Checkbox
                            size='small'
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            onChange={(e)=> handlePictureChange(e.target.checked)}
                            checked={deleteBulkPictures.pictures.includes(finding.id)}
                        />
                    </span>:null
                }
                <div className="dropped-picture-cta" style={{border:'1px solid #d7d7d7', background:'#fff'}}>
                    
                    <span onClick={(e) => {e.preventDefault(); e.stopPropagation(); updateSlider()}}>
                        <Tooltip title='View Picture(s)' arrow><VisibilityIcon color="primary" fontSize='small'/></Tooltip>
                    </span>
                    {canEdit && !disabled ?
                        <>
                            <span>
                                <Tooltip title='Edit Picture' arrow><EditIcon onClick={(e) => {e.preventDefault(); e.stopPropagation(); onEdit() }} color="primary" fontSize='small' /></Tooltip>
                            </span>
                            <span>
                                <Tooltip title='Delete Picture' arrow><DeleteOutlineIcon onClick={(e) => {e.preventDefault(); e.stopPropagation(); onDelete() }} color="secondary" fontSize='small'/></Tooltip>
                            </span>
                        </>:null
                    }
                </div>
            </div>
            <div className="">
                <TextField
                    disabled={!(canEdit && !disabled)}
                    style={{border:'none'}}
                    id="description"
                    fullWidth
                    margin="none"
                    value={desc}
                    onChange={(e) => setDesc(e.target.value)}
                    onBlur={(e) => onEditDescription(e.target.value)}
                    inputProps={{maxLength: 255}}
                    InputLabelProps={{shrink: true}}
                    variant='outlined'
                />
            </div>
        </li>
    )
}
const DisassemblyFinding = ({params, currentFormDetail, getCurrentFormDetail}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [filter, setFilter] = useState({});
    const [disassemblyInfo, setDisassemblyInfo] = useState({list:[], pagination:null});
    const [addEditDisassembly, setAddEditDisassembly] = useState({data:null, modal:false});
    const [uploadFindings, setUploadFindings] = useState({modal:false, data:null})
    const [deleteDisassembly, setDeleteDisassembly] = useState({data:[], type:'', modal:false});
    const [deletePicture, setDeletePicture] = useState({modal:false, picture:null});
    const [deleteBulkPictures, setDeleteBulkPictures] = useState({modal:false, sectionId:null, pictures:[]});
    const [sliderInfo, setSliderInfo] = useState({modal:false, title:'', images:[], startIndex:null});
    const [imgAnnotation, setImgAnnotation] = useState({modal:false, data:null});
    useEffect(() => {
        getDisassemblyInfo({}, 'skeletonLoader');
    },[]);
    
    const getDisassemblyInfo = (query, loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true):setLoading(true);
        globalGetService(`technical/v2/workorder/${params.workOrderSlug}/table-inspections/disassembly-findings/`, query)
        .then(response => {
            if(checkApiStatus(response)){
              setDisassemblyInfo(response.data.data);
              if(loaderType !== 'skeletonLoader'){
                setDeleteBulkPictures({modal:false, sectionId:null, pictures:[]});
              }
            }
            loaderType === 'skeletonLoader' ? setSkeletonLoader(false):setLoading(false);
        })
    }
    const handleChange = (type, flag, id) => {
        if(type === 'single'){
          setDeleteDisassembly({
                ...deleteDisassembly,
                type:'bulk',
                data: flag ? [...deleteDisassembly.data, id]:deleteDisassembly.data.filter(item =>  item !== id)
            })
        }else{
            let ids = disassemblyInfo.list.map(item => item.id)
            setDeleteDisassembly({
                ...deleteDisassembly,
                type:'bulk',
                data: flag ? ids:[]
            })
        }
    }
    const handlePictureChange = (sectionId, type, flag, id) => {
        if(type === 'single'){
            if(sectionId === deleteBulkPictures.sectionId){
                setDeleteBulkPictures({
                    ...deleteBulkPictures,
                    pictures: flag ? [...deleteBulkPictures.pictures, id]:deleteBulkPictures.pictures.filter(item =>  item !== id)
                })
            }else{
                setDeleteBulkPictures({
                    ...deleteBulkPictures,
                    sectionId,
                    pictures: [id]
                })
            }
        }else{
            let disassembly = disassemblyInfo.list.find(item => item.id === sectionId);
            setDeleteBulkPictures({
                ...deleteBulkPictures,
                sectionId,
                pictures: flag ? disassembly.finding_pictures.map((item) => item.id):[]
            })
        }
    }
    const onDeleteDisassembly = () => {
        setLoading(true);
        globalDeleteService(`technical/v2/workorder/${params.workOrderSlug}/table-inspections/disassembly-findings/`,{ids:deleteDisassembly.data})
        .then(response => {
            if(checkApiStatus(response)){
              setDeleteDisassembly({modal:false, type:'', data:[]});
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                getDisassemblyInfo({}, 'pageLoader');
                getCurrentFormDetail('pageLoader');
            }
            setLoading(false);
        })
    }
    const onDeletePicture = () => {
        setLoading(true);
        globalDeleteService(`technical/v2/workorder/${params.workOrderSlug}/table-inspections/disassembly-findings/picture/${deletePicture.picture.id}/`)
        .then(response => {
            if(checkApiStatus(response)){
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                setDeletePicture({modal:false, picture:null});
                getDisassemblyInfo({page: disassemblyInfo?.pagination?.page||1, per_page:disassemblyInfo?.pagination?.per_page||10},'pageLoader')
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
            }
            setLoading(false);
        })
    }
    const onDeleteBulkPicture = () => {
        setLoading(true);
        globalDeleteService(`technical/v2/workorder/${params.workOrderSlug}/table-inspections/disassembly-findings/${deleteBulkPictures.sectionId}/pictures/`, {ids: deleteBulkPictures.pictures})
        .then(response => {
            if(checkApiStatus(response)){
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                setDeleteBulkPictures({modal:false, pictures:[], sectionId:null});
                getDisassemblyInfo({page: disassemblyInfo?.pagination?.page||1, per_page:disassemblyInfo?.pagination?.per_page||10},'pageLoader')
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
            }
            setLoading(false);
        })
    }
    const updateSlider = (pictures, title, index) => {
        setSliderInfo({modal:true, title: title, startIndex:index, images:pictures.map(item => {return {original:item.image, thumbnail:item.image}}),})
    }
    const annotateGenPicture = (file) => {
        setLoading(true);
        dataURItoBlob(file).then(editedImage => {
            let formData = new FormData();
            formData.append('file', editedImage);
            globalPostService(`technical/v2/workorder/${params.workOrderSlug}/table-inspections/disassembly-findings/picture/${imgAnnotation.data.id}/`, formData)
            .then(response => {
                setLoading(false);
                if(checkApiStatus(response)){
                    enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
                    setImgAnnotation({modal:false, data:null, sectionId:null});
                    getDisassemblyInfo({},'pageLoader')
                }else{
                    enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
                }
            })
        });
    }
    const onEditDescription = (value, id, pictures, sectionId) => {
        let isSave = false;
        const picture = pictures.find((item) => item.id === id);
        if(value?.trim()?.length){
            if(value !== picture.description){
                isSave = true;
            }
        }else{
            if(picture?.description &&  picture?.description?.trim()?.length){
                isSave = true;
            }
        }
        if(isSave){
            globalPostService(`technical/v2/workorder/${params.workOrderSlug}/table-inspections/disassembly-findings/picture/${id}/`, {description:value})
            .then(response => {
                if(checkApiStatus(response)){
                    setDisassemblyInfo({...disassemblyInfo, list:disassemblyInfo.list.map((section) => section.id !== sectionId ? section:{
                        ...section,
                        finding_pictures:section.finding_pictures.map((item) => item.id !== id ? item:{
                            ...item,
                            description:value
                        })
                    })});
                    enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                }else{
                    enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
                }
            })
        }
    }
    const canEdit = currentFormDetail?.current_form?.permissions?.can_save;
    return(
        <div className='engine-te005-form'>
            { skeletonLoader ? <STableLoader count={8} />:
                <>
                    { canEdit ?
                        <div className='flex-centered' style={{marginBottom:'10px'}}>
                            { disassemblyInfo?.list?.length ?
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            size='small'
                                            checked={deleteDisassembly?.data?.length && deleteDisassembly.type !== 'single' ? deleteDisassembly?.data?.length === disassemblyInfo?.list.length :false}
                                            onChange={(e) => handleChange('bulk', e.target.checked)}
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                    }
                                    label="Select All"
                                />:null
                            }
                            <ul className='list-inline' style={{marginLeft:'auto'}}>
                                { deleteDisassembly?.data?.length && deleteDisassembly.type !== 'single' ?
                                    <li className='list-inline-item'>
                                        <Button onClick={() => setDeleteDisassembly({...deleteDisassembly, modal:true})} color='secondary' size='small' variant='contained'>Delete</Button>
                                    </li>:null
                                }
                                <li className='list-inline-item'>
                                    <Button disabled={(deleteDisassembly?.data?.length && deleteDisassembly.type === 'bulk')} onClick={() => setAddEditDisassembly({data:disassemblyObj, modal:true})} color='primary' size='small' variant='contained'>Add Disassembly</Button>
                                </li>
                            </ul>
                        </div>:null
                    }
                    <Paper className='disassembly-findings' style={{height: !disassemblyInfo.list.length ? 'auto':`${window.innerHeight-240}px`,overflow:'auto'}}>
                        <div>
                            {disassemblyInfo.list.map((item, index) => 
                                <Paper square className='disassembly-finding-content'>
                                    <div className='disassembly-finding-header'>
                                        <Grid container spacing={1} alignItems='center'>
                                            <Grid item md={9}>
                                                <div>
                                                    <div style={{marginBottom:'12px'}} className='flex-centered'>
                                                        {canEdit ?
                                                            <Checkbox
                                                                size='small'
                                                                checked={deleteDisassembly.data.includes(item.id) && deleteDisassembly.type === 'bulk'}
                                                                onChange={(e) => handleChange('single', e.target.checked, item.id)}
                                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                                            />:null
                                                        }
                                                        <h4 >{item?.title||''}</h4>
                                                    </div>
                                                    {item?.remarks ? 
                                                        <div dangerouslySetInnerHTML={{ __html: item?.remarks||'' }}></div>:null
                                                    }
                                                </div>
                                            </Grid>
                                            <Grid item md={3}>
                                                { canEdit ?
                                                    <ul className='list-inline'>
                                                        { !(deleteDisassembly?.data?.length && deleteDisassembly.type === 'bulk') ?
                                                            <>
                                                                <li className='list-inline-item'>
                                                                    <Tooltip title='Edit Disassembly' arrow>
                                                                        <IconButton size="small">
                                                                            <EditIcon fontSize='small' color='primary' onClick={() => setAddEditDisassembly({modal:true, data:item})} />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </li>
                                                                <li className='list-inline-item'>
                                                                    <Tooltip title='Delete Disassembly' arrow>
                                                                        <IconButton size="small">
                                                                            <DeleteOutlineIcon onClick={() => setDeleteDisassembly({modal:true, type:'single', data:[item.id]})} fontSize='small' color='secondary' />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </li>
                                                            </>:null
                                                        }
                                                    </ul>:null
                                                }
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <div className='disassembly-finding-body'>
                                        { canEdit ?
                                            <div className='flex-centered' style={{marginBottom:'10px'}}>
                                                {item?.finding_pictures?.length ?
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                size='small'
                                                                checked={deleteBulkPictures.sectionId === item.id ?  deleteBulkPictures.pictures.length === item.finding_pictures.length :false}
                                                                onChange={(e) => handlePictureChange(item.id, 'bulk', e.target.checked)}
                                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                                            />
                                                        }
                                                        label="Select All"
                                                    />:null
                                                }
                                                <ul className='list-inline' style={{marginLeft:'auto'}}>
                                                    { item?.finding_pictures?.length && deleteBulkPictures.sectionId === item.id && deleteBulkPictures.pictures.length ?
                                                        <li className='list-inline-item'>
                                                            <Button size='small' variant='contained' color='secondary' onClick={() => setDeleteBulkPictures({...deleteBulkPictures, modal:true})}>Delete</Button>
                                                        </li>:null
                                                    }
                                                    <li className='list-inline-item'>
                                                        <Button disabled={(deleteDisassembly?.data?.length && deleteDisassembly.type === 'bulk')} onClick={() => setUploadFindings({modal:true, data:{id:item.id}})} size='small' variant='contained' color='primary'>Upload Finding(s)</Button>
                                                    </li>
                                                </ul>
                                            </div>:null
                                        }
                                        {item?.finding_pictures?.length ?  
                                            <ul className='list-inline'>
                                                {item.finding_pictures.map((finding, imgIndex) => 
                                                    <DisassemblyPicture 
                                                        handlePictureChange={(flag) => handlePictureChange(item.id, 'single', flag, finding.id)}
                                                        deleteBulkPictures={deleteBulkPictures}
                                                        disabled={(deleteDisassembly?.data?.length && deleteDisassembly.type === 'bulk')}
                                                        canEdit={canEdit}
                                                        onEditDescription={(value) =>onEditDescription(value, finding.id, item.finding_pictures, item.id)}
                                                        finding={finding}
                                                        onEdit={() => setImgAnnotation({modal:true, data:finding})}
                                                        onDelete={() => setDeletePicture({modal:true, picture:finding})}
                                                        updateSlider={() => updateSlider(item.finding_pictures, item.title, imgIndex)}
                                                    />
                                                )}
                                            </ul>:
                                            <div style={{textAlign:'center'}}><EmptyCollection title='No records found' /></div>
                                        }
                                        
                                    </div>
                                </Paper>
                            )}
                        </div>
                        <Pagination 
                            pagination={disassemblyInfo.pagination}
                            onChangePage={(event, newPage) => getDisassemblyInfo({...filter, page:newPage+1, per_page: disassemblyInfo.pagination.per_page}, 'pageLoader')}
                            onChangeRowsPerPage={(event) => getDisassemblyInfo({...filter, page:1, per_page: event.target.value}, 'pageLoader')}
                        />
                        { !disassemblyInfo.list.length ? <EmptyCollection title="No records found" />:null }
                    </Paper>    
                </>
            }
             { sliderInfo.modal ?
                <ImageSlider
                    sliderInfo={sliderInfo}
                    toggleModalFn={() => setSliderInfo({modal:false, title:'', startIndex:null, images:[]})}
                />:null
            }
            { imgAnnotation.modal ?
                <EditImageDialog
                    title='Edit Disassembly Picture'
                    imgAnnotation={imgAnnotation}
                    saveAnnotateImg={(file) => annotateGenPicture(file)}
                    toggleModalFn={() => setImgAnnotation({modal:false, data:null, sectionId:null})}
                />:null
            }
            { addEditDisassembly.modal ?
                <AddEditDisassemblyFind 
                    params={params}
                    addEditDisassembly={addEditDisassembly}
                    toggleModalFn={() => setAddEditDisassembly({data:null, modal:false})}
                    getResponseBack={() => {getDisassemblyInfo({page: disassemblyInfo?.pagination?.page||1, per_page:disassemblyInfo?.pagination?.per_page||10}, 'pageLoader');getCurrentFormDetail('pageLoader');}}
                />:null
            }
            { uploadFindings.modal ? 
                <UploadFindings 
                    params={params}
                    uploadFindings={uploadFindings}
                    toggleModalFn={() => setUploadFindings({modal:false, data:null})}
                    getResponseBack={() => getDisassemblyInfo({page: disassemblyInfo?.pagination?.page||1, per_page:disassemblyInfo?.pagination?.per_page||10}, 'pageLoader')}
                />:null
            }
            { deleteDisassembly.modal ?
                <DeletePopUp
                    modal={deleteDisassembly.modal}
                    toggleModalFn={() => setDeleteDisassembly({data:[], type:'', modal:false})}
                    title="Delete Disassembly(s)"
                    content={<h4>Are you sure you want to delete?</h4>}
                    deleteRecordFn={onDeleteDisassembly}
                />:null
            }
            { deletePicture.modal ?
                <DeletePopUp
                    modal={deletePicture.modal}
                    toggleModalFn={() => setDeletePicture({picture:null, modal:false})}
                    title="Delete Picture"
                    content={<h4>Are you sure you want to delete?</h4>}
                    deleteRecordFn={onDeletePicture}
                />:null
            }
            { deleteBulkPictures.modal ?
                <DeletePopUp
                    modal={deleteBulkPictures.modal}
                    toggleModalFn={() => setDeleteBulkPictures({pictures:[], sectionId:null, modal:false})}
                    title="Delete Picture(s)"
                    content={<h4>Are you sure you want to delete?</h4>}
                    deleteRecordFn={onDeleteBulkPicture}
                />:null
            }
            { isLoading ? <PageLoader /> :null }
        </div>
    )
}
export default withRouter(DisassemblyFinding);
