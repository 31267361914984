import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { useSnackbar } from 'notistack';
import { Button, TableRow, TableCell, TextField, Paper, Table, TableHead, TableBody, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Checkbox, CircularProgress, Tooltip, IconButton } from '@material-ui/core';
import { EmptyCollection, DeletePopUp, PageLoader, Pagination, STableLoader } from '../../../shared_elements';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import SettingsInputSvideoIcon from '@material-ui/icons/SettingsInputSvideo';
import { globalDeleteService, globalGetService, globalPostService, globalPutService } from '../../../utils_v2/globalApiServices';
import { checkApiStatus } from '../../../utils_v2';
const ConfigDefaultComponents = ({defaultComponents, toggleModalFn, getResponseBack, params}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false)
    const onConfigDefaultComponents = () => {
        globalPostService(`technical/v2/workorder/${params.workOrderSlug}/borescope-components/`, {components:defaultComponents.data})
        .then(response => {
            if(checkApiStatus(response)){
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                toggleModalFn(); getResponseBack();
            }
            setLoading(false);
        })
    }
    return(
        <Dialog
            open={defaultComponents.modal}
            onClose={toggleModalFn}
            className='project-management-modal'
            maxWidth='md'
        >
            <DialogTitle id="scroll-dialog-title">
                Config Default Component Inspection
            </DialogTitle>   
            <DialogContent dividers={true}>
                <div style={{minWidth:'420px'}}>
                    <Grid container spacing={1}>
                        <Grid item md={12}>
                            <Table className='mui-table-format'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Description</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    { defaultComponents.data.map((item) => 
                                        <TableRow>
                                            <TableCell>{item.description}</TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                            { !defaultComponents.data.length ? <EmptyCollection title="No records found" />:null }
                        </Grid>
                    </Grid>
                </div>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} onClick={toggleModalFn} color="primary" size="small">Cancel</Button>
                <Button disabled={isLoading} onClick={onConfigDefaultComponents} variant="contained" color="primary" size="small">
                    {isLoading ? <CircularProgress size={20} /> : 'SAVE' }
                </Button>
            </DialogActions>
        </Dialog>
    )
}
const AddEditCompInspected = ({toggleModalFn, addEditCompInspected, getResponseBack, params}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false)
    const [error, setError] = useState({});
    const [compInspected, setCompInspected] = useState(addEditCompInspected.data);
    const onFieldChange = (key, value) => {
        setCompInspected({...compInspected, [key]:value})
    }
    const onAddEditCompInspected = () => {
        let validationInputs = {
            description:compInspected?.description?.trim()?.length ? '':'Please enter Description',
            status: compInspected?.status?.value ? '':'Please select Status'
        }
        if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
            setLoading(true);
            if(compInspected.id){
                globalPutService(`technical/v2/workorder/${params.workOrderSlug}/borescope-components/${compInspected.id}/`, compInspected)
                .then(response => {
                    if(checkApiStatus(response)){
                        enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                        toggleModalFn(); getResponseBack();
                    }
                    setLoading(false);
                })
            }else{
                globalPostService(`technical/v2/workorder/${params.workOrderSlug}/borescope-components/`, {components:[compInspected]})
                .then(response => {
                    if(checkApiStatus(response)){
                        enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                        toggleModalFn(); getResponseBack();
                    }
                    setLoading(false);
                })
            }
        }else{
            setError(validationInputs);
        }
    }
    return(
        <Dialog
            open={addEditCompInspected.modal}
            onClose={toggleModalFn}
            className='project-management-modal'
            maxWidth='md'
        >
            <DialogTitle id="scroll-dialog-title">
                {compInspected?.id ? 'Edit':'Add'} Component Inspection
            </DialogTitle>
            <DialogContent dividers={true}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <TextField
                            required
                            name="description"
                            label="Description"
                            fullWidth
                            margin="normal"
                            inputProps={{maxLength: 250}}
                            value={compInspected?.description||''}
                            onChange={(e) => onFieldChange('description', e.target.value)}
                            error={error?.description}
                            helperText={error?.description||''}
                            multiline
                            rows={4}
                            InputLabelProps={{shrink: true}}
                            onFocus={(e) => setError({...error,'description':''})}
                            variant='outlined'
                        />
                    </Grid>
                    {(() => {
                        const options = [{label: 'Serviceable', value: 1},{label: 'Unserviceable', value: 2},{label: 'Restricted', value: 3}];
                        return(
                            <Grid item md={12} xs={12}>
                                <Autocomplete
                                    options = {options}
                                    getOptionLabel={option => option.label}
                                    name='status'
                                    value={compInspected.status}
                                    onChange={(e, data) => onFieldChange('status', data)}
                                    renderInput={params => <TextField error={error?.status} helperText={error?.status||''} onFocus={() => setError({...error,'status':''})} {...params} label="Status" margin="normal" fullWidth InputLabelProps={{shrink: true}} required variant='outlined'/>}
                                />
                            </Grid>
                        )
                    })()}
                    <Grid item xs={12}>
                        <TextField
                            name="findings"
                            label="Findings"
                            fullWidth
                            margin="normal"
                            inputProps={{maxLength: 250}}
                            multiline
                            rows={4}
                            value={compInspected?.findings||''}
                            onChange={(e) => onFieldChange('findings', e.target.value)}
                            InputLabelProps={{shrink: true}}
                            variant='outlined'
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} onClick={toggleModalFn} color="primary" size="small">Cancel</Button>
                <Button disabled={isLoading} onClick={onAddEditCompInspected} variant="contained" color="primary" size="small">
                    {isLoading ? <CircularProgress size={20} /> : 'SAVE' }
                </Button>
            </DialogActions>
        </Dialog>
    )
}
const ComponentInspected = ({params, currentFormDetail, getCurrentFormDetail}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [filter, setFilter] = useState({});
    const [componentInspectedInfo, setComponentInspectedInfo] = useState({list:[], pagination:null});
    const [addEditCompInspected, setAddEditCompInspected] = useState({data:null, modal:false});
    const [deleteCompInspected, setDeleteCompInspected] = useState({data:[], modal:false});
    const [defaultComponents, setDefaultComponents] = useState({modal:false, data:[]});
    useEffect(() => {
        getComponentInspected({}, 'skeletonLoader');
    },[]);
    const getComponentInspected = (query, loaderType) => {
        loaderType === 'skeletonLoader'? setSkeletonLoader(true):setLoading(true);
        globalGetService(`technical/v2/workorder/${params.workOrderSlug}/borescope-components/`, query)
        .then(response => {
            if(checkApiStatus(response)){
                setComponentInspectedInfo(response.data.data)
            }
            loaderType === 'skeletonLoader'? setSkeletonLoader(false):setLoading(false);
        })
    }
    const handleChange = (type, flag, id) => {
        if(type === 'single'){
            setDeleteCompInspected({
                ...deleteCompInspected,
                data: flag ? [...deleteCompInspected.data, id]:deleteCompInspected.data.filter(item =>  item !== id)
            })
        }else{
            let ids = componentInspectedInfo.list.map(item => item.id)
            setDeleteCompInspected({
                ...deleteCompInspected,
                data: flag ? ids:[]
            })
        }
    }
    const onDeleteComponentInspected = () => {
        setLoading(true);
        globalDeleteService(`technical/v2/workorder/${params.workOrderSlug}/borescope-components/`, {"ids": deleteCompInspected.data})
        .then(response => {
            if(checkApiStatus(response)){
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
                setDeleteCompInspected({modal:false, data:[]});
                getComponentInspected({}, 'pageLoader');
                getCurrentFormDetail('pageLoader');
            }
            setLoading(false);
        })
    }
    const getDefaultComponents = () => {
        setLoading(true)
        globalGetService(`technical/v2/workorder/${params.workOrderSlug}/borescope-components-config/`)
        .then(response => {
            if(checkApiStatus(response)){
                setDefaultComponents({modal:true, data: response?.data?.data?.list||[]})
            }
            setLoading(false)
        })
    }
    const canEdit = currentFormDetail?.current_form?.permissions?.can_save;
    return(
        <div className='forms-table-layout'>
            { skeletonLoader ? <STableLoader count={5} />:
                <>
                    <Paper className='forms-table-cta' square>
                        <Grid container spacing={1}>
                            <Grid item md={6}></Grid>
                            <Grid item md={6}>
                                { canEdit ?
                                    <ul className='list-inline flex-centered'>
                                        { deleteCompInspected?.data?.length ?
                                            <li className='list-inline-item'>
                                                <Button onClick={() => setDeleteCompInspected({...deleteCompInspected, modal:true})} color='secondary' size='small' variant='contained'>Delete</Button>
                                            </li>:null
                                        }
                                        { !componentInspectedInfo.list.length && componentInspectedInfo.config_exist ?
                                            <li className='list-inline-item'>
                                                <Tooltip title='Config Default Components' arrow><SettingsInputSvideoIcon style={{cursor:'pointer'}} onClick={getDefaultComponents} fontSize='small' color='primary' /></Tooltip>
                                            </li>:null
                                        }
                                        <li className='list-inline-item'>
                                            <Button onClick={() => setAddEditCompInspected({data:{description:'',findings:'', status:null}, modal:true})} color='primary' size='small' variant='contained'>Add Item</Button>
                                        </li>
                                        <li className='list-inline-item' style={{display:'none'}}>
                                            <Button onClick={() => setAddEditCompInspected({data:{}, modal:true})} color='primary' size='small' variant='contained'>Import</Button>
                                        </li>
                                        <li className='list-inline-item' style={{display:'none'}}>
                                            <Button onClick={() => setAddEditCompInspected({data:{}, modal:true})} color='primary' size='small' variant='contained'>Export</Button>
                                        </li>
                                    </ul>:null
                                }
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper className='forms-table-content'>
                        <div style={{height: !componentInspectedInfo.list.length ? 'auto':`${window.innerHeight-290}px`,overflow:'auto', paddingRight:'5px'}}>
                            <Table className='mui-table-format' stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        { canEdit ?
                                            <TableCell style={{width:'40px'}}>
                                                <div style={{width:'40px'}}>
                                                    <Checkbox
                                                        size='small'
                                                        checked={deleteCompInspected?.data?.length ? deleteCompInspected?.data?.length === componentInspectedInfo?.list.length :false}
                                                        onChange={(e) => handleChange('bulk', e.target.checked)}
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                        disabled={!componentInspectedInfo?.list?.length}
                                                    />
                                                </div>
                                            </TableCell>:null
                                        }
                                        <TableCell>SI No.</TableCell>
                                        <TableCell>Description</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Findings</TableCell>
                                        <TableCell align='right'>Action(s)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    { componentInspectedInfo.list.map((item, index) => 
                                        <TableRow key={index}>
                                            { canEdit ?
                                                <TableCell>
                                                    <div style={{width:'40px'}}>
                                                        <Checkbox
                                                            size='small'
                                                            checked={deleteCompInspected.data.includes(item.id)}
                                                            onChange={(e) => handleChange('single', e.target.checked, item.id)}
                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                        />
                                                    </div>
                                                </TableCell>:null
                                            }
                                            <TableCell>
                                                <div style={{width:'80px'}}>{ (componentInspectedInfo?.pagination?.per_page||10) *(componentInspectedInfo.pagination.page-1) + (index+1)}</div>
                                            </TableCell>
                                            <TableCell>
                                                <div style={{width:'280px'}}>{item?.description || '--'}</div>
                                            </TableCell>
                                            <TableCell>
                                                <div style={{width:'100px'}}>{item?.status?.label || '--'}</div>
                                            </TableCell>
                                            <TableCell>
                                                <div style={{width:'280px'}}>{item?.findings || '--'}</div>
                                            </TableCell>
                                            <TableCell align='right'>
                                                { canEdit ? 
                                                    <ul className='list-inline' style={{width:'80px', float:'right'}}>
                                                        <li className='list-inline-item'>
                                                            <Tooltip title='Edit' arrow>
                                                                <IconButton size="small"><EditIcon onClick={() => setAddEditCompInspected({modal:true, data:item})} fontSize='small' color='primary' /> </IconButton>
                                                            </Tooltip>
                                                        </li>
                                                        <li className='list-inline-item'>
                                                            <Tooltip title='Delete' arrow>
                                                                <IconButton size="small">
                                                                    <DeleteOutlineIcon onClick={() =>setDeleteCompInspected({modal:true, data:[item.id]})} fontSize='small' color='secondary' /> 
                                                                </IconButton>
                                                            </Tooltip>
                                                        </li>
                                                    </ul>:null
                                                }
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </div>
                        <Pagination 
                            pagination={componentInspectedInfo.pagination}
                            onChangePage={(event, newPage) => getComponentInspected({...filter, page:newPage+1, per_page: componentInspectedInfo.pagination.per_page}, 'pageLoader')}
                            onChangeRowsPerPage={(event) => getComponentInspected({...filter, page:1, per_page: event.target.value}, 'pageLoader')}
                        />
                        { !componentInspectedInfo.list.length ? <EmptyCollection title="No records found" />:null }
                    </Paper>    
                </>
            }
            { defaultComponents.modal ? 
                <ConfigDefaultComponents 
                    defaultComponents={defaultComponents}
                    toggleModalFn={() => setDefaultComponents({modal:false, data:[]})}
                    getResponseBack={() => {getComponentInspected({}, 'pageLoader');getCurrentFormDetail('pageLoader');}}
                    params={params}
                />:null
            }
            { addEditCompInspected.modal ?
                <AddEditCompInspected 
                    addEditCompInspected={addEditCompInspected}
                    toggleModalFn={() => setAddEditCompInspected({data:null, modal:false})}
                    getResponseBack={() => {getComponentInspected({}, 'pageLoader');getCurrentFormDetail('pageLoader');}}
                    params={params}
                />:null
            }
            { deleteCompInspected.modal ?
                <DeletePopUp
                    modal={deleteCompInspected.modal}
                    toggleModalFn={() => setDeleteCompInspected({data:[], modal:false})}
                    title="Delete Component Inspection(s)"
                    content={<h4>Are you sure you want to delete?</h4>}
                    deleteRecordFn={onDeleteComponentInspected}
                />:null
            }
            { isLoading ? <PageLoader />: null }
        </div>
    )
}
export default withRouter(ComponentInspected);