import React, { useState } from "react";
import { withRouter } from 'react-router';
import { useSnackbar } from 'notistack';
import { Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip, CircularProgress } from '@material-ui/core';
import {SortableContainer, SortableElement, sortableHandle} from 'react-sortable-hoc';
import SortIcon from '@material-ui/icons/Sort';
import {arrayMoveImmutable} from 'array-move';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { checkApiStatus } from '../../../utils_v2';
import { globalPostService } from '../../../utils_v2/globalApiServices';
const DragHandle = sortableHandle(() => <span style={{paddingRight:'10px', display:'inline-block', cursor:'move', width:'10%'}}><DragIndicatorIcon /></span>);
const SortableItem = SortableElement(({section}) => <div className='sort-section-list flex-centered'><DragHandle /> <span style={{width:'80%', fontSize:'13px'}}>{section.name}</span></div>);
const SortableList = SortableContainer(({sections}) => {
    return (
        <Grid item md={12} >
            {sections.map((section, index) => (
                <SortableItem key={`item-${index}`} sortIndex={index} index={index} section={section} style={{zIndex: 100000}} />
            ))}
        </Grid>
    );
});
const SortSections = ({params, assetSections, getResponseBack, toolTipContent, url}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [formLoader, setFormLoader] = useState(true);
    const [sections, setSections] = useState([]);
    const [modal, setModal] = useState(false);
    const onSortEnd = ({oldIndex, newIndex}) => {
        setSections(arrayMoveImmutable(sections, oldIndex, newIndex));
    }
    const onUpdateSort = () => {
        setLoading(true)
        globalPostService(url, sections.map((section,index) => { return {id:section.id,order:index+1}}))
        .then(response => {
            if(checkApiStatus(response)){
                setModal(false);
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                getResponseBack();
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
            }
            setLoading(false)
        })
    }
    return(
        <>
            <Tooltip title={toolTipContent} arrow><SortIcon onClick={() => {setModal(true); setSections(assetSections)}} color='primary' style={{cursor:'pointer'}} /></Tooltip>
            { modal ?
                <Dialog
                    open={modal}
                    onClose={() => setModal(false)}
                    className='project-management-modal'
                    maxWidth='lg'
                >
                    <DialogTitle id="scroll-dialog-title">
                        Sorting
                    </DialogTitle>
                    <DialogContent dividers={true}>
                        <div style={{width:'320px'}}>
                            <Grid container spacing={1}>
                                <SortableList helperClass='sortableHelper' sections={sections} onSortEnd={onSortEnd} useDragHandle />
                            </Grid>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={isLoading} onClick={() => setModal(false)} color='primary' size="small" variant="outlined">Cancel</Button>
                        <Button disabled={isLoading} onClick={onUpdateSort} color='primary' size="small" variant="contained">
                            {isLoading ? <CircularProgress size={24} />:'Save'}
                        </Button>
                    </DialogActions>
                </Dialog>:null
            } 
        </>
    )
}
export default withRouter(SortSections);