import React, { Fragment, isLoading, useState} from 'react';
import { withRouter } from 'react-router';
import moment from "moment"
import { Drawer, Grid, Button, Tooltip, IconButton } from "@material-ui/core"
import FilterListIcon from "@material-ui/icons/FilterList"
import { removeEmptyKey } from "../../utils_v2"
import DateFilter from './DateFilter';
import TextFilter from './TextFilter';
import DropdownFilter from './DropdownFilter';
import FilterOutput from './FilterOutput';
const FilterComponent = ({icon, direction='right', skipKeys=[], tooltipContent='Filter', filter, filterMenu, getResponseBack}) => {
    const [isLoading, setLoading] = useState(false);
    const [filterOption, setFilterOption] = useState({});
    const [isOpen, setOpen] = useState(false)
    const onFieldChange = (keyParam, value) => {
        setFilterOption({...filterOption, [keyParam]:value});
    }
    const onRemoveFilter = (key) => {
        if( key === 'all'){
            setFilterOption({});
            onApplyFilter({});
        }else{
            let newFilter = {...filter};
            delete newFilter[key];
            setFilterOption(newFilter);
            onApplyFilter(newFilter);
        }
    }
    const onApplyFilter = (query) => {
        getResponseBack(query);
    }
    return(
        <Fragment>
            <div className='filter-ui-component flex-centered'>
                <span>
                    <Tooltip title={tooltipContent} arrow>
                        <IconButton
                            size="small"
                            color="primary"
                            onClick={() => {setOpen(true); setFilterOption(filter)}}
                        >
                            {icon ? icon : <FilterListIcon />}
                        </IconButton>
                    </Tooltip>
                </span>
                { filter && Object.keys(filter).length ? 
                    <FilterOutput 
                        filter={filter} 
                        filterMenu={filterMenu} 
                        onRemoveFilter={onRemoveFilter}
                    />: null 
                }
                <Drawer
                    anchor={direction}
                    open={isOpen}
                    onClose={() => setOpen(false)}
                >
                    <div className='filter-ui-drawer' style={{width:'380px'}}>
                        <div className='filter-ui-drawer-header'><h4>Apply Filter</h4> </div>
                        <div className='filter-ui-drawer-body' style={{height: `${window.innerHeight-95}px`}}>
                            <Grid container spacing={1}>
                                {Object.keys(filterMenu).map((keyParam, index) =>
                                    {
                                        if(filterMenu[keyParam].inputType == "text"){
                                            return(
                                                <Grid item xs={12} key={index}>
                                                    <TextFilter
                                                        filterOption={filterOption}
                                                        filterMenu={filterMenu}
                                                        keyParam={keyParam}
                                                        onFieldChange={onFieldChange}
                                                    />
                                                </Grid>
                                            )
                                        }
                                        if(filterMenu[keyParam].inputType == "dropdown"){
                                            return(
                                                <Grid item xs={12} key={index}>
                                                    <DropdownFilter
                                                        filterOption={filterOption}
                                                        filterMenu={filterMenu}
                                                        keyParam={keyParam}
                                                        onFieldChange={onFieldChange}
                                                    />
                                                </Grid>
                                            )
                                        }
                                        if(filterMenu[keyParam].inputType == "date"){
                                            return(
                                                <Grid item xs={12} key={index}>
                                                    <DateFilter
                                                        filterOption={filterOption}
                                                        filterMenu={filterMenu}
                                                        keyParam={keyParam}
                                                        onFieldChange={onFieldChange}
                                                    />
                                                </Grid>
                                            )
                                        }
                                    }
                                )}
                            </Grid>
                        </div>
                        <div className='filter-ui-drawer-footer'>
                            <ul className='list-inline'>
                                { filterOption && Object.keys(removeEmptyKey(filterOption)).length ?
                                    <li className='list-inline-item'>
                                        <Button onClick={() => {onApplyFilter(filterOption);setOpen(false)}} color='primary' variant='contained' size='small'>Apply</Button>
                                    </li>:null
                                }
                                <li className='list-inline-item'>
                                    <Button onClick={()=>setOpen(false)} color='primary' variant='outlined' size='small'>Cancel</Button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </Drawer>
            </div>
        </Fragment>
    )
}
export default withRouter(FilterComponent);