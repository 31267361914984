import React from 'react';
import {Paper} from '@material-ui/core';
import moment from 'moment';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
require('highcharts/modules/exporting')(Highcharts);
const TaskStatusChart = ({filesCount, graphPieData}) => {
  return(
    <Paper>
      <HighchartsReact
        highcharts={Highcharts}
        options={graphPieData}
      />
    </Paper>
  )
}
export default TaskStatusChart;
