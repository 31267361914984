import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { TableRow, TableCell } from '@material-ui/core';
import deleteIcon from '../../shared/assets/img/delete_icon.png';
import editIcon from '../../shared/assets/img/edit_icon_mui.png';
import moment from 'moment'
const TagsList = ({item, editTag}) => {
  return(
    <TableRow hover tabIndex={-1} className="tags-listing-row">
        <TableCell>{item.id}</TableCell>
        <TableCell>{item.name}</TableCell>
        <TableCell>{item.created_by && item.created_by.name ? item.created_by.name : '--'}</TableCell>
        <TableCell>{moment.utc(item.created_at).format('DD-MM-YYYY HH:MM A')} UTC</TableCell>
        <TableCell>{moment.utc(item.updated_at).format('DD-MM-YYYY HH:MM A')} UTC</TableCell>
        <TableCell>
          <img className="delete-icon" onClick={() => editTag()} src={editIcon} alt="Delete Icon" style={{marginRight: '10px', cursor: 'pointer'}}/>
          <img className="delete-icon" src={deleteIcon} alt="Delete Icon" style={{display: 'none'}}/>
        </TableCell>
    </TableRow>
  )
}
export default TagsList;
