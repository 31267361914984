import React from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router';
import TableList from './TableList';
const PageNotFound = (props) => {
  return(
    <Row>
      <Col xs="12" style={{minHeight: '90vh', position: 'relative'}}>
        <div className="page-not-found">
          <h1>404</h1>
          <h4>Page Not Found :(</h4>
          <Link className="start-over btn btn-default" exact to="/technical/projects">Start Over Here!</Link>
        </div>
      </Col>
    </Row>
  )
}
export default PageNotFound
