export const configObj = [{
    columns: [['A', 'B'], [], []],
    starting_row: '',
    end_row: '',
    seat_type: '',
    section_order: '',
  }];
  export const pilotConfig = [
    {
      seat_number: 'FO',
      seat_type : 0,
      row_number: 6,
      interior_inspection_id:  null
      },
      {
        seat_number: 'C',
        seat_type : 0,
        row_number: 4,
        interior_inspection_id:  null
      },
      {
        seat_number: 'F OB',
        seat_type : 0,
        row_number: 5,
        interior_inspection_id:  null
      },
      {
        seat_number: 'S OB',
        seat_type : 0,
        row_number: 7,
        interior_inspection_id:  null
      },
  ];
  