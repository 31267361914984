import React, { Component } from 'react';
import {  Row, Col } from 'reactstrap';
import { Link } from 'react-router';
import { TextInputField,DateInputField, GetInputField } from '../../../Elements';
import { imgStoragePath, displayDateFormat } from '../../../../../constants';
import editIcon from '../../../../../shared/assets/img/edit_icon.svg';
import viewIcon from '../../../../../shared/assets/img/view_icon.svg';

export default class DisassemblyFinding extends Component {
  constructor(props) {
    super(props);
  }


  updateForm = () => {

  }
  render() {
  const { disAssemblyFnding, index, formInfo } = this.props;
  let type = 'edit';
  return(
    <tr>
			<td>{index+1}</td>
			<td>{disAssemblyFnding.title}</td>
			<td dangerouslySetInnerHTML={{__html: disAssemblyFnding.remarks}} ></td>
			<td>
      {/*<img width="12"  style={{ display: 'inline-block', marginRight: '8px'}}
          onClick = {() => this.props.editViewDisAssemBlk(disAssemblyFnding.id,'edit')}
        className="edit-img-blk" src={imgStoragePath+"blue_eye_icon.png"}
        /> */}
        {
          formInfo?.current_form?.permissions?.can_save? 
         <> <img width="12"  style={{ display: 'inline-block', marginRight: '8px'}}
          onClick = {() => this.props.editViewDisAssemBlk(disAssemblyFnding.id,'edit')}
          className="edit-img-blk" src={editIcon}
          />
          <img width="12" onClick = {() => this.props.toggleDelModal(disAssemblyFnding.id, 'dissasembly', index)} className="" src={imgStoragePath+"delete_red.png"}/>
          </> : null}
          </td>

      </tr>
    );
  }
}
