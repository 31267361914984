import React from 'react';
import { Row, Col } from 'reactstrap';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import { getLocalStorageInfo } from '../../../utils';
class DropDownField extends React.Component {
  constructor(props){
    super(props);
    this.state ={
      value: ''
    }
  }
  // componentDidMount(){
  //   this.setState({
  //     value: this.props.value
  //   })
  // }
  // componentWillReceiveProps(nextProps){
  //   if(this.props.value != nextProps.value){
  //     this.setState({
  //       value: nextProps.value
  //     })
  //   }
  // }
  onChange = (value) => {
    this.props.updateForm(value);
  }
  // onBlur = () => {
    // this.props.updateForm(this.state.value);
  // }
  render(){
    let userInfo= getLocalStorageInfo().user
    const { type, isDisabled } = this.props;
    if(this.state.type == ''){
      return null
    }
    return(
        <div className={`tech-sum-item  ${this.props.className}`}>
          { this.props.children ?
            <label className="label">
              {this.props.children}
              { this.props.required ? <sup className="reqStar"> * </sup> :null}
            </label>
            :null
          }
          {type == 'view' ?
            <h6>{this.props.dropDownValue && this.props.dropDownValue != '' ? this.props.dropDownValue: this.props.value ? this.props.value:'--'}</h6> :
            <Select
              name="form-field-name"
              placeholder={this.props.placeholder ? this.props.placeholder : ''}
              className="custom-select-block"
              value={this.props.value}
              clearable={this.props.clearable=='false'? false :true}
              options = {this.props.options}
              labelKey={this.props.labelKey ? this.props.labelKey:'label'}
              valueKey={this.props.valueKey?this.props.valueKey: 'value'}
              multi={this.props.multiple}
              disabled = {isDisabled? isDisabled : false}
              onBlur={this.onBlur}
              onChange={(target) => this.onChange(target ? target[this.props.valueKey?this.props.valueKey:'value']:'')}
            />
          }
          {this.props.error != '' ? <h6 className="error-msg">{this.props.error}</h6> : null}
        </div>
    )
  }
}
export default DropDownField;
