import React from 'react';
import { Link } from 'react-router';
const CrewSeatConfig = ({ seatWindFlag, existingFinding, seatarr, addSeatsFn, pilotSeats }) => {
  const getCrewSeatClasses = (seatType) => {
    return existingFinding.seats && existingFinding.seats.filter(findingData => (findingData.status === 0 && findingData.seat_number == seatType)).length && seatarr.filter(seat => seat.seat_number == seatType).length && [seatarr.filter(seat => seat.seat_number == seatType)[0]['seat_number']].includes(seatType) ? 'disabled-seat current' : existingFinding.seats && existingFinding.seats.filter(findingData => (findingData.status === 0 && findingData.seat_number == seatType)).length ? 'disabled-seat' : seatarr.filter(seat => seat.seat_number == seatType).length && [seatarr.filter(seat => seat.seat_number == seatType)[0]['seat_number']].includes(seatType) ? 'active current enabled-seat ' : existingFinding.seats && existingFinding.seats.filter(findingData => findingData.seat_number == (seatType) && findingData.findings.length).length && [existingFinding.seats.filter(findingData => findingData.seat_number == (seatType))[0]['seat_number']].includes((seatType)) ? 'has-finding findings-found enabled-seat' : 'enabled-seat';
  }
  return (
    <div className="background-image-block" style={{ backgroundImage: 'url(https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/img/head.png)', width: '700px', backgroundPositionX: '16px' }}>
      <ul className="list-unstyled cockpit-seats">
        <li className="cls-seat" title="" data-original-title="First officer">
          <Link
            style={seatWindFlag ? { opacity: 1, pointerEvents: 'auto' } : { opacity: '0.5', pointerEvents: 'none' }}
            onClick={() => addSeatsFn(pilotSeats[0])}
            className={getCrewSeatClasses('FO')}
          >
            FO
          </Link>
        </li>
        <li className="o2-seat  " data-original-title="Captain">
          <Link
            style={seatWindFlag ? { opacity: 1, pointerEvents: 'auto' } : { opacity: '0.5', pointerEvents: 'none' }}
            onClick={() => addSeatsFn(pilotSeats[1])}
            className={getCrewSeatClasses('C')}
          >
            C
          </Link>
        </li>
        <li className="o1-seat " data-original-title="First observer">
          <Link
            style={seatWindFlag ? { opacity: 1, pointerEvents: 'auto', width: '45px' } : { opacity: '0.5', pointerEvents: 'none', width: '45px' }}
            onClick={() => addSeatsFn(pilotSeats[2])}
            className={getCrewSeatClasses('F OB')}
          >
            F OB
          </Link>
        </li>
        <li className="fo-seat " data-original-title="Second observer">
          <Link
            style={seatWindFlag ? { opacity: 1, pointerEvents: 'auto', width: '45px' } : { opacity: '0.5', pointerEvents: 'none', width: '45px' }}
            onClick={() => addSeatsFn(pilotSeats[3])}
            className={getCrewSeatClasses('S OB')}
          >
            S OB
          </Link>
        </li>
      </ul>
    </div>
  )
}
export default CrewSeatConfig;
