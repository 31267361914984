import React from 'react';
import moment from 'moment';
import { imgStoragePath, displayDateFormat } from '../../../../../constants';
import editIcon from '../../../../../shared/assets/img/edit_icon.svg';
import viewIcon from '../../../../../shared/assets/img/view_icon.svg';

 const ShopvisitList = (props) => {
	return(
		<ul className="list-inline shop-visit-list flex-not-centered">
			<li className="list-inline-item">
				<label>
				Shop Visit Number
				</label>
				<p>
				{props.shopVisitData.shop_visit_number ? props.shopVisitData.shop_visit_number : '--'}
				</p>
			</li>
			<li className="list-inline-item">
				<label>
				Date of Removal
				</label>
				<p>
				{props.shopVisitData.date_of_removal ? moment(props.shopVisitData.date_of_removal).format(displayDateFormat)    : '--'}

				</p>
			</li>
			<li className="list-inline-item">
				<label>
				Reason for Removal
				</label>
				<p>
				{props.shopVisitData.reason_for_removal ? props.shopVisitData.reason_for_removal : '--'}
				</p>
			</li>
			<li className="list-inline-item">
				<label>
				Shop Facility
				</label>
				<p>
				{props.shopVisitData.shop_facility ? props.shopVisitData.shop_facility : '--'}

				</p>
			</li> 
			<li className="list-inline-item">
				<label>
				Engine Induction Date
				</label>
				<p>
				{props.shopVisitData.induction_date ?  moment(props.shopVisitData.induction_date).format(displayDateFormat)  : '--'}
				</p>
			</li>
			{
				props.userInfo.permission['technical']['projects'].indexOf('C') != '-1'?
				<li className="list-inline-item img-action-blk flex-centered">
				<img style={{ cursor: 'pointer'}} onClick={() => props.toggleAddEditShopvisit({data: props.shopVisitData, flag: true, type: 'edit' })} src={editIcon} alt="img" width="15" className="edit-img" />               
				<img style={{ cursor: 'pointer'}}  onClick = {() => props.toggleDelModal('', props.shopVisitData.id, 'shopVisit')} src={imgStoragePath + "delete_red.png"} alt="img" width="15" className="blue-img" />
			</li>
			: null}
		</ul>
	)
}

export default ShopvisitList;