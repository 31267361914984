import React from 'react';
import { Grid, TextField, Tooltip, IconButton } from '@material-ui/core'
import EmptyCollection from './EmptyCollection';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
const WorksScopeTable = ({workScopes, flag, addWorkScopeModuleFn, updateWorkScope, addSBWorkScopeModuleFn, addWorkScopeItem, deleteWorkScopeItem}) => {
  return(
    <div className="engine-workscope-module-table">
      <div>
        <Grid container spacing={0} className="workscope-table-heading">
          <Grid item xs={2} className="flex-centered border-right border-left">
            <h6><span>Major Module</span></h6>
          </Grid>
          <Grid item xs={10} className="flex-centered padding-0">
            <Grid container spacing={0} className="workscope-sub-row margin-0">
              <Grid item xs={3} className="submodule-col flex-centered border-right">
                <h6>Sub Module</h6>
              </Grid>
              <Grid item xs={9} className="flex-centered padding-right-0">
                <Grid container spacing={0} className="workscope-sub-row flex-not-centered margin-0">
                  <Grid item xs={2} className="item-col flex-centered border-right">
                    <h6>Module No</h6>
                  </Grid>
                  <Grid item xs={5} className="item-col flex-centered border-right">
                    <h6>Module Description</h6>
                  </Grid>
                  <Grid item xs={4} className="item-col workscope-level border-right">
                    <h6 className="text-center">Workscope Level</h6>
                    <Grid container spacing={0}>
                      <Grid item xs={6} className="item-col border-right">
                        <h6>Initial</h6>
                      </Grid>
                      <Grid item xs={6} className="item-col">
                        <h6>Final</h6>
                      </Grid>
                    </Grid>
                  </Grid>
                  {flag ?
                    <Grid item xs={1} className="border-right"></Grid> : null
                  }
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div className="engine-workscope-table">
          {  workScopes.length ? workScopes.map((workScope, index) =>
            <Grid container spacing={0} className="workscope-row">
              <Grid item xs={2} className="module-col border-left border-right">
                <div className="module-content">
                  { flag ?
                    <>
                      <TextField
                        id={`name-${index}`}
                        fullWidth
                        margin="normal"
                        value={workScope.name}
                        InputLabelProps={{shrink:true}}
                        onChange={(e) => updateWorkScope(e, 'name', e.target.value, 'majorModule', index)}
                      />
                    <span style={{cursor: 'pointer'}} onClick={() => addSBWorkScopeModuleFn(index)} className="add-new-row">+ Add a Sub Module</span>
                    </> :  workScope.name ? workScope.name : '--'
                  }

                </div>
              </Grid>
              <Grid item xs={10} className="padding-0">
                {workScope.subModule.map((sb, childIndex)=>
                  <Grid container spacing={0} className='workscope-sub-row margin-0'>
                    <Grid item xs={3} className="submodule-col border-right">
                      <div className="submodule-content">
                        { flag ?
                          <TextField
                            id={`sb-name-${childIndex}`}
                            fullWidth
                            margin="normal"
                            value={sb.name}
                            InputLabelProps={{shrink:true}}
                            onChange={(e) => updateWorkScope(e, 'name', e.target.value, 'childModule', index, childIndex)}
                          /> : sb.name ? sb.name :'--'
                        }

                      </div>
                    </Grid>
                    <Grid item xs={9} className="padding-right-0">
                      { sb.items.map((item, itemIndex) =>
                        <div>
                          <Grid container spacing={0} className="workscope-item-row flex-not-centered margin-0">
                            <Grid item xs={2} className="item-col flex-centered border-right" >
                              <div className="item-content">
                                { flag ?
                                  <>
                                    <TextField
                                      id={`item-name-${itemIndex}`}
                                      fullWidth
                                      margin="normal"
                                      value={item.moduleNo}
                                      InputLabelProps={{shrink: true}}
                                      onChange={(e) => updateWorkScope(e, 'moduleNo', e.target.value, 'itemModule', index,childIndex,itemIndex)}
                                    />
                                    {(sb.items.length - 1) == itemIndex ?
                                      <span onClick={() => addWorkScopeItem(index, childIndex)} className="add-new-row">+Add Another</span>:null
                                    }
                                  </> : item.moduleNo ? item.moduleNo : '--'
                                }

                              </div>
                            </Grid>
                            <Grid item xs={5} className="item-col flex-centered border-right">
                              <div className="item-content">
                                { flag ?
                                  <TextField
                                    id={`item-descriptiom-${itemIndex}`}
                                    fullWidth
                                    margin="normal"
                                    value={item.moduleDescription}
                                    InputLabelProps={{shrink: true}}
                                    onChange={(e) => updateWorkScope(e, 'moduleDescription', e.target.value, 'itemModule', index,childIndex,itemIndex)}
                                  /> :  item.moduleDescription ? item.moduleDescription : '--'
                                }

                              </div>
                            </Grid>
                            <Grid item xs={4} className="item-col workscope-level border-right flex-not-centered">
                              <Grid container spacing={0}>
                                <Grid item xs={6} className="border-right flex-centered">
                                  <div className="item-content">
                                    { flag ?
                                      <TextField
                                        id={`item-initial-${itemIndex}`}
                                        fullWidth
                                        margin="normal"
                                        value={item.initialLevel}
                                        InputLabelProps={{shrink: true}}
                                        onChange={(e) => updateWorkScope(e, 'initialLevel', e.target.value, 'itemModule', index,childIndex,itemIndex)}
                                      /> : item.initialLevel ? item.initialLevel : '--'
                                    }

                                  </div>
                                </Grid>
                                <Grid item xs={6} className="flex-centered">
                                  <div class="item-content">
                                    { flag ?
                                      <TextField
                                        id={`item-final-${itemIndex}`}
                                        fullWidth
                                        margin="normal"
                                        value={item.finalLevel}
                                        InputLabelProps={{shrink: true}}
                                        onChange={(e) => updateWorkScope(e, 'finalLevel', e.target.value, 'itemModule', index,childIndex,itemIndex)}
                                      /> :  item.finalLevel ? item.finalLevel :'--'
                                    }

                                  </div>
                                </Grid>
                              </Grid>
                            </Grid>
                            {flag ?
                              <Grid item xs={1} className="flex-centered text-center">
                                <Tooltip title='Remove' arrow><IconButton size="small"><DeleteOutlineIcon className="del-row" style={{marginLeft:'10px'}} fontSize='small' color='secondary' onClick={() => deleteWorkScopeItem(index, childIndex,itemIndex)} /></IconButton></Tooltip>
                              </Grid> : null
                            }
                          </Grid>
                        </div>
                      )}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
            ) : <EmptyCollection />
          }
      </div>
    </div>
  )
}

export default WorksScopeTable;
