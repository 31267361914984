import { regexConstants, errorMsg1 } from '../../constants/regEx';
export const robberyObj = {
    part_number:'',
    removal_date:null,
    part_description:'',
    part_location:'',
    reason_for_removal:'',
    date_verified_removed_from_aircraft:null,
    date_verified_installed_on_aircraft:null,
    date_verified_in_store_house:null,
    price:'',
    work_card_no:'',
    comments:''
  };
  export const robberyHd=[
    { id: 'part_number', label: 'Part Number', sortOption: true },
    { label: 'Part Description', sortOption: false },
    { id:'part_location', label: 'Location', sortOption: true },
    { label: 'Removal Date', sortOption: false },
    { label: 'Reason For Removal', sortOption: false },
    { label: 'Date Verified Removed from A/C', sortOption: false },
    { label: 'Date Verified in Storehouse', sortOption: false },
    { label: 'Date Verified installed on A/C', sortOption: false },
    { label: 'Price', sortOption: false },
    { label: 'Work Card No.', sortOption: false },
    { label: 'Remarks', sortOption: false },
    { label: 'Action', sortOption: false, actionCell: true }
  ]
  export const robberyFilterOps = {
    part_number: {
      inputType: 'text',
      placeholder: 'Search by Part Number',
      title:'Part Number'
    },
    part_description: {
      inputType: 'text',
      placeholder: 'Search by Part Description',
      title:'Part Description'
    },
    part_location: {
      inputType: 'text',
      placeholder: 'Search by Part Location',
      title:'Part Location'
    }
  }
export const errorCode = {
  part_number:{
    0:'',
    1: 'Please enter part number',
  },
  part_numberObj:{
    required: true,
  }
}
