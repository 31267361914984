import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchReviewDataAc, fetchFormsFeedbackAc, addIssuesAc, fetchIssueDetailAc, changeIssueStatusAc, updateCommentsAc, addCommentsAc, notifyClientAc } from '../actionCreators';
import { imgStoragePath } from "../../../../../constants";
import { ReviewIssueDrawer, ReviewHeader, IssueDetail } from "../components";
import { MSNSwitcherHeader, ListLoader } from "../../../../../shared";
import { SecondaryTechHeaderMid, SecondaryTechHeaderRH, EditFormBar } from "../../../Elements";
import { getFormDetailsAc } from "../../T001/actionCreators";
import { TOGGLE_DETAILS, TOOGLE_ADD_ISSUES, TOOGLE_ISSUES_LIST, TOOGLE_EDIT_ISSUES } from '../actions';
import AddIssues from '../components/AddIssues';
import EditIssues from '../components/EditIssues';
import { trackActivity } from '../../../../../utils/mixpanel';
class ReviewT009 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldName: '',
      robberyId: '',
      editIssue: '',
    };
  }
  componentDidMount() {
    this.props.fetchReviewData();
    this.props.getFormDetails();
    this.props.fetchFormsFeedback();
    trackActivity('ReviewT009', {
      event_type: 'Page Visited',
      page_title: 'ReviewT009'
    })
  }
  toggleComments = (fieldName, id) => {
    this.setState({
      fieldName: fieldName ? fieldName : '',
      robberyId: id ? id : '',
    });
    this.props.toggleComment();
  };
  toggleIssues = () => {
    this.props.toggleIssue();
  };

  toggleEditIssues = () => {
    this.props.toggleEditIssue();
  };

  toggleEdits = (e, issue, status) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({
      editIssue: issue,
    });
    this.props.toggleEditIssue();
  };

  deleteStatus = (e, issue, status) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.reviewIssueStatusChange(issue.location_slug, status, 'delete');
  };

  changeStatus = (e, issue, status) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.reviewIssueStatusChange(issue.location_slug, status);
  };
  changeStatusDetails = (e, location_slug, status) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.reviewIssueStatusChange(location_slug, status, 'details');
  };

  updateCommentStatus = (e, location_slug, commentId) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.updateCommentStatus(location_slug, commentId);
  }

  updateIssues = (value) => {
    this.props.reviewIssueStatusChange(this.state.editIssue.location_slug, value, 'edit');
  }


  updateComments = (value) => {
    let data = {
      description: value,
      form_id: this.props.formInfo.current_form.id,
      robbery_list_id: this.state.robberyId,
      field_name: this.state.fieldName,
    }
    this.props.addIssue(data);
  }


  toggleDetail = (issue) => {
    issue ? this.props.reviewFeedBackDetail(issue.location_slug) : this.props.toggleDetails();
  };

  reviewUIComp = (issue = {}, fieldName, id) => {

    if (issue.order_number == null || issue.order_number == undefined) {
      return (
        <span onClick={() => this.toggleComments(fieldName, id)} className="comment-icon" >
          <img src={imgStoragePath + "cmnt_active.svg"} className="blue-icon" alt="" />
          <img src={imgStoragePath + "cmnt-inactive.svg"} className="grey-icon" alt="" />
        </span>

      )
    } else {
      return (
        <span onClick={() => this.toggleIssues()} className="issue-number" >
          <span>#{issue.order_number}</span>
        </span>
      )
    }
  }
  render() {
    const { t009ReviewList, notifyFlag } = this.props;
    return (
      <div className="technical-review-model">
        {Object.keys(this.props.formInfo).length ?
          <MSNSwitcherHeader
            SecodaryHeaderRH={<SecondaryTechHeaderRH projectInfo={this.props.formInfo.project} />}
            SecondaryHeaderMid={<SecondaryTechHeaderMid projectInfo={this.props.formInfo.project} activeAsset={this.props.formInfo.asset} />}
            activeAsset={this.props.formInfo.asset}
            projectSlug={Object.keys(this.props.formInfo).length ? this.props.formInfo.project.slug : ''}
          /> : null
        }
        <ReviewHeader
          formInfo={this.props.formInfo}
          notifyFlag={notifyFlag}
          notifyClient={this.props.notifyClient}
          workOrderSlug={this.props.params.workOrderSlug}
        />
        {this.props.commentsLoader ? <ListLoader /> : null}
        <div className="form-t009">
          {t009ReviewList.list ? t009ReviewList.list.map((robbery, index) =>
            <div className="robbery-list">
              <p className="serial-number"><span>{index + 1}</span><em>Part Number : {robbery.part_number}</em></p>
              <table>
                <tr>
                  <td>Part Description</td>
                  <td>{robbery.part_description.value}</td>
                  <td>
                    {this.reviewUIComp(robbery.part_description.issue, 'part_description', robbery.id)}
                  </td>
                </tr>
                <tr>
                  <td>Removal Date</td>
                  <td>{robbery.removal_date.value}</td>
                  <td>
                    {this.reviewUIComp(robbery.removal_date.issue, 'removal_date', robbery.id)}
                  </td>
                </tr>
                <tr>
                  <td>Location of the part</td>
                  <td>{robbery.part_location.value}</td>
                  <td>
                    {this.reviewUIComp(robbery.part_location.issue, 'part_location', robbery.id)}
                  </td>
                </tr>
                <tr>
                  <td>Date verified</td>
                  <td>
                    <ul className="list-inline">
                      <li className="list-inline-item">
                        <label>Removed from Aircraft</label>
                        <td>{robbery.date_verified.value.date_verified_removed_from_aircraft}</td>
                      </li>
                      <li className="list-inline-item">
                        <label>Verified in Storehouse</label>
                        <td>{robbery.date_verified.value.date_verified_in_store_house}</td>
                      </li>
                      <li className="list-inline-item">
                        <label>Installed on Aircraft</label>
                        <td>{robbery.date_verified.value.date_verified_installed_on_aircraft}</td>
                      </li>
                    </ul>
                  </td>
                  <td>
                    {this.reviewUIComp(robbery.date_verified.issue, 'date_verified', robbery.id)}
                  </td>
                </tr>
                <tr>
                  <td>Price</td>
                  <td>{robbery.price.value}</td>
                  <td>
                    {this.reviewUIComp(robbery.price.issue, 'price', robbery.id)}
                  </td>
                </tr>
                <tr>
                  <td>Work Card No.</td>
                  <td>{robbery.wcard_number.value}</td>
                  <td>
                    {this.reviewUIComp(robbery.wcard_number.issue, 'wcard_number', robbery.id)}
                  </td>
                </tr>
                <tr>
                  <td>Comments</td>
                  <td>{robbery.comments.value}</td>
                  <td>
                    {this.reviewUIComp(robbery.comments.issue, 'comments', robbery.id)}
                  </td>
                </tr>
              </table>
            </div>
          ) : null}
        </div>
        <a href="javascript:void(0)" className="view-issues-btn" onClick={() => this.toggleIssues()} >
          <img src={imgStoragePath + 'icon_CommentDash.svg'} alt="" />
        </a>
        <EditFormBar
          titleContent={<div><h3 style={{ textTransform: 'capitalize' }}> {'Post Issues'} </h3></div>}
          toggleEditSideBar={() => this.toggleComments()}
          isOpen={this.props.toggleComments}
          style={this.props.toggleComments ? { width: '400px' } : { width: '0px' }}>
          {this.props.toggleComments ?
            <AddIssues
              updateComments={this.updateComments}
              toggleEditSideBar={() => this.toggleComments()}
            /> : null
          }
        </EditFormBar>
        <EditFormBar
          titleContent={<div><h3 style={{ textTransform: 'capitalize' }}> {'Edit Issue'} </h3></div>}
          toggleEditSideBar={() => this.toggleEditIssues()}
          isOpen={this.props.toggleEditIssues}
          style={this.props.toggleEditIssues ? { width: '400px' } : { width: '0px' }}>
          {this.props.toggleEditIssues ?
            <EditIssues
              updateComments={this.updateIssues}
              issueData={this.state.editIssue}
              toggleEditSideBar={() => this.toggleEditIssues()}
            /> : null
          }
        </EditFormBar>
        <EditFormBar
          titleContent={<div><h3 style={{ textTransform: 'capitalize' }}> {'Issues List'} </h3></div>}
          toggleEditSideBar={() => this.toggleIssues()}
          isOpen={this.props.toggleIssues}
          style={this.props.toggleIssues ? { width: '400px' } : { width: '0px' }}>
          {this.props.toggleIssues ?
            <ReviewIssueDrawer
              issueList={this.props.reviewFeedbacksList}
              toggleEditSideBar={() => this.toggleComments()}
              toggleDetailBar={this.toggleDetail}
              changeStatus={this.changeStatus}
              deleteStatus={this.deleteStatus}
              editStatus={this.toggleEdits}
            /> : null}
        </EditFormBar>
        <EditFormBar
          titleContent={<div><h3 style={{ textTransform: 'capitalize' }}> {'Issues Discussion'} </h3></div>}
          toggleEditSideBar={() => this.toggleDetail()}
          isOpen={this.props.toggleDetail}
          style={this.props.toggleDetail ? { width: '400px' } : { width: '0px' }}>
          {this.props.toggleDetail ?
            <IssueDetail
              issueDetailInfo={this.props.issueDetailInfo}
              toggleEditSideBar={() => this.toggleDetail()}
              toggleSideBars={() => this.toggleIssues()}
              addComments={this.props.addComments}
              changeStatus={this.changeStatusDetails}
              updateCommentStatus={this.updateCommentStatus}
            />
            : null}
        </EditFormBar>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  t009ReviewList: state.FormReviewReducer.t009ReviewList,
  reviewFeedbacksList: state.FormReviewReducer.reviewFeedbacksList,
  formInfo: state.FormT001Reducer.formInfo,
  toggleComments: state.FormReviewReducer.toggleComments,
  toggleIssues: state.FormReviewReducer.toggleIssues,
  toggleEditIssues: state.FormReviewReducer.toggleEditIssues,
  toggleDetail: state.FormReviewReducer.toggleDetail,
  issueDetailInfo: state.FormReviewReducer.issueDetailInfo,
  commentsLoader: state.FormReviewReducer.commentsLoader,
  notifyFlag: state.FormReviewReducer.notifyFlag
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchReviewData: () => dispatch(fetchReviewDataAc(ownProps, 't009')),
    fetchFormsFeedback: () => dispatch(fetchFormsFeedbackAc(ownProps, 't009')),
    getFormDetails: () => dispatch(getFormDetailsAc(ownProps)),
    reviewFeedBackDetail: (locationSlug) => dispatch(fetchIssueDetailAc(ownProps, locationSlug, 't009')),
    addIssue: (data) => dispatch(addIssuesAc(ownProps, data, 't009')),
    notifyClient: () => dispatch(notifyClientAc(ownProps)),
    updateCommentStatus: (locationSlug, commentID) => dispatch(updateCommentsAc(ownProps, locationSlug, commentID)),
    addComments: (location_slug, message) => dispatch(addCommentsAc(location_slug, message)),
    reviewIssueStatusChange: (locationSlug, status, flag = '') => dispatch(changeIssueStatusAc(ownProps, locationSlug, status, 't009', flag)),
    toggleComment: () => dispatch({
      type: TOOGLE_ADD_ISSUES,
      payload: {}
    }),
    toggleIssue: () => dispatch({
      type: TOOGLE_ISSUES_LIST,
      payload: {}
    }),
    toggleEditIssue: () => dispatch({
      type: TOOGLE_EDIT_ISSUES,
      payload: {}
    }),
    toggleDetails: () => dispatch({
      type: TOGGLE_DETAILS,
      payload: {}
    }),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ReviewT009);
