import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { Link } from 'react-router';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { DeleteModal, PrerequisiteWrapper, EditFormBar, SecondaryTechHeaderRH, SecondaryTechHeaderMid, FormHeader, ImageAnnotator, DownloadedLogs, ImageSlider } from '../../../Elements';
import CKeditor from "../../../../../shared/CKEditor";
import { GenralDetail01Wrapper, ComponentsInspected, BoroInspectionPictures, BulkOperation, InspectedComponents } from '../components';
import { getFormDetailsAc, updateFormStatusAc } from '../../T001/actionCreators';
import { downLoadTechEngineFormAc, usageDetailAc } from '../../../../../shared/actionCreators';
import { fetchBoroscopeDetailsAc, deleteComponentAc, uploadImageAc, updateBoroscopeDetlsAc, deleteImageAc, saveEditedImageAc, editPlatePicAc } from '../actionCreators';
import { imgStoragePath } from '../../../../../constants';
import { ExportFiles, MSNSwitcherHeader, ListLoader, TableUIComp, LicenceModal } from '../../../../../shared';
import { UPDATE_FORM, ADD_DYNAMIC_COMPONENT, EDIT_INSPECTION_IMAGE, SORT_PICTURES, ERROR_MESSAGE, CLEAR_ERR_MESSGAE } from '../actions';
import { arrayMove } from 'react-sortable-hoc';
import { checkString, checkEmail, checkValidDate } from '../../../../../formValidator';
import { removeEmptyKey } from '../../../../../utils';
import * as actions from '../actions';
import { BUY_LICENCE } from '../../../../../shared/actions';
import { getLocalStorageInfo } from '../../../../../utils';
import { trackActivity } from '../../../../../utils/mixpanel';
import 'tui-image-editor/dist/tui-image-editor.css';
import ImageEditor from '@toast-ui/react-image-editor';
class InspectionFormTE001 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '1',
      preReqModal: false,
      delCompModal: false,
      deleteComponentIndex: '',
      deleteComponentTitle: '',
      deleteCompoId: '',
      componentStruct: {
        'description': "",
        'findings': null,
        'id': null,
        'status': 0
      },
      deleteImageTitle: '',
      delImageModal: '',
      deleteImageId: '',
      deleteImageIndex: '',
      hideActionblock: '',
      classCustom: '',
      downloadFormLogs: false,
      imageSlider: {
        images: [],
        startIndex: 0
      },
      sliderModal: false
    }
  }

  editorRef = React.createRef();

  componentDidMount() {
    this.props.getFormDetails(2, 'TE001');
    this.props.fetchBoroscopeDetailsFn();
    if (getLocalStorageInfo().user.permission.records && getLocalStorageInfo().user.permission.records.is_saas) {
      this.props.usageDetail()
    }
  }

  uploadImageFn = (files) => {
    if (getLocalStorageInfo().user.permission.records && getLocalStorageInfo().user.permission.records.is_saas) {
      let filesSize = 0
      for (let file of files) {
        filesSize += file.size
      }
      if (this.props.storageUsage.used + filesSize >= this.props.storageUsage.available) {
        this.props.buyLicence({
          flag: true,
          action: '/licenses/storage',
          content: 'The uploaded file(s) size is not available in storage.'
        })
        return;
      }
    }
    this.props.uploadImageFn(files)
  }

  openSlider = (pictures, startIndex) => {
    let items = [];
    if (pictures) {
      pictures.map(picture => {
        items.push({ original: picture.image, thumbnail: picture.image })
      });
    }
    this.setState(prevState => ({
      ...prevState,
      sliderModal: !prevState.modal,
      imageSlider: {
        ...prevState.imageSlider,
        images: items,
        startIndex: startIndex
      }
    }));
  }

  toggleDownloadedLogs = () => {
    this.setState(prevState => ({
      ...prevState,
      downloadFormLogs: !prevState.downloadFormLogs
    }))
  }

  toggleComDelModal = (title = null, index = null, compoId = null) => {
    this.setState({
      delCompModal: !this.state.delCompModal,
      deleteComponentTitle: title,
      deleteComponentIndex: index,
      deleteCompoId: compoId
      // picId: id,
      // sectionId: sectionId
    });
  }
  toggleDelImageModal = (title = null, deleteImageId = null, deleteImageIndex = null) => {
    this.setState({
      delImageModal: !this.state.delImageModal,
      deleteImageTitle: title,
      deleteImageId: deleteImageId,
      deleteImageIndex: deleteImageIndex
    });
  }

  togglePrerequisiteModal = () => {
    this.setState({
      preReqModal: !this.state.preReqModal,
    });
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  updateCkeditor = (evt, type) => {
    var newContent = evt.editor.getData();
    this.props.updateForm({ type: type, value: newContent, sectionType: 'genDetails' })
  }

  scrollToBottom() {
    this.tabEnd.scrollIntoView({ behavior: 'smooth' });
  }

  updateForm = (obj) => {
    this.props.updateForm(obj);
    this.props.clearErr({ type: obj.type, sectionType: obj.sectionType, index: obj.index ? obj.index : 0 });
  }

  saveImage = () => {
    const editorInstance = this.editorRef.current.getInstance();
    var blob = editorInstance.toDataURL();
    setTimeout(() => {
      // this.setState(prevState => ({
      //   ...prevState,
      //   editedImage: document.querySelector("#annotator").contentWindow.document.querySelector('#editedImage').getAttribute('src')
      // }))
      this.props.saveEditedImage(this.props.editBoroscpPicCrud.data.data.id, blob)
    }, 1000)
  }

  sortFiles = (args, pictures) => {
    if (args[0].oldIndex != args[0].newIndex) {
      let sortedPictures = [];
      pictures = arrayMove(pictures, args[0].oldIndex, args[0].newIndex);
      pictures.map(picture => sortedPictures.push(picture))
      this.props.sortPictures(sortedPictures);
    }

    this.setState(prevState => ({
      ...prevState,
      classCustom: ''
    }))
  }

  updateBoroscopeDetlsFn = () => {
    let validateNewInput = {};
    validateNewInput = Object.keys(this.props.boroscopeInspDet).map(dataKey => {
      if (dataKey == 'borescope_details') {
        return (
          [dataKey] = [{
            ...validateNewInput,
            type_of_report: checkString({ value: this.props.boroscopeInspDet.borescope_details.type_of_report, required: true, minLength: '', maxLength: '', message: 'Please select Report Type' }),
            place_of_inspection: checkString({ value: this.props.boroscopeInspDet.borescope_details.place_of_inspection, required: true, minLength: '', maxLength: '', message: 'Please enter Place of Inspection' }),
            amm_revision_number: checkString({ value: this.props.boroscopeInspDet.borescope_details.amm_revision_number, required: true, minLength: '', maxLength: '', message: 'Please enter AMM Revision number' }),
            amm_revision_date: checkValidDate({ value: this.props.boroscopeInspDet.borescope_details.amm_revision_date, required: true, minLength: '', maxLength: '', message: 'Please select AMM  Revision Date' }),
            date_of_inspection_from: checkValidDate({ value: this.props.boroscopeInspDet.borescope_details.date_of_inspection_from, required: true, minLength: '', maxLength: '', message: 'Please select Date of Inspection From' }),
            date_of_inspection_to: checkValidDate({ value: this.props.boroscopeInspDet.borescope_details.date_of_inspection_to, required: false, minLength: '', maxLength: '', message: 'Please select Date of Inspection To' }),
            report_date: checkValidDate({ value: this.props.boroscopeInspDet.borescope_details.report_date, required: true, minLength: '', maxLength: '', message: 'Please select Report Date' }),
            report_issued_place: checkString({ value: this.props.boroscopeInspDet.borescope_details.report_issued_place, required: true, minLength: '', maxLength: '', message: 'Please enter Report issued Place' })
          }]
        )
      } else if (dataKey == 'components_inspected') {
        return (
          [dataKey] = this.props.boroscopeInspDet.components_inspected.map((compData, compIndex) => compData ? {
            description: checkString({ value: compData.description, required: true, minLength: '', maxLength: '', message: 'Please enter description' }),
          } : compData
          )
        )
      }
    })
    validateNewInput = removeEmptyKey(validateNewInput);
    let validationFlag = validateNewInput.map(validateInput =>
      validateInput.map(innerArray => Object.keys(innerArray).every((k) => { return innerArray[k] === '' }))
    );

    if (validationFlag.filter(validData => validData.includes(false)).length == 0) {
      this.props.updateBoroscopeDetlsFn(this.props.boroscopeInspDet)
    } else {
      this.props.errFn(validateNewInput)
      trackActivity('TE001', {
        event_type: 'Save Borescope Details Failed',
        page_title: 'Borescope Inspection',
        workorder_slug: this.props.params.workOrderSlug ? this.props.params.workOrderSlug : '',
        form_slug: this.props.params.formSlug ? this.props.params.formSlug : '',
        error_source: 'Frontend',
        error_type: 'Validation',
        error_details: validateNewInput ? validateNewInput : '',
        asset_type: this.props.params.type ? this.props.params.type : '',
      })
    }
  }

  sortStart = () => {
    this.setState(prevState => ({
      ...prevState,
      classCustom: 'hideActionblock'
    }))
  }

  trackTabChangeActivity = (type, event, tab) => {
    trackActivity('TE001', { event_type: type, page_title: 'Borescope Inspection', event_desc: event, tab_name: tab })
  }

  render() {
    const { componentSideBar, boroscopeInspDet, boroscopeLoader, editBoroscpPicCrud, error, techExportLoader, formInfo, formStatusLoader } = this.props;
    let editorRef = React.createRef();
    if (!Object.keys(boroscopeInspDet).length) {
      return false
    }
    return (
      <div className="technical-inspection-cn ">
        <MSNSwitcherHeader
          SecodaryHeaderRH={<SecondaryTechHeaderRH projectInfo={this.props.formInfo.project} />}
          SecondaryHeaderMid={<SecondaryTechHeaderMid projectInfo={this.props.formInfo.project} />}
          activeAsset={this.props.formInfo.asset}
          projectSlug={Object.keys(this.props.formInfo).length ? this.props.formInfo.project.slug : ''}
        />
        <FormHeader
          formInfo={this.props.formInfo}
          saveButton={<Link style={boroscopeLoader ? { pointerEvents: 'none', opacity: '0.5' } : { pointerEvents: 'auto', opacity: '1' }} onClick={() => this.updateBoroscopeDetlsFn(this.props.boroscopeInspDet)} className="primary-btn"> {boroscopeLoader ? 'Processing...' : 'Save'} </Link>}
          exportReport={<ExportFiles exportFile={(fileType) => this.props.exportReport('borescope-details', 'ESN_' + this.props.formInfo.asset.esn + '_' + this.props.formInfo.current_form.name, fileType)} exportName={'Export Report '} files={[{ img: 'pdf_icon.png', label: 'PDF', type: 'pdf' }]} />}
          updateFormStatus={(data) => this.props.updateFormStatus(data)}
          toggleDownloadedLogs={this.toggleDownloadedLogs}
        />
        <div className="technical-inspection-ee"  >
          <h4 className="tech-heading-block flex-centered">
            General Details
            <span onClick={() => { this.togglePrerequisiteModal(); trackActivity('TE001', { event_type: 'Clicked', page_title: 'Borescope Inspection', item_type: 'Read Prerequisite' }) }} className="pre-requisite flex-centered">
              <img src={imgStoragePath + "read_pre_icon.png"} alt="img" className="blue-img" />
              Read Prerequisite
            </span>
          </h4>
          {/* <CollapseTeWrapper key={0} title={'Prerequisite'}>
         <PrerequisiteWrapper/>
         </CollapseTeWrapper>
         <CollapseTeWrapper key={1} title={'Genral Details'}>
         <GenralDetail01Wrapper/>
         </CollapseTeWrapper> */}
          {/* <PrerequisiteModal>prere</PrerequisiteModal> */}

          <GenralDetail01Wrapper
            borescopeDetails={boroscopeInspDet.borescope_details}
            updateForm={(obj) => this.updateForm(obj)}
            error={error && error[0] ? error[0][0] : ''}
          />
        </div>
        <div className="form-te001-cn">
          {formStatusLoader ||
            techExportLoader ?
            <ListLoader />
            : null
          }
          <div className="edit-view-tech-spec">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '1' })}
                  onClick={() => { this.toggle('1'); this.trackTabChangeActivity('Tab Clicked', 'Clicked on Components Inspected Tab', 'Components Inspected') }}
                >
                  Components Inspected
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '2' })}
                  onClick={() => { this.toggle('2'); this.trackTabChangeActivity('Tab Clicked', 'Clicked on Additional Information Tab', 'Additional Information') }}
                >
                  Additional Information
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '3' })}
                  onClick={() => { this.toggle('3'); this.trackTabChangeActivity('Tab Clicked', 'Clicked on Recommendations Tab', 'Recommendations') }}
                >
                  Recommendations
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '4' })}
                  onClick={() => { this.toggle('4'); this.trackTabChangeActivity('Tab Clicked', 'Clicked on Inspection Pictures Tab', 'Inspection Pictures') }}
                >
                  Inspection Pictures
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}  >
              <TabPane tabId="1" >
                <TableUIComp
                  noStickyHeader={true}
                  hover={true}
                  tableBulkOp={ formInfo?.current_form?.permissions?.can_save? <BulkOperation addText="+ Add Item" addComponent={() => { this.props.addComponent(this.state.componentStruct); this.scrollToBottom() }} export={false} />: false}
                  filter={false}
                  tableHeight="auto"
                  theads={[{ label: 'Sl No.', sortKey: '' }, { label: 'Description', sortKey: '' }, { label: 'Findings', sortKey: '' }, { label: 'Status', sortKey: '' }, { label: 'Action', sortKey: '' }]}
                  bulkOperation={false}
                  pagination={[]}
                  //  content = {<EngineEditableTableContent/>}
                  content={boroscopeInspDet.components_inspected.map((componentInspected, index) => <InspectedComponents  formInfo={formInfo} userInfo={getLocalStorageInfo().user} error={error && error[1] ? error[1][index] : ''} toggleComDelModal={() => this.toggleComDelModal('Are you sure to delete?', index, componentInspected.id)} key={index} index={index} content={componentInspected} updateForm={this.updateForm} />)}
                  recordsFound={false}
                />
                <div ref={el => { this.tabEnd = el; }} />
              </TabPane>

              <TabPane tabId="2">
                <CKeditor
                  events={{ "change": (e) => this.updateCkeditor(e, 'additional_information') }}
                  content={boroscopeInspDet.borescope_details.additional_information}
                />
              </TabPane>
              <TabPane tabId="3">
                <CKeditor
                  events={{ "change": (e) => this.updateCkeditor(e, 'recommendation') }}
                  content={boroscopeInspDet.borescope_details.recommendation}
                />
              </TabPane>
              <TabPane tabId="4">
                <BoroInspectionPictures
                  inspectionPicture={boroscopeInspDet.inspection_pictures}
                  uploadImageFn={this.uploadImageFn}
                  updateForm={this.props.updateForm}
                  userInfo={getLocalStorageInfo().user}
                  editImageFn={this.props.editPlateImage}
                  toggleDelImageModal={this.toggleDelImageModal}
                  onSortEnd={(...args) => this.sortFiles(args, boroscopeInspDet.inspection_pictures)}
                  // onSortStart	 = {(evt) => {evt.preventDefault();this.sortStart()}}
                  axis="xy"
                  openSlider={this.openSlider}
                  classCustom={this.state.classCustom}
                />
              </TabPane>
            </TabContent>
            <EditFormBar title="Components Inspected" toggleEditSideBar={this.props.componentEditSideBar} isOpen={componentSideBar} style={componentSideBar ? { width: '400px' } : { width: '0px' }}>
              <ComponentsInspected />
              <div className="editSideBar-footer">
                <button className="btn btn-primary" onClick={() => ''}>Save</button>
                <button className="btn btn-default" onClick={() => this.props.componentEditSideBar('')}>Cancel</button>
              </div>
            </EditFormBar>
          </div>
        </div>


        {/* pre requiste modal */}
        <DeleteModal isOpen={this.state.preReqModal}
          toggle={() => this.togglePrerequisiteModal()}
          title={'Prerequisite'} className="modal-tform-delete-pictures modal-dialog-centered pre-requsite-modal">
          <PrerequisiteWrapper form={'te001'} />
        </DeleteModal>



        {/* delete component modal */}
        <DeleteModal isOpen={this.state.delCompModal}
          toggle={() => this.toggleComDelModal('')}
          title={this.state.deleteComponentTitle}
          className="modal-tform-delete-pictures modal-dialog-centered">
          <ul className="list-inline">
            <li className="list-inline-item">
              <button type="button" className="btn btn-danger" onClick={() => { this.toggleComDelModal(''); this.props.removeComp(this.state.deleteComponentIndex, this.state.deleteCompoId) }}>Yes</button>
            </li>
            <li className="list-inline-item">
              <button type="button" className="btn btn-primary" onClick={() => this.toggleComDelModal('')}>No</button>
            </li>
          </ul>
        </DeleteModal>


        {/* delete image modal */}
        <DeleteModal isOpen={this.state.delImageModal}
          toggle={() => this.toggleDelImageModal('')}
          title={this.state.deleteImageTitle}
          className="modal-tform-delete-pictures modal-dialog-centered">
          <ul className="list-inline">
            <li className="list-inline-item">
              <button type="button" className="btn btn-danger" onClick={() => { this.toggleDelImageModal(''); this.props.removeImageFn(this.state.deleteImageIndex, this.state.deleteImageId) }}>Yes</button>
            </li>
            <li className="list-inline-item">
              <button type="button" className="btn btn-primary" onClick={() => this.toggleDelImageModal('')}>No</button>
            </li>
          </ul>
        </DeleteModal>


        <EditFormBar
          titleContent="Edit Image"
          // { <div><h3>Edit {editBoroscpPicCrud.data.name} Picture</h3></div>}
          toggleEditSideBar={() => this.props.editImageFn({ flag: false, data: {} })}
          isOpen={editBoroscpPicCrud.flag}
          style={editBoroscpPicCrud.flag ? { width: '840px' } : { width: '0px' }}
        >
          {editBoroscpPicCrud.flag ?
            <div className="sidebar-form" style={{ height: (window.innerHeight - document.getElementById('sidebar-header').clientHeight + 5) + 'px', paddingBottom: '90px' }}>
              <ImageEditor
                ref={this.editorRef}
                includeUI={{
                  loadImage: {
                    path: editBoroscpPicCrud.data.image,
                    name: 'SampleImage',
                  },
                  initMenu: 'filter',
                  uiSize: {
                    width: '800px',
                    height: '700px',
                  },
                  menuBarPosition: 'bottom',
                }}
                cssMaxHeight={500}
                cssMaxWidth={700}
                selectionStyle={{
                  cornerSize: 20,
                  rotatingPointOffset: 70,
                }}
                usageStatistics={false}
              />
            </div> : null
          }
          <div className="edit-sidebar-footer" style={editBoroscpPicCrud.flag ? { width: '840px', position: 'fixed', bottom: '0px', right: '0px', left: (window.innerWidth - 840) + 'px' } : { width: '0px', left: window.innerWidth + 'px' }}>
            <ul className="list-inline">
              <li className="list-inline-item">
                <button disabled={this.props.boroscopeLoader} onClick={this.saveImage} type="submit" className="btn btn-primary">{this.props.boroscopeLoader ? 'Processing...' : 'Save'}</button>
              </li>
              <li className="list-inline-item">
                <Link onClick={() => this.props.editImageFn({ flag: false, data: {} })}> Cancel</Link>
              </li>
            </ul>
          </div>
        </EditFormBar>
        <EditFormBar
          titleContent={<div><h3 style={{ textTransform: 'capitalize' }}>Downloaded Logs</h3></div>}
          toggleEditSideBar={this.toggleDownloadedLogs}
          isOpen={this.state.downloadFormLogs}
          style={this.state.downloadFormLogs ? { width: '400px' } : { width: '0px' }}
        >
          {this.state.downloadFormLogs ?
            <DownloadedLogs logs={formInfo.current_form.download_report} />
            : null
          }
        </EditFormBar>
        {boroscopeLoader ? <ListLoader /> : null}
        {
          this.state.sliderModal ?
            <Modal isOpen={this.state.sliderModal} toggle={() => this.setState({ sliderModal: false })} className="modal-tform-pictures">
              <ModalHeader toggle={() => this.setState({ sliderModal: false })}>{this.state.modalTitle}</ModalHeader>
              <ModalBody>
                <ImageSlider imageSlider={this.state.imageSlider} />
              </ModalBody>
            </Modal>
            : null
        }
        {
          this.props.buyLicenceModal.flag ?
            <LicenceModal
              planId="2"
              toggleLicence={() => this.props.buyLicence({
                flag: false,
                action: '',
                content: ''
              })}
              licenceModal={this.props.buyLicenceModal}
            />
            : null
        }
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  formInfo: state.FormT001Reducer.formInfo,
  componentSideBar: state.FormTE001Reducer.componentInspectSideBar,
  boroscopeInspDet: state.FormTE001Reducer.boroscopeInspDet,
  boroscopeLoader: state.FormTE001Reducer.boroscopeLoader,
  editBoroscpPicCrud: state.FormTE001Reducer.editBoroscpPicCrud,
  techExportLoader: state.sharedReducers.techExportLoader,
  error: state.FormTE001Reducer.error,
  formStatusLoader: state.FormT001Reducer.formStatusLoader,
  buyLicenceModal: state.sharedReducers.buyLicenceModal,
  storageUsage: state.sharedReducers.storageUsage,
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getFormDetails: (type, application) => dispatch(getFormDetailsAc(ownProps, type, application)),
    componentEditSideBar: () => dispatch({
      type: actions.COMPONENT_INSPECT_BAR,
      payload: {}
    }),
    fetchBoroscopeDetailsFn: () => dispatch(fetchBoroscopeDetailsAc(ownProps)),
    updateForm: (data) => dispatch({
      type: actions.UPDATE_FORM,
      payload: data
    }),
    updateDescription: (data) => dispatch({
      type: actions.UPLOAD_IMAGE,
      payload: data
    }),
    addComponent: (componentStruct) => dispatch({
      type: ADD_DYNAMIC_COMPONENT,
      payload: componentStruct
    }),
    editImageFn: (data) => dispatch({
      type: EDIT_INSPECTION_IMAGE,
      payload: data
    }),
    sortPictures: (data) => dispatch({
      type: SORT_PICTURES,
      payload: data
    }),
    errFn: (errMsg) => dispatch({
      type: ERROR_MESSAGE,
      payload: errMsg
    }),
    clearErr: (obj) => dispatch({
      type: CLEAR_ERR_MESSGAE,
      payload: obj
    }),
    updateFormStatus: (data) => dispatch(updateFormStatusAc(ownProps, data, 2)),
    exportReport: (url, name, fileType) => dispatch(downLoadTechEngineFormAc(url, ownProps.params.workOrderSlug, fileType, name)),
    removeComp: (compoIndex, compoId) => dispatch(deleteComponentAc(ownProps, compoIndex, compoId)),
    removeImageFn: (imgIndex, imgId) => dispatch(deleteImageAc(ownProps, imgIndex, imgId)),
    uploadImageFn: (file) => dispatch(uploadImageAc(ownProps, file)),
    updateBoroscopeDetlsFn: (data) => dispatch(updateBoroscopeDetlsAc(ownProps, data)),
    saveEditedImage: (imageId, image) => dispatch(saveEditedImageAc(ownProps, imageId, image)),
    editPlateImage: (data) => dispatch(editPlatePicAc(ownProps, data)),
    usageDetail: () => dispatch(usageDetailAc()),
    buyLicence: (modalObj) => dispatch({
      type: BUY_LICENCE,
      payload: modalObj
    })
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(InspectionFormTE001);
