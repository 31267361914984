import UsersList from './containers/UsersList';
import userAssetReducer from './reducers';
export {
  userAssetReducer,
  UsersList
};

export const tagsHd = [
  {label:'ID', sortOption: '', id: null},
  {label:'Label', sortOption: '', id: null},
  {label:'Created By', sortOption: '', id: null},
  {label:'Created At', sortOption: '', id: null},
  {label:'Modified At', sortOption: '', id: null},
  {label:'', sortOption: '', id: null},
];
