import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory, Link } from 'react-router';
import { Row, Col } from 'reactstrap';
import { SecondaryTechHeaderRH, SecondaryTechHeaderMid, FormHeader, EditFormBar, DownloadedLogs } from '../../../Elements';
import { AddEditConfig, ViewLopasideBar, DetailSidebar, TechSpecSidebar } from '../components';
import { ExportFiles, MSNSwitcherHeader, ListLoader } from '../../../../../shared';
import { imgStoragePath } from '../../../../../constants';
import { sGlobalConstantAc, sManufacturerAc, downLoadTechFormAc } from '../../../../../shared/actionCreators'
import { getFormDetailsAc, updateFormStatusAc } from '../../T001/actionCreators';
import { FINDING_ARR, SEATS_ARR, UPDATE_WINDOW_FINDING, ADD_CONFIG, CLEAR_ARRAYS, SEAT_ARR_UPDATE_AC, WINDOW_ARR_UPDATE_AC } from '../actions';
import { addEditConfigAc,
  configureViewCrudAc,
  getSeatingArrangementAc,
  configureListAc,
  addEditLopaAc,
  toggleEditAddTechSpecAc,
  toggleTechSpecSideBrAc,
  toggleEditAddDetailAc,
  updateTechSpecAc,
  updateLopaAc,
  seatFindingUpdateAc,
  diableSeatAc,
  windowFindingUpdateAc,
  deleteConfigAc,
  getFindingsAc } from '../actionCreators';
import { getLocalStorageInfo } from '../../../../../utils';
import RestrictedAccess from '../../../../../shared_elements/components/RestrictedAccess';
import InvalidUrl from '../../../../../shared_elements/components/InvalidUrl';
let param = {}
class InspectionFormT008 extends Component {
  constructor(props){
    super(props);
    this.state = {
      modalTitle: ' ',
      modal: false,
      delModal: false,
      configObj : [{
       columns: [['A', 'B'], [], []],
       starting_row: '',
       end_row: '',
       seat_type: '',
       section_order: '',
       }],
       seatWindFlag: 1,
       configtype: 'edit',
       selectedAllSeats: 0,
       selectedAllWindows: 0,
       selectedSeatType: '',
       pilotSeats : [
        {
          seat_number: 'FO',
          seat_type : 0,
          row_number: 6,
          interior_inspection_id:  null
          },
          {
            seat_number: 'C',
            seat_type : 0,
            row_number: 4,
            interior_inspection_id:  null
          },
          {
            seat_number: 'F OB',
            seat_type : 0,
            row_number: 5,
            interior_inspection_id:  null
          },
          {
            seat_number: 'S OB',
            seat_type : 0,
            row_number: 7,
            interior_inspection_id:  null
          },
      ],
      downloadFormLogs: false
    }
  }


  componentDidMount(){
    this.props.getFormDetails(1, 'T008');
    this.props.fetchTechConstant({constant_types:["pax_seat_type"]});
    this.props.configureListFn();
    this.props.fetchManufactureList();
    this.props.getFindingsFn();
   }

   componentWillReceiveProps(nextProps){
    if(nextProps.formInfo && nextProps.formInfo.current_form && nextProps.formInfo.current_form.permissions.can_save) {
      if(this.props.seatConfig != nextProps.seatConfig && !( nextProps.seatConfig.interior_config_details && Object.keys(nextProps.seatConfig.interior_config_details).length ) ) {
       this.props.toggleEditAddSide({data: this.state.configObj, flag: true, type: this.state.configtype});
      }
    }
  }

  toggleDownloadedLogs = () => {
    this.setState(prevState => ({
      ...prevState,
      downloadFormLogs: !prevState.downloadFormLogs
    }))
  }

  selectAllSeats = () => {
    let seatRange = [];
    let selectedSeats = [];
    let disabledSeat = [];
      if(this.state.selectedAllSeats != this.props.seatarr.length || this.props.seatarr.length == 0){
        this.props.seatConfig.interior_config_details.map((data, index) => {
          seatRange = this.range(data.starting_row, data.end_row);
          return(
            seatRange.map((seatNo, seatIndex) =>
              data.columns.map((colItem, index) =>
                colItem.map((col, colIndex) =>  {
                  disabledSeat = this.props.existingFinding.seats ? this.props.existingFinding.seats.filter(findingData => (findingData.status ==  0)): 0;
                 disabledSeat =  disabledSeat.length ? disabledSeat.map(data => data.seat_number) : [];
                 if(!(disabledSeat.includes((seatNo+col)))){
                      return (
                        selectedSeats.push(
                          {
                          seat_number: seatNo+col,
                          seat_type : data.seat_type,
                          row_number: parseInt(seatIndex+1),
                          interior_inspection_id:  data.id
                          }
                        )
                      )
                    }
                })
              ))
              )
            })
        selectedSeats = [...selectedSeats, ...this.state.pilotSeats]
        this.props.selectAllfn(selectedSeats);
      }else{
        this.props.selectAllfn([]);
      }
      this.setState(prevState => ({
        ...prevState,
        selectedAllSeats: selectedSeats.length
      }))
  }
  selectAllWindows = () => {
    let seatRange = [];
    let selectedWindows = [];
    if(this.state.selectedAllWindows != this.props.windowArr.length || this.props.windowArr.length == 0){
      this.props.seatConfig.interior_config_details.map((data, index) => {
        seatRange = this.range(data.starting_row, data.end_row);
        return(
          seatRange.map((seatNo, seatIndex) => {
            return(
              selectedWindows.push({ window_number : (seatNo+'R'), row_number : (seatIndex+1),  interior_inspection_id: data.id}),
              selectedWindows.push({ window_number : (seatNo+'L'), row_number : (seatIndex+1),  interior_inspection_id: data.id})
            )
          }))
        })
        this.props.selectAllWindowfn(selectedWindows);
    }else{
      this.props.selectAllWindowfn([]);
    }
    this.setState(prevState => ({
      ...prevState,
      selectedAllWindows: selectedWindows.length
    }))
  }
  range = (start, end) => {
    return Array(end - start + 1).fill().map((_, idx) => start + idx)
  }
  seatwindowToggleFn = (value) => {
    if(value){
      this.setState(prevState => ({
        ...prevState,
        seatWindFlag: !this.state.seatWindFlag
      }));
    }
    this.props.clearArraysFn();
  }

  componentDidUpdate(prevProps, prevState){
    let seatSelected = document.querySelector('.current');
    let allSeats = document.querySelectorAll('.seat');
    let disabledSeat = document.querySelectorAll('.disabled-seat');
    let enabledSeat = document.querySelectorAll('.enabled-seat');
    let i;
    if(seatSelected){
      if(seatSelected.classList.contains('disabled-seat')){
          for (i = 0; i < enabledSeat.length; i++) {
            enabledSeat[i].style.pointerEvents = "none";
          }
          for (i = 0; i < disabledSeat.length; i++) {
            disabledSeat[i].style.pointerEvents = "auto";
          }
      }else{
        for (i = 0; i < disabledSeat.length; i++) {
          disabledSeat[i].style.pointerEvents = "none";
        }
        for (i = 0; i < enabledSeat.length; i++) {
          enabledSeat[i].style.pointerEvents = "auto";
        }
      }
    }else{
      for (i = 0; i < allSeats.length; i++) {
        allSeats[i].style.pointerEvents = "auto";
      }
      for (i = 0; i < disabledSeat.length; i++) {
        disabledSeat[i].style.pointerEvents = "auto";
      }
    }
  }
  render(){
    const { aircraftConfig, techConstants, seatConfig, techSpecConfig, detailConfig, lopaConfig, technicalData, seatQuestions, seatarr, findingArr, existingFinding, paxSeatingLoader, windowArr, formInfo } = this.props;

    let configtype = 'edit';
    if(!(Object.keys(seatConfig).length && seatConfig.interior_config_details && seatConfig.interior_config_details.length)){
      configtype = 'add';
    }

    let userInfo =getLocalStorageInfo().user
    let formViewPermission = formInfo?.list?.length && formInfo?.list?.find(form => form.slug == formInfo?.current_form?.slug)
    let formType =  window.location?.pathname?.toUpperCase()?.includes(formInfo?.current_form?.name)
    console.log('formInfo',formInfo);

    return(
      <div style={formViewPermission?{}:{backgroundColor:'#fff', marginLeft:"40px"}} className="technical-inspection-cn">
         {formViewPermission && formType ?
        <MSNSwitcherHeader
          SecodaryHeaderRH = {<SecondaryTechHeaderRH projectInfo={formInfo.project}/>}
          SecondaryHeaderMid = {<SecondaryTechHeaderMid projectInfo={formInfo.project} activeAsset={formInfo.asset}/>}
          activeAsset={formInfo.asset}
          projectSlug = {Object.keys(formInfo).length ? formInfo.project.slug: ''}
        />: null}
        {formViewPermission && formType?
        <FormHeader
          formInfo={formInfo}
          exportReport={ <ExportFiles exportFile={(fileType) => this.props.exportReport('interior-inspection-download','MSN_'+formInfo.asset.msn+'_'+formInfo.current_form.name,fileType)} exportName={'Export Report '} files={[{img: 'pdf_icon.png', label: 'PDF', type: 'pdf'},{img:'docx_exp_icon.png', label: 'Document', type:'docx'}]} />}
          updateFormStatus={(data) => this.props.updateFormStatus(data)}
          toggleDownloadedLogs = {this.toggleDownloadedLogs}
        />: null}
           { formType ? formViewPermission ?
        <div className="t008-form" style={{ marginTop: '5px',marginBottom: '-30px'}}>
          <div className="seats-container">
            <Row className="edit-config">
              <Col md="2" className="text-left">
                <ul className="list-inline toggle-btn-list">
                  <li className="list-inline-item">Seats</li>
                  <li className="list-inline-item">
                    <label className="switch">
                      <input type="checkbox" onChange={(e) => this.seatwindowToggleFn(e.target.value)}/>
                      <div className="slider round"></div>
                    </label>
                  </li>
                  <li className="list-inline-item">Windows</li>
                </ul>
              </Col>
              <Col md="10">
                <ul className="list-inline action-top-bar">
                  <li className="list-inline-item">
                    <Link className="">
                      { this.state.seatWindFlag ? seatarr.length +'  Seat(s) selected'
                        : windowArr.length +' Window(s) selected'
                      }
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    { this.state.seatWindFlag ?
                      <Link  onClick={()=> this.selectAllSeats()} className="">
                        { this.state.selectedAllSeats != seatarr.length || seatarr.length == 0 ? 'Select All' : 'Unselect All'}
                      </Link> :
                      <Link  onClick={()=> this.selectAllWindows()} className="">
                        { this.state.selectedAllWindows != windowArr.length || windowArr.length == 0 ? 'Select All' : 'Unselect All'}
                      </Link>
                    }
                  </li>
                  { formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save ?
                    <li className="list-inline-item">
                      <Link style={ seatarr.length || windowArr.length ? {'opacity': '1', pointerEvents: 'auto'} : {'opacity': '0.5', pointerEvents: 'none'}} onClick={()=> { this.state.seatWindFlag ? this.props.toggleEditAddDetail({data: [], flag: true, type: 'edit', itemtype : 'seat'}, seatarr) : this.props.toggleEditAddDetail({data: [], flag: true, type: 'edit', itemtype : 'window'}, windowArr)}} className="">
                        <img src={imgStoragePath + "detail.png"} alt="img" className="blue-img" width="16" />
                        Details
                      </Link>
                    </li>:null
                  }
                  <li className="list-inline-item">
                    <Link  onClick={()=> this.props.toggleTechSpecSideBr({data: [], flag: true, type: 'edit'})} className="">
                      <img src={imgStoragePath + "technical.png"} alt="img"   className="blue-img" width="12" />
                      Technical Specification
                    </Link>
                  </li>
                  { formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save ?

                  <li className="list-inline-item">
                    <Link   onClick={()=> { seatConfig.is_lopa_details ? this.props.toggleLopaConfigSide({data: [], flag: true, type: 'edit'}) : this.props.toggleLopaConfigSide({data: [], flag: true, type: 'add'})}} className="">
                      <img src={imgStoragePath + "view.png"} alt="img"  className="blue-img" width="16" />
                      View LOPA
                    </Link>
                  </li>:null}
                  { formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && formInfo.current_form.status.id <= 3?

                  <li className="list-inline-item">
                      <Link
                        onClick={()=> this.props.toggleEditAddSide(Object.keys(seatConfig).length  && seatConfig.interior_config_details && seatConfig.interior_config_details.length ?{data: seatConfig.interior_config_details, flag: true, type: this.state.configtype}:{data: this.state.configObj, flag: true, type: this.state.configtype})}
                        className="">
                        <img src={imgStoragePath + "settings_white.png"} alt="img"  className="blue-img" width="16" />
                        {Object.keys(seatConfig).length  && seatConfig.interior_config_details && seatConfig.interior_config_details.length ?
                        'Edit Configuration' : 'Add Configuration'}
                      </Link>
                    </li>:null
                  }
                </ul>
              </Col>
            </Row>
            {Object.keys(seatConfig).length  && seatConfig.interior_config_details && seatConfig.interior_config_details.length ?
            <div className="seat-matrix" style={{display: "flex"}}>
              <div className="background-image-block"
                style={{ backgroundImage: 'url(https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/img/head.png)', width: '700px', backgroundPositionX: '16px'}}>
                  { Object.keys(seatConfig).length  && seatConfig.interior_config_details && seatConfig.interior_config_details.length ?
                    <ul className="list-unstyled cockpit-seats">
                      <li className="cls-seat" title="" data-original-title="First officer">
                        <Link

                          className= { existingFinding.seats && existingFinding.seats.filter(findingData => (findingData.status ===  0 && findingData.seat_number == 'FO')).length  && seatarr.filter(seat => seat.seat_number == 'FO').length &&  [seatarr.filter(seat => seat.seat_number == 'FO')[0]['seat_number']].includes('FO') ? 'disabled-seat  current' : existingFinding.seats && existingFinding.seats.filter(findingData => (findingData.status ===  0 && findingData.seat_number == 'FO')).length  ? 'disabled-seat' :  seatarr.filter(seat => seat.seat_number == 'FO').length &&  [seatarr.filter(seat => seat.seat_number == 'FO')[0]['seat_number']].includes('FO') ? 'seat active current enabled-seat ' : existingFinding.seats && existingFinding.seats.filter(findingData => findingData.seat_number == ('FO') &&  findingData.findings.length).length && [existingFinding.seats.filter(findingData => findingData.seat_number == ('FO'))[0]['seat_number']].includes(('FO')) ?  'has-finding findings-found seat enabled-seat' : 'seat enabled-seat'}
                          style={this.state.seatWindFlag ? { opacity: 1, pointerEvents: 'auto'} : { opacity: '0.5', pointerEvents: 'none'} }
                          href="javascript:void(0)"
                          onClick = {() => this.props.addSeatsFn(this.state.pilotSeats[0])}
                          >FO
                        </Link>
                      </li>
                      <li title="" className="o2-seat  " data-original-title="Captain">
                        <Link className="seat"
                          style={this.state.seatWindFlag ? { opacity: 1, pointerEvents: 'auto'} : { opacity: '0.5', pointerEvents: 'none'} }
                          className= { existingFinding.seats && existingFinding.seats.filter(findingData => (findingData.status ===  0 && findingData.seat_number == 'C')).length && seatarr.filter(seat => seat.seat_number == 'C').length &&  [seatarr.filter(seat => seat.seat_number == 'C')[0]['seat_number']].includes('C') ? 'disabled-seat  current' : existingFinding.seats && existingFinding.seats.filter(findingData => (findingData.status ===  0 && findingData.seat_number == 'C')).length  ? 'disabled-seat' : seatarr.filter(seat => seat.seat_number == 'C').length &&  [seatarr.filter(seat => seat.seat_number == 'C')[0]['seat_number']].includes('C') ? 'seat active current enabled-seat ' : existingFinding.seats && existingFinding.seats.filter(findingData => findingData.seat_number == ('C') &&  findingData.findings.length).length && [existingFinding.seats.filter(findingData => (findingData.seat_number == ('C')))[0]['seat_number']].includes(('C')) ?  'has-finding findings-found seat enabled-seat' : 'seat enabled-seat'}
                          onClick = {() => this.props.addSeatsFn(this.state.pilotSeats[1])}
                          >C
                        </Link>
                      </li>
                      <li title="" className=" o1-seat " data-original-title="First observer">
                        <Link className="seat"
                          style={this.state.seatWindFlag ? { opacity: 1, width: '45px', pointerEvents: 'auto'} : { opacity: '0.5', width: '45px', pointerEvents: 'none'} }
                          className= { existingFinding.seats && existingFinding.seats.filter(findingData => (findingData.status ===  0 && findingData.seat_number == 'F OB')).length && seatarr.filter(seat => seat.seat_number == 'F OB').length &&  [seatarr.filter(seat => seat.seat_number == 'F OB')[0]['seat_number']].includes('F OB') ? 'disabled-seat  current' : existingFinding.seats && existingFinding.seats.filter(findingData => (findingData.status ===  0 && findingData.seat_number == 'F OB')).length  ? 'disabled-seat' : seatarr.filter(seat => seat.seat_number == 'F OB').length &&  [seatarr.filter(seat => seat.seat_number == 'F OB')[0]['seat_number']].includes('F OB') ? 'seat active current enabled-seat ' : existingFinding.seats && existingFinding.seats.filter(findingData => findingData.seat_number == ('F OB') &&  findingData.findings.length).length && [existingFinding.seats.filter(findingData => findingData.seat_number == ('F OB'))[0]['seat_number']].includes(('F OB')) ?  'has-finding findings-found seat enabled-seat' : 'seat enabled-seat'}
                          onClick = {() => this.props.addSeatsFn(this.state.pilotSeats[2])}
                        >
                          F OB
                        </Link>
                      </li>
                      <li title="" className="fo-seat " data-original-title="Second observer">
                        <Link
                          style={this.state.seatWindFlag ? { opacity: 1, width: '45px', pointerEvents: 'auto' } : { opacity: '0.5', width: '45px', pointerEvents: 'none'} }
                          className= { existingFinding.seats && existingFinding.seats.filter(findingData => (findingData.status ===  0 && findingData.seat_number == 'S OB')).length && seatarr.filter(seat => seat.seat_number == 'S OB').length &&  [seatarr.filter(seat => seat.seat_number == 'S OB')[0]['seat_number']].includes('S OB') ? 'disabled-seat  current' : existingFinding.seats && existingFinding.seats.filter(findingData => (findingData.status ===  0 && findingData.seat_number == 'S OB')).length ? 'disabled-seat' : seatarr.filter(seat => seat.seat_number == 'S OB').length &&  [seatarr.filter(seat => seat.seat_number == 'S OB')[0]['seat_number']].includes('S OB') ? 'seat active current enabled-seat ' : existingFinding.seats && existingFinding.seats.filter(findingData => findingData.seat_number == ('S OB') &&  findingData.findings.length).length && [existingFinding.seats.filter(findingData => findingData.seat_number == ('S OB'))[0]['seat_number']].includes(('S OB')) ?  'has-finding findings-found seat enabled-seat' : 'seat enabled-seat'}
                          onClick = {() => this.props.addSeatsFn(this.state.pilotSeats[3])}
                          >S OB
                        </Link>
                      </li>
                    </ul> :  null
                  }
                </div>
                <div className="cabin-seats" >
                  <img src={imgStoragePath + "aircraft-body.png"} className="cabin-image"/>
                  { Object.keys(seatConfig).length  && seatConfig.interior_config_details && seatConfig.interior_config_details.length ?
                    seatConfig.interior_config_details.map((data, interiorIndex) => {

                      let endRow = data.row_diff ? data.row_diff : 0;
                      let startRow = data.starting_row == 0 ? 1 : data.starting_row;
                      let seatRange = this.range(startRow, parseInt(data.end_row + endRow));
                      if(interiorIndex == 0){
                        seatRange = this.range(1, parseInt(data.end_row + endRow));
                      }else{
                        seatRange = this.range(data.starting_row, parseInt(data.end_row + endRow));
                      }
                      return(
                        seatRange.map((seatNo, seatIndex) =>
                        <ul key={seatIndex} className="list-inline seat-list" >
                        <li className="list-inline-item">
                        <ul className="list-unstyled ">
                          <li className="" >
                            <a
                              className= { windowArr.filter(window => window.window_number == (seatNo+'R').toString()).length &&  [windowArr.filter(window => window.window_number == (seatNo+'R').toString())[0]['window_number']].includes((seatNo+'R').toString()) ? 'window window-selected' : existingFinding.windows && existingFinding.windows.filter(findingData => findingData.window_number == (seatNo+'R').toString()).length && [existingFinding.windows.filter(findingData => findingData.window_number == (seatNo+'R').toString())[0]['window_number']].includes((seatNo+'R').toString()) ?  'has-finding  window' : 'window'}
                              onClick = {() => this.props.addWindowFn({ window_number : (seatNo+'R'), row_number : (seatIndex+1),  interior_inspection_id: data.id})}
                              style={this.state.seatWindFlag ? {'opacity': '0.5', pointerEvents: 'none'} : {'opacity': '1', pointerEvents: 'auto'} }
                              >
                            </a>
                          </li>
                          { data.columns.map((colItem, index) =>
                            colItem.map((col, colIndex) =>  {
                              let checkExistingFinding = existingFinding.seats ? existingFinding.seats.filter(findingData => (findingData.seat_number == seatNo+col)): [];
                              let disabledSeat = existingFinding.seats ? existingFinding.seats.filter(findingData => (findingData.status ==  0 && findingData.seat_number == seatNo+col)): [];
                              let selectedSeat = seatarr.length ? seatarr.filter(seat => seat.seat_number == (seatNo+col) ) : [];
                              return(
                                <li key={colIndex} className={((colItem.length - 1) == colIndex)  ? "column-seperator" : ""}
                                  style={seatRange.indexOf(seatNo) > seatRange.indexOf(data.end_row) || seatRange.indexOf(seatNo) < seatRange.indexOf(data.starting_row)   ? {opacity: 0, pointerEvents: 'none', visibility: 'hidden'} : {}}
                                >
                                  <a
                                    style={this.state.seatWindFlag ? { opacity: 1,  pointerEvents: 'auto'} : { opacity: '0.5', pointerEvents: 'none'} }
                                    className= { (disabledSeat.length && [disabledSeat[0]['seat_number']].includes((seatNo+col)) && selectedSeat.length && [selectedSeat[0]['seat_number']].includes((seatNo+col)) ) ? 'disabled-seat seat current' : disabledSeat.length && [disabledSeat[0]['seat_number']].includes((seatNo+col)) ? 'disabled-seat' : selectedSeat.length &&  [selectedSeat[0]['seat_number']].includes((seatNo+col)) ? 'seat active current enabled-seat ' : checkExistingFinding.length && checkExistingFinding[0].findings.length && [checkExistingFinding[0]['seat_number']].includes((seatNo+col)) ? 'has-finding findings-found enabled-seat seat' : 'seat enabled-seat'}
                                    href="javascript:void(0)"
                                    onClick = {() => this.props.addSeatsFn({seat_number: (seatNo+col), seat_type: data.seat_type ,row_number: (seatIndex+1),  interior_inspection_id: data.id})}
                                    data-config="83"> {seatNo} {col} </a>
                                </li>
                              )
                            }
                          ))
                        }
                        <li className="">
                          <a
                            className= { windowArr.filter(window => window.window_number == (seatNo+'L').toString()).length &&  [windowArr.filter(window => window.window_number == (seatNo+'L').toString())[0]['window_number']].includes((seatNo+'L').toString()) ? 'window window-selected' : existingFinding.windows && existingFinding.windows.filter(findingData => findingData.window_number == (seatNo+'L').toString()).length && [existingFinding.windows.filter(findingData => findingData.window_number  == (seatNo+'L').toString())[0]['window_number']].includes((seatNo+'L').toString()) ?  'has-finding  window' : 'window'}
                            onClick = {() => this.props.addWindowFn({ window_number : (seatNo+'L'), row_number : (seatIndex+1),  interior_inspection_id: data.id})}
                            style={this.state.seatWindFlag ? {'opacity': '0.5', pointerEvents: 'none'} : {'opacity': '1', pointerEvents: 'auto'} }
                            >
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                ))
              }) : null
            }
          </div>
          <div style={{position: 'relative', left: '-3px', paddingRight: '15px'}}>
            <div className="background-image-block"
              style={{ backgroundImage: 'url(https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/img/tail.png)', width: '1000px', height: '635px'}}>
            </div>
          </div>
        </div>:<p style={{color:'#6f859b',textAlign:'center',fontSize:'16px',marginTop:'30px'}}>{formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save ? 'Seating configuration not found':''}</p>
            }
      </div>
    </div>: paxSeatingLoader? null:  Object.keys(formInfo).length ?
    <RestrictedAccess />  : <ListLoader/> :
    formInfo?.list?.length || formInfo.error =='404'?
    <InvalidUrl 
    getResponseBack={()=>browserHistory.push(`/technical/project/view/${formInfo?.project?.slug}`) } 
    /> : <ListLoader />
    }
    <EditFormBar
      titleContent=   { <div><h3 style={{ textTransform: 'capitalize'}}>{Object.keys(seatConfig).length  && seatConfig.interior_config_details && seatConfig.interior_config_details.length ?
        'Edit Configuration' : 'Add Configuration'}</h3></div>}
      toggleEditSideBar={() => this.props.toggleEditAddSide({type: 'add', flag: false, data: {}}) }
      isOpen={aircraftConfig.flag}
      style={aircraftConfig.flag ? {width: '700px'} : {width: '0px'}}
      >
      { aircraftConfig.flag && Object.keys(aircraftConfig.data).length ?
        <AddEditConfig
          configDataObj = {aircraftConfig.data}
          techConstants = {techConstants}
          addEditConfig = {this.props.addEditConfigFn}
          toggleEditSideBar={() =>this.props.toggleEditAddSide({type: 'add', flag: false, data: {}})}
          paxSeatingLoader = {paxSeatingLoader}
          deleteFn = {this.props.deleteConfigFn}
        /> : null
      }
    </EditFormBar>
    <EditFormBar
      titleContent=   { <div><h3 style={{ textTransform: 'capitalize'}}> View LOPA </h3></div>}
      toggleEditSideBar={() => this.props.toggleLopaConfigSide({type: 'add', flag: false, data: {}})}
      isOpen={lopaConfig.flag}
      style={lopaConfig.flag ? {width: '700px'} : {width: '0px'}}
      >
      { lopaConfig.flag && Object.keys(lopaConfig.data).length ?
        <ViewLopasideBar
          lopaConfig = {lopaConfig}
          techConstants = {techConstants}
          addEditConfig = {this.props.addEditConfigFn}
          toggleEditSideBar={() => this.props.toggleLopaConfigSide({type: 'add', flag: false, data: {}})}
          updateLopaDetail = {this.props.updateLopaDetail}
          paxSeatingLoader = {paxSeatingLoader}
          formInfo={formInfo}
        /> : null
      }
    </EditFormBar>
    <EditFormBar
      titleContent=  { <div><h3 style={{ textTransform: 'capitalize'}}> technical Specification </h3></div>}
      toggleEditSideBar={() => this.props.toggleTechSpecSideBr({type: 'add', flag: false, data: {}})}
      isOpen={technicalData.flag}
      style={technicalData.flag ? {width: '700px'} : {width: '0px'}}
    >
      { technicalData.flag && technicalData.data.length ?
        <TechSpecSidebar
          userInfo={userInfo}
          techSpecObj = {technicalData}
          updateTechSpecFn= {this.props.updateTechSpecFn}
          toggleEditSideBar={() => this.props.toggleTechSpecSideBr({type: 'add', flag: false, data: {}})}
          sManufacruerList ={this.props.sManufacruerList}
          paxSeatingLoader = {paxSeatingLoader}
          formInfo={formInfo}
        /> : null
      }
    </EditFormBar>
    <EditFormBar
      titleContent=   { <div><h3 style={{ textTransform: 'capitalize'}}> { this.state.seatWindFlag ? seatarr.length +' Seat' : windowArr.length +' Window'} Details   </h3></div>}
      toggleEditSideBar={() => this.props.toggleEditAddDetail({type: 'add', flag: false, data: {}})}
      isOpen={seatQuestions.flag}
      style={seatQuestions.flag ? {width: '700px'} : {width: '0px'}}
    >
      { seatQuestions.flag  ?
        <DetailSidebar
          seatQuestions = {seatQuestions}
          techConstants = {techConstants}
          addEditConfig = {this.props.addEditConfigFn}
          toggleEditSideBar={() => this.props.toggleEditAddDetail({type: 'add', flag: false, data: {}})}
          seatWindFlag = {this.state.seatWindFlag}
          addFindingFn = {this.props.addFindingFn}
          findingArr= {findingArr}
          seatarr = {seatarr}
          updateSeatFindingUpdate = {this.props.updateSeatFindingUpdate}
          updateWindowFindingUpdate = {this.props.updateWindowFindingUpdate}
          paxSeatingLoader = {paxSeatingLoader}
          windowArr = {windowArr}
          seatStatus = {this.props.seatStatus}
          disableSeatFn = {this.props.disableSeatFn}
        /> : null
      }
    </EditFormBar>
    { paxSeatingLoader ? <ListLoader/> : null }
    <EditFormBar
      titleContent= { <div><h3 style={{ textTransform: 'capitalize'}}>Downloaded Logs</h3></div>}
      toggleEditSideBar={this.toggleDownloadedLogs}
      isOpen={this.state.downloadFormLogs}
      style={this.state.downloadFormLogs ? {width: '400px'} : {width: '0px'}}
      >
      {this.state.downloadFormLogs ?
        <DownloadedLogs logs={formInfo.current_form.download_report} />
        :null
      }
    </EditFormBar>
   </div>
    )
  }
}
const mapStateToProps = (state) => ({
  formInfo: state.FormT001Reducer.formInfo,
  aircraftConfig: state.FormT008Reducer.aircraftConfig,
  techConstants: state.sharedReducers.techConstants,
  seatConfig: state.FormT008Reducer.seatConfig,
  techSpecConfig: state.FormT008Reducer.techSpecConfig,
  detailConfig: state.FormT008Reducer.detailConfig,
  lopaConfig: state.FormT008Reducer.lopaConfig,
  technicalData: state.FormT008Reducer.technicalData,
  seatQuestions: state.FormT008Reducer.seatQuestions,
  sManufacruerList : state.sharedReducers.sManufacruerList,
  seatarr:  state.FormT008Reducer.seatarr,
  findingArr: state.FormT008Reducer.findingArr,
  existingFinding: state.FormT008Reducer.existingFinding,
  paxSeatingLoader: state.FormT008Reducer.paxSeatingLoader,
  windowArr: state.FormT008Reducer.windowArr,
  seatStatus: state.FormT008Reducer.seatStatus
});
const mapDispatchToProps = (dispatch,ownProps) =>{
  return{
    getFormDetails: (type, application) => dispatch(getFormDetailsAc(ownProps, type, application)),
    updateFormStatus: (data) => dispatch(updateFormStatusAc(ownProps, data)),
    addEditConfigFn: (data) => dispatch(addEditConfigAc(ownProps, data)),
    toggleEditAddSide: (addConfigCrud) => dispatch({
      type: ADD_CONFIG,
			payload: addConfigCrud
    }),
    fetchTechConstant : (constantTypes) => dispatch(sGlobalConstantAc(constantTypes)),
    configureListFn: () => dispatch(getSeatingArrangementAc(ownProps)),
    toggleLopaConfigSide : (data) => dispatch(addEditLopaAc(ownProps, data)),
    toggleTechSpecSideBr : (data) => dispatch(toggleTechSpecSideBrAc(ownProps, data)),
    toggleEditAddDetail : (data, seatwindowArr) => dispatch(toggleEditAddDetailAc(ownProps, data, seatwindowArr)),
    fetchManufactureList: () => dispatch(sManufacturerAc()),
    updateTechSpecFn: (data) => dispatch(updateTechSpecAc(ownProps, data)),
    updateLopaDetail : (data, file) => dispatch(updateLopaAc(ownProps, data, file)),
    addSeatsFn: (seatObj) => dispatch({
      type:SEATS_ARR,
      payload:seatObj
    }),
    addFindingFn: (finidingArr) => dispatch({
      type:FINDING_ARR,
      payload:finidingArr
    }),
    addWindowFn : (windowArr) => dispatch({
      type: UPDATE_WINDOW_FINDING,
      payload: windowArr
    }),
    updateSeatFindingUpdate : (data) => dispatch(seatFindingUpdateAc(ownProps, data)),
    updateWindowFindingUpdate:  (data) => dispatch(windowFindingUpdateAc(ownProps, data)),
    getFindingsFn : () => dispatch(getFindingsAc(ownProps)),
    deleteConfigFn: (configId) => dispatch(deleteConfigAc(ownProps, configId)),
    clearArraysFn : () =>  dispatch({
      type: CLEAR_ARRAYS,
      payload: ''
    }),
    selectAllfn : (data) => dispatch({
      type: SEAT_ARR_UPDATE_AC,
      payload: data
    }),
    selectAllWindowfn : (data) => dispatch({
      type: WINDOW_ARR_UPDATE_AC,
      payload: data
    }),
    disableSeatFn: (data) => dispatch(diableSeatAc(ownProps, data)),
    exportReport:(url,name,fileType) => dispatch(downLoadTechFormAc(url,ownProps.params.workOrderSlug,fileType,name)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(InspectionFormT008);
