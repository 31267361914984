import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Tabs, Tab } from '@material-ui/core';
import moment from 'moment';
import InfiniteScroll from "react-infinite-scroll-component";
import { isMobile } from 'react-device-detect';
import InfoIcon from '@material-ui/icons/Info';
import { ListLoader, TableListComp, FilterUIComp, ExportManu, EmptyCollection } from '../../../shared';
import { STabsLoader, STableLoader } from '../../../shared/loaders';
import { removeEmptyKey, convertFilterObject, getLocalStorageInfo } from '../../../utils';
import { sGlobalConstantAc } from '../../../shared/actionCreators';
import { displayDateFormatShort } from '../../../constants';
import { ProjectAnalyticList, ResourcesAnalyticList } from '../components';
import { getAnalyticResourceApi, getAnalyticProjectsApi } from '../apiServices';
import { projectsFilterOptions, resourceFilterOptions, projectAnalyticHd, resourceAnalyticHd } from '../'
class AnalyticTracker extends Component {
  constructor(props){
    super(props);
    this.state = {
      pageLoader:false,
      skeletonLoader:true,
      tabIndex:0,
      filter: {},
      applyingFilter:{},
      page:1,
      resourceDetail:{
        general_info:{},
        plots:[],
        user_list:[]
      },
      projectsDetail:{list:[], pagination:{}}
    }
    this.getAnalyticResourceApi = getAnalyticResourceApi.bind(this);
    this.getAnalyticProjectsApi = getAnalyticProjectsApi.bind(this);
  }
  componentDidMount(){
    this.getAnalyticResourceApi(this.props, {}, 'skeletonLoader');
    // this.getAnalyticProjectsApi(this.props, {list:true}, 'skeletonLoader');
  }

  applyFilter = (keyParam, value, rangeKey) => {
    if(rangeKey){
      this.setState(prevState => ({
        ...prevState,
        applyingFilter: {
          ...prevState.applyingFilter,
          [keyParam]: {
            ...prevState.applyingFilter[keyParam],
            [rangeKey]:value
          }
        }
      }))
    }else{
      this.setState(prevState => ({
        ...prevState,
        applyingFilter: {
          ...prevState.applyingFilter,
          [keyParam]: value
        }
      }))
    }
  }
  removeFilter = (keyParam) => {
    const { filter, applyingFilter } = this.state;
    let query = Object.assign({}, filter);
    delete query[keyParam];
    this.getProjectTrackerApi(this.props, {...query, page:1}, 'pageLoader');
    this.setState({applyingFilter: delete applyingFilter[keyParam]})
  }
  moveNextPage = () => {
    const { filter } = this.state;
    this.getProjectTrackerApi(this.props, {...filter, page:this.state.page+1}, 'loadmore');
    this.setState({
      page: this.state.page + 1
    });
  }
  render(){
    const { tabIndex, filter, applyingFilter, projectsDetail, resourceDetail, skeletonLoader,  pageLoader } = this.state;
    let filterOptions = resourceFilterOptions;
    return(
      <Fragment>
        <div>
          { skeletonLoader ? <STableLoader count={10} />:
            <Fragment>
              <span style={{float: 'right', padding: '5px 0'}}>
                <ExportManu title="Export Projects" files={[{title:'Excel', extension: 'xls', key:''}]} />
              </span>
              <FilterUIComp
                 filter={filter}
                 applyingFilter={applyingFilter}
                 removeFilter={this.removeFilter}
                 removeAllFilter={() => this.getAnalyticProjectsApi(this.props, {}, 'pageLoader')}
                 submitFilter={() => this.getAnalyticProjectsApi(this.props, {...convertFilterObject(removeEmptyKey(applyingFilter), filterOptions)}, 'pageLoader')}
                 applyFilter={this.applyFilter}
                 filterMenu={filterOptions}
               />
              <TableListComp
                heads={resourceAnalyticHd}
                data={ resourceDetail.user_list.map((item,index) =>
                  <ResourcesAnalyticList
                    key={index}
                    item={item}
                  />
                )}
                noRecord={ resourceDetail.user_list.length ? null:
                  <EmptyCollection
                    title="No records found"
                  />
                }
              />
            </Fragment>
          }
          { pageLoader ? <ListLoader />:null }
        </div>
        {
          /*
          <div>
            { skeletonLoader ? <STableLoader count={10} />:
              <Fragment>
                <span style={{float: 'right', padding: '5px 0'}}>
                  <ExportManu title="Export Projects" files={[{title:'Excel', extension: 'xls', key:''}]} />
                </span>
                <FilterUIComp
                   filter={filter}
                   applyingFilter={applyingFilter}
                   removeFilter={this.removeFilter}
                   removeAllFilter={() => this.getAnalyticProjectsApi(this.props, {}, 'pageLoader')}
                   submitFilter={() => this.getAnalyticProjectsApi(this.props, {...convertFilterObject(removeEmptyKey(applyingFilter), filterOptions)}, 'pageLoader')}
                   applyFilter={this.applyFilter}
                   filterMenu={filterOptions}
                 />
                <TableListComp
                  heads={projectAnalyticHd}
                  data={ projectsDetail.list.map((item,index) =>
                    <ProjectAnalyticList
                      key={index}
                      item={item}
                    />
                  )}
                  noRecord={ projectsDetail.list.length ? null:
                    <EmptyCollection
                      title="No records found"
                    />
                  }
                  pagination={projectsDetail.pagination}
                />
              </Fragment>
            }
            { pageLoader ? <PageLoader />:null }
          </div>
          */
        }
      </Fragment>
    )
  }
}
// const mapStateToProps = state => ({
//   pltConstants: state.shareReducer.pltConstants
// })
// const mapDispatchToProps = (dispatch, ownProps) => {
//   return {
//     getPlatformConstants: () => dispatch(getPlatformConstantsAc({constant_types:['project_departments', 'project_name']}))
//   }
// }
export default AnalyticTracker
