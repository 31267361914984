import React, { Component, Fragment }  from 'react';
import { Grid, TextField, Button, Paper, Chip } from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { AutocompleteCreatable } from '../../../../../shared';
import { MuiPickersUtilsProvider,KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { fieldDateFormat, backendDateFormat, numberRegx } from '../../../../../constants';
import { toastFlashMessage } from '../../../../../utils'
const filter = createFilterOptions();
const OtherInfo = ({ editable, issueDetail, userList, onFieldChange, t011ReportInfo, error, updatePRSSectionsField, tags, issueTags, addTags, removeIssueTags, assigneeList}) => {
  var lessorTags = tags.map(tag => {
    return {value: tag.id, name: tag.name, label: tag.name}
  })
  var dtlTags = issueTags.map(tag => {
    return {value: tag.id, name: tag.name, label: tag.name}
  })

  const addIssueTag = (args) => {
    if(args[2].length < issueTags.length){
      let filteredTag = args[2].filter(({ value: id1 }) => !issueTags.some(({ value: id2 }) => id2 === id1));
      let filteredId = []
      filteredTag.map(item => {
        filteredId.push(item.value)
      })
      let deleteTagId = issueTags.filter(tag => !filteredId.includes(tag.id))
      removeIssueTags(deleteTagId[0].id)
    }else{
      addTags(args[2][args[2].length - 1])
    }
  }
  let assigneeListOptions = assigneeList
  if( issueDetail && issueDetail.assignee && issueDetail.assignee.id != 0){
    assigneeListOptions = assigneeList.map(user => ({ id: user.id , name:user.name})).filter(user => user.id == issueDetail.assignee.id).length ?
      assigneeList.map(user => ({...user}))
      : [...assigneeList.map(user => ({...user})), {...issueDetail.assignee}]
  }
  return(
    <Paper square className="other-info">

        <Grid container spacing={2}>
              <Grid item xs={6} md={4}>
                <Autocomplete
                  options = {[{label:'Open',value:0},{label:'Closed',value:1}, {label:'Reopen',value:2}]}
                  disableClearable={true}
                  value={issueDetail.status ?issueDetail.status:null}
                  getOptionLabel={option => option.label}
                  disabled={!editable}
                  filterSelectedOptions={true}
                  onChange={(e,priority) => {
                    onFieldChange(e,'status', priority )
                    setTimeout(() => updatePRSSectionsField('status', priority.value), 1000)
                  }}
                  renderInput={params => <TextField {...params} label="Status" margin="normal" fullWidth InputLabelProps={{shrink: true}} required/>}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                    clearable
                    margin="normal"
                    disabled={!editable}
                    id="started_date"
                    label="Discrepancy Raised on"
                    placeholder="DD-MM-YYYY"
                    format={"DD/MM/YYYY"}
                    fullWidth
                    disableFuture={true}
                    maxDateMessage="Please enter date after today"
                    maxDate={issueDetail.estimated_completion_date ? moment(issueDetail.estimated_completion_date) : moment()}
                    InputLabelProps={{shrink: true}}
                    value={issueDetail.started_date !== undefined && issueDetail.started_date !== null && issueDetail.started_date !== "" ? issueDetail.started_date: null }
                    onChange={(data, value) => {
                      onFieldChange(value, 'started_date', data ? moment(data).format(backendDateFormat): data)
                      setTimeout(() => {
                        if(data){
                          if(issueDetail.estimated_completion_date){
                            if(moment(data).diff(moment(issueDetail.estimated_completion_date), 'days') < 0){
                              updatePRSSectionsField('started_date', data ? moment(data).format(backendDateFormat): data)
                            }else{
                              toastFlashMessage('Date cannot be greater than or equal to end date', 'error');
                            }
                          }else{
                            updatePRSSectionsField('started_date', data ? moment(data).format(backendDateFormat): data)
                          }
                        }else{
                          updatePRSSectionsField('started_date', '')
                        }
                      })
                    }}
                />
                </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} md={4}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                    clearable
                    margin="normal"
                    id="estimated_completion_date"
                    label="Discrepancy Resolved on"
                    placeholder="DD/MM/YYYY"
                    disabled={!editable}
                    format={"DD/MM/YYYY"}
                    fullWidth
                    disableFuture={true}
                    minDateMessage="Please enter date after Discrepancy Raised on"
                    maxDate={moment.utc()}
                    minDate={issueDetail.started_date ? moment(issueDetail.started_date) : null}
                    InputLabelProps={{shrink: true}}
                    value={issueDetail.estimated_completion_date ? issueDetail.estimated_completion_date : null }
                    onChange={(data, value) => {
                      onFieldChange(value, 'estimated_completion_date', data ? moment(data).format(backendDateFormat): data)
                      setTimeout(() => {
                        if(data){
                          if(moment(issueDetail.started_date).diff(moment(data), 'days') < 0){
                            updatePRSSectionsField('estimated_completion_date', data ? moment(data).format(backendDateFormat): data)
                          }else{
                            toastFlashMessage('Date cannot be less or equal to start date', 'error');
                          }
                        }else{
                          updatePRSSectionsField('estimated_completion_date', '')
                        }
                      })
                    }}
                />
                </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={6} md={4}>
                <TextField
                  id="ata"
                  label='ATA'
                  fullWidth
                  disabled={!editable}
                  margin="normal"
                  inputProps={{ maxLength: 10 }}
                  InputLabelProps={{shrink: true}}
                  value={issueDetail.ata !== undefined && issueDetail.ata !== null && issueDetail.ata !== 'null' ? issueDetail.ata : ""}
                  onChange={(e) => onFieldChange(e,'ata', e.target.value)}
                  onBlur={(e) => updatePRSSectionsField('ata', e.target.value)}
                />
            </Grid>
            <Grid item xs={6} md={4}>
                <TextField
                  id="part_number"
                  label='Part Number'
                  fullWidth
                  margin="normal"
                  disabled={!editable}
                  inputProps={{ maxLength: 20 }}
                  InputLabelProps={{shrink: true}}
                  value={issueDetail.part_number !== undefined && issueDetail.part_number !== null && issueDetail.part_number !== 'null' ? issueDetail.part_number : ""}
                  onChange={(e) => onFieldChange(e,'part_number', e.target.value)}
                  onBlur={(e) => updatePRSSectionsField('part_number', e.target.value)}
                />
            </Grid>
            <Grid item xs={6} md={4}>
                <TextField
                  id="serial_number"
                  label='Serial Number'
                  fullWidth
                  margin="normal"
                  disabled={!editable}
                  inputProps={{ maxLength: 20 }}
                  InputLabelProps={{shrink: true}}
                  value={issueDetail.serial_number !== undefined && issueDetail.serial_number !== null && issueDetail.serial_number !== 'null' ? issueDetail.serial_number : ''}
                  onChange={(e) => onFieldChange(e,'serial_number', e.target.value)}
                  onBlur={(e) => updatePRSSectionsField('serial_number', e.target.value)}
                />
            </Grid>
            {
              issueDetail.gap_report_section_id && [2,5,6,7,8,9,10,11,21,22,14,15,16,17,23,24,25,26,27,28,29,30,31,32,33,34,35].includes(issueDetail.gap_report_section_id.id) ?
              <Grid item xs={6} md={4}>
                  <TextField
                    id="ata"
                    label='Serial Number'
                    fullWidth
                    disabled={!editable}
                    margin="normal"
                    inputProps={{ maxLength: 10 }}
                    InputLabelProps={{shrink: true}}
                    value={issueDetail.serial_number !== undefined && issueDetail.serial_number !== null && issueDetail.serial_number !== 'null' ? issueDetail.serial_number : ''}
                    onChange={(e) => onFieldChange(e,'serial_number', e.target.value)}
                    onBlur={(e) => updatePRSSectionsField('serial_number', e.target.value)}
                  />
              </Grid>
              :null
            }
            <Grid item xs={6} md={4}>
                <Autocomplete
                  options = {t011ReportInfo.list ? t011ReportInfo.list.map(cat => {
                    return {value: cat.id, label: cat.name}
                  }):[]}
                  clearOnBlur={false}
                  disableClearable={true}
                  multiple={false}
                  value={issueDetail.gap_report_section !== null && issueDetail.gap_report_section.id !== null ? {value: issueDetail.gap_report_section.id, label: issueDetail.gap_report_section.name} : {value: issueDetail.gap_report_section} }
                  getOptionLabel={option => option.label}
                  filterSelectedOptions={true}
                  disabled={!editable}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    // Suggest the creation of a new value
                    if (params.inputValue !== '') {
                      filtered.push({
                        inputValue: params.inputValue,
                        label: `Add "${params.inputValue}"`,
                      });
                    }
                    return filtered;
                  }}
                  onChange={(...args) => {
                    let category = args[1]
                    onFieldChange(null,'gap_report_section', category)
                    setTimeout(() => {
                      updatePRSSectionsField('gap_report_section', category.value ? category.value : category.inputValue)
                    }, 1000)
                  }}
                  renderInput={params => <TextField {...params} label="Category" margin="normal" fullWidth InputLabelProps={{shrink: true}} required/>}
                />
                {
                  issueDetail.gap_report_section === '' || issueDetail.gap_report_section === null || Object.keys(issueDetail.gap_report_section).length === 0 ?
                    <h6 className="error-msg">Please select Category</h6>
                  :null
                }
            </Grid>
            <Grid item xs={6} md={4}>
              <TextField
                id="position"
                label='Position'
                fullWidth
                margin="normal"
                disabled={!editable}
                inputProps={{ maxLength: 30 }}
                InputLabelProps={{shrink: true}}
                value={issueDetail.position !== undefined && issueDetail.position !== null && issueDetail.position !== 'null' ? issueDetail.position : ''}
                onChange={(e) => onFieldChange(e,'position', e.target.value)}
                onBlur={(e) => updatePRSSectionsField('position', e.target.value)}
              />
          </Grid>
          <Grid item xs={6} md={4}>
              <TextField
                id="cycles_remaining"
                label='Cycles Remaining'
                fullWidth
                margin="normal"
                disabled={!editable}
                inputProps={{ maxLength: 10 }}
                InputLabelProps={{shrink: true}}
                value={issueDetail.cycles_remaining !== undefined && issueDetail.cycles_remaining !== null && issueDetail.cycles_remaining !== 'null' ? issueDetail.cycles_remaining : ''}
                onChange={(e) => {numberRegx.test(e.target.value) || e.target.value === '' ? onFieldChange(e,'cycles_remaining', e.target.value):e.preventDefault()} }
                onBlur={(e) => updatePRSSectionsField('cycles_remaining', e.target.value)}
              />
          </Grid>
          <Grid item xs={12} md={4}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                  clearable
                  margin="normal"
                  id="install_date"
                  label="Install Date"
                  disabled={!editable}
                  placeholder="DD-MM-YYYY"
                  format={"DD/MM/YYYY"}
                  fullWidth
                  disableFuture={true}
                  InputLabelProps={{shrink: true}}
                  value={issueDetail.install_date ? issueDetail.install_date: null }
                  onChange={(data, value) => {
                    onFieldChange(value, 'install_date', data ? moment(data).format(backendDateFormat): data)
                    setTimeout(() => updatePRSSectionsField('install_date', data ? moment(data).format(backendDateFormat): data))
                  }}
              />
              </MuiPickersUtilsProvider>
          </Grid>
              <Grid item xs={6} md={4}>
                <Autocomplete
                  options = {[{label:'None',value:0},{label:'P1',value:1},{label:'P2',value:2}]}
                  clearOnBlur={false}
                  disabled={!editable}
                  disableClearable={true}
                  value={issueDetail.priority ? issueDetail.priority : null }
                  getOptionLabel={option => option.label}
                  filterSelectedOptions={true}
                  onChange={(e,priority) => {
                    onFieldChange(e,'priority', priority )
                    setTimeout(() => updatePRSSectionsField('priority', priority && priority.value ? priority.value : 0), 1000)
                  }}
                  renderInput={params => <TextField {...params} label="Priority" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                />
            </Grid>
            <Grid item xs={6} md={4}>
                <Autocomplete
                  disableClearable={true}
                  options = {[...assigneeListOptions, {name: 'Unassigned', id: 0}]}
                  clearOnBlur={false}
                  value={issueDetail.assignee ? issueDetail.assignee : null }
                  getOptionLabel={option => option.name}
                  filterSelectedOptions={true}
                  disabled={!editable}
                  onChange={(e,assignee) => {
                    onFieldChange(e,'assignee', assignee)
                  }}
                  renderInput={params => <TextField {...params} label="Assignee" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                />
            </Grid>
            <Grid item xs={6} md={4}>
              <AutocompleteCreatable
                options = {lessorTags}
                clearOnBlur={false}
                getOptionLabel={option => option.label}
                chips={true}
                value={dtlTags}
                label='Tags'
                disabled={!editable}
                filterSelectedOptions={true}
                optionKey="Tag"
                onFieldChange={(...args) => addIssueTag(args)}
                renderInput={params => <TextField {...params} label="Tags" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
              />
            </Grid>
            <Grid item xs={12}>
                {/* <Button color="primary" variant="contained" onClick="">Add Custom Fields</Button> */}
            </Grid>
        </Grid>
    </Paper>
  )
}

export default OtherInfo;
