import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
export default class ProjectInfoBar extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }
  render(){
    return(
      <div>
        <Row>
          <Col md="6">
            <ul className="list-inline">
              <li className="list-inline-item">
                <h6>Form Switcher</h6>
              </li>
              <li className="list-inline-item">
                <button>Save</button>
              </li>
              <li className="list-inline-item">
                <button>Submit for Review</button>
              </li>
              <li className="list-inline-item">
                Download Report
              </li>
            </ul>
          </Col>
          <Col md="6">
            <ul className="list-inline">
              <li className="list-inline-item">
                <span>Project Engineer</span>
                <h6></h6>
              </li>
              <li className="list-inline-item">
                <span>Last Activity</span>
                <h6></h6>
              </li>
              <li className="list-inline-item">
                <span>Status</span>
                <button></button>
              </li>
            </ul>
          </Col>
        </Row>
      </div>
    )
  }
}
