import React, {Fragment} from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { Grid } from "@material-ui/core"
const TaskIssueLoader = () => {
    return(
        <Fragment>
            <div style={{marginBottom:'15px'}}><Skeleton variant="rect" width={'100%'} height={78} /></div>
            <div style={{marginBottom:'15px'}}><Skeleton variant="rect" width={'100%'} height={78} /></div>
            <div><Skeleton variant="rect" width={'100%'} height={340} /></div>
        </Fragment>
    )
}
export default TaskIssueLoader;