import React, { Component } from 'react';
import { Button, Popover, PopoverBody} from 'reactstrap';
import { imgStoragePath } from '../../../../constants';
import { ToolTipHover } from '../../../../shared';
import InfoIcon from '../../../../shared/assets/img/info_icon.svg';
export default class PopoverItem extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      popoverOpen: false

    };
  }

  toggle() {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }



  render() {
    const {  tagetId, placement, files } = this.props;
    return (
		<span>
		<img className="img_eye" width="12" style={{ marginLeft: '5px'}} id={tagetId} onClick={this.toggle} src={(this.state.popoverOpen ? InfoIcon : InfoIcon) } alt="img"/>
		<ToolTipHover placement="right" tagetId={tagetId}>
    { this.props.assets.map((asset,index) =>
					<div className="popover-div" key={index}>
							<span style={{marginBottom:'5px'}} >
							<span>{asset.asset_type == '1' ? 'MSN ' + asset.msn : asset.asset_type == '2' ? 'ESN ' + asset.esn : asset.asset_type == '3' ? 'APU ' + asset.serial_number : asset.asset_type == '4' ? 'LG ' + asset.serial_number : asset.asset_type == '5' ? 'PROPELLER ' + asset.serial_number :'' } </span>
							</span>
					</div>
				)}
      </ToolTipHover>

		</span>
    );
  }
}
