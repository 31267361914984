import React from "react";
import Skeleton from '@material-ui/lab/Skeleton';
import { Grid } from '@material-ui/core';
const ProjectViewsSkeleton = ({}) => {
    return(
        <div>
            <Skeleton style={{marginBottom:'10px'}} variant="rect" height={40} />
            <Grid container alignItems='center' spacing={1}>
                <Grid item xs={12} md={8}>
                    <Skeleton variant="rect" width={'100%'} height={480} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Skeleton variant="rect" width={'100%'} height={480} />
                </Grid>
            </Grid>
        </div>
    )
}
export default ProjectViewsSkeleton;