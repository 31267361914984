import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router'
import { Link } from 'react-router';
import { Grid, TextField, Button } from '@material-ui/core';
import { TableListComp, NoRecordFound } from '../../shared';
import { Row } from 'reactstrap'
import { UserManageSideBar } from '../components';
import {TagsList} from '../../User/components/';
import { globalGetService, globalPostService, globalPutService } from '../../globalServices'
import {tagsHd} from '../';
// import closeIcon from '../../../../../User/assets/img/black_close.png'

class Tags extends Component{
  constructor(props){
    super(props);
    this.state = {
      pageLoader:false,
      tags: [],
      tagName: '',
      tagID: null
    }
  }

  componentDidMount(){
    this.fetchTags()
  }

  fetchTags = () =>{
    this.setState({ pageLoader: true, tags: [] });
    globalGetService(`/technical/prs-lessor-tags/`)
    .then(response => {
      this.setState({ pageLoader: false });
      if(response.data.statusCode === 200){
        this.setState({tags: response.data.data})
      }
    })
  }

  addTag = () =>{
    if(this.state.tagID !== null){
      globalPutService(`/technical/prs-lessor-tags/${this.state.tagID}/`, {name: this.state.tagName})
      .then(response => {
        this.setState({ pageLoader: false });
        if(response.data.statusCode === 200){
          this.setState({tagName: '', tagID: null})
          this.fetchTags()
        }
      })
    }else{
      globalPostService(`/technical/prs-lessor-tags/`, {name: this.state.tagName})
      .then(response => {
        this.setState({ pageLoader: false });
        if(response.data.statusCode === 200){
          this.fetchTags()
        }
      })
    }
  }

  render(){
    const { pageLoader, tags } = this.state;
    return(
      <div className="contract-container user-profile-blk">
        <UserManageSideBar/>
        <section className="tags-list" style={{marginLeft: '270px'}}>
            <h4 className="tags-heading">Tags</h4>
            <Grid container spacing={3} alignItems="center">
                <Grid item xs={12}>
                    <ul className="list-inline flex-centered">
                        <li className="list-inline-item">
                            <TextField
                                id="tags"
                                inputProps={{ maxLength: 20 }}
                                value={this.state.tagName}
                                onChange={(event) => this.setState({tagName: event.target.value})}
                            />
                        </li>
                        <li className="list-inline-item">
                            <Button onClick={() => this.addTag()} disabled={this.state.tagName === ''} color="primary" variant="contained">{this.state.tagID !== null ? "Update" : "Add"} Tag</Button>
                            <Button style={{marginLeft: '10px'}} disabled={this.state.tagName === ''} onClick={() => this.setState({tagName: '', tagID: null})()} color="primary" variant="outlined">Cancel</Button>
                        </li>
                    </ul>
                </Grid>
                <Grid item xs={12}>
                    <TableListComp
                        heads={tagsHd}
                        style={{height: window.innerHeight - 250 + 'px'}}
                        noRecord={tags.length > 0 && !this.state.pageLoader ? null : <NoRecordFound/>}
                        data={ tags.map((item,index) =>
                        <TagsList
                            item={item}
                            index={index}
                            editTag={() => this.setState({tagName: item.name, tagID: item.id})}
                        />
                    )}
                    />
                </Grid>
            </Grid>
        </section>
      </div>
    )
  }
}

export default withRouter(Tags);
