import React, { useState, useEffect } from "react";
import { useSnackbar } from 'notistack';
import { withRouter } from "react-router";
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, Grid, Button, TextField, Dialog, DialogContent, DialogTitle, DialogActions, Tooltip, CircularProgress } from '@material-ui/core';
import { Pagination, STableLoader, PageLoader, EmptyCollection, DeletePopUp } from "../../../shared_elements";
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { globalDeleteService, globalGetService, globalPostService, globalPutService } from "../../../globalServices";
import { checkApiStatus, convertStringToNumber, onCheckFormPermission } from '../../../utils_v2';
import { regexConstants } from "../../../constants/regEx";
import AvionicsImport from "./AvionicsImport";
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
const avionicObj = {
    chapter: '',
    description: '',
    quantity: null,
    manufacturer: '',
    family_code: '',
    part_no: '',
}
const AvionicHd = ({ currentFormDetail }) => {
    return (
        <TableHead>
            <TableRow>
                {onCheckFormPermission(currentFormDetail) ?
                <TableCell>Action(s)</TableCell> : null}
                <TableCell>ATA Chapter</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Manufacturer</TableCell>
                <TableCell>Part Number</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Family Code</TableCell>
            </TableRow>
        </TableHead>
    )
}
const AvionicList = ({ item, onEdit, onDelete, currentFormDetail }) => {
    const [showMore, setShowMore] = useState({});
    const toggleDescription = (field) => {
        setShowMore({
            ...showMore,
            [field]: !showMore[field],
        });
    };
    return (
        <TableRow>
            {onCheckFormPermission(currentFormDetail) ?
                <TableCell>
                    <ul className="list-inline" style={{ width: '60px', float: '' }}>
                        <li className="list-inline-item hover-inline" onClick={onEdit}>
                            <Tooltip title='Edit' style={{ cursor: 'pointer' }} arrow><EditIcon color="primary" fontSize="small" /></Tooltip>
                        </li>
                        <li className="list-inline-item hover-inline" onClick={onDelete}>
                            <Tooltip title='Delete' style={{ cursor: 'pointer' }} arrow><DeleteOutlineIcon color="secondary" fontSize="small" /></Tooltip>
                        </li>
                    </ul>
                </TableCell> : null
            }
            <TableCell>
                {item?.chapter && item?.chapter?.length > 30 ? (
                    <div style={{ width: '225px' }} >
                        {showMore[item?.field] && showMore[item?.field] ? (
                            <span> {item?.chapter}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.field); }}  >  less </a>
                            </span>
                        ) : (
                            <span> {item.chapter.substring(0, 25)}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.field); }}  > ..more </a>
                            </span>
                        )}
                    </div>
                ) :
                    <div style={{ width: '115px' }}>{item?.chapter || '--'}</div>
                }
            </TableCell>
            <TableCell>
                {item?.description && item?.description?.length > 30 ? (
                    <div style={{ width: '225px' }} >
                        {showMore[item?.field] && showMore[item?.field] ? (
                            <span> {item?.description}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.field); }}  >  less </a>
                            </span>
                        ) : (
                            <span> {item.description.substring(0, 25)}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.field); }}  > ..more </a>
                            </span>
                        )}
                    </div>
                ) :
                    <div style={{ width: '115px' }}>{item.description || '--'}</div>
                }
            </TableCell>
            <TableCell>
                {item?.manufacturer && item?.manufacturer?.length > 30 ? (
                    <div style={{ width: '225px' }} >
                        {showMore[item?.field] && showMore[item?.field] ? (
                            <span> {item?.manufacturer}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.field); }}  >  less </a>
                            </span>
                        ) : (
                            <span> {item.manufacturer.substring(0, 25)}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.field); }}  > ..more </a>
                            </span>
                        )}
                    </div>
                ) :
                    <div style={{ width: '115px' }}>{item?.manufacturer || '--'}</div>
                }
            </TableCell>
            <TableCell>
                {item?.part_no && item?.part_no?.length > 30 ? (
                    <div style={{ width: '225px' }} >
                        {showMore[item?.field] && showMore[item?.field] ? (
                            <span> {item?.part_no}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.field); }}  >  less </a>
                            </span>
                        ) : (
                            <span> {item.part_no.substring(0, 25)}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.field); }}  > ..more </a>
                            </span>
                        )}
                    </div>
                ) :
                    <div style={{ width: '115px' }}>{item?.part_no || '--'}</div>
                }
            </TableCell>
            <TableCell>
                <div style={{ width: '100px' }}>{item?.quantity === 0 ? '0' : item?.quantity || '--'}</div>
            </TableCell>
            <TableCell>
                {item?.family_code && item?.family_code?.length > 30 ? (
                    <div style={{ width: '225px' }} >
                        {showMore[item?.field] && showMore[item?.field] ? (
                            <span> {item?.family_code}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.field); }}  >  less </a>
                            </span>
                        ) : (
                            <span> {item.family_code.substring(0, 25)}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.field); }}  > ..more </a>
                            </span>
                        )}
                    </div>
                ) :
                    <div style={{ width: '115px' }}>{item?.family_code || '--'}</div>
                }
            </TableCell>
        </TableRow>
    )
}
const AddEditAvionic = ({ params, addEditAvionic, toggleModalFn, getResponseBack }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [avionic, setAvionic] = useState(addEditAvionic.data);
    const [error, setError] = useState({});
    const onFieldChange = (key, value) => {
        setAvionic({ ...avionic, [key]: value.trimStart() })
    }
    const onAddEditAvionic = () => {
        const payload = Object.assign({}, convertStringToNumber(!avionic.id ? { configurations: [avionic] } : avionic, ['quantity']))
        setLoading(true);
        if (avionic.id) {
            globalPutService(`technical/workorder/${params.workOrderSlug}/t014/avioinc-configurations/${avionic.id}/?type=T014&asset_type=${params.type}`, payload)
                .then(response => {
                    if (checkApiStatus(response)) {
                        enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                        toggleModalFn(); getResponseBack();
                    }
                    setLoading(false);
                })
        } else {
            globalPostService(`technical/workorder/${params.workOrderSlug}/t014/avioinc-configurations/?type=T014&asset_type=${params.type}`, payload)
                .then(response => {
                    if (checkApiStatus(response)) {
                        enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                        toggleModalFn(); getResponseBack();
                    }
                    setLoading(false);
                })
        }
    }
    const isFieldEmpty = () => {
        if(avionic.id){
            return Object.entries(avionic).filter(([key]) => key !== 'id').some(([_, value]) => value !== '' && value !== null);        
        }else{
            return Object.values(avionic).some(value => value !== '' && value !== null);
        }
    };
    return (
        <Dialog
            open={addEditAvionic.modal}
            onClose={toggleModalFn}
            className='project-management-modal'
            maxWidth="md"
        >
            <DialogTitle id="scroll-dialog-title">
                {avionic?.id ? 'Edit ' : 'Add '} Avionic
            </DialogTitle>
            <DialogContent dividers={true}>
                <Grid container spacing={1}>
                    <Grid item md={6}>
                        <TextField
                            name='chapter'
                            label='ATA Chapter'
                            value={avionic?.chapter || ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('chapter', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                            inputProps={{ maxLength: 255 }}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            name='quantity'
                            label='Quantity'
                            value={avionic?.quantity === 0 ? '0' : avionic?.quantity || ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange('quantity', e.target.value) : e.preventDefault()}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                            inputProps={{ maxLength: 9 }}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <TextField
                            name='manufacturer'
                            label='Manufacturer'
                            value={avionic?.manufacturer || ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('manufacturer', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={4}>
                        <TextField
                            name='part_no'
                            label='Part Number'
                            value={avionic?.part_no || ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('part_no', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 255 }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={4}>
                        <TextField
                            name='family_code'
                            label='Family Code '
                            value={avionic?.family_code || ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('family_code', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                            inputProps={{ maxLength: 255 }}
                        />
                    </Grid>
                    <Grid item md={12}>
                        <TextField
                            name='description'
                            label='Description'
                            value={avionic?.description || ''}
                            fullWidth
                            multiline
                            rows={3}
                            margin="normal"
                            onChange={(e) => onFieldChange('description', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 255 }}
                            variant='outlined'
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} onClick={toggleModalFn} color='primary' size='small' variant='outlined'>Cancel</Button>
                <Button disabled={isLoading || !isFieldEmpty()} onClick={onAddEditAvionic} color='primary' size='small' variant='contained'>
                    {isLoading ? <CircularProgress size={24} /> : 'Save'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
const Avionics = ({ params, getCurrentFormDetail, currentFormDetail }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [filter, setFilter] = useState({});
    const [sortInfo, setSortInfo] = useState({ sort: '', sort_by: '' });
    const [avionicsInfo, setAvionicsInfo] = useState({ list: [], pagination: null });
    const [addEditAvionic, setAddEditAvionic] = useState({ modal: false, data: null });
    const [deleteAvionic, setDeleteAvionic] = useState({ modal: false, data: null });
    useEffect(() => {
        getAvionics('skeletonLoader');
    }, []);
    const getAvionics = (query, loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true) : setLoading(true)
        globalGetService(`technical/workorder/${params.workOrderSlug}/t014/avioinc-configurations/?type=T014&asset_type=${params.type}`,query)
            .then(response => {
                if (checkApiStatus(response)) {
                    setAvionicsInfo(response.data.data)
                    getCurrentFormDetail()
                }
                loaderType === 'skeletonLoader' ? setSkeletonLoader(false) : setLoading(false)
            })
    }
    const onDeleteAvionic = () => {
        setLoading(true);
        globalDeleteService(`technical/workorder/${params.workOrderSlug}/t014/avioinc-configurations/${deleteAvionic.data.id}/?type=T014&asset_type=${params.type}`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setDeleteAvionic({ modal: false, data: null });
                    enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                    getAvionics({}, 'pageLoader');
                }
                setLoading(false);
            })
    }
    return (
        <>
            <div className="tech-specs-content">
                {skeletonLoader ? <STableLoader count={8} /> :
                    <div style={{ padding: '10px' }}>
                        <Paper square style={{ padding: '4px 8px' }}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item md={9}></Grid>
                                <Grid item md={3}>
                                    {onCheckFormPermission(currentFormDetail) ?
                                        <ul className="list-inline" style={{ float: 'right' }}>
                                            <li className="list-inline-item">
                                                <AvionicsImport getResponseBack={() => getAvionics({})} />
                                            </li>
                                            <li className="list-inline-item">
                                                <Button onClick={() => setAddEditAvionic({ modal: true, data: avionicObj })} color="primary" size='small' variant="contained"><AddOutlinedIcon style={{ fontSize: '16px' }} />ADD</Button>
                                            </li>
                                        </ul> : null
                                    }
                                </Grid>
                            </Grid>
                        </Paper>
                        <Paper style={{ maxHeight: window.innerHeight - 235 + 'px', overflow: "auto" }}>
                            <Table className='mui-table-format' stickyHeader>
                                <AvionicHd currentFormDetail={currentFormDetail} />
                                <TableBody>
                                    {avionicsInfo.list?.map((item, index) =>
                                        <AvionicList
                                            key={index}
                                            item={item}
                                            currentFormDetail={currentFormDetail}
                                            onEdit={() => setAddEditAvionic({ modal: true, data: item })}
                                            onDelete={() => setDeleteAvionic({ modal: true, data: item })}
                                        />
                                    )}
                                </TableBody>
                            </Table>
                        </Paper>
                        {avionicsInfo?.pagination ?
                            <Paper>
                                <Pagination
                                    pagination={avionicsInfo.pagination}
                                    onChangePage={(event, newPage) => getAvionics({ ...filter, page: newPage + 1, per_page: avionicsInfo.pagination.per_page }, 'pageLoader')}
                                    onChangeRowsPerPage={(event) => getAvionics({ ...filter, page: 1, per_page: event.target.value }, 'pageLoader')}
                                />
                            </Paper>
                            : null
                        }
                        <Paper>
                            {!avionicsInfo?.list?.length ? <div style={{ textAlign: 'center' }}><EmptyCollection title='No records found' /></div> : null}
                        </Paper>
                    </div>
                }
            </div>
            {addEditAvionic.modal ?
                <AddEditAvionic
                    params={params}
                    addEditAvionic={addEditAvionic}
                    toggleModalFn={() => setAddEditAvionic({ modal: false, data: null })}
                    getResponseBack={() => getAvionics({}, 'pageLoader')}
                /> : null
            }
            {deleteAvionic.modal ?
                <DeletePopUp
                    modal={deleteAvionic.modal}
                    title='Delete Avionic'
                    content={`Are you sure, you want to Delete?`}
                    toggleModalFn={() => setDeleteAvionic({ modal: false, data: null })}
                    deleteRecordFn={onDeleteAvionic}
                /> : null
            }
            {isLoading ? <PageLoader /> : null}
        </>

    )
}
export default withRouter(Avionics);