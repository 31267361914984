import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { DeleteModal, PrerequisiteWrapper, EditFormBar, ReviewCollapse, SecondaryTechHeaderRH, SecondaryTechHeaderMid, FormHeader, DownloadedLogs } from '../../../Elements';
import { downLoadTechEngineFormAc, usageDetailAc } from '../../../../../shared/actionCreators'
import { GenralDetail06Wrapper, BulkOperation, ShopvisitFileList, ReviewCollapseMr } from '../components';
import { getFormDetailsAc, updateFormStatusAc } from '../../T001/actionCreators';
import { getMrSectionDetailsAC, updateMrSectionDetailsAc, deleteMrAttachmentAc, GenUrlAc, deleteMrSectionAc } from '../actionCreators';
import { ExportFiles, MSNSwitcherHeader, ListLoader, TableUIComp, NoRecordFound, LicenceModal } from '../../../../../shared';
import { imgStoragePath } from '../../../../../constants';
import * as actions from '../actions';
import { removeEmptyKey } from '../../../../../utils'
import { mrSectionData } from '../../AddFormObjects';
import { sGlobalConstantAc } from '../../../../../shared/actionCreators';
import { checkString, checkEmail, checkValidDate, checkFloat, checkIntNumber } from '../../../../../formValidator';
import { BUY_LICENCE } from '../../../../../shared/actions';
import { getLocalStorageInfo } from '../../../../../utils';
import { trackActivity } from '../../../../../utils/mixpanel';

class InspectionFormTE006 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      preReqModal: false,
      delCompModal: false,
      deleteComponentIndex: '',
      deleteComponentTitle: '',
      deleteCompoId: '',
      deleteSection: '',
      downloadFormLogs: false,
      error: {}
    }
  }
  componentDidMount() {
    this.props.getFormDetails(2, 'TE006');
    this.props.getMrSectionDetails();
    this.props.fetchTechConstant({ constant_types: ['mr_type_of_report'] });
    if (getLocalStorageInfo().user.permission.records && getLocalStorageInfo().user.permission.records.is_saas) {
      this.props.usageDetail()
    }
  }
  toggleDownloadedLogs = () => {
    this.setState(prevState => ({
      ...prevState,
      downloadFormLogs: !prevState.downloadFormLogs
    }))
  }
  togglePrerequisiteModal = () => {
    this.setState({
      preReqModal: !this.state.preReqModal,
    });
  }
  updateMrSectiontDetails = (mrSectionDet) => {

    let validateNewInput = {};
    validateNewInput = Object.keys(mrSectionDet).map(dataKey => {
      if (dataKey == 'details') {
        return (
          [dataKey] = [{
            ...validateNewInput,
            report_date: checkValidDate({ value: mrSectionDet.details.report_date, required: true, minLength: '', maxLength: '', message: 'Please enter valid date' }),
            report_type: checkString({ value: mrSectionDet.details.report_type, required: true, minLength: '', maxLength: '', message: 'Please select the Report Type' }),
            report_issued_place: checkString({ value: mrSectionDet.details.report_issued_place, required: true, minLength: '', maxLength: '', message: 'Please enter Report Issued Place' }),
          }]
        )
      } else if (dataKey == 'sections') {
        return (
          [dataKey] = mrSectionDet.sections.map((compData, compIndex) => compData ? {
            title: checkString({ value: compData.title, required: true, minLength: '', maxLength: '', message: 'Please enter title' }),
          } : compData
          )
        )
      }
    })
    validateNewInput = removeEmptyKey(validateNewInput);
    let validationFlag = validateNewInput.map(validateInput =>
      validateInput.map(innerArray => Object.keys(innerArray).every((k) => { return innerArray[k] === '' }))
    );

    if (validationFlag.filter(validData => validData.includes(false)).length == 0) {
      this.props.updateMrSectionDetailsFn(mrSectionDet);
    } else {
      this.props.errFn(validateNewInput)
      trackActivity('TE006', {
        event_type: 'Save MR Section Details Failed',
        page_title: 'MR Claim and Invoice Review',
        workorder_slug: this.props.params.workOrderSlug ? this.props.params.workOrderSlug : '',
        form_slug: this.props.params.formSlug ? this.props.params.formSlug : '',
        error_source: 'Frontend',
        error_type: 'Validation',
        error_details: validateNewInput ? validateNewInput : '',
        asset_type: this.props.params.type ? this.props.params.type : '',
      })
    }
  }

  toggleDelAttachmentModal = (id = null, type = null, index) => {
    this.setState({
      delAttachmentModal: !this.state.delAttachmentModal,
      deleteType: type,
      deleteId: id,
      deleteIndex: index
    });
  }

  GenUrlFn = (files, type) => {
    if (getLocalStorageInfo().user.permission.records && getLocalStorageInfo().user.permission.records.is_saas) {
      let filesSize = 0
      for (let file of files) {
        filesSize += file.size
      }
      if (this.props.storageUsage.used + filesSize >= this.props.storageUsage.available) {
        this.props.buyLicence({
          flag: true,
          action: '/licenses/storage',
          content: 'The uploaded file(s) size is not available in storage.'
        })
        return;
      }
    }
    this.props.GenUrlFn(files, type)
  }

  toggleDelSectionModal = (id = null, type = null, index) => {
    this.setState({
      delSectionModal: !this.state.delSectionModal,
      deleteType: type,
      deleteId: id,
      deleteIndex: index
    });
  }
  updateForm = (obj) => {
    this.props.updateForm(obj);
    this.props.clearErr({ type: obj.type, sectionType: obj.sectionType, index: obj.index ? obj.index : 0 });
  }

  render() {
    const { mrSectionDet, mrSectionLoader, techConstants, error, techExportLoader, formStatusLoader, formInfo } = this.props;
    return (
      <div className="technical-inspection-cn">
        <MSNSwitcherHeader
          SecodaryHeaderRH={<SecondaryTechHeaderRH projectInfo={this.props.formInfo.project} />}
          SecondaryHeaderMid={<SecondaryTechHeaderMid projectInfo={this.props.formInfo.project} />}
          activeAsset={this.props.formInfo.asset}
          projectSlug={Object.keys(this.props.formInfo).length ? this.props.formInfo.project.slug : ''}
        />
        <FormHeader
          formInfo={this.props.formInfo}
          saveButton={<Link style={mrSectionLoader ? { pointerEvents: 'none', opacity: '0.5' } : { pointerEvents: 'auto', opacity: '1' }} onClick={() => this.updateMrSectiontDetails(mrSectionDet)} className="primary-btn"> {mrSectionLoader ? 'Processing...' : 'Save'} </Link>}
          exportReport={<ExportFiles exportFile={(fileType) => this.props.exportReport('mr-sections', 'ESN_' + this.props.formInfo.asset.esn + '_' + this.props.formInfo.current_form.name, fileType)}
            exportName={'Export Report '}
            files={[{ img: 'pdf_icon.png', label: 'PDF', type: 'pdf' }]} />}
          updateFormStatus={(data) => this.props.updateFormStatus(data)}
          toggleDownloadedLogs={this.toggleDownloadedLogs}
        />
        <div className="technical-inspection-ee">
          <h4 className="tech-heading-block flex-centered">
            General Details
            <span onClick={() => { this.togglePrerequisiteModal(); trackActivity('TE006', { event_type: 'Clicked', page_title: 'MR Claim and Invoice Review', item_type: 'Read Prerequisite' }) }} className="pre-requisite flex-centered">

              <img src={imgStoragePath + "read_pre_icon.png"} alt="img" className="blue-img" />
              Read Prerequisite
            </span>
          </h4>
          {mrSectionDet.details ?
            <GenralDetail06Wrapper
              mrSectionDetails={mrSectionDet.details}
              updateForm={this.updateForm}
              error={error && error[0] ? error[0][0] : ''}
              techConstants={techConstants}
            /> : null}

        </div>
        <div className="form-te006-cn">
          {formStatusLoader ||
            techExportLoader ?
            <ListLoader />
            : null
          }
          <div>
            <h4 className="tech-heading-block flex-centered">
              Sections
              {
                formInfo?.current_form?.permissions?.can_save?
                <Link className="add-section" onClick={() => { this.props.addReviewCollapse(); trackActivity('TE006', { event_type: 'Section Added', page_title: 'MR Claim and Invoice Review' }) }}>+ ADD SECTION</Link>
                  :null
              }
            </h4>
            {mrSectionDet.sections && mrSectionDet.sections.length ?
              mrSectionDet.sections.map((mrSections, index) =>
                <ReviewCollapseMr
                  updateReviewCollapse={this.updateForm}
                  type={mrSections.id}
                  reviewIndex={true}
                  removeReviewCollapse={() => this.toggleDelSectionModal(mrSections.id, 'section', index)}
                  updateCkEditer={this.props.updateCkEditer}
                  review={mrSections}
                  key={index}
                  index={index}
                  error={error && error[1] ? error[1][index] : ''}
                  formInfo={this.props.formInfo}
                />
              ) : <NoRecordFound style={{ margin: '0px' }} />
            }
          </div>
          <div className='tableInspec'>
            <h4 className="tech-heading-block flex-centered">
              Attachments
            </h4>
            <TableUIComp
              noStickyHeader={true}
              hover={true}
              tableBulkOp={ formInfo?.current_form?.permissions?.can_save? <BulkOperation GenUrlFn={this.GenUrlFn} addText="Create" addComponent={() => this.props.addComponent(this.state.componentStruct)} />: false}
              filter={false}
              tableHeight="auto"
              theads={[{ label: 'File Name', sortKey: '' }, { label: 'Description', sortKey: '' }, { label: 'Uploaded on', sortKey: '' }, { label: 'Uploaded by', sortKey: '' }, { label: 'Action', sortKey: '' }]}
              bulkOperation={false}
              pagination={false}
              content={mrSectionDet.attachments && mrSectionDet.attachments.length ? mrSectionDet.attachments.map((mrDetails, index) => <ShopvisitFileList userInfo={getLocalStorageInfo().user } index={index} shopVisit={mrDetails} key={index} updateForm={this.props.updateForm} toggleDelModal={this.toggleDelAttachmentModal} />) : null}
              recordsFound={mrSectionDet.attachments && mrSectionDet.attachments.length ? false : true}
            />
          </div>
        </div>

        <DeleteModal isOpen={this.state.preReqModal}
          toggle={() => this.togglePrerequisiteModal()}
          title={'Prerequisite'} className="modal-tform-delete-pictures modal-dialog-centered pre-requsite-modal">
          <PrerequisiteWrapper form={'te006'} />
        </DeleteModal>
        <DeleteModal isOpen={this.state.delAttachmentModal} toggle={() => this.toggleDelAttachmentModal('')} title="Are you sure to delete ?" className="modal-tform-delete-pictures modal-dialog-centered">
          <ul className="list-inline">
            <li className="list-inline-item">
              <button type="button" className="btn btn-danger" onClick={() => { this.toggleDelAttachmentModal(''); this.props.deleteAttachmentFn(this.state.deleteId, this.state.deleteType, this.state.deleteIndex) }}>Yes</button>
            </li>
            <li className="list-inline-item">
              <button type="button" className="btn btn-primary" onClick={() => this.toggleDelAttachmentModal('')}>No</button>
            </li>
          </ul>
        </DeleteModal>

        <DeleteModal isOpen={this.state.delSectionModal} toggle={() => this.toggleDelSectionModal('')} title="Are you sure to delete ?" className="modal-tform-delete-pictures modal-dialog-centered">
          <ul className="list-inline">
            <li className="list-inline-item">
              <button type="button" className="btn btn-danger" onClick={() => { this.toggleDelSectionModal(''); this.props.deleteSectionFn(this.state.deleteId, this.state.deleteType, this.state.deleteIndex) }}>Yes</button>
            </li>
            <li className="list-inline-item">
              <button type="button" className="btn btn-primary" onClick={() => this.toggleDelSectionModal('')}>No</button>
            </li>
          </ul>
        </DeleteModal>
        <EditFormBar
          titleContent={<div><h3 style={{ textTransform: 'capitalize' }}>Downloaded Logs</h3></div>}
          toggleEditSideBar={this.toggleDownloadedLogs}
          isOpen={this.state.downloadFormLogs}
          style={this.state.downloadFormLogs ? { width: '400px' } : { width: '0px' }}
        >
          {this.state.downloadFormLogs ?
            <DownloadedLogs logs={formInfo.current_form.download_report} />
            : null
          }
        </EditFormBar>
        {
          mrSectionLoader ?
            <ListLoader /> : null}
        {
          this.props.buyLicenceModal.flag ?
            <LicenceModal
              planId="2"
              toggleLicence={() => this.props.buyLicence({
                flag: false,
                action: '',
                content: ''
              })}
              licenceModal={this.props.buyLicenceModal}
            />
            : null
        }
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  formInfo: state.FormT001Reducer.formInfo,
  mrSectionDet: state.FormTE006Reducer.mrSectionDet,
  mrSectionLoader: state.FormTE006Reducer.mrSectionLoader,
  techConstants: state.sharedReducers.techConstants,
  techExportLoader: state.sharedReducers.techExportLoader,
  error: state.FormTE006Reducer.error,
  formStatusLoader: state.FormT001Reducer.formStatusLoader,
  buyLicenceModal: state.sharedReducers.buyLicenceModal,
  storageUsage: state.sharedReducers.storageUsage,
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getFormDetails: (type, application) => dispatch(getFormDetailsAc(ownProps, type, application)),
    getMrSectionDetails: () => dispatch(getMrSectionDetailsAC(ownProps)),
    updateFormStatus: (data) => dispatch(updateFormStatusAc(ownProps, data, 2)),
    exportReport: (url, name, fileType) => dispatch(downLoadTechEngineFormAc(url, ownProps.params.workOrderSlug, fileType, name)),
    fetchTechConstant: (constantTypes) => dispatch(sGlobalConstantAc(constantTypes)),
    GenUrlFn: (files, type) => dispatch(GenUrlAc(ownProps, files, type)),
    deleteAttachmentFn: (id, type, index) => dispatch(deleteMrAttachmentAc(ownProps, id, type, index)),
    deleteSectionFn: (id, type, index) => dispatch(deleteMrSectionAc(ownProps, id, type, index)),
    updateMrSectionDetailsFn: (data) => dispatch(updateMrSectionDetailsAc(ownProps, data)),
    updateForm: (data) => dispatch({
      type: actions.UPDATE_TE006_FORM,
      payload: data
    }),
    addReviewCollapse: () => dispatch({
      type: actions.ADD_TE006_SECTION,
      payload: mrSectionData
    }),
    errFn: (errMsg) => dispatch({
      type: actions.ERROR_MESSAGE_06,
      payload: errMsg
    }),
    clearErr: (obj) => dispatch({
      type: actions.CLEAR_ERR_MESSGAE_06,
      payload: obj
    }),
    usageDetail: () => dispatch(usageDetailAc()),
    buyLicence: (modalObj) => dispatch({
      type: BUY_LICENCE,
      payload: modalObj
    })
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(InspectionFormTE006);
