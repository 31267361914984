export const PROJECTS_LIST = 'PROJECTS_LIST';
export const ADD_PROJECT = 'ADD_PROJECT';
export const TOGGLE_PROJECT_SIDEBAR = 'TOGGLE_PROJECT_SIDEBAR';
export const UPDATE_USER_TYPES = 'UPDATE_USER_TYPES';
export const TOGGLE_WORKORDER_SIDEBAR = 'TOGGLE_WORKORDER_SIDEBAR';
export const TOGGLE_APUWORKORDER_SIDEBAR = 'TOGGLE_APUWORKORDER_SIDEBAR';
export const PROJECT_MEMBERS = 'PROJECT_MEMBERS';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const GET_GEN_INFO = 'GET_GEN_INFO';
export const PROJECT_LOADER = 'PROJECT_LOADER';
export const SELECTED_USER = 'SELECTED_USER';
export const PROJECT_EXPORT_LOADER='PROJECT_EXPORT_LOADER';
export const AIRCRAFT_TYPE = 'AIRCRAFT_TYPE';
export const BLUE_PRINT_INFO = 'BLUE_PRINT_INFO';
export const INSPECTION_TYPE = 'INSPECTION_TYPE';
export const FORM_LIST = 'FORM_LIST';
export const PROJECT_VIEW_DATA = 'PROJECT_VIEW_DATA';
export const MSN_DETAILS = 'ADD_WORKORDER';
export const FORM_UPDATED_LIST = 'FORM_UPDATED_LIST';
export const CREATE_WORKORDER = 'CREATE_WORKORDER';
export const UPDATE_WORKORDER = 'UPDATE_WORKORDER';
export const REMOVE_ENGINEER = 'REMOVE_ENGINEER';
export const PROJECT_QUESTIONS = 'PROJECT_QUESTIONS';
export const QUESTION_LOADER='QUESTION_LOADER';
export const UPDATE_ANSWERE='UPDATE_ANSWERE';
export const ENGINE_DETAILS='ENGINE_DETAILS';
export const UPDATE_CREATE_ENGINE='UPDATE_CREATE_ENGINE';
export const ATTACH_UPLOAD_FILE_CREATE='ATTACH_UPLOAD_FILE_CREATE';
export const USER_DETAIL = 'USER_DETAIL';
export const CLOSE_USER_MODAL = 'CLOSE_USER_MODAL';
export const COR_EXPORT_LOADER='COR_EXPORT_LOADER';
export const REFERENCE_FILE_ADD ='REFERENCE_FILE_ADD';
export const ADD_SHOP_VISIT='ADD_SHOP_VISIT';
export const ATTACH_UPLOAD_FILE_SHOP='ATTACH_UPLOAD_FILE_SHOP';
export const FETCH_ENGINE_FORMS='FETCH_ENGINE_FORMS';
export const FETCH_APU_FORMS='FETCH_APU_FORMS';
export const FETCH_EXISTING_ENGINES='FETCH_EXISTING_ENGINES';
export const UPDATE_NEW_EMPTY_ENGINE='UPDATE_NEW_EMPTY_ENGINE';
export const CREATE_ENGINE_LOADER='CREATE_ENGINE_LOADER';
export const ATTCHMENT_ENGINE_LOADER='ATTCHMENT_ENGINE_LOADER';
export const DELETE_ENG_SHOPVISIT='DELETE_ENG_SHOPVISIT';
export const DELETE_ENG_SHOPVISIT_ATTACHMENT='DELETE_ENG_SHOPVISIT_ATTACHMENT';
export const DELETE_ENG_ATTACHMENT='DELETE_ENG_ATTACHMENT';
export const DELETE_ENG_REFERENCE='DELETE_ENG_REFERENCE';
export const CLEAR_ESN_DATA='CLEAR_ESN_DATA';
export const COR_CLIENT_STATUS='COR_CLIENT_STATUS';
export const FEEDBACKLIST='FEEDBACKLIST';
export const UPDATE_FEEDBACKS='UPDATE_FEEDBACKS';
export const INSP_USAGE='INSP_USAGE';
export const WORKORDER_SIDEBAR = 'WORKORDER_SIDEBAR'
export const T012_DATA = 'T012_DATA'
export const PROJECTS_DATA = 'PROJECTS_DATA'
