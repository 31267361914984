import React, { useState } from "react"
import { TableRow, TableCell, Tooltip, IconButton, Dialog, } from "@material-ui/core"
import { withRouter, browserHistory } from "react-router"
import moment from 'moment'
import EditIcon from "@material-ui/icons/Edit"
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from "@material-ui/icons/Delete"
import { displayDateFormatShort } from "../../../constants"
const TimeLog = ({params, item, onEdit, onDelete, filter}) => {
  return (
    <TableRow hover tabIndex={-1}>
      <TableCell>
        <div className="multiline-clamp" style={{width:'140px'}}> Test </div>
      </TableCell>
      <TableCell>
        <div className="multiline-clamp" style={{width:'140px'}}> Test </div>
      </TableCell>
      <TableCell>
        <div className="multiline-clamp" style={{width:'140px'}}> Test </div>
      </TableCell>
      <TableCell>
        <div className="multiline-clamp" style={{width:'140px'}}> Test </div>
      </TableCell>
      <TableCell>
        <div className="multiline-clamp" style={{width:'140px'}}> Test </div>
      </TableCell>
      <TableCell>
        <div className="multiline-clamp" style={{width:'140px'}}> Test </div>
      </TableCell>
      <TableCell>
        <div className="multiline-clamp" style={{width:'140px'}}> Test </div>
      </TableCell>
      <TableCell>
        <div className="multiline-clamp" style={{width:'140px'}}> Test </div>
      </TableCell>
      <TableCell>
        <div style={{width:'70px'}}>
          <Tooltip title="View/Edit">
            <IconButton size="small" color="primary">
              <VisibilityIcon color="primary" fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton size="small" color="primary">
              <DeleteIcon onClick={onDelete} color='secondary' fontSize="small" />
            </IconButton>
          </Tooltip>
        </div>
      </TableCell>
    </TableRow>
  )
}
export default withRouter(TimeLog)
