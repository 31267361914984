import React from 'react';
import BulkOpsMenu from './BulkOpsMenu';
import CloseIcon from '@material-ui/icons/Close';
import { statusOptions } from '../'
const BulkOperation = ({filterOptions, assigneeList, onCancelBulkOps, prsBulkFieldUpdate}) => {
    return(
        <div className='prs-bulk-ops'>
            <ul className='list-inline flex-centered'>
                <li className='list-inline-item' onClick={onCancelBulkOps}><CloseIcon color="primary" /></li>
                <li className='list-inline-item'>
                    <BulkOpsMenu label="Assignee" labelKey='name' valueKey='id' options={assigneeList} prsBulkFieldUpdate={(data) => prsBulkFieldUpdate('assignee_id', data)} />
                </li>
                <li className='list-inline-item'>
                    <BulkOpsMenu label="Status" labelKey='label' valueKey='value' options={statusOptions} prsBulkFieldUpdate={(data) => prsBulkFieldUpdate('status', data)} />
                </li>
                <li className='list-inline-item'>
                    <BulkOpsMenu label="Priority" labelKey='label' valueKey='value' options={filterOptions.priority.options} prsBulkFieldUpdate={(data) => prsBulkFieldUpdate('priority', data)} />
                </li>
                <li className='list-inline-item'>
                    <BulkOpsMenu label="Category" labelKey='name' valueKey='id' options={filterOptions.section.options} prsBulkFieldUpdate={(data) => prsBulkFieldUpdate('to_section_id', data)} />
                </li>
                <li className='list-inline-item'><span className='blk-ops-menu delete-menu' onClick={()=> prsBulkFieldUpdate('delete', null)}>Delete</span></li>
            </ul>
        </div>
    )
}
export default BulkOperation;