
import React, { Fragment, useState, useEffect } from "react"
import { browserHistory } from 'react-router'
import { TaskManagementWrapper, TaskIssueLoader,  TaskTitle,TaskDescription,TaskInformation,CommentBox, Attachments, TaskIssues} from "../components"
import { PageLoader } from '../../../shared_elements';
import { Tab, Tabs, Paper } from "@material-ui/core"
import Skeleton from '@material-ui/lab/Skeleton';
import { globalDeleteService, globalGetService, globalPostService, globalPutService } from "../../../utils_v2/globalApiServices"
import { checkApiStatus, onCheckFormPermission, onCheckPermission } from "../../../utils_v2"
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { trackActivity } from '../../../utils/mixpanel';
const queryString = require('query-string');
const IssueDetail = ({ params, location, toggleModalFn, rowId }) => {
  const [skeletonLoader, setSkeletonLoader] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [issueLoader, setIssueLoader] = useState(true);
  const [tabIndex, setTabIndex] = useState(onCheckPermission('task_management','issue_comment','R') ? 'comments': onCheckPermission('task_management','issue_document','R')?'documents':onCheckPermission('task_management','tasks','R')? 'tasks':'');
  const [issueDetail, setIssueDetail] = useState({});
  const [issues, setIssues] = useState([])
  useEffect(() => {
    getIssuesData({ view: 'all', fields:'id,name,created_by', ...location.query}, 'issueLoader')
    getIssueDetail(params.slug, 'skeletonLoader');
    trackActivity('Task Management', {
      event_type: 'Page Visited',
      page_title: 'Issue Detail',
    });
  }, []);
  const getIssuesData = (query = {}) => {
    if(issueLoader) {
      setIssueLoader(true);
    }
    globalGetService( `technical/project/${params.project_slug}/issue/`, query)
    .then((response) => {
      setIssueLoader(false);
      if (checkApiStatus(response)) {
        setIssues(response.data.data)
      }
    })
  }
  const getIssueDetail = (id, loaderType) => {
    if(loaderType === 'skeletonLoader'){ setSkeletonLoader(true) }else{ setLoading(true) }
    globalGetService(`technical/project/${params.project_slug}/issue/${id}/`)
    .then(response => {
      if(checkApiStatus(response)){
        setIssueDetail(response.data.data)
      }
      if(loaderType === 'skeletonLoader'){ setSkeletonLoader(false) }else{ setLoading(false) }
    })
  }
  const updateTaskUrl = (id) => {
    let location = browserHistory.getCurrentLocation()
    location = {
      ...location,
      pathname: `/technical/project/${params.project_slug}/issue/${id}`
    }
    browserHistory.push(location);
    getIssueDetail(id,'skeletonLoader');
  }
  const backToList = () => {
    let location = browserHistory.getCurrentLocation()
    location = {
      ...location,
      pathname: `/technical/project/${params.project_slug}/issues`
    }
    browserHistory.push(location);
  }
  return (
    <section className="projects-management-apps projects-management-section">
        <TaskManagementWrapper>
          <div className="task-management-detail" style={{height:`${window.innerHeight-140}px`}}>
            <div className="task-management-sidebar" style={{height:`${window.innerHeight-140}px`}}>
              {issueLoader ? <Skeleton variant="rect" width={'100%'} height={window.innerHeight-140} />:
                <Fragment>
                  {issues.length > 0 && issues.map((issue) =>
                    <div onClick={()=>updateTaskUrl(issue.id)} className={`task-management-sidebar-card ${issue.id == params.slug?'active':''}`}>
                      <h5>{issue.name}</h5>
                      <p>{issue.created_by && issue.created_by.name ? issue.created_by.name:''}</p>
                    </div>
                  )}
                </Fragment>
              }
            </div>
            <div className="task-detail-section" style={{height:`${window.innerHeight-140}px`}}>
              { skeletonLoader ? <TaskIssueLoader />:
                <Fragment>
                  <h4 onClick={() => backToList()} className="flex-centered back-to-list">
                    <ArrowBackIcon  fontSize="small" color="primary" />
                    <span>Issues</span>
                  </h4>
                  <TaskTitle
                    taskDetail={issueDetail?.issue||{}}
                    permission={issueDetail?.permission||{}}
                    cardType='issue'
                    getResponseBack={()=> getIssuesData({ view: 'all', fields:'id,name,created_by', ...location.query})}

                  />
                  <TaskDescription
                    taskDetail={issueDetail?.issue||{}}
                    permission={issueDetail?.permission||{}}
                    cardType='issue'
                  />
                  <TaskInformation
                    taskDetail={issueDetail?.issue||{}}
                    permission={issueDetail?.permission||{}}
                    cardType='issue'
                  />
                  <Paper style={{marginBottom:'10px'}}>
                    <Tabs
                        value={tabIndex}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={(event, newValue)=> setTabIndex(newValue)}
                        aria-label="disabled tabs example"
                    >
                      { onCheckPermission('task_management','issue_comment','R') ?<Tab label="Comment" value='comments' />:null}
                      { onCheckPermission('task_management','issue_document','R') ?<Tab label="Document" value='documents' />:null}
                      { onCheckPermission('task_management','tasks','R') ? <Tab label="Tasks" value='tasks' />:null}
                    </Tabs>
                  </Paper>
                  { onCheckPermission('task_management','issue_comment','R') && tabIndex === 'comments' ? <CommentBox cardType='issue' /> : null }
                  { onCheckPermission('task_management','issue_document','R') && tabIndex === 'documents' ? <Attachments cardType='issue' /> : null }
                  { onCheckPermission('task_management','tasks','R') && tabIndex === 'tasks' ? <TaskIssues taskDetail={issueDetail?.issue||{}} permission={issueDetail?.permission||{}} cardType='issue' /> : null }
                </Fragment>
              }
            </div>
          </div>
        </TaskManagementWrapper>
        { isLoading ? <PageLoader />:null }
    </section>
  )
}
export default IssueDetail
