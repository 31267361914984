// https://private-anon-4e50b7aab9-chmmv2.apiary-mock.com/
import config from '../config';
import { browserHistory } from 'react-router';
import { trackLogOut } from './mixpanel'
import axios from 'axios';
import { toastFlashMessage, getLocalStorageInfo, setGlobalCookie, getGlobalCookie, eraseGlobalCookie } from './index';
import { signUpBaseUrl } from '../constants';
require('es6-promise').polyfill();
var signUpInterceptor = axios.create();

signUpInterceptor.defaults.baseURL = config.api.networkInterface;
signUpInterceptor.defaults.timeout = 600000
signUpInterceptor.interceptors.request.use(function (config) {
  let lessorAccess = JSON.parse(getGlobalCookie('lessorAccess'));
  if(lessorAccess){
      config['headers']['Authorization'] = lessorAccess.access;
    }
    return config;
  },function (error) {
    return Promise.reject(error);
  });
signUpInterceptor.interceptors.response.use(function (response) {
    if(response.data.statusCode == 200){
      return response;
    }
    else if(response.data.statusCode == 1001){
      if(!browserHistory.getCurrentLocation().pathname.includes('login') && !browserHistory.getCurrentLocation().pathname.includes('signup')){
        setGlobalCookie('redirectURIProjects', window.location.href, 3)
      }
      trackLogOut(getLocalStorageInfo())
      localStorage.clear();
      browserHistory.push('/login');
      eraseGlobalCookie('lessorAccess')
      eraseGlobalCookie('domain')
      toastFlashMessage('Your previous logged in session expired, please login again', 'error');
    }
    else if(response.data.statusCode == 3002){
      browserHistory.push('/technical/projects');
      toastFlashMessage(response.data.message, 'error');
    }
    else if(response.data.statusCode == 3001){
      toastFlashMessage(response.data.message, 'error');
    }
    else if(response.request.responseType == 'arraybuffer' && response.config.url.includes('data-room-report')){
      return response
    }
    else if(response.data.statusCode != 404 && response.request.responseType == 'blob'){
      if(response.data.type != 'application/json'){
        toastFlashMessage('File has been Exported Successfully', 'success');
      }
      return response;
    }else if(response.request.responseType == 'arraybuffer'){
      if(window.location.href.includes('asset-view')){
        if(response.status === 200){
          toastFlashMessage('Data Transfer Key has been downloaded successfully', 'success');
          return response;
        }else{
          toastFlashMessage('Unable to complete download')
          return
        }
      }else{
        if(response.status === 200){
          toastFlashMessage('Data has been Downloaded Successfully', 'success');
          return response;
        }else{
          toastFlashMessage('Unable to complete download')
          return
        }
      }
    }else if(response.data.statusCode == 1004 || response.data.statusCode == 500 || response.data.statusCode == 1003 || response.data.statusCode == 1009 ){
      toastFlashMessage(response.data.message, 'error');
    }
    return response;
  },function (error) {
    toastFlashMessage('Unable to Process the Request due to Technical Error', 'error', 10000);
    return error;
  });

export default signUpInterceptor;
