export const sectionIds = [2,5,6,7,8,9,10,14,15,16,17,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35];
export const gapReportHd = [
  {label:'Item', sortKey: ''},
  {label:'Action', sortKey: ''},
  {label:'Description*', sortKey: ''},
  {label:'Files', sortKey: ''},
  {label:'Priority', sortKey: ''},
  {label:'Disposition', sortKey: ''},
  {label:'Started Date', sortKey: ''},
  {label:'Completed Date', sortKey: ''},
  {label:'Discrepancy', sortKey: '',child:[{label:'Requirement'},{label:'Background', style: {borderRight:'none' }}]},
  {label:'Comments', sortKey: '', child:[{label:'Project Lead/Engineer'},{label:'Current Lessee'}, {label: 'Next Lessee'}]},
  {label:'Assignee', sortKey: ''},
];
export const gapReporATAtHd = [
  {label:'Item', sortKey: ''},
  {label:'Action', sortKey: ''},
  {label:'ATA', sortKey: ''},
  {label:'Description*', sortKey: ''},
  {label:'Files', sortKey: ''},
  {label:'Priority', sortKey: ''},
  {label:'Disposition', sortKey: ''},
  {label:'Started Date', sortKey: ''},
  {label:'Completed Date', sortKey: ''},
  {label:'Discrepancy', sortKey: '',child:[{label:'Requirement'},{label:'Background', style: {borderRight:'none' }}]},
  {label:'Comments', sortKey: '', child:[{label:'Project Lead/Engineer'},{label:'Current Lessee'}, {label: 'Next Lessee'}]},
  {label:'Assignee', sortKey: ''},
];
export const tableContentHd = [
  {label:'Issue', sortOption: 'issue', id: 'issue'},
  {label:'Attachment(s)', sortOption: '', id: null},
  {label:'Category', sortOption: 'section', id: 'section'},
  {label:'Priority', sortOption: 'priority', id: 'priority'},
  {label:'Description', sortOption: '', id: null},
  {label:'Status', sortOption: 'disposition', id: 'status' },
  {label:'Status Updated By', sortOption:'updated_by', id:'updated_by'},
  {label:'Assignee', sortOption: 'assignee', id: 'assignee'},
  {label:'Discrepancy Raised on', sortOption: 'created_at', id: 'created_at'},
  {label:'Created By', sortOption: 'created_by', id: 'created_by'},
  {label:'P/N', sortOption: '', id: null},
  {label:'S/N', sortOption: '', id: null},
];
export const taskStatusHd = [
  {label:'Assignee', sortKey: ''},
  {label:'All Tasks', sortKey: ''},
  {label:'Opened', sortKey: '' },
  {label:'Reopened', sortKey: ''},
  {label:'Closed', sortKey: ''},
];
export const gapSecReportHd = [
  {label:'Item', sortKey: ''},
  {label:'Action', sortKey: ''},
  {label:'Description*', sortKey: ''},
  {label:'Files', sortKey: ''},
  {label:'Priority', sortKey: ''},
  {label:'Disposition', sortKey: ''},
  {label:'P/N', sortKey: ''},
  {label:'S/N', sortKey: ''},
  {label:'Started Date', sortKey: ''},
  {label:'Completed Date', sortKey: ''},
  {label:'Install Date', sortKey: ''},
  {label:'Position', sortKey: ''},
  {label:'Discrepancy', sortKey: '',child:[{label:'Requirement'},{label:'Background', style: {borderRight:'none' }}]},
  {label:'Comments', sortKey: '', child:[{label:'Project Lead/Engineer'},{label:'Current Lessee'}, {label: 'Next Lessee'}]},
  {label:'Assignee', sortKey: ''},
];
export const gapSecReportATAHd = [
  {label:'Item', sortKey: ''},
  {label:'Action', sortKey: ''},
  {label:'ATA', sortKey: ''},
  {label:'Description*', sortKey: ''},
  {label:'Files', sortKey: ''},
  {label:'Priority', sortKey: ''},
  {label:'Disposition', sortKey: ''},
  {label:'P/N', sortKey: ''},
  {label:'S/N', sortKey: ''},
  {label:'Started Date', sortKey: ''},
  {label:'Completed Date', sortKey: ''},
  {label:'Install Date', sortKey: ''},
  {label:'Position', sortKey: ''},
  {label:'Discrepancy', sortKey: '',child:[{label:'Requirement'},{label:'Background', style: {borderRight:'none' }}]},
  {label:'Comments', sortKey: '', child:[{label:'Project Lead/Engineer'},{label:'Current Lessee'}, {label: 'Next Lessee'}]},
  {label:'Assignee', sortKey: ''},
];
export const gapSecReportCyclesHd = [
  {label:'Item', sortKey: ''},
  {label:'Action', sortKey: ''},
  {label:'ATA', sortKey: ''},
  {label:'Description*', sortKey: ''},
  {label:'Files', sortKey: ''},
  {label:'Priority', sortKey: ''},
  {label:'Disposition', sortKey: ''},
  {label:'P/N', sortKey: ''},
  {label:'S/N', sortKey: ''},
  {label:'Cycles Remaining', sortKey: ''},
  {label:'Started Date', sortKey: ''},
  {label:'Completed Date', sortKey: ''},
  {label:'Install Date', sortKey: ''},
  {label:'Position', sortKey: ''},
  {label:'Discrepancy', sortKey: '',child:[{label:'Requirement'},{label:'Background', style: {borderRight:'none' }}]},
  {label:'Comments', sortKey: '', child:[{label:'Project Lead/Engineer'},{label:'Current Lessee'}, {label: 'Next Lessee'}]},
  {label:'Assignee', sortKey: ''},
];

export const T011SectionCrud = {
  title:'',
  category: null,
  description:'',
  background_remarks:'',
  ata: '',
  disposition: 0,
  estimated_completion_date: null,
  image_url: null,
  install_date: null,
  operator_comments: null,
  part_number: "",
  cycles_remaining: '',
  position: null,
  priority: null,
  requirement_remarks: null,
  serial_number: "",
  started_date: null,
  acumen_comments:'',
  assignee: null
}
