import { regexConstants } from "../../constants/regEx"
import { onCheckPermission, getLocalStorageInfo } from "../../utils_v2";

export const stagesHd = [
  { id: "name", label: "Name", sortOption: true },
  { label: "Status", sortOption: false },
  { label: "Expected Start Date", sortOption: false },
  { label: "Expected End Date", sortOption: false },
  { label: "Actual Start Date", sortOption: false },
  { label: "Actual End Date", sortOption: false },
  { label: "Closure Note", sortOption: false },
  { label: "Remarks", sortOption: false },
  { label: "Actions", sortOption: false },
]

export const milestoneHd = [
  { id: "name", label: "Milestone", sortOption: true },
  { label: "Status", sortOption: false },
  { label: "Progress", sortOption: false },
  { label: "Owner", sortOption: false },
  { label: "Start Date", sortOption: false },
  { label: "End Date", sortOption: false },
  { label: "Stage", sortOption: false },
  { label: "Asset", sortOption: false },
  { label: "Tasks", sortOption: false },
  { label: "Issues", sortOption: false },
  { label: "Remarks", sortOption: false },
  { label: "Actions", sortOption: false },
]
export const taskHd = [
  { id: "name", label: "Task", sortOption: true },
  { id: "status", label: "Status", sortOption: true },
  { label: "Owner", sortOption: false },
  { label: "Start Date", sortOption: false },
  { label: "End Date", sortOption: false },
  { label: "Priority", sortOption: false },
  { label: "Severity", sortOption: false },
  { label: "Follower", sortOption: false },
  { label: "Stages", sortOption: false },
  { label: "Milestone", sortOption: false },
  { label: "Work Order", sortOption: false },
  { label: "Form", sortOption: false },
  { label: "Created By", sortOption: false },
  { label: "Created At", sortOption: false },
  { label: "Actions", sortOption: false },
];
export const issueHd = [
  { id: "name", label: "Issue", sortOption: true },
  { id: "status", label: "Status", sortOption: true },
  { label: "Due Date", sortOption: false },
  { label: "Priority", sortOption: false },
  { label: "Severity", sortOption: false },
  { label: "Follower", sortOption: false },
  { label: "Assignee", sortOption: false },
  { label: "Stages", sortOption: false },
  { label: "Milestone", sortOption: false },
  { label: "Work Order", sortOption: false },
  { label: "Form", sortOption: false },
  { label: "Created By", sortOption: false },
  { label: "Created At", sortOption: false },
  { label: "Actions", sortOption: false },
];
export const timeSheetHd = [
  {label:'Task/Issue', sortOption:false},
  {label:'Duration', sortOption:false},
  {label:'Time Period', sortOption:false},
  {label:'User', sortOption:false},
  {label:'Billing Type', sortOption:false},
  {label:'Approval Status', sortOption:false},
  {label:'Notes', sortOption:false},
  {label:'Created By', sortOption:false},
  {label:'Action', sortOption:false}
]

export const stagesFilterOps = {
  name: {
    inputType: "text",
    placeholder: "Search by Stages Name",
    title: "Stages",
  },
  status: {
    inputType: "dropdown",
    placeholder: "Search by Status",
    title: "Status",
    options: [],
    labelKey: "name",
    valueKey: "id",
    multiple: true,
  },
}

export const milestonesFilterOps = {
  name: {
    inputType: "text",
    placeholder: "Search by Milestones Name",
    title: "Milestones",
  },
  owner: {
    inputType: "dropdown",
    placeholder: "Search by Owner",
    title: "Owners",
    options: [],
    labelKey: "name",
    valueKey: "id",
    multiple: true,
  },
  status: {
    inputType: "dropdown",
    placeholder: "Search by Status",
    title: "Status",
    options: [],
    labelKey: "label",
    valueKey: "value",
    multiple: true,
  },
  start_date: {
    inputType: "date",
    keyParam: "start_date",
    title: "Start Date",
  },
  end_date: {
    inputType: "date",
    keyParam: "end_date",
    title: "End Date",
  },
  project_stage: {
    inputType: "dropdown",
    placeholder: "Search by Stages",
    title: "Stages",
    options: [],
    labelKey: "name",
    valueKey: "id",
    multiple: true,
  },
  work_order: {
    inputType: "dropdown",
    placeholder: "Search by Project Workorder",
    title: "Project Workorder",
    options: [],
    labelKey: "unique_name",
    valueKey: "id",
    multiple: true,
  },

}
export const tasksFilterOps = {
  name: {
    inputType: "text",
    placeholder: "Search by Task Name",
    title: "Task Name",
  },
  status: {
    inputType: "dropdown",
    placeholder: "Search by Status",
    title: "Status",
    options: [],
    labelKey: "label",
    valueKey: "value",
    multiple: true,
  },
  owner: {
    inputType: "dropdown",
    placeholder: "Search by Owner",
    title: "Owners",
    options: [],
    labelKey: "name",
    valueKey: "id",
    multiple: true,
  },
  start_date: {
    inputType: "date",
    keyParam: "start_date",
    title: "Start Date",
  },
  end_date: {
    inputType: "date",
    keyParam: "end_date",
    title: "End Date",
  },
  priority: {
    inputType: "dropdown",
    placeholder: "Search by Priority",
    title: "Priority",
    options: [],
    labelKey: "label",
    valueKey: "value",
    multiple: true,
  },
  severity: {
    inputType: "dropdown",
    placeholder: "Search by Severity",
    title: "Severity",
    options: [],
    labelKey: "label",
    valueKey: "value",
    multiple: true,
  },
  follower: {
    inputType: "dropdown",
    placeholder: "Search by Follower",
    title: "Follower",
    options: [],
    labelKey: "name",
    valueKey: "id",
    multiple: true,
  },
  project_stage: {
    inputType: "dropdown",
    placeholder: "Search by Stages",
    title: "Stages",
    options: [],
    labelKey: "name",
    valueKey: "id",
    multiple: true,
  },
  project_milestone: {
    inputType: "dropdown",
    placeholder: "Search by Milestone",
    title: "Milestone",
    options: [],
    labelKey: "name",
    valueKey: "id",
    multiple: true,
  },
  work_order: {
    inputType: "dropdown",
    placeholder: "Search by Project Workorder",
    title: "Project Workorder",
    options: [],
    labelKey: "unique_name",
    valueKey: "id",
    multiple: true,
  },
  form: {
    inputType: "dropdown",
    placeholder: "Search by Form",
    title: "Form",
    options: [],
    labelKey: "name",
    valueKey: "id",
    multiple: true,
  },
}

export const issuesFilterOps = {
  name: {
    inputType: "text",
    placeholder: "Search by Issue Name",
    title: "Issue Name",
  },
  status: {
    inputType: "dropdown",
    placeholder: "Search by Status",
    title: "Status",
    options: [],
    labelKey: "label",
    valueKey: "value",
    multiple: true,
  },
  assign: {
    inputType: "dropdown",
    placeholder: "Search by Assign",
    title:   'Assignee',
    options: [],
    labelKey: "name",
    valueKey: "id",
    multiple: true,
  },
  due_date: {
    inputType: "date",
    keyParam: "due_date",
    title: "Due Date",
  },
  priority: {
    inputType: "dropdown",
    placeholder: "Search by Priority",
    title: "Priority",
    options: [],
    labelKey: "label",
    valueKey: "value",
    multiple: true,
  },
  severity: {
    inputType: "dropdown",
    placeholder: "Search by Severity",
    title: "Severity",
    options: [],
    labelKey: "label",
    valueKey: "value",
    multiple: true,
  },
  follower: {
    inputType: "dropdown",
    placeholder: "Search by Follower",
    title: "Follower",
    options: [],
    labelKey: "name",
    valueKey: "id",
    multiple: true,
  },
  project_stage: {
    inputType: "dropdown",
    placeholder: "Search by Stages",
    title: "Stages",
    options: [],
    labelKey: "name",
    valueKey: "id",
    multiple: true,
  },
  project_milestone: {
    inputType: "dropdown",
    placeholder: "Search by Milestone",
    title: "Milestone",
    options: [],
    labelKey: "name",
    valueKey: "id",
    multiple: true,
  },
  work_order: {
    inputType: "dropdown",
    placeholder: "Search by Project Workorder",
    title: "Workorder",
    options: [],
    labelKey: "unique_name",
    valueKey: "id",
    multiple: true,
  },
  form: {
    inputType: "dropdown",
    placeholder: "Search by Form",
    title: "Form",
    options: [],
    labelKey: "name",
    valueKey: "id",
    multiple: true,
  },
}

export const milestoneForm = {
  name: "",
  start_date: null,
  end_date: null,
  owner: null,
  project_stage: null,
  // asset: null,
  remarks: "",
  asset_type: 1,
  asset_id: 1,
  work_order: null,
}
export const taskForm = {
  name: "",
  description: "",
  start_date: null,
  end_date: null,
  owner: null,
  follower: null,
  priority: null,
  severity: null,
  project_stage: null,
  project_milestone: null,
  work_order: null,
  form: null,
  files: [],
}

export const issueForm = {
  name: "",
  description: "",
  remarks: "",
  due_date: null,
  end_date: null,
  assign: null,
  follower: null,
  priority: null,
  severity: null,
  project_stage: null,
  project_milestone: null,
  work_order: null,
  form: null,
  files: [],
}
export const timeLogForm = {
  project_task:null,
  project_issue:null,
  user:null,
  date:null,
  start_time:'',
  end_time:'',
  hours:'',
  notes:'',
  log_task:''
}
export const stageClosureData = {
  actual_start_date: null,
  actual_end_date: null,
  closure_remarks: "",
}

export const closureErrorCode = {
  closure_remarks: {
    0: "",
    1: "Please Enter Closure Note",
  },
  closure_remarks_obj: {
    required: true,
  },
  actual_start_date: {
    0: "",
    1: "Please Enter Start Date",
    4: "Invalid Format",
  },
  actual_start_date_obj: {
    required: true,
  },
  actual_end_date: {
    0: "",
    // 1: "Please enter Start Date",
    2: "Please Enter End Date",
    // 3: "Start date is required",
    4: "Start date is not valid",
    5: "End date is not valid",
    6: "End Date should be greater than Start Date",
    7: "Start Date and End Date should be different",
  },
  actual_end_date_obj: {
    required: true,
  },
}

export const stageErrorCode = {
  name: {
    0: "",
    1: "Please Enter Stage Name",
  },
  name_obj: {
    required: true,
  },
  expected_start_date: {
    0: "",
    1: "Please Enter Start Date",
    4: "Invalid Format",
  },
  expected_start_date_obj: {
    required: true,
  },
  expected_end_date: {
    0: "",
    // 1: "Please enter Start Date",
    2: "Please Enter End Date",
    // 3: "Start date is required",
    4: "Start date is not valid",
    5: "End date is not valid",
    6: "End Date should be greater than Start Date",
    7: "Start Date and End Date should be different",
  },
  expected_end_date_obj: {
    required: true,
  },
}

export const milestonesErrorCode = {
  name: {
    0: "",
    1: "Please Enter Milestone Name",
  },
  name_obj: {
    required: true,
  },
  start_date: {
    0: "",
    1: "Please Enter Start Date",
    4: "Invalid Format",
  },
  start_date_obj: {
    required: true,
  },
  end_date: {
    // 0: "",
    // 1: "Required",
    // 4: "Invalid Format",
    0: "",
    // 1: "Please enter Start Date",
    2: "Please Enter End Date",
    // 3: "Start date is required",
    4: "Start date is not valid",
    5: "End date is not valid",
    6: "End Date should be greater than Start Date",
  },
  end_date_obj: {
    required: true,
  },
  owner: {
    0: "",
    1: "Please select Project Lead",
  },
  ownerObj: {
    required: true,
  },
}

export const tasksErrorCode = {
  name: {
    0: "",
    1: "Please Enter Name",
  },
  name_obj: {
    required: true,
  },
  start_date: {
    0: "",
    1: "Please Enter Start Date",
    4: "Invalid Format",
  },
  start_date_obj: {
    required: true,
  },
  end_date: {
    0: "",
    // 1: "Please enter Start Date",
    2: "Please Enter End Date",
    // 3: "Start date is required",
    4: "Start date is not valid",
    5: "End date is not valid",
    6: "End Date should be greater than Start Date",
  },
  end_date_obj: {
    required: true,
  },
  owner: {
    0: "",
    1: "Please select Project Lead",
  },
  owner_obj: {
    required: true,
  },
}

export const taskAndIssueSelfPermission = (permissionKey, permissionOps, item, selfPermission) => {
  let permissionFlag = false;
  const userInfo = getLocalStorageInfo();
  if(onCheckPermission('task_management',permissionKey, permissionOps)){
    if(selfPermission === 2){
      permissionFlag = true
    }else{
      let ids = [];
      if(item?.created_by?.id){
          ids = [...ids, item?.created_by?.id]
      }
      if(item?.assign?.id){
          ids = [...ids, item?.assign?.id]
      }
      if(item?.owner?.id){
          ids = [...ids, item?.owner?.id]
      }
      if(item?.follower?.id){
          ids = [...ids, item?.follower?.id]
      }
      if(ids.includes(userInfo.user.id)){
        permissionFlag = true;
      }
    }
  }

  return permissionFlag
}
