import React, { useState, useEffect } from 'react';
import { Table, Grid, TableHead, TableRow, TableCell, TableBody, TextField, Paper, Button, TextareaAutosize, Tooltip } from '@material-ui/core';
import { globalGetService, globalPostService } from '../../../globalServices';
import EditIcon from '@material-ui/icons/Edit';
import { withRouter } from 'react-router';
import { checkApiStatus, onCheckFormPermission } from '../../../utils_v2';
import { useSnackbar } from 'notistack';
import { regexConstants } from '../../../constants/regEx';
import { PageLoader, STableLoader } from '../../../shared_elements';
const WeightRowItem = ({ isEdit, keyParam, onFieldChange, error, kgsValue, lbsValue }) => {
    return (
        <TableCell className='content-cell'>
            <Table className='inner-table'>
                <TableRow>
                    <TableCell>
                        <TextField
                            disabled={!isEdit}
                            fullWidth
                            style={{marginTop: '0px'}}
                            margin="normal"
                            value={lbsValue || ''}
                            InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                            variant='outlined'
                            onChange={(e) => regexConstants.decimalnodotstart.test(e.target.value) ? onFieldChange(`${keyParam}_lbs`, e.target.value) : e.preventDefault()}
                        />
                    </TableCell>
                    <TableCell>
                        <TextField
                            disabled={!isEdit}
                            fullWidth
                            margin="normal"
                            value={kgsValue || ''}
                            style={{marginTop: '0px'}}
                            error={error}
                            InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                            variant='outlined'
                            onChange={(e) => regexConstants.decimalnodotstart.test(e.target.value) ? onFieldChange(`${keyParam}_kgs`, e.target.value) : e.preventDefault()}
                        />
                    </TableCell>
                </TableRow>
            </Table>
        </TableCell>
    )
}
const Comment = ({ isEdit, keyParam, onFieldChange, comment }) => {
    return (
        <TableCell className='content-cell'>
            <Table className='inner-table'>
                <TableRow>
                    <TableCell>
                        <TextareaAutosize
                            aria-label="empty textarea"
                            style={{ border: 'rgb(0 0 0 / 22%) groove', padding: '3px' , width:'168px'}}
                            minRows={2}
                            disabled={!isEdit}
                            fullWidth
                            margin="normal"
                            value={comment}
                            InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                            variant='outlined'
                            onChange={(e) => onFieldChange(keyParam, e.target.value)}
                            maxLength={255}
                        />
                    </TableCell>
                </TableRow>
            </Table>
        </TableCell>
    )

}
const Weight = ({ params, getCurrentFormDetail, currentFormDetail}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [isEdit, setEdit] = useState(false, { data: null });
    const [weightDetails, setWeightDetails] = useState([]);
    const [error, setError] = useState({});
    useEffect(() => {
        getWeightDetails('skeletonLoader');
    }, []);
    const getWeightDetails = (loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true) : setLoading(true);
        globalGetService(`technical/workorder/${params.workOrderSlug}/t014/weights?type=T014&asset_type=${params.type}`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setWeightDetails(response.data.data);
                    getCurrentFormDetail();
                }
                loaderType === 'skeletonLoader' ? setSkeletonLoader(false) : setLoading(false);
            })
    }
    const editWeightDetails = () => {
        setLoading(true);
        globalPostService(`technical/workorder/${params.workOrderSlug}/t014/weights/?type=T014&asset_type=${params.type}`, ({ weights: weightDetails }))
            .then(response => {
                if (checkApiStatus(response)) {
                    enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                    setEdit(false);
                    getWeightDetails('skeletonLoader');
                }
                setLoading(false)
            })
    }
    const updateWeights = (key, value, index) => {
        setWeightDetails(prevDetails => {
            const updatedDetails = [...prevDetails];
            const item = updatedDetails[index];
            if (key.includes('_kgs')) {
                const lbKey = key.replace('_kgs', '_lbs');
                item[key] = value;
                item[lbKey] = isNaN(parseFloat(value)) ? '' : (parseFloat(value) * 2.205).toFixed(3);
            } else if (key.includes('_lbs')) {
                const kgKey = key.replace('_lbs', '_kgs');
                item[key] = value;
                item[kgKey] = isNaN(parseFloat(value)) ? '' : (parseFloat(value) / 2.205).toFixed(3);
            } else {
                item[key] = value.trimStart();
            }
            return updatedDetails;
        });
    };
    return (
        <div>
            <div className='tech-specs-content' style={{}} >
            {onCheckFormPermission(currentFormDetail) ?
                <p style={{ textAlign: 'right', height: '20px', padding: '5px' }}>
                    {!isEdit  ?
                        <Tooltip title='Edit'  style={{ cursor: 'pointer' }}>
                            <EditIcon onClick={() => { setEdit(true, { data: weightDetails.data }) }} fontSize="small" color='primary' />
                        </Tooltip> : null
                    }
                </p>:null}
                {skeletonLoader ? <STableLoader count={4} /> : <>
                    <div className='aircraft-weight-table' style={{ padding: '10px' }}>
                        <Paper className='console-forms-fields' style={{ overflow: "auto",  }} >
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className='header-cell-border' style={{ pointerEvents: 'none' }}></TableCell>
                                        {['Current', 'Purchased', 'Redelivery'].map((label, index) =>
                                            <TableCell key={index} className='header-cell-border' style={{ pointerEvents: 'none', marginBottom: '5px' }}>
                                                <p>{label}</p>
                                                <Table className='inner-table-hd' style={{ pointerEvents: 'none', marginTop: '5px' }}>
                                                    <TableRow>
                                                        <TableCell style={{ textAlign: 'center' }} >{"lbs"}</TableCell>
                                                        <TableCell style={{ textAlign: 'center' }} >{"kgs"}</TableCell>
                                                    </TableRow>
                                                </Table>
                                            </TableCell>
                                        )}
                                        <TableCell style={{ textAlign: 'center' }}>Comment</TableCell>
                                    </TableRow>
                                </TableHead>
                                {weightDetails?.map((item, index) =>
                                    <TableBody >
                                        <TableRow key={index}>
                                            <TableCell style={{ backgroundColor: "#b6bedc",width:'208px' }}>{item.weight_label}</TableCell>
                                            <WeightRowItem isEdit={isEdit} keyParam='current_weight' onFieldChange={(key, value) => updateWeights(key, value, index)} kgsValue={item.current_weight_kgs} lbsValue={item.current_weight_lbs} />
                                            <WeightRowItem isEdit={isEdit} keyParam='purchased_weight' onFieldChange={(key, value) => updateWeights(key, value, index)} kgsValue={item.purchased_weight_kgs} lbsValue={item.purchased_weight_lbs} />
                                            <WeightRowItem isEdit={isEdit} keyParam='redelivery_weight' onFieldChange={(key, value) => updateWeights(key, value, index)} kgsValue={item.redelivery_weight_kgs} lbsValue={item.redelivery_weight_lbs} />
                                            <Comment isEdit={isEdit} keyParam='comments' onFieldChange={(key, value) => updateWeights(key, value, index)} comment={item.comments} />
                                        </TableRow>
                                    </TableBody>)}
                            </Table>
                            {isEdit ?
                                <Paper square style={{ position: 'relative', bottom: '0', left: '0', width: '100%', background: '#fff', zIndex: 9 }}>
                                    <ul className='list-inline ' style={{ float: 'right', padding:'8px' }}>
                                        <li className='list-inline-item'>
                                            <Button onClick={() => { setError(''); setEdit(false); getWeightDetails() }} color='primary' variant='outlined' size='small'>Cancel</Button>
                                        </li>
                                        <li className='list-inline-item'>
                                            <Button onClick={editWeightDetails} color='primary' variant='contained' size='small'>Save</Button>
                                        </li>
                                    </ul>
                                </Paper> : null
                            }
                        </Paper>
                    </div></>}
            </div>
            {isLoading ? <PageLoader /> : null}
        </div>
    )
}
export default withRouter(Weight);