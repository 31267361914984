import React, { Fragment, useState } from 'react';
import { useSnackbar } from 'notistack';
import { withRouter } from 'react-router';
import { CircularProgress, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid,  Tooltip } from '@material-ui/core';
import DeleteOutlined from '@material-ui/icons/DeleteOutlined';
import GetAppIcon from '@material-ui/icons/GetApp';
import { DeletePopUp } from '../../../shared_elements';
import { globalDeleteService } from '../../../utils_v2/globalApiServices'
import { checkApiStatus, getLocalStorageInfo, onCheckPermission } from '../../../utils_v2';
import { trackActivity } from '../../../utils/mixpanel';
const ExpenseAttachments = ({params, budgetExpenses, attachmentsInfo, toggleModalFn, getResponseBack}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [modal, setModal] = useState(false);
    const [attachment, setAttachment] = useState(null);
    const userInfo = getLocalStorageInfo()
    const onDeleteExpenseAttachment = () => {
        globalDeleteService(`technical/project/${params.project_slug}/budgets/expenses/deleteAttachment/${attachment.id}/`)
        .then(response => {
            if(checkApiStatus(response)){                
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
                getResponseBack(attachment.id, attachmentsInfo.expense.id)
                setModal(false);
                setAttachment(null);
                trackActivity('Expense Management', {
                    event_type: 'Deleted Expense Attachment',
                    page_title: 'Expense Attachment',
                    expense_slug: attachmentsInfo.expense.slug,
                    project_slug: params.project_slug
                  });
            }else{
                trackActivity('Expense Management', {
                    event_type: 'Delete Expense Attachment Error!',
                    page_title: 'Expense Attachment',
                    expense_slug: attachmentsInfo.expense.slug,
                    project_slug: params.project_slug,
                    error_message: response.data.message
                    });
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
            }
        });
    }
    return(
        <Fragment>
            <Dialog
                maxWidth="md"
                open={attachmentsInfo.modal}
                onClose={toggleModalFn}
                aria-labelledby="scroll-dialog-title"
                className='project-management-modal'
            >
                <DialogTitle id="scroll-dialog-title">
                    Attachments
                </DialogTitle>
                <DialogContent dividers={true}>
                    <div className='expense-attachment-modal'>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={12}>
                                <div>   
                                    {attachmentsInfo.expense.attachments && attachmentsInfo.expense.attachments.map((item) => 
                                        <div className="attachment">
                                            <ul className='list-inline flex-centered'>
                                                { onCheckPermission('expense_management', 'expense', 'U') && (attachmentsInfo?.expense?.status !== 'Approved' && attachmentsInfo?.expense?.status !== 'Paid') ?
                                                    <>
                                                        {budgetExpenses?.permission?.update?.value === 2 ?
                                                            <li onClick={()=> {setModal(true);setAttachment(item)}} className='list-inline-item'>
                                                                <DeleteOutlined color='secondary' fontSize='small' /> 
                                                            </li>:
                                                            <>
                                                                {(attachmentsInfo?.expense?.created_by?.id === userInfo.id) || (attachmentsInfo?.expense?.approver?.id === userInfo.id) ?
                                                                    <li onClick={()=> {setModal(true);setAttachment(item)}} className='list-inline-item'>
                                                                        <DeleteOutlined color='secondary' fontSize='small' /> 
                                                                    </li>:null
                                                                }
                                                            </>
                                                        }
                                                    </>
                                                    :null
                                                }
                                                <li className='list-inline-item'>
                                                    <a href={item.url} download={item.name}><GetAppIcon color='primary' fontSize='small' /></a>
                                                </li>
                                                <li className='list-inline-item file-name'>
                                                    <span>{item.name}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    )}
                                    {!attachmentsInfo?.expense?.attachments?.length ?<p style={{textAlign:'center', padding:'30px 0'}}>No more attachment(s)</p>:null}
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={toggleModalFn} color="primary" variant='outlined' size='small'>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <DeletePopUp
                modal={modal}
                toggleModalFn={() => {setModal(false);setAttachment(null)}}
                title="Delete Attachment"
                content={<h4>Are you sure you want to delete?</h4>}
                deleteRecordFn={onDeleteExpenseAttachment}
            />
        </Fragment>

    )
}
export default withRouter(ExpenseAttachments);