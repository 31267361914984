import React from 'react';
import { Paper, TextField, Button, Menu, MenuItem } from '@material-ui/core/';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { statusOptions } from '../'
const IssuesSearch = ({onChange, filter, onChangeStatus, onApplyFilter}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const statusList = filter.status ? statusOptions.filter((item) => item.value != filter.status): statusOptions;
    return(
        <Paper square style={{margin:'8px 0'}}>
            <form>
                <ul className='list-inline flex-centered'>
                    <li className='list-inline-item'>
                        <Button endIcon={<ArrowDropDownIcon color='primary' fontSize='small'/>} onClick={handleClick}>
                            All {filter.status ? statusOptions.find(item => item.value == filter.status).label : 'Issues'}
                        </Button>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >   {filter.status ? <MenuItem onClick={() => {handleClose();onChangeStatus({status:''})}}>All Issues</MenuItem> : null }
                            {statusList.map((item,index) => 
                                <MenuItem onClick={() => {handleClose();onChangeStatus({status:item.value})}}>All {item.label}</MenuItem>
                            )}
                        </Menu>
                    </li>
                    <li className='list-inline-item' style={{width:'300px'}}>
                        <TextField 
                            id='title'
                            value={filter?.free_text||''}
                            fullWidth
                            margin="none"
                            placeholder='Search by code, created by or category'
                            onChange={(e) => onChange('free_text', e.target.value)}
                            InputLabelProps={{shrink: true}}
                            variant='outlined'
                            size='small'
                            inputProps={{style:{paddingTop:'7px',paddingBottom:'7px'}}}
                        />
                    </li>
                    <li className='list-inline-item'>
                        <Button onClick={() => onApplyFilter('apply')} size='small' color='primary' variant='contained'>Apply</Button>
                    </li>
                    <li className='list-inline-item'>
                        <Button onClick={() => onApplyFilter('clear')} size='small' color='primary' variant='outlined'>Clear</Button>
                    </li>
                </ul>
            </form>
        </Paper>
    )
}
export default IssuesSearch;