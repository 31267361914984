import React from 'react';
import { Row, Col } from 'reactstrap';
import { ExportFiles } from '../../../../../shared';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

const BulkOperation = (props) => {
  return(
    <div className="add-bulk-operations">
      {<ul className="list-inline flex-centered">
        <li className="list-inline-item operation-cta">
        {props.export ?
          <ExportFiles exportFile={(fileType) => props.contactListExport(fileType)} files={[{img:'excel_icon.png', label: 'Excel', type:'xls'}]} />:null}
        </li>
       </ul>}
      <button onClick={() => props.addComponent()} className="aims-primary-btn">{props.addText ? props.addText : ' Add '}</button>
       {
        props?.importSection ?
        <Button style={{float:'right', marginTop:"-5px", marginRight:'10px'}} startIcon={<AddIcon color='primary' fontSize='small' />} onClick={() =>props.importFn()} color='primary' variant='outlined' size='small'>Import</Button>
        : null
       }
    </div>
  )
}
export default BulkOperation;
