import BudgetPermissionWrapper from './components/BudgetPermissionWrapper'
import EngineerPermissionWrapper from './components/EngineerPermissionWrapper'
import BudgetExpense from './containers/BudgetExpense'
import BudgetAllocation from './containers/BudgetAllocation'
import BudgetAnalytics from './containers/BudgetAnalytics'
import requireAuth from '../../utils/requireAuth'
import RoleBasedAccess from '../../utils/RoleBasedAccess'
export const expenseManagementRoutes = [
{
    path:'/technical/project/:project_slug/budget/expense',
    component:requireAuth(BudgetPermissionWrapper(RoleBasedAccess(BudgetExpense,['expense_management', 'expense', 'R']))),
    key:'BudgetExpense'
},
{
    path:'/technical/project/:project_slug/budget/allocation',
    component:requireAuth(BudgetPermissionWrapper(RoleBasedAccess(BudgetAllocation,['expense_management', 'budget_allocation', 'R']))),
    key:'BudgetAllocation'
},
{
    path:'/technical/project/:project_slug/budget/analytics',
    component:requireAuth(BudgetPermissionWrapper(RoleBasedAccess(BudgetAnalytics, ['expense_management', 'analytics', 'R']))),
    key:'BudgetAnalytics'
}];