import React, { Fragment } from 'react';
import { withRouter, browserHistory } from 'react-router';
import { Button, ClickAwayListener, Grow, Paper, Popper, MenuItem, MenuList } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { getLocalStorageInfo } from '../../../utils_v2';
import config from '../../../config'
import { castleAirInstance, halcyonInstance } from '../../../shared_elements/components';
const WorkOrderDropdown = ({toggleModalFn, params}) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event, assetType) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
    if(assetType){
      toggleModalFn(assetType)
    }
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  const smbcInstance = getLocalStorageInfo()?.defaultLessor?.id === (config.env.key === "api" ? 465 : 532 );
  return(
    <Fragment>
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
        variant="contained"
        size="small"
      >
        Add Asset <ArrowDropDownIcon />
      </Button>
      <Popper placement="bottom-end" style={{zIndex: '9'}} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  {smbcInstance || halcyonInstance ? null :<MenuItem onClick={(event) => handleClose(event,1)}>
                    Aircraft
                  </MenuItem>}
                  <MenuItem  style={{width:"110px"}} onClick={() => browserHistory.push(`/technical/project/create-engine/${params.project_slug}`)}>Engine</MenuItem>
                  {smbcInstance || halcyonInstance ? null :<MenuItem onClick={(event) => handleClose(event,3)}>APU</MenuItem>}
                  {smbcInstance || halcyonInstance ? null : <MenuItem onClick={(event) => handleClose(event,4)}>Landing Gear</MenuItem>}
                   {smbcInstance  || halcyonInstance|| castleAirInstance? null :<MenuItem onClick={(event) => handleClose(event,5)}>Propeller</MenuItem>}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  )
}

export default withRouter(WorkOrderDropdown);
