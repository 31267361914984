import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { imgStoragePath } from '../../constants';
class BuyLicenseModal extends Component{
    constructor(props) {
        super(props);
        this.state = {
            buyLicenseModal : false
        };
    }

    componentDidMount(){
        // this.props.fetchPlans(this.props.planId);
        // this.props.fetchSelectedPlans(this.props.planId);
    }

    render(){

        return(
            <div>

            </div>
        )
    }
}



export default connect(null, null)(BuyLicenseModal);
