import React from "react";
import {Paper} from '@material-ui/core';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
require('highcharts/modules/exporting')(Highcharts);
const IssueStatusChart = ({prsCategories}) => {
    const chartOptions = {
        chart: {
            type: 'pie',
            height: 300,
            options3d: {
                enabled: true,
                alpha: 45
            }
        },
        title: {text: null},
        plotOptions: {
            pie: {
                innerSize: 100,
                depth: 45,
                dataLabels: {
                    enabled: true
                }
            }
        },
        series:[
            {
                name: 'Item',
                colorByPoint: true,
                data:[
                    {
                        name:'Open',
                        color: '#4b82de',
                        y: prsCategories?.items?.open || 0,
                        sliced: true,
                        selected: true
                    },
                    {
                        name:'Closed',
                        color: '#269b2b',
                        y: prsCategories?.items?.closed || 0
                    },
                    {
                        name:'Reopen',
                        color: '#e86642',
                        y: prsCategories?.items?.reopen || 0
                    },
                    {
                        name:'Unresolved',
                        color: '#dbcc28',
                        y: prsCategories?.items?.unresolved || 0
                    }
                ]
            }
        ],
        exporting: {
            buttons: {
                contextButton: {
                    text: 'Export',
                    menuItems: [
                        {
                            text: 'Export as PNG',
                            onclick: function () { this.exportChart(); }
                        }
                    ]
                }
            }
        },
        credits: {
            enabled: false
        }
    }
    return(
        <Paper>
            <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions}
            />
        </Paper>
    )
}
export default IssueStatusChart;