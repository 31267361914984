import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { useSnackbar } from 'notistack';
import { Button, TableRow, TableCell, TextField, Paper, Table, TableHead, TableBody, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Checkbox, CircularProgress, Tooltip, IconButton } from '@material-ui/core';
import { EmptyCollection, DeletePopUp, PageLoader, Pagination, STableLoader } from '../../../shared_elements';
import { regexConstants } from '../../../constants/regEx';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import { globalDeleteService, globalGetService, globalPostService, globalPutService } from '../../../utils_v2/globalApiServices';
import { checkApiStatus } from '../../../utils_v2';
const status = [{label: 'Scrap', value:1}, {label: 'Reuse', value:2}];
const llpObj = {
    description:'',
    part_number:'',
    serial_number:'',
    csn:null,
    cycles_remaining:null,
    status:'',
    remarks:''
}
const AddEditLLP = ({params, toggleModalFn, addEditLLP, getResponseBack}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false)
    const [error, setError] = useState({});
    const [llp, setLLP] = useState(addEditLLP.data);
    const [pltConstants, setPltConstants] = useState([]);
    useEffect(() => {
        // getPltConstants();
    },[]);

    // const getPltConstants = () => {
    //     globalPostService(`console/get-constants/`, {constant_types:["engine_gap_report_status"]})
    //     .then(response => {
    //         if(checkApiStatus(response)){
    //             setPltConstants(response.data.data)
    //         }
    //     })
    // }
    const onFieldChange = (key, value) => {
      setLLP({...llp, [key]:value})
    }
    const onAddEditLLP = () => {
        let validationInputs = {
            status: llp?.status ? '':'Please select Status',
            description: llp?.description?.trim()?.length ? '':'Please enter Description'
        }
        if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
            setLoading(true);
            if(llp.id){
                globalPutService(`technical/v2/workorder/${params.workOrderSlug}/table-inspections/llp-details/${llp.id}/`, llp)
                .then(response => {
                    if(checkApiStatus(response)){
                        enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                        toggleModalFn(); getResponseBack();
                    }else{
                        enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
                    }
                    setLoading(false);
                })
            }else{
                globalPostService(`technical/v2/workorder/${params.workOrderSlug}/table-inspections/llp-details/`, [llp])
                .then(response => {
                    if(checkApiStatus(response)){
                        enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                        toggleModalFn(); getResponseBack();
                    }else{
                        enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
                    }
                    setLoading(false);
                })
            }
        }else{
            setError(validationInputs);
        }
    }
    return(
        <Dialog
            open={addEditLLP.modal}
            onClose={toggleModalFn}
            className='project-management-modal'
            maxWidth='md'
        >
            <DialogTitle id="scroll-dialog-title">
                {llp.id ? 'Edit':'Add'} LLP Detail
            </DialogTitle>
            <DialogContent dividers={true}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <TextField
                            required
                            name="description"
                            label="Description"
                            fullWidth
                            margin="normal"
                            inputProps={{maxLength: 250}}
                            value={llp?.description||''}
                            onChange={(e) => onFieldChange('description', e.target.value)}
                            error={error?.description}
                            multiline
                            rows={3}
                            helperText={error?.description||''}
                            InputLabelProps={{shrink: true}}
                            onFocus={(e) => setError({...error,'description':''})}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <Autocomplete
                            options = {status}
                            getOptionLabel={option => option.label}
                            name='status'
                            value={llp?.status||null}
                            onChange={(e, data) => onFieldChange('status', data)}
                            renderInput={params => <TextField required error={error?.status} helperText={error?.status||''} onFocus={() => setError({...error, 'status':''})} {...params} label="Status" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined'/>}
                        />
                        
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <TextField
                            name="part_number"
                            label="Part Number"
                            fullWidth
                            margin="normal"
                            inputProps={{maxLength: 20}}
                            value={llp?.part_number||''}
                            onChange={(e) => onFieldChange('part_number', e.target.value)}
                            error={error?.part_number}
                            helperText={error?.part_number||''}
                            InputLabelProps={{shrink: true}}
                            onFocus={(e) => setError({...error,'part_number':''})}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <TextField
                            name="serial_number"
                            label="Serial Number"
                            fullWidth
                            margin="normal"
                            inputProps={{maxLength: 20}}
                            value={llp?.serial_number||''}
                            onChange={(e) => onFieldChange('serial_number', e.target.value)}
                            error={error?.serial_number}
                            helperText={error?.serial_number||''}
                            InputLabelProps={{shrink: true}}
                            onFocus={(e) => setError({...error,'serial_number':''})}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <TextField
                            name="csn"
                            label="LLP CSN"
                            fullWidth
                            margin="normal"
                            inputProps={{maxLength: 8}}
                            value={llp?.csn||''}
                            onChange={(e, value) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange('csn', e.target.value) : e.preventDefault() }}
                            error={error?.cycles_remaining}
                            helperText={error?.csn||''}
                            InputLabelProps={{shrink: true}}
                            onFocus={(e) => setError({...error,'csn':''})}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <TextField
                            name="cycles_remaining"
                            label="Cycles Remaining"
                            fullWidth
                            margin="normal"
                            inputProps={{maxLength: 8}}
                            value={llp?.cycles_remaining||''}
                            onChange={(e, value) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange('cycles_remaining', e.target.value) : e.preventDefault() }}
                            error={error?.cycles_remaining}
                            helperText={error?.cycles_remaining||''}
                            InputLabelProps={{shrink: true}}
                            onFocus={(e) => setError({...error,'cycles_remaining':''})}
                            variant='outlined'
                        />
                    </Grid>
                    
                    <Grid item xs={12}>
                        <TextField
                            name="remarks"
                            label="Comments"
                            fullWidth
                            margin="normal"
                            inputProps={{maxLength: 250}}
                            value={llp?.remarks||''}
                            onChange={(e) => onFieldChange('remarks', e.target.value)}
                            multiline
                            rows={3}
                            InputLabelProps={{shrink: true}}
                            variant='outlined'
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} onClick={toggleModalFn} color="primary" size="small">Cancel</Button>
                <Button disabled={isLoading} onClick={onAddEditLLP} variant="contained" color="primary" size="small">
                    {isLoading ? <CircularProgress size={20} /> : 'SAVE' }
                </Button>
            </DialogActions>
        </Dialog>
    )
}
const LLPDetails = ({params, currentFormDetail, getCurrentFormDetail}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [filter, setFilter] = useState({});
    const [llpInfo, setLlpInfo] = useState({list:[], pagination:null});
    const [addEditLLP, setAddEditLLP] = useState({data:null, modal:false});
    const [deleteLLP, setDeleteLLP] = useState({data:[], type:'', modal:false});
    useEffect(() => {
        getLLPDetails({},'skeletonLoader');
    },[]);
    const getLLPDetails = (query, loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true):setLoading(true);
        globalGetService(`technical/v2/workorder/${params.workOrderSlug}/table-inspections/llp-details/`, {...query, per_page: query?.per_page||20})
        .then(response => {
            if(checkApiStatus(response)){
                setLlpInfo(response.data.data)
            }
            loaderType === 'skeletonLoader' ? setSkeletonLoader(false):setLoading(false);
        })
    }
    const handleChange = (type, flag, id) => {
        if(type === 'single'){
            setDeleteLLP({
                ...deleteLLP,
                type:'bulk',
                data: flag ? [...deleteLLP.data, id]:deleteLLP.data.filter(item =>  item !== id)
            })
        }else{
            let ids = llpInfo.list.map(item => item.id)
            setDeleteLLP({
                ...deleteLLP,
                type:'bulk',
                data: flag ? ids:[]
            })
        }
    }
    const onDeleteLLP = () => {
        setLoading(true);
        globalDeleteService(`technical/v2/workorder/${params.workOrderSlug}/table-inspections/llp-details/`, {ids:deleteLLP.data})
        .then(response => {
            if(checkApiStatus(response)){
                setDeleteLLP({modal:false, type:'', data:[]});
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                getLLPDetails({}, 'pageLoader');
                getCurrentFormDetail('pageLoader');
            }
            setLoading(false);
        })
    }
    const canEdit = currentFormDetail?.current_form?.permissions?.can_save;
    return(
        <div className='forms-table-layout'>
            { skeletonLoader ? <STableLoader count={5} />: 
                <>
                    <Paper className='forms-table-cta' square>
                        <Grid container spacing={1}>
                            <Grid item md={6}></Grid>
                            <Grid item md={6}>
                                { canEdit ? 
                                    <ul className='list-inline'>
                                        { deleteLLP?.data?.length && deleteLLP.type !== 'single'  ?
                                            <li className='list-inline-item'>
                                                <Button onClick={() => setDeleteLLP({...deleteLLP, modal:true})} color='secondary' size='small' variant='contained'>Delete</Button>
                                            </li>:null
                                        }
                                        <li className='list-inline-item'>
                                            <Button disabled={(deleteLLP?.data?.length && deleteLLP.type === 'bulk')} onClick={() => setAddEditLLP({data:llpObj, modal:true})} color='primary' size='small' variant='contained'>Add LLP Detail</Button>
                                        </li>
                                    </ul>:null
                                }
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper className='forms-table-content'>
                        <div style={{height: !llpInfo.list.length ? 'auto':`${window.innerHeight-290}px`,overflow:'auto', paddingRight:'5px'}}>
                            <Table className='mui-table-format' stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        { canEdit ?
                                            <TableCell style={{width:'40px'}}>
                                                <div style={{width:'40px'}}>
                                                    <Checkbox
                                                        size='small'
                                                        checked={deleteLLP?.data?.length && deleteLLP.type === 'bulk' ? deleteLLP?.data?.length === llpInfo?.list.length :false}
                                                        onChange={(e) => handleChange('bulk', e.target.checked)}
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                        disabled={!llpInfo?.list?.length}
                                                    />
                                                </div>
                                            </TableCell>:null
                                        }
                                        <TableCell>SI No</TableCell>
                                        <TableCell>Description</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>P/N</TableCell>
                                        <TableCell>S/N</TableCell>
                                        <TableCell>LLP CSN</TableCell>
                                        <TableCell>Cycle Remaining</TableCell>
                                        <TableCell>Comments</TableCell>
                                        <TableCell align='right'>Action(s)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    { llpInfo.list.map((item, index) =>
                                        <TableRow>
                                            { canEdit ?
                                                <TableCell>
                                                    <div style={{width:'40px'}}>
                                                        <Checkbox
                                                            size='small'
                                                            checked={deleteLLP.data.includes(item.id) && deleteLLP.type === 'bulk'}
                                                            onChange={(e) => handleChange('single', e.target.checked, item.id)}
                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                        />
                                                    </div>
                                                </TableCell>:null
                                            }
                                            <TableCell>
                                                <div style={{width:'80px'}}>{ (llpInfo?.pagination?.per_page||10) *(llpInfo.pagination.page-1) + (index+1)}</div>
                                            </TableCell>
                                            <TableCell>
                                                <div style={{width:'210px'}}>{item?.description||'--'}</div>
                                            </TableCell>
                                            <TableCell>
                                                <div style={{width:'100px'}}>
                                                    {item?.status?.label ?
                                                        <span>{item.status.label}</span>:null
                                                    }
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div style={{width:'100px'}}>{item?.part_number||'--'}</div>
                                            </TableCell>
                                            <TableCell>
                                                <div style={{width:'100px'}}>{item?.serial_number||'--'}</div>
                                            </TableCell>
                                            <TableCell>
                                                <div style={{width:'100px'}}>{item?.csn||'--'}</div>
                                            </TableCell>
                                            <TableCell>
                                                <div style={{width:'100px'}}>{item?.cycles_remaining||'--'}</div>
                                            </TableCell>
                                            <TableCell>
                                                <div style={{width:'210px'}}>{item?.remarks||'--'}</div>
                                            </TableCell>
                                            <TableCell align='right'>
                                                { canEdit ?
                                                    <ul className='list-inline' style={{width:'80px', float:'right'}}>
                                                        {!(deleteLLP?.data?.length && deleteLLP.type === 'bulk') ?
                                                            <>
                                                                <li className='list-inline-item'>
                                                                    <Tooltip title='Edit' arrow>
                                                                        <IconButton size="small"><EditIcon onClick={() => setAddEditLLP({modal:true, data:item})} fontSize='small' color='primary' /> </IconButton>
                                                                    </Tooltip>
                                                                </li>
                                                                <li className='list-inline-item'>
                                                                    <Tooltip title='Delete' arrow>
                                                                        <IconButton size="small"> <DeleteOutlineIcon onClick={() => setDeleteLLP({modal:true, type:'single', data:[item.id]})} fontSize='small' color='secondary' /> </IconButton>
                                                                    </Tooltip>
                                                                </li>
                                                            </>:null
                                                        }
                                                    </ul>:null
                                                }
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </div>
                        <Pagination 
                            pagination={llpInfo.pagination}
                            onChangePage={(event, newPage) => getLLPDetails({...filter, page:newPage+1, per_page: llpInfo.pagination.per_page}, 'pageLoader')}
                            onChangeRowsPerPage={(event) => getLLPDetails({...filter, page:1, per_page: event.target.value}, 'pageLoader')}
                        />
                        { !llpInfo.list.length ? <EmptyCollection title="No records found" />:null }
                    </Paper>
                </>
            }
            { addEditLLP.modal ?
                <AddEditLLP
                    params={params}
                    addEditLLP={addEditLLP}
                    toggleModalFn={() => setAddEditLLP({data:null, modal:false})}
                    getResponseBack={() => {getLLPDetails({page:llpInfo?.pagination?.page||1, per_page:llpInfo?.pagination?.per_page||20}, 'pageLoader'); getCurrentFormDetail('pageLoader')}}
                />:null
            }
            { deleteLLP.modal ?
                <DeletePopUp
                    modal={deleteLLP.modal}
                    toggleModalFn={() => setDeleteLLP({data:[], type:'', modal:false})}
                    title="Delete LLP Detail(s)"
                    content={<h4>Are you sure you want to delete?</h4>}
                    deleteRecordFn={onDeleteLLP}
                />:null
            }
            { isLoading ? <PageLoader />:null }
        </div>
    )
}
export default withRouter(LLPDetails);