import React, { Fragment, useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { Paper, Grid, Button } from "@material-ui/core";
import { TaskManagementWrapper, TimeLog, AddEditTimeLog } from '../components';
import { TableListComp, FilterUIComp, ExportManu, EmptyCollection, PageLoader } from '../../../shared_elements';
import { STabsLoader, STableLoader } from '../../../shared_elements/loaders';
import { globalDeleteService, globalExportService, globalGetService, globalPostService, globalPutService } from "../../../utils_v2/globalApiServices";
import { checkApiStatus, convertFilterObject, downloadFileType, getLocalStorageInfo, removeEmptyKey } from "../../../utils_v2";
import { trackActivity } from '../../../utils/mixpanel';
import { timeSheetHd } from "..";
const TimeSheet = ({params}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(true);
    const [sort, setSort] = useState("")
    const [sort_by, setSortBy] = useState("")
    const [filter, setFilter] = useState({})
    const [applyingFilter, setApplyingFilter] = useState({})
    const [timeSheetInfo, setTimeSheetInfo] = useState({list:[], pagination:{}});
    const [addEditTimeLog, setAddEditTimeLog] = useState({modal:false, timeLog:null});
    const [deleteTimeLog, setDeleteTimeLog] = useState({modal:false, timeLog:null});
    useEffect(() => {
        getTimeSheetLogs({}, 'skeletonLoader');
        trackActivity('Task Management', {
            event_type: 'Page Visited',
            page_title: 'TimeSheet',
          });
    }, []);
    const getTimeSheetLogs = (query, loaderType) => {
        if(loaderType === 'skeletonLoader'){ setSkeletonLoader(true) }else{ setLoading(true) }
        globalGetService(`technical/project/${params.project_slug}/timesheet/`)
        .then(response => {
            if(loaderType === 'skeletonLoader'){ setSkeletonLoader(false) }else{ setLoading(false) }
            if(checkApiStatus(response)){

            }
        })
    }
    const createSortHandler = (sortField) => {
        setSort(sortField)
        setSortBy(sortField === sort ? (sort_by === "asc" ? "desc" : "asc") : "asc")
        if (sortField === sort) {
          if (sort_by === "asc") {
            getTimeSheetLogs({ ...filter, sort: sortField, sort_by: "desc", per_page: timeSheetInfo.pagination.per_page, }, "pageLoader" )
          } else {
            getTimeSheetLogs({ ...filter, sort: sortField, sort_by: "asc", per_page: timeSheetInfo.pagination.per_page, }, "pageLoader" )
          }
        } else {
            getTimeSheetLogs({ ...filter, sort: sortField, sort_by: "asc", per_page: timeSheetInfo.pagination.per_page, }, "pageLoader" )
        }
      }
      const applyFilter = (keyParam, value) => {
        setApplyingFilter({
          ...applyingFilter,
          [keyParam]: value,
        })
      }
      const removeFilter = (keyParam) => {
        let query = Object.assign({}, filter)
        delete query[keyParam]
        getTimeSheetLogs({ ...query }, "pageLoader");
        delete applyingFilter[keyParam]
        setApplyingFilter(applyingFilter);
      }
    return (
        <section className='projects-management-apps projects-management-section'>
            <TaskManagementWrapper>
                <div>
                    <Paper square className="filter-cta">
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={9}></Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <ul className="list-inline cta-list">
                                    <li className="list-inline-item">
                                        <Button onClick={() => setAddEditTimeLog({modal:true, timeLog:null})} color="primary" variant="contained" size="small">Add TimeSheet</Button>
                                    </li>
                                    <li className="list-inline-item">
                                        <Button color="primary" variant="outlined" size="small">Export</Button>
                                    </li>
                                </ul>
                            </Grid>
                        </Grid>
                    </Paper>
                    <TableListComp
                        heads={timeSheetHd}
                        sort={sort}
                        sort_by={sort_by}
                        data={timeSheetInfo.list.map((item, index) => (
                            <TimeLog
                                key={index}
                                item={item}
                                onEdit={() => setAddEditTimeLog({modal:true, timeLog:item})}
                                onDelete={(item) => setDeleteTimeLog({modal:true,timeLog:item})}
                                filter={filter}
                            />
                        ))}
                        noRecord={ timeSheetInfo.list.length ? null : <EmptyCollection title="No records found" /> }
                        pagination={timeSheetInfo.pagination}
                        onChangePage={(event, newPage) => getTimeSheetLogs( { ...filter, page: newPage + 1, per_page: timeSheetInfo.pagination.per_page, }, "pageLoader" ) }
                        onChangeRowsPerPage={(event) => getTimeSheetLogs( { ...filter, page: 1, per_page: event.target.value, }, "pageLoader" ) }
                        createSortHandler={createSortHandler}
                    />
                </div>
            </TaskManagementWrapper>
            { addEditTimeLog.modal ?
                <AddEditTimeLog 
                    addEditTimeLog={addEditTimeLog} 
                    toggleModalFn={() => setAddEditTimeLog({modal:false, timeLog:null})}
                />:null
            }
        </section>
    );
}
export default TimeSheet;