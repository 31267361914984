import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { getLocalStorageInfo } from './';
function RoleBasedAccess(ComposedComponent, extraInfo) {
  class authorizedUser extends Component {
    render() {
      let permissionInfo1 = getLocalStorageInfo()?.user?.permission||{};
      if(permissionInfo1 && permissionInfo1[extraInfo[0]] && permissionInfo1[extraInfo[0]][extraInfo[1]] && permissionInfo1[extraInfo[0]][extraInfo[1]].indexOf(extraInfo[2]) != -1) {
        return(<ComposedComponent {...this.props}/>)
      }else {
        return (
          <div>
            <div style={{position: 'fixed', top: '50%', left: '58%', transform: 'translate(-50%, -58%)', color: '#000000', textAlign: 'center'}}>
              Sorry, You do not have access to this.<br/>
              Please contact to Admin for access
            </div>
          </div>
        )
      }
    }
  }
  return connect(null, null)(authorizedUser);
}
export default RoleBasedAccess;
