import React from "react";
import { Table, TableCell, TableRow, Tooltip } from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { onCheckFormPermission } from '../../../utils_v2';
const Observation = ({currentFormDetail, section, onEdit, onDelete}) => {
    return(
        <TableRow>
            <TableCell>{section.name}</TableCell>
            <TableCell colSpan={3} className="rating-cell">
                <Table>
                    <TableRow>
                        <TableCell align="center" style={{width:'100px'}}>{section?.condition_status_counts?.poor_count||'--'}</TableCell>
                        <TableCell align="center" style={{width:'100px'}}>{section?.condition_status_counts?.average_count||'--'}</TableCell>
                        <TableCell align="center" style={{width:'100px'}}>{section?.condition_status_counts?.good_count||'--'}</TableCell>
                    </TableRow>
                </Table>
            </TableCell>
            <TableCell>{section?.condition_status_counts?.total_count||'--'}</TableCell>
            <TableCell align="right">
                {onCheckFormPermission(currentFormDetail) ?
                    <Tooltip title='Delete Section' arrow>
                        <DeleteOutlineIcon style={{cursor:'pointer'}} onClick={onDelete} color="secondary" fontSize="small" />
                    </Tooltip>:null
                }
                <Tooltip title='Observation List' arrow>
                    {onCheckFormPermission(currentFormDetail) ?
                        <EditIcon style={{cursor:'pointer'}} onClick={() => onEdit('observation')} color="primary" fontSize="small" />:
                        <VisibilityIcon style={{cursor:'pointer'}} onClick={() => onEdit('observation')} color="primary" fontSize="small" />
                    }
                </Tooltip>
            </TableCell>
        </TableRow>
    )
}
export default Observation;