import React, { Component, Fragment } from 'react';
import { UncontrolledCollapse, Table,  } from 'reactstrap';
import { imgStoragePath } from '../../constants';
import PermissionModuleTable from './PermissionModuleTable'
class PermissionModuleList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      accordianArrow:false
    }
  }
  handleAccordian = () => {
    this.setState(prevState => ({
      accordianArrow:!prevState.accordianArrow
    }))
  }
  render() {
    const { module, index, item, topIndex } = this.props
    const { accordianArrow } = this.state
    return(
      <Fragment>
        <Table>
          <thead>
            <tr>
              <th style={{width:'200px'}}></th>
              <th style={{width:'200px'}}>Create</th>
              <th style={{width:'200px'}}>View</th>
              <th style={{width:'200px'}}>Edit</th>
              {module == "records" ?
              <th style={{width:'200px'}}>Download</th> : null
              }
              <th style={{width:'200px'}}>Delete</th>
            </tr>
          </thead>
          <tbody>
          {Object.keys(item.permission[module]).map((key, keyIndex) =>
            <PermissionModuleTable paramkey={key} key={keyIndex} module={module} item={item} />
          )}

          </tbody>
        </Table>
      </Fragment>
    )
  }
}
export default PermissionModuleList;
