import React, { Component } from 'react';
import { Link } from 'react-router';
import PopoverItem from './PopoverItem';
import AssetPopover from './AssetPopover';
import { imgStoragePath } from '../../../../../constants';
import { trackActivity } from '../../../../../utils/mixpanel';
export default class AuditCheckList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popoverOpen: false,
      value: 0,
      remarks: '',
      crudType: 'add',
      remarkErr: '',
      error: false
    }
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const data = Object.assign({}, this.props.list)
    this.setState({
      remarks: data.answer && data.answer.remarks != '' ? data.answer.remarks : '',
      value: data.answer && data.answer.value ? data.answer.value : '',
      crudType: data.answer && Object.keys(data.answer).length ? 'edit' : 'add'
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      crudType: nextProps.list.answer && Object.keys(nextProps.list.answer).length ? 'edit' : 'add'
    });
  }

  previewFile = (data) => {
    let extension = data.name.substring(data.name.lastIndexOf('.') + 1).toLowerCase()
    if (['png', 'jpg', 'jpeg', 'gif', 'pdf', 'csv', 'xls', 'xlsx'].includes(extension)) {
      this.props.previewFile(data)
    } else {
      this.props.downloadFile(data)
    }
  }

  handleChange(value) {
    this.setState(prevState => ({
      ...prevState,
      remarks: value
    }));
    if (value) {
      this.setState(prevState => ({
        ...prevState,
        remarkErr: ''
      }));
    }
  }
  changeStatus = (value, listindex, id) => {
    this.setState(prevState => ({
      ...prevState,
      value: value,
      remarkErr: '',
      error: false
    }))
    if ((value == 2 || value == 3) && !(this.state.remarks && this.state.remarks.length)) {
      this.setState(prevState => ({
        ...prevState,
        value: value,
        remarkErr: 'Please enter remarks',
        error: false
      }))
    }
    this.props.changeStatus(value);

    setTimeout(() => this.state.remarkErr == '' ? this.props.saveData(this.state.crudType, listindex, id) : null, 300);
  }

  changeRemark = (value, checkValue, listindex, id) => {
    if (value === '' && checkValue != 1) {
      this.setState(prevState => ({
        remarkErr: 'Please enter remarks',
      }))
      return
    }
    this.props.changeRemark(value);

    if (checkValue == undefined) {
      this.setState(prevState => ({
        error: true
      }))
    } else {
      setTimeout(() => this.props.saveData(this.state.crudType, listindex, id), 300);
      this.setState(prevState => ({
        error: false
      }))
    }
  }

  fileSelectFn = (files) => {
    if (!files.length) {
      return false;
    }
    this.props.saveData(this.state.crudType, this.props.listindex, this.props.list.id, files, [], [])
  }

  deleteFiles = (id, fileArr) => {
    const existFileArr = fileArr.filter(data => data.id != id);
    this.props.saveData('delete', this.props.listindex, this.props.list.id, [], existFileArr)
  }
  render() {
    const { listindex, list, id, auditLoader, formInfo, section } = this.props;
    return (
      <tr>
        <td style={{width:'48%'}}>
          <h4>{list.name != '' ? list.name : '--'}</h4>
          <div>
            {section.name.toLowerCase() != 'photos' && section.name.toLowerCase() != 'contacts' ? <ul className="list-inline attach-files">
              {formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save ?
                <li className="list-inline-item">
                  <span className="file-attachment flex-centered" >
                    <img className="img-attachment" src={imgStoragePath + "attach_doc_icon.png"} alt="img" />
                    Upload the file(s)
                    <input style={{ left: "-59px" }} type="file"
                      onChange={(e) => this.fileSelectFn(e.target.files)}
                      accept={".png, .jpg, .jpeg, .pdf, .xls, .xlsx, .doc, .docx"}
                      multiple={true}
                    />
                    {list.file_naming_convention && list.file_naming_convention.length ?
                      <AssetPopover
                        placement='right'
                        tagetId={'Popover-t003-1' + id}
                        fileNames={list.file_naming_convention}
                      />
                      : null}
                  </span>
                </li> : null
              }
              {formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save ?
                <li className="list-inline-item">
                  <span className="file-attachment flex-centered" onClick={() => this.props.toggleEditSideBar(list.name)}>
                    <img className="img-attachment" src={imgStoragePath + "attach_link_icon.png"} alt="img" />
                    Link file from Records Module
                  </span>
                </li> : null
              }
            </ul> : null}
            <ul className="list-inline attached-files">
              {list.answer && list.answer.files ?
                list.answer.files.map((data, key) =>
                  <li key={key} className="list-inline-item">
                    <span className="flex-centered">
                      <a style={{ marginRight: '10px' }} onClick={() => this.previewFile(data)}>
                        <img className="file-type" src={imgStoragePath + 'pdf_icon.png'} alt="img" />
                        {data.name}
                      </a>
                      <i style={{ right: '16px', fontSize: '12px', marginTop: '3px' }} onClick={() => this.props.downloadFile(data)} className="fa fa-download close" src={imgStoragePath + 'downloadresources.png'} alt="img"></i>
                      {formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save ?
                        <img onClick={() => this.deleteFiles(data.id, list.answer.files)} className="close" src={imgStoragePath + 'black_close.png'} alt="img" /> : null
                      }
                    </span>
                  </li>
                ) : null
              }
            </ul>
          </div>
          {list.notes != null && list.notes != '' ?
            <p style={{ fontSize: '11px', fontStyle: 'italic', color: '#717171' }}>Note: {list.notes}</p> : null
          }
        </td>
        <td style={{width:'22%'}}>
          <div style={{display:'flex', justifyContent:'space-around'}}>
            {
              formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save ?
                <img className="img_status" onClick={() => { this.changeStatus(1, listindex, list.id); trackActivity('T003', { event_type: 'Status Updated', page_title: 'Inspection Documents Checklist', status_id: list.id ? list.id : '', status_code: 1 }) }} src={imgStoragePath + (list.answer.value != 1 ? 'tick_gray.png' : 'tick_color.png')} alt="img" />
                : <img className="img_status" src={imgStoragePath + (list.answer.value != 1 ? 'tick_gray.png' : 'tick_color.png')} alt="img" />
            }
            {
              formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save ?
                <img className="img_status" onClick={() => { this.changeStatus(3, listindex, list.id); trackActivity('T003', { event_type: 'Status Updated', page_title: 'Inspection Documents Checklist', status_id: list.id ? list.id : '', status_code: 3 }) }} src={imgStoragePath + (list.answer.value != 3 ? 'cross_gray.png' : 'cross_color.png')} alt="img" />
                : <img className="img_status" src={imgStoragePath + (list.answer.value != 3 ? 'cross_gray.png' : 'cross_color.png')} alt="img" />
            }
            {
              formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save ?
                <img className="img_status" onClick={() => { this.changeStatus(2, listindex, list.id); trackActivity('T003', { event_type: 'Status Updated', page_title: 'Inspection Documents Checklist', status_id: list.id ? list.id : '', status_code: 2 }) }} src={imgStoragePath + (list.answer.value != 2 ? 'na_gray.png' : 'na_color.png')} alt="img" />
                : <img className="img_status" src={imgStoragePath + (list.answer.value != 2 ? 'na_gray.png' : 'na_color.png')} alt="img" />
            }
          </div>
        </td>
        <td>
          {formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save ?
            <div>
              <textarea value={this.state.remarks}
                className={this.state.remarkErr.length ? 'mandatory' : 'no mandatory ' + this.state.value}
                rows="6"
                onChange={(e) => this.handleChange(e.target.value)}
                onBlur={(evt) => this.changeRemark(evt.target.value, list.answer.value, listindex, list.id)}
              >{this.state.remarks}</textarea>
              {
                this.state.error ?
                  <h6 className="error-msg">Please update status before entering remark</h6>
                  : null
              }
            </div> :
            <p>{this.state.remarks ? this.state.remarks : '--'}</p>
          }

          <h6 className="error-msg">{this.state.remarkErr}</h6>
        </td>
        <td>
          {list.answer.value != 1 && list.answer.value != 2 && list.answer.value != 3 ? null :
            formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save ?
              <div className="save-block" style={{ display: 'none' }}>
                <Link className="save-btn" style={auditLoader || this.state.remarkErr.length ? { opacity: '0.5', cursorPointer: 'none' } : { opacity: '1' }} onClick={() => auditLoader || this.state.remarkErr.length ? '' : this.props.saveData(this.state.crudType, listindex, list.id)}>
                  Save
                </Link>
              </div> : null
          }
        </td>
      </tr>
    )
  }
}
