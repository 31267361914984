import React, { useState } from 'react';
import { Tooltip, Grid, TextField, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Button } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { fieldDateFormat, backendDateFormat } from '../../../constants';
import { EmptyCollection, DeletePopUp, PageLoader } from '../../../shared_elements';
import { regexConstants } from '../../../constants/regEx';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import GetAppIcon from '@material-ui/icons/GetApp';
const ShopVisitCRU = ({mode, currentFormDetail, shopVisitCrud, onFieldChange, error, onResetError, uploadAttachments, onChangeAttachmentDesc, onRemoveAttachment}) => {
  const [deleteAttachment, setDeleteAttachment] = useState({modal:false ,data:null})
  const canEdit = currentFormDetail?.current_form?.permissions?.can_save && mode === 'edit';
  return(
    <Grid container spacing={1}>
      <Grid item xs={12} md={3}>
        <TextField
          disabled={!canEdit}
          required
          name="shop_visit_number"
          label="Shop Visit Number"
          fullWidth
          margin="normal"
          inputProps={{maxLength: 20}}
          error={error?.shop_visit_number}
          helperText={error?.shop_visit_number||''}
          onFocus={() => onResetError('shop_visit_number')}
          value={shopVisitCrud?.shop_visit_number||''}
          InputLabelProps={{shrink: true}}
          onChange={(e) => { onFieldChange('shop_visit_number', e.target.value)}}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDatePicker
            disabled={!canEdit}
            margin="normal"
            name="date_of_removal"
            label="Date of Removal"
            format={fieldDateFormat}
            fullWidth
            disableFuture={true}
            error={error?.date_of_removal}
            helperText={error?.date_of_removal||''}
            InputLabelProps={{shrink: true}}
            value={shopVisitCrud?.date_of_removal||null}
            onChange={(data, value) => {onFieldChange('date_of_removal', data ? moment(data).format(backendDateFormat):data);onResetError('date_of_removal')}}
            inputVariant="outlined"
          />
        </MuiPickersUtilsProvider>
      </Grid>

      <Grid item xs={12} md={3}>
        <TextField
          disabled={!canEdit}
          id="reason_for_removal"
          label="Reason of Removal"
          fullWidth
          margin="normal"
          inputProps={{maxLength: 30}}
          value={shopVisitCrud?.reason_for_removal||''}
          onChange={(e) => onFieldChange('reason_for_removal', e.target.value)}
          InputLabelProps={{shrink: true}}
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12} md={3}>
        <TextField
          disabled={!canEdit}
          id="shop_facility"
          label="Shop Facility"
          fullWidth
          margin="normal"
          inputProps={{maxLength: 30}}
          value={shopVisitCrud?.shop_facility||''}
          onChange={(e) => onFieldChange('shop_facility', e.target.value)}
          InputLabelProps={{shrink: true}}
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12} md={3}>
        <TextField
          disabled={!canEdit}
          id="tsn"
          label="TSN"
          fullWidth
          margin="normal"
          inputProps={{maxLength: 8}}
          value={shopVisitCrud?.tsn||''}
          onChange={(e, value) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange('tsn', e.target.value): e.preventDefault()}}
          InputLabelProps={{shrink: true}}
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12} md={3}>
        <TextField
          disabled={!canEdit}
          id="csn"
          label="CSN"
          fullWidth
          margin="normal"
          inputProps={{maxLength: 8}}
          value={shopVisitCrud?.csn||''}
          onChange={(e, value) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange('csn', e.target.value) : e.preventDefault() }}
          InputLabelProps={{shrink: true}}
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12} md={3}>
        <TextField
          disabled={!canEdit}
          id="tslsv"
          label="TSLSV"
          fullWidth
          margin="normal"
          inputProps={{maxLength: 8}}
          value={shopVisitCrud?.tslsv||''}
          onChange={(e, value) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange('tslsv', e.target.value): e.preventDefault()}}
          InputLabelProps={{shrink: true}}
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12} md={3}>
        <TextField
          disabled={!canEdit}
          id="cslsv"
          label="CSLSV"
          fullWidth
          margin="normal"
          inputProps={{maxLength: 8}}
          value={shopVisitCrud?.cslsv||''}
          onChange={(e, value) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange('cslsv', e.target.value) : e.preventDefault() }}
          InputLabelProps={{shrink: true}}
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12} md={3}>
        <TextField
          disabled={!canEdit}
          id="tsr"
          label="TSR"
          fullWidth
          margin="normal"
          inputProps={{maxLength: 8}}
          value={shopVisitCrud?.tsr||''}
          onChange={(e, value) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange('tsr', e.target.value): e.preventDefault()}}
          InputLabelProps={{shrink: true}}
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12} md={3}>
        <TextField
          disabled={!canEdit}
          id="csr"
          label="CSR"
          fullWidth
          margin="normal"
          inputProps={{maxLength: 8}}
          value={shopVisitCrud?.csr||''}
          onChange={(e, value) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange('csr', e.target.value) : e.preventDefault() }}
          InputLabelProps={{shrink: true}}
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12} md={3}>
        <TextField
          disabled={!canEdit}
          id="tso"
          label="TSO"
          fullWidth
          margin="normal"
          inputProps={{maxLength: 8}}
          value={shopVisitCrud?.tso||''}
          onChange={(e, value) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange('tso', e.target.value): e.preventDefault()}}
          InputLabelProps={{shrink: true}}
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12} md={3}>
        <TextField
          disabled={!canEdit}
          id="cso"
          label="CSO"
          fullWidth
          margin="normal"
          inputProps={{maxLength: 8}}
          value={shopVisitCrud?.cso||''}
          onChange={(e, value) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange('cso', e.target.value) : e.preventDefault() }}
          InputLabelProps={{shrink: true}}
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12} md={3}>
        <TextField
          disabled={!canEdit}
          id="tshi"
          label="TSHSI"
          fullWidth
          margin="normal"
          inputProps={{maxLength: 8}}
          value={shopVisitCrud?.tshi||''}
          onChange={(e, value) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange('tshi', e.target.value): e.preventDefault()}}
          InputLabelProps={{shrink: true}}
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12} md={3}>
        <TextField
          disabled={!canEdit}
          id="cshi"
          label="CSHSI"
          fullWidth
          margin="normal"
          inputProps={{maxLength: 8}}
          value={shopVisitCrud?.cshi||''}
          onChange={(e, value) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange('cshi', e.target.value) : e.preventDefault() }}
          InputLabelProps={{shrink: true}}
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12} md={3}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDatePicker
            disabled={!canEdit}
            margin="normal"
            id="induction_date"
            label="Engine Induction Date"
            format={fieldDateFormat}
            fullWidth
            disableFuture={true}
            error={error?.induction_date}
            helperText={error?.induction_date||''}
            InputLabelProps={{shrink: true}}
            value={shopVisitCrud?.induction_date||null}
            onChange={(data, value) => {onFieldChange('induction_date', data ? moment(data).format(backendDateFormat):data); onResetError('induction_date')}}
            inputVariant="outlined"
          />
        </MuiPickersUtilsProvider>
      </Grid>

      <Grid item xs={12} md={3}>
        <TextField
          disabled={!canEdit}
          id="final_workscope"
          label="Engine Workscope"
          fullWidth
          margin="normal"
          inputProps={{maxLength: 30}}
          value={shopVisitCrud?.final_workscope||''}
          onChange={(e) => onFieldChange('final_workscope', e.target.value)}
          InputLabelProps={{shrink: true}}
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12} md={3}>
        <TextField
          disabled={!canEdit}
          id="build_target"
          label="Engine Build Target"
          fullWidth
          margin="normal"
          inputProps={{maxLength: 30}}
          value={shopVisitCrud?.build_target||''}
          onChange={(e) => onFieldChange('build_target', e.target.value)}
          InputLabelProps={{shrink: true}}
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12} md={3}>
        <TextField
          disabled={!canEdit}
          id="test_run_egt_margin"
          label="Test Run EGT Margin"
          fullWidth
          margin="normal"
          inputProps={{maxLength: 30}}
          value={shopVisitCrud?.test_run_egt_margin||''}
          onChange={(e) => onFieldChange('test_run_egt_margin', e.target.value)}
          InputLabelProps={{shrink: true}}
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12} md={3}>
        <TextField
          disabled={!canEdit}
          id="llp_status"
          label="LLP Status"
          fullWidth
          margin="normal"
          inputProps={{maxLength: 30}}
          value={shopVisitCrud?.llp_status||''}
          onChange={(e) => onFieldChange('llp_status', e.target.value)}
          InputLabelProps={{shrink: true}}
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12} md={3}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDatePicker
            disabled={!canEdit}
            margin="normal"
            id="arc_date"
            label="ARC Date ( EASA/FAA)"
            format={fieldDateFormat}
            fullWidth
            disableFuture={true}
            error={error?.arc_date}
            helperText={error?.arc_date||''}
            InputLabelProps={{shrink: true}}
            value={shopVisitCrud?.arc_date||null}
            onChange={(data, value) => {onFieldChange('arc_date', data ? moment(data).format(backendDateFormat):data); onResetError('arc_date')}}
            inputVariant="outlined"
          />
        </MuiPickersUtilsProvider>
      </Grid>

      <Grid item xs={12} md={3}>
        <TextField
          disabled={!canEdit}
          id="total_air_temperature"
          label="TAT"
          fullWidth
          margin="normal"
          inputProps={{maxLength: 30}}
          value={shopVisitCrud?.total_air_temperature||''}
          onChange={(e) => onFieldChange('total_air_temperature', e.target.value)}
          InputLabelProps={{shrink: true}}
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12} md={3}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDatePicker
            disabled={!canEdit}
            margin="normal"
            id="date_of_reinstallation"
            label="Date of Reinstallation"
            format={fieldDateFormat}
            fullWidth
            disableFuture={true}
            error={error?.date_of_reinstallation}
            helperText={error?.date_of_reinstallation||''}
            InputLabelProps={{shrink: true}}
            value={shopVisitCrud?.date_of_reinstallation||null}
            onChange={(data, value) => {onFieldChange('date_of_reinstallation', data ? moment(data).format(backendDateFormat):data); onResetError('date_of_reinstallation')}}
            inputVariant="outlined"
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={12} md={12}>
        { canEdit ?
          <div style={{textAlign:'right', marginBottom:'10px'}}>
            <a className="add-attachment" style={{ cursor: 'pointer'}}> + ADD ATTACHMENT
                <input type="file" onChange = {(e) =>  uploadAttachments(e.target.files)}
                  accept=".xls,.xlsx,application/pdf"
                  onClick = {(e) => e.target.value = null}
                  style={{opacity:0}}
                  multiple
                />
            </a>
            <p><span style={{fontSize:'11px'}}>(Only Excel and PDF supported, with max 10 file at a time)</span></p>
          </div>:null
        }
        
        <Table className='mui-table-format'>
          <TableHead>
            <TableRow>
              <TableCell>File Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Upload By</TableCell>
              <TableCell align='right'>Action(s)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { shopVisitCrud?.attachments && shopVisitCrud.attachments.map((attachment, index) =>
              <TableRow key={index}>
                <TableCell>{attachment.name}</TableCell>
                <TableCell>
                  <TextField
                    disabled={!canEdit}
                    id={`description_${index}`}
                    fullWidth
                    margin="none"
                    inputProps={{maxLength: 250}}
                    value={attachment?.description||''}
                    onChange={(e) => onChangeAttachmentDesc('description', e.target.value, index)}
                    InputLabelProps={{shrink: true}}
                    variant="outlined"
                  />
                </TableCell>
                <TableCell>
                  {attachment?.user?.name||'--'}
                </TableCell>
                <TableCell align='right'>
                  <ul className='list-inline'>
                    <li className='list-inline-item'>
                      <a href={attachment.attachment} download>
                        <Tooltip title='Download' arrow>
                          <IconButton size="small">
                            <GetAppIcon fontSize='small' color='primary' />
                          </IconButton>
                        </Tooltip>
                      </a>
                    </li>
                    { canEdit ?
                      <li className='list-inline-item'>
                        <Tooltip title='Remove' arrow>
                          <IconButton size="small">
                            <DeleteOutlineIcon onClick={() => setDeleteAttachment({modal:true, data:attachment, index:index})} fontSize='small' color='secondary' />
                          </IconButton>
                        </Tooltip>
                      </li>:null
                    }
                  </ul>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        { !shopVisitCrud.attachments.length ? <EmptyCollection title="No records found" />:null }
        { deleteAttachment.modal ?
            <DeletePopUp
                modal={deleteAttachment.modal}
                toggleModalFn={() => setDeleteAttachment({data:null, modal:false})}
                title="Delete Attachment"
                content={<h4>Are you sure you want to delete?</h4>}
                deleteRecordFn={() => {onRemoveAttachment(deleteAttachment); setDeleteAttachment({modal:false, data:null, index:null})}}
            />:null
        }
      </Grid>
    </Grid>
  )
}
export default ShopVisitCRU
