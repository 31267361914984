import React, { Fragment, useState, useEffect } from "react"
import { withRouter } from 'react-router';
import { useSnackbar } from "notistack"
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@material-ui/core"
import { KeyboardDatePicker, MuiPickersUtilsProvider, } from "@material-ui/pickers"
import MomentUtils from "@date-io/moment"
import moment from "moment"
import { backendDateFormat, fieldDateFormat } from "../../../constants";
import { globalPostService, globalPutService } from "../../../utils_v2/globalApiServices";
import { checkApiStatus } from "../../../utils_v2";
import { stageErrorCode } from "..";
import { dateValidations, fieldValidation, } from "../../../utils_v2/formValidation";
import { trackActivity } from '../../../utils/mixpanel';
const initRow = { name: "", notes: "", expected_start_date: null, expected_end_date: null, }
const AddEditStage = ({ addEditStage, params, toggleModalFn, getResponseBack, editFormId, addStages }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isLoading, setLoading] = useState(false);
  const [row, setRow] = useState( addEditStage.stage ? [addEditStage.stage] :[initRow]);
  const [error, setError] = useState([]);
  const [doubleClick, setDoubleClick] = useState(false);
  const removeRow = () => {
    const listRow = [...row]
    listRow.pop()
    setRow(listRow)
  }
  const onFieldChange = (index, keyParam, value) => {
    setRow(
      row.map((item, itemIndex) => itemIndex !== index ? item : { ...item, [keyParam]: value })
    );
  }
  const onRestErrorKey = (index, keyParam) => {
    setError(
      error.map((err, errIndx) => errIndx !== index ? err : { ...err, [keyParam]: "" })
    );
  }  
  const onAddEditStages = () => {
    // Apply Validation
    let stageErrors = [], isError=false;
    stageErrors = row.map(item => {
      let errObj = {
        name:item.name ? '':'Please enter Name',
        expected_start_date:item.expected_start_date ? '':'Please enter Start Date',
        expected_end_date:item.expected_end_date ? '':'Please enter End Date'
      }
      if(item.expected_start_date && item.expected_end_date){
        if(!moment(item.expected_end_date).isAfter(item.expected_start_date)){
          errObj.expected_end_date = 'End Date should be greater than Start Date'
        }
      }
      return errObj;
    });
    stageErrors.map(data => {
      if(!Object.keys(data).every((k) => { return data[k] === ''})){
        isError = true
      }
    });
    if(!isError){
      setLoading(true);
      if(addEditStage.stage){
        globalPutService( `technical/project/${params.project_slug}/stages/${addEditStage.stage.id}/`, row[0])
        .then((response) => {
          setLoading(false)
          if (checkApiStatus(response)) {
            enqueueSnackbar(response.data.message, { variant: "success", anchorOrigin: { horizontal: "right", vertical: "top" }})
            toggleModalFn();getResponseBack();
            trackActivity('Task Management', {
              event_type: 'Update Stages Success',
              page_title: 'Update Stages',
              stages_id: addEditStage.stage.id ? addEditStage.stage.id : '',
              project_slug: params.project_slug,
              request_body: row[0] ? row[0] : [],
              response_msg: response.data.message
            });
          } else {
            enqueueSnackbar(response.data.message, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "top" }})
            trackActivity('Task Management', {
              event_type: 'Update Stages Failed',
              page_title: 'Update Stages',
              project_slug: params.project_slug,
              request_body: row[0] ? row[0] : [],
              response_msg: response.data.message,
              error_source: 'Backend',
              error_message: response.data.message
            });
          }
        })
      }else{
        globalPostService( `technical/project/${params.project_slug}/stages/`, row)
        .then((response) => {
          setLoading(false)
          if (checkApiStatus(response)) {
            enqueueSnackbar(response.data.message, { variant: "success", anchorOrigin: { horizontal: "right", vertical: "top" }, })
            toggleModalFn();getResponseBack();
            trackActivity('Task Management', {
              event_type: 'Add Stages Success',
              page_title: 'Add Stages',
              project_slug: params.project_slug,
              request_body: row ? row : '',
              response_msg: response.data.message
            })
          } else {
            enqueueSnackbar(response.data.message, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "top" }})
            trackActivity('Task Management', {
              event_type: 'Add Stages Failed',
              page_title: 'Add Stages',
              project_slug: params.project_slug,
              request_body: row ? row : '',
              error_source: 'Backend',
              error_message: response.data.message
            })
          }
        })
      }
    }else{
      setError(stageErrors)
      trackActivity('Task Management', {
        event_type: 'Add/update Stages Failed',
        page_title: 'Add/update Stages',
        project_slug: params.project_slug,
        milesone_id: addEditStage?.stage?.id ? addEditStage.stage.id : '',
        error_source: 'Frontend',
        error: stageErrors ? stageErrors : ''
      });
    }
  }
  return (
    <Fragment>
      <Dialog
          className="project-management-modal"
          open={addEditStage.modal}
          onClose={() => toggleModalFn()}
          maxWidth={"md"}
      >
        <DialogTitle>{addEditStage.stage ? 'Edit Stage':'Add Stage(s)'}</DialogTitle>
        <DialogContent dividers={true}>
          <Table className="mui-table-format stages-table">
            <TableHead>
              <TableRow>
                <TableCell>
                  Stage Name<span className="MuiFormLabel-asterisk">*</span>
                </TableCell>
                <TableCell>Remarks</TableCell>
                <TableCell>
                  Expected Start Date
                  <span className="MuiFormLabel-asterisk">*</span>
                </TableCell>
                <TableCell>
                  Expected End Date
                  <span className="MuiFormLabel-asterisk">*</span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(row) &&
                row.map((row, index) => (
                  <TableRow
                    key={index}
                  >
                    <TableCell align="right">
                      <TextField
                        autoFocus
                        variant="outlined"
                        id={`name_${index}`}
                        name="name"
                        onChange={(e, value) => { onFieldChange(index, "name", e?.target.value?.trim()?.length ? e.target.value: '')}}
                        value={row.name}
                        error={error[index] && error[index].name ? true : false}
                        onFocus={() => onRestErrorKey(index, "name")}
                        helperText={error[index] && error[index].name ? error[index].name : ""}
                        fullWidth
                      />
                    </TableCell>
                    <TableCell align="right">
                      <TextField
                        autoFocus
                        variant="outlined"
                        id={`notes_${index}`}
                        name="notes"
                        onChange={(e, value) => onFieldChange(index, "notes", e.target.value) }
                        value={row.notes}
                        fullWidth
                        inputProps={{ maxLength: 255 }}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                          id={`expected_start_date_${index}`}
                          name="expected_start_date"
                          onChange={(data, value) => { onFieldChange( index, "expected_start_date", data ? moment(data).format(backendDateFormat):data); onRestErrorKey(index, "expected_start_date") }}
                          value={row.expected_start_date}
                          error={error[index] && error[index].expected_start_date ? true : false}
                          helperText={ error[index] && error[index].expected_start_date ? error[index].expected_start_date : "" }
                          onFocus={() => onRestErrorKey(index, "expected_start_date") }
                          format={fieldDateFormat}
                          inputVariant="outlined"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          minDate={moment().subtract(25, "years")}
                        />
                      </MuiPickersUtilsProvider>
                    </TableCell>
                    <TableCell align="right">
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                          id={`expected_end_date_${index}`}
                          name="expected_end_date"
                          format={fieldDateFormat}
                          onChange={(data, value) => { onFieldChange( index, "expected_end_date", data ? moment(data).format(backendDateFormat):data);onRestErrorKey(index, "expected_end_date") }}
                          value={row.expected_end_date}
                          error={error[index] && error[index].expected_end_date ? true : false}
                          helperText={ error[index] && error[index].expected_end_date ? error[index].expected_end_date : "" }
                          onFocus={() => onRestErrorKey(index, "expected_end_date") }
                          inputVariant="outlined"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          minDate={moment().subtract(25, "years")}
                        />
                      </MuiPickersUtilsProvider>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          { addEditStage.stage === null ?
            <Fragment>
              <Button size="small" color="primary" variant="outlined" style={{ marginTop: "10px" }} onClick={() => setRow([ ...row, initRow])} > ADD Row </Button>
              { row.length !== 1 ?
                <Button size="small" color="secondary" variant="outlined" style={{ marginTop: "10px", float: "right" }} onClick={removeRow} > Remove </Button>:null
              }
          </Fragment>:null
          }
        </DialogContent>
        <DialogActions className="dialogActions">
          <Button disabled={isLoading} color="primary" variant="outlined" size="small" onClick={toggleModalFn} > Cancel </Button>
          <Button disabled={isLoading} color="primary" variant="contained" className="blueFill-btn" size="small" onClick={onAddEditStages}> 
            {isLoading ? <CircularProgress size={24} className="buttonProgress" /> : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
export default withRouter(AddEditStage)
