import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { Tabs, Tab, Paper } from '@material-ui/core';
import { PageLoader, ExportManu } from '../../../shared_elements';
import { GeneralDetail, LLPDetails, PrerequisiteContent, InspectionWorkScope, DisassemblyFinding, ShopVisitCard } from '../components';
import { FormWrapper, FormSkeletonLoader } from '../../Elements';
import { checkApiStatus, downloadFileType, getLocalStorageInfo } from '../../../utils_v2';
import { globalGetService, globalPostService } from '../../../utils_v2/globalApiServices';
import { globalStaticExportService } from '../../../globalServices';
import { trackActivity } from '../../../utils/mixpanel';
import RestrictedAccess from '../../../shared_elements/components/RestrictedAccess';
import InvalidUrl from '../../../shared_elements/components/InvalidUrl';
import { browserHistory } from 'react-router';
const TableInspection = ({ params }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [tabIndex, setTabIndex] = useState('general-details');
    const [formLoader, setFormLoader] = useState(false);
    const [currentFormDetail, setCurrentFormDetail] = useState({})
    useEffect(() => {
        getCurrentFormDetail('formLoader');
        trackActivity('Project Management', { event_type: 'Page Visited', page_title: 'TE005 Form' });
    }, []);
    const getCurrentFormDetail = (loaderType) => {
        if (loaderType) { setFormLoader(true) }
        globalGetService(`technical/engine-workorder/${params.workOrderSlug}/forms/`, { form: params.formSlug })
            .then(response => {
                if (checkApiStatus(response)) {
                    setCurrentFormDetail(response.data.data)
                }
                if (loaderType) { setFormLoader(false) }
            })
    }
    const onChangeFormStatus = (data) => {
        setLoading(true)
        globalPostService(`technical/workorder/${params.workOrderSlug}/form/${params.formSlug}/status-update/`, data)
            .then(response => {
                setLoading(false)
                if (checkApiStatus(response)) {
                    getCurrentFormDetail('formLoader');
                    enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                }
            });
    }
    const exportFormReport = (extension) => {
        enqueueSnackbar('TE005 Report Downloading ...', { autoHideDuration:50000, variant: 'info',anchorOrigin:{horizontal:'right', vertical:'bottom'}})
        globalStaticExportService(`technical/engine-workorder/${params.workOrderSlug}/table-inspections/`, {download:extension})
        .then(response => {
            downloadFileType(response, (`TE005_${getLocalStorageInfo().defaultLessor.name}_${moment().format('YYYY-MM-DD')}.`), extension);
            closeSnackbar();
        });
    }
    let formViewPermission = currentFormDetail?.list?.length && currentFormDetail?.list?.find(form => form.slug == currentFormDetail?.current_form?.slug)
    let formType = window.location?.pathname?.toUpperCase()?.includes(currentFormDetail?.current_form?.name)

    return (
        <section className='projects-management-apps project-forms-section'>
            {currentFormDetail && Object.keys(currentFormDetail).length && formViewPermission && formType?
                <FormWrapper
                    hideAction={currentFormDetail?.current_form.progress === 0 ? true : false}
                    formInfo={currentFormDetail}
                    exportReportBtn={<ExportManu disabled={currentFormDetail?.current_form.progress === 0 ? true : false} exportReportFn={(file) => exportFormReport(file.extension)} title="Export Report" files={[{ title: 'PDF', extension: 'pdf', key: '' }, { title: 'Excel', extension: 'xls', key: '' }]} />}
                    updateFormStatusFn={(data) => onChangeFormStatus(data)}
                /> : formLoader ? <FormSkeletonLoader /> : null
            }
            {isLoading || formLoader ? <PageLoader /> : 
            formType ?formViewPermission?
            <div className='technical-engine-forms'>
                <Paper square style={{marginBottom:'15px'}}>
                    <Tabs
                        value={tabIndex}
                        onChange={(e, newValue) => setTabIndex(newValue)}
                        indicatorColor="primary"
                        textColor="primary"
                    >
                        <Tab label="General Details" value='general-details' />
                        <Tab label="Last Shop Visit" value='lsv' />
                        <Tab label="Disassembly Findings" value='disassembly' />
                        <Tab label="LLP Details" value='llp-details' />
                        <Tab label="WorkScope" value='work-scope' />
                    </Tabs>
                </Paper>
                { tabIndex === 'general-details' ? <GeneralDetail currentFormDetail={currentFormDetail} getCurrentFormDetail={getCurrentFormDetail} />:null }
                { tabIndex === 'lsv' ? <ShopVisitCard currentFormDetail={currentFormDetail} getCurrentFormDetail={getCurrentFormDetail} />:null }
                { tabIndex === 'disassembly' ? <DisassemblyFinding currentFormDetail={currentFormDetail} getCurrentFormDetail={getCurrentFormDetail} />:null }
                { tabIndex === 'llp-details' ? <LLPDetails currentFormDetail={currentFormDetail} getCurrentFormDetail={getCurrentFormDetail} />:null }
                { tabIndex === 'work-scope' ? <InspectionWorkScope currentFormDetail={currentFormDetail} getCurrentFormDetail={getCurrentFormDetail} />:null }
            </div>
             : <RestrictedAccess />
             :<InvalidUrl getResponseBack={()=>browserHistory.push(`/technical/project/view/${currentFormDetail?.project?.slug}`) }/>
     }
            { isLoading ? <PageLoader /> :null }
        </section>
    )
}
export default TableInspection;