import React, { useState, useEffect, useRef } from 'react';
import { DropzoneArea } from 'material-ui-dropzone';
import { Button, TextField, Grid, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress, Tooltip } from '@material-ui/core';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { globalGetService, globalPostService } from '../../../utils_v2/globalApiServices';
import { checkApiStatus, onCheckFormPermission } from '../../../utils_v2';
import { withRouter } from 'react-router';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useSnackbar } from 'notistack';
import { DeletePopUp, PageLoader } from '../../../shared_elements';
import { Skeleton } from '@material-ui/lab';
import { CloseOutlined } from '@material-ui/icons';

const LopaConfig = ({ params, is_lopa_details, getResponseBack, disablebtn, currentFormDetail }) => {
  const [formSubmitLoader, setFormSubmitLoader] = useState(false);
  const [modal, setModal] = useState(false);
  const [deletelopa, setDeleteLopa] = useState(false);
  const [viewImageModal, setViewImageModal] = useState(false);
  const [lopaConfigs, setLopaConfigs] = useState({ file: [], lopa_remarks: '' });
  const [isLoading, setLoading] = useState(false);
  const [imageWidth, setImageWidth] = useState(0);
  const imageRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (modal) {
      getLopaInfo('pageloader');
    }
  }, [modal]);

  const getLopaInfo = () => {
    setLoading(true);
    globalGetService(`technical/workorder/${params.workOrderSlug}/inspection-lopa-image/`)
      .then(response => {
        if (checkApiStatus(response)) {
          setLopaConfigs(response.data.data);
          setLoading(false);
        }
      });
  };

  const saveLopa = () => {
    setFormSubmitLoader(true);
    let formData = new FormData();
    formData.append('remarks', lopaConfigs.lopa_remarks || '');
    if (lopaConfigs.file && lopaConfigs.file.length) {
      formData.append('file', lopaConfigs.file[0]);
    }
    globalPostService(`technical/workorder/${params.workOrderSlug}/inspection-lopa-image/`, formData)
      .then(response => {
        if (checkApiStatus(response)) {
          setModal(false)
          enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          getResponseBack()
        } else {
          enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
        setFormSubmitLoader(false);
      })
  };

  const handleFieldChange = (files) => {
    if (files.length) {
      const file = files[0];
      const fileURL = URL.createObjectURL(file);
      setLopaConfigs({ ...lopaConfigs, file: [file], lopa_image_url: fileURL });
    }
  };

  const handleImageLoad = () => {
    if (imageRef.current) {
      setImageWidth(imageRef.current.offsetWidth);
    }
  };
  const onDeleteLopa =()=>{
    globalPostService(`technical/workorder/${params.workOrderSlug}/inspection-lopa-image/`, {remove_file: true})
    .then(response => {
      if (checkApiStatus(response)) {
        setDeleteLopa(false)
        enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        getLopaInfo()
      } else {
        enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      }
    })
  }

  return (
    <>
      <span onClick={() => setModal(disablebtn ? false : true)}>
        <Button
          style={{ color: !disablebtn ? 'white' : 'rgba(255,255,255,0.4)', cursor: disablebtn ? 'not-allowed' : '', border: !disablebtn ? 'groove white' : 'groove rgba(255,255,255,0.4)', fontSize: '12px' }}
          size='small'
          variant='outlined' >
          {is_lopa_details ? 'View' : 'Add'} LOPA
        </Button>
      </span>
      {modal && (
        <Dialog
          open={modal}
          className='project-management-modal'
          // onClose={() => setModal(false)}
          aria-labelledby="scroll-dialog-title"
        >
          <DialogTitle id="scroll-dialog-title">{is_lopa_details ? 'View' : 'Add'} LOPA</DialogTitle>
          <DialogContent dividers={true} style={{ width: '600px', height: '300px' }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                {isLoading ? <Skeleton style={{ marginBottom: '2px' }} variant="rect" width={'100%'} height={400} /> :
                  <div style={{ position: 'relative' }}>
                    {lopaConfigs.lopa_image_url &&
                      <div style={{ position: 'absolute', marginLeft: imageWidth ? imageWidth - 24 : '20px', zIndex: 1, cursor: 'pointer' }}>
                        <Tooltip placement='bottom-end' title='View Lopa Image'>
                          <VisibilityIcon onClick={() => setViewImageModal(true)} color='primary' />
                        </Tooltip>
                      </div>}
                    {lopaConfigs.lopa_image_url && lopaConfigs.lopa_image_url!==null ? (
                      <div>
                        <img
                          ref={imageRef}
                          onLoad={handleImageLoad}
                          style={{ maxWidth: '550px', maxHeight: '125px', cursor: 'unset' }}
                          src={lopaConfigs.lopa_image_url}
                          alt="LOPA"
                        />
                        <div style={{ margin: '10px 0' }}>
                          <div>
                            <input
                              accept="image/*"
                              id="contained-button-file"
                              type="file"
                              style={{ display: 'none' }}
                              onChange={(e) => handleFieldChange(e.target.files)}
                            />
                            {onCheckFormPermission(currentFormDetail) ?<>
                              <label htmlFor="contained-button-file">
                                <Button size="small" variant="outlined" color="primary" component="span">Change LOPA</Button>
                              </label> 
                              <label style={{marginLeft:'3px'}}>
                                <Button variant='contained' color='secondary' size='small' onClick={() => setDeleteLopa(true)}>Remove Image</Button>
                              </label></>: null}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <DropzoneArea
                          filesLimit={1}
                          maxFileSize={10000000}
                          showPreviews={false}
                          showPreviewsInDropzone={false}
                          acceptedFiles={['image/*']}
                          dropzoneClass="drag-drop-cnt"
                          showAlerts={['error', 'info']}
                          onDrop={handleFieldChange}
                          dropzoneText={<p style={{ fontSize: '13px' }}>Drag & Drop Image<br /> OR <br /> Click Here<br /> </p>}
                          alertSnackbarProps={{ anchorOrigin: { vertical: 'top', horizontal: 'right' } }}
                        />
                        <p><span style={{ background: '#ffe160', fontSize: '11px', padding: '2px 3px' }}>Format Support: .png, .jpg, .jpeg</span></p>
                      </div>
                    )}
                  </div>}
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  id="lopa_remarks"
                  label="Remarks"
                  fullWidth
                  multiline
                  rows={3}
                  disabled={!onCheckFormPermission(currentFormDetail)}
                  margin="normal"
                  variant='outlined'
                  value={lopaConfigs.lopa_remarks || ''}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setLopaConfigs({ ...lopaConfigs, lopa_remarks: e.target.value })}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button size='small' onClick={() => setModal(false)} variant="outlined" color="primary">Cancel</Button>
            {onCheckFormPermission(currentFormDetail) ?
              <Button size='small' disabled={formSubmitLoader} variant="contained" onClick={saveLopa} color="primary">
                {formSubmitLoader ? <CircularProgress color="inherit" size={24} /> : 'Save'}
              </Button> : null
            }
          </DialogActions>
        </Dialog>
      )}
      {viewImageModal && (
        <Dialog
          open={viewImageModal}
          // onClose={() => setViewImageModal(false)}
          fullWidth
          maxWidth=""
          aria-labelledby="image-view-dialog-title"
        >
          <DialogTitle id="image-view-dialog-title">LOPA Image
            <Tooltip title='Close'>
              <CloseOutlined style={{ float: 'right', cursor: 'pointer' }} onClick={() => setViewImageModal(false)} color="primary" />
            </Tooltip>
          </DialogTitle>
          <DialogContent>
            <img
              src={lopaConfigs.lopa_image_url}
              alt="LOPA"
              style={{ width: '100%', height: 'auto' }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setViewImageModal(false)} color="primary">Close</Button>
          </DialogActions>
        </Dialog>
      )}
      {deletelopa ?
        <DeletePopUp
          modal={deletelopa}
          toggleModalFn={() => setDeleteLopa(false)}
          title='Delete Lopa Image'
          content={<h4>Are you sure you want to delete?</h4>}
          deleteRecordFn={onDeleteLopa}
          confirmText={'Remove Image'}
        /> : null
      }
      {isLoading ? <PageLoader /> : null}
    </>
  );
};

export default withRouter(LopaConfig);
