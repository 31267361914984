import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { useSnackbar } from "notistack";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import {DropzoneArea} from 'material-ui-dropzone';
import {DeletePopUp,PageLoader} from '../../../shared_elements';
import { globalDeleteService, globalGetService, globalPostService ,globalPutService,globalExportService} from "../../../utils_v2/globalApiServices";
import { checkApiStatus, getLocalStorageInfo ,onCheckFormPermission,downloadFileType} from "../../../utils_v2";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
const Attachment = ({params,currentFormDetail,getCurrentFormDetail}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const [isLoading, setLoading] = useState(false);
    const [uploadAttachment, setUploadAttachment] = useState({modal:false, files:[]})
    const [attachments, setAttachments] = useState([]);
    const [deleteAttachment, setDeleteAttachment] = useState({modal:false, attachment:null});
    useEffect(() => {
         getAttachments({});  
    }, []);
    const getAttachments = () => {
        globalGetService(`technical/workorder/${params.workOrderSlug}/gap-report/${params.id}/?asset_type=${params.type}`)
        .then(response => {
            if(checkApiStatus(response)){
                setAttachments(response.data.data.files)
            }
        })
    }
    const onDeleteAttachment = () => {
        setLoading(true)
        globalDeleteService(`technical/workorder/${params.workOrderSlug}/gap-report/${params.id}/file/${deleteAttachment.attachment.id}/?asset_type=${params.type}`)
        .then(response => {
            setLoading(false)
            if(checkApiStatus(response)){
                enqueueSnackbar(response.data.message, { variant: "success", anchorOrigin: { horizontal: "right", vertical: "top" }});
                setDeleteAttachment({modal:false, attachment:null});
                setAttachments(attachments.filter((item) => item.id !== deleteAttachment.attachment.id))
                getCurrentFormDetail()
            }else{
                enqueueSnackbar(response.data.message, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "top" }});
            }
        })
    }
    const onUploadAttachment = () => {
        if(uploadAttachment.files.length === 0){
            enqueueSnackbar("Please upload Attachment ", { variant: "error", anchorOrigin: { horizontal: "right", vertical: "top" }});
        }else{
            setLoading(true)
            let formData = new FormData();
            for(let i=0; i<uploadAttachment.files.length;i++){
                formData.append("files", uploadAttachment.files[i])
            }
              globalPutService(`technical/workorder/${params.workOrderSlug}/gap-report/${params.id}/?asset_type=${params.type}`,formData)
            .then(response => {
                setLoading(false)
                if(checkApiStatus(response)){
                    enqueueSnackbar(response.data.message, { variant: "success", anchorOrigin: { horizontal: "right", vertical: "top" }});
                    setUploadAttachment({modal:false, files:[]});
                     setAttachments(response.data.data.files);
                     getCurrentFormDetail()
                }else{
                    enqueueSnackbar(response.data.message, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "top" }});
                }
            })
        }
        
    }

    const onDownloadAttachment = (item) =>{
        setLoading(true)
        globalExportService(`technical/workorder/${params.workOrderSlug}/gap-report/${params.id}/file-download/${item.id}/?asset_type=${params.type}`)
        .then(response => {
            setLoading(false)
            downloadFileType(response, item.name.split('.').slice(0, -1).join('.'), `.${item.name.split('.').pop()}`);
          })
    }

    return(
        <div className='task-management-attachments'>
            <ul className='list-inline attachment-blk'>
                {attachments.length > 0 &&  attachments.map((item) => {
                    const extension = item?.name?.split('.')?.pop()?.toLowerCase();
                    return(
                        <li className='attachment-list list-inline-item' style={{width:'150px'}}>
                            {['jpg', 'jpeg', 'png', 'gif'].includes(extension) ?
                                <div className='attachment'>
                                    <img style={{width:'150px', height:'120px', border:'groove 1px'}} src={decodeURIComponent(item.file)} alt="" />
                                </div>:
                                <div className='attachment' style={{width:'150px', height:'120px', border:'groove 1px'}}>
                                    <span className='extension'>{extension?.toUpperCase()}</span>
                                </div>
                            }
                            <div className='file-info '>
                                <span>{item?.name?.length > 15 ? item?.name?.slice(0,15)+'...' : item?.name?.length}</span>
                                <ul className='list-inline flex-centered'>
                                    <li className='list-inline-item'>
                                        <SaveAltIcon onClick={() => onDownloadAttachment(item)} download={item.name}  color='primary' fontSize='small' style={{cursor:'pointer'}} />
                                    </li>
                                    {onCheckFormPermission(currentFormDetail) ? item.created_by &&  item.created_by.id ?
                                        getLocalStorageInfo().user.id === item.created_by.id ?
                                        <li className='list-inline-item'>
                                            <DeleteOutlineIcon onClick={() => setDeleteAttachment({modal:true, attachment:item})} color='secondary' fontSize='small' style={{cursor:'pointer'}} />
                                        </li>:null:
                                        <li className='list-inline-item'>
                                            <DeleteOutlineIcon onClick={() => setDeleteAttachment({modal:true, attachment:item})} color='secondary' fontSize='small' style={{cursor:'pointer'}} />
                                        </li>
                                   : null }
                                </ul>
                            </div>
                        </li>
                    )
                })}
            </ul>
            {onCheckFormPermission(currentFormDetail) ?
             <div>
                <Button onClick={() => setUploadAttachment({modal:true, files:[]})} color='primary' variant='outlined' size='small'>Add Attachment</Button>
            </div>  : null}
            { deleteAttachment.modal ? (
                <DeletePopUp
                    modal={deleteAttachment.modal}
                    toggleModalFn={() => setDeleteAttachment({modal:false, attachment:null})}
                    title="Delete Attachment"
                    content={<h4>Are you sure you want to delete?</h4>}
                    deleteRecordFn={() => onDeleteAttachment()}
                    confirmText="Delete"
                />
            ) : null}  
            { uploadAttachment.modal ?
                <Dialog
                    open={uploadAttachment.modal}
                    onClose={() => setUploadAttachment({modal:false, files:[]})}
                >
                    <DialogTitle id="scroll-dialog-title">
                        Upload Attachment(s)
                    </DialogTitle>
                    <DialogContent dividers={true}>
                        <div>
                            <DropzoneArea
                                filesLimit={10}
                                maxFileSize={131072000}
                                showPreviewsInDropzone={false}
                                useChipsForPreview={true}
                                showPreviews={ true }
                                dropzoneText={<p>Drag & Drop Documents<br/> OR <br/> Click Here<br/> </p>}
                                dropzoneClass="drag-drop-cnt"
                                maxWidth="sm"
                                showAlerts={['info', 'error']}
                                alertSnackbarProps={{anchorOrigin: {vertical:'top', horizontal:'right'}}}
                                clearOnUnmount={true}
                                onChange={(files) => setUploadAttachment({...uploadAttachment, files:files})}
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={isLoading} onClick={() => setUploadAttachment({modal:false, files:[]})} color="primary" variant='outlined' size='small'>Cancel</Button>
                        <Button disabled={isLoading} onClick={onUploadAttachment} color="primary" variant='contained' size='small'>
                            {isLoading ? <CircularProgress size={20} /> : 'Upload'}
                        </Button>
                    </DialogActions>
                </Dialog>:null
            }
            {isLoading ? <PageLoader />:null}
        </div>
    )
}
export default withRouter(Attachment);