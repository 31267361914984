import React, {Component} from 'react';
import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap';
import classnames from 'classnames';
import { Link } from 'react-router';
import { imgStoragePath } from '../../../../../constants';
import { TextInputField, GetInputField } from'../../../Elements';
export default class TechSpecSidebar extends Component{
  constructor(props){
  	super(props);
  	this.state = {
  		activeTab: 0,
  		techSpecObj: {}
  	}
  }

	componentDidMount(){
   	const	dataObj = Object.assign({}, this.props.techSpecObj);
		this.setState({
			techSpecObj: dataObj.data
		})
	}
	toggle(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab
			});
		}
	}
	toggleTab(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeMainTab: tab,
				activeTab: 0
			});
		}
	}
	updateForm = (key,value, index, specIndex, label) => {
		this.setState(prevState => ({
			...prevState,
			techSpecObj: [...prevState.techSpecObj].map((data, dataIndex) => dataIndex == index ? {
				...data,
				seat_specs: data.seat_specs.map((specData, specDataIndex) =>  specIndex == specDataIndex ?  {
					...specData,
					answers :  {
						...specData.answers,
						[key] : value
					}
				} : specData)
			}	: data
			)
		}));
	}
	updateTechSpecFn = (e) => {
		e.preventDefault();
		this.props.updateTechSpecFn(this.state.techSpecObj);
	}
	render(){
		const { techSpecObj } = this.state;
    const { formInfo, userInfo } = this.props;
		const headerHeight = document.body.contains(document.getElementById('sidebar-header')) ? (document.getElementById('sidebar-header').clientHeight - 5) : 0 ;
		if(!techSpecObj.length){
			return false
		}
		return(
      <div className="tech-spec-sidebar" style={{overFlow: '100%'}} >
		    <form className="tech-spec-sidebar" onSubmit={(e) => this.updateTechSpecFn(e)}>
          <div className="sidebar-form" style={{ height: '80vh'}}>
            <div style={{ overflow: 'hidden'}}>
              <Nav tabs className="commons-tabs" style={{ overflow: 'auto', paddingBottom: '15px'}}>
                <div className="tab-heading-inner-wrap" style={{ overflow: 'hidden', width:'170%'}}>
                  { techSpecObj.map((item, index) =>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeTab === index })}
                        onClick={() => { this.toggle(index); }}
                      >
                        {item.name}
                      </NavLink>
                    </NavItem>
			            )}
		            </div>
              </Nav>
		         <TabContent activeTab={this.state.activeTab}>
              { techSpecObj.map((item, index) =>
                <TabPane tabId={index}>
                  <div>
                    { item.seat_specs.map((seatSpec, specIndex) =>
                      <div className="tech-config-wrapper">
                        <h4>{seatSpec.name}</h4>
                        <Row className="specs" style={{marginBottom: '20px'}}>
                          <Col md="4">
                            {
      												GetInputField(
      													'string',
      													<label className="for-label">Part Number </label>,
      													{ type: formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save ? 'edit':'view',
      														value: seatSpec.answers && seatSpec.answers.part_number ? seatSpec.answers.part_number : '',
      														error: '',
      														updateForm: (value) => this.updateForm('part_number', value, index,  specIndex)
      													}
      												)
      											}
                          </Col>
                          <Col md="4">
                            {
      												GetInputField(
      													'string',
      													<label className="for-label">Serial Number</label>,
      													{ type: formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save ? 'edit':'view',
                                  value: seatSpec.answers && seatSpec.answers.serial_number ? seatSpec.answers.serial_number : '',
      													  error: '',
      														updateForm: (value) => this.updateForm('serial_number', value, index, specIndex)
      													}
      												)
      											}
                          </Col>
                          <Col md="4">
                            {
        											GetInputField(
        												'dropdown',
        												<label className="for-label">Manufacturer </label>,
        												{ type: formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save ? 'edit':'view',
        												value: seatSpec.answers && seatSpec.answers.manufacturer ? seatSpec.answers.manufacturer : '',
        												error: '',
        												labelKey:'name',
        												valueKey: 'id',
        												options: this.props.sManufacruerList,
        													updateForm: (value) => this.updateForm('manufacturer', value, index, specIndex)
        												}
        											)
        										}
                          </Col>
                        </Row>
                      </div>
                    )
                  }
                </div>
              </TabPane>
            )}
          </TabContent>
        </div>
        <div className="edit-sidebar-footer">
          <ul className="list-inline">
			  {
				  formInfo?.current_form?.permissions?.can_save ?
				  <li className="list-inline-item">
              <input  disabled={this.props.paxSeatingLoader}
                className="primary-btn" type="submit"
                value={this.props.paxSeatingLoader ?  "Processing..." : "Save" }
				/>
            </li>:
			null
			}
            <li className="list-inline-item">
              <Link onClick={this.props.toggleEditSideBar}> Cancel</Link>
            </li>
          </ul>
        </div>
				</div>
		 	</form>
			</div>
		)
	}
}
