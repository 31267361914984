import React, { Component } from 'react';
import { connect } from 'react-redux';
import ToastList from '../components/ToastList';
import { S_CLOSE_TOAST_NOTIFY } from '../actions';
class ToastNotify extends Component {
  constructor(props){
    super(props);
  }
  returnMessageClass = () => {
    if(this.props.toastLists){
      return ["MessageWrapper", "open"].join(' ')
    }else{
      return ["MessageWrapper", "close"].join(' ')
    }
  }
  render() {
    const { toastLists } = this.props;
    return(
      <div className={ this.returnMessageClass() }>
         { toastLists.map((toast, index) => <ToastList toast={toast} key={index} clearToast = {() => this.props.clearToast(index)} /> ) }
      </div>
    )
  }
}
const mapStateToProps = state => ({
  toastLists: state.sharedReducers.toastLists,
});

const mapDispatchToProps = (dispatch) => {
  return {
    clearToast : (item) => dispatch({
      type: S_CLOSE_TOAST_NOTIFY,
      payload: (item+1),
    })
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ToastNotify);
