import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { useSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, CircularProgress } from '@material-ui/core';
import { checkApiStatus } from '../../../utils_v2';
import { globalPutService, globalPostService } from '../../../utils_v2/globalApiServices';
import { fieldValidation } from '../../../utils_v2/formValidation';
import { capitalizeFirstLetter } from '../../../utils_v2';
import { errorCode, contactObj  } from '../';
import { regexConstants } from '../../../constants/regEx';
import { trackActivity } from '../../../utils/mixpanel';

const ContactCRU = ({params, addEditContact, toggleModalFn, getResponseBack}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isLoading, setLoading] = useState(false)
  const [contact, setContact] = useState(addEditContact.contact?addEditContact.contact:contactObj);
  const [error, setError] = useState({});
  const onFieldChange = (e, keyParam, value) => {
    setContact({
      ...contact,
      [keyParam]: value
    });
  }
  const resetErrorKey = (key) => {
    setError({
      ...error,
      [key]: ''
    });
  }
  const onAddEditContact = () => {
    let validationInputs={
      full_name:errorCode['full_name'][fieldValidation({...errorCode['full_nameObj'], fieldval: contact.full_name})],
      email:errorCode['email'][fieldValidation({...errorCode['emailObj'], fieldval: contact.email})],
      title:errorCode['title'][fieldValidation({...errorCode['titleObj'], fieldval: contact.title})],
      phone_number:errorCode['phone_number'][fieldValidation({...errorCode['phone_numberObj'], fieldval: contact.phone_number})],
      fax:errorCode['fax'][fieldValidation({...errorCode['faxObj'], fieldval: contact.fax})],
    }
    if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
      setLoading(true);
      if(addEditContact.contact){
        globalPutService(`technical/workorder/${params.workOrderSlug}/contacts/${contact.id}/`, contact)
        .then(response => {
          setLoading(false);
          if(checkApiStatus(response)){
            enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
            toggleModalFn(); getResponseBack();
            trackActivity('Project Management', {
              event_type: 'Contact Update Success',
              page_title: 'T006 Form',
              workOrder_slug: params.workOrderSlug ? params.workOrderSlug : '',
              contact_id: contact.id ? contact.id : '',
              request_body: contact ? contact : {},
              success_msg: response.data.message
          });
          }else{
            enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
            trackActivity('Project Management', {
              event_type: 'Contact Update Failed',
              page_title: 'T006 Form',
              workOrder_slug: params.workOrderSlug ? params.workOrderSlug : '',
              contact_id: contact.id ? contact.id : '',
              request_body: contact ? contact : {},
              error_source: 'Backend',
              error_msg: response.data.message
          });
          }
        })
      }else{
        globalPostService(`technical/workorder/${params.workOrderSlug}/contacts/`, contact)
        .then(response => {
          setLoading(false);
          if(checkApiStatus(response)){
            enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
            toggleModalFn(); getResponseBack();
            trackActivity('Project Management', {
              event_type: 'Contact Add Success',
              page_title: 'T006 Form',
              workOrder_slug: params.workOrderSlug ? params.workOrderSlug : '',
              request_body: contact ? contact : {},
              success_msg: response.data.message
          });
          }else{
            enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
            trackActivity('Project Management', {
              event_type: 'Contact Add Failed',
              page_title: 'T006 Form',
              workOrder_slug: params.workOrderSlug ? params.workOrderSlug : '',
              request_body: contact ? contact : {},
              error_source: 'Backend',
              error_msg: response.data.message
          });
          }
        })
      }
    }else{
      setError(validationInputs);
      trackActivity('Project Management', {
        event_type: 'Contact Add/Edit Failed',
        page_title: 'T006 Form',
        workOrder_slug: params.workOrderSlug ? params.workOrderSlug : '',
        request_body: contact ? contact : {},
        error_source: 'Frontend',
        error_details: validationInputs ? validationInputs : ''
    });
    }
  }
  return(
    <Dialog
      open={addEditContact.modal}
      onClose={toggleModalFn}
      className='project-management-modal'
    >
      <DialogTitle id="scroll-dialog-title">
        {capitalizeFirstLetter(addEditContact.mode)} Contact
      </DialogTitle>
      <DialogContent dividers={true}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextField
              required
              id="full_name"
              label="Name"
              fullWidth
              margin="normal"
              inputProps={{maxLength: 30}}
              value={contact.full_name ? contact.full_name:''}
              onChange={(e, value) => onFieldChange(e, 'full_name', e.target.value)}
              error={error.full_name ? true:false }
              helperText={error.full_name ? error.full_name:''}
              InputLabelProps={{shrink: true}}
              onFocus={(e) => resetErrorKey('full_name')}
              variant='outlined'
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              id="email"
              label="Email"
              fullWidth
              margin="normal"
              value={contact.email ? contact.email:''}
              onChange={(e, value) => onFieldChange(e, 'email', e.target.value)}
              error={error.email ? true:false }
              helperText={error.email ? error.email:''}
              InputLabelProps={{shrink: true}}
              onFocus={(e) => resetErrorKey('email')}
              variant='outlined'
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="title"
              label="Designation"
              fullWidth
              margin="normal"
              inputProps={{maxLength: 30}}
              value={contact.title ? contact.title:''}
              onChange={(e, value) => onFieldChange(e, 'title', e.target.value)}
              error={error.title ? true:false }
              helperText={error.title ? error.title:''}
              InputLabelProps={{shrink: true}}
              onFocus={(e) => resetErrorKey('title')}
              variant='outlined'
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="phone_number"
              label="Phone Number"
              fullWidth
              margin="normal"
              value={contact.phone_number ? contact.phone_number:''}
              onChange={(e) => {const phoneNumber = e.target.value;if ( phoneNumber === '' || regexConstants.numberWithSpecChar.test(phoneNumber)) {onFieldChange(e, 'phone_number', phoneNumber);}}}
              error={error.phone_number ? true:false }
              helperText={error.phone_number ? error.phone_number:''}
              InputLabelProps={{shrink: true}}
              onFocus={(e) => resetErrorKey('phone_number')}
              variant='outlined'
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="fax"
              label="Fax"
              fullWidth
              margin="normal"
              value={contact.fax ? contact.fax:''}
              onChange={(e, value) => onFieldChange(e, 'fax', e.target.value)}
              error={error.fax ? true:false }
              helperText={error.fax ? error.fax:''}
              InputLabelProps={{shrink: true}}
              onFocus={(e) => resetErrorKey('fax')}
              variant='outlined'
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} onClick={toggleModalFn} color="primary" size="small">Cancel</Button>
        <Button disabled={isLoading} onClick={onAddEditContact} variant="contained" color="primary" size="small">
          {isLoading ? <CircularProgress size={20} /> : 'SAVE' }
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withRouter(ContactCRU);