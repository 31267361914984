import React, { Fragment, useState } from 'react';
import { TableRow, TableCell, Tooltip } from '@material-ui/core';
import moment from 'moment';
import { Link } from 'react-router';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import { DeleteOutlined } from '@material-ui/icons';
import { RevisionHistory } from '../../../shared_elements'
import { displayDateFormatShort } from '../../../constants';
import { onCheckPermission } from '../../../utils_v2';
const FormTemplate = ({index, item, onDelete, onEdit}) => {
  const [open, setOpen] = useState(false);
  return(
    <TableRow hover tabIndex={-1}>
      <TableCell>#{item.id}</TableCell>
      <TableCell>{item.name}</TableCell>
      <TableCell>{item.type}</TableCell>
      <TableCell>{item.description?item.description:'--'}</TableCell>
      <TableCell>
        <a href={item.file_path} target='_blank'>{item.file_name}</a>
      </TableCell>
      <TableCell>{item.created_by && item.created_by.name?item.created_by.name:'--'}</TableCell>
      <TableCell>{item.created_at ? moment(item.created_at).format(displayDateFormatShort):'--'}</TableCell>
      <TableCell className='actions-cell'>
        <RevisionHistory buttonType={true} url='audit/technical/tctemplate/' queryParams={{action:1,object_id:item.id}} />
        { onCheckPermission('project_management','template','U') ?
          <Tooltip title='Edit' arrow>
            <EditIcon onClick={onEdit} color='primary' fontSize='small' />
          </Tooltip>:null
        }
        {onCheckPermission('project_management','template','D') ?
          <Tooltip title='Delete' arrow>
            <DeleteOutlined onClick={onDelete} color="secondary" fontSize='small' />
          </Tooltip>:null
        }
      </TableCell>
    </TableRow>
  )
}
export default FormTemplate;
