import React, { Component } from 'react';
import { Link } from 'react-router';
import { Row, Col } from 'reactstrap';
import {  GetInputField } from'../../Elements';
import { checkString, checkValidDate, checkIntNumber,checkEmail, checkNumber, checkDateDiff,checkFloat } from '../../../../formValidator';
import PlacesAutocomplete from 'react-places-autocomplete';
import { imgStoragePath } from '../../../../constants';
import { getLocalStorageInfo } from '../../../../utils'
import { geocodeByAddress, geocodeByPlaceId, getLatLng } from 'react-places-autocomplete';
import moment from 'moment'
class AddEditWorkOrder extends Component{
	constructor(props){
    super(props);
    this.state = {
			workorder:{},
      error: {},
      create_msn: false
		};
	}
	componentDidMount(){

    let data = Object.assign({}, this.props.workorder.data);

    if(this.props.workorder.type == 'add'){
      data = {
        ...data,
        start_date: this.props.projectViewData.start_date,
        expected_end_date : this.props.projectViewData.expected_end_date,
        no_of_galleys: { 'fwd': 1, 'mid': '0', 'aft': 1},
        no_of_lavatories: { 'fwd': 1, 'mid': '0', 'aft': 1},
        no_of_gears: 3,

      }
    }
    this.setState({
      workorder:data
    });
	}

	updateForm = (key,value) => {

    this.setState(prevState => ({
      ...prevState,
      workorder:{
        ...prevState.workorder,
        [key]:value
      },
      error: {
        ...prevState.error,
        [key]: ''
      }
    }));

    if(key == 'aircraft_id' && value && !this.state.create_msn){
      this.props.fetchMsnDetails(value, 'aircraft');
    }
    if(key == 'inspection_types' && value){
      this.props.fetchForms({'inspection_type': value[0]});
    }
    if(key == 'aircraft_type_id' && this.state.create_msn){
      let aircraftTypeobj = this.props.aircraftTypeList.filter(data => data.id == value);

      this.setState(prevState => ({
        ...prevState,
        workorder:{
          ...prevState.workorder,
          [key]:value,
          blueprint_id: aircraftTypeobj && aircraftTypeobj[0] ? aircraftTypeobj[0]['blueprint_id'] : '',
          manufacturer_id:  aircraftTypeobj && aircraftTypeobj[0] ? aircraftTypeobj[0]['manufacturer_id'] : ''
        },
        error: {
          ...prevState.error,
          [key]: ''
        }
      }));
    }
  }
  updatejsonFormat = (key, value, type) =>{
    this.setState(prevState => ({
      ...prevState,
      workorder: {
        ...prevState.workorder,
        [type]: {
          ...prevState.workorder[type],
          [key] : value
        }
      }
    }));
  }
  handleChange = address => {
    this.setState(prevState => ({
      ...prevState,
      workorder: {
        ...prevState.workorder,
        location : address
      }
    }));
	};

	handleSelect = address => {
    let location =  geocodeByAddress(address);
    location.then(results =>
      getLatLng(results[0]))
      .then(latLng =>
        this.setState(prevState => ({
          ...prevState,
          workorder: {
            ...prevState.workorder,
            location : address,
            lat: latLng['lat'],
            lang: latLng['lng'],
          }
      })))
      .catch(error => console.error('Error', error));
      location.then(results =>
        results[0]
        ).then(country => {
          let place = country['address_components'].filter((data, index) =>   data['types'][0] == 'country'  );

          this.setState(prevState => ({
            ...prevState,
            workorder: {
              ...prevState.workorder,
                country: place.length ?  place[0]['long_name']: address
              }
            }))
           })
      .catch(error => console.error('Error', error));
  };

  componentWillReceiveProps(nextProps){
    let data = this.state.workorder;
    if(!nextProps.projectLoader){
      this.setState(prevState => ({
        ...prevState,
        workorder: {
          aircraft_id: nextProps.msnDetails &&  nextProps.msnDetails.slug && !this.state.create_msn  ? nextProps.msnDetails.aircraft_id : this.state.workorder.aircraft_id,
          aircraft_type_id: nextProps.msnDetails &&  nextProps.msnDetails.aircraft_type_id  ? nextProps.msnDetails.aircraft_type_id : this.state.workorder.aircraft_type_id,
          date_of_manufacture: nextProps.msnDetails &&  nextProps.msnDetails.date_of_manufacture  ? nextProps.msnDetails.date_of_manufacture : this.state.workorder.date_of_manufacture,
          manufacturer_id: nextProps.msnDetails &&  nextProps.msnDetails.manufacturer_id  ? nextProps.msnDetails.manufacturer_id : this.state.workorder.manufacturer_id,
          blueprint_id: nextProps.msnDetails &&  nextProps.msnDetails.blueprint_id  ? nextProps.msnDetails.blueprint_id : this.state.workorder.blueprint_id,
          no_of_gears: nextProps.msnDetails &&  nextProps.msnDetails.no_of_gears ? nextProps.msnDetails.no_of_gears :  this.state.workorder.no_of_gears,
          registration: nextProps.msnDetails && nextProps.msnDetails.current_registration_number ? nextProps.msnDetails.current_registration_number : '',
          lease_start_date : nextProps.msnDetails && nextProps.msnDetails.lease_start_date ? nextProps.msnDetails.lease_start_date : this.state.workorder.lease_start_date,
          tsn : nextProps.msnDetails && nextProps.msnDetails.tsn ? nextProps.msnDetails.tsn : this.state.workorder.tsn,
          csn : nextProps.msnDetails && nextProps.msnDetails.csn ? nextProps.msnDetails.csn : this.state.workorder.csn,
          status_as_of : nextProps.msnDetails && nextProps.msnDetails.status_as_of ? nextProps.msnDetails.status_as_of : this.state.workorder.status_as_of,
          operator_storage_facility : nextProps.msnDetails && nextProps.msnDetails.operator_storage_facility ? nextProps.msnDetails.operator_storage_facility : this.state.workorder.operator_storage_facility,
          aoc_regulation : nextProps.msnDetails && nextProps.msnDetails.aoc_regulation ? nextProps.msnDetails.aoc_regulation : '',
          line_number : nextProps.msnDetails && nextProps.msnDetails.line_number ? nextProps.msnDetails.line_number : '',
          forms:  nextProps.updatedForm && nextProps.updatedForm.data ? nextProps.updatedForm.data.map(data => data.id) : this.state.workorder.forms,
          inspection_types:  nextProps.updatedForm && nextProps.updatedForm.inspection_type ? nextProps.updatedForm.inspection_type  : this.state.workorder.inspection_types,
          start_date: this.state.workorder.start_date ? this.state.workorder.start_date : '',
          expected_end_date: this.state.workorder.expected_end_date ? this.state.workorder.expected_end_date : '',
          location: this.state.workorder.location ? this.state.workorder.location : '',
          slug: this.state.workorder.slug ? this.state.workorder.slug : '',
          physical_inspection_on_start_date:this.state.workorder.physical_inspection_on_start_date ? this.state.workorder.physical_inspection_on_start_date : null,
          physical_inspection_on_end_date:this.state.workorder.physical_inspection_on_end_date ? this.state.workorder.physical_inspection_on_end_date : null,
          records_inspection_on_end_date:this.state.workorder.records_inspection_on_end_date?this.state.workorder.records_inspection_on_end_date:null,
          records_inspection_on_start_date:this.state.workorder.records_inspection_on_start_date?this.state.workorder.records_inspection_on_start_date:null,
          no_of_galleys: nextProps.msnDetails.no_of_galleys ? nextProps.msnDetails.no_of_galleys : this.state.workorder.no_of_galleys,
          no_of_lavatories:  nextProps.msnDetails.no_of_lavatories  ? nextProps.msnDetails.no_of_lavatories : this.state.workorder.no_of_lavatories,
          country:nextProps.msnDetails.country ? nextProps.msnDetails.country : this.state.workorder.country
        }
      }));
    }
  }

  workorderUpdate = (e) => {
    e.preventDefault();
    let data = this.state.workorder;
    const { create_msn } = this.state

    data = {
      ...data,
      create_msn: this.state.create_msn
    }
    let validateNewInput = {
      aircraft_id: checkString({value: this.state.workorder.aircraft_id, required: true, minLength: '', maxLength: '', message: this.state.create_msn ? 'Please enter MSN' : 'Please select MSN'}),
      start_date: checkValidDate({value: this.state.workorder.start_date, required: true, minLength: '', maxLength: '', message: 'Please enter Start Date'}),
		  expected_end_date: checkValidDate({value: this.state.workorder.expected_end_date, required: true, minLength: '', maxLength: '', message: 'Please enter Expected End date'}),
      expected_end_date:checkDateDiff({value:this.state.workorder.start_date,required:true},{value:this.state.workorder.expected_end_date,required:true}),
      blueprint_id: checkString({value: this.state.workorder.blueprint_id, required: false, minLength: '', maxLength: '', message: 'Please select Blueprint'}),
      aircraft_type_id: checkString({value: this.state.workorder.aircraft_type_id, required: true, minLength: '', maxLength: '', message: 'Please select Aircraft Model'}),
      tsn: checkFloat({value: this.state.workorder.tsn, required: false, minLength: '', maxLength: '', message: 'Please enter valid tsn'}),
      csn: checkIntNumber({value: this.state.workorder.csn, required: false, minLength: '', maxLength: '', message: 'Please enter valid csn'}),
      country: create_msn ?   checkString({value: this.state.workorder.country, required: true, minLength: '', maxLength: '', message: 'Please enter Country'}) : '' ,
      date_of_manufacture: checkValidDate({value: this.state.workorder.date_of_manufacture, required: true, minLength: '', maxLength: '', message: 'Please enter Date of Manufacture'}),
    };

		if([8,9].indexOf(this.state.workorder.blueprint_id) == -1){
			validateNewInput = {
				...validateNewInput,
				no_of_lavatories : checkIntNumber({value: this.state.workorder.no_of_lavatories && this.state.workorder.no_of_lavatories.fwd ? this.state.workorder.no_of_lavatories.fwd : '' , required: '',  minLength: '', maxLength: '', message: 'Please enter valid Lavatories '}),
	      no_of_lavatories : checkIntNumber({value: this.state.workorder.no_of_lavatories && this.state.workorder.no_of_lavatories.mid ? this.state.workorder.no_of_lavatories.mid : '' , required: '',  minLength: '', maxLength: '', message: 'Please enter valid Lavatories '}),
	      no_of_lavatories : checkIntNumber({value: this.state.workorder.no_of_lavatories && this.state.workorder.no_of_lavatories.aft ? this.state.workorder.no_of_lavatories.aft : '' , required: '',  minLength: '', maxLength: '', message: 'Please enter valid Lavatories '}),
	      no_of_galleys : checkIntNumber({value: this.state.workorder.no_of_galleys && this.state.workorder.no_of_galleys.fwd ? this.state.workorder.no_of_galleys.fwd : '' , required: '',  minLength: '', maxLength: '', message: 'Please enter valid Galleys '}),
	      no_of_galleys : checkIntNumber({value: this.state.workorder.no_of_galleys && this.state.workorder.no_of_galleys.mid ? this.state.workorder.no_of_galleys.mid : '' , required: '',  minLength: '', maxLength: '', message: 'Please enter valid Galleys '}),
	      no_of_galleys : checkIntNumber({value: this.state.workorder.no_of_galleys && this.state.workorder.no_of_galleys.aft ? this.state.workorder.no_of_galleys.aft : '' , required: '',  minLength: '', maxLength: '', message: 'Please enter valid Galleys '}),
			}
			if(this.state.workorder.no_of_lavatories && (this.state.workorder.no_of_lavatories.fwd > 9 || this.state.workorder.no_of_lavatories.mid > 9 ||  this.state.workorder.no_of_lavatories.aft > 9)){
	      validateNewInput = {
	        ...validateNewInput,
	        no_of_lavatories: 'Lavatories should varry between 0 to 9'
	      }
	    }

	    if(this.state.workorder.no_of_galleys && (this.state.workorder.no_of_galleys.fwd > 9 || this.state.workorder.no_of_galleys.mid > 9 ||  this.state.workorder.no_of_galleys.aft > 9)){
	      validateNewInput = {
	        ...validateNewInput,
	        no_of_galleys: 'Galleys should varry between 0 to 9'
	      }
	    }
		}else{
			validateNewInput = {
				...validateNewInput,
				no_of_lavatories : checkIntNumber({value: this.state.workorder.no_of_lavatories && this.state.workorder.no_of_lavatories.up_fwd ? this.state.workorder.no_of_lavatories.up_fwd : '' , required: '',  minLength: '', maxLength: '', message: 'Please enter valid Lavatories '}),
	      no_of_lavatories : checkIntNumber({value: this.state.workorder.no_of_lavatories && this.state.workorder.no_of_lavatories.up_mid ? this.state.workorder.no_of_lavatories.up_mid : '' , required: '',  minLength: '', maxLength: '', message: 'Please enter valid Lavatories '}),
	      no_of_lavatories : checkIntNumber({value: this.state.workorder.no_of_lavatories && this.state.workorder.no_of_lavatories.up_aft ? this.state.workorder.no_of_lavatories.up_aft : '' , required: '',  minLength: '', maxLength: '', message: 'Please enter valid Lavatories '}),
				no_of_lavatories : checkIntNumber({value: this.state.workorder.no_of_lavatories && this.state.workorder.no_of_lavatories.fwd ? this.state.workorder.no_of_lavatories.fwd : '' , required: '',  minLength: '', maxLength: '', message: 'Please enter valid Lavatories '}),
	      no_of_lavatories : checkIntNumber({value: this.state.workorder.no_of_lavatories && this.state.workorder.no_of_lavatories.mid ? this.state.workorder.no_of_lavatories.mid : '' , required: '',  minLength: '', maxLength: '', message: 'Please enter valid Lavatories '}),
	      no_of_lavatories : checkIntNumber({value: this.state.workorder.no_of_lavatories && this.state.workorder.no_of_lavatories.aft ? this.state.workorder.no_of_lavatories.aft : '' , required: '',  minLength: '', maxLength: '', message: 'Please enter valid Lavatories '}),
	      no_of_galleys : checkIntNumber({value: this.state.workorder.no_of_galleys && this.state.workorder.no_of_galleys.up_fwd ? this.state.workorder.no_of_galleys.up_fwd : '' , required: '',  minLength: '', maxLength: '', message: 'Please enter valid Galleys '}),
	      no_of_galleys : checkIntNumber({value: this.state.workorder.no_of_galleys && this.state.workorder.no_of_galleys.up_mid ? this.state.workorder.no_of_galleys.up_mid : '' , required: '',  minLength: '', maxLength: '', message: 'Please enter valid Galleys '}),
	      no_of_galleys : checkIntNumber({value: this.state.workorder.no_of_galleys && this.state.workorder.no_of_galleys.up_aft ? this.state.workorder.no_of_galleys.up_aft : '' , required: '',  minLength: '', maxLength: '', message: 'Please enter valid Galleys '}),
	      no_of_galleys : checkIntNumber({value: this.state.workorder.no_of_galleys && this.state.workorder.no_of_galleys.fwd ? this.state.workorder.no_of_galleys.fwd : '' , required: '',  minLength: '', maxLength: '', message: 'Please enter valid Galleys '}),
	      no_of_galleys : checkIntNumber({value: this.state.workorder.no_of_galleys && this.state.workorder.no_of_galleys.mid ? this.state.workorder.no_of_galleys.mid : '' , required: '',  minLength: '', maxLength: '', message: 'Please enter valid Galleys '}),
	      no_of_galleys : checkIntNumber({value: this.state.workorder.no_of_galleys && this.state.workorder.no_of_galleys.aft ? this.state.workorder.no_of_galleys.aft : '' , required: '',  minLength: '', maxLength: '', message: 'Please enter valid Galleys '}),
			}
			if(this.state.workorder.no_of_lavatories && (this.state.workorder.no_of_lavatories.up_fwd > 9 || this.state.workorder.no_of_lavatories.up_mid > 9 ||  this.state.workorder.no_of_lavatories.up_aft > 9 || this.state.workorder.no_of_lavatories.fwd > 9 || this.state.workorder.no_of_lavatories.mid > 9 ||  this.state.workorder.no_of_lavatories.aft > 9)){
	      validateNewInput = {
	        ...validateNewInput,
	        no_of_lavatories: 'Lavatories should varry between 0 to 5'
	      }
	    }

	    if(this.state.workorder.no_of_galleys && (this.state.workorder.no_of_galleys.up_fwd > 9 || this.state.workorder.no_of_galleys.up_mid > 9 ||  this.state.workorder.no_of_galleys.up_aft > 9 || this.state.workorder.no_of_galleys.fwd > 9 || this.state.workorder.no_of_galleys.mid > 9 ||  this.state.workorder.no_of_galleys.aft > 9)){
	      validateNewInput = {
	        ...validateNewInput,
	        no_of_galleys: 'Galleys should varry between 0 to 5'
	      }
	    }
		}

    if(this.state.workorder.no_of_gears && (this.state.workorder.no_of_gears > 5 || this.state.workorder.no_of_gears < 3 )){
      validateNewInput = {
        ...validateNewInput,
        no_of_gears: 'Landing Gears should varry between 3 to 5'
      }
    }
		if(this.state.workorder.records_inspection_on_end_date && this.state.workorder.records_inspection_on_end_date.trim() != '' && parseInt(moment(this.state.workorder.records_inspection_on_end_date).diff(moment(this.state.workorder.records_inspection_on_start_date), 'days')) < 0){
			validateNewInput = {
				...validateNewInput,
				records_inspection_on_end_date: 'Record Inspection end date can not be before Record Inspection start date'
			}
		}
		if(this.state.workorder.physical_inspection_on_end_date && this.state.workorder.physical_inspection_on_end_date.trim() != '' && parseInt(moment(this.state.workorder.physical_inspection_on_end_date).diff(moment(this.state.workorder.physical_inspection_on_start_date), 'days')) < 0){
			validateNewInput = {
				...validateNewInput,
				physical_inspection_on_end_date: 'Physical Inspection end date can not be before Physical Inspection start date'
			}
		}
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {

      this.props.workOrderAddEdit(data, this.props.workorder.type);

    }else{
      this.setState({
        error: validateNewInput
      });
    }
  }

  createMsn = (flag) => {
    this.setState(prevState => ({
      ...prevState,
      create_msn: flag
    }))
    if(flag){
      this.setState(prevState => ({
        ...prevState,
        workorder: {
          ...prevState.workorder,
         start_date: this.props.projectViewData.start_date,
         expected_end_date : this.props.projectViewData.expected_end_date,
         no_of_galleys: { 'fwd': 1, 'mid': '0', 'aft': 1},
         no_of_lavatories: { 'fwd': 1, 'mid': '0', 'aft': 1},
         no_of_gears: 3,
        },
        error: {}
      }))

    }else{
      this.setState(prevState => ({
        ...prevState,
        workorder: {
          ...prevState.workorder,
         start_date: this.props.projectViewData.start_date,
         expected_end_date : this.props.projectViewData.expected_end_date,
         no_of_galleys: { 'fwd': 1, 'mid': '0', 'aft': 1},
         no_of_lavatories: { 'fwd': 1, 'mid': '0', 'aft': 1},
         no_of_gears: 3,
        },
        error: {}
      }))
    }
  }
	render(){
    const { type } = this.props.workorder;
    const { msnDetails } =  this.props;
    const { workorder, error } = this.state;
		const userInfo = getLocalStorageInfo();
		return(
		  <form  className="workorder-crud-form"  onSubmit = {(e) =>  this.workorderUpdate(e)} >
        <div className="sidebar-form" style={{ height: (window.innerHeight - 70) + 'px'}}>
          <Row>
            <Col md="6">
            {
                type != 'edit' ?
              <label className="radio-block" style={{marginBottom: '20px'}} >
                <input type="radio" name="msn-type" checked={this.state.create_msn ? false : true} onChange={() => this.createMsn(false)} />
                Link Existing MSN
              </label>:null
            }
            </Col>
            <Col md="6" >
              {
                type != 'edit' && getLocalStorageInfo().user.permission.technical && !getLocalStorageInfo().user.permission.technical.is_saas && getLocalStorageInfo().defaultLessor.lessor_type == 1 ?
                  <label className="radio-block" style={{marginBottom: '20px'}} >
                  <input type="radio" name="msn-type" checked={this.state.create_msn ? true : false} onChange={() => this.createMsn(true)} />
                  Create New MSN
                </label> : null
              }
            </Col>
            <Col md="12" style={{marginBottom: '20px'}}>
              {
                this.state.create_msn ?
                GetInputField(
                  'string',
                  <label className="for-label">MSN {type != 'view'?<sup className="reqStar"> * </sup>:null}</label>,
                  { type: type,
                    value: workorder.aircraft_id,
                    error: error.aircraft_id,
                    updateForm: (value) => this.updateForm('aircraft_id', value)
                  }
                ) :
                GetInputField(
                  'dropdown',
                  <label className="for-label">MSN {type != 'view'?<sup className="reqStar"> * </sup>:null}</label>,
                  { type: type,
                    value: workorder.aircraft_id,
                    error: error.aircraft_id,
                    options: this.props.msnSwitcher ? this.props.msnSwitcher.filter(data => data.asset_type == 1) : [],
                    labelKey: 'asset',
                    valueKey: 'slug',
                    isDisabled: type == 'edit' && workorder.status != 1 ? true: false,
                    updateForm: (value) => this.updateForm('aircraft_id', value)
                  }
                )
              }
            </Col>
            <Col md="6" style={{marginBottom: '20px'}}>
              {
                GetInputField(
                  'date',
                  <label className="for-label">Start Date {type != 'view'?<sup className="reqStar"> * </sup>:null}</label>,
                  { type: type,
                    value: workorder.start_date,
                    error: error.start_date,
                    isDisabled: type == 'edit' && workorder.status >= 7 ? true: false,
                    updateForm: (value) => this.updateForm('start_date', value)
                  }
              )}
            </Col>
            <Col md="6" style={{marginBottom: '20px'}}>
              {
                GetInputField(
                  'date',
                  <label className="for-label">Expected End Date  {type != 'view'?<sup className="reqStar"> * </sup>:null}</label>,
                  { type: type,
                    value: workorder.expected_end_date,
                    error: error.expected_end_date,
                    isDisabled: type == 'edit' && workorder.status >= 7 ? true: false,
                    updateForm: (value) => this.updateForm('expected_end_date', value)
                  }
              )}
            </Col>
            <Col md="6" style={{marginBottom: '20px'}}>
              {
                GetInputField(
                  'dropdown',
                  <label className="for-label">Aircraft Model {type != 'view' && this.state.create_msn ? <sup className="reqStar"> * </sup>:null} </label>,
                  { type: type,
                    value: workorder.aircraft_type_id,
                    options: this.props.aircraftTypeList,
                    labelKey: 'name',
                    valueKey: 'id',
                    error: this.state.create_msn ? error.aircraft_type_id : null,
                    isDisabled: !this.state.create_msn,
                    updateForm: (value) => this.updateForm('aircraft_type_id', value)
                  }
              )}
            </Col>
            <Col md="6" style={{marginBottom: '20px'}}>
              {
                GetInputField(
                  'dropdown',
                  <label className="for-label">Aircraft Blueprint </label>,
                  { type: type,
                    value: workorder.blueprint_id,
                    options: this.props.bluePrintsInfo,
                    labelKey: 'name',
                    valueKey: 'id',
                    updateForm: (value) => this.updateForm('blueprint_id', value)
                  }
              )}
							</Col>
							<Col md="6" style={{marginBottom: '20px'}}>
              {
                GetInputField(
                  'date',
                  <label className="for-label">Lease Start Date</label>,
                  { type: type,
                    value: workorder.lease_start_date,
                    updateForm: (value) => this.updateForm('lease_start_date', value==''?null:value)
                  }
              )}
            </Col>
            <Col md="6" style={{marginBottom: '20px'}}>
              {
                GetInputField(
                  'string',
                  <label className="for-label">TSN</label>,
                  { type: type,
                    value: workorder.tsn,
                    error: error.tsn,
                    updateForm: (value) => this.updateForm('tsn', value)
                  }
              )}
            </Col>
            <Col md="6" style={{marginBottom: '20px'}}>
              {
                GetInputField(
                  'string',
                  <label className="for-label">CSN</label>,
                  { type: type,
                    value: workorder.csn,
                    error: error.csn,
                    updateForm: (value) => this.updateForm('csn', value)
                  }
              )}
            </Col>
							<Col md="6" style={{marginBottom: '20px'}}>
               <label className="for-label">Location</label>
               {
								 window.google !== undefined && window.google !== null ?
								 <PlacesAutocomplete
	                    value={workorder.location}
	                    onChange={this.handleChange}
	                    onSelect={this.handleSelect}
	                  >
	                  {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
	                    <div style={{position: 'relative'}}>
	                      <input
	                        {...getInputProps({
	                          placeholder: 'Search Location',
	                          className: 'location-search-input',
	                        })}
	                      />
	                      {
	                        workorder.location ?
	                        <div className="autocomplete-dropdown-container" style={{position: 'absolute', top: '38px', width: '100%', zIndex: 100}}>
	                        {loading && <div>Loading...</div>}
	                        {suggestions.map(suggestion => {
	                          const className = suggestion.active
	                            ? 'suggestion-item--active'
	                            : 'suggestion-item';
	                          // inline style for demonstration purpose
	                          const style = suggestion.active
	                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
	                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
	                          return (
	                            <div
	                              {...getSuggestionItemProps(suggestion, {
	                                className,
	                                style,
	                              })}
	                            >
	                              <span>{suggestion.description}</span>
	                            </div>
	                          );
	                        })}
	                      </div> :  null
	                      }
	                    </div>
	                  )}
	                </PlacesAutocomplete>
								 :GetInputField(
                   'string',
                   "",
                   { type: type,
                     value: workorder.location,
                     error: error.location,
                     updateForm: (value) => this.updateForm('location', value)
                   }
               )
							 }
            </Col>
						<Col md="6" style={this.state.create_msn ? {marginBottom: '20px'} : {display: 'none'}}>
							{
								GetInputField(
									'string',
									<label className="for-label">Country  {type != 'view'?<sup className="reqStar"> * </sup>:null}</label>,
									{ type: type,
										isDisabled: !this.state.create_msn,
										value: workorder.country ? workorder.country : '',
										error: error.country,
										updateForm: (value) => this.updateForm('country', value)
									}
							)}
						</Col>
						<Col md="6" style={{marginBottom: '20px'}}>
              {
                GetInputField(
                  'date',
                  <label className="for-label"> Date of Manufacture {type != 'view' && this.state.create_msn ? <sup className="reqStar"> * </sup>:null} </label>,
                  { type: type,
                    isDisabled: !this.state.create_msn,
                    value: workorder.date_of_manufacture,
                    error: this.state.create_msn ? error.date_of_manufacture : null,
                    updateForm: (value) => this.updateForm('date_of_manufacture', value)
                  }
              )}
            </Col>
						<Col md="6" style={{marginBottom: '20px'}}>
              {
                GetInputField(
                  'string',
                  <label className="for-label">Registration  </label>,
                  { type: type,
                    value: workorder.registration ? workorder.registration : '',
                    error: error.registration,
                    updateForm: (value) => this.updateForm('registration', value)
                  }
              )}
            </Col>
            <Col md="6" style={{marginBottom: '20px'}}>
              {
                GetInputField(
                  'string',
                  <label className="for-label">Line Number  </label>,
                  { type: type,
                    value: workorder.line_number ? workorder.line_number : '',
                    error: error.line_number,
                    updateForm: (value) => this.updateForm('line_number', value)
                  }
              )}
            </Col>
            <Col md="6" style={{marginBottom: '20px'}}>
              {
                GetInputField(
                  'date',
                  <label className="for-label">Status As Of  </label>,
                  { type: type,
                    value: workorder.status_as_of,
                    error: error.status_as_of,
                    updateForm: (value) => this.updateForm('status_as_of', value)
                  }
              )}
            </Col>


    				 <Col md="6" style={{marginBottom: '20px'}}>
              {
                GetInputField(
                  'dropdown',
                  <label className="for-label">Manufacturer  </label>,
                  { type: type,
                    value: workorder.manufacturer_id,
                    error: error.manufacturer_id,
                    options: this.props.sManufacruerList,
                    labelKey: 'name',
                    valueKey: 'id',
                    isDisabled: true ,
                    updateForm: (value) => this.updateForm('manufacturer_id', value)
                  }
              )}
            </Col>
            <Col md="6" style={{marginBottom: '20px'}}>
              {
                GetInputField(
                  'string',
                  <label className="for-label">Operator Storage Facility </label>,
                  { type: type,
                    value: workorder.operator_storage_facility,
                    error: error.operator_storage_facility,
                    updateForm: (value) => this.updateForm('operator_storage_facility', value)
                  }
              )}
            </Col>
            <Col md="6" style={{marginBottom: '20px'}}>
              {
                GetInputField(
                  'string',
                  <label className="for-label">AOC Regulation  </label>,
                  { type: type,
                    value: workorder.aoc_regulation ? workorder.aoc_regulation : '',
                    error: error.aoc_regulation,
                    updateForm: (value) => this.updateForm('aoc_regulation', value)
                  }
              )}
            </Col>

							<Col md="6" style={{marginBottom: '20px'}}>
              {
                GetInputField(
                  'number',
                  <label className="for-label">No. of Landing Gear </label>,
                  { type: type,
                    value: workorder.no_of_gears,
                    error: error.no_of_gears,
                    max: 5,
                    min: 3,
                    error: error.no_of_gears,
                    updateForm: (value) => this.updateForm('no_of_gears', value)
                  }
              )}
            </Col>
     		   <Col md="12" style={{marginBottom: '20px'}}>
              {
                GetInputField(
                  'multiDropDown',
                  <label className="for-label"> Inspection Types <img width="15" style={{marginLeft:'5px',cursor:'pointer'}} onClick={()=>this.props.workOrderInfo()}src={imgStoragePath+"help_question.png"} alt="img"/> </label>,
                  { type: type,
                    value: workorder.inspection_types,
                    options: this.props.inspectionType,
                    labelKey: 'name',
                    valueKey: 'id',
										error: error.inspection_types,
                    isDisabled: type == 'edit' && workorder.status > 3 ? true:false,
                    updateForm: (value) => this.updateForm('inspection_types', value)
                  }
              )}
            </Col>
            {

            }
					 <Col md="12" style={{marginBottom: '20px'}}>
              {
                GetInputField(
                  'multiDropDown',
                  <label className="for-label"> Forms </label>,
                  { type: type,
                    value: workorder.forms,
                    options: this.props.formList,
                    labelKey: 'name',
                    valueKey: 'id',
                    isDisabled: type == 'edit' && workorder.status > 3 ? true:false,
                    updateForm: (value) => this.updateForm('forms', value)
                  }
              )}
            </Col>

						<Col md="12" className="grouped-blocks">
							{
								[8,9].indexOf(workorder.blueprint_id) == -1 ?
								<div className="inner-grouped-block">
									<h4>No of Galleys</h4>
									<Row>
										<Col md="4">
											{
											GetInputField(
												'number',
												<label className="for-label"> Fwd Area </label>,
	                      { type: type,
	                        max: 9,
	                        min: 0,
	                        labelKey: 'fwd',
	                        value: workorder.no_of_galleys && workorder.no_of_galleys.fwd >= 0 ? workorder.no_of_galleys.fwd : '',
													updateForm: (value) => this.updatejsonFormat('fwd', value, 'no_of_galleys')
												}
										)}
											</Col>
											<Col md="4">
											{
											GetInputField(
												'number',
												<label className="for-label"> Mid Area</label>,
	                      { type: type,
	                        max: 9,
	                        min: 0,
	                        labelKey: 'mid',
	                        value: workorder.no_of_galleys && workorder.no_of_galleys.mid >= 0 ? workorder.no_of_galleys.mid : '',
													updateForm: (value) => this.updatejsonFormat('mid', value, 'no_of_galleys')
												}
										)}
										</Col>
										<Col>
											{
												GetInputField(
													'number',
													<label className="for-label">Aft Area</label>,
	                        { type: type,
	                          max: 9,
	                          min: 0,
	                          labelKey: 'aft',
	                          value: workorder.no_of_galleys && workorder.no_of_galleys.aft >= 0 ? workorder.no_of_galleys.aft : '',
														updateForm: (value) => this.updatejsonFormat('aft', value, 'no_of_galleys')
													}
											)}
										</Col>
									</Row>
	                <h6 className="error-msg">{error.no_of_galleys}</h6>

								</div>:null
							}
							{
								[8,9].indexOf(workorder.blueprint_id) != -1 ?
								<div className="inner-grouped-block">
									<h4>No of Galleys (Upper Deck)</h4>
									<Row>
										<Col md="4">
											{
											GetInputField(
												'number',
												<label className="for-label"> Fwd Area </label>,
	                      { type: type,
	                        max: 5,
	                        min: 0,
	                        labelKey: 'up_fwd',
	                        value: workorder.no_of_galleys && workorder.no_of_galleys.up_fwd >= 0 ? workorder.no_of_galleys.up_fwd : '',
													updateForm: (value) => this.updatejsonFormat('up_fwd', value, 'no_of_galleys')
												}
										)}
											</Col>
											<Col md="4">
											{
											GetInputField(
												'number',
												<label className="for-label"> Mid Area</label>,
	                      { type: type,
	                        max: 5,
	                        min: 0,
	                        labelKey: 'up_mid',
	                        value: workorder.no_of_galleys && workorder.no_of_galleys.up_mid >= 0 ? workorder.no_of_galleys.up_mid : '',
													updateForm: (value) => this.updatejsonFormat('up_mid', value, 'no_of_galleys')
												}
										)}
										</Col>
										<Col>
											{
												GetInputField(
													'number',
													<label className="for-label">Aft Area</label>,
	                        { type: type,
	                          max: 5,
	                          min: 0,
	                          labelKey: 'up_aft',
	                          value: workorder.no_of_galleys && workorder.no_of_galleys.up_aft >= 0 ? workorder.no_of_galleys.up_aft : '',
														updateForm: (value) => this.updatejsonFormat('up_aft', value, 'no_of_galleys')
													}
											)}
										</Col>
									</Row>
	                <h6 className="error-msg">{error.no_of_galleys}</h6>

								</div>:null
							}
							{
								[8,9].indexOf(workorder.blueprint_id) != -1 ?
								<div className="inner-grouped-block">
									<h4>No of Galleys  (Main Deck)</h4>
									<Row>
										<Col md="4">
											{
											GetInputField(
												'number',
												<label className="for-label"> Fwd Area </label>,
	                      { type: type,
	                        max: 5,
	                        min: 0,
	                        labelKey: 'fwd',
	                        value: workorder.no_of_galleys && workorder.no_of_galleys.fwd >= 0 ? workorder.no_of_galleys.fwd : '',
													updateForm: (value) => this.updatejsonFormat('fwd', value, 'no_of_galleys')
												}
										)}
											</Col>
											<Col md="4">
											{
											GetInputField(
												'number',
												<label className="for-label"> Mid Area</label>,
	                      { type: type,
	                        max: 5,
	                        min: 0,
	                        labelKey: 'mid',
	                        value: workorder.no_of_galleys && workorder.no_of_galleys.mid >= 0 ? workorder.no_of_galleys.mid : '',
													updateForm: (value) => this.updatejsonFormat('mid', value, 'no_of_galleys')
												}
										)}
										</Col>
										<Col>
											{
												GetInputField(
													'number',
													<label className="for-label">Aft Area</label>,
	                        { type: type,
	                          max: 5,
	                          min: 0,
	                          labelKey: 'aft',
	                          value: workorder.no_of_galleys && workorder.no_of_galleys.aft >= 0 ? workorder.no_of_galleys.aft : '',
														updateForm: (value) => this.updatejsonFormat('aft', value, 'no_of_galleys')
													}
											)}
										</Col>
									</Row>
	                <h6 className="error-msg">{error.no_of_galleys}</h6>

								</div>:null
							}
					</Col>
					<Col md="12"  className="grouped-blocks">
						{
							[8,9].indexOf(workorder.blueprint_id) == -1 ?
							<div className="inner-grouped-block">
								<h4>No of Lavatories</h4>
									<Row>
										<Col md="4">
										{
											GetInputField(
												'number',
												<label className="for-label"> Fwd Area</label>,
												{ type: type,
													max: 9,
													min: 0,
													labelKey: 'fwd',
													value: workorder.no_of_lavatories && workorder.no_of_lavatories.fwd >= 0 ? workorder.no_of_lavatories.fwd : '',
													updateForm: (value) => this.updatejsonFormat('fwd', value, 'no_of_lavatories')
												}
											)}

										</Col>
										<Col md="4">
										{
											GetInputField(
											'number',
												<label className="for-label">Mid Area</label>,
												{ type: type,
													max: 9,
													min: 0,
													labelKey: 'mid',
													value: workorder.no_of_lavatories && workorder.no_of_lavatories.mid >= 0 ?  workorder.no_of_lavatories.mid : '',
													updateForm: (value) => this.updatejsonFormat('mid', value, 'no_of_lavatories')
												}
											)}
										</Col>
									<Col>
										{
										GetInputField(
											'number',
											<label className="for-label">Aft Area</label>,
											{ type: type,
												max: 9,
												min: 0,
												labelKey: 'aft',
												value: workorder.no_of_lavatories && workorder.no_of_lavatories.aft >= 0 ? workorder.no_of_lavatories.aft : '',
												updateForm: (value) => this.updatejsonFormat('aft', value, 'no_of_lavatories')
											}
										)}
									</Col>
								</Row>
								<h6 className="error-msg">{error.no_of_lavatories}</h6>
							</div>:null
						}
						{
							[8,9].indexOf(workorder.blueprint_id) != -1 ?
							<div className="inner-grouped-block">
								<h4>No of Lavatories (Upper Deck)</h4>
									<Row>
										<Col md="4">
										{
											GetInputField(
												'number',
												<label className="for-label"> Fwd Area</label>,
												{ type: type,
													max: 5,
													min: 0,
													labelKey: 'up_fwd',
													value: workorder.no_of_lavatories && workorder.no_of_lavatories.up_fwd >= 0 ? workorder.no_of_lavatories.up_fwd : '',
													updateForm: (value) => this.updatejsonFormat('up_fwd', value, 'no_of_lavatories')
												}
											)}

										</Col>
										<Col md="4">
										{
											GetInputField(
											'number',
												<label className="for-label">Mid Area</label>,
												{ type: type,
													max: 5,
													min: 0,
													labelKey: 'up_mid',
													value: workorder.no_of_lavatories && workorder.no_of_lavatories.up_mid >= 0 ?  workorder.no_of_lavatories.up_mid : '',
													updateForm: (value) => this.updatejsonFormat('up_mid', value, 'no_of_lavatories')
												}
											)}
										</Col>
									<Col>
										{
										GetInputField(
											'number',
											<label className="for-label">Aft Area</label>,
											{ type: type,
												max: 5,
												min: 0,
												labelKey: 'up_aft',
												value: workorder.no_of_lavatories && workorder.no_of_lavatories.up_aft >= 0 ? workorder.no_of_lavatories.up_aft : '',
												updateForm: (value) => this.updatejsonFormat('up_aft', value, 'no_of_lavatories')
											}
										)}
									</Col>
								</Row>
								<h6 className="error-msg">{error.no_of_lavatories}</h6>
							</div>:null
						}
						{
							[8,9].indexOf(workorder.blueprint_id) != -1 ?
							<div className="inner-grouped-block">
								<h4>No of Lavatories (Main Deck)</h4>
									<Row>
										<Col md="4">
										{
											GetInputField(
												'number',
												<label className="for-label"> Fwd Area</label>,
												{ type: type,
													max: 5,
													min: 0,
													labelKey: 'fwd',
													value: workorder.no_of_lavatories && workorder.no_of_lavatories.fwd >= 0 ? workorder.no_of_lavatories.fwd : '',
													updateForm: (value) => this.updatejsonFormat('fwd', value, 'no_of_lavatories')
												}
											)}

										</Col>
										<Col md="4">
										{
											GetInputField(
											'number',
												<label className="for-label">Mid Area</label>,
												{ type: type,
													max: 5,
													min: 0,
													labelKey: 'mid',
													value: workorder.no_of_lavatories && workorder.no_of_lavatories.mid >= 0 ?  workorder.no_of_lavatories.mid : '',
													updateForm: (value) => this.updatejsonFormat('mid', value, 'no_of_lavatories')
												}
											)}
										</Col>
									<Col>
										{
										GetInputField(
											'number',
											<label className="for-label">Aft Area</label>,
											{ type: type,
												max: 5,
												min: 0,
												labelKey: 'aft',
												value: workorder.no_of_lavatories && workorder.no_of_lavatories.aft >= 0 ? workorder.no_of_lavatories.aft : '',
												updateForm: (value) => this.updatejsonFormat('aft', value, 'no_of_lavatories')
											}
										)}
									</Col>
								</Row>
								<h6 className="error-msg">{error.no_of_lavatories}</h6>
							</div>:null
						}
			</Col>

			<Col md="12"  className="grouped-blocks">
				<div className="inner-grouped-block">
					<h4>Record Inspection</h4>
					<Row>
						<Col md="6">
              {
                GetInputField(
                  'date',
                  <label className="for-label">Start Date </label>,
                  { type: type,
                    value: workorder.records_inspection_on_start_date,
                    option: [],
                    minDate: workorder.start_date,
                    maxDate: workorder.expected_end_date,
                    updateForm: (value) => this.updateForm('records_inspection_on_start_date', value)
                  }
              )}
								</Col>
							<Col md="6">
								{
                GetInputField(
                  'date',
                  <label className="for-label"> End Date</label>,
                  { type: type,
                    value: workorder.records_inspection_on_end_date,
                    minDate: workorder.records_inspection_on_start_date,
                   	maxDate: workorder.expected_end_date,
                    updateForm: (value) => this.updateForm('records_inspection_on_end_date', value)
                  }
              )}
							</Col>

							</Row>
							<h6 className="error-msg">{error.records_inspection_on_end_date}</h6>


				</div>
			</Col>

			<Col md="12"  className="grouped-blocks">
				<div className="inner-grouped-block">
				<h4>Physical Inspection</h4>
				<Row>
					<Col md="6">
					{
					GetInputField(
						'date',
						<label className="for-label">Start Date </label>,
						{ type: type,
							value: workorder.physical_inspection_on_start_date,
              minDate: workorder.start_date,
              maxDate: workorder.expected_end_date,
							updateForm: (value) => this.updateForm('physical_inspection_on_start_date', value)
						}
				)}
					</Col>
					<Col md="6">
					{
					GetInputField(
						'date',
						<label className="for-label"> End Date</label>,
						{ type: type,
              value: workorder.physical_inspection_on_end_date,
              minDate: workorder.physical_inspection_on_start_date,
              maxDate: workorder.expected_end_date,
              updateForm: (value) => this.updateForm('physical_inspection_on_end_date', value)
						}
				)}
				</Col>

			</Row>
			<h6 className="error-msg">{error.physical_inspection_on_end_date}</h6>
			</div>
			</Col>
     </Row>
        </div>
        { type != 'view' ?
          <div className="edit-sidebar-footer">
            <ul className="list-inline">
              <li className="list-inline-item">
                <button disabled={this.props.projectLoader} type="submit" formNoValidate className="btn btn-primary">{this.props.projectLoader ? 'Processing...':'Save'}</button>
              </li>
              <li className="list-inline-item">
                <Link onClick={this.props.toggleEditSideBar}> Cancel</Link>
              </li>
             </ul>
          </div>:null
        }
      </form>
		)
	}
}
export default AddEditWorkOrder;
