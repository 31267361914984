import moment from 'moment';
import React, { Component } from 'react';
import {  Row, Col } from 'reactstrap';
import { TextInputField,DateInputField, GetInputField } from '../../../Elements';


export default class GenralDetail03Wrapper extends Component {
  constructor(props) {
    super(props);
  }


  updateForm = () => {

  }
  render() {
  const { gapReportDetails,error, props, validation_details } = this.props;
  console.log('gapReportDetails',gapReportDetails);
  return(
    <div className="general-detail-block" style={{background: '#fff', paddingTop:'14px'}}>
      <Row>
        <Col xs="6"> <h4 className='tech-heading-block flex-centered'> Report Details</h4></Col>
        <Col xs="6"> <h4 className='tech-heading-block flex-centered'> HSI Details </h4></Col>
         <Col sm="3"  >
           {
            GetInputField(
              'date',
              <label className="for-label">Report Date<sup className="reqStar"> * </sup></label>,
              { type: 'edit',
               value: gapReportDetails.report_date,
               error: error && error.report_date ? error.report_date : '',
                updateForm: (value) => this.props.updateForm({ type: 'report_date', value: value, sectionType: 'genDetails'})
              }
            )
          }
         </Col>

         <Col sm="3" >
           {
            GetInputField(
              'string',
              <label className="for-label"> Report Issued Place <sup className="reqStar"> * </sup></label>,
              { type: 'edit',
              value: gapReportDetails.report_issued_place,
              error: error && error.report_issued_place ? error.report_issued_place : '',
                updateForm: (value) => this.props.updateForm({ type:'report_issued_place', value:value, sectionType: 'genDetails'})
              }
            )
          }
         </Col>
         <Col  className='hsi_details' sm="2"  >
         {
            GetInputField(
              'number',
              <label className="for-label">TSN at HSI</label>,
              { type: 'edit',
              value: gapReportDetails.hsi_tsn? gapReportDetails.hsi_tsn: null,
              className:'for-label',
              label:'hsi_tsn',
                updateForm: (value) => this.props.updateForm({ type:'hsi_tsn', value:value, sectionType: 'genDetails'})
              }
            )
          }
         </Col>
         <Col className='hsi_details' sm="2"  >
         {
            GetInputField(
              'number',
              <label className="for-label">CSN at HSI</label>,
              { type: 'edit',
              value: gapReportDetails.hsi_csn? gapReportDetails.hsi_csn: null,
              className:'for-label',
              label:'hsi_csn',
                updateForm: (value) => this.props.updateForm({ type:'hsi_csn', value:value, sectionType: 'genDetails'})
              }
            )
          }
         </Col>
         <Col sm="2"  >
           {
            GetInputField(
              'date',
              <label className="for-label">HSI Date</label>,
              { type: 'edit',
              value: gapReportDetails.hsi_date,
              error: '',
              minDate: validation_details?.esn_manufacturing_date,
              maxDate: moment(),
               updateForm: (value) => this.props.updateForm({ type: 'hsi_date', value: value, sectionType: 'genDetails'})
             }
            )
          }
         </Col>
      </Row>
    </div>
    );
  }
}
