import React, { Fragment } from 'react';
import { Grid } from '@material-ui/core';
import LabelValueCard from './LabelValueCard';
const RevisionEditCard = ({item}) => {
  return(
    <Fragment>
      <Grid container spacing={3}>
        <LabelValueCard md={6} label='Updated at' value={item.timestamp} />
        <LabelValueCard md={6} label='Updated by' value={item.user && item.user.name ? item.user.name:'--'} />
      </Grid>
      <div className="edit-detail-table">
        <table>
          <thead>
            <tr>
              <th style={{width: '33%'}}>Label</th>
              <th style={{width: '33%'}}>Old Value</th>
              <th style={{width: '33%'}}>New Value</th>
            </tr>
          </thead>
          <tbody>
            { Object.keys(item.changes).map(label =>
              <tr>
                <td>{label}</td>
                <td>{item.changes[label][0]}</td>
                <td>{item.changes[label][1]}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Fragment>
  )
}
export default RevisionEditCard;
