import React from 'react';
import { TableRow, TableCell, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { regexConstants } from '../../../constants/regEx';
export default function ProjectContact({item, index, error, resetErrorKey, rowCount, onFieldChange, addRemoveContact}){
  return(
    <TableRow hover tabIndex={-1}>
      <TableCell>
        <TextField
          id={`name${index}`}
          fullWidth
          margin="none"
          inputProps={{maxLength: 30}}
          value={item.name ? item.name:''}
          onChange={(e) => onFieldChange(e, 'name', e.target.value)}
          InputLabelProps={{shrink: true}}
          variant="outlined"
        />
      </TableCell>
      <TableCell>
        <TextField
          id={`email${index}`}
          fullWidth
          margin="none"
          value={item.email ? item.email:''}
          error={error.email ? true:false}
          helperText={error.email ? error.email:''}
          onChange={(e, value) => onFieldChange(e, 'email', e.target.value)}
          InputLabelProps={{shrink: true}}
          variant="outlined"
        />
      </TableCell>
      <TableCell>
        <TextField
          id={`designation${index}`}
          fullWidth
          margin="none"
          inputProps={{maxLength: 30}}
          value={item.designation ? item.designation:''}
          onChange={(e) => onFieldChange(e, 'designation', e.target.value)}
          InputLabelProps={{shrink: true}}
          variant="outlined"
        />
      </TableCell>
      <TableCell>
        <TextField
          id={`phone_number${index}`}
          fullWidth
          margin="none"
          value={item.phone_number ? item.phone_number:''}
          error={error.phone_number ? true:false}
          helperText={error.phone_number ? error.phone_number:''}
          onChange={(e, value) => onFieldChange(e, 'phone_number', e.target.value)}
          InputLabelProps={{shrink: true}}
          variant="outlined"
        />
      </TableCell>
      <TableCell className="actions-cell">
        { rowCount > 1 ?
          <DeleteOutlineIcon onClick={() => addRemoveContact()} color="error" />:null
        }
      </TableCell>
    </TableRow>
  )
}
