import React, {Fragment, useState, useEffect} from 'react';
import { withRouter } from 'react-router';
import { useSnackbar } from 'notistack';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { read, utils, writeFileXLSX } from 'xlsx';
import { Paper, Button, Dialog, Grid, TextField, DialogActions, DialogContent, DialogTitle, Table, TableHead, TableBody, TableCell, CircularProgress, TableRow, Tabs, Tab, Tooltip } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { globalGetService, globalPostService } from '../../../utils_v2/globalApiServices';
import { checkApiStatus, isPositiveInteger, onCheckPermission } from '../../../utils_v2';
import { regexConstants } from '../../../constants/regEx';
import { Link } from 'react-router';
import { result } from '../';
const ImportTemplate = ({params, importTemplate, toggleModalFn, getResponseBack}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [tabIndex, setTabIndex] = useState(0);
    const [modal, setModal] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [isFetching, setFetching] = useState(false);
    const [importCrud, setImportCrud] = useState({template:null});
    const [templates, setTemplates] = useState([])
    const [pres, setPres] = useState([]);
    const [error, setError] = useState([]);
    const [duplicates, setDuplicates] = useState(new Set());
    useEffect(() => {
        getFormTemplates();
    },[])
    const getFormTemplates = (query) => {
        globalGetService(`technical/templates/`, {type:'T004'})
        .then(response => {
            if(checkApiStatus(response)){
                setTemplates(response.data.data.list)
            }
        })
    }
    const onSpartaTemplate = (value) => {
        if(value === 1){
            setFetching(true);
            globalGetService(`api/technical/v2/workorder/${params.workOrderSlug}/default-observation-sections/?asset_type=${params.type}`)
            .then(response => {
                if(checkApiStatus(response)){
                    setPres(response.data.data.list)
                }
                setFetching(false);
            })
        }
    }
    const onChangeTemplate = (template) => {
        setImportCrud({template});
        setFetching(true);
        if(template){
            (async() => {
                const f = await (await fetch(template.file_path)).arrayBuffer();
                const wb = read(f); // parse the array buffer
                const ws = wb.Sheets[wb.SheetNames[0]]; // get the first worksheet
                const response_data = utils.sheet_to_json(ws,{blankRows: false}); // generate objects
                const sectionNames = new Set();
                const duplicates = new Set();
                const data = response_data.map(item => {
                    var temp_data={}
                    if (item['Section Name']){
                        const sectionName = item['Section Name'];
                        if (sectionNames.has(sectionName)) {
                            duplicates.add(sectionName);
                        } 
                            sectionNames.add(sectionName);
                            temp_data['name'] = sectionName;
                        
                    }
                    if (item['Observation']){
                        temp_data['observations']=item['Observation']
                    }
                    if (item['Component Title']){
                        temp_data['components']=item['Component Title']
                    }
                    if (item['Component Questions']){
                        temp_data['component_question']=item['Component Questions']
                    }
                    return temp_data
                });
                if (duplicates.size > 0) {
                    const duplicateNames = Array.from(duplicates).join(', ');
                    setError(`Duplicate section names found: ${duplicateNames}.Please Update the template file and import again.`);
                    setPres(data);
                } else {
                    setError('');
                }
                setDuplicates(duplicates); 
                setPres(data);
                setFetching(false);
    
            })();
        }else{
            setError('');
            setPres([]);
            setFetching(false);
        }
    }
    const onSetupTemplate = () => {
        if(tabIndex === 0){
            setLoading(true)
            globalPostService(`technical/v2/workorder/${params.workOrderSlug}/import-asset-sections/?asset_type=${params.type}`, pres)
            .then(response => {
                if(checkApiStatus(response)){
                    setModal(false);setPres([]);getResponseBack();
                }
                setLoading(false)
            })
        }else{
            setLoading(true)
            globalPostService(`technical/v2/workorder/${params.workOrderSlug}/asset-sections/?asset_type=${params.type}`, pres)
            .then(response => {
                if(checkApiStatus(response)){
                    setModal(false);setPres([]);getResponseBack();
                }
                setLoading(false)
            })
        }
    }
    return(
        <>
            <div className='import-form-template'>
                <h4>There is no section mapped to this form right now</h4>
                <p>Please choose the template to map the section</p>
                <Button onClick={() => {setModal(true)}} color='primary' variant='outlined' size='small'>Import Template</Button>
            </div>
            { modal ?
                <Dialog
                    open={modal}
                    onClose={() => {setModal(false)}}
                    className='project-management-modal'
                    maxWidth={false}
                >
                    <DialogTitle id="scroll-dialog-title">
                        Import Template
                    </DialogTitle>
                    <DialogContent dividers={true} >
                        <div className='t004-import-template' style={{width:'780px', minHeight:'400px'}}>
                            <Paper>
                                <Tabs
                                    value={tabIndex}
                                    onChange={(event, newValue) => {setPres([]);setTabIndex(newValue);setError('');onSpartaTemplate(newValue);setImportCrud({template:null})}}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="fullWidth"
                                >
                                    <Tab label={<span>Custom Template</span>} />
                                    <Tab label={<span>Sparta Template</span>} />
                                </Tabs>
                            </Paper>
                            { tabIndex === 0 ?
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={6}>
                                        <Autocomplete
                                            options = {templates}
                                            getOptionLabel={option => option.name}
                                            id="template"
                                            value={importCrud.template}
                                            onChange={(e, value) => onChangeTemplate(value)}
                                            renderInput={params => <TextField required {...params} label="Select Template" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
                                        />
                                    </Grid>
                                    { onCheckPermission('project_management', 'template','C') ?
                                    <Grid item sm={6} style={{padding:'25px 0px 0px 7px'}} >
                                            or &nbsp;
                                        <Link to='/technical/form/templates/'>
                                            <Button variant='contained' color='primary'>
                                                Add New Template
                                            </Button>
                                        </Link>
                                    </Grid>
                                    :null}
                                </Grid>:null
                            }
                            <div>
                                {tabIndex === 0 && pres.length ? 
                                <>{error && (
                                    <TableRow>
                                        <TableCell colSpan={4} style={{ color: 'red' }}>{error}</TableCell>
                                    </TableRow>
                                )}
                                    <Table className='mui-table-format'>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Section Name</TableCell>
                                                <TableCell>Observation</TableCell>
                                                <TableCell>Components Title</TableCell>
                                                <TableCell>Components Field</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {pres.map((item,index) => 
                                                <TableRow key={index} style={{ backgroundColor: duplicates.has(item.name) ? 'lightcoral' : 'inherit' }}>
                                                    <TableCell>{item?.name||''}</TableCell>
                                                    <TableCell>{item?.observations||''}</TableCell>
                                                    <TableCell>{item?.components||''}</TableCell>
                                                    <TableCell>{item?.component_question||''}</TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table></>:null
                                    
                                }
                                {tabIndex === 1 && pres.length ?
                                    <div className='sparta-template'>
                                        <Table className='mui-table-format'>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Section Name</TableCell>
                                                    <TableCell>Observation</TableCell>
                                                    <TableCell>Components Fields</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {pres.map((section, sectionIndex) => 
                                                    <TableRow>
                                                        <TableCell>{section.name}</TableCell>
                                                        <TableCell style={{borderLeft:'1px solid #d7d7d7', borderRight:'1px solid #d7d7d7'}}>
                                                            <Table className='inner-table'>
                                                                <TableBody>
                                                                    {section.observations.map((observation, obsIndex) => 
                                                                        <TableRow>
                                                                            <TableCell>{observation.label}</TableCell>
                                                                        </TableRow>
                                                                    )}
                                                                </TableBody>
                                                            </Table>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Table className='inner-table'>
                                                                <TableBody>
                                                                    {section.components.map((component) => 
                                                                        <TableRow>
                                                                            <TableCell>
                                                                                {component.title}
                                                                                <div style={{paddingLeft:'15px'}}>
                                                                                    {component.question_answers.map((question) => 
                                                                                        <p>- {question.label}</p>
                                                                                    )}
                                                                                </div>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    )}
                                                                </TableBody>
                                                            </Table>
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </div>
                                    :null
                                }
                            </div>
                            { isFetching ?
                                <div style={{padding:'40px 0', textAlign:'center'}}>
                                    <p>Processing...</p>
                                </div>:null
                            }
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={isLoading} onClick={() => {setModal(false)}} color="primary" size='small' variant='outlined'>Cancel</Button>
                        { pres.length ?
                            <Button disabled={isLoading||error.length} onClick={onSetupTemplate} color="primary" size='small' variant='contained'>
                                {isLoading ? <CircularProgress size={20} /> : 'Proceed'}
                            </Button>:null
                        }
                    </DialogActions>
                </Dialog>:null
            }
        </>
    )
    
}
export default withRouter(ImportTemplate);