import * as actions from '../actions';
import { globalGetService, globalPutService, globalPostService, globalDeleteService, globalFileUploadService } from '../../../../../globalServices/';
import { toastFlashMessage, getLocalStorageInfo, getDataUri, dataURItoBlob } from '../../../../../utils';
import { trackActivity } from '../../../../../utils/mixpanel';
import moment from 'moment';
import { getFormDetailsAc } from '../../T001/actionCreators';

const userInfo = getLocalStorageInfo();
const shopVisit = {
	arc_date: null,
	build_target: "",
	cshi: null,
	cslsv: null,
	csn: null,
	cso: null,
	csr: null,
	date_of_reinstallation: null,
	date_of_removal: null,
	final_workscope: "",
	induction_date: null,
	llp_status: "",
	reason_for_removal: "",
	shop_facility: "",
	shop_visit_number: "",
	test_run_egt_margin: "",
	total_air_temperature: "",
	tshi: null,
	tslsv: null,
	tsn: null,
	tso: null,
	tsr: null,
	attachments: [],
	added_from: 1
}

export const fetchshopVisitDetailsAc = (props) => {
	return (dispatch) => {
		dispatch(triggerLoader(true));
		globalGetService(`/technical/engine-workorder/${props.params.workOrderSlug}/shop-visit-details/`, {})
			.then(response => {
				dispatch(triggerLoader(false));
				if (response.data.statusCode == 200) {
					dispatch({
						type: actions.GET_SHOPVISIT_DETAILS,
						payload: response.data.data
					})
				}
			})
	}
}

export const toggleAddEditShopvisitAc = (props, data) => {
	return (dispatch) => {
		if (data.type == 'add') {
			dispatch({
				type: actions.ADD_EDIT_SHOPVISIT,
				payload: { data: shopVisit, flag: data.flag, type: data.type }
			})
		} else {
			dispatch(triggerLoader(true));
			globalGetService(`/technical/engine-workorder/${props.params.workOrderSlug}/shop-visit/${data.data.id}/`, {})
				.then(response => {
					dispatch(triggerLoader(false));
					if (response.data.statusCode == 200) {
						dispatch({
							type: actions.ADD_EDIT_SHOPVISIT,
							payload: { data: response.data.data, flag: data.flag, type: data.type }
						})
					}
				})
		}
	}
}

export const UpdateShopVisitDetailsAc = (props, data) => {
	return (dispatch) => {
		dispatch(triggerLoader(true));
		globalPostService(`/technical/engine-workorder/${props.params.workOrderSlug}/shop-visit-details/`, data)
			.then(response => {
				dispatch(triggerLoader(false));
				if (response.data.statusCode == 200) {
					dispatch({
						type: actions.GET_SHOPVISIT_DETAILS,
						payload: response.data.data
					})
					toastFlashMessage(response.data.message, 'success');
					dispatch(getFormDetailsAc(props, 2, 'TE004'))
					trackActivity('TE004', {
						event_type: 'Shop Visit Details Updated',
						page_title: 'Built Standard Evaluation',
						workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
						form_slug: props.params.formSlug ? props.params.formSlug : '',
						request_body: data ? data : '',
						response_body: response.data.data ? response.data.data : '',
						asset_type: props.params.type ? props.params.type : ''
					})
				} else {
					trackActivity('TE004', {
						event_type: 'Shop Visit Details Update Failed',
						page_title: 'Built Standard Evaluation',
						workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
						form_slug: props.params.formSlug ? props.params.formSlug : '',
						request_body: data ? data : '',
						error_msg: response.data.message ? response.data.message : '',
						error_source: 'Backend',
						asset_type: props.params.type ? props.params.type : ''
					})
				}
			})
	}
}

export const GenUrlAc = (props, files) => {
	return (dispatch) => {
		dispatch(triggerLoader(true));
		var formData = new FormData();

		for (let i = 0; i < files.length; i++) {
			let file = files[i];
			if (!(file.type.match('application/pdf.*') || file.type.match('application/vnd.ms-excel.*') || file.type.match('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet.*'))) {
				toastFlashMessage(file.name + ' is not Valid Format', 'error');
				continue;
			}
			formData.append('file', file, file.name);
		}
		formData.append('shop_visit', true);
		var config = {
			onUploadProgress: function (progressEvent) {
				var percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
				// dispatch({
				//   type: actions.PROGRESS_PERCENTAGE,
				//   payload: percentage
				// });
			}
		}
		globalFileUploadService('/technical/engine-workorder/' + props.params.workOrderSlug + '/file-upload/', formData, config)
			.then(response => {
				dispatch(triggerLoader(false));
				if (response.data.statusCode == 200) {
					dispatch({
						type: actions.UPLOAD_FILE,
						payload: { attachment: response.data.url, name: response.data.name, created_at: moment(), user: { id: userInfo.user.id, name: userInfo.user.name, profile_pic: userInfo.user.profile_pic } }
					})
					trackActivity('TE004', {
						event_type: 'File Uploaded',
						page_title: 'Built Standard Evaluation',
						workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
						form_slug: props.params.formSlug ? props.params.formSlug : '',
						file_name: response.data.name ? response.data.name : '',
						attachment: response.data.url ? response.data.url : '',
						request_body: formData ? formData : '',
						response_body: response.data.data ? response.data.data : ''
					})
				}
			})
	}
}

export const updateShopVisitAc = (props, dataObj) => {
	return (dispatch) => {
		dispatch(triggerLoader(true));
		if (dataObj.type == 'add') {
			globalPostService('/technical/engine-workorder/' + props.params.workOrderSlug + '/shop-visit/', dataObj.data)
				.then(response => {
					dispatch(triggerLoader(false));
					if (response.data.statusCode == 200) {
						toastFlashMessage(response.data.message, 'success');
						trackActivity('TE004', {
							event_type: 'Shop Visit Added',
							page_title: 'Built Standard Evaluation',
							workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
							form_slug: props.params.formSlug ? props.params.formSlug : '',
							engine_workorder: dataObj.engine_workorder ? dataObj.engine_workorder : '',
							item_id: dataObj.id ? dataObj.id : '',
							request_body: dataObj.data ? dataObj.data : '',
							response_body: response.data.data ? response.data.data : ''
						})
						dispatch({
							type: actions.ADD_SHOPVISIT,
							payload: response.data.data
						});
					}
				})
		} else {
			globalPutService(`/technical/engine-workorder/${props.params.workOrderSlug}/shop-visit/${dataObj.data.id}/`, dataObj.data)
				.then(response => {
					dispatch(triggerLoader(false));
					if (response.data.statusCode == 200) {
						dispatch({
							type: actions.UPDATE_SHOPVISIT,
							payload: response.data.data
						});
						toastFlashMessage(response.data.message, 'success');
						trackActivity('TE004', {
							event_type: 'Shop Visit Edited',
							page_title: 'Built Standard Evaluation',
							workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
							form_slug: props.params.formSlug ? props.params.formSlug : '',
							engine_workorder: dataObj.engine_workorder ? dataObj.engine_workorder : '',
							item_id: dataObj.id ? dataObj.id : '',
							request_body: dataObj.data ? dataObj.data : '',
							response_body: response.data.data ? response.data.data : ''
						})
					}
				})
		}
	}
}

export const deleteShopVisitAc = (props, id, type, index) => {
	return (dispatch) => {
		if (type == 'shopVisit') {
			dispatch(triggerLoader(true));
			globalDeleteService(`technical/engine-workorder/${props.params.workOrderSlug}/shop-visit/${id}/delete/`, {})
				.then(response => {
					dispatch(triggerLoader(false));
					if (response.data.statusCode == 200) {
						dispatch({
							type: actions.DELETE_SHOPVISIT,
							payload: id
						});
						toastFlashMessage(response.data.message, 'success');
						trackActivity('TE004', {
							event_type: 'Shop Visit Deleted',
							page_title: 'Built Standard Evaluation',
							workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
							form_slug: props.params.formSlug ? props.params.formSlug : '',
							item_id: id ? id : '',
							type: type,
							response_body: response.data.data ? response.data.data : ''
						})
					}
				})
		} else {
			if (!id) {
				dispatch({
					type: actions.DELETE_SHOPVISIT_ATTACHMENT,
					payload: index
				});
			} else {
				dispatch(triggerLoader(true));
				globalDeleteService(`technical/shop-visit-attachment/${id}/delete/`, {})
					.then(response => {
						dispatch(triggerLoader(false));
						if (response.data.statusCode == 200) {
							dispatch({
								type: actions.DELETE_SHOPVISIT_ATTACHMENT,
								payload: index
							});
							trackActivity('TE004', {
								event_type: 'Shop Visit Attachment Deleted',
								page_title: 'Built Standard Evaluation',
								workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
								form_slug: props.params.formSlug ? props.params.formSlug : '',
								item_id: id ? id : '',
								type: type,
								response_body: response.data.data ? response.data.data : ''
							})
						}
					})
			}
			toastFlashMessage('Shop visit attachment deleted successfully', 'success');
		}
	}
}

export const triggerLoader = (flag) => {
	return (dispatch) => {
		dispatch({
			type: actions.SHOP_VISIT_LOADER,
			payload: flag
		});
	}
}


