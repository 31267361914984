import React, { Component } from 'react';
import { connect } from 'react-redux';
import EngineCurrentDetails from '../components/EngineCurrentDetails';
import EngineHistory from '../components/EngineHistory'
import ECTMdetails from '../components/ECTMdetails'
import Remarks from '../components/Remarks'
import MRInvoiceDetails from '../components/MRInvoiceDetails'
import BoroScopeDetails from '../components/BoroScopeDetails'
import {fetchEngineDetailsAc,GenUrlAc,fetchFormsAc,fetchExistingEngineAc,fetchEngineAc,deleteItemsAc, getProjectInfoAc} from '../actionCreators'
import { Link, browserHistory } from 'react-router';
import {getLocalStorageInfo, toastFlashMessage } from '../../../../utils';
import { sGlobalConstantAc,sArctTypesAc, sEngineTypeAc} from '../../../../shared/actionCreators';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card,Row, Col } from 'reactstrap';
import classnames from 'classnames';
import * as actions from '../actions';
import ReferenceLinks from '../components/ReferenceLinks';
import Attachments from '../components/Attachments';
import ShopVisitDetails from '../components/ShopVisitDetails';
import {shopVisitObj,createEngine} from '../ProjectFileObj'
import moment from 'moment';
import {  GetInputField, DeleteModal } from '../../Elements';
import {ListLoader} from '../../../../shared'
import {imgStoragePath} from '../../../../constants'
import { checkString, checkEmail, checkValidDate, checkFloat, checkIntNumber } from '../../../../formValidator'
const userInfo = getLocalStorageInfo();

class CreateTechnicalEngine extends Component{
  constructor(props){
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: 0,
      create_esn: false,
      engineDetails:['esn','engine_type_id','tsn','csn','tslv','cslv','tso','cso','tshi','cshi','tsr','csr','country'],
      inspectionDetails:['inspection_tsn','inspection_csn','inspection_tslv','inspection_cslv','inspection_tso','inspection_cso','inspection_tshi','inspection_cshi','inspection_tsr','inspection_csr'],
      error:{},
      svError:[],
      delModal: false,
      deleteType: '',
      deleteId: '',
      deleteIndex: '',
      deleteParentIndex:''

    };
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  componentDidMount(){
    if(this.props.params.workOrderSlug){
      this.props.fetchEngineDetails('fetch')
    }
    this.props.fetchArchType();
    this.props.fetchExistingEngine();
    this.props.fetchProjectsData();
    this.props.fetchEngines();
    this.props.fetchForms();
    this.props.fetchTechConstant({constant_types:["engine_position"]});
  }

  componentWillUnmount(){
    this.props.clearData();
  }



  updateCreateEngineDetails=(data)=>{
    const {  create_esn } = this.state
    let validateNewInput = {
            esn:  checkString({value: data.esn, required: true, minLength: '', maxLength: '', message: 'Please enter valid ESN'}),
            engine_type_id:  checkString({value: data.engine_type_id, required: true, minLength: '', maxLength: '', message: 'Please select the Engine Type '}),
            tsn: checkFloat({value: data.tsn, required: true, minLength: '', maxLength: '', message: 'Please enter valid TSN'}),
            csn: checkIntNumber({value: data.csn, required: true, minLength: '', maxLength: '', message: 'Please enter valid CSN'}),
            tslv: checkFloat({value: data.tslv, required: false, minLength: '', maxLength: '', message: 'Please enter valid TSLV'}),
            cslv: checkIntNumber({value: data.cslv, required: false, minLength: '', maxLength: '', message: 'Please enter valid CSLV'}),
            tso: checkFloat({value: data.tso, required: false, minLength: '', maxLength: '', message: 'Please enter valid TSO'}),
            cso: checkIntNumber({value: data.cso, required: false, minLength: '', maxLength: '', message: 'Please enter valid CSO'}),
            tshi: checkFloat({value: data.tshi, required: false, minLength: '', maxLength: '', message: 'Please enter valid TSHI'}),
            cshi: checkFloat({value: data.cshi, required: false, minLength: '', maxLength: '', message: 'Please enter valid CSHI'}),
            tsr: checkFloat({value: data.tsr, required: false, minLength: '', maxLength: '', message: 'Please enter valid TSR'}),
            csr: checkIntNumber({value: data.csr, required: false, minLength: '', maxLength: '', message: 'Please enter valid CSR'}),

            country: create_esn ? checkString({value: data.country, required: true, minLength: '', maxLength: '', message: 'Please enter country'}) : '' ,
            inspection_tsn: checkFloat({value: data.inspection_tsn, required: false, minLength: '', maxLength: '', message: 'Please enter valid TSN'}),
            inspection_csn: checkIntNumber({value: data.inspection_csn, required: false, minLength: '', maxLength: '', message: 'Please enter valid CSN'}),
            inspection_tslv: checkFloat({value: data.inspection_tslv, required: false, minLength: '', maxLength: '', message: 'Please enter valid TSLV'}),
            inspection_cslv: checkIntNumber({value: data.inspection_cslv, required: false, minLength: '', maxLength: '', message: 'Please enter valid CSLV'}),
            inspection_tso: checkFloat({value: data.inspection_tso, required: false, minLength: '', maxLength: '', message: 'Please enter valid TSO'}),
            inspection_cso: checkFloat({value: data.inspection_cso, required: false, minLength: '', maxLength: '', message: 'Please enter valid CSO'}),
            inspection_tshi: checkFloat({value: data.inspection_tshi, required: false, minLength: '', maxLength: '', message: 'Please enter valid TSHI'}),
            inspection_cshi: checkIntNumber({value: data.inspection_cshi, required: false, minLength: '', maxLength: '', message: 'Please enter valid CSHI'}),
            inspection_tsr: checkFloat({value: data.inspection_tsr, required: false, minLength: '', maxLength: '', message: 'Please enter valid TSR'}),
            inspection_csr: checkIntNumber({value: data.inspection_csr, required: false, minLength: '', maxLength: '', message: 'Please enter valid CSR'}),
            date_of_inspection: checkValidDate({value: data.date_of_inspection, required: false, minLength: '', maxLength: '', message: 'Please enter valid Date of Inspection'}),

            as_on_date: checkValidDate({value: data.as_on_date, required: false, minLength: '', maxLength: '', message: 'Please enter valid as on date'}),


            engine_manufacturing_date: checkValidDate({value: data.engine_manufacturing_date, required: true, minLength: '', maxLength: '', message: 'Please enter valid Engine Manufactured date'}),
            date_of_installation: checkValidDate({value: data.date_of_installation, required: false, minLength: '', maxLength: '', message: 'Please enter valid date of instalation'}),
            date_of_installation: data.date_of_installation? moment(data.date_of_installation).diff(moment(data.engine_manufacturing_date),'days')<0 ?'Date should be greater than Manufacturing Date':'':'',
            date_of_inspection: data.date_of_inspection? moment(data.date_of_inspection).diff(moment(data.engine_manufacturing_date),'days')<0 ?'Date should be greater than Manufacturing Date':'':'',

     };

      let validShopVisit=[];
      if(data.shop_visits && data.shop_visits.length>0){
         validShopVisit=this.checkmap(data);
       }

        let validationFlag =  validShopVisit.map(innerArray => Object.keys(innerArray).every((k) => { return innerArray[k] === '' } ) );



        if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
          this.setState({
            error: {}
          });
          if(validationFlag.length){
          if(validationFlag.find(validData => validData ===true)!= undefined && validationFlag.find(validData => validData === true)){
            this.setState({
              svError: [],
            });
            this.props.fetchEngineDetails(this.props.params.workOrderSlug?'update':'create',data);
          }else{
            this.setState({
              svError: validShopVisit,
              activeTab:2
            });
          }
        }else{
          this.props.fetchEngineDetails(this.props.params.workOrderSlug?'update':'create',data);
        }
        }else{
          this.setState({
            error: validateNewInput
          });

          if(validationFlag.find(flag => flag ===false)!= undefined&&! validationFlag.find(flag => flag ===false)){
            this.setState({
              svError: validShopVisit,
              activeTab:2
            });
          }
       }
       if(validateNewInput.as_on_date && validateNewInput.as_on_date !=''){
        this.setState({
          activeTab:3
        });
       }
       if(validateNewInput.engine_manufacturing_date && validateNewInput.engine_manufacturing_date !=''){
        this.setState({
          activeTab:1
        });
       }
       let inspectionFlag =  this.state.inspectionDetails.map(label => { return validateNewInput[label] === '' } );
        if(inspectionFlag.find(flag => flag ===false)!= undefined&&!inspectionFlag.find(flag => flag ===false)){
          this.setState({
            activeTab:4
          });
        }

       let engineDetailsFlag =  this.state.engineDetails.map(label => { return validateNewInput[label] === '' } );
        if(engineDetailsFlag.find(flag => flag ===false)!= undefined&& !engineDetailsFlag.find(flag => flag ===false)){
          this.setState({
            activeTab:0
          });
        }


  }

  updateForm=(data)=>{
  if(data.sectionType=='EngineCurrentDetails'){
    this.setState(prevState => ({
      ...prevState,
      error:{
        ...prevState.error,
        [data.type]:''
      }
    }))
  }else if(data.sectionType=='shop_visit'){
    this.setState(prevState => ({
      ...prevState,
      svError: [...prevState.svError].map((dataSv, index) => index == data.index ? {
              ...dataSv,
              [data.type] : '' }:dataSv
          )
    }))
  }else if(data.sectionType=='forms'){
    this.props.updateForm(data);
  }
  this.props.updateForm(data);
  }


  checkmap = (data) => {
    let vaildArray=[];
    data.shop_visits.map((shopDetails, index) => {
      let obj={
        shop_visit_number:  checkString({value: shopDetails.shop_visit_number, required: true, minLength: '', maxLength: '', message: 'Please enter Shop Visit Number'}),
        tsn: checkFloat({value: shopDetails.tsn, required: true, minLength: '', maxLength: '', message: 'Please enter valid TSN'}),
        csn: checkIntNumber({value: shopDetails.csn, required: true, minLength: '', maxLength: '', message: 'Please enter valid CSN'}),
        tslsv: checkFloat({value: shopDetails.tslsv, required: false, minLength: '', maxLength: '', message: 'Please enter valid TSLV'}),
        cslsv: checkFloat({value: shopDetails.cslsv, required: false, minLength: '', maxLength: '', message: 'Please enter valid CSLV'}),
        tso: checkFloat({value: shopDetails.tso, required: false, minLength: '', maxLength: '', message: 'Please enter valid TSO'}),
        cso: checkFloat({value: shopDetails.cso, required: false, minLength: '', maxLength: '', message: 'Please enter valid CSO'}),
        tshi: checkFloat({value: shopDetails.tshi, required: false, minLength: '', maxLength: '', message: 'Please enter valid TSHI'}),
        cshi: checkIntNumber({value: shopDetails.cshi, required: false, minLength: '', maxLength: '', message: 'Please enter valid CSHI'}),
        tsr: checkFloat({value: shopDetails.tsr, required: false, minLength: '', maxLength: '', message: 'Please enter valid TSR'}),
        csr: checkIntNumber({value: shopDetails.csr, required: false, minLength: '', maxLength: '', message: 'Please enter valid CSR'}),
        date_of_removal: checkValidDate({value: shopDetails.date_of_removal, required: false, minLength: '', maxLength: '', message: 'Please enter valid date of removal'}),
        arc_date: checkValidDate({value: shopDetails.arc_date, required: false, minLength: '', maxLength: '', message: 'Please enter valid  ARC date'}),
        date_of_reinstallation: checkValidDate({value: shopDetails.date_of_reinstallation, required: false, minLength: '', maxLength: '', message: 'Please enter valid date of re-instalation'}),
      };
      vaildArray.push(obj)
    })
    toastFlashMessage('Please fill all mandatory fields.', 'error');
    return vaildArray;
  }


  createEsn = (flag) => {
    this.setState(prevState => ({
      ...prevState,
      create_esn: flag,
      error:{}
    }))

   this.props.updateEngineDetails();
   this.updateForm({type: 'create_esn', value:flag, sectionType: 'EngineCurrentDetails', index:this.props.index})
  }

  toggleDelModal = (id=null, type=null, index,ParentIndex=null) => {
    this.setState({
      delModal: !this.state.delModal,
      deleteType: type,
      deleteId: id,
      deleteIndex: index,
      deleteParentIndex:ParentIndex
    });
  }

  getComponent=(index, content)=>{
    switch (index){
      case 0:
      return <EngineCurrentDetails content={content} editFeature={this.props.params.workOrderSlug ? true:false} error={this.state.error} techConstants = {this.props.techConstants} updateForm={this.updateForm} fetchEngine ={this.props.fetchEngine} aircraftType={this.props.aircraftTypes} engineType={this.props.sEngineTypes} esn={this.state.create_esn} esnList={this.props.esnlist}/>;
      case 1:
      return <EngineHistory content={content} editFeature={this.props.params.workOrderSlug ? true:false} error={this.state.error} updateForm = {this.updateForm} />;
      case 2:
      return <ShopVisitDetails content={content} svError={this.state.svError} GenUrlFn = {this.props.GenUrlFn} updateForm = {this.updateForm} addShopVisit={this.props.addShopVisit}  toggleDelModal={this.toggleDelModal} />;
      case 3:
      return <MRInvoiceDetails content={content} error={this.state.error} updateForm = {this.updateForm}/>;
      case 4:
      return <BoroScopeDetails content={content} error={this.state.error} updateForm = {this.updateForm}/>;
      case 5:
      return <ECTMdetails content={content} updateForm = {this.updateForm}/>;
      case 6:
      return <Remarks content={content} updateForm = {this.updateForm} />;
      case 7:
      return <Attachments content={content} GenUrlFn = {this.props.GenUrlFn} updateForm = {this.updateForm} toggleDelModal={this.toggleDelModal} />;
      case 8:
      return <ReferenceLinks content={content} addReferenceLink={this.props.addReferenceLink} updateForm = {this.updateForm} toggleDelModal={this.toggleDelModal}  />;
    }
  }
  render(){
    var{engineDetails,engineForms,createEngineLoader,attachmentLoader, projectsData}=this.props;
     const engineOption = engineForms.map((item)=>({
       id:item.id,
       name:`${item.name}-${item.title}`
     }))
    const userInfo = getLocalStorageInfo()
    engineDetails = {
      ...engineDetails,
      forms: engineDetails && Object.keys(engineDetails).length && engineDetails.forms.length ? engineDetails.forms: ''
    }
    console.log('projectsData',projectsData);
    return (
      <div className="create-engine-form">
        <div className="create-engine">
        {createEngineLoader || this.props.projectLoader? <ListLoader/> :null}
        {attachmentLoader ? <ListLoader/> :null}
        <Row className="view-header">
            <Col md="8">
              <ul className="list-inline project-edit flex-centered">
                <li className="list-inline-item ">
                  <Link to={"/technical/project/view/"+this.props.params.project_slug} className="project-nav">
                    <img width="18" src={imgStoragePath+"back_arrow.png"} />
                  </Link>
                </li>
                <li className="list-inline-item" style={{minWidth: '300px'}}>
                  <div className="">
                    <h4>{this.props.params.workOrderSlug? 'Edit ESN' : 'Add ESN'}</h4>
                  </div>
                </li>
              </ul>
              </Col>
            </Row>
        <div className="general-detail-block" style={{background: '#fff',margin: '20px 0px'}}>
         <Row  style={{margin: '0px'}}>
         {this.props.params.workOrderSlug ? null:
            <Col md="4">
            {
              <label className="radio-block" style={{marginBottom: '20px'}} >
                <input type="radio" name="msn-type" checked={this.state.create_esn ? false : true} onChange={() => this.createEsn(false)} />
                Link Existing ESN
              </label>
            }
            </Col>
         }
          {this.props.params.workOrderSlug ? null:
            userInfo.defaultLessor.lessor_type != 3 ?
            <Col md="4" >
              {
                  <label className="radio-block" style={{marginBottom: '20px'}} >
                  <input type="radio" name="msn-type" checked={this.state.create_esn ? true : false} onChange={() => this.createEsn(true)} />
                  Create New ESN
                </label>
              }
            </Col>:<Col md="4" ></Col>
          }
         <Col sm="6"  style={{marginBottom: '20px'}}>
           {
         GetInputField(
            'multiDropDown',
            <label className="for-label">Forms</label>,
            { type: 'edit',
              value:createEngineLoader?"": engineDetails && Object.keys(engineDetails).length && engineDetails.forms.length ? engineDetails.forms: '',
              error: '',
              options: engineOption,
              labelKey:'name',
              valueKey: 'id',
              isDisabled: (this.props.params.workOrderSlug && projectsData?.formPermission?.update?.value != 2)? true :false,
              updateForm: (value) => this.updateForm({type: 'forms', value:value, sectionType: 'forms', index:0})
            }
         )
         }
         { this.props.params.workOrderSlug && projectsData?.formPermission?.update?.value != 2 ?
                <div style={{  }} className='general-notes'>
                   Forms can not be edited due to self assigned or no permission for Form update.
                </div>: null
                }
        </Col>
         </Row>
         </div>
          <Nav tabs className="commons-tabs">
            { ['Engine Current Details', 'Engine History', 'Shop Visits', 'MR and Invoice Details', 'Borescope Inspection Details', 'ECTM Details', 'Remarks', 'Attachments', 'Reference Links'].map((label, index) =>
              <NavItem key={index}>
                <NavLink
                  className={
                    classnames({  active: this.state.activeTab === index })
                  }
                  onClick={() => { this.toggle(index); }}
                 >
                  {label}
                </NavLink>
              </NavItem>
            )}
          </Nav>
          <TabContent activeTab={this.state.activeTab}>
            { ['Engine Current Details', 'Engine History', 'Shop Visits', 'MR and Invoice Details', 'Borescope Inspection Details', 'ECTM Details', 'Remarks', 'Attachments', 'Reference Links'].map((label, index) =>
              <TabPane tabId={index} key={index}>
                {engineDetails && Object.keys(engineDetails).length?
                  this.getComponent(index,engineDetails):null}
              </TabPane>
            )}
          </TabContent>
          <Link style={ { pointerEvents : 'auto', opacity: '1',float:'right',marginTop:'20px',marginBottom:'20px'}} onClick={() => {this.updateCreateEngineDetails(engineDetails); }} className="primary-btn"> {'SAVE'} </Link>
        </div>
        <DeleteModal isOpen={this.state.delModal} toggle={() => this.toggleDelModal('')} title="Are you sure to delete ?" className="modal-tform-delete-pictures modal-dialog-centered">
          <ul className="list-inline">
            <li className="list-inline-item">
              <button type="button" className="btn btn-danger" onClick={() => {this.toggleDelModal('');this.props.deleteItems(this.state.deleteId, this.state.deleteType, this.state.deleteIndex,this.state.deleteParentIndex)}}>Yes</button>
            </li>
            <li className="list-inline-item">
              <button type="button" className="btn btn-primary" onClick={() => this.toggleDelModal('')}>No</button>
            </li>
          </ul>
        </DeleteModal>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({

  engineDetails: state.PorjectsReducer.engineDetails,
  projectsData: state.PorjectsReducer.projectsData,
  projectLoader: state.PorjectsReducer.projectLoader,
  engineForms: state.PorjectsReducer.engineForms,
  aircraftTypes: state.sharedReducers.aircraftTypes,
  sEngineTypes: state.sharedReducers.sEngineTypes,
  esnlist:state.PorjectsReducer.esnlist,
  createEngineLoader:state.PorjectsReducer.createEngineLoader,
  attachmentLoader:state.PorjectsReducer.attachmentLoader,
  techConstants: state.sharedReducers.techConstants,
});
const mapDispatchToProps = (dispatch,ownProps) =>{
  return{
    fetchEngineDetails: (type,data=null) => dispatch(fetchEngineDetailsAc(ownProps.params,data,type)),
    fetchArchType: () => dispatch(sArctTypesAc()),
    deleteItems: (id, type, index,childIndex) => dispatch(deleteItemsAc(ownProps,id, type, index,childIndex)),
    fetchExistingEngine:()=>dispatch(fetchExistingEngineAc()),
    fetchProjectsData:()=>dispatch(getProjectInfoAc(ownProps.params)),
    fetchEngine:(esn_slug)=>dispatch(fetchEngineAc(esn_slug)),
    fetchForms: () => dispatch(fetchFormsAc()),
    GenUrlFn: (files, type,index) => dispatch(GenUrlAc(ownProps, files, type,index)),
    fetchEngines: () => dispatch(sEngineTypeAc()),
    fetchTechConstant : (constantTypes) => dispatch(sGlobalConstantAc(constantTypes)),
    updateForm : (data) => dispatch({
      type:  actions.UPDATE_CREATE_ENGINE,
      payload: data
    }),
    updateEngineDetails:()=>dispatch({
      type:  actions.UPDATE_NEW_EMPTY_ENGINE,
      payload: ''
    }),
    addReferenceLink : () => dispatch({
      type:  actions.REFERENCE_FILE_ADD,
      payload: {link : '', description:'', updated_at: moment(), user: { id: userInfo.user.id, name: userInfo.user.name, profile_pic: userInfo.user.profile_pic  }}
    }),
    addShopVisit : () => dispatch({
      type:  actions.ADD_SHOP_VISIT,
      payload: shopVisitObj
    }),
    clearData : () => dispatch({
      type:  actions.CLEAR_ESN_DATA,
      payload: ''
    }),

  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateTechnicalEngine);
