import React from 'react';
import {Paper} from '@material-ui/core';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
require('highcharts/modules/exporting')(Highcharts);
const TeamWiseChart = ({usersStats}) => {
    const chartOptions = {
        chart: {
            type: 'column',
            height: 300,
            options3d: {
                enabled: true,
                alpha: 10,
                beta: 10,
                viewDistance: 55,
                depth: 50
            }
        },
        title: {text: "" },
        xAxis: {
            categories: usersStats.length && usersStats.filter(item => item.open != 0 || item.closed != 0 || item.reopened !== 0).map(item => item.user.name)
        },
        yAxis: {
            allowDecimals: false,
            title: {text: ''},
            stackLabels: {
                enabled: true,
                style: {
                    fontWeight: 'bold',
                    color: ( // theme
                        Highcharts.defaultOptions.title.style &&
                        Highcharts.defaultOptions.title.style.color
                    ) || 'gray',
                    textOutline: 'none'
                }
            }
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                depth: 40,
                dataLabels: {
                    enabled: true
                }
            },
            series: {
              cursor: 'pointer',
              point: {
                events: {
                  click: (e) => {
                    this.graphToTabMove(e.point.category)
                  }
                }
              }
            }
        },
        series: [
            {
                name: 'Open',
                color: '#4b82de',
                data: usersStats.length && usersStats.filter(item => item.open != 0 || item.closed != 0 || item.reopened != 0).map(item => ({ y: item.open, open: item.open, close: item.closed, reopen: item.reopened }))
            }, 
            {
                name: 'Reopened',
                color: '#e86642',
                data: usersStats.length && usersStats.filter(item => item.open != 0 || item.closed != 0 || item.reopened != 0).map(item => ({ y: item.reopened, open: item.open, close: item.closed, reopen: item.reopened }))
            }, 
            {
                name: 'Closed',
                color: '#269b2b',
                data: usersStats.length && usersStats.filter(item => item.open != 0 || item.closed != 0 || item.reopened != 0).map(item => ({ y: item.closed, open: item.open, close: item.closed, reopen: item.reopened }))
            }
            ,
            {
                name: 'Unresolved',
                color: '#dbcc28',
                data: usersStats.length && usersStats.filter(item => item.open != 0 || item.closed != 0 || item.reopened != 0).map(item => ({ y: item.unresolved, open: item.open, close: item.closed, reopen: item.reopened }))
            }
        ],
        credits: {
            enabled: false
        }
    }
    return(
        <Paper>
            <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions}
            />
        </Paper>
    )
}
export default TeamWiseChart;