import React, { Fragment } from 'react';
import { Button, ClickAwayListener, Grow, Paper, Popper, MenuItem, MenuList } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
const IssueListMenu = ({removeSearch, applyFilter, applySearch, onChangeSearch, totalCount, free_text}) => {
  const [open, setOpen] = React.useState(false);
  const [activeStatus, setActiveStatus] = React.useState(null);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
   setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event, value) => {
    if(value !== undefined){
      applyFilter(value)
      setActiveStatus(value)
    }
   if (anchorRef.current && anchorRef.current.contains(event.target)) {
     return;
   }

   setOpen(false);
  };

  function handleListKeyDown(event) {
   if (event.key === 'Tab') {
     event.preventDefault();
     setOpen(false);
   }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
   if (prevOpen.current === true && open === false) {
     anchorRef.current.focus();
   }

   prevOpen.current = open;
  }, [open]);
  return(
    <Fragment>
      <ul className="list-inline flex-centered">
        <li className="list-inline-item issues-menu">
          <Button
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            variant="contained"
            endIcon={<ArrowDropDownIcon />}
            size="small"
            className="issue-btn"
          >
          {activeStatus === 0 ? 'All Open Issues' : activeStatus === 1 ? 'All Closed Issues' : activeStatus === 2 ? 'All Reopen Issues' : activeStatus === 3 ? 'All Unresolved Issues':'All Issues'}
        </Button>
        <Popper style={{zIndex:9999}} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    {
                      activeStatus !== null ?
                      <MenuItem active={activeStatus === null} onClick={(evt) => handleClose(evt, null)}>
                        All Issues
                      </MenuItem>
                      :null
                    }
                    {
                      activeStatus !== 0 ?
                      <MenuItem active={activeStatus === 0} onClick={(evt) => handleClose(evt, 0)}>
                        All Open
                      </MenuItem>
                      :null
                    }
                    {
                      activeStatus !== 1 ?
                      <MenuItem active={activeStatus === 1} onClick={(evt) => handleClose(evt, 1)}>
                        All Closed
                      </MenuItem>
                      :null
                    }
                    {
                      activeStatus !== 2 ?
                      <MenuItem active={activeStatus === 2} onClick={(evt) => handleClose(evt, 2)}>
                        All Reopened
                      </MenuItem>
                      :null
                    }
                    {
                      activeStatus !== 3 ?
                      <MenuItem active={activeStatus === 3} onClick={(evt) => handleClose(evt, 3)}>
                        All Unresolved
                      </MenuItem>
                      :null
                    }
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        </li>
      <li className="list-inline-item">
          <p className="total-count">Total Count: <span>{totalCount}</span></p>
        </li>
        <li className="list-inline-item">
          <input
            type="search"
            id="free-text-search"
            onChange={onChangeSearch}
            value={free_text}
            aria-label="Search content"
            placeholder="Search by code, created-by or category"
          />
        </li>
        <li className="list-inline-item">
          <Button disabled={free_text === null || free_text === ''} style={{marginRight:'5px'}} color="primary" onClick={applySearch} variant="contained" size="small">Search</Button>
          <Button color="primary" variant="outlined" size="small" onClick={removeSearch}>Clear</Button>
        </li>
      </ul>
    </Fragment>
  )
}
export default IssueListMenu;
