import React from 'react';
import {Paper} from '@material-ui/core';
import moment from 'moment';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
require('highcharts/modules/exporting')(Highcharts);
const TaskStatusProgressChart = ({graphData}) => {
  const customToolTip = (points) => {
    let tlTip = `<p>Date: ${moment(points[0].x).format('MMM YYYY')}</p><br/>`;
    points.map(point => {
      tlTip += '<b>'+point.series.name+'</b>:'+point.y+'</p><br/>';
    });
    return tlTip
  }
  const getTimePlot = (data) => {
    // return data.map((item) => {return [new Date(item[0]).getTime(), item[1]]})
  }
  return(
    <Paper style={{clear:'both'}}>
      <HighchartsReact
        highcharts={Highcharts}
        options={graphData}
      />
    </Paper>
  )
}
export default TaskStatusProgressChart;
