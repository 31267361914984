import React, { useState } from "react"
import { TableRow, TableCell, Tooltip, IconButton, Dialog, } from "@material-ui/core"
import { withRouter, browserHistory } from "react-router"
import moment from 'moment'
import EditIcon from "@material-ui/icons/Edit"
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from "@material-ui/icons/Delete"
import { displayDateFormatShort } from "../../../constants"
import { onCheckPermission, getLocalStorageInfo } from "../../../utils_v2";
import { taskAndIssueSelfPermission } from '../'
const IssueList = ({params, item, issuesInfo, onDelete, filter}) => {
    const userInfo = getLocalStorageInfo();
    const redirectDetail = () => {
        let location = browserHistory.getCurrentLocation();
        location = {
          ...location,
          pathname:`/technical/project/${params.project_slug}/issue/${item.id}`,
          query: filter
        }
        browserHistory.push(location);
    }
    return(
        <TableRow style={{cursor:'pointer'}} hover tabIndex={-1} onClick={() => redirectDetail()}>
            <TableCell>
                <div className="multiline-clamp" style={{width:'140px'}} title={item?.name||''}>
                    {item.name}
                </div>
            </TableCell>
            <TableCell>
                {item.status ?
                    <span className={`milestone-status ${item.status.label.toLowerCase()}`}>
                        <span className="flex-centered">
                            {item.status.label}
                        </span>
                    </span>:null
                }
            </TableCell>
            <TableCell>
                <div style={{width:'100px'}}>
                    {item.due_date?moment(item.due_date).format(displayDateFormatShort):''}
                </div>
            </TableCell>
            <TableCell>
                <div className="singleline-clamp" style={{width:'80px'}} title={item?.priority?.label||''}>
                    {item.priority ? item.priority.label : "--"}
                </div>
            </TableCell>
            <TableCell>
                <div className="singleline-clamp" style={{width:'120px'}} title={item?.severity?.label||''}>
                    {item.severity ? item.severity.label : "--"}
                </div>
            </TableCell>
            <TableCell>
                <div className="singleline-clamp" style={{width:'120px'}} title={item?.follower?.name||''}>
                    {item.follower ? item.follower.name : "--"}
                </div>
            </TableCell>
            <TableCell>
                <div className="singleline-clamp" style={{width:'120px'}} title={item?.assign?.name||''}>
                    {item.assign ? item.assign.name : "--"}
                </div>
            </TableCell>
            { onCheckPermission('task_management','stages','R') ?
                <TableCell> 
                    <div className="singleline-clamp" style={{width:'130px'}} title={item?.project_stage?.name||''}>
                        {item.project_stage ? item.project_stage.name : "--"} 
                    </div>
                </TableCell>:null
            }
            
            { onCheckPermission('task_management','milestone','R') ?
                <TableCell> 
                    <div className="singleline-clamp" style={{width:'130px'}} title={item?.project_milestone?.name}>
                        {item.project_milestone ? item.project_milestone.name : "--"} 
                    </div>
                </TableCell>:null
            }
            <TableCell> 
                <div className="singleline-clamp" style={{width:'120px'}} title={item?.work_order?.unique_name||''}>
                    {item.work_order ? item.work_order.unique_name : "--"} 
                </div>
            </TableCell>
            <TableCell>
                <div className="singleline-clamp" style={{width:'80px'}} title={item?.form?.name||''}>
                    {item.form ? item.form.name : "--"}
                </div>
            </TableCell>
            <TableCell> 
                <div className="singleline-clamp" style={{width:'120px'}} title={item?.created_by?.name||''}>
                    {item.created_by.name ? item.created_by.name : "--"} 
                </div>
            </TableCell>
            <TableCell>
                <div className="singleline-clamp" style={{width:'100px'}}>
                    {item.created_at ? moment(item.created_at).format(displayDateFormatShort) : "--"}
                </div>
            </TableCell>
            <TableCell>
                <div style={{width:'70px'}}>
                    <Tooltip title="View/Edit">
                        <IconButton size="small" color="primary" onClick={ () => browserHistory.push(`/technical/project/${params.project_slug}/issue/${item.id}`)}>
                            <VisibilityIcon color="primary" fontSize="small" />
                        </IconButton>
                    </Tooltip>
                    {taskAndIssueSelfPermission('issues','D', item, issuesInfo?.permission?.delete?.value||null) ? 
                        <Tooltip title="Delete">
                            <IconButton size="small" color="primary" onClick={(e) => {e.preventDefault(); e.stopPropagation(); onDelete(item)}} >
                                <DeleteIcon color='secondary' fontSize="small" />
                            </IconButton>
                        </Tooltip>:null
                    }
                </div>
            </TableCell>
        </TableRow>
    )
}
export default withRouter(IssueList);

