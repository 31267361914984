import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { useSnackbar } from "notistack";
import {Paper, Link, TextField, Button } from '@material-ui/core';
import { globalPutService } from '../../../utils_v2/globalApiServices';
import { checkApiStatus, onCheckPermission, getLocalStorageInfo } from '../../../utils_v2';
import EditIcon from '@material-ui/icons/Edit';
import { trackActivity } from '../../../utils/mixpanel';
import { taskAndIssueSelfPermission } from '../';
const  TaskDescription = ({params, taskDetail, permission, cardType}) => {
    const userInfo = getLocalStorageInfo();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const [task, setTask] = useState(taskDetail);
    const [edit, setEdit] = useState(false);
    const onEditTask = () => {
        let payload = Object.assign({},task)
        delete payload['created_by']
        delete payload['updated_by']
        globalPutService(`technical/project/${params.project_slug}/${cardType === 'issue'? 'issue':'tasks'}/${taskDetail.id}/`,payload)
        .then(response => {
            if(checkApiStatus(response)){
                setEdit(false);
                enqueueSnackbar(response.data.message, { variant: "success", anchorOrigin: { horizontal: "right", vertical: "top" }, })
                trackActivity('Task Management', {
                    event_type: 'Update Task Success',
                    page_title: 'Update Task',
                    task_detail_id: taskDetail.id ? taskDetail.id : '',
                    project_slug: params.project_slug,
                    request_body: payload ? payload : {},
                    card_type: cardType === 'issue' ? 'issue':'tasks',
                    response_msg: response.data.message
                  });
            }else{
                enqueueSnackbar(response.data.message, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "top" }, });
                trackActivity('Task Management', {
                    event_type: 'Update Task Failed',
                    page_title: 'Update Task',
                    task_detail_id: taskDetail.id ? taskDetail.id : '',
                    project_slug: params.project_slug,
                    request_body: payload ? payload : {},
                    card_type: cardType === 'issue' ? 'issue':'tasks',
                    error_source: 'Backend',
                    error_msg: response.data.message
                  });
            }
        });
    }
    let canEdit = false;
    if(onCheckPermission('task_management',cardType === 'issue' ? 'issues':'tasks','U')){
        if(permission?.update?.value === 2){
            canEdit = true
        }else{
            if(taskDetail?.created_by?.id && userInfo.user.id === taskDetail.created_by.id){
                canEdit=true
            }
        }
    }
    return (
        <div>
            <Paper className='task-description-section'>
                <h6 className='flex-centered'>
                    <span>Description</span>
                    { !edit && taskAndIssueSelfPermission(cardType === 'issue' ? 'issues':'tasks','U', task, permission?.update?.value||null) ?
                        <EditIcon onClick={()=>setEdit(true)} style={{marginLeft:'10px', cursor:'pointer'}} color='primary' fontSize='small' />:null
                    }
                </h6>
                { edit ?
                    <div>
                        <TextField
                            margin="none"
                            variant="outlined"
                            value={task.description}
                            fullWidth 
                            onChange={(e) => setTask({...task, description:e.target.value})}
                        />
                        <ul style={{marginTop:'10px'}} className='list-inline'>
                            <li style={{marginLeft:'0px'}} className='list-inline-item'>
                                <Button onClick={() => {setTask(taskDetail);setEdit(false)}} color='primary' variant='outlined' size='small'>Cancel</Button>
                            </li>
                            <li className='list-inline-item'>
                                <Button onClick={onEditTask} color='primary' variant='contained' size='small'>Save</Button>
                            </li>
                        </ul>
                    </div>:
                    <div>
                        {task.description}
                    </div>
                }
            </Paper>
        </div>
    )
}

export default withRouter(TaskDescription)