import React, { Fragment, useState } from 'react';
import { Link, withRouter } from 'react-router';
import { useSnackbar } from 'notistack';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { read, utils } from 'xlsx';
import { Button, Dialog, Grid, TextField, DialogActions, DialogContent, DialogTitle, Table, TableHead, TableBody, TableCell, CircularProgress, TableRow, Tabs, Tab, Paper } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { globalExportService, globalGetService, globalPostService } from '../../../utils_v2/globalApiServices';
import { checkApiStatus, downloadFileType } from '../../../utils_v2';
import { regexConstants } from '../../../constants/regEx';
import { DropzoneArea } from 'material-ui-dropzone';
const ImportAvionicsTemplate = ({ params, getResponseBack }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [tabIndex, setTabIndex] = useState(0);
    const [modal, setModal] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [importCrud, setImportCrud] = useState({ template: null });
    const [templates, setTemplates] = useState([])
    const [pres, setPres] = useState([]);
    const getFormTemplates = () => {
        globalGetService(`technical/templates/?asset_type=${params.type}`, { type: 'T014' })
            .then(response => {
                if (checkApiStatus(response)) {
                    setTemplates(response.data.data.list)
                }
            })
    }
    const expectedFileHeaders = ['ATA Chapter','Quantity','Manufacturer','Part No.','Family Code','Description'];
    const onChangeTemplate = (template) => {
        setImportCrud({ template });
        setLoading(true);
        if (template) {
            (async () => {
                const f = await (await fetch(template.file_path)).arrayBuffer();
                const wb = read(f);
                const ws = wb.Sheets['Avionics'];
                const data = utils.sheet_to_json(ws, { blankRows: false });
                const headers = Object.keys(data[0] || {});
                const validFile = expectedFileHeaders.some(header => headers.includes(header));
                if (!validFile) {
                    enqueueSnackbar('Unable to Import the file . Please check for exact column names as per sample template & data exists in the sheet', { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } , autoHideDuration: 9000 });
                    setPres([]);
                    setLoading(false);
                    return;
                }
                const updatedData = data.map(row => ({
                    chapter: row['ATA Chapter'] || '',
                    quantity: row['Quantity'] || null,
                    manufacturer: row['Manufacturer'] || '',
                    part_no: row['Part Number'] || '',
                    family_code: row['Family Code'] || '',
                    description: row['Description'] || ''
                }));
                setPres(updatedData);
                setLoading(false);
            })()
        } else {
            setPres([]);
            setLoading(false);
        }
    }
    const onFieldChange = (e, keyParam, value, index) => {
        setPres(pres.map((item, itemIndex) => itemIndex !== index ? item : { ...item, [keyParam]: value }))
    }
    const onRemove = (index) => {
        setPres(pres.filter((item, itemIndex) => itemIndex !== index))
    }
    const onSetupTemplate = () => {
        setLoading(true);
        globalPostService(`technical/workorder/${params.workOrderSlug}/t014/avioinc-configurations/?type=T014&asset_type=${params.type}`, { configurations: pres })
            .then(response => {
                setLoading(false);
                if (checkApiStatus(response)) {
                    enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    getResponseBack();
                    setModal(false);
                    setImportCrud({ template: null });
                    setPres([]);
                } else {
                    enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' }, autoHideDuration: 9000 })
                }
            })
    }
    const downloadSampleTemplate = () => {
        globalExportService(`technical/workorder/t014/sampleDownloadAvionics`)
            .then(response => {
                downloadFileType(response, 'T014_Avionics_Sample_Template.', 'xls')
            })
    }
    const handleFile =
        async (files) => {
            if (files.length === 0) return;
            setLoading(true);
            const file = files[0];
            const data = await file.arrayBuffer();
            const wb = read(data);
            const ws = wb.Sheets['Avionics'];
            const jsonData = utils.sheet_to_json(ws, { blankRows: false });
            const headers = Object.keys(jsonData[0] || {});
            const validFile = expectedFileHeaders.some(header => headers.includes(header));
            if (!validFile) {
                enqueueSnackbar('Unable to Import the file . Please check for exact column names as per sample template & data exists in the sheet', { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' },  autoHideDuration: 9000 });
                setPres([]);
                setLoading(false);
                return;
            }
            const updatedData = jsonData.map(row => ({
                chapter: row['ATA Chapter'] || '',
                quantity: row['Quantity'] || null,
                manufacturer: row['Manufacturer'] || '',
                part_no: row['Part Number'] || '',
                family_code: row['Family Code'] || '',
                description: row['Description'] || ''
            }));
            setPres(updatedData);
            setLoading(false)
        }
    return (
        <Fragment>

            <Button onClick={() => { getFormTemplates(); setModal(true);  setTabIndex(0) }} color='primary' variant='outlined' size='small'>Import Template</Button>
            {modal ?
                <Dialog
                    open={modal}
                    onClose={() => { setModal(false) }}
                    className=' project-modal'
                    maxWidth={false}
                >
                    <DialogTitle id="scroll-dialog-title">
                        Import Template
                    </DialogTitle>
                    <DialogContent dividers={true} style={{padding:'8px'}} >
                        <div style={{ width: '780px', minHeight: '400px' }}>
                            <Paper>
                                <Tabs
                                    value={tabIndex}
                                    onChange={(event, newValue) => { setPres([]); setTabIndex(newValue); setImportCrud({ template: null }) }}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="fullWidth"
                                >
                                    <Tab label={<span>Custom Template</span>} />
                                    <Tab label={<span>Excel Import</span>} />
                                </Tabs>
                            </Paper>
                            {tabIndex === 0 ?
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={6}>
                                        <Autocomplete
                                            options={templates}
                                            getOptionLabel={option => option.name}
                                            id="template"
                                            value={importCrud.template}
                                            onChange={(e, value) => onChangeTemplate(value)}
                                            renderInput={params => <TextField required {...params} label="Select Template" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                        />
                                    </Grid>
                                    <Grid item sm={6} style={{ padding: '20px 0px 0px 7px' }} >
                                        or &nbsp;
                                        <Link to='/technical/form/templates/'>
                                            <Button variant='contained' color='primary'>
                                                Add New Template
                                            </Button>
                                        </Link>
                                    </Grid>

                                </Grid> :
                                <div>
                                    <div >
                                        {!pres.length ?
                                            <DropzoneArea
                                                acceptedFiles={['.xls, .xlsx,']}
                                                filesLimit={1}
                                                maxFileSize={104857600}
                                                showPreviewsInDropzone={false}
                                                useChipsForPreview={true}
                                                showPreviews={true}
                                                dropzoneText={<p style={{ fontSize: '13px' }}>Drag & Drop Documents<br /> OR <br /> Click Here<br /> </p>}
                                                dropzoneClass="drop_zone"
                                                maxWidth="xl"
                                                showAlerts={['info', 'error']}
                                                alertSnackbarProps={{ anchorOrigin: { vertical: 'top', horizontal: 'right' } }}
                                                clearOnUnmount={true}
                                                onChange={(files) => handleFile(files)}
                                            /> : null}
                                    </div>
                                    {!pres?.length ? <span style={{ fontSize: '11px' }} className='files-upload-limit' > Note : Supported extension ('.xls, .xlsx').</span> : null}
                                    {!pres?.length ?
                                        <Grid>
                                            <Link style={{ color: 'blue', fontSize: '11px', textDecoration: 'underline' }} onClick={downloadSampleTemplate}>Download Sample</Link>
                                        </Grid> : null}
                                </div>
                            }
                            <Grid container spacing={1}>
                                {
                                    isLoading ?
                                        <Grid item sm={12}>
                                            <div style={{ textAlign: 'center', marginTop: '45px' }} >
                                                <CircularProgress size={40} />
                                            </div>
                                        </Grid> : null
                                }
                                <Grid item md={12} style={{marginTop:tabIndex===1?'5px':''}}>
                                    {pres.length ?
                                        <Table className='mui-table-format'>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>ATA Chapter</TableCell>
                                                    <TableCell>Quantity</TableCell>
                                                    <TableCell>Manufacturer</TableCell>
                                                    <TableCell>Part Number</TableCell>
                                                    <TableCell>Family Code</TableCell>
                                                    <TableCell>Description</TableCell>
                                                    <TableCell>Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {pres.map((pres, index) => (
                                                    <TableRow>
                                                        <TableCell>
                                                            <TextField
                                                                id={`chapter_${index}`}
                                                                value={pres.chapter}
                                                                fullWidth
                                                                margin="normal"
                                                                onChange={(e) => onFieldChange(e, 'chapter', e.target.value, index)}
                                                                InputLabelProps={{ shrink: true }}
                                                                variant='outlined'
                                                                style={{marginTop: '0px'}}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField
                                                                id={`quantity_${index}`}
                                                                value={pres.quantity ? pres.quantity : ''}
                                                                fullWidth
                                                                margin="normal"
                                                                onChange={(e) => regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'quantity', e.target.value, index) : e.preventDefault()}
                                                                InputLabelProps={{ shrink: true }}
                                                                variant='outlined'
                                                                style={{marginTop: '0px'}}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField
                                                                name='manufacturer'
                                                                id={`manufacturer_${index}`}
                                                                value={pres.manufacturer || ''}
                                                                fullWidth
                                                                margin="normal"
                                                                onChange={(e) => onFieldChange(e, 'manufacturer', e.target.value, index)}
                                                                variant='outlined'
                                                                style={{marginTop: '0px'}}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField
                                                                name='part_no'
                                                                id={`part_no_${index}`}
                                                                value={pres?.part_no || ''}
                                                                fullWidth
                                                                margin="normal"
                                                                onChange={(e) => onFieldChange(e, 'part_no', e.target.value, index)}
                                                                variant='outlined'
                                                                style={{marginTop: '0px'}}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField
                                                                name='family_code'
                                                                id={`family_code_${index}`}
                                                                value={pres?.family_code || ''}
                                                                fullWidth
                                                                margin="normal"
                                                                onChange={(e) => onFieldChange(e, 'family_code', e.target.value, index)}
                                                                variant='outlined'
                                                                style={{marginTop: '0px'}}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField
                                                                name='description'
                                                                id={`description_${index}`}
                                                                value={pres?.description || ''}
                                                                fullWidth
                                                                margin="normal"
                                                                onChange={(e) => onFieldChange(e, 'description', e.target.value, index)}
                                                                variant='outlined'
                                                                style={{marginTop: '0px'}}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            {pres.length !== 1 ?
                                                                <DeleteOutlineIcon color='secondary' onClick={() => onRemove(index)} /> : null
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                                }
                                            </TableBody>
                                        </Table> : null
                                    }
                                </Grid>
                            </Grid>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={isLoading} onClick={() => { setModal(false); setPres([]); setImportCrud({template:null}) }} color="primary" size='small' variant='outlined'>Cancel</Button>
                        {pres.length ?
                            <Button disabled={isLoading} onClick={onSetupTemplate} color="primary" size='small' variant='contained'>
                                {isLoading ? <CircularProgress size={20} /> : 'Proceed'}
                            </Button> : null
                        }
                    </DialogActions>
                </Dialog> : null
            }
        </Fragment>
    )
}
export default withRouter(ImportAvionicsTemplate);