import React, { Fragment } from 'react';
import { withRouter } from 'react-router';
import { CircularProgress } from '../../../shared_elements';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { onCheckPermission } from '../../../utils_v2'
import { projectsSelfPermission } from '../'
const assetTypes = {
  3: 'APU',
  4: 'Landing Gear',
  5: 'Propeller'
}
const WorkOrderAsset = ({project, icon, serialNo, type, progress, onEditWorkOrder,work_order_number,onDeleteWorkOrder, workOrder, permission}) => {
  return(
    <div className="work-order-asset flex-centered">
      <CircularProgress value={progress} />
      <div className="asset-info">
        <div style={{marginLeft:'6px'}}>
         { type ? <p>{type}</p>:null}
          <h4>
            {serialNo}
            { onCheckPermission('project_management', 'workorder','U') && projectsSelfPermission('workorder','U', workOrder, permission?.update?.value||null)  && (project?.status?.id && ![0,7,8].includes(project.status.id)) ? <span onClick={(e) => {e.preventDefault(); e.stopPropagation(); onEditWorkOrder()}}>Edit<EditIcon color="primary" fontSize='small' /></span>:null}
            { onCheckPermission('project_management', 'workorder','D') && projectsSelfPermission('workorder','D', workOrder, permission?.delete?.value||null)  ? <span onClick={(e) => {e.preventDefault(); e.stopPropagation(); onDeleteWorkOrder()}} >Delete<DeleteOutlinedIcon color="secondary" fontSize='medium' /></span>:null}
          </h4>
         <p>{ work_order_number ?  'WO No.' + ' ' + work_order_number : ''}</p>
        </div>
      </div>
    </div>
  )
}
export default withRouter(WorkOrderAsset);
