import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router'
import {  fieldDateFormat, backendDateFormat, onlyNumeric } from '../../../../../constants';
import 'react-select/dist/react-select.css';
import { DropzoneArea } from 'material-ui-dropzone';
import { MuiPickersUtilsProvider,KeyboardDatePicker } from '@material-ui/pickers';
import { Grid, TextField, Button,} from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import MomentUtils from '@date-io/moment';
import CKeditor from "../../../../../shared/CKEditor";
import moment from 'moment';
import { FieldLabel } from '../../../Elements_V';
import { updateTechnicalFormDataApi, getTableSectionsApi } from '../apiServices';
import { toastFlashMessage } from '../../../../../utils';
const filter = createFilterOptions();
class TechnicalInspectionFormV1 extends Component{
  constructor(props){
    super(props);
    this.state = {
      pageLoader:false,
      error:{},
    }
    this.updateTechnicalFormDataApi = updateTechnicalFormDataApi.bind(this);
    this.getTableSectionsApi = getTableSectionsApi.bind(this);
  }

  addTechnicalInspectionForm = (update) => {
    const item = this.props.itemData
    const attachment = this.props.attachment
    let fileSize =attachment.reduce((total, item) => total + item.size, 0 )
    console.log('fileSize', fileSize);
    const validateNewInput = {
       description: !item.description ? "Please enter Description":'',
       title: !item.title ? "Please enter Title":'',
       category: !item.category ? "Please select Category":'',
       status: !item.status ? "Please select Status":'',
       attachment: fileSize > 131072000 ?  "Total size cannot exceed 125 MB" : ''

    };
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      this.setState({ pageLoader: true });
      let payload = item
      payload = {
        ...payload,
        priority:payload.priority ? payload.priority.value : null,
        assignee: payload.assignee ? payload.assignee.id : null,
        category: payload.category ? payload.category : null
      }
      this.setState({error: {}})
      this.props.updateTechnicalFormDataApi(this.props, payload, item.category.id, update)
    }else{
      this.setState({
        error: validateNewInput
      })
    }
  }
  updateErrorField = (keyParam) => {
    this.setState(prevState => ({
      ...prevState,
      error  :{
        ...prevState.error,
        [keyParam] : ''
      }
    }))
  }

  render(){
    const { error, pageLoader } = this.state;
    const item = this.props.itemData
    const { userList, t011ReportInfo, closeTechForm, attachment } = this.props;
    console.log('error',error);
    return(
      <Fragment>
        <Fragment>
        <div style={{ height: (window.innerHeight -250) + 'px'}}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                  id="title"
                  label='Issue Title'
                  fullWidth
                  margin="normal"
                  required={true}
                  inputProps={{ maxLength: 50 }}
                  InputLabelProps={{shrink: true}}
                  value={item.title}
                  onChange={(e) =>  {this.props.onFieldChange(e,'title', e.target.value); this.updateErrorField('title')}}
                  error={error.title ? true :false}
                  helperText={error.title ? error.title: ''}
                />
            </Grid>
            <Grid item xs={12}>
                <label className="label" style={{marginBottom: '5px', color: 'rgba(0, 0, 0, 0.54)'}}>Description<sup style={error.description ? {color:'red'} :{}}>*</sup></label>
                <div style={error.description ? {border: '1px solid red'} : {}}>
                  <CKeditor
                    className="ck-editor"
                    events={{"change": (e) => {this.props.updateCkeditor(e, 'description'); this.updateErrorField('description')}}}
                    content={item.description ? item.description:''}
                  />
                </div>
                  <h6 className="error-msg">{error.description ? error.description :''}</h6>
              </Grid>
              <Grid item md={6} xs={12}>
                <Autocomplete
                  options = {[{label:'None',value:0},{label:'P1',value:1},{label:'P2',value:2}]}
                  clearOnBlur={false}
                  value={item.priority ? item.priority : null }
                  getOptionLabel={option => option.label}
                  filterSelectedOptions={true}
                  onChange={(e,priority) => this.props.onFieldChange(e,'priority', priority )}
                  renderInput={params => <TextField {...params} label="Priority" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Autocomplete
                  options = {[{label:'Open',value:'0'},{label:'Closed',value:'1'}, {label:'Reopen',value:'2'}]}
                  clearOnBlur={false}
                  value={item.status ? item.status : null }
                  getOptionLabel={option => option.label}
                  filterSelectedOptions={true}
                  onChange={(e,priority) => this.props.onFieldChange(e,'status', priority )}
                  renderInput={params => <TextField {...params} label="Status" margin="normal" fullWidth InputLabelProps={{shrink: true}} error={error.status} required/>}
                />
              <h6 className="error-msg">{error.status ? error.status :''}</h6>
              </Grid>
              <Grid item md={6} xs={12}>
                <Autocomplete
                  options = {userList}
                  clearOnBlur={false}
                  value={item.assignee ? item.assignee : null }
                  getOptionLabel={option => option.name}
                  filterSelectedOptions={true}
                  onChange={(e,assignee) => this.props.onFieldChange(e,'assignee', assignee)}
                  renderInput={params => <TextField {...params} label="Assignee" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                />
              </Grid>
              <Grid item md={6} xs={12} >
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                      margin="normal"
                      id="started_date"
                      label="Discrepancy Raised on"
                      placeholder="DD-MM-YYYY"
                      format={fieldDateFormat}
                      fullWidth
                      disableFuture={true}
                      minDate={moment().subtract(25, 'years')}
                      InputLabelProps={{shrink: true}}
                      value={item.started_date !== undefined && item.started_date !== null && item.started_date !== "" ? item.started_date: null }
                      onChange={(data, value) => this.props.onFieldChange(value, 'started_date', data ? moment(data).format(backendDateFormat): data)}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item md={6} xs={12} >
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                      margin="normal"
                      id="estimated_completion_date"
                      label="Discrepancy Resolved on"
                      placeholder="DD-MM-YYYY"
                      format={fieldDateFormat}
                      fullWidth
                      minDate={item.started_date ?  moment(item.started_date) : moment().subtract(25, 'years')}
                      disableFuture={true}
                      InputLabelProps={{shrink: true}}
                      value={item.estimated_completion_date ? item.estimated_completion_date : null }
                      onChange={(data, value) => this.props.onFieldChange(value, 'estimated_completion_date', data ? moment(data).format(backendDateFormat): data)}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item md={6} xs={12}>
                <Autocomplete
                  options = {t011ReportInfo.list ? t011ReportInfo.list.map(cat => {
                    return {value: cat.id, label: cat.name}
                  }):[]}
                  clearOnBlur={false}
                  disableClearable={true}
                  multiple={false}
                  value={item.category ? item.category : null }
                  getOptionLabel={option => option.label}
                  filterSelectedOptions={true}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    // Suggest the creation of a new value
                    if (params.inputValue !== '') {
                      filtered.push({
                        inputValue: params.inputValue,
                        label: `${params.inputValue}`,
                      });
                    }
                    return filtered;
                  }}
                  onChange={(...args) => {
                    let category = args[1]
                    category = {...category, label: category.label.substr(category.label.lastIndexOf('Add ') + 1)}
                    setTimeout(() => {
                      this.props.onFieldChange(null,'category', category )
                    }, 1000)
                  }}
                  renderInput={params => <TextField {...params} label="Category" margin="normal" fullWidth InputLabelProps={{shrink: true}} error={error.category} required/>}
                />
              <h6 className="error-msg">{error.category ? error.category :''}</h6>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  id="ata"
                  label='ATA'
                  fullWidth
                  margin="normal"
                  inputProps={{ maxLength: 10 }}
                  InputLabelProps={{shrink: true}}
                  value={item.ata}
                  onChange={(e) =>  this.props.onFieldChange(e,'ata', e.target.value)}
                  error={error.ata ? true :false}
                  helperText={error.ata ? error.ata: ''}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  id="cycles_remaining"
                  label='Cycles Remaining'
                  fullWidth
                  margin="normal"
                  inputProps={{ maxLength: 10 }}
                  InputLabelProps={{shrink: true}}
                  value={item.cycles_remaining}
                  onChange={(e) => {onlyNumeric.test(e.target.value) ? this.props.onFieldChange(e,'cycles_remaining', e.target.value):e.preventDefault()} }
                  error={error.cycles_remaining ? true :false}
                  helperText={error.cycles_remaining ? error.cycles_remaining: ''}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  id="part_number"
                  label='Part Number'
                  fullWidth
                  margin="normal"
                  inputProps={{ maxLength: 10 }}
                  InputLabelProps={{shrink: true}}
                  value={item.part_number}
                  onChange={(e) =>  this.props.onFieldChange(e,'part_number', e.target.value)}
                  error={error.part_number ? true :false}
                  helperText={error.part_number ? error.part_number: ''}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  id="serial_number"
                  label='Serial Number'
                  fullWidth
                  margin="normal"
                  inputProps={{ maxLength: 10 }}
                  InputLabelProps={{shrink: true}}
                  value={item.serial_number}
                  onChange={(e) => this.props.onFieldChange(e,'serial_number', e.target.value)}
                  error={error.serial_number ? true :false}
                  helperText={error.serial_number ? error.serial_number: ''}
                />
              </Grid>
              <Grid item md={6} xs={12} >
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                      margin="normal"
                      id="install_date"
                      label="Install Date"
                      placeholder="DD-MM-YYYY"
                      format={fieldDateFormat}
                      fullWidth
                      disableFuture={true}
                      InputLabelProps={{shrink: true}}
                      value={item.install_date ? item.install_date: null }
                      onChange={(data, value) => this.props.onFieldChange(value, 'install_date', data ? moment(data).format(backendDateFormat): data)}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  id="position"
                  label='Position'
                  fullWidth
                  margin="normal"
                  inputProps={{ maxLength: 30 }}
                  InputLabelProps={{shrink: true}}
                  value={item.position ? item.position : ''}
                  onChange={(e) => this.props.onFieldChange(e,'position', e.target.value)}
                  error={error.position ? true :false}
                  helperText={error.position ? error.position: ''}
                />
              </Grid>
              <Grid item xs={12}>
                <FieldLabel className="label" style={{marginBottom: '5px'}}>Requirement Discrepancy</FieldLabel>
                  <CKeditor
                    className="ck-editor"
                    events={{"change": (e) => this.props.updateCkeditor(e, 'requirement_remarks')}}
                    content={item.requirement_remarks ? item.requirement_remarks : ''}
                  />
              </Grid>
              <Grid item xs={12} style={{position: 'relative',bottom: '100px',paddingTop: '100px'}}>
                <FieldLabel className="label" style={{marginBottom: '5px'}}>Background Discrepancy</FieldLabel>
                  <CKeditor
                    className="ck-editor"
                    events={{"change": (e) => this.props.updateCkeditor(e, 'background_remarks')}}
                    content={item.background_remarks ? item.background_remarks : ''}
                  />
              </Grid>
              <Grid item xs={12}>
                <div style={{width:'100%', marginTop:'-90px'}}> 
                  <ul className="list-inline flex-centered">
                    <li className="list-inline-item dropable-zone" style={{border: error.attachment ? '1px solid tomato' : ''}} >
                      <DropzoneArea
                        filesLimit={10}
                        maxFileSize={131072000}
                        showPreviewsInDropzone={false}
                        useChipsForPreview={true}
                        acceptedFiles={['.png, .jpg, .jpeg, .pdf, .xls, .xlsx, .doc, .docx']}
                        showPreviews={ true }
                        dropzoneText={error.attachment ? <p  style={{color:'tomato', fontSize:'12px'}} >{error.attachment}</p> :  <p>Drag & Drop Documents<br/> OR <br/> Click Here<br/> <span style={{fontSize:'12px', position: "absolute",bottom: "1px",left: "1px"}}>maximum 10 files</span></p>}
                        dropzoneClass="drag-drop-cnt"
                        maxWidth="sm"
                        showAlerts={['info']}
                        onChange={(e) =>  {this.props.addAttachment(e); this.updateErrorField('attachment') }}
                        getDropRejectMessage={() =>  toastFlashMessage('Total size cannot exceed 125 MB', "error")}
                        getFileLimitExceedMessage={() =>  toastFlashMessage('You can upload a maximum of 10 files', 'error')}
                        alertSnackbarProps={{anchorOrigin: {vertical:'top', horizontal:'right'}}}
                        clearOnUnmount={true}
                      />
                    </li>
                  </ul>
                </div>

              </Grid> 
          </Grid>
      </div>
      <div className="submit-block">
        <Button variant="contained" color="primary" onClick={() => this.addTechnicalInspectionForm(false)} >Add</Button>
        <Button variant="contained" color="primary" style={{margin:'0px 8px'}} onClick={() => this.addTechnicalInspectionForm(true)} > Save &amp; Add Another</Button>
        <Button color="primary" onClick={closeTechForm}>Cancel</Button>
      </div>
      </Fragment>
        {/* <UploadExcel formInfo={this.props.formInfo} sectionId={sectionId} getTableContentsApi={() => this.props.getTableContentsApi()} exportReport={() => this.props.exportReport()} closeTechForm={closeTechForm}/>  */}
    </Fragment>
    )
  }
}

export default withRouter(TechnicalInspectionFormV1);
