import React, { useEffect, useState} from 'react';
import { withRouter } from 'react-router';
import { useSnackbar } from 'notistack';
import { Paper, TextField, Grid, Button, IconButton, Tooltip } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider,KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { CkEditorText, PageLoader } from '../../../shared_elements';
import { ReadPrerequisite } from '../../Elements'
import EditIcon from '@material-ui/icons/Edit';
import PrerequisiteContent from './PrerequisiteContent'
import { fieldDateFormat, backendDateFormat } from '../../../constants';
import { globalGetService, globalPutService } from '../../../utils_v2/globalApiServices';
import { checkApiStatus } from '../../../utils_v2';
const GeneralDetail = ({currentFormDetail, params, getCurrentFormDetail}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setLoading] = useState(false);
  const [isEditAble, setEditAble] = useState(false);
  const [error, setError] = useState({});
  const [generalDetail, setGeneralDetail] = useState({});
  const canEdit = currentFormDetail?.current_form?.permissions?.can_save;
  useEffect(() => {
    getGeneralDetails();
  }, []);
  const onFieldChange = (key, value) => {
    setGeneralDetail({...generalDetail,[key]:value})
  }
  const getGeneralDetails = () => {
    globalGetService(`technical/v2/workorder/${params.workOrderSlug}/built_standard_evaluation/`)
    .then(response => {
      if(checkApiStatus(response)){
        setGeneralDetail(response.data.data);
      }else{
        if(response.data.statusCode === 404){
          setGeneralDetail({type_of_report:null, place_of_inspection:'', amm_revision_number:'', amm_revision_date:null, date_of_inspection_from:null, date_of_inspection_to:null, report_date:null, report_issued_place:''})
        }
      }
    })
  }
  const onUpdateGeneralDetails = () => {
    let validationInputs = {
      report_date:generalDetail?.report_date ? moment(generalDetail.report_date).isValid() ? '':'Please enter valid Date':'Please enter Report Date',
      report_issued_place:generalDetail?.report_issued_place?.trim()?.length ? '':'Please enter Report Issued Place',
    }
    if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
      setLoading(true);
      globalPutService(`technical/v2/workorder/${params.workOrderSlug}/built_standard_evaluation/`, generalDetail)
      .then(response => {
        if(checkApiStatus(response)){
          enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
          getGeneralDetails();
          setEditAble(false);
          getCurrentFormDetail('pageLoader');
        }
        setLoading(false);
      })
    }else{
      setError(validationInputs);
    }
  }
  return(
    <Paper className="form-detail-card">
      <p className='edit-details'>
        <ReadPrerequisite content={<PrerequisiteContent />} />
        {canEdit && !isEditAble ? 
          <span className='edit-g'>
            <Tooltip title='Edit General Detail' arrow>
              <IconButton size="small"><EditIcon onClick={() => setEditAble(true)} fontSize='small' color='primary' /></IconButton>
            </Tooltip>
          </span>:null
        }
      </p>
      <div className='scrollable-content' style={{height: `${window.innerHeight- (isEditAble ? 265:225)}px`, overflow:'auto'}}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={3}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                disabled={!isEditAble}
                margin="normal"
                id="report_date"
                label="Report Date"
                format={fieldDateFormat}
                fullWidth
                required
                disableFuture={true}
                InputLabelProps={{shrink: true}}
                value={generalDetail?.report_date||null}
                error={error?.report_date}
                helperText={error?.report_date||''}
                onChange={(data, value) => {onFieldChange('report_date', data ? moment(data).format(backendDateFormat):data); setError({...error,'report_date':''})}}
                inputVariant="outlined"
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              disabled={!isEditAble}
              id="report_issued_place"
              label="Report Issued Place"
              fullWidth
              required
              margin="normal"
              inputProps={{maxLength: 30}}
              value={generalDetail?.report_issued_place||''}
              error={error?.report_issued_place}
              helperText={error?.report_issued_place||''}
              InputLabelProps={{shrink: true}}
              onChange={(e) => onFieldChange('report_issued_place', e.target.value)}
              onFocus={(e) => setError({...error,'report_issued_place':''})}
              variant="outlined"
            />
          </Grid>
          <Grid item xs='12'>
            <div className='content-editor'>
              <label>Background</label>
              <CkEditorText
                  disabled={!isEditAble}
                  htmlData={generalDetail?.background||''}
                  onBlurData={(data) => onFieldChange('background', data)}
              />
            </div>
          </Grid>
          <Grid item xs='12'>
            <div className='content-editor'>
              <label>Documents Reviewed</label>
              <CkEditorText
                  disabled={!isEditAble}
                  htmlData={generalDetail?.documents_reviewed||''}
                  onBlurData={(data) => onFieldChange('documents_reviewed', data)}
              />
            </div>
          </Grid>
          <Grid item xs='12'>
            <div className='content-editor'>
              <label>Executive Summary Observation</label>
              <CkEditorText
                  disabled={!isEditAble}
                  htmlData={generalDetail?.executive_summary_observation||''}
                  onBlurData={(data) => onFieldChange('executive_summary_observation', data)}
              />
            </div>
          </Grid>
          <Grid item xs='12'>
            <div className='content-editor'>
              <label>Executive Summary Recommendation</label>
              <CkEditorText
                  disabled={!isEditAble}
                  htmlData={generalDetail?.executive_summary_recommendation||''}
                  onBlurData={(data) => onFieldChange('executive_summary_recommendation', data)}
              />
            </div>
          </Grid>
          <Grid item xs='12'>
            <div className='content-editor'>
              <label>Advisory Report Observation</label>
              <CkEditorText
                  disabled={!isEditAble}
                  htmlData={generalDetail?.advisory_report_observation||''}
                  onBlurData={(data) => onFieldChange('advisory_report_observation', data)}
              />
            </div>
          </Grid>
          <Grid item xs='12'>
            <div className='content-editor'>
              <label>Advisory Report Recommendation</label>
              <CkEditorText
                  disabled={!isEditAble}
                  htmlData={generalDetail?.advisory_report_recommendation||''}
                  onBlurData={(data) => onFieldChange('advisory_report_recommendation', data)}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      { isEditAble ?
        <div className='actions-cta'>
          <ul className='list-inline'>
            <li className='list-inline-item'>
              <Button onClick={onUpdateGeneralDetails} size='small' color='primary' variant='contained'>Save</Button>
            </li>
            <li className='list-inline-item'>
              <Button onClick={() => {setEditAble(false);getGeneralDetails(); setError({})}} size='small' color='primary' variant='outlined'>Cancel</Button>
            </li>
          </ul>
        </div>:null
      }
      { isLoading ? <PageLoader />:null }
    </Paper>
  )
}
export default withRouter(GeneralDetail);