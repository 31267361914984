import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router';
import { useSnackbar } from 'notistack';
import { Button, TableRow, TableCell, Paper, Table, TableHead, TableBody, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Checkbox, CircularProgress, Tooltip, IconButton } from '@material-ui/core';
import { EmptyCollection, DeletePopUp, PageLoader, Pagination, STableLoader } from '../../../shared_elements';
import ShopVisitCRU from './ShopVisitCRU';
import moment from "moment";
import { globalDeleteService, globalGetService, globalPostService, globalPutService } from "../../../utils_v2/globalApiServices";
import { checkApiStatus, getLocalStorageInfo } from "../../../utils_v2";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import AttachmentIcon from '@material-ui/icons/Attachment';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { displayDateFormatShort } from "../../../constants";
const shopVisitObj = {
  shop_visit_number: '',
  date_of_removal: null,
  reason_for_removal: '',
  tsn: null,
  csn: null,
  tslsv: null,
  cslsv: null,
  tso: null,
  cso: null,
  tshi: null,
  cshi: null,
  tsr: null,
  csr: null,
  shop_facility: '',
  induction_date: null,
  final_workscope: '',
  build_target: '',
  test_run_egt_margin: '',
  llp_status: '',
  arc_date: null,
  total_air_temperature: '',
  date_of_reinstallation: null,
  status: null,
  attachments:[],
  added_from:1
}
const AddEditShopVisit = ({params, currentFormDetail, addEditShopVisit, toggleModalFn, getResponseBack}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [shopVisit, setShopVisit] = useState(addEditShopVisit.data)
  const [deleteFiles, setDeleteFiles] = useState([]);
  const onFieldChange = (key, value) => {
    setShopVisit({...shopVisit, [key]:value});
  }
  const onAddEditShopVisit = () => {
    let validationInputs = {
      shop_visit_number:shopVisit?.shop_visit_number?.trim()?.length ? '':'Please enter Shop Visit Number',
      date_of_removal:shopVisit?.date_of_removal ? moment(shopVisit.date_of_removal).isValid() ? '':'Please enter valid Date':'',
      induction_date:shopVisit?.induction_date ? moment(shopVisit.induction_date).isValid() ? '':'Please enter valid Date':'',
      arc_date:shopVisit?.arc_date ? moment(shopVisit.arc_date).isValid() ? '':'Please enter valid Date':'',
      date_of_reinstallation:shopVisit?.date_of_reinstallation ? moment(shopVisit.date_of_reinstallation).isValid() ? '':'Please enter valid Date':'',
    }
    if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
      setLoading(true);
      if(shopVisit.id){
        globalPutService(`technical/v2/workorder/${params.workOrderSlug}/shop-visit/${shopVisit.id}/`, shopVisit)
        .then(response => {
          if(checkApiStatus(response)){
            if(deleteFiles.length){
              onDeleteAttachments(response.data.message)
            }else{
              enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
              toggleModalFn(); getResponseBack();
            }
          }else{
            enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
          }
          setLoading(false);
        })
      }else{
        globalPostService(`technical/v2/workorder/${params.workOrderSlug}/shop-visit/`, shopVisit)
        .then(response => {
          if(checkApiStatus(response)){
            if(deleteFiles.length){
              onDeleteAttachments(response.data.message)
            }else{
              enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
              toggleModalFn(); getResponseBack();
            }
          }else{
            enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
          }
          setLoading(false);
        })
      }
    }else{
      setError(validationInputs);
    }
  }

  const onDeleteAttachments = (message) => {
    globalPostService(`technical/v2/workorder/${params.workOrderSlug}/file-remove/`,{ paths:deleteFiles,model:'shop-visit'})
    .then(response => {
      if(checkApiStatus(response)){
        enqueueSnackbar(message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
        toggleModalFn(); getResponseBack();
        setDeleteFiles([]);
      }
    })
  }
  const uploadAttachments = (files) => {
    if(files.length < 11){
      let formData = new FormData();
      formData.append('shop_visit', true);
      for(let i=0;i<files.length;i++){
        formData.append('files[]', files[i])
      }
      
      setLoading(true)
      globalPostService(`technical/engine-workorder/${params.workOrderSlug}/file-upload/`, formData)
      .then(response => {
        if(checkApiStatus(response)){
          const user = getLocalStorageInfo()?.user || null
          let attachment = response?.data?.data.map((item) => {
            return {
              attachment:item.url,
              name:item.name,
              created_at:moment(),
              user:{id:user?.id||null, name:user?.name||''},
              description:''
            }
          })
          setShopVisit({...shopVisit, attachments:[...shopVisit.attachments, ...attachment]})
        }
        setLoading(false);
      })
    }else{
      enqueueSnackbar('Exceed max files upload limit', { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
    }
    
  }
  const onRemoveAttachment = (attachment) => {
    let filePath = decodeURIComponent(attachment.data.attachment);
    let newpath = filePath.split('https://ams3.digitaloceanspaces.com/sparta-aims/').join('').split('?Signature=')[0];
    setShopVisit({...shopVisit, attachments:shopVisit.attachments.filter((item,itemIndex) => itemIndex !== attachment.index)});
    setDeleteFiles([...deleteFiles,newpath]);
  }

  const onChangeAttachmentDesc = (key, value, index) => {
    setShopVisit({...shopVisit, attachments:shopVisit.attachments.map((item,itemIndex) => itemIndex !== index ? item : {
      ...item, description:value
    })})
  }
  return(
    <Dialog
      open={addEditShopVisit.modal}
      onClose={() => {toggleModalFn();setDeleteFiles([])}}
      className='project-management-modal'
      maxWidth='md'
    >
      <DialogTitle id="scroll-dialog-title">
        {shopVisit.id ? addEditShopVisit.mode === 'edit' ?'Edit':'View': 'Add'} Shop Visit
      </DialogTitle>
      <DialogContent dividers={true}>
        <ShopVisitCRU 
          mode={addEditShopVisit.mode}
          currentFormDetail={currentFormDetail}
          shopVisitCrud={shopVisit} 
          onFieldChange={onFieldChange}
          error={error}
          onResetError={(key) => setError({...error,[key]:''})}
          uploadAttachments={uploadAttachments}
          onChangeAttachmentDesc={onChangeAttachmentDesc}
          onRemoveAttachment={onRemoveAttachment}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} onClick={() => {toggleModalFn();setDeleteFiles([])}} color="primary" size="small">Cancel</Button>
        { addEditShopVisit.mode === 'edit' ?
          <Button disabled={isLoading} onClick={onAddEditShopVisit} variant="contained" color="primary" size="small">
            {isLoading ? <CircularProgress size={20} /> : 'SAVE' }
          </Button>:null
        }
      </DialogActions>
    </Dialog>
  )
}
const ShopVisitList = ({ currentFormDetail, params, getCurrentFormDetail }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [skeletonLoader, setSkeletonLoader] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [filter, setFilter] = useState({});
  const [addEditShopVisit, setAddEditShopVisit] = useState({data:null, mode:'', modal:false});
  const [deleteShopVisit, setDeleteShopVisit] = useState({modal:false, data:[]})
  const [shopVisitsInfo, setShopVisitsInfo] = useState({list:[], pagination:null});
  useEffect(() => {
    getShopVisits({per_page:20}, 'skeletonLoader');
  },[]);
  const getShopVisits = (query, loaderType) => {
    loaderType === 'skeletonLoader' ? setSkeletonLoader(true):setLoading(true);
    globalGetService(`technical/v2/workorder/${params.workOrderSlug}/shop-visit/`, query)
    .then(response => {
      if(checkApiStatus(response)){
        setShopVisitsInfo(response.data.data)
      }
      loaderType === 'skeletonLoader' ? setSkeletonLoader(false):setLoading(false);
    })
  }
  const handleChange = (type, flag, id) => {
    if(type === 'single'){
      setDeleteShopVisit({
            ...deleteShopVisit,
            data: flag ? [...deleteShopVisit.data, id]:deleteShopVisit.data.filter(item =>  item !== id)
        })
    }else{
        let ids = shopVisitsInfo.list.map(item => item.id)
        setDeleteShopVisit({
            ...deleteShopVisit,
            data: flag ? ids:[]
        })
    }
}
  const onDeleteShopVisit = () => {
    setLoading(true);
    globalDeleteService(`technical/v2/workorder/${params.workOrderSlug}/shop-visit/`, {ids:deleteShopVisit.data})
    .then(response => {
      if(checkApiStatus(response)){
        enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
        setDeleteShopVisit({modal:false, data:[]});
        getShopVisits({per_page: shopVisitsInfo?.pagination?.per_page || 20}, 'pageLoader');
        getCurrentFormDetail('pageLoader');
      }
      setLoading(false)
    })
  }
  const canEdit = currentFormDetail?.current_form?.permissions?.can_save;
  return (
    <div className='forms-table-layout'>
      { skeletonLoader ? <STableLoader count={10} />: 
        <>
          <Paper className='forms-table-cta' square>
            <Grid container spacing={1}>
              <Grid item md={6}></Grid>
              <Grid item md={6}>
                { canEdit ? 
                  <ul className="list-inline">
                    { deleteShopVisit?.data?.length ?
                        <li className='list-inline-item'>
                            <Button onClick={() => setDeleteShopVisit({...deleteShopVisit, modal:true})} color='secondary' size='small' variant='contained'>Delete</Button>
                        </li>:null
                    }
                    <li className="list-inline-item">
                      <Button onClick={() => setAddEditShopVisit({modal:true, mode:'edit',data:shopVisitObj})} color="primary" size="small" variant="contained">Add Shop Visit</Button>
                    </li>
                  </ul>:null
                }
              </Grid>
            </Grid>
          </Paper>
          <Paper>
            <div style={{height: !shopVisitsInfo.list.length ? 'auto':`${window.innerHeight-290}px`,overflow:'auto', paddingRight:'5px'}}>
              <Table className='mui-table-format' stickyHeader>
                <TableHead>
                  <TableRow>
                    { canEdit ?
                        <TableCell style={{width:'40px'}}>
                            <div style={{width:'40px'}}>
                                <Checkbox
                                    size='small'
                                    checked={deleteShopVisit?.data?.length ? deleteShopVisit?.data?.length === shopVisitsInfo?.list.length :false}
                                    onChange={(e) => handleChange('bulk', e.target.checked)}
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                    disabled={!shopVisitsInfo?.list?.length}
                                />
                            </div>
                        </TableCell>:null
                    }
                    <TableCell>Shop Visit Number</TableCell>
                    <TableCell>Date of Removal</TableCell>
                    <TableCell>ARC Date (EASA/FAA)</TableCell>
                    <TableCell>Reason for Removal</TableCell>
                    <TableCell>Shop Facility</TableCell>
                    <TableCell>Engine Induction Date</TableCell>
                    <TableCell align='right'>Action(s)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  { shopVisitsInfo.list.map((item) => 
                    <TableRow key={item.id}>
                      { canEdit ?
                          <TableCell>
                              <div style={{width:'40px'}}>
                                  <Checkbox
                                      size='small'
                                      checked={deleteShopVisit.data.includes(item.id)}
                                      onChange={(e) => handleChange('single', e.target.checked, item.id)}
                                      inputProps={{ 'aria-label': 'primary checkbox' }}
                                  />
                              </div>
                          </TableCell>:null
                      }
                      <TableCell>{item?.shop_visit_number||'--'}</TableCell>
                      <TableCell>{item?.date_of_removal ? moment(item.date_of_removal).format(displayDateFormatShort) :'--'}</TableCell>
                      <TableCell>{item?.arc_date ? moment(item.arc_date).format(displayDateFormatShort) :'--'}</TableCell>
                      <TableCell>{item?.reason_for_removal||'--'}</TableCell>
                      <TableCell>{item?.shop_facility||'--'}</TableCell>
                      <TableCell>{item?.induction_date? moment(item.induction_date).format(displayDateFormatShort) :'--'}</TableCell>
                      <TableCell align='right'>
                        <ul className='list-inline flex-centered' style={{float:'right'}}>
                            {item?.attachments?.length ?
                              <li className="list-inline-item">
                                <span className="flex-centered"><Tooltip title='Attachment(s)' arrow><AttachmentIcon fontSize="small" color="primary" /></Tooltip><span>({item.attachments.length})</span></span>
                              </li>:null
                            }
                            { canEdit ?
                              <>
                                <li className='list-inline-item'>
                                    <Tooltip title='Edit' arrow>
                                        <IconButton size="small"><EditIcon onClick={() => setAddEditShopVisit({modal:true, mode:'edit', data:item})} fontSize='small' color='primary' /> </IconButton>
                                    </Tooltip>
                                </li>
                                <li className='list-inline-item'>
                                    <Tooltip title='Delete' arrow>
                                        <IconButton size="small"> <DeleteOutlineIcon onClick={() => setDeleteShopVisit({modal:true, data:[item.id]})} fontSize='small' color='secondary' /> </IconButton>
                                    </Tooltip>
                                </li>
                              </>:
                              <li className='list-inline-item'>
                                  <Tooltip title='Edit' arrow>
                                      <IconButton size="small"><VisibilityIcon onClick={() => setAddEditShopVisit({modal:true, mode:'view', data:item})} fontSize='small' color='primary' /> </IconButton>
                                  </Tooltip>
                              </li>
                            }
                        </ul>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>  
            </div>
            <Pagination 
                pagination={shopVisitsInfo.pagination}
                onChangePage={(event, newPage) => getShopVisits({...filter, page:newPage+1, per_page: shopVisitsInfo.pagination.per_page}, 'pageLoader')}
                onChangeRowsPerPage={(event) => getShopVisits({...filter, page:1, per_page: event.target.value}, 'pageLoader')}
            />
            { !shopVisitsInfo.list.length ? <EmptyCollection title="No records found" />:null }
          </Paper>  
        </>
      }
      { addEditShopVisit.modal ?
        <AddEditShopVisit 
          currentFormDetail={currentFormDetail}
          params={params}
          addEditShopVisit={addEditShopVisit}
          toggleModalFn={() => {setAddEditShopVisit({data:null, mode:'', modal:false});}}
          getResponseBack={() => {getShopVisits({per_page: shopVisitsInfo?.pagination?.per_page || 20}, 'pageLoader');getCurrentFormDetail('pageLoader')}}
        />:null
      }
      { deleteShopVisit.modal ?
        <DeletePopUp
            modal={deleteShopVisit.modal}
            toggleModalFn={() => setDeleteShopVisit({data:[], modal:false})}
            title="Delete Shop Visit"
            content={<h4>Are you sure you want to delete?</h4>}
            deleteRecordFn={onDeleteShopVisit}
        />:null
      }
      { isLoading ? <PageLoader />: null }
    </div>
  );
};
export default withRouter(ShopVisitList);
