import React from 'react';
import {DropzoneArea} from 'material-ui-dropzone';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
const UploadPictures = ({uploadModal, onUpload, toggleModalFn}) => {
    const [files, setFiles] = React.useState([]);

    const handleFileUpload = () => {
      onUpload(files);
      toggleModalFn();
    };
    const handleDropzoneChange = (uploadedFiles) => {
      setFiles(uploadedFiles);
    };
    return(
        <Dialog
            open={uploadModal}
            onClose={toggleModalFn}
        >
            <DialogTitle id="scroll-dialog-title">
                Upload Pictures
            </DialogTitle>
            <DialogContent dividers={true}>
                <div style={{width:'380px'}}>
                    <DropzoneArea
                        filesLimit={30}
                        maxFileSize={104857600}
                        showPreviewsInDropzone={false}
                        useChipsForPreview={true}
                        acceptedFiles={['.png, .jpg, .jpeg, .heif, .heic']}
                        showPreviews={ true }
                        dropzoneText={<p style={{fontSize:'13px'}}>Drag & Drop Documents<br/> OR <br/> Click Here<br/> </p>}
                        dropzoneClass="drag-drop-cnt"
                        maxWidth="sm"
                        showAlerts={['info','error']}
                        alertSnackbarProps={{anchorOrigin: {vertical:'top', horizontal:'right'}}}
                        clearOnUnmount={true}
                        onChange={handleDropzoneChange} 
                    />
                </div>
                <span className='files-upload-limit' > Note : Max files upload limit is 30 in single time.</span>
            </DialogContent>
            <DialogActions>
                <Button onClick={toggleModalFn} color="primary" size='small' variant='outlined'>Cancel</Button>
                <Button onClick={handleFileUpload}  disabled={files.length > 0 ? false: true} color="primary" size='small' variant='contained'>Proceed</Button>
            </DialogActions>
            
        </Dialog>
    )
}
export default UploadPictures;