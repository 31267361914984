import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { fetchReviewDataAc, addIssuesAc, fetchIssueDetailAc, fetchFormsFeedbackAc, changeIssueStatusAc, updateCommentsAc, addCommentsAc, notifyClientAc } from '../actionCreators';
import { imgStoragePath } from "../../../../../constants";
import { ReviewIssueDrawer, ReviewHeader, IssueDetail } from "../components";
import { MSNSwitcherHeader, ListLoader } from "../../../../../shared";
import { TOGGLE_DETAILS, TOOGLE_ADD_ISSUES, TOOGLE_ISSUES_LIST, TOOGLE_EDIT_ISSUES } from '../actions';
import { SecondaryTechHeaderMid, SecondaryTechHeaderRH, EditFormBar } from "../../../Elements";
import { getFormDetailsAc } from "../../T001/actionCreators";
import AddIssues from '../components/AddIssues';
import EditIssues from '../components/EditIssues';
import { trackActivity } from '../../../../../utils/mixpanel';
class ReviewT005 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      specLabel: '',
      specId: '',
      editIssue: '',
    };
  }
  componentDidMount() {
    this.props.fetchReviewData();
    this.props.getFormDetails();
    this.props.fetchFormsFeedback();
    trackActivity('ReviewT005', {
      event_type: 'Page Visited',
      page_title: 'ReviewT005'
    })
  }

  readTheAnswer = (answers, key) => {
    let answer = answers.filter(answer => answer.label == key);
    if (answer.length) {
      return answer[0].value
    }
  }
  toggleEditIssues = () => {
    this.props.toggleEditIssue();
  };

  toggleEdits = (e, issue, status) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({
      editIssue: issue,
    });
    this.props.toggleEditIssue();
  };

  toggleComments = (label, id) => {
    this.setState({
      specLabel: label ? label : '',
      specId: id ? id : '',
    });
    this.props.toggleComment();
  };
  toggleIssues = () => {
    this.props.toggleIssue();
  };

  toggleDetail = (issue) => {
    issue ? this.props.reviewFeedBackDetail(issue.location_slug) : this.props.toggleDetails();
  };

  updateComments = (value) => {
    let data = {
      description: value,
      form_id: this.props.formInfo.current_form.id,
      obj_id: this.state.specId,
      label: this.state.specLabel,
    }
    this.props.addIssue(data);
  }

  updateIssues = (value) => {
    this.props.reviewIssueStatusChange(this.state.editIssue.location_slug, value, 'edit');
  }

  deleteStatus = (e, issue, status) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.reviewIssueStatusChange(issue.location_slug, status, 'delete');
  };

  changeStatus = (e, issue, status) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.reviewIssueStatusChange(issue.location_slug, status);
  };

  changeStatusDetails = (e, location_slug, status) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.reviewIssueStatusChange(location_slug, status, 'details');
  };

  updateCommentStatus = (e, location_slug, commentId) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.updateCommentStatus(location_slug, commentId);
  }

  reviewUIComp = (issue = {}, label, id) => {

    if (issue.order_number == null || issue.order_number == undefined) {
      return (
        <span onClick={() => this.toggleComments(label, id)} className="comment-icon" >
          <img src={imgStoragePath + "cmnt_active.svg"} className="blue-icon" alt="" />
          <img src={imgStoragePath + "cmnt-inactive.svg"} className="grey-icon" alt="" />
        </span>

      )
    } else {
      return (
        <span onClick={() => this.toggleIssues()} className="issue-number" >
          <span>#{issue.order_number}</span>
        </span>
      )
    }
  }
  render() {
    const { t005ReviewList, notifyFlag } = this.props;
    return (
      <div className="technical-review-model">
        {Object.keys(this.props.formInfo).length ?
          <MSNSwitcherHeader
            SecodaryHeaderRH={<SecondaryTechHeaderRH projectInfo={this.props.formInfo.project} />}
            SecondaryHeaderMid={<SecondaryTechHeaderMid projectInfo={this.props.formInfo.project} activeAsset={this.props.formInfo.asset} />}
            activeAsset={this.props.formInfo.asset}
            projectSlug={Object.keys(this.props.formInfo).length ? this.props.formInfo.project.slug : ''}
          /> : null
        }
        <ReviewHeader
          formInfo={this.props.formInfo}
          notifyFlag={notifyFlag}
          notifyClient={this.props.notifyClient}
          workOrderSlug={this.props.params.workOrderSlug}
        />
        {this.props.commentsLoader ? <ListLoader /> : null}
        <div className="form-t005">
          {t005ReviewList.map((section, sectionIndex) =>
            <div className="section-cn" key={sectionIndex}>
              <h3>{section.name}</h3>
              {section.asset_specs && Object.keys(section.asset_specs).length ?
                <Row>
                  {Object.keys(section.asset_specs).map((key, assetIndex) =>
                    <Col md="3" key={assetIndex} style={{ marginBottom: '30px', paddingLeft: '20px', paddingRight: '20px' }}>
                      <h5>{section.asset_specs[key].label}</h5>
                      <span className="label-value">
                        {this.readTheAnswer(section.answers, key) ? this.readTheAnswer(section.answers, key) : '--'}
                      </span>
                      {this.reviewUIComp(section.asset_specs[key].issue, section.asset_specs[key].label, section.id)}
                    </Col>
                  )}
                </Row>
                : null
              }
              {section.maintenance_specs && Object.keys(section.maintenance_specs).length ?
                <Row>
                  {Object.keys(section.maintenance_specs).map((key, mntIndex) =>
                    <Col md="3" key={mntIndex} style={{ marginBottom: '30px', paddingLeft: '20px', paddingRight: '20px' }}>
                      <h5>{section.maintenance_specs[key].label}</h5>
                      <span className="label-value">{this.readTheAnswer(section.answers, key) ? this.readTheAnswer(section.answers, key) : '--'}</span>
                      {this.reviewUIComp(section.maintenance_specs[key].issue, section.maintenance_specs[key].label, section.id)}
                    </Col>
                  )}
                </Row>
                : null
              }
              {section.specs && Object.keys(section.specs).length ?
                <Row>
                  {Object.keys(section.specs).map((key, secIndex) =>
                    <Col md="3" key={secIndex} style={{ marginBottom: '30px', paddingLeft: '20px', paddingRight: '20px' }}>
                      <h5>{section.specs[key].label}</h5>
                      <span className="label-value">{this.readTheAnswer(section.answers, key) ? this.readTheAnswer(section.answers, key) : '--'}</span>
                      {this.reviewUIComp(section.specs[key].issue, section.specs[key].label, section.id)}
                    </Col>
                  )}
                </Row>
                : null
              }
              <div>
                {section.component_questions && section.component_questions.length ?
                  section.component_questions.map((component, componentIndex) =>
                    <div key={componentIndex} className="" style={{ marginBottom: '20px', padding: '15px' }}>
                      <h4 style={{ fontSize: '16px', color: '#691AD3', marginBottom: '20px' }}>{component.name}</h4>
                      {component.maintenance_specs && Object.keys(component.maintenance_specs).length ?
                        <Row>
                          {Object.keys(component.maintenance_specs).map((key, index) =>
                            <Col md="3" key={index} style={{ marginBottom: '30px', paddingLeft: '20px', paddingRight: '20px' }}>
                              <h5>{component.maintenance_specs[key].label}</h5>
                              <span className="label-value">{this.readTheAnswer(component.answers, key) ? this.readTheAnswer(component.answers, key) : '--'}</span>
                              {this.reviewUIComp(component.maintenance_specs[key].issue, component.maintenance_specs[key].label, section.id)}
                            </Col>
                          )}
                        </Row> : null
                      }
                      {component.specs && Object.keys(component.specs).length ?
                        <Row>
                          {Object.keys(component.specs).map((key, index) =>
                            <Col md="3" key={index} style={{ marginBottom: '30px', paddingLeft: '20px', paddingRight: '20px' }}>
                              <h5>{component.specs[key].label}</h5>
                              <span>{this.readTheAnswer(component.answers, key) ? this.readTheAnswer(component.answers, key) : '--'}</span>
                              <span className="label-value">{this.readTheAnswer(component.answers, key) ? this.readTheAnswer(component.answers, key) : '--'}</span>
                              {this.reviewUIComp(component.specs[key].issue, component.specs[key].label, section.id)}
                            </Col>
                          )}
                        </Row>
                        : null
                      }
                      {component.custom_components && component.custom_components.length ?
                        component.custom_components.map((customComponents, subComponentIndex) =>
                          <Row>
                            {customComponents.maintenance_specs && Object.keys(customComponents.maintenance_specs).length ?
                              Object.keys(customComponents.maintenance_specs).map((key, index) =>
                                <Col md="3" key={index} style={{ marginBottom: '30px', paddingLeft: '20px', paddingRight: '20px' }}>
                                  <h5>{customComponents.maintenance_specs[key].label}</h5>
                                  <span className="label-value">{this.readTheAnswer(customComponents.answers, key) ? this.readTheAnswer(customComponents.answers, key) : '--'}</span>
                                  {this.reviewUIComp(customComponents.maintenance_specs[key].issue, customComponents.maintenance_specs[key].label, section.id)}
                                </Col>
                              ) : null}
                            {customComponents.specs && Object.keys(customComponents.specs).length ?
                              Object.keys(customComponents.specs).map((key, index) =>
                                <Col md="3" key={index} style={{ marginBottom: '30px', paddingLeft: '20px', paddingRight: '20px' }}>
                                  <h5>{customComponents.specs[key].label}</h5>
                                  <span className="label-value">{this.readTheAnswer(customComponents.answers, key) ? this.readTheAnswer(customComponents.answers, key) : '--'}</span>
                                  {this.reviewUIComp(customComponents.specs[key].issue, customComponents.specs[key].label, section.id)}
                                </Col>
                              ) : null}
                          </Row>
                        ) : null}
                      {component.sub_components && component.sub_components.length ?
                        component.sub_components.map((subComponent, subComponentIndex) =>
                          <div>
                            <h5 style={{ fontSize: '14px', color: '#691ad3', paddingBottom: '10px', marginBottom: '20px' }}>{subComponent.name}</h5>
                            <Row>
                              {subComponent.maintenance_specs && Object.keys(subComponent.maintenance_specs).length ?
                                Object.keys(subComponent.maintenance_specs).map((key, index) =>
                                  <Col md="3" key={index} style={{ marginBottom: '30px', paddingLeft: '20px', paddingRight: '20px' }}>
                                    <h5>{subComponent.maintenance_specs[key].label}</h5>
                                    <span className="label-value">{this.readTheAnswer(subComponent.answers, key) ? this.readTheAnswer(subComponent.answers, key) : '--'}</span>
                                    {this.reviewUIComp(subComponent.maintenance_specs[key].issue, subComponent.maintenance_specs[key].label, section.id)}
                                  </Col>
                                ) : null}
                              {subComponent.specs && Object.keys(subComponent.specs).length ?
                                Object.keys(subComponent.specs).map((key, index) =>
                                  <Col md="3" key={index} style={{ marginBottom: '30px', paddingLeft: '20px', paddingRight: '20px' }}>
                                    <h5>{subComponent.specs[key].label}</h5>
                                    <span className="label-value">{this.readTheAnswer(subComponent.answers, key) ? this.readTheAnswer(subComponent.answers, key) : '--'}</span>
                                    {this.reviewUIComp(subComponent.specs[key].issue, subComponent.specs[key].label, section.id)}
                                  </Col>
                                ) : null}
                            </Row>
                          </div>
                        ) : null}
                    </div>
                  ) : null}
              </div>
              <div>
                {section.sections && section.sections.length ?
                  section.sections.map((component, componentIndex) =>
                    <div key={componentIndex} className="" style={{ background: '#F7F7F7', marginBottom: '20px', padding: '15px', borderTop: '1px solid #' }}>
                      <h4 style={{ fontSize: '16px', color: '#691AD3', marginBottom: '20px' }}>{component.name}</h4>
                      <Row>
                        {component.maintenance_specs && Object.keys(component.maintenance_specs).length ?
                          Object.keys(component.maintenance_specs).map((key, index) =>
                            <Col key={index} md="3" style={{ marginBottom: '30px', paddingLeft: '20px', paddingRight: '20px' }}>
                              <h5>{component.maintenance_specs[key].label}</h5>
                              <span className="label-value">{this.readTheAnswer(component.answers, key) ? this.readTheAnswer(component.answers, key) : '--'}</span>
                              {this.reviewUIComp(component.maintenance_specs[key].issue, component.maintenance_specs[key].label, section.id)}
                            </Col>
                          ) : null}
                        {component.specs && Object.keys(component.specs).length ?
                          Object.keys(component.specs).map((key, index) =>
                            <Col key={index} md="3" style={{ marginBottom: '30px', paddingLeft: '20px', paddingRight: '20px' }}>
                              <h5>{component.specs[key].label}</h5>
                              <span className="label-value">{this.readTheAnswer(component.answers, key) ? this.readTheAnswer(component.answers, key) : '--'}</span>
                              {this.reviewUIComp(component.specs[key].issue, component.specs[key].label, section.id)}
                            </Col>
                          ) : null}
                        {component.sub_components && component.sub_components.length ?
                          component.sub_components.map((subComponent, subComponentIndex) =>
                            <div>
                              <h5 style={{ fontSize: '16px', borderBottom: '1px solid #d7d7d7', paddingBottom: '10px', marginBottom: '20px' }}>{subComponent.name}</h5>
                              <Row>
                                {subComponent.maintenance_specs && Object.keys(subComponent.maintenance_specs).length ?
                                  Object.keys(subComponent.maintenance_specs).map((key, index) =>
                                    <Col key={index} md="3" style={{ marginBottom: '30px', paddingLeft: '20px', paddingRight: '20px' }}>
                                      <h5>{subComponent.maintenance_specs[key].label}</h5>
                                      <span className="label-value">{this.readTheAnswer(subComponent.answers, key) ? this.readTheAnswer(subComponent.answers, key) : '--'}</span>
                                      {this.reviewUIComp(subComponent.maintenance_specs[key].issue, subComponent.maintenance_specs[key].label, section.id)}
                                    </Col>
                                  ) : null}
                                {subComponent.specs && Object.keys(subComponent.specs).length ?
                                  Object.keys(subComponent.specs).map((key, index) =>
                                    <Col key={index} md="3" style={{ marginBottom: '30px', paddingLeft: '20px', paddingRight: '20px' }}>
                                      <h5>{subComponent.specs[key].label}</h5>
                                      <span className="label-value">{this.readTheAnswer(subComponent.answers, key) ? this.readTheAnswer(subComponent.answers, key) : '--'}</span>
                                      {this.reviewUIComp(subComponent.specs[key].issue, subComponent.specs[key].label, section.id)}
                                    </Col>
                                  ) : null}
                              </Row>
                            </div>

                          ) : null}
                      </Row>
                    </div>
                  ) : null}
              </div>
            </div>
          )}
        </div>
        <a href="javascript:void(0)" className="view-issues-btn" onClick={() => this.toggleIssues()} >
          <img src={imgStoragePath + 'icon_CommentDash.svg'} alt="" />
        </a>
        <EditFormBar
          titleContent={<div><h3 style={{ textTransform: 'capitalize' }}> {'Post Issues'} </h3></div>}
          toggleEditSideBar={() => this.toggleComments()}
          isOpen={this.props.toggleComments}
          style={this.props.toggleComments ? { width: '400px' } : { width: '0px' }}>
          {this.props.toggleComments ?
            <AddIssues
              updateComments={this.updateComments}
              toggleEditSideBar={() => this.toggleComments()}
            /> : null
          }
        </EditFormBar>
        <EditFormBar
          titleContent={<div><h3 style={{ textTransform: 'capitalize' }}> {'Edit Issue'} </h3></div>}
          toggleEditSideBar={() => this.toggleEditIssues()}
          isOpen={this.props.toggleEditIssues}
          style={this.props.toggleEditIssues ? { width: '400px' } : { width: '0px' }}>
          {this.props.toggleEditIssues ?
            <EditIssues
              updateComments={this.updateIssues}
              issueData={this.state.editIssue}
              toggleEditSideBar={() => this.toggleEditIssues()}
            /> : null
          }
        </EditFormBar>
        <EditFormBar
          titleContent={<div><h3 style={{ textTransform: 'capitalize' }}> {'Issues List'} </h3></div>}
          toggleEditSideBar={() => this.toggleIssues()}
          isOpen={this.props.toggleIssues}
          style={this.props.toggleIssues ? { width: '400px' } : { width: '0px' }}>
          {this.props.toggleIssues ?
            <ReviewIssueDrawer
              issueList={this.props.reviewFeedbacksList}
              toggleEditSideBar={() => this.toggleComments()}
              toggleDetailBar={this.toggleDetail}
              changeStatus={this.changeStatus}
              deleteStatus={this.deleteStatus}
              editStatus={this.toggleEdits}
            /> : null}
        </EditFormBar>
        <EditFormBar
          titleContent={<div><h3 style={{ textTransform: 'capitalize' }}> {'Issues Discussion'} </h3></div>}
          toggleEditSideBar={() => this.toggleDetail()}
          isOpen={this.props.toggleDetail}
          style={this.props.toggleDetail ? { width: '400px' } : { width: '0px' }}>
          {this.props.toggleDetail ?
            <IssueDetail
              issueDetailInfo={this.props.issueDetailInfo}
              toggleEditSideBar={() => this.toggleDetail()}
              toggleSideBars={() => this.toggleIssues()}
              addComments={this.props.addComments}
              changeStatus={this.changeStatusDetails}
              updateCommentStatus={this.updateCommentStatus}
            />
            : null}
        </EditFormBar>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  t005ReviewList: state.FormReviewReducer.t005ReviewList,
  formInfo: state.FormT001Reducer.formInfo,
  toggleComments: state.FormReviewReducer.toggleComments,
  toggleIssues: state.FormReviewReducer.toggleIssues,
  toggleDetail: state.FormReviewReducer.toggleDetail,
  toggleEditIssues: state.FormReviewReducer.toggleEditIssues,
  reviewFeedbacksList: state.FormReviewReducer.reviewFeedbacksList,
  issueDetailInfo: state.FormReviewReducer.issueDetailInfo,
  commentsLoader: state.FormReviewReducer.commentsLoader,
  notifyFlag: state.FormReviewReducer.notifyFlag
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchReviewData: () => dispatch(fetchReviewDataAc(ownProps, 't005')),
    getFormDetails: () => dispatch(getFormDetailsAc(ownProps)),
    fetchFormsFeedback: () => dispatch(fetchFormsFeedbackAc(ownProps, 't005')),
    reviewFeedBackDetail: (locationSlug) => dispatch(fetchIssueDetailAc(ownProps, locationSlug, 't005')),
    addIssue: (data) => dispatch(addIssuesAc(ownProps, data, 't005')),
    notifyClient: () => dispatch(notifyClientAc(ownProps)),
    updateCommentStatus: (locationSlug, commentID) => dispatch(updateCommentsAc(ownProps, locationSlug, commentID)),
    addComments: (location_slug, message) => dispatch(addCommentsAc(location_slug, message)),
    reviewIssueStatusChange: (locationSlug, status, flag = '') => dispatch(changeIssueStatusAc(ownProps, locationSlug, status, 't005', flag)),
    toggleComment: () => dispatch({
      type: TOOGLE_ADD_ISSUES,
      payload: {}
    }),
    toggleEditIssue: () => dispatch({
      type: TOOGLE_EDIT_ISSUES,
      payload: {}
    }),
    toggleIssue: () => dispatch({
      type: TOOGLE_ISSUES_LIST,
      payload: {}
    }),
    toggleDetails: () => dispatch({
      type: TOGGLE_DETAILS,
      payload: {}
    }),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ReviewT005);
