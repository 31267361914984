import React, { useState, Fragment } from 'react';
import { withRouter } from 'react-router';
import { useSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, CircularProgress, Tooltip } from '@material-ui/core';
import { checkApiStatus } from '../../../utils_v2';
import { globalPutService } from '../../../utils_v2/globalApiServices';
import { imgStoragePath } from '../../../constants';
import { trackActivity } from '../../../utils/mixpanel';
import { castleAirInstance } from '../../../shared_elements/components';
const EditContentItem = ({params, editItem, toggleModalFn, documentCheckList, questionList, getResponseBack,currentFormDetail}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState({})
    const [formData, setFormData] = useState(editItem.data);
    const onFieldChange = (keyParam, value) => {
        setFormData({...formData,[keyParam]:value})
    }
    const onResetErrorKey = (key) => {
        setError({...error,[key]:''});
    }
    const onSubmitEdit = () => {
        // add Validations
        let validationInputs = {
            name:formData.name && formData.name.trim().length ? '':'Please enter Name'
        }
        if(editItem.itemType === 'question'){
            validationInputs = {
                ...validationInputs,
                remarks:formData.status && (formData.status === 2 || formData.status === 3) ? (formData.remarks && formData.remarks.trim().length ? '':'Please enter Remarks'):''
            }
        }
        if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
            setLoading(true);
            let arr = [];
            if(editItem.itemType === 'section'){
                arr = documentCheckList.filter((section) => section.id !== formData.id).map((item) => item.name.toLowerCase());
            }else if(editItem.itemType === 'sub-section'){
                let subSecArr = documentCheckList.find(section => section.id === editItem.sectionId).sub_sections
                arr = subSecArr && subSecArr.length ? subSecArr.filter(subSec => subSec.id !== formData.id).map((item) => item.name.toLowerCase()):[];
            }else{
                let qusArr = questionList;
                arr = qusArr && qusArr.length ? qusArr.filter((item) => item.id !== formData.id).map((item) => item.name.toLowerCase()):[];
            }
            if(!arr.includes(formData.name.toLowerCase().trim())){
                if(editItem.itemType === 'section'){
                    globalPutService(`technical/v2/workorder/${params.workOrderSlug}/workbook/sections/${editItem.sectionId}/?asset_type=${params.type}`, formData)
                    .then(response => {
                        setLoading(false);
                        if(checkApiStatus(response)){
                            toggleModalFn();
                            getResponseBack(response.data.data);
                            enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
                        }else{
                            enqueueSnackbar(response?.data?.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
                        }
                    })
                } else if(editItem.itemType === 'sub-section'){
                    globalPutService(`technical/v2/workorder/${params.workOrderSlug}/workbook/sections/${editItem.sectionId}/sub-section/${editItem?.data?.id}/?asset_type=${params.type}`, formData)
                    .then(response => {
                        setLoading(false);
                        if(checkApiStatus(response)){
                            toggleModalFn();
                            getResponseBack(response.data.data);
                            enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
                        }else{
                            enqueueSnackbar(response?.data?.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
                        }
                    })
                }else{
                    globalPutService(`technical/v2/workorder/${params.workOrderSlug}/workbook/sections/${editItem.sectionId}/sub-section/${editItem?.subSectionId}/checklists/${formData?.id}/?asset_type=${params.type}`, formData)
                    .then(response => {
                        setLoading(false);
                        if(checkApiStatus(response)){
                            toggleModalFn();
                            getResponseBack(response.data.data);
                            enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
                        }else{
                            enqueueSnackbar(response?.data?.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
                        }
                    })
                }
            }else{
                setLoading(false);
                enqueueSnackbar('Name already exists', {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
            }
        }else{
            setError(validationInputs)
            trackActivity('Project Management', {
                event_type: 'Section/Sub-Section Data Update Failed',
                page_title: 'T003 Form',
                workOrder_slug: params.workOrderSlug ? params.workOrderSlug : '',
                edit_type: editItem.itemType ==='section' ?'document-sections':editItem.itemType ==='sub-section'?'document-sub-sections':'document-checklists',
                id: editItem.data.id ? editItem.data.id : '',
                error_detail:validationInputs ? validationInputs : '',
                error_source:'Frontend'
            });
        }
    }
    console.log('formData',formData);
    return(
        <Fragment>
            <Dialog
                    open={editItem.modal}
                    onClose={toggleModalFn}
                    className='project-management-modal'
                >
                <DialogTitle id="scroll-dialog-title">
                    Edit {editItem.itemType === 'section'? ' Section': editItem.itemType ==='sub-section' ? ' Sub-Section':' Question'}
                </DialogTitle>
                <DialogContent dividers={true}>
                    <div style={{width:'420px'}}>
                        <Grid container spacing={1}>
                            <Grid item md={12}>
                                <TextField
                                    required
                                    id={`name`}
                                    label={`${editItem.itemType === 'section' ? 'Section': editItem.itemType ==='sub-section' ? 'Sub-Section':'Checklist'} Name`}
                                    fullWidth
                                    margin="normal"
                                    InputLabelProps={{shrink: true}}
                                    error={error.name?true:false}
                                    helperText={error.name ? error.name:''}
                                    value={formData.name?formData.name:''}
                                    onChange={(e) => onFieldChange('name', e.target.value)}
                                    onFocus={() => onResetErrorKey('name')}
                                    variant='outlined'
                                />
                            </Grid>
                                    <Grid item md={12}>
                                        <TextField
                                            id={`notes`}
                                            label='Notes'
                                            fullWidth
                                            multiline
                                            maxRows={4}
                                            margin="normal"
                                            InputLabelProps={{shrink: true}}
                                            value={formData.notes?formData.notes:''}
                                            onChange={(e) => onFieldChange('notes', e.target.value)}
                                            variant='outlined'
                                        />
                                    </Grid>

                            { editItem.itemType === 'question' ?
                                <Fragment>
                                    <Grid item md={12}  style={{display:currentFormDetail?.current_form?.status?.id==1 ?'none':''}}>
                                        <div>
                                            <label style={{fontSize:'13px'}}>Status</label>
                                            <ul className="list-inline spacing-list">
                                            <Tooltip title='Recieved' arrow>
                                                <li className="list-inline-item" style={{cursor:'pointer'}} onClick={(e) => onFieldChange('status', formData.status === 1 ? null:1)}>
                                                    <img src={`${imgStoragePath}${formData.status && formData.status === 1 ? 'tick_color.png':'tick_gray.png'}`} alt="" />
                                                </li>
                                            </Tooltip> <Tooltip title='Pending' arrow>
                                                <li className="list-inline-item" style={{cursor:'pointer'}} onClick={(e) => onFieldChange('status', formData.status === 2 ? null : 2)}>
                                                    <img src={`${imgStoragePath}${formData.status && formData.status === 2 ? 'cross_color.png':'cross_gray.png'}`} alt="" />
                                                </li>
                                            </Tooltip> <Tooltip title='N/A' arrow>
                                                <li className="list-inline-item" style={{cursor:'pointer'}} onClick={(e) => onFieldChange('status',formData.status === 3 ? null : 3)}>
                                                    <img src={`${imgStoragePath}${formData.status && formData.status === 3 ? 'na_color.png':'na_gray.png'}`} alt="" />
                                                </li>
                                                </Tooltip>
                                            </ul>
                                        </div>
                                    </Grid>
                                    <Grid item md={12}>
                                        <TextField
                                            required={formData.status && formData.status === 2 || formData.status === 3 ? true:false}
                                            id={`remarks`}
                                            label='Remarks'
                                            fullWidth
                                            margin="normal"
                                            multiline
                                            maxRows={4}
                                            InputLabelProps={{shrink: true}}
                                            error={error.remarks?true:false}
                                            helperText={error.remarks ? error.remarks:''}
                                            value={formData.remarks?formData.remarks:''}
                                            onChange={(e) => onFieldChange('remarks', e.target.value)}
                                            onFocus={() => onResetErrorKey('remarks')}
                                            variant='outlined'
                                        />
                                    </Grid>
                                </Fragment>
                                :null
                            }
                        </Grid>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button disabled={isLoading} onClick={toggleModalFn} color="primary" size='small' variant='outlined'>Cancel</Button>
                    <Button disabled={isLoading} onClick={onSubmitEdit} color="primary" size='small' variant='contained'>
                        {isLoading ? <CircularProgress size={24} /> : 'Save'}
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default withRouter(EditContentItem);