import React, { Fragment, useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { browserHistory } from 'react-router'
import { Button, Grid, Paper } from '@material-ui/core';
import { TableListComp,DeletePopUp, EmptyCollection, ExportManu, PageLoader } from '../../../shared_elements';
import { STableLoader } from '../../../shared_elements/loaders';
import { List, ContactCRU } from '../components';
import { checkApiStatus, downloadFileType, getLocalStorageInfo,removeEmptyKey } from '../../../utils_v2';
import { globalGetService, globalDeleteService, globalPostService } from '../../../utils_v2/globalApiServices';
import { contactFilterOps } from '../';
import { FormWrapper, FormSkeletonLoader } from '../../Elements';
import { globalStaticExportService } from '../../../globalServices';
import FilterComponent from '../../../shared_elements/filter_component';
import { trackActivity } from '../../../utils/mixpanel';
import RestrictedAccess from '../../../shared_elements/components/RestrictedAccess';
import InvalidUrl from '../../../shared_elements/components/InvalidUrl';
const Contacts = ({params,location}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [initialLoader, setInitialLoader] = useState(false);
    const [formLoader, setFormLoader] = useState(true);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [currentFormDetail, setCurrentFormDetail] = useState(null);
    const [contacts, setContacts] = useState({list:[], pagination:{}});
    const [deleteContacts, setDeleteContacts] = useState({modal:false, contacts:[]});
    const [addEditContact, setAddEditContact] = useState({modal:false, mode:'', contacts:null});
    const [sort, setSort] = useState("");
    const [sort_by, setSortBy] = useState("");
    const [filter, setFilter] = useState({});
    useEffect(() => {
        getCurrentFormDetail('formLoader','initial');
        getContacts({}, 'skeletonLoader');
        trackActivity('Project Management', {
            event_type: 'Page Visited',
            page_title: 'T006 Form'
          });
    }, []);
    const getCurrentFormDetail = (loaderType, initialLoad) => {
        if(loaderType){setFormLoader(true)}
        if(initialLoad){setInitialLoader(true)}
        globalGetService(`technical/workorder/${params.workOrderSlug}/forms/`,{form:params.formSlug})
        .then(response => {
            if(initialLoad){setInitialLoader(false)}
            if(loaderType){setFormLoader(false)}
            if(checkApiStatus(response)){
                setCurrentFormDetail(response.data.data)
            }
        })
    }
    const onChangeFormStatus = (data) => {
        setLoading(true)
        globalPostService(`technical/workorder/${params.workOrderSlug}/form/${params.formSlug}/status-update/`,data)
        .then(response => {
            setLoading(false)
            if(checkApiStatus(response)){
                getCurrentFormDetail('formLoader');
                enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
                trackActivity('Project Management', {
                    event_type: 'Form Status Updated',
                    page_title: 'T006 Form',
                    form_slug: params.formSlug ? params.formSlug : '',
                    workOrder_slug: params.workOrderSlug ? params.workOrderSlug : '',
                    status: data.status ? data.status: '',
                    request_body: data ? data : '',
                    success_msg: response.data.message
                });
            }else{
                getCurrentFormDetail('formLoader');
                enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
                trackActivity('Project Management', {
                    event_type: 'Form Status Update Failed',
                    page_title: 'T006 Form',
                    form_slug: params.formSlug ? params.formSlug : '',
                    workOrder_slug: params.workOrderSlug ? params.workOrderSlug : '',
                    status: data.status ? data.status: '',
                    request_body: data ? data : '',
                    error_source: 'Backend',
                    error_msg: response.data.message
                });
            }
        });
    }
    const getContacts = (query, loaderType) => {
        if(loaderType === 'skeletonLoader'){setSkeletonLoader(true)}else{setLoading(true)}
        globalGetService(`technical/workorder/${params.workOrderSlug}/contacts/`, query)
        .then(response => {
            if(loaderType === 'skeletonLoader'){setSkeletonLoader(false)}else{setLoading(false)}
            if(checkApiStatus(response)){
                delete query.sort;
                delete query.sort_by;
                delete query.page;
                delete query.per_page;
                setContacts(response.data.data);
                setFilter(query)
            }
        })
    }
    const onDeleteContact = () => {
        setLoading(true);
        globalDeleteService(`technical/workorder/${params.workOrderSlug}/contacts/`, {ids:deleteContacts.contacts})
        .then(response => {
            setLoading(false);
            if(checkApiStatus(response)){
                enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
                setDeleteContacts({modal:false, contacts:[]})
                getContacts({page:1, per_page:contacts.pagination.per_page, ...filter}, 'pageLoader');
                trackActivity('Project Management', {
                    event_type: 'Contact Deleted',
                    page_title: 'T006 Form',
                    workOrder_slug: params.workOrderSlug ? params.workOrderSlug : '',
                    ids: deleteContacts.contacts ? deleteContacts.contacts : '',
                    success_msg: response.data.message
                });
            }else{
                enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
                trackActivity('Project Management', {
                    event_type: 'Contact Delete Failed',
                    page_title: 'T006 Form',
                    workOrder_slug: params.workOrderSlug ? params.workOrderSlug : '',
                    ids: deleteContacts.contacts ? deleteContacts.contacts : '',
                    error_source: 'Backend',
                    error_msg: response.data.message
                });
            }
        })
    }
    const exportFormReport = (query = {}, file) => {
        enqueueSnackbar('T006 Report Downloading ...', { variant: 'info',anchorOrigin:{horizontal:'right', vertical:'bottom'}})
        globalStaticExportService(`technical/workorder/${params.workOrderSlug}/contacts/`, { ...query, download: file.extension, })
        .then(response => {
            downloadFileType(response, (`T006_${getLocalStorageInfo().defaultLessor.name}_${moment().format('YYYY-MM-DD')}.`), file.extension);
            closeSnackbar();
            trackActivity('Project Management', {
                event_type: 'Report Exported',
                page_title: 'T006 Form',
                workorder_slug: params.workOrderSlug? params.workOrderSlug : '',
                file_name: `T006_${getLocalStorageInfo().defaultLessor.name}_${moment().format('YYYY-MM-DD')}.`,
                file_extension: file.extension,
                success_msg: 'T006 Report Downloaded'
            })
        });
    }
    const toggleBulkOps = (flag) => {
        if(flag){
            setDeleteContacts({modal:false, contacts:contacts.list.map(item=>item.id)})
        }else{
            setDeleteContacts({modal:false, contacts:[]})
        }
    }
    const onSelectRow = (flag, id) => {
        if(flag){
            setDeleteContacts({...deleteContacts, contacts:[...deleteContacts.contacts,id]})
        }else{
            setDeleteContacts({...deleteContacts, contacts:deleteContacts.contacts.filter(item => item !==id)})
        }
    }
    const createSortHandler = (sortField) => {
        let sort_order = 'asc';
        if(sortField === sort){
            sort_order = sort_by === 'asc' ? 'desc':'asc'
        }
        getContacts({page:1, per_page: contacts.pagination.per_page, sort_by:sortField, sort:sort_order},'pageLoader')
        setSort(sortField);
        setSortBy(sortField === sort ? sort_by === 'asc' ? 'desc':'asc' :'asc')
    }
    let formViewPermission = currentFormDetail?.list?.length && currentFormDetail?.list?.find(form => form.slug == currentFormDetail?.current_form?.slug)
    let formType = window.location?.pathname?.toUpperCase()?.includes(currentFormDetail?.current_form?.name)

    return(
        <section className='project-forms-section'>
            { currentFormDetail && Object.keys(currentFormDetail).length && formViewPermission && formType?
                <FormWrapper 
                    formInfo={currentFormDetail} 
                    exportReportBtn={
                    <ExportManu disabled={contacts?.list?.length ? false: true}
                     title="Export Report"
                     files={[{title:'PDF', extension: 'pdf', key:''},{title:'Doc', extension: 'docx', key:''}]} 
                     exportReportFn={(file) => exportFormReport({download:file.extension, ...removeEmptyKey(filter)}, file)}/>
                    }
                    updateFormStatusFn={(data) => onChangeFormStatus(data)}
                />:formLoader ? <FormSkeletonLoader />:null
            }
            <div className='contacts'>
                { skeletonLoader || initialLoader ? (<STableLoader count={6} />) : formType?
                formViewPermission?
                    (<Fragment>
                        <Paper square className="plt-filter-comp">
                            <Grid container spacing={1} alignItems='center'>
                                <Grid item md={9}>
                                  <FilterComponent 
                                        filter={filter}
                                        filterMenu={contactFilterOps}
                                        getResponseBack={(applyFilter) => {getContacts({...applyFilter, page:1, per_page:contacts.pagination.per_page}, 'pageLoader'); browserHistory.push({...location,query:applyFilter})}}
                                  />
                                </Grid>
                                <Grid item md={3}>
                                    <ul className='list-inline' style={{float:'right'}}>
                                        { deleteContacts.contacts.length ?
                                            <li className='list-inline-item'>
                                                <Button onClick={() => setDeleteContacts({...deleteContacts, modal:true})} size='small' variant='outlined' color='secondary'>Delete ({deleteContacts.contacts.length})</Button>
                                            </li>:null
                                        }
                                        
                                        { currentFormDetail && currentFormDetail.current_form && currentFormDetail.current_form.permissions && currentFormDetail.current_form.permissions.can_save ?
                                            <li className='list-inline-item'><Button onClick={() => setAddEditContact({modal:true, mode:'add', contact:null})} color="primary" variant="contained" size="small" style={{marginRight:'10px'}}>Add Contact</Button></li>:null
                                        }
                                    </ul>
                                </Grid>
                            </Grid>
                        </Paper>
                        <TableListComp
                            heads={[
                                { id:'name', label: 'Name', sortOption: true },
                                { id:'email', label: 'Email', sortOption: true },
                                { label: 'Designation', sortOption: false },
                                { label: 'Phone Number', sortOption: false },
                                { label: 'Fax', sortOption: false },
                                { label: 'Action', sortOption: false, actionCell: true }
                            ]}
                            sort={sort}
                            sort_by={sort_by}
                            bulkOperation={ currentFormDetail?.current_form?.permissions?.can_save ? true : false}
                            bulkFlag={contacts.list.length ? deleteContacts.contacts.length === contacts.list.length:false}
                            toggleBulkOps={toggleBulkOps}
                            data={contacts.list.map((item,index) =>
                                <List
                                    key={index}
                                    item={item}
                                    deleteContacts={deleteContacts}
                                    onEdit={() => setAddEditContact({modal:true, mode:'edit', contact:item})}
                                    onDelete={() => setDeleteContacts({modal:true, contacts:[item.id]})}
                                    onSelectRow={(flag) => onSelectRow(flag, item.id)}
                                    currentFormDetail={currentFormDetail}
                                />
                            )}
                            onChangePage={(event, newPage) => {getContacts({ page:newPage+1, per_page: contacts.pagination.per_page, ...filter}, 'pageLoader')}}
                            onChangeRowsPerPage={(event) => getContacts({ page:1, per_page: event.target.value, ...filter}, 'pageLoader')}
                            noRecord={ contacts.list.length ? null:
                                <EmptyCollection title="No records found" />
                            }
                            createSortHandler={createSortHandler}
                            pagination={contacts.pagination}
                        />
                    </Fragment>
                ) : formLoader? null :
                <RestrictedAccess />
                : <InvalidUrl getResponseBack={()=>browserHistory.push(`/technical/project/view/${currentFormDetail?.project?.slug}`) } />
                }
            </div>
            { addEditContact.modal ?
                <ContactCRU
                    addEditContact={addEditContact}
                    toggleModalFn={() => setAddEditContact({modal:false, mode:'', contact:null})}
                    getResponseBack={() => getContacts({ page:1, per_page: contacts.pagination.per_page, ...filter}, 'pageLoader')}
                />:null
            }
            { deleteContacts.modal ?
                <DeletePopUp
                    modal={deleteContacts.modal}
                    toggleModalFn={() => setDeleteContacts({modal:false, contacts:[]})}
                    title="Delete Contact"
                    content={<h4>Are you sure you want to delete?</h4>}
                    deleteRecordFn={() => onDeleteContact()}
                />:null
            }
            { isLoading ? <PageLoader />:null }
        </section>
    )
}
export default Contacts;