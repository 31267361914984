import React, { Component } from 'react';
import { Link } from 'react-router';
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Creatable } from 'react-select';
import {  GetInputField } from'../../Elements';
import { imgStoragePath } from '../../../../constants';
import { checkString, checkValidDate, checkIntNumber,checkEmail, checkNumber, checkDateDiff } from '../../../../formValidator';
import PlacesAutocomplete from 'react-places-autocomplete';
import FilterTypeDropdown from '../../../../shared/TableFilter/components/FilterTypeDropdown';
import FilterList from '../../../../shared/TableFilter/components/FilterList';
import FilterTypes from '../../../../shared/TableFilter/components/FilterTypes';
import FilterLabel from '../../../../shared/TableFilter/components/FilterLabel';
import CKeditor from "../../../../shared/CKEditor";
import moment from 'moment';
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from 'react-places-autocomplete';
export default class LaunchProject extends Component{
  constructor(props){
    super(props);
    this.state = {
      project:{},
      inspectionType:[],
      error: {},
      contactObj:{
        name: '',
        email:'',
        desigantion:'',
        phone_number: ''
      },
      projectExtendedFlag:false,
      projectExtendedValue:'',
      dropdownOpen: false,
      projectCloseFlag: false,
      projectStatusFlag: false
    }
    this.toggle = this.toggle.bind(this);
  };
  updateGeneralInstructions = (evt) =>{
    var newContent = evt.editor.getData();
    this.setState(prevState => ({
      ...prevState,
      project:{
        ...prevState.project,
        instructions:newContent
      },
      error: {
        ...prevState.error,
        instructions: ''
      }
    }));
  }
  equipmentRequiremets = (evt) =>{
    var newContent = evt.editor.getData();
    this.setState(prevState => ({
      ...prevState,
      project:{
        ...prevState.project,
        equipment_requirements:newContent
      },
      error: {
        ...prevState.error,
        equipment_requirements: ''
      }
    }));
  }
  miscComments = (evt) =>{
    var newContent = evt.editor.getData();
    this.setState(prevState => ({
      ...prevState,
      project:{
        ...prevState.project,
        miscellaneous_comments:newContent
      },
      error: {
        ...prevState.error,
        miscellaneous_comments: ''
      }
    }));
  }
  componentDidMount(){
    let data = Object.assign({}, this.props.project.data);
    delete data['lessee'];
    data = {
      ...data,
      status: 1,
      operator_id: this.props.project?.data?.operator_id ? this.props.project.data.operator_id: null,
      lessee_id : this.props.project.data.lessee && this.props.project.data.lessee.id ? this.props.project.data.lessee.id : null,
      department: this.props.type != 'add' ? data.department && data.department.value ? data.department.value: '':''
    }

    this.setState({
      project:data,
      projectExtendedValue:this.props.project.data.expected_end_date?this.props.project.data.expected_end_date:'',
      inspectionType:this.props.inspectionType
    });
    if(this.props.genInstruction && this.props.genInstruction != null && this.props.genInstruction != undefined && this.props.project.type == 'add'){
      this.setState(prevState =>({
        ...prevState,
        project:{
          ...prevState.project,
          miscellaneous_comments:this.props.genInstruction.miscellaneous_comments,
          equipment_requirements:this.props.genInstruction.equipment_requirements,
          instructions:this.props.genInstruction.project_general_instruction,
        },
      }))
    }
  }
  updateJsonFormat = (key, value, type, index) => {
    this.setState(prevState => ({
      ...prevState,
      project: {
        ...prevState.project,
        [type] :  [...prevState.project[type]].map((data, keyIndex) => keyIndex == index ? {
          ...data,
          [key] : value
        } : data )
      }
    }));
  }
  changeProjectStatus = (status) =>{
    if(status == 0){
      this.setState(prevState => ({
        ...prevState,
        projectStatusFlag: true,
        project:{
          ...prevState.project,
          archive_status: 1
        },
        error: {
          ...prevState.error,
          status: ''
        }
      }));
    }else{
      this.setState(prevState => ({
        ...prevState,
        projectStatusFlag: true,
        project:{
          ...prevState.project,
          status:status
        },
        error: {
          ...prevState.error,
          status: ''
        }
      }));
    }
  }

  toggleDelModal = () =>{
    this.setState({
      projectStatusFlag: false,
    })
  }

  toggleCloseModal = () =>{
    this.setState({
      projectCloseFlag: !this.state.projectCloseFlag,
    })
  }

  updateForm = (key,value) => {
    if(key=='name'){
      this.filterProjectList(value);
    }
    this.setState(prevState => ({
      ...prevState,
      project:{
        ...prevState.project,
        [key]:value
      },
      error: {
        ...prevState.error,
        [key]: ''
      }
    }));
  }
 filterProjectList=(value)=>{
  let filterArray=[];
   if(value.length>2){
      filterArray= this.props.inspectionType.filter(item => ((item.name.toUpperCase()).includes(value.toUpperCase())));
      if(filterArray.length>0){
        this.setState({
           inspectionType:filterArray,
           dropdownOpen: true
        });
       }else{
         this.setState({
          inspectionType:this.props.inspectionType,
          dropdownOpen: false
       });
       }
   }else{
    this.setState({
     inspectionType:this.props.inspectionType,
     dropdownOpen: true
    });
   }
 }

  addContact = (type) => {
    this.setState(prevState => ({
      ...prevState,
      project: {
        ...prevState.project,
        [type] : [ ...prevState.project[type], this.state.contactObj]
      }
    }));
  }
  deleteContact = (type, index) => {
    this.setState(prevState => ({
      ...prevState,
      project: {
        ...prevState.project,
        [type] : [...prevState.project[type]].filter((data, key) => key != index)
      }
    }));
  }
  handleChange = address => {
    this.setState(prevState => ({
      ...prevState,
      project: {
        ...prevState.project,
        location : address
      }
    }));
  };
  handleSelect = address => {
    let location =  geocodeByAddress(address);
    location.then(results =>
      getLatLng(results[0]))
      .then(latLng =>
        this.setState(prevState => ({
          ...prevState,
          project: {
            ...prevState.project,
            location : address,
            lat: latLng['lat'],
            lang: latLng['lng'],
          }
      })))
      .catch(error => console.error('Error', error));
      location.then(results =>
        results[0]
        ).then(country => {
          let place = country['address_components'].filter((data, index) =>   data['types'][0] == 'country'  );
          this.setState(prevState => ({
            ...prevState,
            project: {
              ...prevState.project,
                country: place.length ? place[0]['long_name'] : address
              }
            }))
           })
      .catch(error => console.error('Error', error));
  };
  addEditProject = (e) => {
    e.preventDefault();
    let validateNewInput = {
      name: checkString({value: this.state.project.name, required: true, minLength: '', maxLength: '', message: 'Please enter Project Name'}),
      start_date: checkValidDate({value: this.state.project.start_date, required: true, minLength: '', maxLength: '', message: 'Please enter Start Date'}),
      expected_end_date: checkValidDate({value: this.state.project.expected_end_date, required: false, minLength: '', maxLength: '', message: 'Please enter Expected End date'}),
      lessee_id: checkString({value: this.state.project.lessee_id, required: false, minLength: '', maxLength: '', message: 'Please select Lessee'}),
    };
    if(this.props.projectNames.filter(item => item.label == this.state.project.name).length == 0){
      this.setState(prevState =>({
        ...prevState,
        error: {...prevState.error, name: 'Please select a project name from dropdown'}
      }))
      return;
    }
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      this.props.addEditProject(this.state.project, this.props.project.type);
    }else{
      this.setState({
        error: validateNewInput
      });
    }
  }

  toggle(value) {
    this.setState(prevState => ({
      dropdownOpen: value
    }));
  }

  compare = (a, b) => {
  // Use toUpperCase() to ignore character casing
    const genreA = a.label.toUpperCase();
    const genreB = b.label.toUpperCase();

    let comparison = 0;
    if (genreA > genreB) {
      comparison = 1;
    } else if (genreA < genreB) {
      comparison = -1;
    }
    return comparison;
  }

  render(){
    const { project, error } = this.state;

    const { type } = this.props.project;
    return(
      <div>
      <form className="project-crud-form" onSubmit={(e) =>  this.addEditProject(e)}>
        <div className="sidebar-form" style={{ height: (window.innerHeight - 70) + 'px'}}>
          <Row>
            <Col md="12" style={{marginBottom: '20px'}}>
              {
                GetInputField(
                  'dropdown',
                  <label className="for-label">Project Name<img width="15" style={{marginLeft:'5px',cursor:'pointer'}} onClick={()=>this.props.workOrderInfo()}src={imgStoragePath+"help_question.png"} alt="img"/></label>,
                  { type: type,
                    value: project.name,
                    error: error.name,
                    options: this.props.projectNames.sort(this.compare),
                    labelKey: 'label',
                    valueKey: 'label',
                    updateForm: (value) => this.updateForm('name', value)
                  }
              )
            }
            {
            //   this.state.dropdownOpen?
            //  <ul className="list-unstyled filter-list" >
            //   { this.state.inspectionType.map((label, index) =>
            //     <li  onClick={() => {this.updateForm('name',label.name);this.toggle(false)}}  className="para-ui th-highlight">{label.name}</li>
            //   )}
            // </ul>:null
            }
            </Col>
            <Col md="12" style={{marginBottom: '20px'}}>
              {
                GetInputField(
                  'textarea',
                  <label className="for-label">Project Remarks</label>,
                  { type: type,
                    value: project.project_name_remarks,
                    error: error.project_name_remarks,
                    updateForm: (value) => this.updateForm('project_name_remarks', value)
                  }
              )
            }
            </Col>
            <Col md="6" style={{marginBottom: '20px'}}>
              {
                GetInputField(
                  'date',
                  <label className="for-label">Start Date {type != 'view'?<sup className="reqStar"> * </sup>:null}</label>,
                  { type: type,
                    value: project.start_date,
                    error: error.start_date,
                    updateForm: (value) => this.updateForm('start_date', value)
                  }
              )}
            </Col>
            <Col md="6" style={{marginBottom: '20px'}}>
              {
                GetInputField(
                  'date',
                  <label className="for-label">Expected End Date</label>,
                  { type: type,
                    error: error.expected_end_date,
                    value: project.expected_end_date,
                    updateForm: (value) => this.updateForm('expected_end_date', value)
                  }
              )}
            </Col>
            {project.remarks?
            <Col md="6" style={{marginBottom: '20px'}}>
              { GetInputField(
                  'string',
                  <label className="for-label">Project Extended Reason</label>,
                  { type: type,
                    value: project.remarks,
                    error: error.remarks,
                    updateForm: (value) => this.updateForm('remarks', value)
                  }
              )}
            </Col>:project.expected_end_date && this.state.projectExtendedValue &&
            moment(project.expected_end_date).diff(moment(this.state.projectExtendedValue)) > 0 ?

            <Col md="6" style={{marginBottom: '20px'}}>
            { GetInputField(
                'string',
                <label className="for-label">Project Extended Reason</label>,
                { type: type,
                  value: project.remarks,
                  error: error.remarks,
                  updateForm: (value) => this.updateForm('remarks', value)
                }
            )}
          </Col>:null
            }
            <Col md="6" style={{marginBottom: '20px'}}>
              {
                GetInputField(
                  'multiDropDown',
                  <label className="for-label">Project Lead</label>,
                  { type: type,
                    value: project.leads,
                    error: error.leads,
                    options: this.props.projectLead,
                    labelKey: 'name',
                    valueKey: 'id',
                    updateForm: (value) => this.updateForm('leads', value)
                  }
              )}
            </Col>
            <Col md="6" style={{marginBottom: '20px'}}>
              {
                GetInputField(
                  'dropdown',
                  <label className="for-label">Project Departments</label>,
                  { type: type,
                    value: project.department,
                    error: error.department,
                    options: this.props.projectDepartment,
                    labelKey: 'label',
                    valueKey: 'value',
                    updateForm: (value) => this.updateForm('department', value)
                  }
              )}
            </Col>
            <Col md="6" style={{marginBottom: '20px'}}>
              {
                GetInputField(
                  'multiDropDown',
                  <label className="for-label">Project Engineers</label>,
                  { type: type,
                    value: project.engineers,
                    error: error.engineers,
                    options: this.props.projectEngineer,
                    labelKey: 'name',
                    valueKey: 'id',
                    updateForm: (value) => this.updateForm('engineers', value)
                  }
              )}
            </Col>
            <Col md="6" style={{marginBottom: '20px'}}>
               <label className="for-label">Location</label>
               {
                 window.google !== undefined && window.google !== null ?
                 <PlacesAutocomplete
                      value={project.location}
                      onChange={this.handleChange}
                      onSelect={this.handleSelect}
                    >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                      <div style={{position: 'relative'}}>
                        <input
                          {...getInputProps({
                            placeholder: 'Search Location',
                            className: 'location-search-input',
                          })}
                        />
                        { project.location ?
                          <div className="autocomplete-dropdown-container" style={{position: 'absolute', top: '38px', width: '100%', zIndex: 100}}>
                          {loading && <div>Loading...</div>}
                          {suggestions.map(suggestion => {
                            const className = suggestion.active
                              ? 'suggestion-item--active'
                              : 'suggestion-item';
                            const style = suggestion.active
                              ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                              : { backgroundColor: '#ffffff', cursor: 'pointer' };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div> :  null
                        }
                      </div>
                    )}
                  </PlacesAutocomplete>
                 : GetInputField(
                     'string',
                     <span></span>,
                     { type: type,
                       value: project.location,
                       error: error.location,
                       updateForm: (value) => this.updateForm('location', value)
                     }
                 )
               }
            </Col>
            <Col md="6" style={{marginBottom: '20px'}}>
              { GetInputField(
                  'dropdown',
                  <label className="for-label">Lessee </label>,
                  { type: type,
                    value: project.lessee_id,
                    error: error.lessee_id,
                    labelKey: 'name',
                    valueKey: 'id',
                    options: this.props.sLesseeList,
                    updateForm: (value) => this.updateForm('lessee_id', value)
                  }
              )}
            </Col>
            <Col md="6" style={{marginBottom: '20px'}}>
              { GetInputField(
                  'string',
                  <label className="for-label">Third Party</label>,
                  { type: type,
                    value: project.third_party,
                    error: error.third_party,
                    updateForm: (value) => this.updateForm('third_party', value)
                  }
              )}
            </Col>
            <Col md="12" style={{marginBottom: '20px',cursor:'pointer'}}>
              <label className="for-label" style={{opacity: '1.0'}}>
                <input  type="checkbox"  value={project.cor_required} checked={project.cor_required}
                  onChange = {(e) => this.updateForm('cor_required', e.target.checked)}
                /> COR (Confidential Operator Report) Required
              </label>
            </Col>
            <Col className="add-contacts" md="12" style={{marginBottom: '20px'}}>
              <h4>Client Contact <Link onClick = {() => this.addContact('client_contacts')}>+ Add Contact</Link></h4>
              <table className="normal-table-layout">
                <thead>
                  <tr>
                    {['Name', 'Email', 'Designation','Phone', ''].map((label, index) => <th key={index}>{label}</th>)}
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(project).length && project.client_contacts?
                    project.client_contacts.map((data, index) => {
                      return(
                        <tr>
                          <td>
                            <input type="text" value={data.name} onChange= {(e) => this.updateJsonFormat('name',e.target.value, 'client_contacts', index)} />
                          </td>
                          <td style={{width: '300px'}}>
                            <input type="text" value={data.email} onChange= {(e) => this.updateJsonFormat('email',e.target.value, 'client_contacts', index)}/>
                          </td>
                          <td>
                            <input type="text" value={data.designation} onChange= {(e) => this.updateJsonFormat('designation',e.target.value, 'client_contacts', index)} />
                          </td>
                          <td >
                            <input type="text" value={data.phone_number} onChange= {(e) => this.updateJsonFormat('phone_number',e.target.value, 'client_contacts', index)} />
                          </td>
                          <td style={{width: '45px'}}>
                         {
                          index != 0 ?
                          <img  onClick={() => this.deleteContact('client_contacts', index)} style={{width: '12px', cursor:'pointer'}} src={imgStoragePath + "blue_delete_icon.png"} alt="" />
                          : null
                          }
                        </td>
                        </tr>
                      )}):null
                  }
                </tbody>
              </table>
            </Col>
            <Col className="add-contacts" md="12" style={{marginBottom: '20px'}}>
              <h4>Lessee Contact<Link onClick = {() => this.addContact('operator_contacts')}>+ Add Contact</Link></h4>
              <table className="normal-table-layout">
                <thead>
                  <tr>
                    {['Name', 'Email', 'Designation','Phone', ''].map((label, index) => <th key={index}>{label}</th>)}
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(project).length && project.operator_contacts?
                    project.operator_contacts.map((data, index) => {
                      return(
                        <tr>
                          <td>
                            <input type="text" value={data.name}  onChange= {(e) => this.updateJsonFormat('name',e.target.value, 'operator_contacts', index)} />
                          </td>
                          <td style={{width: '300px'}}>
                            <input type="text" value={data.email}  onChange= {(e) => this.updateJsonFormat('email',e.target.value, 'operator_contacts', index)} />
                          </td>
                          <td>
                            <input type="text" value={data.designation}  onChange= {(e) => this.updateJsonFormat('designation',e.target.value, 'operator_contacts', index)} />
                          </td>
                          <td >
                            <input type="text" value={data.phone_number} onChange= {(e) => this.updateJsonFormat('phone_number',e.target.value, 'operator_contacts', index)} />
                          </td>
                          <td style={{width: '45px'}}>
                          {
                            index != 0 ?
                            <img  onClick={() => this.deleteContact('operator_contacts', index)} style={{width: '12px', cursor:'pointer'}} src={imgStoragePath + "blue_delete_icon.png"} alt="" />
                            : null }
                          </td>
                        </tr>
                      )}):null
                  }
                </tbody>
              </table>
            </Col>
            <Col className="add-contacts" md="12" style={{marginBottom: '20px'}}>
              <h4>Third Party Contact <Link onClick = {() => this.addContact('third_party_contacts')}>+ Add Contact</Link></h4>
              <table className="normal-table-layout">
                <thead>
                  <tr>
                    {['Name', 'Email', 'Designation','Phone', ''].map((label, index) => <th key={index}>{label}</th>)}
                  </tr>
                </thead>
                <tbody>
                { Object.keys(project).length && project.third_party_contacts ?
                  project.third_party_contacts.map((data, index) => {
                    return(
                      <tr>
                        <td>
                          <input type="text" value={data.name} onChange= {(e) => this.updateJsonFormat('name',e.target.value, 'third_party_contacts', index)}/>
                        </td>
                        <td style={{width: '300px'}}>
                          <input type="text" value={data.email} onChange= {(e) => this.updateJsonFormat('email',e.target.value, 'third_party_contacts', index)}/>
                        </td>
                        <td>
                          <input type="text" value={data.designation} onChange= {(e) => this.updateJsonFormat('designation',e.target.value, 'third_party_contacts', index)}/>
                        </td>
                        <td>
                            <input type="text" value={data.phone_number} onChange= {(e) => this.updateJsonFormat('phone_number',e.target.value, 'third_party_contacts', index)}/>
                        </td>
                        <td style={{width: '45px'}}>
                           {
                            index != 0 ?
                            <img  onClick={() => this.deleteContact('third_party_contacts', index)} style={{width: '12px', cursor:'pointer'}} src={imgStoragePath + "blue_delete_icon.png"} alt="" />
                            : null
                            }
                          </td>
                    </tr>
                  )}):null
                }
                </tbody>
              </table>
            </Col>
            <Col className="add-ck-editor" md="12" style={{marginBottom: '20px'}}>
              <h4>General Instruction</h4>
              <CKeditor
                events={{"change": this.updateGeneralInstructions}}
                content={this.state.project.instructions}
              />
            </Col>
            <Col className="add-ck-editor" md="12" style={{marginBottom: '20px'}}>
              <h4>Miscellaneous comments</h4>
              <CKeditor
                events={{"change": this.miscComments}}
                content={this.state.project.miscellaneous_comments}
              />
            </Col>
            <Col className="add-ck-editor" md="12" style={{marginBottom: '20px'}}>
              <h4>Equipment requirement</h4>
              <CKeditor
                events={{"change": this.equipmentRequiremets}}
                content={this.state.project.equipment_requirements}
              />
            </Col>
          </Row>
          { type == 'edit' ?
           <Row>
            <Col md="12" style={{marginBottom: '20px'}}>
            { GetInputField(
                'textarea',
                <label className="for-label">Closure Notes</label>,
                { type: type,
                  value: project.closure_notes,
                  error: error.closure_notes,
                  updateForm: (value) => this.updateForm('closure_notes', value)
                }
            )}
            </Col>
            <Col md="12" style={{marginBottom: '20px'}}>
            { GetInputField(
                'date',
                <label className="for-label">Operator Contacted at</label>,
                { type: type,
                  value: project.operator_contacted_at,
                  error: error.operator_contacted_at,
                  updateForm: (value) => this.updateForm('operator_contacted_at', value)
                }
            )}
            </Col>
        </Row>
         : '' }

         <Row className="delete-archive-project">
         <Col md="12" style={{marginBottom: '20px'}}>
            {
                type == 'edit' ?
                  <Link style={{color: 'red', fontSize: '14px'}} onClick={() => this.toggleCloseModal()}>{this.props.projectLoader ? 'Processing...':'Close Project'}</Link>
                :null
              }
           </Col>
           <Col md="12" style={{marginBottom: '20px'}}>
            {
                type == 'edit' ?
                  <Link style={{color: 'red', fontSize: '14px'}} onClick={() => this.changeProjectStatus(0)}>{this.props.projectLoader ? 'Processing...':'Archive Project'}</Link>
                :null
              }
           </Col>
           <Col md="12" style={{marginBottom: '20px', fontSize: '14px'}}>
             {
                type == 'edit' ?
                  <Link style={{color: 'red'}} onClick={() => this.changeProjectStatus(8)}>{this.props.projectLoader ? 'Processing...':'Cancel Project'}</Link>
                :null
              }
           </Col>
         </Row>
        </div>


        { type != 'view' ?
          <div className="edit-sidebar-footer">
            <ul className="list-inline">
              <li className="list-inline-item">
                <button disabled={this.props.projectLoader} type="submit" className="btn btn-primary">{this.props.projectLoader ? 'Processing...':'Save'}</button>
              </li>


              <li className="list-inline-item">
                <Link onClick={() => this.props.toggleEditSideBar({type: 'add', flag: false, data: {}})}> Cancel</Link>
              </li>
             </ul>
          </div>:null
        }
      </form>
      <Modal isOpen={this.state.projectStatusFlag} toggle={() => this.toggleDelModal([])} className="modal-dialog-centered modal-tform-delete-pictures">
        <ModalHeader toggle={() => this.toggleDelModal([])}>
          {
            this.state.project.archive_status == 1 ? 'Do you want to archive this project?' : 'Do you want to cancel this project?'
          }
        </ModalHeader>
        <ModalBody>
          <ul className="list-inline">
              <li className="list-inline-item">
                <button type="button" className="btn btn-danger" onClick={() => {this.props.addEditProject(this.state.project, this.props.project.type);this.props.toggleEditSideBar({type: 'add', flag: false, data: {}});this.toggleDelModal([]);}}>Yes</button>
              </li>
              <li className="list-inline-item">
                <button type="button" className="btn btn-primary" onClick={() => this.toggleDelModal([])}>No</button>
              </li>
          </ul>
        </ModalBody>
      </Modal>
      <Modal isOpen={this.state.projectCloseFlag} toggle={() => this.toggleCloseModal([])} className="modal-dialog-centered modal-tform-delete-pictures">
        <ModalHeader toggle={() => this.toggleCloseModal([])}>
          {
           'Do you want to close this project?'
          }
        </ModalHeader>
        <ModalBody>
          <ul className="list-inline">
              <li className="list-inline-item">
                <button type="button" className="btn btn-danger" onClick={() => {this.props.closeProject(project.slug);this.toggleCloseModal([])}}>Yes</button>
              </li>
              <li className="list-inline-item">
                <button type="button" className="btn btn-primary" onClick={() => this.toggleCloseModal([])}>No</button>
              </li>
          </ul>
        </ModalBody>
      </Modal>

    </div>
    )
  }
}
