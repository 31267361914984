import React from 'react';
import {Chip} from '@material-ui/core';

const StatsWidget = ({statsSummary}) => {
    return(
        <div style={{marginBottom:'15px'}}>
            <ul className='list-inline'>
                <li className='list-inline-item'>
                    <Chip style={{background:'#a2a2a2'}} label={`Total ${statsSummary?.total_items||0}`} variant="outlined" />
                </li>
                <li className='list-inline-item'>
                    <Chip style={{background:'#8cb2f2'}} label={`Open ${statsSummary?.open_items||0}`} variant="outlined" />
                </li>
                <li className='list-inline-item'>
                    <Chip style={{background:'#6dbb71'}} label={`Closed ${statsSummary?.closed_items||0}`} variant="outlined" />
                </li>
                <li className='list-inline-item'>
                    <Chip style={{background:'#de8f79'}} label={`Reopen ${statsSummary?.reopen_items||0}`} variant="outlined" />
                </li>
                <li className='list-inline-item'>
                    <Chip style={{background:'#e8dc61'}} label={`Unresolved ${statsSummary?.unresolved_items||0}`} variant="outlined" />
                </li>
            </ul>
        </div>
    )
}
export default StatsWidget;