import React, { useState, Fragment, useEffect } from 'react';
import { withRouter } from 'react-router';
import { useSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Table, TableHead, TableBody, TableRow, TableCell, CircularProgress } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import AddIcon from '@material-ui/icons/Add';
import { checkApiStatus } from '../../../utils_v2';
import { globalPostService } from '../../../utils_v2/globalApiServices';
import { trackActivity } from '../../../utils/mixpanel';
const sectionObj = {name:'',notes:''};
const subSectionObj = {name:'',notes:''};
const questionObj = {name:'', notes:''};
const AddNewContent = ({params, addType, sectionId, subSectionId, documentCheckList, questionList, getResponseBack, open, toggleModalFn}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [modal, setModal] = useState(open? true:false);
    const [duplicateError, setDuplicateError] = useState([]);
    const [error, setError] = useState([]);
    const [formData, setFormData] = useState([]);
    const findDuplicates = (strArray) => {
        return strArray.filter((item, index) => strArray.indexOf(item.trim()) !== index)
    }
    useEffect(()=>{
        if(addType === 'sub-section'){
            setFormData([...formData,sectionObj])
        }
    },[])
    const onAddNewItem = () => {
        if(addType === 'section'){
            setFormData([...formData,sectionObj])
            trackActivity('Project Management', {
                event_type: 'Section Added',
                page_title: 'T013 Form',
                workOrder_slug: params.workOrderSlug ? params.workOrderSlug : '',
                section_id: sectionId ? sectionId : '',
                add_type: addType ? addType : '',
                section_name: sectionObj ? sectionObj : {}
            });
        }else if(addType === 'sub-section'){
            setFormData([...formData,subSectionObj])
            trackActivity('Project Management', {
                event_type: 'Sub Section Added',
                page_title: 'T013 Form',
                workOrder_slug: params.workOrderSlug ? params.workOrderSlug : '',
                subb_section_id: subSectionId ? subSectionId : '',
                add_type: addType ? addType : '',
                sub_section_name: subSectionObj ? subSectionObj : {}
            });
        }else{
            setFormData([...formData,questionObj])
        }
    }
    const onRemoveItem = (itemIndex) => {
        setFormData(formData.filter((item, index) => index !== itemIndex));
    }
    const onFieldChange = (keyParam, value, itemIndex) => {
        setFormData(formData.map((item, index) => index !== itemIndex ? item : {...item, [keyParam]:value}));
    }
    const resetErrorKey = (key, index) => {
        setError(error.map((item, itemIndex) => itemIndex !== index ? item : {...item, [key]:''}));
        setDuplicateError([]);
    }
    const onSubmitAdd = () => {
        let payload = Object.assign([], formData), errors = [], isError=false;        
        errors = payload.map((item) => {
            return {
                name:item.name && item.name.trim().length ?'':'Please enter Name'
            }
        });
        errors.map(data => {
            if(!Object.keys(data).every((k) => { return data[k] === ''})){
              isError = true
            }
        });
        if(!isError){
            // Check for Duplicate Entry
            let arr = [];
            if(addType === 'section'){
                arr = [...formData, ...documentCheckList.map(section=> {return{id:section.id, name:section.name}})].map((item) => item.name.toLowerCase());
            }else if(addType === 'sub-section'){
                let subSecArr = documentCheckList.find(section => section.id === sectionId).sub_sections
                arr = subSecArr && subSecArr.length ? [...formData, ...subSecArr.map(subSec => {return{id:subSec.id, name:subSec.name}})].map((item) => item.name.toLowerCase()):formData.map((item) => item.name.toLowerCase());
            }else{
                let qusArr = questionList;
                arr = qusArr && qusArr.length ? [...formData, ...qusArr.map(qus => {return{id:qus.id, name:qus.name}})].map((item) => item.name.toLowerCase()):formData.map((item) => item.name.toLowerCase());
            }
            if(findDuplicates(arr).length === 0){
                setLoading(true);
                if(addType === 'section'){
                    globalPostService(`technical/v2/workorder/${params.workOrderSlug}/workbook/sections/?asset_type=${params.type}`, payload)
                    .then(response => {
                        setLoading(false);
                        if(checkApiStatus(response)){
                            setModal(false);setFormData([]);setError([]);
                            getResponseBack(response.data.data);
                            enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
                        }else{
                            enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
                        }
                    });
                }
                else if(addType === 'sub-section'){
                    payload = formData.map(item => {return{...item}})
                    globalPostService(`technical/v2/workorder/${params.workOrderSlug}/workbook/sections/${sectionId}/sub-section/?asset_type=${params.type}`, payload)
                    .then(response => {
                        setLoading(false);
                        if(checkApiStatus(response)){
                            if(addType=='sub-section'){ toggleModalFn()}
                            setModal(false);setFormData([]);setError([]);
                            getResponseBack(response.data.data);
                            enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
                        }else{
                            enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
                        }
                    });
                }else if(addType === 'question'){
                    payload = formData.map(item => {return{...item}})
                    globalPostService(`technical/v2/workorder/${params.workOrderSlug}/workbook/sections/${sectionId}/sub-section/${subSectionId}/checklists/?asset_type=${params.type}`, payload)
                    .then(response => {
                        setLoading(false);
                        if(checkApiStatus(response)){
                            setModal(false);setFormData([]);setError([]);
                            getResponseBack(response.data.data);
                            enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
                        }else{
                            enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
                        }
                    });
                }
               
            }else{
                //Duplicate Entries
                setDuplicateError(findDuplicates(arr));
                enqueueSnackbar('Found Duplicate Entries', {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
            }
        }else{
            setError(errors);
        }
    }
    return(
        <Fragment>
            <Button startIcon={<AddIcon color='primary' fontSize='small' />} onClick={(e) => {e.stopPropagation(); setModal(true); setFormData(addType === 'section' ? [sectionObj]: addType === 'sub-section' ? [subSectionObj]:[questionObj])}} color='primary' variant='outlined' size='small'>{addType === 'section'? 'Add Section': addType === 'sub-section' ? 'Add Sub Section':'Add Checklist'}</Button>
            { modal ||open ?
                <Dialog
                    open={modal || open}
                    onClose={(e) => {e.stopPropagation(); e.preventDefault()}}
                    maxWidth={'lg'}
                    className='project-management-modal'
                >
                    <DialogTitle id="scroll-dialog-title">
                        {addType === 'section'? 'Add Section(s)': addType ==='sub-section' ? 'Add Sub-Section(s)':'Add Checklist(s)'}
                    </DialogTitle>
                    <DialogContent dividers={true}>
                        <div style={{width:'680px'}}>
                            <Grid container spacing={1}>
                                <Grid item md={12}>
                                    {duplicateError.length ?
                                        <div>
                                            <h6>{addType === 'section'? 'Duplicate Section(s)': addType ==='sub-section' ? 'Duplicate Sub-Section(s)':'Duplicate Checklist(s)'}</h6>
                                            {duplicateError.map((err,errIndex) => <p className='error-msg'>- {err}</p>)}
                                        </div>:null
                                    }
                                    <Table className={'mui-table-format'}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Name<sup>*</sup></TableCell>
                                                 <TableCell>Notes</TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {formData.map((item, index) =>
                                                <TableRow key={index}>
                                                    <TableCell>
                                                        <TextField
                                                            id={`name_${index}`}
                                                            fullWidth
                                                            margin="none"
                                                            multiline
                                                            InputLabelProps={{shrink: true}}
                                                            value={item.name?item.name:''}
                                                            error={error && error[index] && error[index].name ? true:false}
                                                            helperText={error && error[index] && error[index].name ? error[index].name:''}
                                                            onChange={(e) => onFieldChange('name', e.target.value,index)}
                                                            onFocus={() => resetErrorKey('name', index)}
                                                            variant='outlined'
                                                        />
                                                    </TableCell>

                                                    {/* { addType === 'question' ? */}
                                                        <TableCell>
                                                            <TextField
                                                                id={`notes_${index}`}
                                                                fullWidth
                                                                margin="none"
                                                                multiline
                                                                maxRows={4}
                                                                InputLabelProps={{shrink: true}}
                                                                value={item.notes ? item.notes:''}
                                                                onChange={(e) => onFieldChange('notes', e.target.value,index)}
                                                                variant='outlined'
                                                            />
                                                        </TableCell>
                                                        {/* :null} */}
                                                    <TableCell style={{width:'40px'}}>
                                                        <div style={{width:'40px'}}>
                                                            { formData.length > 1 ?
                                                                <DeleteOutlineIcon onClick={() => onRemoveItem(index)} color='secondary' fontSize='small' /> :null
                                                            }
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                    <Button startIcon={<AddIcon color='primary' fontSize='small' />} onClick={onAddNewItem} color='primary' variant='outlined' size='small' style={{marginTop:'5px'}}>Add More</Button>
                                </Grid>
                            </Grid>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={isLoading} onClick={() => { addType=='sub-section'?toggleModalFn() : setModal(false);setError([]);setFormData([]);setDuplicateError([])}} color="primary" size='small' variant='outlined'>Cancel</Button>
                        <Button disabled={isLoading} onClick={onSubmitAdd} color="primary" size='small' variant='contained'>
                            {isLoading ? <CircularProgress size={24} color='primary' />:'Save'}
                        </Button>
                    </DialogActions>
                </Dialog>:null
            }
        </Fragment>
    )
}

export default withRouter(AddNewContent);