import React from 'react';
import { imgStoragePath } from '../../../constants';
export default function ContentDrop(props){
  return(
    <div className="content-drop-blk" onDrop={(evt) => props.onDrop ? props.onDrop(evt) : console.log(evt)}>
      {
        props.mutlipleEnabled ?
        <input
          type="file"
          multiple
          accept={props.acceptExtensions}
          style={{position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', opacity: 0, zIndex: 10}}
          onChange={(e) => props.filesUpload(e)}
        />
        :<input
          type="file"
          accept={props.acceptExtensions}
          style={{position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', opacity: 0, zIndex: 10}}
          onChange={(e) => props.filesUpload(e)}
        />
      }
      {
        props.error ?
        <h6 className="error-msg">{props.error}</h6>
        :null
      }
      <div>
        <img width="30" src={imgStoragePath+"content_attachment.png"} alt="Attachment" />
        {props.screenshot?
         <p>
          Drag and Drop Screenshots Here
          or  Click here to choose the Screenshots
        </p>:
        props.content?
        <p>
        Drag & Drop Or Click here to choose Document(s)
        </p>
        :<p>
          Drag and Drop Pictures Here
          or  Click here to choose the Pictures
        </p>
        }

      </div>
    </div>
  )
}
