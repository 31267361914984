import React, {Component, Fragment} from 'react';
import {Row, Col} from 'reactstrap';
import {Link} from 'react-router';
class User extends Component {
  constructor(props){
    super(props)
  }
  render() {

    return(
    <section className="secondary-sidenav-section">
        <ul className="list-unstyled secondary-sidenav">
            <li>
                <Link to="/settings/role" className={window.location.href.includes('role') ? "active" : ''}>Roles</Link>
            </li>
            <li>
                <Link to="/settings/users" className={window.location.href.includes('users') ? "active" : ''}>Users</Link>
            </li>
        </ul>
    </section>
    )
  }
}
export default User;
