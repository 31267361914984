import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { Grid } from '@material-ui/core'
import CategoryWiseChart from './CategoryWiseChart';
import IssueStatusChart from './IssueStatusChart';
import TeamWiseChart from './TeamWiseChart';
import { checkApiStatus } from '../../../utils_v2';
import { globalGetService } from '../../../utils_v2/globalApiServices';
const PRSAnalytic = ({params, prsCategories}) => {
    const [usersStats, setUsersStats] = useState([]);
    useEffect(() => {
        getUserGraph();
    },[]);
    const getUserGraph = () => {
        globalGetService(`technical/workorder/${params.workOrderSlug}/assignee-plot-data/?asset_type=${params.type}`)
        .then(response => {
            if(checkApiStatus(response)){
                setUsersStats(response.data.data);
            }else{

            }
        });
    }
    return(
        <Grid container spacing={1}>
            <Grid item md={6}>
                <IssueStatusChart prsCategories={prsCategories} />
            </Grid>
            <Grid item md={6}>
                <TeamWiseChart usersStats={usersStats} />
            </Grid>
            { prsCategories?.list?.length &&
                <Grid item md={12}>
                    <CategoryWiseChart categories={prsCategories} />
                </Grid>
            }
        </Grid>
    )
}
export default withRouter(PRSAnalytic);