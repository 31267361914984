import * as actions from '../actions';
import { globalGetService, globalPostService, globalFileUploadService, globalStaticPostService } from '../../../../../globalServices';
import { toastFlashMessage } from '../../../../../utils';
import { getFormDetailsAc } from '../../T001/actionCreators';
import { usageDetailAc } from '../../../../../shared/actionCreators';
import { trackActivity } from '../../../../../utils/mixpanel';
export const fetchT005DataAc = (props) => {
  return (dispatch) => {
    dispatch(triggeLoader(true));
    globalGetService(`/technical/workorder/${props.params.workOrderSlug}/technical-specification-sections/`)
      .then(response => {
        if (response.data.statusCode == 200) {
          dispatch({
            type: actions.GET_TECHNICAL_SPECS,
            payload: response.data.data
          })
        }
        dispatch(triggeLoader(false));
      })
  }
}

export const uploadT005AttachmentAc = (props, file, sectionId, label, componet_id = null, sub_component_id = null) => {
  return (dispatch) => {
    var formData = new FormData();
    formData.append('label', label);
    formData.append('file', file[0]);
    if (componet_id) {
      formData.append('component_id', componet_id);
    }
    if (sub_component_id) {
      formData.append('sub_component_id', sub_component_id);
    }
    var config = {
      onUploadProgress: function (progressEvent) {
        var percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        // dispatch({
        //   type: actions.PROGRESS_PERCENTAGE,
        //   payload: percentage
        // });
      }
    }
    globalFileUploadService('/technical/workorder/' + props.params.workOrderSlug + '/section/' + sectionId + '/upload-file/', formData, config)
      .then(response => {
        dispatch(usageDetailAc())
        dispatch(fetchT005DataAc(props));
        dispatch(getFormDetailsAc(props));
        trackActivity('T005', {
          event_type: 'File Added',
          page_title: 'Technical Specifications',
          workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
          form_slug: props.params.formSlug ? props.params.formSlug : '',
          asset_type: props.params.type ? props.params.type : '',
          aircraft_slug: props.params.aircraft_slug ? props.params.aircraft_slug : '',
          request_body: formData ? formData : '',
          section_id: sectionId ? sectionId : ''
        })
      })
  }
}

export const saveT005DataAc = (props, data) => {
  return (dispatch) => {
    dispatch(triggeLoader(true));
    globalStaticPostService('/technical/workorder/' + props.params.workOrderSlug + '/technical-specification-sections/save/', data)
      .then(response => {
        if (response.data.statusCode == 200) {
          toastFlashMessage(response.data.message, 'success');
          dispatch(fetchT005DataAc(props));
          trackActivity('T005', {
            event_type: 'Data Saved',
            page_title: 'Technical Specifications',
            workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
            form_slug: props.params.formSlug ? props.params.formSlug : '',
            asset_type: props.params.type ? props.params.type : '',
            aircraft_slug: props.params.aircraft_slug ? props.params.aircraft_slug : '',
            request_body: data ? data : ''
          })
        } else {
          trackActivity('T005', {
            event_type: 'Data Save Failed',
            page_title: 'Technical Specifications',
            workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
            form_slug: props.params.formSlug ? props.params.formSlug : '',
            asset_type: props.params.type ? props.params.type : '',
            aircraft_slug: props.params.aircraft_slug ? props.params.aircraft_slug : '',
            request_body: data ? data : '',
            error_source: 'Backend',
            error_msg: response.data.message ? response.data.message : ''
          })
        }
        dispatch(triggeLoader(false));
        dispatch(getFormDetailsAc(props));
      })
  }
}

export const removeComponentAc = (props, sectionIndex, componentIndex, subComponentIndex, id, sectionId) => {
  return (dispatch) => {
    let data = { custom_component_ids: [id] };
    dispatch(triggeLoader(true));
    globalPostService('/technical/workorder/' + props.params.workOrderSlug + '/section/' + sectionId + '/custom-components/delete/', data)
      .then(response => {
        if (response.data.statusCode == 200) {
          toastFlashMessage(response.data.message, 'success');
          trackActivity('T005', {
            event_type: 'Remove Component',
            page_title: 'Technical Specifications',
            workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
            form_slug: props.params.formSlug ? props.params.formSlug : '',
            asset_type: props.params.type ? props.params.type : '',
            aircraft_slug: props.params.aircraft_slug ? props.params.aircraft_slug : '',
            id: id ? id : '',
            section_id: sectionId ? sectionId : '',
            section_index: sectionIndex ? sectionIndex : '',
            request_body: data ? data : ''
          })
          dispatch({
            type: actions.REMOVE_COMPONENT,
            payload: { sectionIndex, componentIndex, subComponentIndex }
          })
        }
        dispatch(getFormDetailsAc(props));
        dispatch(triggeLoader(false));
      })
  }
}

export const removesubComponentIdAc = (props, sectionIndex, componentIndex, subComponentIndex, customSubComponentIndex, component, sectionId) => {
  return (dispatch) => {
    let ids = [];
    component.sub_components.map((subComponent, subComponentIndex) => {
      subComponent.custom_components.map((customSubComponent, customComponentIndex) => {
        if (customComponentIndex == customSubComponentIndex) {
          ids.push(customSubComponent.id)
        }
      }
      )
    });
    let data = { custom_component_ids: ids };
    dispatch(triggeLoader(true));
    globalPostService('/technical/workorder/' + props.params.workOrderSlug + '/section/' + sectionId + '/custom-components/delete/', data)
      .then(response => {
        if (response.data.statusCode == 200) {
          toastFlashMessage(response.data.message, 'success');
          dispatch({
            type: actions.REMOVE_SUB_COMPONENT,
            payload: { sectionIndex, componentIndex, subComponentIndex, customSubComponentIndex }
          })
          trackActivity('T005', {
            event_type: 'Remove Sub Component',
            page_title: 'Technical Specifications',
            workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
            form_slug: props.params.formSlug ? props.params.formSlug : '',
            asset_type: props.params.type ? props.params.type : '',
            aircraft_slug: props.params.aircraft_slug ? props.params.aircraft_slug : '',
            section_id: sectionId ? sectionId : '',
            component: component ? component : '',
            custom_sub_component_index: customSubComponentIndex ? customSubComponentIndex : '',
            section_index: sectionIndex ? sectionIndex : '',
            request_body: data ? data : '',
            response_body: response.data.data ? response.data.data : ''
          })
        }
        dispatch(getFormDetailsAc(props));
        dispatch(triggeLoader(false));
      })
  }
}

export const fetchAircraftModelAc = () => {
  return (dispatch) => {
    globalGetService('console/aircraft-types/', {})
      .then(response => {
        if (response.data.statusCode == 200) {
          let data = [];
          response.data.data.aircraftTypes.map(aircraftType => {
            data = [...data, { value: aircraftType.id, label: aircraftType.name }]
          });
          dispatch({
            type: actions.T005_AIRCRAFT_MODEL,
            payload: data
          })
        }
      })
  }
}
export const fetchOperatorAc = () => {
  return (dispatch) => {
    globalGetService('console/lessees/', {})
      .then(response => {
        if (response.data.statusCode == 200) {
          let data = [];
          response.data.data.lessees.map(lessee => {
            data = [...data, { value: lessee.id, label: lessee.name }]
          })
          dispatch({
            type: actions.T005_OPERATOR_LIST,
            payload: data
          })
        }
      })
  }
}
export const fetchEngineModelAc = () => {
  return (dispatch) => {
    globalGetService('console/engine-types/', {})
      .then(response => {
        if (response.data.statusCode == 200) {
          let data = [];
          response.data.data.engineTypes.map(engine => {
            data = [...data, { label: engine.name, value: engine.id }]
          })
          dispatch({
            type: actions.T005_ENGINE_MODEL,
            payload: data
          })
        }
      })
  }
}
export const triggeLoader = (flag) => {
  return (dispatch) => {
    dispatch({
      type: actions.TECHNICAL_SPECS_LOADER,
      payload: flag
    })
  }
}
