import React from "react";
import { Table, TableCell, TableHead, TableRow } from '@material-ui/core';
const ObservationHeader = ({}) => {
    return(
        <TableHead>
            <TableRow>
                <TableCell>Section Name</TableCell>
                <TableCell colSpan={3}>
                    <Table>
                        <TableRow>
                            <TableCell align="center" colSpan={3}>Conditions</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" style={{width:'100px'}}>Poor</TableCell>
                            <TableCell align="center" style={{width:'100px'}}>Average</TableCell>
                            <TableCell align="center" style={{width:'100px'}}>Good</TableCell>
                        </TableRow>
                    </Table>
                </TableCell>
                <TableCell>Total</TableCell>
                <TableCell align="right">Action</TableCell>
            </TableRow>
        </TableHead>
    )
}
export default ObservationHeader;