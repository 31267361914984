import * as actions from '../actions';
export default function( state = {
  searchResult:{
    "data": {
        "results": [],
        "pagination": {
            "total": 0,
            "current_page": "0",
            "number_of_pages": 0
        }
    },
    "statusCode": 200,
    "message": "Search results fetched successfully!"
},
gloableSearchLoader: false,
downloadLoader: false,
previewCard: {},
filterOptions: {}
}, action){
  switch (action.type) {
    case actions.GLOBAL_SEARCH_RESULT:
      return{
        ...state,
        searchResult: action.payload
      }
    case actions.ASSET_FILTERS:
      return{
        ...state,
        filterOptions: action.payload
      }
    case actions.GLOBAL_SEARCH_LOADER:
      return{
        ...state,
        gloableSearchLoader: action.payload
      }
    case actions.DOWNLOAD_LOADER:
      return {
        ...state,
        downloadLoader: action.payload
      }
    case actions.PREVIEW_RECORD:
      return{
        ...state,
        previewCard: action.payload
      }
    default:
      return state
  }
}
