import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { withRouter } from 'react-router';
import { Button } from '@material-ui/core'
import { WorksScopeTable } from '../../../shared_elements';
import { globalGetService, globalPutService } from '../../../utils_v2/globalApiServices';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import { checkApiStatus } from '../../../utils_v2';
import { PageLoader, EmptyCollection, STableLoader } from '../../../shared_elements';
import EditIcon from '@material-ui/icons/Edit';
const workScopeObj = { name:'', subModule:[ { name:'', items:[ { moduleNo: '', moduleDescription: '', initialLevel: '', finalLevel:'' } ] } ] }
const sbWorkScopeObj = { name:'', items:[ { moduleNo: '', moduleDescription: '', initialLevel: '', finalLevel:'' } ] }
const InspectionWorkScope = ({params, currentFormDetail}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [isEditAble, setEditAble] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [workScopeInfo, setWorkScopeInfo] = useState({});
    useEffect(() => {
        getWorkScopeInfo('skeletonLoader');
    },[]);
    const getWorkScopeInfo = (loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true):setLoading(true);
        globalGetService(`technical/v2/workorder/${params.workOrderSlug}/table-inspections/work-scope/`)
        .then(response => {
            if(checkApiStatus(response)){
                setWorkScopeInfo(response.data.data);
            }
            loaderType === 'skeletonLoader' ? setSkeletonLoader(false):setLoading(false);
        })
    }

    const onSaveWorkScopeInfo = () => {
        setLoading(true);
        globalPutService(`technical/v2/workorder/${params.workOrderSlug}/table-inspections/work-scope/`, workScopeInfo)
        .then(response => {
            if(checkApiStatus(response)){
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                setEditAble(false);
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
            }
            setLoading(false);
        })
    }

    const addWorkScopeModuleFn = () => {
        setWorkScopeInfo({
            ...workScopeInfo,
            workscope_modules: [...workScopeInfo.workscope_modules, workScopeObj]
        })
    }
    const addSBWorkScopeModuleFn = (arrayIndex) => {
        setWorkScopeInfo({
            ...workScopeInfo,
            workscope_modules: workScopeInfo.workscope_modules.map((majorMod,index) => index !== arrayIndex ? majorMod: {
                ...majorMod,
                subModule:[...majorMod.subModule, sbWorkScopeObj]
            })
        })
    }
    const addWorkScopeItem = (arrayIndex, innerIndex) => {
        setWorkScopeInfo({
            ...workScopeInfo,
            workscope_modules:workScopeInfo.workscope_modules.map((majorMod,index) => index !== arrayIndex ? majorMod: {
                ...majorMod,
                subModule:majorMod.subModule.map((sb, childIndex) => childIndex !== innerIndex ? sb: {
                    ...sb,
                    items:[...sb.items, {moduleNo:'',moduleDescription:'',initialLevel:'',finalLevel:''}]  
                })
            })
        })
    }
    const updateWorkScope = (event, keyParam, data, type, arrayIndex, innerArrayIndex, lastArrayIndex) => {
        switch (type) {
          case 'majorModule':
            setWorkScopeInfo({
                ...workScopeInfo,
                workscope_modules:workScopeInfo.workscope_modules.map((majorMod,index) => index !== arrayIndex ? majorMod:{
                    ...majorMod,
                    [keyParam]:data
                })
            })
            break;
          case 'childModule':
            setWorkScopeInfo({
                ...workScopeInfo,
                workscope_modules:workScopeInfo.workscope_modules.map((majorMod,index) => index !== arrayIndex ? majorMod : {
                    ...majorMod,
                    subModule:majorMod.subModule.map((childModule,childIndex) => childIndex !== innerArrayIndex ? childModule :{
                        ...childModule,
                        [keyParam]:data
                    })
                })
            })
            break;
          case 'itemModule':
            setWorkScopeInfo({
                ...workScopeInfo,
                workscope_modules: workScopeInfo.workscope_modules.map((majorMod,index) => index !== arrayIndex ? majorMod: {
                    ...majorMod,
                    subModule: majorMod.subModule.map((childModule,childIndex) => childIndex !== innerArrayIndex ? childModule: {
                        ...childModule,
                        items:childModule.items.map((itemData,itemIndex) => itemIndex !== lastArrayIndex ? itemData : {
                            ...itemData,
                            [keyParam]:data
                        })
                    })
                })
            })
            break;
          default:
        }
    }
    const deleteWorkScopeItem = (arrayIndex, innerArrayIndex, lastArrayIndex) => {
        const wkScope = Object.assign([], workScopeInfo.workscope_modules)
        if(workScopeInfo.workscope_modules[arrayIndex].subModule.length === 1 && workScopeInfo.workscope_modules[arrayIndex].subModule[innerArrayIndex].items.length === 1){
            wkScope.splice(arrayIndex,1);
            setWorkScopeInfo({
                ...workScopeInfo,
                workscope_modules:wkScope
            })
        }else if(workScopeInfo.workscope_modules[arrayIndex].subModule[innerArrayIndex].items.length==1) {
            wkScope[arrayIndex].subModule.splice(innerArrayIndex,1);
            setWorkScopeInfo({
                ...workScopeInfo,
                workscope_modules:wkScope
            })
        }else{
            setWorkScopeInfo({
                ...workScopeInfo,
                workscope_modules:workScopeInfo.workscope_modules.map((majorMod,index) => index !== arrayIndex ? majorMod: {
                    ...majorMod,
                    subModule:majorMod.subModule.map((sb, childIndex) => {
                        let subLength=[...sb.items];
                        return childIndex == innerArrayIndex ? {...sb, ...sb.items.splice(lastArrayIndex,1)} :sb;
                    })
                })
            })
        }
    }
    const canEdit = currentFormDetail?.current_form?.permissions?.can_save;
    return(
        <div>
            { skeletonLoader ? <STableLoader count={5} />: 
                <>
                    <div style={{textAlign:'right', marginBottom:'10px'}}>
                        <ul className='list-inline'>
                            {isEditAble ?
                                <>
                                    <li className='list-inline-item' style={{borderRight:'1px solid #d7d7d7', paddingRight:'10px'}}>
                                        <Button size="small" variant = "outlined" color="primary" onClick={addWorkScopeModuleFn} startIcon={<ControlPointIcon fontSize="small" color="primary" />}>Add Major Module</Button>
                                    </li>
                                    <li className='list-inline-item'>
                                        <Button onClick={onSaveWorkScopeInfo} size="small" variant = "contained" color="primary">Save</Button>
                                    </li>
                                    <li className='list-inline-item'>
                                        <Button onClick={() => {getWorkScopeInfo('pageLoader'); setEditAble(false)}} size="small" variant = "outlined" color="primary">Cancel</Button>
                                    </li>
                                </>:
                                <>
                                    { canEdit && !isEditAble ?
                                        <li className='list-inline-item'>
                                            <Button startIcon={<EditIcon fontSize='small' />} onClick={() => setEditAble(true)} size="small" variant = "outlined" color="primary">Edit</Button>
                                        </li>:null
                                    }
                                </>
                            }
                        </ul>
                    </div>
                    { workScopeInfo?.workscope_modules?.length ?
                         <>
                            { Object.keys(workScopeInfo)?.length ?
                                <WorksScopeTable
                                    workScopes={workScopeInfo.workscope_modules}
                                    addWorkScopeModuleFn={addWorkScopeModuleFn}
                                    addSBWorkScopeModuleFn={addSBWorkScopeModuleFn}
                                    addWorkScopeItem={addWorkScopeItem}
                                    updateWorkScope={updateWorkScope}
                                    deleteWorkScopeItem={deleteWorkScopeItem}
                                    flag={currentFormDetail?.current_form?.permissions?.can_save && isEditAble}
                                />:null
                            }
                        </>:
                        <div style={{textAlign:'center'}}><EmptyCollection title='No more records' /></div>
                    }
                </>
            }
            { isLoading ? <PageLoader />:null }
        </div>
    )
}
export default withRouter(InspectionWorkScope);