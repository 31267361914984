import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
const ReadPrerequisite = ({content}) => {
  const [open, setOpen] = useState(false);
  return(
    <>
      <span className='read-prerequisite' onClick={() => setOpen(true)}> Read Prerequisite</span>
      { open ?
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          className='project-management-modal'
        >
          <DialogTitle id="scroll-dialog-title"> Prerequisite </DialogTitle>
          <DialogContent dividers={true}>
            <div className="read-prerequisite-modal">
              {content}
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} color="primary">Okay</Button>
          </DialogActions>
        </Dialog>:null
      }
    </>
  )
}
export default ReadPrerequisite;
