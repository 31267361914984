import React, { Fragment, useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { useSnackbar } from "notistack"
import { Button, CircularProgress, Tooltip } from '@material-ui/core';
import moment from 'moment'
import {CkEditorText, DeletePopUp} from '../../../shared_elements';
import { globalDeleteService, globalGetService, globalPostService, globalPutService } from "../../../utils_v2/globalApiServices"
import { checkApiStatus, getLocalStorageInfo, onCheckPermission } from "../../../utils_v2"
import { displayDateFormatShort } from '../../../constants';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
const Comment = ({item, comments, cardType, editComment, onDelete, onEditMode, onUpdateComment, onEdit}) => {
    const [edit, setEdit] = useState(false);
    return(
        <div>
            { edit ?
                <div style={{marginBottom:'15px'}}>
                    <CkEditorText
                        htmlData={editComment.comments}
                        onChangeData={(data) => onUpdateComment(data)}
                    />
                    <div style={{marginTop:'10px'}}>
                        <ul className='list-inline'>
                            <li className='list-inline-item'>
                                <Button onClick={() => {setEdit(false);}} color='primary' variant='outlined' size='small'>Cancel</Button>
                            </li>
                            <li className='list-inline-item'>
                                <Button onClick={() => {setEdit(false); onEdit()}} disabled={!item.comments.trim().length} color='primary' variant='contained' size='small'>Update Comment</Button>
                            </li>
                        </ul>
                    </div>
                </div>:
                <Fragment>
                    <h6>{item.created_by.name}</h6>
                    <div className='comment-content' dangerouslySetInnerHTML={{__html: item.comments}}></div>
                    <ul className='list-inline flex-centered edit-delete-comment'>
                        <li className='list-inline-item'>{moment(item.created_at).format(displayDateFormatShort)}</li>
                        { getLocalStorageInfo().user.id === item.created_by.id ?
                            <li onClick={()=>{setEdit(true); onEditMode()}} className='list-inline-item'>
                                <Tooltip title='Edit' arrow><EditIcon fontSize='small' color='primary' /></Tooltip>
                            </li>:null
                        }
                        { onCheckPermission('task_management',cardType === 'issue' ?'issue_comment':'task_comment','D') ?
                            <>
                                { comments?.permission?.delete?.value === 2 ?
                                    <li onClick={onDelete} className='list-inline-item'>
                                        <Tooltip title='Delete' arrow><DeleteOutlineIcon fontSize='small' color='secondary' /></Tooltip>
                                    </li>:
                                    <>
                                        { getLocalStorageInfo().user.id === item.created_by.id ?
                                            <li onClick={onDelete} className='list-inline-item'>
                                                <Tooltip title='Delete' arrow><DeleteOutlineIcon fontSize='small' color='secondary' /></Tooltip>
                                            </li>:null
                                        }
                                    </>
                                }
                            </>:null
                        }
                    </ul>
                </Fragment>
            }
        </div>
    )
}
const CommentBox = ({params, cardType}) =>  {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const [isLoading, setLoading] = useState(false);
    const [deleteComment, setDeleteComment] = useState({modal:false, comment:null})
    const [comments, setComments] = useState({list:[]});
    const [comment, setComment] = useState('');
    const [editComment, setEditComment] = useState({});
    useEffect(() => {
        getComments({});
    }, []);
    const onAddEditComment = (data,id) => {
        let payload = Object.assign({},data);
        if(cardType === 'issue'){
            payload = {
                ...payload,
                project_task_id:null,
                project_issue_id:params.slug
            }
        }else{
            payload = {
                ...payload,
                project_task_id:params.slug,
                project_issue_id:null
            }
        }
        if(id){
            globalPutService(`technical/project/${params.project_slug}/comments/${id}/`,payload)
            .then(response => {
                if(checkApiStatus(response)){
                    enqueueSnackbar(response.data.message, { variant: "success", anchorOrigin: { horizontal: "right", vertical: "top" }});
                    setComments({...comments, list: comments.list.map(item => item.id !== response.data.data.id ? item:response.data.data)})
                    setEditComment({});
                }else{
                    enqueueSnackbar(response.data.message, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "top" }});
                }
            })
        }else{
            setLoading(true);
            globalPostService(`technical/project/${params.project_slug}/comments/`, payload)
            .then(response => {
                setLoading(false)
                if(checkApiStatus(response)){
                    enqueueSnackbar(response.data.message, { variant: "success", anchorOrigin: { horizontal: "right", vertical: "top" }});
                    setComments({...comments, list:[...comments.list, response.data.data]})
                    setComment('')
                }else{
                    enqueueSnackbar(response.data.message, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "top" }});
                }
            })
        }
    }
    const onDeleteComment = () => {
        setLoading(true);
        globalDeleteService(`technical/project/${params.project_slug}/comments/${cardType === 'issue'?'issue':'task'}/delete/${deleteComment.comment.id}/`)
        .then(response => {
            setLoading(false)
            if(checkApiStatus(response)){
                enqueueSnackbar(response.data.message, { variant: "success", anchorOrigin: { horizontal: "right", vertical: "top" }});
                setDeleteComment({modal:false,comment:null});
                setComments({...comments, list: comments.list.filter(item => item.id !== deleteComment.comment.id)})
            }else{
                 enqueueSnackbar(response.data.message, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "top" }});
            }
        })
    }
    const getComments = () => {
        globalGetService(`technical/project/${params.project_slug}/comments/${cardType === 'issue'?'issue':'task'}/${params.slug}/`, {view:'all'})
        .then(response => {
            if(checkApiStatus(response)){
                setComments(response.data.data)
            }
        })
    }
    return (
        <div className='task-comment-box'>
            {comments?.list.map((item) =>
                <Comment
                    cardType={cardType}
                    comments={comments}
                    item={item}
                    editComment={editComment}
                    onEdit={() => onAddEditComment(editComment,editComment.id)}
                    onDelete={() => setDeleteComment({modal:true,comment:item})}
                    onEditMode={() => setEditComment(item)}
                    onUpdateComment={(data) => setEditComment({...editComment, comments:data})}
                />
            )}

            { onCheckPermission('task_management',cardType === 'issue' ?'issue_comment':'task_comment','C') ?
                <>
                    <CkEditorText
                        htmlData={comment}
                        onChangeData={(data) => setComment(data)}
                    />
                    <div style={{marginTop:'10px'}}>
                        <ul className='list-inline ckeditor-button'>
                            <li className='list-inline-item'>
                            {comment.length > 0 && <Button disabled={isLoading} onClick={() => setComment('')} variant="outlined" color="primary" size="small">Cancel</Button>}
                            </li>
                            <li className='list-inline-item'>
                                <Button disabled={isLoading || comment.trim().length===0} onClick={()=> onAddEditComment({comments:comment})} variant="contained" color="primary" size="small">
                                    {isLoading ? <CircularProgress size={24} />: 'Submit'}
                                </Button>
                            </li>
                        </ul>
                    </div>
                </>:null
            }
            {deleteComment.modal ? (
                <DeletePopUp
                    modal={deleteComment.modal}
                    toggleModalFn={() => setDeleteComment({modal:false, comment:null})}
                    title="Delate Comment"
                    content={<h4>Are you sure you want to delete?</h4>}
                    deleteRecordFn={() => onDeleteComment()}
                    confirmText="Delete"
                    isLoading={isLoading}
                />
            ) : null}
        </div>
    );
}

export default withRouter(CommentBox);
