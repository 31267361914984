import React, { Component } from 'react';
import { Collapse, Card, CardBody } from 'reactstrap';
import { imgStoragePath } from '../../../constants';
import arrowIcon from '../../../shared/assets/img/down_arrow.svg';
export default class CollapseWrapper extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            collapse: false,
            alphabet: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
        };
    }
    toggle() {
        this.setState({ collapse: !this.state.collapse });
    }
    render() {
        const { section, subSection, index } = this.props;
        const received= section.questions.filter(i=> i?.answer?.value==1)
        const pending= section.questions.filter(i=> i?.answer?.value==3)
        const notApplicable= section.questions.filter(i=> i?.answer?.value==2)
        return (
            <div className="form-collapse-wrapper">
                <table>
                    <thead>
                        <tr onClick={this.toggle} style={{background: '#ffffff', cursor: 'pointer'}}>
                            <td style={{width:'48%', padding:'0px'}}>
                                <h6 className="form-collapse-title" >
                                    {subSection ? subSection == 1 ? this.state.alphabet[index] + '. ' + section.name : index + 1 + '. ' + section.name : section.name}
                                </h6>
                            </td>
                            <td style={{width:'22%'}}>
                                <div style={{display:'flex', justifyContent:'space-around'}}>
                                <h6> {received?.length ? received.length : '--'}</h6>
                                <h6 style={{marginRight:'9px'}}> {pending?.length ? pending.length : '--'}</h6>
                                <h6> {notApplicable?.length ? notApplicable.length : '--'}</h6> 
                                </div>
                            </td>
                            <td > <img style={this.state.collapse ? { transform: 'rotate(180deg)',float:'right' } : { transform: 'rotate(0deg)',float:'right' }} src={arrowIcon} alt="img" /></td>
                        </tr>
                    </thead>
                </table>
                <Collapse isOpen={this.state.collapse}>
                    <Card>
                        <CardBody>
                            {this.props.children}
                        </CardBody>
                    </Card>
                </Collapse>
            </div>
        )
    }
}
