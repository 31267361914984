export const formTemplateHd = [
    {label:'Ref. No.', sortOption:false},
    {id:'name', label:'Name', sortOption:true},
    {id:'type', label:'Form Type', sortOption:true},
    {label:'Description', sortOption:false},    
    {label:'Attachment', sortOption:false},
    {label:'Created By', sortOption:false},
    {id:'created_at',label:'Created At', sortOption:true},
    {label:'Actions', sortOption:false, actionCell:true}
];
export const templateObj = {
    name:'',
    description:'',
    file:[],
    type:null,
}
export const templateFilterOptions = {
    'name': {
        'inputType': 'text',
        'placeholder': 'Search by Template Name',
        'title': 'Template Name'
    },
    'type': {
        'inputType': 'dropdown',
        'title': 'Form Type',
        'options':[],
        'labelKey':'name',
        'valueKey':'name',
        'multiple': true
    }
};