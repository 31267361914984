import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { useSnackbar } from 'notistack';
import { Paper, Grid, Accordion, AccordionSummary, AccordionDetails, Button, TextField, Switch, Tooltip } from '@material-ui/core';
import AddSection from './AddSection'
import SortSections from './SortSections'
import AssetLopa from './AssetLopa'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { CkEditorText, DeletePopUp, EmptyCollection, PageLoader } from '../../../shared_elements';
import { checkApiStatus, downloadFileType, getLocalStorageInfo, onCheckFormPermission } from '../../../utils_v2';
import { globalGetService, globalPutService, globalPostService, globalDeleteService } from '../../../utils_v2/globalApiServices';
import { Skeleton } from '@material-ui/lab';
const SectionCheckList = ({params, currentFormDetail,getCurrentFormDetail}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [initialLoader, setInitialLoader] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [sectionCheckLists, setSectionCheckLists] = useState({list:[]});
    const [expanded, setExpanded] = useState(false);
    const [addSection, setAddSection] = useState({modal:false, data:null})
    const [deleteSection, setDeleteSection] = useState({modal:false, type:'', data:null})
    useEffect(() => {
        getContentSections();
    },[]);
    const importDefaultSections = () => {
        setLoading(true);
        globalPostService(`api/technical/v2/workorder/${params.workOrderSlug}/default-summary-sections/?asset_type=${params.type}`)
        .then(response => {
            if(checkApiStatus(response)){
                getContentSections();
                getCurrentFormDetail()
            }
            setLoading(false);
        })
    }
    const onFieldChange = (key, value, index, sectionId=null) => {
        if(sectionId){
            setSectionCheckLists({
                ...sectionCheckLists,
                list:sectionCheckLists.list.map(section => section.id !== sectionId ? section : {
                    ...section, sub_sections:section.sub_sections.map((sbSec, sbSecIndex) => sbSecIndex !== index ? sbSec : {
                        ...sbSec,[key]:value? value ==="<p>1.&nbsp;</p>" ? "": value : ''
                    })
                })
            })
        }else{
            setSectionCheckLists({
                ...sectionCheckLists,
                list:sectionCheckLists.list.map((section, sectionIndex) => sectionIndex !== index ? section : {
                    ...section, [key]:value? value ==="<p>1.&nbsp;</p>" ? "": value : ''
                })
            })
        }
    }
    const getContentSections = () => {
       // loaderType === 'skeletonLoader' ? setSkeletonLoader(true):setLoading(true)     
        setLoading(true)
        setInitialLoader(true)
        globalGetService(`technical/v2/workorder/${params.workOrderSlug}/inspection-sections/?asset_type=${params.type}`)
        .then(response => {
            setTimeout(() => {setInitialLoader(false)}, 400); 
            if(checkApiStatus(response)){
                setSectionCheckLists(response.data.data)
            }
            setLoading(false) 
        })
    }
    const onDeleteSection = () => {
        setLoading(true)
        globalDeleteService(`technical/v2/workorder/${params.workOrderSlug}/inspection-sections/${deleteSection.data.id}/?asset_type=${params.type}`)
        .then(response => {
            if(checkApiStatus(response)){
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                if(deleteSection.type === 'Section'){
                    window.location.reload();
                }else{
                    getCurrentFormDetail()
                    getContentSections();
                }
                setLoading(false)
                setDeleteSection({modal:false, type:'', data:null});
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
            }
        })
    }
    const updateSectionInfo = (data) => {
        globalPutService(`technical/v2/workorder/${params.workOrderSlug}/inspection-sections/${data.id}/?asset_type=${params.type}`,data)
        .then(response => {
            if(checkApiStatus(response)){
                // enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                getContentSections('pageLoader')
                getCurrentFormDetail()
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
            }
        });
    }
    const handleChange = (panel, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    console.log('sectionCheckLists',sectionCheckLists);
    return(
        <div className='t004-content-sections'>
            {/* {skeletonLoader ? <div style={{margin:'15px 0'}}><Skeleton variant="rect" width={'100%'} height={300} /></div>: */}
                <>
                    <div className='content-sections-hd'>
                        <Grid container spacing={1}>
                            <Grid item md={6}>
                                <ul className='list-inline'>
                                    {onCheckFormPermission(currentFormDetail) ?
                                        <li className='list-inline-item'>
                                            <Button onClick={() => setAddSection({modal:true, data:{title:'', value:'',value_type:1,parent_section:null}})} color='primary' size='small' variant='contained'>Add Section</Button>
                                        </li>:null
                                    }
                                    <li className='list-inline-item'>
                                        <AssetLopa getCurrentFormDetail={getCurrentFormDetail} currentFormDetail={currentFormDetail}/>
                                    </li>
                                </ul>
                            </Grid>
                            <Grid item md={6}>
                                <ul className='list-inline' style={{float:'right'}}>
                                    {sectionCheckLists?.list?.length > 1 && onCheckFormPermission(currentFormDetail) ?
                                        <li className='list-inline-item'>
                                            <SortSections 
                                                url={`technical/v2/workorder/${params.workOrderSlug}/inspection_sections_order/?asset_type=${params.type}`}
                                                toolTipContent='Sort Sections'
                                                assetSections={sectionCheckLists.list.map((section) => {return{id:section.id,name:section.title,order:section.order}})} 
                                                getResponseBack={() => getContentSections()}
                                            />
                                        </li>:null
                                    }
                                </ul>
                            </Grid>
                        </Grid>
                    </div>
                    {sectionCheckLists.list.map((section, sectionIndex) => 
                        <Accordion key={sectionIndex} expanded={expanded ===   `section_${sectionIndex}`} onClick={(event) => handleChange(`section_${sectionIndex}`, expanded === `section_${sectionIndex}` ? false:true)}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                id={`panel1bh-header_${sectionIndex}`}
                            >
                                <Grid container spacing={1} alignItems='center'>
                                    <Grid item md={10}>
                                        <div className='section-title-field' onClick={(e) => {e.preventDefault(); e.stopPropagation()}}>
                                            <TextField
                                                disabled={!onCheckFormPermission(currentFormDetail)}
                                                id={`section_name_${section.id}`}
                                                placeholder='Type Section Name'
                                                fullWidth
                                                margin="none"
                                                InputLabelProps={{shrink: true}}
                                                value={section.title}
                                                onChange={(e) => {e.preventDefault(); e.stopPropagation();onFieldChange('title', e.target.value, sectionIndex)}}
                                                onBlur={(e) => updateSectionInfo({...section,title:e.target.value})}
                                                onFocus={(e) => {e.preventDefault(); e.stopPropagation();}}
                                                variant='outlined'
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item md={2}>
                                        {onCheckFormPermission(currentFormDetail) ?
                                            <ul className='list-inline flex-centered' style={{float:'right'}}>
                                                <li className='list-inline-item' onClick={(e) => {e.preventDefault(); e.stopPropagation()}}>
                                                    <Tooltip title='Enable Section Content' arrow>
                                                        <Switch
                                                            checked={section.value_type === null ?false:true}
                                                            onChange={(e) => {updateSectionInfo({...section,value_type:e.target.checked ? 1:null})}}
                                                            color="primary"
                                                            name="checkedB"
                                                        />
                                                    </Tooltip>
                                                </li>
                                                <li className='list-inline-item'>
                                                    <Tooltip title='Delete Section' arrow>
                                                        <DeleteOutlineIcon onClick={(e) => {e.preventDefault();e.stopPropagation();setDeleteSection({modal:true, type:'Section', data:{id:section.id}})}} color='secondary' fontSize='medium' />
                                                    </Tooltip>
                                                </li>
                                            </ul>:null
                                        }
                                    </Grid>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className='section-content' onClick={(e) => {e.preventDefault(); e.stopPropagation()}}>
                                    <div>
                                        { section.value_type === 1 ?
                                            <CkEditorText 
                                                disabled={!onCheckFormPermission(currentFormDetail)}
                                                htmlData={section.value}
                                                onChangeData={(data) => onFieldChange('value', data, sectionIndex)}
                                                onBlurData={(data) => updateSectionInfo({...section,value:data})}
                                            />:null
                                        }
                                    </div>
                                    <Paper className='sub-sections-content'>
                                        { onCheckFormPermission(currentFormDetail) ?
                                            <Grid container spacing={1}>
                                                <Grid item md={6}>
                                                    <ul className='list-inline'>
                                                        <li className='list-inline-item'>
                                                            <Button onClick={()=>setAddSection({modal:true, data:{title:'', value:'', value_type:1, parent_section:section.id}})} color='primary' size='small' variant='contained'>Add Sub Section</Button>
                                                        </li>
                                                    </ul>
                                                </Grid>
                                                <Grid item md={6}>
                                                    <ul className='list-inline' style={{float:'right'}}>
                                                        { section.sub_sections.length && section.sub_sections.length > 1 ?
                                                            <SortSections 
                                                                url={`technical/v2/workorder/${params.workOrderSlug}/inspection_sections_order/?asset_type=${params.type}`}
                                                                toolTipContent='Sort Sub-Sections'
                                                                assetSections={section.sub_sections.map((sbSection) => {return{id:sbSection.id,name:sbSection.title,order:sbSection.order}})} 
                                                                getResponseBack={() => getContentSections('pageLoader')}
                                                            />:null
                                                        }
                                                    </ul>
                                                </Grid>
                                            </Grid>:null
                                        }
                                        {section.sub_sections.map((subSection, subSecIndex) =>
                                            <Paper key={`Section_${sectionIndex}_${subSecIndex}`} className='sub-section-blk'>
                                                <div className='sub-section-head'>
                                                    <Grid container spacing={1} alignItems='center'>
                                                        <Grid item md={11}>
                                                            <TextField
                                                                disabled={!onCheckFormPermission(currentFormDetail)}
                                                                id={`sb_section_name_${section.id}_${subSection.id}`}
                                                                fullWidth
                                                                margin="none"
                                                                InputLabelProps={{shrink: true}}
                                                                value={subSection.title}
                                                                onChange={(e) => onFieldChange('title', e.target.value, subSecIndex, section.id)}
                                                                onBlur={(e) => updateSectionInfo({...subSection,title:e.target.value})}
                                                                variant='outlined'
                                                            />
                                                        </Grid>
                                                        <Grid item md={1}>
                                                            {onCheckFormPermission(currentFormDetail) ?
                                                                <ul className='list-inline' style={{float:'right'}}>
                                                                    <li className='list-inline-item'>
                                                                        <Tooltip title='Delete Sub-Section' arrow>
                                                                            <DeleteOutlineIcon style={{cursor:'pointer'}} onClick={() => setDeleteSection({modal:true, type:'Sub Section', data:{id:subSection.id }})} color='secondary' fontSize='medium' />
                                                                        </Tooltip>
                                                                    </li>
                                                                </ul>:null
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                                {expanded === `section_${sectionIndex}` ?
                                                    <div className='sub-section-body'>
                                                        <CkEditorText
                                                            disabled={!onCheckFormPermission(currentFormDetail)}
                                                            htmlData={subSection.value}
                                                            onChangeData={(data) => onFieldChange('value', data, subSecIndex, section.id)}
                                                            onBlurData={(data) => updateSectionInfo({ ...subSection, value: data })}
                                                        />
                                                    </div>
                                                    : null}
                                            </Paper>
                                        )}
                                        {!section.sub_sections.length ? <div style={{textAlign:'center', padding:'30px 0'}}>No sub-sections added yet!</div>:null}
                                    </Paper>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    )}
                    {initialLoader?null : !sectionCheckLists?.list?.length && onCheckFormPermission(currentFormDetail) ?
                        <div style={{textAlign:'center',padding:'120px 0px'}}>
                            <p>No section's added yet, click to import default sections or click to add new section.</p>
                            <Button onClick={importDefaultSections} color='primary' size='small' variant='outlined'>Import Default Section(s)</Button>
                        </div>:
                        sectionCheckLists?.list?.length ? null: <EmptyCollection  title="No records found." />
                    }
                </>
            {addSection.modal ?
                <AddSection 
                    getCurrentFormDetail={getCurrentFormDetail} 
                    toggleModalFn={() => setAddSection({modal:false, data:null})}
                    sectionObj={addSection}
                    getResponseBack={()=> getContentSections('pageLoader')}
                />:null
            }
            { deleteSection.modal ?
                <DeletePopUp
                    modal={deleteSection.modal}
                    toggleModalFn={() => setDeleteSection({modal:false, type:'', data:null})}
                    title={`Delete ${deleteSection.type}`}
                    content={<h4>Are you sure you want to Delete?</h4>}
                    deleteRecordFn={() => onDeleteSection()}
                />:null
            }
            {isLoading ? <PageLoader />:null}
        </div>
    )
}
export default withRouter(SectionCheckList);