import React, { Fragment, Component } from 'react';
import { Link } from 'react-router'
import { budgetAccessGroup, budgetAccessEngineer, checkBudgetPermission } from '../'
export default function BudgetPermissionWrapper(HocComponent){
    return class extends Component{
        render(){
            if(checkBudgetPermission([...budgetAccessGroup, ...budgetAccessEngineer])){
                return(
                    <HocComponent {...this.props} />
                )
            }else{
                return(
                    <Fragment>
                        <section style={{position:'relative', marginLeft:'70px', height:'90vh'}}>
                            <div className='no-budget-access'>
                                <h4>You don't have access to this feature</h4>
                                <Link to={`/technical/project/view/${this.props.params.project_slug}`}>Click here to, Go Back</Link>
                            </div>
                        </section>
                    </Fragment>
                )

            }
        }
    }
}
