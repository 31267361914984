import React, { useState, useEffect } from "react";
import { Paper, Grid, Button, Dialog } from "@material-ui/core";
import { browserHistory } from 'react-router';
import { TaskManagementWrapper, MilestoneList,AddEditMilestone } from "../components";
import {TableListComp,EmptyCollection,DeletePopUp,PageLoader,ExportManu,FilterUIComp,} from "../../../shared_elements";
import { STableLoader } from '../../../shared_elements/loaders';
import { milestoneHd, milestonesFilterOps } from "..";
import FilterComponent from '../../../shared_elements/filter_component';
import { globalDeleteService, globalExportService, globalGetService, globalPostService, globalPutService } from "../../../utils_v2/globalApiServices";
import { checkApiStatus, onCheckPermission, downloadFileType, getLocalStorageInfo, removeEmptyKey } from "../../../utils_v2";
import { useSnackbar } from "notistack";
import moment from "moment";
import { trackActivity } from '../../../utils/mixpanel';
import { getProjectWorkOrdersApi, getProjectDetailApi, getAllStagesApi, getPlatformConstantsApi } from "../apiService";
import RestrictedAccess from "../../../shared_elements/components/RestrictedAccess";
const Milestones = ({ params, location }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [isLoading, setLoading] = useState(false)
  const [skeletonLoader, setSkeletonLoader] = useState(true)
  const [addEditMilestone, setAddEditMilestone] = useState({modal:false, milestone:null})
  const [deleteMilestone, setDeleteMilestone] = useState({modal:false, milestone:null})
  const [sort, setSort] = useState("")
  const [sort_by, setSortBy] = useState("")
  const [filter, setFilter] = useState({})
  const [owners, setOwners] = useState([])
  const [stages, setStages] = useState([])
  const [workOrders, setWorkOrders] = useState([])
  const [pltConstants, setPltConstants] = useState([])
  const [noAcess, setNoAcess] = useState(false)
  const [status, setStatus] = useState([ { id: 1, name: "Active" }, { id: 2, name: "Close" }, ])
  const [mileStonesInfo, setMileStonesInfo] = useState({ pagination: {}, list: [] })
  
  useEffect(() => {
    getProjectDetails()
    getMilestonesData({ per_page: 10 }, "skeletonLoader")
    getOwners({role:'milestone_users'}); getStages(); getWorkOrders(); getConstants();
    trackActivity('Task Management', {
      event_type: 'Page Visited',
      page_title: 'Milestones',
    });
  }, []);
  
  const getOwners = (query) => {
    globalGetService(`technical/users/`, {...query,project_slug:params.project_slug})
    .then(response => {
      if(checkApiStatus(response)){
        setOwners(response.data.data);
      }
    });
  }
  const getStages = () => {
    getAllStagesApi(params, {view: "all", fields:'id,name'})
    .then((response) => {
      setStages(response.data.data)
    })
  }
  const getWorkOrders = () => {
    getProjectWorkOrdersApi( params, { })
    .then((response) => {
      setWorkOrders(response.data.data)
    })
  }
  const getConstants = () => {
    getPlatformConstantsApi({constant_types: ["milestone_status"]})
    .then((response) => {
      setPltConstants(response.data.data)
    })
  }
  const getMilestonesData = (query = {}, loaderType) => {
    if(loaderType === 'skeletonLoader'){ setSkeletonLoader(true) }else{ setLoading(true) }
    globalGetService( `technical/project/${params.project_slug}/milestones/`, query )
    .then((response) => {
      if(loaderType === 'skeletonLoader'){ setSkeletonLoader(false) }else{ setLoading(false) }
      if (checkApiStatus(response)) {
        delete query.sort
        delete query.sort_by
        delete query.page
        delete query.per_page
        setMileStonesInfo(response.data.data)
        setFilter(query)
      }
    })
  }
  const onDeleteMilestone = () => {
    setLoading(true)
    globalDeleteService( `technical/project/${params.project_slug}/milestones/${deleteMilestone.milestone.id}/` )
    .then((response) => {
      setLoading(false)
      if (checkApiStatus(response)) {
        setDeleteMilestone({modal:false, milestone:null})
        enqueueSnackbar(response.data.message, { variant: "success", anchorOrigin: { horizontal: "right", vertical: "top" }})
        getMilestonesData({ per_page: 10 }, "pageLoader")
        trackActivity('Task Management', {
          event_type: 'Delete Milestone Success',
          page_title: 'Delete Milestone',
          project_slug: params.project_slug,
          milestone_id: deleteMilestone.milestone.id ? deleteMilestone.milestone.id : '',
          response_msg: response.data.message
        })
      } else {
        enqueueSnackbar(response.data.message, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "top" }})
        trackActivity('Task Management', {
          event_type: 'Delete Milestone Failed',
          page_title: 'Delete Milestone',
          project_slug: params.project_slug,
          milestone_id: deleteMilestone.milestone.id ? deleteMilestone.milestone.id : '',
          response_msg: response.data.message
        })
      }
    })
  }
  const exportMilestonesApi = (query = {}, file) => {
    enqueueSnackbar('Milestones Report Downloading ...', { variant: 'info',anchorOrigin:{horizontal:'right', vertical:'bottom'}})
    globalExportService(`technical/project/${params.project_slug}/milestones/`,{...query,download: file.extension})
    .then((response) => {
      downloadFileType(response,`Milestones_${getLocalStorageInfo().defaultLessor.name}_${moment().format("YYYY-MM-DD")}.`,file.extension)
      closeSnackbar();
      enqueueSnackbar('Milestones Report Downloaded', { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'bottom'}});
      trackActivity('Task Management', {
        event_type: 'Export Milestone Success',
        page_title: 'Export Milestone',
        project_slug: params.project_slug,
        file_extension: file.extension ? file.extension : '',
        file_name: `Milestones_${getLocalStorageInfo().defaultLessor.name}_${moment().format("YYYY-MM-DD")}.` ? `Milestones_${getLocalStorageInfo().defaultLessor.name}_${moment().format("YYYY-MM-DD")}.` : '',
        response_msg: response.data.message
      })
    })
  }

  const createSortHandler = (sortField) => {
    setSort(sortField)
    setSortBy(sortField === sort ? (sort_by === "asc" ? "desc" : "asc") : "asc")
    if (sortField === sort) {
      if (sort_by === "asc") {
        getMilestonesData( { ...filter, sort: sortField, sort_by: "desc", per_page: mileStonesInfo.pagination.per_page, }, "pageLoader" )
      } else {
        getMilestonesData( { ...filter, sort: sortField, sort_by: "asc", per_page: mileStonesInfo.pagination.per_page, }, "pageLoader" )
      }
    } else {
      getMilestonesData( { ...filter, sort: sortField, sort_by: "asc", per_page: mileStonesInfo.pagination.per_page, }, "pageLoader" )
    }
  }
  const getProjectDetails = (loaderType) => {
    setLoading(true)
    globalGetService(`technical/project/${params.project_slug}/`)
    .then(res => {
      setLoading(false)
        if(res.data.statusCode == 404){
            setNoAcess(true)
        }
    })
}
  const setMilestonesStatus = (data, id) => {
    globalPutService( `/api/technical/project/${params.project_slug}/milestones/${id}/status-update/`, {status:data})
    .then((response) => {
      if (checkApiStatus(response)) {
        getMilestonesData({ per_page: 10 }, "pageLoader")
        enqueueSnackbar(response.data.message, { variant: "success", anchorOrigin: { horizontal: "right", vertical: "top" }, })
      } else {
        enqueueSnackbar(response.data.message, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "top" }, })
      }
    })
  }
  let filterOptions = {
    ...milestonesFilterOps,
    owner: {
      ...milestonesFilterOps.owner,
      options: owners,
    },
    status: {
      ...milestonesFilterOps.status,
      options: pltConstants,
    },
    project_stage: {
      ...milestonesFilterOps.project_stage,
      options: stages,
    },
    work_order: {
      ...milestonesFilterOps.work_order,
      options: workOrders,
    },    
  }

  return (
    <section className="projects-management-apps projects-management-section">
       {noAcess? <RestrictedAccess />:
      <TaskManagementWrapper>
      { skeletonLoader ? <STableLoader count={8} />:
        <div style={{height:`${window.innerHeight-140}px`, overflow:'auto'}}>
          <Paper square className="filter-cta">
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12} sm={12} md={9}>
               <FilterComponent 
                   filter={filter}
                   filterMenu={filterOptions}
                   getResponseBack={(applyFilter) => {getMilestonesData({...applyFilter, page:1, per_page:mileStonesInfo.pagination.per_page}, 'pageLoader'); browserHistory.push({...location,query:applyFilter})}}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <ul className="list-inline cta-list">
                  { onCheckPermission('task_management','milestone','C') ?
                    <li className="list-inline-item">
                      <Button color="primary" variant="contained" size="small" onClick={() => setAddEditMilestone({modal:true,milestone:null})} > Add Milestone </Button>
                    </li>:null
                  }
                  { onCheckPermission('task_management','milestone','EXP') ?
                    <li className="list-inline-item">
                      <ExportManu
                        disabled={mileStonesInfo.list.length === 0 ? true : false}
                        title="Export"
                        files={[{ title: "Excel", extension: "xls", key: "" }]}
                        exportReportFn={(file) => exportMilestonesApi({download:file.extension, ...removeEmptyKey(filter)}, file)}
                      />
                    </li>:null
                  }
                </ul>
              </Grid>
            </Grid>
          </Paper>
          <TableListComp
            style={{height: !mileStonesInfo.list.length ? 'auto':`${window.innerHeight-235}px`,overflow:'auto'}}
            heads={milestoneHd}
            data={mileStonesInfo.list.map((item, index) => (
              <MilestoneList
                key={index}
                item={item}
                statusDropdown={pltConstants.filter(item => item.type === 'milestone_status')}
                onRemoveMilestones={() => setDeleteMilestone({modal:true, milestone:item})}
                changeStatus={setMilestonesStatus}
                editMilestones={() => setAddEditMilestone({modal:true, milestone:item})}
                refreshComp={() => getMilestonesData({ per_page: 10 }, "pageLoader") }
                mileStonesInfo={mileStonesInfo}
              />
            ))}
            noRecord={ mileStonesInfo.list.length ? null : <EmptyCollection title="No records found" /> }
            pagination={mileStonesInfo.pagination}
            onChangePage={(event, newPage) =>
              getMilestonesData( { ...filter, page: newPage + 1, per_page: mileStonesInfo.pagination.per_page, }, "pageLoader" )
            }
            onChangeRowsPerPage={(event) =>
              getMilestonesData( { ...filter, page: 1, per_page: event.target.value, }, "pageLoader" )
            }
            createSortHandler={createSortHandler}
          />
        </div>
      }
      </TaskManagementWrapper>}
      {deleteMilestone.modal ? (
        <DeletePopUp
          modal={deleteMilestone.modal}
          toggleModalFn={() => setDeleteMilestone({modal:false, milestone:null})}
          title="Delete Milestone"
          content={<h4>Are you sure you want to delete?</h4>}
          deleteRecordFn={() => onDeleteMilestone()}
          confirmText="Delete"
          isLoading={isLoading}
        />
      ) : null}
      { addEditMilestone.modal ?
        <AddEditMilestone
          addEditMilestone={addEditMilestone}
          toggleModalFn={() => setAddEditMilestone({modal:false, milestone:null})}
          getResponseBack={() => getMilestonesData({ per_page: 10 }, "pageLoader")}
          owners={owners}
          stages={stages}
          workOrders={workOrders}
        />:null  
      }
      {isLoading ? <PageLoader /> : null}
    </section>
  )
}
export default Milestones
