import React from 'react'
import { Button } from '@material-ui/core'
export default function InvalidUrl({ getResponseBack }) {
  return (
    <div>
      <div>
        <div style={{ position: 'fixed', top: '50%', left: '54%', transform: 'translate(-50%, -58%)', color: '#000000', textAlign: 'center' }}>
          Oops! This link is not accessible for this asset.<br />
          <span style={{ color: "#008eff", cursor: 'pointer', textDecoration: 'underline' }} onClick={() => { getResponseBack() }}>click here</span> to go back to project details.
          {/* <Button onClick={()=>{getResponseBack()}} >
                        Go back
                        </Button>  */}
        </div>
      </div>
    </div>
  )
}
