import React, { useEffect, useState} from 'react';
import { withRouter } from 'react-router';
import { useSnackbar } from 'notistack';
import { Paper, TextField, Grid, Button, IconButton, Tooltip } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider,KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { CkEditorText, PageLoader } from '../../../shared_elements';
import { ReadPrerequisite } from '../../Elements'
import EditIcon from '@material-ui/icons/Edit';
import PrerequisiteContent from './PrerequisiteContent'
import { fieldDateFormat, backendDateFormat } from '../../../constants';
import { globalGetService, globalPutService } from '../../../utils_v2/globalApiServices';
import { checkApiStatus } from '../../../utils_v2';
const GeneralDetail = ({currentFormDetail, params, getCurrentFormDetail}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setLoading] = useState(false);
  const [isEditAble, setEditAble] = useState(false);
  const [generalDetail, setGeneralDetail] = useState({});
  const [error, setError] = useState({});
  useEffect(() => {
    getGeneralDetails();
  }, []);
  const getGeneralDetails = () => {
    globalGetService(`technical/v2/workorder/${params.workOrderSlug}/table-inspections/general-details/`)
    .then(response => {
      if(checkApiStatus(response)){
        setGeneralDetail(response.data.data);
      }else{
        if(response.data.statusCode === 404){
          setGeneralDetail({type_of_report:null, place_of_inspection:'', amm_revision_number:'', amm_revision_date:null, date_of_inspection_from:null, date_of_inspection_to:null, report_date:null, report_issued_place:''})
        }
      }
    })
  }
  const onUpdateGeneralDetails = () => {
    let validationInputs = {
      inspection_performed_from:generalDetail?.inspection_performed_from ? moment(generalDetail.inspection_performed_from).isValid() ? '':'Please enter valid Date':'',
      inspection_performed_till:generalDetail?.inspection_performed_till ? moment(generalDetail.inspection_performed_till).isValid() ? '':'Please enter valid Date':'',
      report_date:generalDetail?.report_date ? moment(generalDetail.report_date).isValid() ? '':'Please enter valid Date':'',
    }
    if((generalDetail?.inspection_performed_from && moment(generalDetail.inspection_performed_from).isValid()) && (generalDetail?.inspection_performed_till && moment(generalDetail.inspection_performed_till).isValid())){
      if(generalDetail?.inspection_performed_from > generalDetail?.inspection_performed_till){
        validationInputs = {
          ...validationInputs,
          inspection_performed_from: 'From Date should be less then To Date'
        }
      }
    }
    if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
      setLoading(true);
      globalPutService(`technical/v2/workorder/${params.workOrderSlug}/table-inspections/general-details/`, generalDetail)
      .then(response => {
        if(checkApiStatus(response)){
          enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
          setEditAble(false);
          getCurrentFormDetail('pageLoader');
        }
        setLoading(false);
      })
    }else{
      setError(validationInputs);
    }
  }
  const onFieldChange = (key, value) => {
    setGeneralDetail({...generalDetail, [key]:value});
  }
  const canEdit = currentFormDetail?.current_form?.permissions?.can_save;
  return(
    <Paper className="form-detail-card">
      <p className='edit-details'>
        <ReadPrerequisite content={<PrerequisiteContent />} />
        {canEdit && !isEditAble ? 
          <span className='edit-g'>
            <IconButton size="small">
              <Tooltip title='Edit General Detail' arrow><EditIcon onClick={() => setEditAble(true)} fontSize='small' color='primary' /></Tooltip>
            </IconButton></span>:null
        }
      </p>
      <div className='scrollable-content' style={{height: `${window.innerHeight- (isEditAble ? 265:225)}px`, overflow:'auto'}}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={3}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                disabled={!isEditAble}
                margin="normal"
                id="inspection_performed_from"
                label="Inspection Performed From"
                format={fieldDateFormat}
                fullWidth
                disableFuture={true}
                error={error?.inspection_performed_from}
                helperText={error?.inspection_performed_from||''}
                InputLabelProps={{shrink: true}}
                value={generalDetail?.inspection_performed_from||null}
                onChange={(data, value) => {onFieldChange('inspection_performed_from', data ? moment(data).format(backendDateFormat):data); setError({...error,inspection_performed_from:''})}}
                inputVariant="outlined"
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} md={3}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                disabled={!isEditAble}
                margin="normal"
                id="inspection_performed_till"
                label="Inspection Performed Till"
                format={fieldDateFormat}
                fullWidth
                error={error?.inspection_performed_till}
                helperText={error?.inspection_performed_till||''}
                InputLabelProps={{shrink: true}}
                value={generalDetail?.inspection_performed_till||null}
                onChange={(data, value) => {onFieldChange('inspection_performed_till', data ? moment(data).format(backendDateFormat):data); setError({...error,inspection_performed_till:''})}}
                inputVariant="outlined"
                />
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item xs={12} md={3}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                disabled={!isEditAble}
                margin="normal"
                id="report_date"
                label="Report Date"
                format={fieldDateFormat}
                fullWidth
                disableFuture={true}
                error={error?.report_date}
                helperText={error?.report_date||''}
                InputLabelProps={{shrink: true}}
                value={generalDetail?.report_date||null}
                onChange={(data, value) => {onFieldChange( 'report_date', data ? moment(data).format(backendDateFormat):data); setError({...error,report_date:''})}}
                inputVariant="outlined"
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              disabled={!isEditAble}
              id="report_issued_place"
              label="Report Issued Place"
              fullWidth
              margin="normal"
              inputProps={{maxLength: 30}}
              value={generalDetail?.report_issued_place||''}
              onChange={(e) => onFieldChange('report_issued_place', e.target.value)}
              InputLabelProps={{shrink: true}}
              variant="outlined"
            />
          </Grid>
          <Grid item xs='12'>
            <div className='content-editor'>
              <label>Executive Summary</label>
              <CkEditorText
                  disabled={!isEditAble}
                  htmlData={generalDetail?.executive_summary||''}
                  onBlurData={(data) => onFieldChange('executive_summary', data)}
              />
            </div>
          </Grid>
          <Grid item xs='12'>
            <div className='content-editor'>
              <label>LLP Summary</label>
              <CkEditorText
                  disabled={!isEditAble}
                  htmlData={generalDetail?.llp_summary||''}
                  onBlurData={(data) => onFieldChange('llp_summary', data)}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      { isEditAble ?
        <div className='actions-cta'>
          <ul className='list-inline'>
            <li className='list-inline-item'>
              <Button onClick={onUpdateGeneralDetails} size='small' color='primary' variant='contained'>Save</Button>
            </li>
            <li className='list-inline-item'>
              <Button onClick={() => {setEditAble(false);getGeneralDetails(); setError({})}} size='small' color='primary' variant='outlined'>Cancel</Button>
            </li>
          </ul>
        </div>:null
      }
      { isLoading ? <PageLoader />:null }
    </Paper>
  )
}

export default withRouter(GeneralDetail);