import React, { useState } from "react"
import { withRouter } from "react-router"
import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, Grid, TextField, } from "@material-ui/core"
import { isMobile } from "react-device-detect"
import { KeyboardDatePicker, MuiPickersUtilsProvider, } from "@material-ui/pickers"
import MomentUtils from "@date-io/moment"
import { backendDateFormat, fieldDateFormat } from "../../../constants"
import { closureErrorCode, stageClosureData } from ".."
import { dateValidation, dateValidations, fieldValidation, } from "../../../utils_v2/formValidation"
import moment from "moment"
const ClosurePopUp = ({ modal, title, params, content, toggleModalFn, deleteRecordFn, confirmText, cancelText, cancelColor, confirmColor, submitClosureFn }) => {
  const [closureData, setClosureData] = useState(stageClosureData)
  const [error, setError] = useState({})
  const [doubleClick, setDoubleClick] = useState(false)
  const onFieldChange = (keyParam, value) => {
    setClosureData({ ...closureData, [keyParam]: value })
  }
  const onRestErrorKey = (keyParam) => {
    setError({ ...error, [keyParam]: "" })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    let validationInputs = {}
    const data = closureData
    validationInputs = {
      closure_remarks:data.closure_remarks ? "" : "Please Enter Closure Note",
      actual_start_date:data.actual_start_date ? "" : "Please Enter Actual Start Date",
      actual_end_date: data.actual_end_date ? "" : "Please Enter Actual End Date",
    }
    if(data.actual_start_date && data.actual_end_date){
      if(!moment(data.actual_end_date).isAfter(data.actual_start_date)){
        validationInputs.actual_end_date = 'End Date should be greater than Start Date'
      }
    }
    if ( Object.keys(validationInputs).every((k) => { return validationInputs[k] === "" }) ) {
      setClosureData((prevState) => ({
        ...prevState,
        closureData: {
          ...prevState.closureData,
        },
      }))
      setDoubleClick(true)
      submitClosureFn(closureData, params)
    } else {
      setError(validationInputs)
    }
  }

  return (
    <Dialog
      open={modal}
      onClose={toggleModalFn}
      aria-labelledby="scroll-dialog-title"
    >
      <DialogTitle id="scroll-dialog-title">
        {title ? title : "Delete"}
      </DialogTitle>
      <DialogContent dividers={true}>
        <div
          className="delete-record-modal"
          style={isMobile ? { width: "auto" } : { minWidth: "400px" }}
        >
          <div className="">
            <h4>Are you sure you want to mark stage as Completed ?</h4>

            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    required
                    id="actual_start_date"
                    label="Actual Start Date"
                    format={fieldDateFormat}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={closureData.actual_start_date}
                    error={error.actual_start_date ? true : false}
                    helperText={ error.actual_start_date ? error.actual_start_date : "" }
                    onChange={(data, value) => { onFieldChange( "actual_start_date", data ? moment(data).format(backendDateFormat) : data ); onRestErrorKey("actual_start_date") }}
                    onFocus={() => onRestErrorKey("actual_start_date")}
                    minDate={moment().subtract(25, "years")}
                    inputVariant="outlined"
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} md={6}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    required
                    id="actual_end_date"
                    label="Actual End Date"
                    format={fieldDateFormat}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={closureData.actual_end_date}
                    error={error.actual_end_date ? true : false}
                    helperText={ error.actual_end_date ? error.actual_end_date : "" }
                    onChange={(data, value) => { onFieldChange( "actual_end_date", data ? moment(data).format(backendDateFormat) : data ); onRestErrorKey("actual_end_date")}}
                    onFocus={() => onRestErrorKey("actual_end_date")}
                    minDate={moment().subtract(25, "years")}
                    inputVariant="outlined"
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                required
                id="closure_remarks"
                label="Closure Note"
                fullWidth
                margin="normal"
                multiline
                minRows={3}
                inputProps={{ maxLength: 255 }}
                value={closureData.closure_remarks}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                onChange={(e, value) => onFieldChange("closure_remarks", e.target.value) }
                onFocus={() => onRestErrorKey("closure_remarks")}
                error={error.closure_remarks ? true : false}
                helperText={error.closure_remarks ? error.closure_remarks : ""}
              />
            </Grid>
          </div>
          <div className="actions-cta">
            <Button
              variant="contained"
              onClick={toggleModalFn}
              color={cancelColor ? cancelColor : "secondary"}
            >
              {cancelText ? cancelText : "Cancel"}
            </Button>
            <Button
              variant="contained"
              onClick={handleSubmit}
              color={confirmColor ? confirmColor : "primary"}
              disabled={doubleClick}
            >
              {doubleClick ? (
                <CircularProgress size={22} />
              ) : confirmText ? (
                confirmText
              ) : (
                "Delete"
              )}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default withRouter(ClosurePopUp)
