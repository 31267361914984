import * as actions from '../actions';
import { globalGetService, globalPutService, globalFileUploadPutService, globalPostService, globalDeleteService, globalFileUploadService } from '../../../../../globalServices/';
import { toastFlashMessage } from '../../../../../utils';
import { getFormDetailsAc } from '../../T001/actionCreators';
import { usageDetailAc } from '../../../../../shared/actionCreators';
import { trackActivity } from '../../../../../utils/mixpanel';
export const fetchT003DataAc = (props, queryParam = {}) => {
  return (dispatch) => {
    dispatch(triggerLoader(true));
    dispatch({
      type: actions.AUDIT_SECTION_LIST,
      payload: []
    })
    globalGetService('technical/workorder/' + props.params.workOrderSlug + '/document-sections/', queryParam)
      .then(response => {
        if (response.data.statusCode == 200) {
          dispatch({
            type: actions.AUDIT_SECTION_LIST,
            payload: response.data.data
          })
        }
        dispatch(triggerLoader(false));
      })
  }
}

export const auditCheckUpdateAnsAc = (props, sectionId, subSectionId, ansData, crudType, questionIndex, questionId, files = [], existFileArr) => {
  let formData = new FormData();    //formdata object
  if (files && files.length) {
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      formData.append('files[]', file, file.name);
    }
  } else if (crudType == 'delete') {
    let ids = existFileArr.map(item => item.id).join(',');
    formData.append('files', ids);
    formData.append('delete', true);
  } else {
    formData.append('remarks', ansData.remarks ? ansData.remarks : '');   //append the values with key, value pair
    formData.append('value', ansData.value);
  }
  formData.append('question_id', questionId);
  const config = {
    headers: { 'content-type': 'multipart/form-data' }
  }
  return (dispatch) => {
    dispatch(triggerLoader(true));
    if (ansData.id) {
      globalFileUploadPutService('/technical/workorder/' + props.params.workOrderSlug + '/document-sections/' + ansData.id + '/', formData, config)
        .then(response => {
          if (response.data.statusCode == 200) {
            dispatch({
              type: actions.LIST_UPDATE,
              payload: { data: response.data.data, sectionId: sectionId, subSectionId: subSectionId, questionId: questionIndex }
            })
            toastFlashMessage(response.data.message, 'success');
            if (crudType == 'delete') {
              trackActivity('T003', {
                event_type: 'File(s) Deleted',
                page_title: 'Inspection Documents Checklist',
                workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
                form_slug: props.params.formSlug ? props.params.formSlug : '',
                question_index: questionIndex ? questionIndex : '',
                file_id: ansData.id ? ansData.id : '',
                section_id: sectionId ? sectionId : '',
                response_body: response.data.data ? response.data.data : '',
                asset_type: props.params.type ? props.params.type : '',
                aircraft_slug: props.params.aircraft_slug ? props.params.aircraft_slug : ''
              })
            } else {
              for (let i = 0; i < files.length; i++) {
                let file = files[i];
                trackActivity('T003', {
                  event_type: 'File(s) Uploaded',
                  page_title: 'Inspection Documents Checklist',
                  workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
                  form_slug: props.params.formSlug ? props.params.formSlug : '',
                  question_index: questionIndex,
                  file_id: ansData.id,
                  section_id: sectionId ? sectionId : ''
                })
              }
            }
          }
          dispatch(triggerLoader(false));
        })
    } else {
      globalFileUploadService('/technical/workorder/' + props.params.workOrderSlug + '/document-sections/', formData, config)
        .then(response => {
          if (response.data.statusCode == 200) {
            dispatch({
              type: actions.LIST_UPDATE,
              payload: { data: response.data.data, sectionId: sectionId, subSectionId: subSectionId, questionId: questionIndex }
            })
            toastFlashMessage(response.data.message, 'success');
          }
          dispatch(triggerLoader(false));

        })
    }
    dispatch(usageDetailAc())
    dispatch(getFormDetailsAc(props));
  }
}

export const fetchAuditDocsListAc = (props, questionId) => {
  return (dispatch) => {
    dispatch(triggerLoader(true));
    globalGetService('/technical/workorder/' + props.params.workOrderSlug + '/configuration/' + questionId + '/list-files/', {})
      .then(response => {
        if (response.data.statusCode == 200) {
          dispatch({
            type: 'FETCH_FILE_LIST',
            payload: response.data.data
          })
        }
        dispatch(triggerLoader(false));

      })
  }
}

export const updateAuditDocsListAc = (props, answerId, fileArr, questionId, sectionIndex, subSectionId, questionIndex) => {
  let formData = new FormData();    //formdata object
  formData.append('files', [fileArr]);
  formData.append('question_id', questionId);
  const config = {
    headers: { 'content-type': 'multipart/form-data' }
  }
  return (dispatch) => {
    dispatch(triggerLoader(true));
    if (answerId == '' || answerId == null) {
      globalFileUploadService('/technical/workorder/' + props.params.workOrderSlug + '/document-sections/', formData, config)
        .then(response => {
          if (response.data.statusCode == 200) {
            dispatch({
              type: actions.LIST_UPDATE,
              payload: { data: response.data.data, sectionId: sectionIndex, subSectionId: subSectionId, questionId: questionIndex }
            })
            toastFlashMessage(response.data.message, 'success');
            trackActivity('T003', {
              event_type: 'File(s) Linked',
              page_title: 'Inspection Documents Checklist',
              workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
              form_slug: props.params.formSlug ? props.params.formSlug : '',
              question_index: questionIndex ? questionIndex : '',
              file_id: fileArr[0] ? fileArr[0] : '',
              asset_type: props.params.type ? props.params.type : '',
              aircraft_slug: props.params.aircraft_slug ? props.params.aircraft_slug : ''
            })
            dispatch({
              type: actions.LINKED_DOCS_EMPTY,
              payload: []
            })
          }
          dispatch(triggerLoader(false));
        })
    } else {
      globalFileUploadPutService('/technical/workorder/' + props.params.workOrderSlug + '/document-sections/' + answerId + '/', formData, config)
        .then(response => {
          if (response.data.statusCode == 200) {
            dispatch({
              type: actions.LIST_UPDATE,
              payload: { data: response.data.data, sectionId: sectionIndex, subSectionId: subSectionId, questionId: questionIndex }
            })
            toastFlashMessage(response.data.message, 'success');
            dispatch({
              type: actions.LINKED_DOCS_EMPTY,
              payload: []
            })

          }
          dispatch(triggerLoader(false));
        })
    }
    dispatch(getFormDetailsAc(props));
  }
}

export const triggerLoader = (flag) => {
  return (dispatch) => {
    dispatch({
      type: actions.AUDIT_LOADER,
      payload: flag
    })
  }
}
