import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import {DropzoneArea} from 'material-ui-dropzone';
import { Button, TableRow, TableCell, TextField, Paper, Table, TableHead, TableBody, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Checkbox, CircularProgress, Tooltip, IconButton } from '@material-ui/core';
import { EmptyCollection, DeletePopUp, PageLoader, Pagination, STableLoader } from '../../../shared_elements';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import GetAppIcon from '@material-ui/icons/GetApp';
import { globalDeleteService, globalGetService, globalPostService, globalPutService } from '../../../utils_v2/globalApiServices';
import { checkApiStatus } from '../../../utils_v2';
import { displayDateFormatShort } from '../../../constants';

const AddEditAttachment = ({params, disabled, getResponseBack}) => {
  const [isLoading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [files, setFiles] = useState([]);
  const onUploadAttachment = () => {
    let formData = new FormData();
    for(let i=0;i < files.length;i++){
      formData.append(`files_${i+1}`, files[i])
    }
    setLoading(true);
    globalPostService(`technical/v2/workorder/${params.workOrderSlug}/mr-claim/attachments/`, formData)
    .then(response => {
      if(checkApiStatus(response)){
        setModal(false); setFiles([]);
        getResponseBack();
      }else{
        
      }
      setLoading(false);
    })
  }
  return(
    <>
      <Button disabled={disabled} onClick={() => setModal(true)} color='primary' size='small' variant='contained'>Add Attachment</Button>
      {modal ?
        <Dialog
          open={modal}
          onClose={() => {setModal(false); setFiles([])}}
          className='project-management-modal'
          maxWidth='md'
        >
          <DialogTitle id="scroll-dialog-title">
            Add Attachment(s)
          </DialogTitle>
          <DialogContent dividers={true}>
            <Grid container spacing={1}>
              <Grid item md={12}>
                <div style={{width:'380px'}}>
                    <DropzoneArea
                        filesLimit={10}
                        maxFileSize={104857600}
                        showPreviewsInDropzone={false}
                        useChipsForPreview={true}
                        showPreviews={ true }
                        dropzoneText={ <p style={{fontSize:'13px'}}>Drag & Drop Documents<br/> OR <br/> Click Here<br/> </p>}
                        dropzoneClass="drag-drop-cnt"
                        maxWidth="sm"
                        showAlerts={['info','error']}
                        alertSnackbarProps={{anchorOrigin: {vertical:'top', horizontal:'right'}}}
                        clearOnUnmount={true}
                        onDrop={(files) => setFiles(files)} 
                    />
                </div>
                <span className='files-upload-limit' > Note : Max files upload limit is 10 in single time.</span>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button disabled={isLoading} onClick={() => {setModal(false);setFiles([])}} color="primary" size="small">Cancel</Button>
            { files?.length ?
              <Button disabled={isLoading} onClick={onUploadAttachment} variant="contained" color="primary" size="small">
                {isLoading ? <CircularProgress size={20} /> : 'SAVE' }
            </Button>:null
            }
          </DialogActions>
        </Dialog>:null
      }
    </>
  )
}
const AttachmentDesc = ({item, index, onEditDescription, canEdit}) => {
  const [desc, setDesc] = useState(item?.description||'')
  return(
    <TextField
      disabled={!canEdit}
      id={`description_${index}`}
      fullWidth
      margin="none"
      multiline
      rows={1}
      inputProps={{maxLength: 250}}
      onChange={(e) => setDesc(e.target.value)}
      onBlur={(e) => onEditDescription(e.target.value)}
      value={desc}
      InputLabelProps={{shrink: true}}
      variant="outlined"
    />
  )
}
const Attachments = ({params, currentFormDetail, getCurrentFormDetail}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [skeletonLoader, setSkeletonLoader] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [filter, setFilter] = useState({});
  const [attachmentsInfo, setAttachmentsInfo] = useState({list:[], pagination:null});
  const [deleteAttachment, setDeleteAttachment] = useState({data:[], type:'', modal:false});
  useEffect(() => {
    getAttachmentsInfo({}, 'skeletonLoader');
  },[]);
  const getAttachmentsInfo = (query, loaderType) => {
    loaderType === 'skeletonLoader'? setSkeletonLoader(true):setLoading(true);
    globalGetService(`technical/v2/workorder/${params.workOrderSlug}/mr-claim/attachments/`, query)
    .then(response => {
        if(checkApiStatus(response)){
          setAttachmentsInfo(response.data.data)
        }
        loaderType === 'skeletonLoader'? setSkeletonLoader(false):setLoading(false);
    })
  }
  const onEditDescription = (value, id) => {
    let isSave = false;
    const attachment = attachmentsInfo.list.find((item) => item.id === id);
    if(value?.trim()?.length){
        if(value !== attachment.description){
            isSave = true;
        }
    }else{
        if(attachment?.description &&  attachment?.description?.trim()?.length){
            isSave = true;
        }
    }
    if(isSave){
      setLoading(true);
      globalPutService(`technical/v2/workorder/${params.workOrderSlug}/mr-claim/attachments/${id}/`, {description:value})
      .then(response => {
        if(checkApiStatus(response)){
          enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
          setAttachmentsInfo({...attachmentsInfo, list:attachmentsInfo.list.map((item) => item.id !== id ? item : {...item, description:value})})
        }else{
          enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
        }
        setLoading(false);
      })
    }
  }
  const handleChange = (type, flag, id) => {
    if(type === 'single'){
        setDeleteAttachment({
            ...deleteAttachment,
            type:'bulk',
            data: flag ? [...deleteAttachment.data, id]:deleteAttachment.data.filter(item =>  item !== id)
        })
    }else{
        let ids = attachmentsInfo.list.map(item => item.id)
        setDeleteAttachment({
            ...deleteAttachment,
            type:'bulk',
            data: flag ? ids:[]
        })
    }
}
  const onDeleteAttachment = () => {
    setLoading(true);
    globalDeleteService(`technical/v2/workorder/${params.workOrderSlug}/mr-claim/attachments/`, {"ids": deleteAttachment.data})
    .then(response => {
        if(checkApiStatus(response)){
            enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
            setDeleteAttachment({modal:false, type:'', data:[]});
            getAttachmentsInfo({}, 'pageLoader');
            getCurrentFormDetail('pageLoader');
        }
        setLoading(false);
    })
  }
  const canEdit = currentFormDetail?.current_form?.permissions?.can_save;
  return(
    <div className='forms-table-layout'>
      { skeletonLoader ? <STableLoader count={5} />:
        <>
          <Paper className='forms-table-cta' square>
            <Grid container spacing={1}>
              <Grid item md={6}></Grid>
                <Grid item md={6}>
                  { canEdit ?
                    <ul className='list-inline flex-centered'>
                      { deleteAttachment?.data?.length && deleteAttachment.type !== 'single' ?
                        <li className='list-inline-item'>
                          <Button color='secondary' size='small' variant='contained' onClick={() => setDeleteAttachment({...deleteAttachment, modal:true})}>Delete</Button>
                        </li>:null
                      }
                      <li className='list-inline-item'>
                        <AddEditAttachment disabled={(deleteAttachment?.data?.length && deleteAttachment.type === 'bulk')} params={params} getResponseBack={() => getAttachmentsInfo()} />
                      </li>
                    </ul>:null
                  }
                </Grid>
              </Grid>
          </Paper>
          <Paper className='forms-table-content'>
            <div style={{height: !attachmentsInfo.list.length ? 'auto':`${window.innerHeight-290}px`,overflow:'auto', paddingRight:'5px'}}>
              <Table className='mui-table-format' stickyHeader>
                <TableHead>
                  <TableRow>
                    { canEdit ?
                      <TableCell style={{width:'40px'}}>
                        <div style={{width:'40px'}}>
                          <Checkbox
                            size='small'
                            checked={deleteAttachment?.data?.length && deleteAttachment.type === 'bulk' ? deleteAttachment?.data?.length === attachmentsInfo?.list.length :false}
                            onChange={(e) => handleChange('bulk', e.target.checked)}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            disabled={!attachmentsInfo?.list?.length}
                          />
                        </div>
                      </TableCell>:null
                    }
                    <TableCell>File Name</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Uploaded By</TableCell>
                    <TableCell align='right'>Action(s)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {attachmentsInfo.list.map((item, index) =>
                    <TableRow>
                      { canEdit ?
                        <TableCell style={{width:'40px'}}>
                          <div style={{width:'40px'}}>
                            <Checkbox
                              size='small'
                              checked={deleteAttachment.data.includes(item.id) && deleteAttachment.type === 'bulk'}
                              onChange={(e) => handleChange('single', e.target.checked, item.id)}
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                              disabled={!attachmentsInfo?.list?.length}
                            />
                          </div>
                        </TableCell>:null
                      }
                      <TableCell>
                        <div style={{width:'280px'}}>
                          {item?.name||'--'}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div style={{width:'380px'}}>
                          <AttachmentDesc 
                            item={item} 
                            canEdit={canEdit}
                            index={index} 
                            onEditDescription={(value) => onEditDescription(value, item.id)} />
                        </div>
                      </TableCell>
                      <TableCell>
                        {item?.created_by?.name||'--'}
                        <p>{moment(item.created_at).format(displayDateFormatShort)}</p>
                      </TableCell>
                      <TableCell align='right'>
                        <ul className='list-inline' style={{width:'80px', float:'right'}}>
                          { canEdit && !(deleteAttachment?.data?.length && deleteAttachment.type === 'bulk') ?
                            <li className='list-inline-item'>
                              <Tooltip title='Delete' arrow>
                                <IconButton size="small">
                                  <DeleteOutlineIcon onClick={() => setDeleteAttachment({modal:true, type:'single', data:[item.id]})} fontSize='small' color='secondary' />
                                </IconButton>
                              </Tooltip>
                            </li>:null
                          }
                          {currentFormDetail?.current_form?.permissions?.can_export ?
                            <li className='list-inline-item'>
                              <a href={item.attachment} download>
                                <Tooltip title='Download' arrow>
                                  <IconButton size="small">
                                    <GetAppIcon fontSize='small' color='primary' />
                                  </IconButton>
                                </Tooltip>
                              </a>
                            </li>
                            : null}
                        </ul>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
            <Pagination 
                pagination={attachmentsInfo.pagination}
                onChangePage={(event, newPage) => getAttachmentsInfo({...filter, page:newPage+1, per_page: attachmentsInfo.pagination.per_page}, 'pageLoader')}
                onChangeRowsPerPage={(event) => getAttachmentsInfo({...filter, page:1, per_page: event.target.value}, 'pageLoader')}
            />
            { !attachmentsInfo.list.length ? <EmptyCollection title="No records found" />:null }
          </Paper>
        </>
      }
      { deleteAttachment.modal ?
          <DeletePopUp
              modal={deleteAttachment.modal}
              toggleModalFn={() => setDeleteAttachment({data:[], type:'', modal:false})}
              title="Delete Attachment(s)"
              content={<h4>Are you sure you want to delete?</h4>}
              deleteRecordFn={onDeleteAttachment}
          />:null
      }
      { isLoading ? <PageLoader />: null }
    </div>
  )
}
export default withRouter(Attachments);