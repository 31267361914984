import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { imgStoragePath } from '../../../../../constants';
import { EditFormBar, TextInputField, AircraftBluePrints,ImageSlider, SecondaryTechHeaderRH, SecondaryTechHeaderMid, FormHeader, ContentDrop, ReviewCollapse, ImageAnnotator, DownloadedLogs } from '../../../Elements';
import { MSNSwitcherHeader, ListLoader, ToolTipHover,ExportFiles,LicenceModal } from '../../../../../shared';
import { EditAuditsReports, AddEditFinding, CoverImageUpload, IntroductionEditor, AircraftDocsAndReview, ReviewEditor, QuestionList } from '../components';
import { AUDITS_SECTIONS_INFO, UPDATE_TITLE, AUDITS_FINDING_INFO, COVER_IMAGE, FORM_INTRODUCTION, RECORD_REVIEW, RECORD_REVIEW_TOGGLE, ADD_REVIEW_TOGGLE, REMOVE_REVIEW_TOGGLE, UPDATE_REVIEW_TOGGLE } from '../actions';
import { EDIT_FINDING } from '../../T007/actions'
import { downLoadTechFormAc, usageDetailAc } from '../../../../../shared/actionCreators'
import { getFormDetailsAc, updateFormStatusAc } from '../../T001/actionCreators';
import { removeEmptyKey, getLocalStorageInfo } from '../../../../../utils';
import { fetchAircraftModelAc, fetchEngineModelAc, fetchOperatorAc } from '../../T005/actionCreators';
import { editFindingSaveAc, editFindingImageAc, auditFindingDetailAc } from '../../T007/actionCreators';
import { fetchAuditsSectionsAc, findingAddEditT004Ac, auditSectionInfoAc, editFindingImageSaveAc, deleteImageAc , deleteFindingAc, editImageAc, changePictureAc, auditSectionInfoSaveAc, getCoverImgAc, uploadCoverImgAc, uploadLopaImgAc, AddEditIntroductionQaAc, UpdateIntroductionQaAc, removeComponentAc } from '../actionCreators';
import { BUY_LICENCE } from '../../../../../shared/actions';
import editIcon from '../../../../../shared/assets/img/edit_icon.svg';
import viewIcon from '../../../../../shared/assets/img/view_icon.svg';
import 'tui-image-editor/dist/tui-image-editor.css';
import ImageEditor from '@toast-ui/react-image-editor';
class InspectionFormT004 extends Component {
  constructor(props){
    super(props);
    this.state = {
      indexStatus:  true,
      activeSectionId: 0,
      downloadFormLogs: false,
      exportParams: [],
      modal: false,
      modalSlider:false,
      fileName:'',
      fileType:'',
      sliderIndex: 0,
      exportType: 'id',
      imageSlider: {
        images: [],
        startIndex: 0
      },

    }
    this.toggle = this.toggle.bind(this);
  }


  editorRef = React.createRef();

  componentDidMount(){
    this.props.fetchAuditsSection();
    this.props.getFormDetails(1, 'T004');
    this.props.getQaFn();
    this.props.fetchAircraft();
    this.props.fetchOperator();
    this.props.fetchEngineModel();
    if(getLocalStorageInfo().user.permission.records && getLocalStorageInfo().user.permission.records.is_saas){
			this.props.usageDetail()
		}
  }
  toggle() {
    this.setState(prevState => ({
      ...prevState,
      modal: !prevState.modal,
    }));
  }


  openSlider = (plates, startIndex,title) => {
    let items = [];
    let position=0;

    plates.map(plate => {
        items.push({
          original: plate.image,
          thumbnail: plate.image,
          title:'',
          id:plate.id,
        })

    });

    items.map((item,index) => {
     if(item.id==plates[startIndex].id){
     position=index;
     }
    });

    this.setState(prevState => ({
      ...prevState,
      modalSlider: !prevState.modalSlider,
      sliderIndex: position,
      imageSlider: {
        ...prevState.imageSlider,
        images: items,
        startIndex: position
      }
    }));
  }

  toggleSlider = () => {
    this.setState({
      modalSlider: !this.state.modalSlider,
      modalTitle: '',
    });
  }
  updateExpOption = (flag, value, type) => {
    let ids = Object.assign([], this.state.exportParams);
    if(flag){
      ids = [...ids, value]
    }else{
      ids = ids.filter(id => id != value)
    }
    this.setState(prevState => ({
      ...prevState,
      exportParams: ids,
      exportType: type
    }));
  }

  updateImgFn = (files) => {
    if(getLocalStorageInfo().user.permission.records && getLocalStorageInfo().user.permission.records.is_saas){
      let filesSize = 0
      for(let file of files){
        filesSize += file.size
      }
      if(this.props.storageUsage.used + filesSize >= this.props.storageUsage.available){
        this.props.buyLicence({
          flag: true,
          action: '/licenses/storage',
          content: 'The uploaded file(s) size is not available in storage.'
        })
        return;
      }
    }
    this.props.updateImgFn(files)
  }

  uploadLopaImage = (data) =>{


  }

  editFindingImageSave = (data, type, fileArr, sectionId, files) =>{
    if(getLocalStorageInfo().user.permission.records && getLocalStorageInfo().user.permission.records.is_saas){
      let filesSize = 0
      for(let file of files){
        filesSize += file.size
      }
      if(this.props.storageUsage.used + filesSize >= this.props.storageUsage.available){
        this.props.buyLicence({
          flag: true,
          action: '/licenses/storage',
          content: 'The uploaded file(s) size is not available in storage.'
        })
        return;
      }
    }
    this.props.editFindingImageSave(data, type, fileArr, sectionId)
  }

  exportFile=()=>{
    let filterParam ={};
    if(this.state.exportParams.length>0){
      let choices='';
      for(let i=0;i<this.state.exportParams.length;i++){
        if(i==0){
        choices = this.state.exportParams[i];
        }else{
        choices=choices+','+this.state.exportParams[i];
      }
      }
       filterParam = {
        choices:choices,
        download:this.state.fileType
      }
    }else{
      filterParam = {
        download:this.state.fileType
      }
    }
   this.toggle();
   this.props.exportReport('audit-report-sections',this.state.fileName,this.state.fileType,filterParam, this.state.exportType);
  }

  exportReport = (url,name,fileType) => {
    if(this.props.questions.length){
      let ids = []
      this.props.questions.map(question => {
        ids.push(question.lessor_id != null ? question.id : question.order)
      })
      this.setState(prevState => ({
        ...prevState,
        exportParams: ids
      }));
    }
    if(fileType=='consolidated'){
      let filterParam = {
        download :'pdf',
        consolidate:1
      }
     this.props.exportReport(url,name+'_consolidated','pdf',filterParam);
    }else if(fileType=='Inspection'){
      let filterParam = {
        download :'pdf',
        inspection:1
      }
     this.props.exportReport(url,name+'_inspection','pdf',filterParam);
    }else if(fileType=='checklist'){
      let filterParam = {
        download :'pdf',
        checklist:1
      }
     this.props.exportReport(url,name+'_checklist','pdf',filterParam);
    }else{
      this.setState(prevState => ({
        ...prevState,
        fileName:name ,
        fileType:fileType
      }));
      this.toggle();
    }

  }
  changeRecordReviews = (type, data, questionIndex ) => {
    this.setState(prevState => ({
      ...prevState,
      activeSectionId: (questionIndex)
    }))
    this.props.changeRecordReviews(type, {data: questionIndex, flag:true});
  }
  changeFormIntroduction = (type, data, questionIndex) => {
    this.setState(prevState => ({
      ...prevState,
      activeSectionId: (questionIndex)
    }))
    this.props.changeFormIntroduction(type, {data: questionIndex, flag:true});
  }
  updateTitleSinggleFn=(data,key,value)=>{
    data = {
      ...data,
      [key] : value
    }
    this.props.updateIntroduction(data);
  }
  indexToggleFn = () => {
    this.setState(prevState => ({
      ...prevState,
      indexStatus: !prevState.indexStatus
    }))
  }
  toggleDownloadedLogs = () => {
    this.setState(prevState => ({
      ...prevState,
      downloadFormLogs: !prevState.downloadFormLogs
    }))
  }
  getAnnotatedImage = () => {
    const editorInstance = this.editorRef.current.getInstance();
    var blob = editorInstance.toDataURL();
    setTimeout(() => {
      this.setState(prevState => ({
        ...prevState,
        editedImage: blob
      }))
      this.props.editFindingSave(blob, this.props.findingCrud.data.imageId)
    }, 1000)
  }
  render(){
    const { findingCrud, formInfo, auditReportsCrud, auditsSections, auditFindingCrud, coverImage, formIntroduction, recordReviews, inspectionSummary, auditFindings, findingLoader, questions,techExportLoader, formStatusLoader } = this.props;
    let customClass = ''
    let editorRef = React.createRef();
    let  avlonExport = [{img: 'pdf_icon.png', label: 'PDF', type: 'pdf'},{img:'docx_exp_icon.png', label: 'Document', type:'docx'},{img:'pdf_icon.png', label: 'Inspection Report', type:'Inspection'},{img:'pdf_icon.png', label: 'Physical Checklist Report', type:'checklist'}];
    let defaultExport = [{img: 'pdf_icon.png', label: 'PDF', type: 'pdf'},{img:'docx_exp_icon.png', label: 'Document', type:'docx'}];
    if(Object.keys(auditsSections).length && auditsSections.list.length){
        switch(auditsSections.aircraft_model.blueprint.id){
          case 1 :
          customClass = 'model-two'
          break;
          case 2 :
            customClass = 'model-three'
            break;
          case 3:
            customClass = 'model-four'
            break;
          case 4:
           customClass = 'model-eight'
            break;
          case 5:
            customClass = 'model-eight'
           break;
          case 6:
           customClass = 'model-one'
           break;
          case 7:
            customClass = 'model-one'
            break;
          case 8:
           customClass = 'model-nine'
          break;
          case 9:
            customClass = 'model-nine'
          break;
          case 10:
            customClass='model-seven'
          break;
          case 11:
          customClass ='model-eleven'
          break;
          case 12:
          customClass ='model-two'
          break;
          case 13:
          customClass ='model-two'
          break;
          default:
          customClass ='model-one'
        }
      }
      const assetTypes = {
        1: 'MSN',
        3: 'APU',
        4: 'LG',
        5: 'PROPELLER'
      }
    return(
      <div className="technical-inspection-cn">
        <MSNSwitcherHeader
          SecodaryHeaderRH = {<SecondaryTechHeaderRH projectInfo={formInfo.project}/>}
          SecondaryHeaderMid = {<SecondaryTechHeaderMid projectInfo={formInfo.project} activeAsset={formInfo.asset}/>}
          activeAsset={formInfo.asset}
          projectSlug = {Object.keys(formInfo).length ? formInfo.project.slug: ''}
        />
        <FormHeader
          formInfo={formInfo}
          exportReport={ <ExportFiles exportFile={(fileType) => this.exportReport('audit-report-sections',assetTypes[formInfo.asset.asset_type]+'_'+(formInfo.asset.msn ? formInfo.asset.msn : formInfo.asset.serial_number)+this.props.formInfo.current_form.name,fileType)} exportName={'Export Report '} files={  getLocalStorageInfo().defaultLessor&&  getLocalStorageInfo().defaultLessor.id==68?avlonExport:defaultExport} />}
          updateFormStatus={(data) => this.props.updateFormStatus(data)}
          toggleDownloadedLogs = {this.toggleDownloadedLogs}
         />
       { (techExportLoader || formStatusLoader || findingLoader) ? <ListLoader drawer="primary-nav" />:null}
        <div className="form-t004-cn">
          <div className="indicater-detail-block">
            <ul className="list-unstyled indicater-block">
              <li>
                <span className="poor"></span> Poor / Findings
                ({ auditsSections.list && auditsSections.list.length ? auditsSections.list.reduce((total, sectionList) => total + (sectionList.status == 1 ? 1 : 0), 0) : 0})
              </li>
              <li>
                <span className="average"></span> Average
                ({ auditsSections.list && auditsSections.list.length ? auditsSections.list.reduce((total, sectionList) => total + (sectionList.status == 2 ? 1 : 0), 0) : 0})
              </li>
              <li>
                <span className="good"> </span> Good
                ({ auditsSections.list && auditsSections.list.length ? auditsSections.list.reduce((total, sectionList) => total + (sectionList.status == 3 ? 1 : 0), 0) : 0})
              </li>
            </ul>
          </div>
          <div className="t004-form-sidebar">
            <div>
              <Link className="hide-index" onClick={() => this.indexToggleFn()}>
                { this.state.indexStatus ? <label>Hide Index</label>:<label>Show Index</label> }
              </Link>
            </div>
            { this.state.indexStatus ?
              <ul className="list-unstyled ">
                <li>
                  Cover Photo
                  <img
                    className="edit-img"
                    onClick={() => { this.props.getCoverImageFn()}}
                    src={(formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save ? editIcon: viewIcon)} alt="img" width="12"/>
                </li>
                {
                  questions && questions.length ?
                    questions.map((question, questionIndex) =>
                    <QuestionList formInfo={formInfo} question={question} key={'question_' + questionIndex} questionIndex={questionIndex} changeRecordReviews={this.changeRecordReviews} changeFormIntroduction={this.changeFormIntroduction}/>
                  )
                  :null
                }
              </ul>
              : null
            }
          </div>
          <div className={customClass} >
            { Object.keys(auditsSections).length && auditsSections.list.length ?
              <div className="model-image" >
                <img className="model-image" src={imgStoragePath + auditsSections.aircraft_model.blueprint.image} alt="img" />
                { auditsSections['list'].map((item, index) =>
                  <AircraftBluePrints 
                    key={index}
                    bluePrintId={auditsSections.aircraft_model.blueprint.id}
                    auditSectionInfo={() => this.props.auditSectionInfo(item)}
                    index={index}
                    sectionDetail={item}/>
                )}
              </div>
              :null
            }
          </div>
        </div>
        <EditFormBar
          titleContent={formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save ? 'Add/Update Cover Photo': 'View Cover Photo' }
          toggleEditSideBar={() => this.props.changeCoverImage('', {data: {}, flag: false})}
          isOpen={coverImage.flag}
          style={coverImage.flag ? {width: '600px'} : {width: '0px'}}
          >
          { coverImage.flag ?
            <CoverImageUpload
              coverImage = {coverImage}
              changeCoverImage = {this.props.changeCoverImage}
              updateImgFn = {this.updateImgFn}
              findingLoader = {findingLoader}
              formInfo={formInfo}
            /> : null
          }
        </EditFormBar>
        <EditFormBar
          titleContent= {<input style={{width: '90%'}} value={questions[formIntroduction.data] && Object.keys(questions[formIntroduction.data]).length ? questions[formIntroduction.data]['title'] : 'Introduction'} onChange={(e) => this.props.updateTitleFn(questions[formIntroduction.data-1]['id'], 'title', e.target.value)}/>}
          toggleEditSideBar={() => this.props.changeFormIntroduction(' ',{data: {}, flag: false})}
          isOpen={formIntroduction.flag}
          style={formIntroduction.flag ? {width: '800px'} : {width: '0px'}}
          >
          { formIntroduction.flag ?
            <IntroductionEditor
              formIntroduction={questions[formIntroduction.data]}
              toggleEditSideBar={() => this.props.changeFormIntroduction('', {data: {}, flag: false})}
              updateFormIntroduction = {this.props.updateIntroduction}
              findingLoader = {findingLoader}
              formInfo={formInfo}
            /> : <div style={{textAlign: 'center', paddingTop: '30%'}}>Loading...</div>
          }
        </EditFormBar>
        <EditFormBar
          titleContent= {<input placeholder="Enter the title here" style={{width: '90%', borderBottom: '1px solid #2670ca'}} value={questions[recordReviews.data] && Object.keys(questions[recordReviews.data]).length ? questions[recordReviews.data]['title'] : ''} onChange={(e) => this.props.updateTitleFn(questions[recordReviews.data]['id'], 'title', e.target.value)}/>}
          toggleEditSideBar={() => this.props.changeRecordReviews('recordReviews', {data: [], flag: false})}
          isOpen={recordReviews.flag}
          style={recordReviews.flag ? {width: '800px'} : {width: '0px'}}
          >
            { recordReviews.flag  ?
            <AircraftDocsAndReview
              recordReviews={questions[recordReviews.data]}
              removeReviewCollapse  = {this.props.removeReviewCollapse}
              addReviewCollapse = {this.props.addReviewCollapse}
              updateReviewCollapse = {this.props.updateReviewCollapse}
              toggleReviewCollapse = {this.props.toggleReviewCollapse}
              updateCkEditer = {this.props.updateReviewCollapse}
              updateIntroduction = {this.props.updateIntroduction}
              toggleEditSideBar={() => this.props.changeRecordReviews('recordReviews', {data: [], flag: false})}
              formInfo={formInfo}
            /> : <div style={{textAlign: 'center', paddingTop: '30%'}}>Loading...</div>
          }
        </EditFormBar>
        <EditFormBar
          titleContent={auditReportsCrud.title}
          toggleEditSideBar={() => this.props.dispatch({type: AUDITS_SECTIONS_INFO, payload: {type: '', flag: false, data:{}}})}
          isOpen={auditReportsCrud.flag}
          style={auditReportsCrud.flag ? {width: '840px'} : {width: '0px'}}
        >
          { auditReportsCrud.flag ?
            <EditAuditsReports
              auditsInfo={auditReportsCrud.data}
              sectionId={auditReportsCrud.sectionId}
              toggleEditSideBar={() => this.props.dispatch({type: AUDITS_SECTIONS_INFO, payload: {type: '', flag: false, data:{}}})}
              findingCrudFn={this.props.findingCrudFn}
              addEditFinding = {this.props.addEditFindingFn}
              deleteFindingFn = {this.props.deleteFindingFn}
              addEditFindingFn = {this.props.addEditFindingFn}
              auditSectionInfoSaveFn = {this.props.auditSectionInfoSaveFn}
              lessee_list = {this.props.lessee_list}
              engine_model = {this.props.engine_model}
              aircraft_model = {this.props.aircraft_model}
              removeComponentFn = {this.props.removeComponentFn}
              uploadLopa={this.uploadLopaImage}
              formInfo={formInfo}
              storageUsage={this.props.storageUsage}
              buyLicence={this.props.buyLicence}
              />:<div style={{textAlign: 'center', paddingTop: '30%'}}>Loading...</div>
          }
        </EditFormBar>
        <EditFormBar
          titleContent={"Add Findings to "+auditReportsCrud.title}
          toggleEditSideBar={() => this.props.dispatch({type: AUDITS_FINDING_INFO, payload: {type: '', flag: false, data: {}}})}
          isOpen={auditFindingCrud.flag}
          style={auditFindingCrud.flag ? {width: '840px'} : {width: '0px'}}
          >
          { auditFindingCrud.flag ?
            <AddEditFinding
              formInfo={formInfo}
              sectionId = {auditFindingCrud.sectionId}
              addEditFinding = {this.props.addEditFindingFn}
              auditFindingCrud={auditFindingCrud}
              toggleEditSideBar={() => this.props.dispatch({type: AUDITS_FINDING_INFO, payload: {type: '',flag: false, data: {}}})}
              addFindingImageSave = {this.props.addFindingImageSave}
              editFindingImageSave = {this.editFindingImageSave}
              editFindingImage= {this.props.editFindingImage}
              deleteImgFn = {this.props.deleteImgFn}
              changePicture = {this.props.changePicture}
              openSlider = {this.openSlider}
              editImage = {this.props.editImageFn}
              findingLoader = {findingLoader}
            />:<div style={{textAlign: 'center', paddingTop: '30%'}}>Loading...</div>
          }
        </EditFormBar>
        <Modal isOpen={this.state.modalSlider} toggle={this.toggleSlider} className="modal-tform-pictures">
          <ModalHeader toggle={this.toggleSlider}>{this.state.imageSlider.images.length ? this.state.imageSlider.images[this.state.sliderIndex].title:''}</ModalHeader>
          <ModalBody>
            {
              this.state.modalSlider ?
              <ImageSlider getCurrentIndex={this.getCurrentIndex} imageSlider={this.state.imageSlider} />:null
            }

          </ModalBody>
        </Modal>
        <EditFormBar
           titleContent={ <div><h3>Edit Findings Of {findingCrud.flag && findingCrud.title ? findingCrud.title:''} </h3></div>}
           toggleEditSideBar={() => this.props.dispatch({type: EDIT_FINDING, payload: {title: '', flag: false, imageUrl: ''}})}
           isOpen={findingCrud.flag}
           style={findingCrud.flag ? {width: '840px'} : {width: '0px'}}
           >
           { findingCrud.flag ?
             <ImageEditor
               ref={this.editorRef}
               includeUI={{
                 loadImage: {
                   path: findingCrud.data.imageUrl,
                   name: 'SampleImage',
                 },
                 initMenu: 'filter',
                 uiSize: {
                   width: '800px',
                   height: '700px',
                 },
                 menuBarPosition: 'bottom',
               }}
               cssMaxHeight={500}
               cssMaxWidth={700}
               selectionStyle={{
                 cornerSize: 20,
                 rotatingPointOffset: 70,
               }}
               usageStatistics={false}
             />
             :<div style={{textAlign: 'center', paddingTop: '30%'}}>Loading...</div>
           }
           <div className="edit-sidebar-footer" style={findingCrud.flag ? {width: '840px', position: 'fixed', bottom: '0px', right: '0px',left: (window.innerWidth - 840) + 'px'} : {width: '0px', left: window.innerWidth + 'px'}}>
             <ul className="list-inline">
               <li className="list-inline-item">
                 <button disabled={this.props.findingLoader} onClick={() => this.getAnnotatedImage()} type="submit" className="btn btn-primary">{ this.props.findingLoader ? 'Processing...' : 'Save'}</button>
               </li>
               <li className="list-inline-item">
                 <Link onClick={() => this.props.dispatch({type: EDIT_FINDING, payload: {title: '', flag: false, imageUrl: ''}})}> Cancel</Link>
               </li>
             </ul>
           </div>
         </EditFormBar>
         { findingLoader ? <ListLoader drawer="primary-nav" /> : null }
         <EditFormBar
           titleContent= { <div><h3 style={{ textTransform: 'capitalize'}}>Downloaded Logs</h3></div>}
           toggleEditSideBar={this.toggleDownloadedLogs}
           isOpen={this.state.downloadFormLogs}
           style={this.state.downloadFormLogs ? {width: '400px'} : {width: '0px'}}
           >
           {this.state.downloadFormLogs ?
             <DownloadedLogs logs={formInfo.current_form.download_report} />
             :<div style={{textAlign: 'center', paddingTop: '30%'}}>Loading...</div>
           }
         </EditFormBar>
         <Modal size="lg" centered={true} isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.toggle}>Export Report</ModalHeader>
          <ModalBody>
            <Row>
            {
              questions && questions.length ?
                questions.map((question, questionIndex) =>
                <Col md="4"  style={{marginBottom: '10px'}}>
                  <label style={{fontSize: '12px'}}>
                    <input
                     style={{float:'left',marginRight:'4px',top:'3px',position:'relative',marginBottom:'7px'}}
                      type="checkbox"
                      checked={this.state.exportParams.includes(question.lessor_id != null ? question.id : question.order)}
                      onChange={(e) => this.updateExpOption(e.target.checked, question.lessor_id != undefined && question.lessor_id != null ? question.id : question.order, question.lessor_id != undefined && question.lessor_id != null ? 'id' : 'order')}
                    /> {question.title}
                  </label>
                </Col>
              )
              :null
            }
            </Row>
          </ModalBody>
          <ModalFooter>
            <button className="primary-btn" onClick={()=>this.exportFile()}>Export</button>
          </ModalFooter>
        </Modal>
        {
          this.props.buyLicenceModal.flag ?
          <LicenceModal
            planId="2"
            toggleLicence={() => this.props.buyLicence({
              flag: false,
              action: '',
              content: ''
            })}
            licenceModal={this.props.buyLicenceModal}
            />
          :null
        }
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  formInfo: state.FormT001Reducer.formInfo,
  auditReportsCrud: state.FormT004Reducer.auditReportsCrud,
  auditFindingCrud: state.FormT004Reducer.auditFindingCrud,
  auditsSections: state.FormT004Reducer.auditsSections,
  coverImage: state.FormT004Reducer.coverImage,
  formIntroduction: state.FormT004Reducer.formIntroduction,
  recordReviews: state.FormT004Reducer.recordReviews,
  auditFindings: state.FormT004Reducer.auditFindings,
  inspectionSummary: state.FormT004Reducer.inspectionSummary,
  findingCrud: state.FormT007Reducer.findingCrud,
  findingLoader: state.FormT007Reducer.findingLoader,
  questions: state.FormT004Reducer.questions,
  aircraft_model: state.FormT005Reducer.aircraft_model,
  engine_model: state.FormT005Reducer.engine_model,
  lessee_list: state.FormT005Reducer.lessee_list,
  techExportLoader:state.sharedReducers.techExportLoader,
  formStatusLoader:state.FormT001Reducer.formStatusLoader,
  buyLicenceModal: state.sharedReducers.buyLicenceModal,
  storageUsage: state.sharedReducers.storageUsage,
});
const mapDispatchToProps = (dispatch, ownProps) =>{
  return{
    getFormDetails: (type, application) => dispatch(getFormDetailsAc(ownProps, type, application)),
    updateFormStatus: (data) => dispatch(updateFormStatusAc(ownProps, data)),
    fetchAuditsSection: () => dispatch(fetchAuditsSectionsAc(ownProps.params)),
    auditSectionInfo: (data) => dispatch(auditSectionInfoAc(ownProps, data)),
    findingCrudFn: (type, data, sectionId) => dispatch(auditFindingDetailAc(ownProps, type, data, sectionId)),
    addEditFindingFn: (data, type, fileArr, sectionId) => dispatch(findingAddEditT004Ac(ownProps, data, type, fileArr, sectionId)),
    auditSectionInfoSaveFn: (data) => dispatch(auditSectionInfoSaveAc(ownProps, data)),
    updateImgFn: (data) =>  dispatch(uploadCoverImgAc(ownProps, data)),
    uploadLopaImage:(data)=> dispatch(uploadLopaImgAc(ownProps, data)),
    updateIntroduction : (data) => dispatch(UpdateIntroductionQaAc(ownProps, data)),
    updateTitleFn : (sectionId, keyParam, value) => dispatch({
      type:UPDATE_TITLE,
      payload:{sectionId: sectionId, keyParam: keyParam, value: value}
    }),
    changeCoverImage: (data) => dispatch({
      type:COVER_IMAGE,
      payload:data
    }),
    changeFormIntroduction: (type, data) => dispatch({
      type:FORM_INTRODUCTION,
      payload:{
        type,
        data
      }    }),
    getQaFn: () => dispatch(AddEditIntroductionQaAc(ownProps)),
    changeRecordReviews: (type, data) => dispatch({
      type:RECORD_REVIEW,
      payload:{
        type,
        data
      }
    }),
    toggleReviewCollapse: (questionId, answerIndex) => dispatch({
      type:RECORD_REVIEW_TOGGLE,
      payload:{
        questionId : questionId,
        answerIndex: answerIndex
      }
    }),
    addReviewCollapse: (type, data) => dispatch({
      type:ADD_REVIEW_TOGGLE,
      payload:{
        type,
        data
      }
    }),
    removeReviewCollapse: (questionId, answerIndex) => dispatch({
      type:REMOVE_REVIEW_TOGGLE,
      payload:{
        questionId : questionId,
        answerIndex: answerIndex
      }
    }),
    updateReviewCollapse: (questionId, keyParam, data, index) => dispatch({
      type:UPDATE_REVIEW_TOGGLE,
      payload:{
        questionId,
        keyParam,
        data,
        index
      }
    }),
    exportReport:(url,name,fileType,query, type) => dispatch(downLoadTechFormAc(url,ownProps.params.workOrderSlug,fileType,name,query, type)),
    editFindingImageSave: (data, type, fileArr, sectionId) => dispatch(editFindingImageSaveAc(ownProps, data, type, fileArr, sectionId)),
    deleteImgFn: (picId, picIndex, type) => dispatch(deleteImageAc(ownProps, picId, picIndex, type)),
    deleteFindingFn: ( findingId, sectionId) => dispatch(deleteFindingAc(ownProps, findingId, sectionId)),
    editImageFn: (picId) => dispatch(editImageAc(ownProps, picId )),
    changePicture: (picId, file, type) => dispatch(changePictureAc(ownProps, picId, file, type)),
    getCoverImageFn: () => dispatch(getCoverImgAc(ownProps)),
    editFindingImage: (data) => dispatch(editFindingImageAc(ownProps, data)),
    editFindingSave: (image, imageId) => dispatch(editFindingSaveAc(ownProps.params, image, imageId)),
    fetchAircraft: () => dispatch(fetchAircraftModelAc()),
    fetchOperator: () => dispatch(fetchOperatorAc()),
    fetchEngineModel: () => dispatch(fetchEngineModelAc()),
    removeComponentFn: (sectionId, id) => dispatch(removeComponentAc(ownProps,sectionId, id)),
    usageDetail: () => dispatch(usageDetailAc()),
    buyLicence: (modalObj) => dispatch({
      type: BUY_LICENCE,
      payload: modalObj
    })
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(InspectionFormT004);
