import React, { useState, useEffect } from "react";
import { useSnackbar } from 'notistack';
import { withRouter } from 'react-router';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { backendDateFormat, fieldDateFormat, displayDateFormatShort } from "../../../constants";
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, Grid, CircularProgress, Button, TextField, Dialog, DialogContent, DialogTitle, DialogActions, Tooltip, Link } from '@material-ui/core';
import { STableLoader, PageLoader, EmptyCollection, DeletePopUp } from "../../../shared_elements";
import EditIcon from '@material-ui/icons/Edit';
import { globalDeleteService, globalGetService, globalPostService } from "../../../globalServices";
import { checkApiStatus, onCheckFormPermission } from '../../../utils_v2';
import { regexConstants } from "../../../constants/regEx";
const MajorComponentHd = ({ params }) => {
    return (
        <TableHead>
            <TableRow>
                <TableCell><div >Component Sub Class</div></TableCell>
                <TableCell><div >Manufacturer</div></TableCell>
                <TableCell><div >Date of Manufacture</div></TableCell>
                <TableCell><div >Component Model</div></TableCell>
                <TableCell><div >Component Part No.</div></TableCell>
                <TableCell><div >Component Serial No.</div></TableCell>
                <TableCell><div >Current Location</div></TableCell>
                <TableCell><div >{params.type ==='aircraft'?'Latest TSN/ APU Hours':'Latest TSN'}</div></TableCell>
                <TableCell><div >{params.type === 'aircraft'?'Latest CSN/ APU Cycles':'Latest CSN'}</div></TableCell>
                <TableCell><div >TSN/CSN as of Date</div></TableCell>
            </TableRow>
        </TableHead>
    )
}
const MajorComponenetList = ({ error,item, editField, onFieldChange , onResetError, params}) => {
    const [showMore, setShowMore] = useState({});
    const toggleComment = (field) => {
        setShowMore({
            ...showMore,
            [field]: !showMore[field],
        });
    };
    const isPartNoEditable = ['Aircraft', 'Engine 1', 'Engine 2', 'Engine'].includes(item?.component_name);
    const isModelEditable = ['Aircraft', 'Engine 1', 'Engine 2', 'Engine', 'APU', 'Propeller'].includes(item?.component_name)

    return (
        <TableRow >
            <TableCell>
                    <div style={{width:'160px'}}>{item?.component_name || "--"}</div>
            </TableCell>
            <TableCell>{editField ? (
                <TextField
                    style={{ width: '150px' }}
                    value={item?.manufacturer}
                    onChange={(e) => onFieldChange('manufacturer', e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 255 }}
                />
            ) : (
                <>
                    {item?.manufacturer && item?.manufacturer?.length > 30 ? (
                        <div style={{ width: '225px' }} >
                            {showMore[item?.field] && showMore[item?.field] ? (
                                <span> {item?.manufacturer}
                                    <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleComment(item.field); }}  >  less </a>
                                </span>
                            ) : (
                                <span> {item.manufacturer.substring(0, 25)}
                                    <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleComment(item.field); }}  > ..more </a>
                                </span>
                            )}
                        </div>
                    ) :
                        <div style={{ width: '115px' }}>{item?.manufacturer || '--'}</div>
                    }
                </>
            )}
            </TableCell>

            <TableCell>{editField ?
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                        style={{ width: '135px' }}
                        name="date_of_manufacture"
                        format={fieldDateFormat}
                        fullWidth
                        maxDate={moment()}
                        minDate={moment().subtract(25, 'years')}
                        value={item?.date_of_manufacture || null}
                        onChange={(data, value) => { onFieldChange('date_of_manufacture', moment(data).format(backendDateFormat));onResetError('date_of_manufacture') }}
                        error={error?.date_of_manufacture}
                        helperText={error?.date_of_manufacture || ''}
                    />
                </MuiPickersUtilsProvider>
                :
                <div style={{width:'122px'}}>{item?.date_of_manufacture ? moment(item?.date_of_manufacture).format(displayDateFormatShort) : "--"}</div>}
            </TableCell>
            <TableCell>{editField ?
                <TextField
                    disabled={!isModelEditable}
                    value={!isModelEditable ? 'N/A' : item?.model|| ''}
                    onChange={(e) => onFieldChange('model', e.target.value)}
                    style={{ width: "100px" }} 
                    inputProps={{maxLength:255}}
                />
                :
                <>
                    {item?.model && item?.model?.length > 30 ? (
                        <div style={{ width: '225px' }} >
                            {showMore[item?.field] && showMore[item?.field] ? (
                                <span> {!isModelEditable ? 'N/A' : item.model || '--'}
                                    <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleComment(item.field); }}  >  less </a>
                                </span>
                            ) : (
                                <span> {item.model.substring(0, 25)}
                                    <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleComment(item.field); }}  > ..more </a>
                                </span>
                            )}
                        </div>
                    ) :
                    <div style={{ width: "115px" }}>{!isModelEditable ? 'N/A' : item.model || '--'}</div>}
                </>}
                
            </TableCell>
            <TableCell>
                {editField ? 
                    <TextField
                        disabled={isPartNoEditable}
                        style={{ width: "100px" }}
                        value={isPartNoEditable? 'N/A' : item?.part_no || ''}
                        onChange={(e) => onFieldChange('part_no', e.target.value)}
                        inputProps={{maxLength:255}}
                    />
                    :
                    <>
                    {item?.part_no && item?.part_no?.length > 30 ? (
                        <div style={{ width: '225px' }} >
                            {showMore[item?.field] && showMore[item?.field] ? (
                                <span> {isPartNoEditable ? 'N/A' : (item?.part_no || '--')}
                                    <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleComment(item.field); }}  >  less </a>
                                </span>
                            ) : (
                                <span> {item.part_no.substring(0, 25)}
                                    <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleComment(item.field); }}  > ..more </a>
                                </span>
                            )}
                        </div>
                    ) :
                    <div style={{ width: "150px" }}>{isPartNoEditable ? 'N/A' : (item?.part_no || '--')}</div>}
                </>}
            </TableCell>
            <TableCell>{editField ?
                <TextField
                    style={{ width: "100px" }}
                    value={item?.sl_no}
                    onChange={(e) => onFieldChange('sl_no', e.target.value)}
                    inputProps={{maxLength:255}}
                />
                :
                <>
                    {item?.sl_no && item?.sl_no?.length > 30 ? (
                        <div style={{ width: '225px' }} >
                            {showMore[item?.field] && showMore[item?.field] ? (
                                <span> {item?.sl_no}
                                    <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleComment(item.field); }}  >  less </a>
                                </span>
                            ) : (
                                <span> {item.sl_no.substring(0, 25)}
                                    <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleComment(item.field); }}  > ..more </a>
                                </span>
                            )}
                        </div>
                    ) :
                        <div style={{ width: '165px' }}>{item?.sl_no || '--'}</div>
                    }
                </>}
            </TableCell>
            <TableCell>{editField ?
                <TextField
                    style={{ width: "100px" }}
                    value={item?.location}
                    onChange={(e) => onFieldChange('location', e.target.value)}
                    inputProps={{maxLength:255}}
                />
                :
                <>
                    {item?.location && item?.location?.length > 30 ? (
                        <div style={{ width: '225px' }} >
                            {showMore[item?.field] && showMore[item?.field] ? (
                                <span> {item?.location}
                                    <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleComment(item.field); }}  >  less </a>
                                </span>
                            ) : (
                                <span> {item.location.substring(0, 25)}
                                    <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleComment(item.field); }}  > ..more </a>
                                </span>
                            )}
                        </div>
                    ) :
                        <div style={{ width: '115px' }}>{item?.location || '--'}</div>
                    }
                </>}
            </TableCell>
            <TableCell>{editField ?
                <TextField
                    style={{ width: "95px" }}
                    value={item?.tsn ? item?.tsn : ''}
                    inputProps={{ maxLength: 10 }}
                    onChange={(e) => regexConstants.decimalnodotstart.test(e.target.value) ? onFieldChange('tsn', e.target.value) : e.preventDefault()}
                />
                :
                <div  style={{ width:params.type ==='aircraft'? "138px":'100px' }}>{item?.tsn ? (item?.tsn) : '--'}</div>}
            </TableCell>
            <TableCell>{editField ?
                <TextField
                    style={{ width: "95px" }}
                    value={item?.csn ? item?.csn : ''}
                    inputProps={{ maxLength: 10 }}
                    onChange={(e) => regexConstants.decimalnodotstart.test(e.target.value) ? onFieldChange('csn', e.target.value) : e.preventDefault()}
                />
                :
                <div  style={{ width:params.type ==='aircraft'? "142px":'100px' }}>{item?.csn ? (item?.csn) : '--'}</div>}
            </TableCell>
            <TableCell>{editField ? (
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                        style={{ width: "135px" }}
                        name="tsn_csn_date"
                        format={fieldDateFormat}
                        fullWidth
                        maxDate={moment()}
                        minDate={moment().subtract(25, 'years')}
                        InputLabelProps={{ shrink: true }}
                        value={item?.tsn_csn_date || null}
                        onChange={(data, value) => { onFieldChange('tsn_csn_date', moment(data).format(backendDateFormat));onResetError('tsn_csn_date') }}
                        error={error?.tsn_csn_date}
                        helperText={error?.tsn_csn_date || ''}
                    />
                </MuiPickersUtilsProvider>
            ) : (
               <div style={{width:'120px'}}>{moment(item?.tsn_csn_date).isValid() ? moment(item?.tsn_csn_date).format(displayDateFormatShort) : "--"}</div> 
            )}
            </TableCell>
            {/* {!editField ?
                <TableCell>
                    <ul className="list-inline">
                        <li className="list-inline-item hover-inline " onClick={onDelete}> <Tooltip title='Delete' arrow><DeleteOutlineIcon color="secondary" fontSize="small" /></Tooltip></li>
                    </ul>
                </TableCell> : null} */}
        </TableRow>
    )
}

const AddEditLLP = ({ params, addEditMajorComponent, toggleModalFn, getResponseBack }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState([]);
    const [majorComponents, setMajorComponents] = useState(addEditMajorComponent.majorComponent);
    const onAddEditMajorComponents = (loaderType) => {
        let comError = [], isComError = true;
        comError = majorComponents?.map((major) => {
            return {
                tsn_csn_date: major.tsn_csn_date ? major.tsn_csn_date && !moment(major.tsn_csn_date).isValid()?'Enter valid TSN/CSN as of date': moment(major.tsn_csn_date).isValid() && moment(major.tsn_csn_date).isSameOrBefore(moment(), 'day') ? '' : "TSN/CSN as of date can't be future Date":'',
                date_of_manufacture: major.date_of_manufacture ?major.date_of_manufacture && !moment(major.date_of_manufacture).isValid()?'Enter valid Date of Manufacture':moment(major.date_of_manufacture).isValid() && moment(major.date_of_manufacture).isSameOrBefore(moment(), 'day') ? '' : "Date of Manufacture can't be future Date":'',
            }
        })
        comError.map(item => {
            if (!Object.keys(item).every((k) => { return item[k] === '' })) {
                isComError = false;
            }
        });
        if (isComError) {
        setLoading(true);
        globalPostService(`technical/workorder/${params.workOrderSlug}/t014/major-component-history/?type=T014&asset_type=${params.type}`, { major_assemblies: majorComponents })
            .then(response => {
                if (checkApiStatus(response)) {
                    enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' }, });
                    toggleModalFn(); getResponseBack();
                }
                setLoading(false);
            })
        }else{
            setError(comError)
        }
    }


    const onFieldChange = (key, value, index) => {
        setMajorComponents(majorComponents?.map((item, itemIndex) => itemIndex !== index ? item : { ...item, [key]: value.trimStart() }));
    }
    return (
        <Dialog
            open={addEditMajorComponent.modal}
            onClose={toggleModalFn}
            className='console-management-modal'
            fullScreen
        >
            <DialogTitle id="scroll-dialog-title">
                Edit Major Component
            </DialogTitle>
            <DialogContent dividers={true}>
                <Paper>
                    <Table className='mui-table-format'>
                        <MajorComponentHd params={params} editField={true} />
                        <TableBody>
                            {majorComponents?.map((item, index) =>
                                <MajorComponenetList
                                    key={index}
                                    item={item}
                                    params={params}
                                    editField={true}
                                    onFieldChange={(key, value) => onFieldChange(key, value, index)}
                                    error={error[index] ? error[index] : null}
                                    setError={setError}
                                    onResetError={(key) => error?.length ? setError(error.map((errorItem, errorIndex) => index !== errorIndex ? errorItem : { ...errorItem, [key]: '' })) : setError([])}
                                />
                            )}
                        </TableBody>
                    </Table>
                </Paper>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} onClick={toggleModalFn} color='primary' size='small' variant='outlined'>Cancel</Button>
                <Button disabled={isLoading} onClick={onAddEditMajorComponents} color='primary' size='small' variant='contained'>{isLoading ? <CircularProgress size={24} /> : 'Save'}</Button>
            </DialogActions>
        </Dialog>
    )
}
const MajorComponents = ({ params, getCurrentFormDetail, currentFormDetail}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [filter, setFilter] = useState({});
    const [majorComponentInfo, setMajorComponentInfo] = useState({ data: [] });
    const [addEditMajorComponent, setAddEditMajorComponent] = useState({ modal: false, data: null });
    const [deleteMajorComponent, setDeleteMajorComponent] = useState({ modal: false, data: null });
    useEffect(() => {
        getMajorComponent('skeletonLoader');
    }, []);
    const getMajorComponent = (loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true) : setLoading(true)
        globalGetService(`technical/workorder/${params.workOrderSlug}/t014/major-component-history/?type=T014&asset_type=${params.type}`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setMajorComponentInfo(response.data);
                    getCurrentFormDetail();
                }
                loaderType === 'skeletonLoader' ? setSkeletonLoader(false) : setLoading(false)
            })
    }
    const onDeleteMajorComponent = () => {
        setLoading(true);
        globalDeleteService(`technical/workorder/${params.workOrderSlug}/t014/major-component-history/${deleteMajorComponent.data.id}/?type=T014&asset_type=${params.type}`)
            .then(response => {
                if (checkApiStatus(response)) {
                    enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' }, });
                    setDeleteMajorComponent({ modal: false, data: null });
                    getFullMajorComponent({}, 'pageLoader');
                }
                setLoading(false);
            })
    }

    const getFullMajorComponent = () => {
        setLoading(true);
        globalGetService(`technical/workorder/${params.workOrderSlug}/t014/major-component-history/?type=T014&asset_type=${params.type}`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setAddEditMajorComponent({ modal: true, majorComponent: response?.data?.data || [] })
                }
                setLoading(false);
            })
    }
    return (
        <>
            <div className="tech-specs-content" >
                <div style={{ padding: '10px' }}>
                    {skeletonLoader ? <STableLoader count={10} /> :
                        <>
                            <Paper square style={{ padding: '4px 8px' }}>
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item md={9}></Grid>
                                    <Grid item md={3}>
                                       {onCheckFormPermission(currentFormDetail) ?
                                        <ul className="list-inline" style={{ float: 'right' }}>
                                            <li className="list-inline-item hover-inline" >
                                                <Tooltip title='Edit' style={{ cursor: 'pointer' }} arrow><EditIcon onClick={getFullMajorComponent} color="primary" fontSize="small" /></Tooltip>
                                            </li>
                                        </ul>:null}
                                    </Grid>
                                </Grid>
                            </Paper>
                            <Paper style={{ maxHeight: window.innerHeight - 180 + 'px', overflow: "auto" }}>
                                <Table className='mui-table-format' stickyHeader>
                                    <MajorComponentHd params={params} />
                                    <TableBody>
                                        {majorComponentInfo.data?.map((item, index) =>
                                            <MajorComponenetList
                                                params={params}
                                                key={index}
                                                item={item}
                                                onEdit={() => setAddEditMajorComponent({ modal: true, mode: 'edit', data: item })}
                                                editField={false}
                                                onDelete={() => setDeleteMajorComponent({ modal: true, data: item })}

                                            />
                                        )}
                                    </TableBody>
                                </Table>
                            </Paper>
                            <Paper>
                            </Paper>
                            <Paper>
                                {!majorComponentInfo?.data?.length ? <div style={{ textAlign: 'center' }}><EmptyCollection title='No records found' /></div> : null}
                            </Paper>
                        </>
                    }

                </div>
            </div>
            {addEditMajorComponent.modal ?
                <AddEditLLP
                    params={params}
                    addEditMajorComponent={addEditMajorComponent}
                    toggleModalFn={() => setAddEditMajorComponent({ modal: false, majorComponents: [] })}
                    getResponseBack={() => getMajorComponent({}, 'pageLoader')}
                /> : null
            }
            {deleteMajorComponent.modal ?
                <DeletePopUp
                    modal={deleteMajorComponent.modal}
                    title='Delete Major Component'
                    content={`Are you sure, you want to Delete?`}
                    toggleModalFn={() => setAddEditMajorComponent({ modal: false, data: null })}
                    deleteRecordFn={onDeleteMajorComponent}
                /> : null
            }
            {isLoading ? <PageLoader /> : null}
        </>

    )
}
export default withRouter(MajorComponents);