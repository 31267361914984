import React, { Fragment } from 'react';
import { Grid, Tooltip, Accordion, AccordionDetails, AccordionSummary, Typography, Button } from '@material-ui/core';
import CKeditor from "../../../../../shared/CKEditor";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import editIcon from '../../../../../shared/assets/img/edit_icon_mui.png';
import { getLocalStorageInfo } from '../../../../../utils';
import commentIcon from '../../../../../shared/assets/img/comment_icon.png';
import closeIcon from '../../../../../shared/assets/img/close_icon.svg';
import attachmentIcon from '../../../../../shared/assets/img/attach_icon.png';
import deleteIcon from '../../../../../shared/assets/img/delete_icon.svg';
import InfiniteScroll from 'react-infinite-scroll-component';
import { trackActivity } from '../../../../../utils/mixpanel';
import { CommentsAndAttachments, OtherInfo, MoreInfoPopper } from '../components';

const IssueDetail = ({ deleteIssue, formInfo, issueDetail, issuesList, operatorFlag, getIssueDetail, userList, moveNextPage, pagination, editIssue, addIssueComment, comments, uploadAttachment, onFieldChange, t011ReportInfo, updateCkeditor, onCloseIssueModal, updatePRSSectionsField, updateIssueComment, deleteIssueComments, tags, issueTags, addTags, removeIssueTags, previewFile, fileIcons, deleteFileApi, onDownloadAttachment, editablePermission, assigneeList }) => {
    const [expanded1, setExpanded1] = React.useState('');
    const [expanded2, setExpanded2] = React.useState('');
    const [expanded3, setExpanded3] = React.useState('');
    const [editable1, setEditable1] = React.useState('');
    const [descriptionPre, setDescriptionPre] = React.useState('');
    const [requirementPre, setRequirementPre] = React.useState('');
    const [backgroundPre, setBackgroundPre] = React.useState('');
    const [editable2, setEditable2] = React.useState('');
    const [editable3, setEditable3] = React.useState('');


    const handleChange = (panel) => (event, isExpanded) => {
        if (panel === 'panel1') {
            setExpanded1(isExpanded ? panel : false);
        }
        if (panel === 'panel2') {
            setExpanded2(isExpanded ? panel : false);
        }
        if (panel === 'panel3') {
            setExpanded3(isExpanded ? panel : false);
        }
    };
    const handleEditable = (panel) => {
        if (panel === 'panel1') {
            setEditable1(panel);
        }
        if (panel === 'panel2') {
            setEditable2(panel);
        }
        if (panel === 'panel3') {
            setEditable3(panel);
        }
    }
    return (
        <section className="issue-detail">
            <Grid container spacing={3}>
                <Grid item xs={12} sm={4} md={3}>
                    <div className="left-block">
                        <h4 className="issue-head">Issue({issuesList.length})</h4>
                        <ul className="list-unstyled list-wrapper">
                            <InfiniteScroll
                                dataLength={issuesList ? issuesList.length : 0} //This is important field to render the next data
                                next={() => moveNextPage()}
                                hasMore={pagination && issuesList.length < pagination.total}
                                height={window.innerHeight - 210}
                                className="scrollbar-hidden inifinity-bottom"
                            >
                                {
                                    issuesList.map((item, index) =>
                                        <li className={issueDetail.id === item.id ? "active" : ""} onClick={() => {
                                            getIssueDetail(item); trackActivity('T011', {
                                                event_type: 'Row Clicked',
                                                page_title: 'Issue Detail',
                                                item_type: 'Issue List Sidebar row',
                                                issue_unique_code: item.unique_code ? item.unique_code : ''
                                            })
                                        }}>
                                            <div className="list-wrapper-detail">
                                                {
                                                    item.status && item.status.value === 0 ?
                                                        <span className="issue-no open-issue">{item.unique_code} </span> : null
                                                }
                                                {
                                                    item.status && item.status.value === 1 ?
                                                        <span className="issue-no closed-issue">{item.unique_code} </span> : null
                                                }
                                                {
                                                    item.status && item.status.value === 2 ?
                                                        <span className="issue-no reopen-issue">{item.unique_code} </span> : null
                                                }
                                                {
                                                    item.title ? <span className="issue-desc">{item.title} </span> : <span className="issue-desc" dangerouslySetInnerHTML={{ __html: item.description.substring(0, 60) }}></span>
                                                }
                                                <p className="assignee">{item.assignee ? item.assignee.name : '--'}</p>
                                            </div>
                                        </li>
                                    )
                                }
                            </InfiniteScroll>
                        </ul>
                    </div>
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                    <div className="right-block">
                        <Grid container alignItems="center" spacing={3}>
                            <Grid item xs={12}>
                                <div className="issue-head-blk">
                                    <div className="issue-desc-head">
                                        <input
                                            type="text"
                                            placeholder="Enter Issue Title"
                                            id="title"
                                            disabled={!editablePermission || !(formInfo.current_form && formInfo.current_form.status && ![0, 4, 5, 6, 7].includes(formInfo.current_form.status.id))}
                                            style={{ width: '95%' }}
                                            value={issueDetail.title}
                                            style={issueDetail.title === '' || issueDetail.title === null || issueDetail.title.trim() === '' ? { border: '1px solid red' } : {}}
                                            onChange={(e) => onFieldChange(e, 'title', e.target.value)}
                                            onBlur={(e) => {
                                                if (issueDetail.title === '' || issueDetail.title === null || issueDetail.title.trim() === '') {
                                                    return
                                                }
                                                updatePRSSectionsField('title', e.target.value)
                                            }}
                                        />
                                        {
                                            issueDetail.title === '' || issueDetail.title === null || issueDetail.title.trim() === '' ?
                                                <h6 className="error-msg">Please enter title</h6>
                                                : null
                                        }
                                        {
                                            editablePermission && !(formInfo.current_form && formInfo.current_form.status && [0, 4, 5, 6, 7].includes(formInfo.current_form.status.id)) ?
                                                <img src={deleteIcon} alt='Delete Icon'
                                                    onClick={() => deleteIssue()}
                                                    className="delete-icon"
                                                />
                                                : null
                                        }
                                        <img onClick={() => { onCloseIssueModal(); trackActivity('T011', { page_title: 'Issue Detail', event_type: 'Click', event_desc: 'Clicked on Close Icon' }) }} className="close-icon" src={closeIcon} alt="Close" />
                                    </div>
                                    <ul className="list-inline">
                                        <li className="list-inline-item">
                                            <p className="added-by">By <span>{issueDetail.created_by !== undefined && issueDetail.created_by !== null && issueDetail.created_by.name ? issueDetail.created_by.name : issueDetail.updated_by !== undefined && issueDetail.updated_by !== null && issueDetail.updated_by.name ? issueDetail.updated_by.name : '--'}</span></p>
                                        </li>
                                        <li className="list-inline-item">
                                            <span className="right-border"></span>
                                            <Tooltip title={`Comment(s) (${comments.length})`} arrow>
                                                <img src={commentIcon} width="13" alt="Icon" onClick={() => trackActivity('T011', { page_title: 'Issue Detail', event_type: 'Click', event_desc: 'Clicked on Comment Count Icon' })} />
                                            </Tooltip>
                                            <span className="attachment-count">({comments.length})</span>
                                        </li>
                                        <li className="list-inline-item">
                                            <span className="right-border"></span>
                                            <Tooltip title={`Attachment(s) (${issueDetail.files.length})`} arrow>
                                                <img src={attachmentIcon} width="15" alt="Icon" onClick={() => trackActivity('T011', { page_title: 'Issue Detail', event_type: 'Click', event_desc: 'Clicked on Attachment Count Icon' })} />
                                            </Tooltip>
                                            <span className="attachment-count">({issueDetail.files.length})</span>
                                        </li>
                                        <li className="list-inline-item">
                                            <span className="right-border"></span>
                                            <MoreInfoPopper issueDetail={issueDetail} />
                                        </li>
                                    </ul>
                                </div>
                            </Grid>

                            <Grid item xs={12} md={12} className="content-blk">
                                <Accordion expanded={expanded1 === 'panel1'} onChange={handleChange('panel1')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>
                                            Description
                                            {editablePermission && !operatorFlag && expanded1 && !(formInfo.current_form && formInfo.current_form.status && [0, 4, 5, 6, 7].includes(formInfo.current_form.status.id)) ?
                                                <img className="edit-icon" width={13} src={editIcon} alt="icon" onClick={(e) => { e.preventDefault(); e.stopPropagation(); handleEditable('panel1'); setDescriptionPre(issueDetail.description) }} /> :
                                                null
                                            }
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {editable1 && !operatorFlag ?
                                            <Fragment>
                                                <div style={{ width: '100%' }}>
                                                    <div style={issueDetail.description === '' || issueDetail.description === null || issueDetail.description.trim() === '' ? { width: '100%', border: '1px solid red' } : { width: '100%' }}>
                                                        <CKeditor
                                                            className="ck-editor"
                                                            events={{ "change": (e) => updateCkeditor(e, 'description') }}
                                                            content={issueDetail.description ? issueDetail.description : ''}
                                                        />
                                                    </div>
                                                    {
                                                        issueDetail.description === '' || issueDetail.description === null || issueDetail.description.trim() === '' ?
                                                            <h6 className="error-msg">Please enter description</h6>
                                                            : null
                                                    }
                                                    <div>
                                                        <ul className='list-inline' style={{ marginTop: '10px' }}>
                                                            <li className='list-inline-item'><Button color="primary" variant="contained" size="small" onClick={() => {
                                                                if (issueDetail.description === null || issueDetail.description === '' || issueDetail.description.trim() === '') {
                                                                    return
                                                                }
                                                                updatePRSSectionsField('description', issueDetail.description)
                                                                setDescriptionPre(issueDetail.description)
                                                                setTimeout(() => setEditable1(''), 1000)
                                                            }}
                                                                disabled={issueDetail.description === null || issueDetail.description === '' || issueDetail.description.trim() === ''}
                                                            >Submit</Button></li>
                                                            <li className='list-inline-item'><Button color="primary" variant="outlined" size="small" onClick={() => {
                                                                setEditable1('')
                                                                setDescriptionPre('')
                                                                onFieldChange(null, 'description', descriptionPre)
                                                            }}>Cancel</Button></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </Fragment>
                                            : <p className="desc-detail" dangerouslySetInnerHTML={{ __html: issueDetail.description }}></p>
                                        }
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded2 === 'panel2'} onChange={handleChange('panel2')}>
                                    <AccordionSummary
                                        defaultExpanded={true}
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel3a-content"
                                        id="panel3a-header"
                                    >
                                        <Typography>Requirement Discrepancy
                                            {editablePermission && !operatorFlag && expanded2 && !(formInfo.current_form && formInfo.current_form.status && [0, 4, 5, 6, 7].includes(formInfo.current_form.status.id)) ?
                                                <img className="edit-icon" width={13} src={editIcon} alt="icon" onClick={(e) => { e.preventDefault(); e.stopPropagation(); handleEditable('panel2') }} /> : null}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {editable2 && !operatorFlag ?
                                            <Fragment>
                                                <div style={{ width: '100%' }}>
                                                    <div style={{ width: '100%' }}>
                                                        <CKeditor
                                                            className="ck-editor"
                                                            events={{ "change": (e) => updateCkeditor(e, 'requirement_remarks') }}
                                                            content={issueDetail.requirement_remarks ? issueDetail.requirement_remarks : ''}
                                                            style={{ width: '100%' }}
                                                        />
                                                    </div>
                                                    <div>
                                                        <ul className='list-inline' style={{ marginTop: '10px' }}>
                                                            <li className='list-inline-item'><Button color="primary" variant="contained" size="small" onClick={() => {
                                                                updatePRSSectionsField('requirement_remarks', issueDetail.requirement_remarks)
                                                                setRequirementPre(issueDetail.requirement_remarks)
                                                                setTimeout(() => setEditable2(''), 1000)
                                                            }}>Submit</Button></li>
                                                            <li className='list-inline-item'><Button color="primary" variant="outlined" size="small" onClick={() => {
                                                                setEditable2('')
                                                                setRequirementPre('')
                                                                onFieldChange(null, 'requirement_remarks', requirementPre)
                                                            }}>Cancel</Button></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </Fragment>
                                            :
                                            <p className="desc-detail" dangerouslySetInnerHTML={{ __html: issueDetail.requirement_remarks }}></p>
                                        }
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded3 === 'panel3'} onChange={handleChange('panel3')}>
                                    <AccordionSummary
                                        defaultExpanded={true}
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel3a-content"
                                        id="panel3a-header"
                                    >
                                        <Typography>Background Discrepancy
                                            {editablePermission && !operatorFlag && expanded3 && !(formInfo.current_form && formInfo.current_form.status && [0, 4, 5, 6, 7].includes(formInfo.current_form.status.id)) ?
                                                <img className="edit-icon" width={13} src={editIcon} alt="icon" onClick={(e) => { e.preventDefault(); e.stopPropagation(); handleEditable('panel3') }} /> : null}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {editable3 && !operatorFlag ?
                                            <Fragment>
                                                <div style={{ width: '100%' }}>
                                                    <div style={{ width: '100%' }}>
                                                        <CKeditor
                                                            className="ck-editor"
                                                            events={{ "change": (e) => updateCkeditor(e, 'background_remarks') }}
                                                            content={issueDetail.background_remarks ? issueDetail.background_remarks : ''}
                                                            style={{ width: '100%' }}
                                                        />
                                                    </div>
                                                    <div>
                                                        <ul className='list-inline' style={{ marginTop: '10px' }}>
                                                            <li className='list-inline-item'><Button color="primary" variant="contained" size="small" onClick={() => {
                                                                updatePRSSectionsField('background_remarks', issueDetail.background_remarks)
                                                                setBackgroundPre(issueDetail.background_remarks)
                                                                setTimeout(() => setEditable3(''), 1000)
                                                            }}>Submit</Button></li>
                                                            <li className='list-inline-item'><Button color="primary" variant="outlined" size="small" onClick={() => {
                                                                setEditable3('')
                                                                setBackgroundPre('')
                                                                onFieldChange(null, 'background_remarks', backgroundPre)
                                                            }}>Cancel</Button></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </Fragment>
                                            :
                                            <p className="desc-detail" dangerouslySetInnerHTML={{ __html: issueDetail.background_remarks }}></p>
                                        }
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion defaultExpanded={true}>
                                    <AccordionSummary
                                        defaultExpanded={true}
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        <Typography>Other Information</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <OtherInfo
                                            issueDetail={issueDetail}
                                            editable={editablePermission && !(formInfo.current_form && formInfo.current_form.status && [0, 4, 5, 6, 7].includes(formInfo.current_form.status.id))}
                                            userList={userList}
                                            onFieldChange={onFieldChange}
                                            t011ReportInfo={t011ReportInfo}
                                            removeIssueTags={(tagid) => removeIssueTags(tagid)}
                                            updatePRSSectionsField={(key, data) => updatePRSSectionsField(key, data)}
                                            tags={tags}
                                            issueTags={issueTags}
                                            addTags={addTags}
                                            assigneeList={assigneeList}
                                        />
                                    </AccordionDetails>
                                </Accordion>
                                <CommentsAndAttachments
                                    formInfo= {formInfo}
                                    fileIcons={fileIcons}
                                    operatorFlag={operatorFlag}
                                    editable={formInfo.current_form && formInfo.current_form.status && ![0, 4, 5, 6, 7].includes(formInfo.current_form.status.id)}
                                    editableheck={editablePermission && !(formInfo.current_form && formInfo.current_form.status && [0, 4, 5, 6, 7].includes(formInfo.current_form.status.id))}
                                    previewFile={(file) => previewFile(file)}
                                    deleteIssueComments={(item_id, commentId) => deleteIssueComments(item_id, commentId)}
                                    updateIssueComment={(item_id, comment, commentId) => updateIssueComment(item_id, comment, commentId)}
                                    uploadAttachment={(files) => uploadAttachment(files, issueDetail.id)}
                                    issueDetail={issueDetail} comments={comments}
                                    addIssueComment={(item_id, comment) => addIssueComment(item_id, comment)}
                                    deleteFileApi={(deleteFileId) => deleteFileApi(deleteFileId)}
                                    onDownloadAttachment={(fileId) => onDownloadAttachment(fileId)}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        </section>
    )
}
export default IssueDetail;
