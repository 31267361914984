import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';

import { EditSidebar } from '../../applications/technicalInspection/Elements_V';
import { S_AUDITLOG_ADD_DEL_VIEW, TOGGLE_VIEW_EDIT } from '../actions';
import  UpdateAuditLog  from '../components/UpdateAuditLog';
import AddDeleteAudit from '../components/AddDeleteAudit';
import { Row, Col, Input, FormGroup} from 'reactstrap';

import '../assets/styles/auditLog.scss';
class AuditLog extends Component{
  constructor(props){
    super(props);
  }
  render(){
    const { auditLogView } = this.props;

      return(
        <div className="portfolio-block">
          <h4>Portfolio</h4>
        <div className="asset-list-filter-sort">
          <div className="filter-block row">
           <div className="col-md-4">
             <h6>Portfolio Name</h6>
           </div>
           <div className="col-md-2"><h6>
            <span>No. of Assets<sup className="asc-sort" style={{color: 'rgb(223, 223, 223)'}}> ▲ </sup>
            <sub className="desc-sort" style={{color: 'rgb(223, 223, 223)' }}>▼</sub></span>
            </h6>
          </div>
          <div className="col-md-2">
          <h6><span>Description
            <sup className="asc-sort" style={{color: 'rgb(223, 223, 223)' }}> ▲ </sup>
            <sub className="desc-sort" style={{color: 'rgb(223, 223, 223)' }}>▼</sub>
          </span>
          </h6>
          </div>
          <div className="col-md-2">
            <h6>
              <span>Total inflow<sup className="asc-sort" style={{color: 'rgb(223, 223, 223)' }}> ▲ </sup>
              <sub className="desc-sort" style={{color: 'rgb(223, 223, 223)' }}>▼</sub></span>
            </h6>
          </div>
          <div className="col-md-2">
            <h6>
              <span>Total Outflow<sup className="asc-sort" style={{color: 'rgb(223, 223, 223)' }}> ▲ </sup>
              <sub className="desc-sort" style={{color: 'rgb(223, 223, 223)' }}>▼</sub>
              </span>
            </h6>
            </div>
            </div>
          </div>
          <div className="asset-list">
              <Row className="flex-centered">
                  <Col md="4">
                    <div className="flex-centered msn-block">
                        <Link >
                          <div>
                              <h4>Thunderbolt</h4>
                          </div>
                        </Link>
                    </div>
                  </Col>
                  <Col md="2">
                    <h6 className="theta">
                      255
                    </h6>
                  </Col>
                  <Col md="2">
                    <h6 className="theta">
                     this is planned
                    </h6>
                  </Col>
                  <Col md="2">
                    <h6 className="theta">
                255
                </h6>
                  </Col>
              </Row>
          </div>
  
          <EditSidebar
            title='Audit Log View'
              editSideBar={auditLogView.flag}
              toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false})}
            >
             <div>
              <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'20px',  padding: '5px 22px 5px 25px' }}>
                  <UpdateAuditLog/>
                  <AddDeleteAudit />
              </div>
            </div>
          </EditSidebar>
          </div>
      )
    }
   
  
}

const mapStateToProps = state => ({
  auditLogView: state.sharedReducers.auditLogView
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    auditLogViewFn: () => dispatch({
      type: S_AUDITLOG_ADD_DEL_VIEW,
      paylaod: ''
    }),
    toggleEditBar: (data) => dispatch({
      type: TOGGLE_VIEW_EDIT,
      payload: data
    }),

  }
}
export default connect(mapStateToProps,mapDispatchToProps)(AuditLog);
