import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Paper, Tooltip, Button } from '@material-ui/core';
import { browserHistory } from 'react-router'
import { ListLoader, TableListComp, FilterUIComp, ExportManu, EmptyCollection } from '../../../shared';
import { STableLoader } from '../../../shared/loaders';
import { removeEmptyKey, convertFilterObject } from '../../../utils';
import { ResourcesAnalyticList, ResourcesChart } from '../components';
import { getAnalyticResourceApi, getAnalyticProjectsGrpApi, exportResourceTrackerApi } from '../apiServices';
import { sGlobalConstantAc } from '../../../shared/actionCreators';
import { resourceFilterOptions, resourceAnalyticHd, countryCodes } from '../'
import { imgStoragePath } from '../../../constants';
import { trackActivity } from '../../../utils/mixpanel'
import moment from 'moment'
import CountUp from 'react-countup';
import RefreshIcon from '@material-ui/icons/Refresh';
import MapIcon from '@material-ui/icons/Map';
import CloseIcon from '@material-ui/icons/Close';
import DeploymentMessage from '../../../shared/components/DeploymentMessage';

class ResourcesAnalyticTracker extends Component {
  constructor(props){
    super(props);
    this.state = {
      pageLoader:false,
      skeletonLoader:true,
      country_code:'',
      tabIndex:0,
      filter: {},
      applyingFilter:{},
      sort:'',
      sort_by:'',
      resourceDetail:{
        general_info:{},
        plots:[],
        user_list:[]
      },
    }
    this.getAnalyticResourceApi = getAnalyticResourceApi.bind(this);
    this.getAnalyticProjectsGrpApi = getAnalyticProjectsGrpApi.bind(this)
    this.exportResourceTrackerApi = exportResourceTrackerApi.bind(this)
  }
  componentDidMount(){
    this.getAnalyticResourceApi(this.props, {}, 'skeletonLoader');
    this.props.getPlatformConstants()
    trackActivity('Page Visited',{
      application_module: 'Projects',
      page_title: 'Analytics'
    })
  }

  applyFilter = (keyParam, value, rangeKey) => {
    if(rangeKey){
      this.setState(prevState => ({
        ...prevState,
        applyingFilter: {
          ...prevState.applyingFilter,
          [keyParam]: {
            ...prevState.applyingFilter[keyParam],
            [rangeKey]:value
          }
        }
      }))
    }else{
      this.setState(prevState => ({
        ...prevState,
        applyingFilter: {
          ...prevState.applyingFilter,
          [keyParam]: value
        }
      }))
    }
  }
  removeFilter = (keyParam) => {
    const { filter, applyingFilter } = this.state;
    let query = Object.assign({}, filter);
    delete query[keyParam];
    this.getAnalyticResourceApi(this.props, {...query}, 'pageLoader');
    this.setState({applyingFilter: delete applyingFilter[keyParam]})
  }
  createSortHandler = (sortField) => {
    const { sort, sort_by, filter } = this.state;
    this.setState({
      sort:sortField,
      sort_by: (sortField === sort ? sort_by === 'asc' ? 'desc':'asc' :'asc')
    });
    if(sortField === sort){
      if(sort_by === 'asc'){
        this.getAnalyticResourceApi(this.props, {...filter, sort: sortField, sort_by: 'desc', list:true }, 'pageLoader');
      }else{
        this.getAnalyticResourceApi(this.props, {...filter, sort: sortField, sort_by: 'asc', list:true }, 'pageLoader');
      }
    }else{
      this.getAnalyticResourceApi(this.props, {...filter, sort: sortField, sort_by: 'asc', list:true}, 'pageLoader');
    }
  }
  submitFilter = (filterOptions) => {
    const { applyingFilter } = this.state
    this.getAnalyticResourceApi(this.props, {...convertFilterObject(removeEmptyKey(applyingFilter), filterOptions), list:true}, 'pageLoader')
    this.getAnalyticResourceApi(this.props, {...convertFilterObject(removeEmptyKey(applyingFilter), filterOptions), list:false}, 'pageLoader')
    this.setState({country_code:''})
  }

  exportReportFn = (file) => {
    this.exportResourceTrackerApi(file)
  }
  onClickCountry = (country_code) => {
    const {filter} = this.state 
    this.getAnalyticResourceApi(this.props, {...filter, country_code:country_code}, 'pageLoader')
    this.setState({country_code: country_code})
  }
  getCountryNames = () => {
    const { country_code } = this.state
    for (const key in countryCodes) {
      if (countryCodes.hasOwnProperty(key)) {
          if(key == country_code){
            return countryCodes[key]
          }
      }
    }
  }
  removeAllFilter = () => {
    this.getAnalyticResourceApi(this.props, {list:true}, 'pageLoader')
    this.setState({country_code:''})
  }
  handleTabChange = (value, tab) => {
    trackActivity('Tab Clicked',{page_title: 'Analytics',tab_name: tab})
    browserHistory.push(`/analytics/${value}`)
  }
  handleToggleView = (tab, viewMode ) => {
    trackActivity('Item Clicked', {
      page_title:'Analytics',
      tab_name:'Resources tracker',
      viewmode: viewMode
    })
    this.setState({tabIndex:tab })
  }
  render(){
    const { filter, applyingFilter, resourceDetail, skeletonLoader,  pageLoader, tabIndex, country_code } = this.state;
    const { pltConstants } = this.props;
    let filterOptions = resourceFilterOptions;
    filterOptions = {
      ...filterOptions,
      category: {
        ...filterOptions.category,
        options: pltConstants.filter(item => item.type === 'user_category')
      },
      department: {
        ...filterOptions.department,
        options: pltConstants.filter(item => item.type === 'project_departments')
      },
      startDate:{
        ...filterOptions.startDate,
        maxDate:moment()
      },
      endDate:{
        ...filterOptions.endDate,
        minDate:applyingFilter.startDate ? moment(applyingFilter.startDate) : '',
      }
    }
    return(
      <section className="resources-analytic-tracker">
        <div className="analytic-head">
          <DeploymentMessage />
          <h2>Projects Analytics</h2>
          <p> The analytics presented below provide detailed information on Acumen's Projects and its Manpower Resources globally.</p>
          <ul className="list-inline">
            <li className="list-inline-item active" onClick={()=> this.handleTabChange('resources', 'Resource Tracker')}>Resources Tracker</li>
            <li className="list-inline-item" onClick={()=>this.handleTabChange('projects', 'Project Tracker')}>Projects Tracker</li>
          </ul>
        </div>
        <div className="analytic-blk">
          <div className="flex-centered">
            <div>
              <ul className="list-inline">
                {
                  resourceDetail && resourceDetail.general_info && resourceDetail.general_info.available ?
                  <li className="list-inline-item">
                    <Paper className="available">
                      <h4 data-target={resourceDetail.general_info.available}><CountUp end={resourceDetail.general_info.available} start={0} duration={resourceDetail.general_info.available < 100 ? 0.75 : 2}  /></h4>
                      <p>Available</p>
                    </Paper>
                  </li> :
                  <li className="list-inline-item">
                    <Paper className="available">
                      <h4 >0</h4>
                      <p>Available</p>
                    </Paper>
                  </li>

                }
                {
                  resourceDetail && resourceDetail.general_info && resourceDetail.general_info.deployed ?
                  <li className="list-inline-item">
                    <Paper className="deployed">
                      <h4 data-target={resourceDetail.general_info.deployed}><CountUp end={resourceDetail.general_info.deployed} start={0} duration={resourceDetail.general_info.deployed < 100 ? 0.75 : 2}  /></h4>
                      <p>Deployed</p>
                    </Paper>
                  </li>
                  :
                  <li className="list-inline-item">
                    <Paper className="deployed">
                      <h4>0</h4>
                      <p>Deployed</p>
                    </Paper>
                  </li>
                }
              </ul>
              { country_code && tabIndex == 0 ? 
                <span style={{fontSize:'12px', background:'#fff3cd', color:'#856404', border:'1px solid #ffeeba' , padding:'3px',marginBottom:'5px'}} >
                  Results based on Location : {this.getCountryNames()} <CloseIcon  style={{position:'relative', top:'3px', fontSize:'16px', cursor:'pointer'}} onClick={() => { this.getAnalyticResourceApi(this.props, {list:true}, 'pageLoader');this.setState({country_code:''})  }}  /> 
                </span> 
                : null
              }
            </div>
            <div style={{marginLeft:'auto'}}>
              <ul className="list-inline flex-centered">
                <li className="list-inline-item toggle-chart">
                  <ul className="list-inline">
                    <li className={`list-inline-item ${tabIndex == 0 ? 'active' : 'inactive-bg'}`} onClick={() => this.handleToggleView(0, 'List View')}>
                      <img src={tabIndex == 0 ? imgStoragePath + 'regular_list.svg' : imgStoragePath + 'regular_list_inactive.svg'} alt="Icon" />
                    </li>
                    <li className={`list-inline-item ${tabIndex == 1 ? 'active' : 'inactive-bg'}`} onClick={() => this.handleToggleView(1, 'Map View')}>
                      <MapIcon  fontSize="small"  style={{position:'relative', top:'5px', fontSize:'1.14rem'}}/>
                     </li>
                  </ul>
                </li>
                <li className="lsit-inline-item">
                  <ul className="list-inline flex-centered">
                    <li className="list-inline-item">
                      <Tooltip title="Click to restore default map view" arrow>
                        <Button onClick={() => { this.getAnalyticResourceApi(this.props, {list:true}, 'pageLoader'); this.getAnalyticProjectsGrpApi(this.props, {list:false}, 'pageLoader');}} color="primary" variant="contained" >
                          <RefreshIcon />
                        </Button>
                      </Tooltip>
                    </li>
                    <li className="list-inline-item">
                      <span style={{float: 'right', padding: '5px 0'}}>
                        <ExportManu title="Export" files={[{title:'Excel', extension: 'xls', key:''}]} exportReportFn={(file) => this.exportReportFn(file) }   />
                      </span>
                    </li>
                  </ul>
                </li>
              </ul>  
            </div>
          </div>
          <div>
            <FilterUIComp
               filter={filter}
               applyingFilter={applyingFilter}
               removeFilter={this.removeFilter}
               removeAllFilter={() => this.removeAllFilter()}
               submitFilter={() => this.getAnalyticResourceApi(this.props, {...convertFilterObject(removeEmptyKey(applyingFilter), filterOptions), list:true}, 'pageLoader')}
               submitFilter={() => this.submitFilter(filterOptions)}
               applyFilter={this.applyFilter}
               filterMenu={filterOptions}
               frontDateFormat={true}
               backDateFormat={true}
             />
            { tabIndex == 1 ?  resourceDetail.plots.length ?
              <ResourcesChart
                plots={resourceDetail.plots.map(plotItem => {return{...plotItem, value:plotItem.count}})}
                onClickCountry={(country_code) =>  this.onClickCountry(country_code)}
              />:null
              : 
              <div>
                { skeletonLoader ? <STableLoader count={10} />:
                  <Fragment>
                    <TableListComp
                      heads={resourceAnalyticHd}
                      data={ resourceDetail.user_list.map((item,index) =>
                        <ResourcesAnalyticList
                          key={index}
                          item={item}
                          index={index+1}
                        />
                      )}
                      noRecord={ resourceDetail.user_list.length ? null:
                        <EmptyCollection
                          title="No records found"
                        />
                      }
                      createSortHandler={this.createSortHandler}
                    />
                  </Fragment>
                }
            </div>
            }
           
            { pageLoader ? <ListLoader />:null }
          </div>
        </div>
      </section>
    )
  }
}
const mapStateToProps = state => ({
  pltConstants: state.sharedReducers.techConstants,
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getPlatformConstants: () => dispatch(sGlobalConstantAc({constant_types:['project_departments', 'user_category']}))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ResourcesAnalyticTracker)
