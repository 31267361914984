import React from 'react';
import {Paper} from '@material-ui/core';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/offline-exporting')(Highcharts);
const  CategoryWiseChart = ({categories}) => {
    let list = categories.list.filter(item => item.total_item > 0);
    const open = list.map(item => item.open_item);
    const reopen = list.map(item => item.reopen_item);
    const closed = list.map(item => item.close_item);
    const unresolved = list.map(item => item.unresolved_item);
    const cat = list.map(item => item.name);
    const chartOptions = {
        chart: {
            type: 'column',
            height: 500,
            options3d: {
                enabled: true,
                alpha: 10,
                beta: 10,
                viewDistance: 55,
                depth: 50
            }
        },
        title: {text: null},
        xAxis: {
            categories: cat
        },
        yAxis: {
            allowDecimals: false,
            title: {text: ''},
            stackLabels: {
                enabled: true,
                style: {
                    fontWeight: 'bold',
                    color: ( // theme
                        Highcharts.defaultOptions.title.style &&
                        Highcharts.defaultOptions.title.style.color
                    ) || 'gray',
                    textOutline: 'none'
                }
            }
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                depth: 40,
                dataLabels: {
                    enabled: true
                }
            },
            series: {
              cursor: 'pointer',
              point: {
                events: {
                  click: (e) => {
                    this.graphToTabMove(e.point.category)
                  }
                }
              }
            }
        },
        series: [
            {
                name: 'Open',
                color: '#4b82de',
                data: open
            }, 
            {
                name: 'Reopened',
                color: '#e86642',
                data: reopen
            }, 
            {
                name: 'Closed',
                color: '#269b2b',
                data: closed
            },
            {
                name: 'Unresolved',
                color: '#dbcc28',
                data: unresolved
            }
        ],
        exporting:{
            fallbackToExportServer: false
        },
        credits: {
            enabled: false
        }
    }
    return(
        <Paper>
            <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions}
            />
        </Paper>
    )
}
export default CategoryWiseChart;