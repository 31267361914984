import React, { Fragment, useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { browserHistory } from 'react-router'
import { Button, Grid, Paper } from '@material-ui/core';
import { TableListComp, FilterUIComp, DeletePopUp, EmptyCollection, ExportManu, PageLoader } from '../../../shared_elements';
import { STableLoader } from '../../../shared_elements/loaders';
import { List, RobberyCRU } from '../components';
import { checkApiStatus, downloadFileType, getLocalStorageInfo ,removeEmptyKey} from '../../../utils_v2';
import { globalGetService, globalDeleteService, globalPostService } from '../../../utils_v2/globalApiServices';
import { robberyFilterOps, robberyHd } from '../';
import { FormWrapper, FormSkeletonLoader } from '../../Elements';
import { globalStaticExportService } from '../../../globalServices';
import FilterComponent from '../../../shared_elements/filter_component';
import { trackActivity } from '../../../utils/mixpanel';
import RestrictedAccess from '../../../shared_elements/components/RestrictedAccess';
import InvalidUrl from '../../../shared_elements/components/InvalidUrl';
const Robberies = ({params,location}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [initialLoader, setInitialLoader] = useState(false);
    const [formLoader, setFormLoader] = useState(true);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [currentFormDetail, setCurrentFormDetail] = useState(null);
    const [robberies, setRobberies] = useState({list:[], pagination:{}});
    const [deleteRobberies, setDeleteRobberies] = useState({modal:false, robberies:[]});
    const [addEditRobbery, setAddEditRobbery] = useState({modal:false, mode:'', robbery:null});
    const [sort, setSort] = useState("");
    const [sort_by, setSortBy] = useState("");
    const [filter, setFilter] = useState({});
    useEffect(() => {
        getCurrentFormDetail('formLoader','initial');
        getRobberies({}, 'skeletonLoader');
        trackActivity('Project Management', {
            event_type: 'Page Visited',
            page_title: 'T009 Form'
          });
    }, []);
    const getCurrentFormDetail = (loaderType, initialLoad) => {
        if(initialLoad){setInitialLoader(true)}
        if(loaderType){setFormLoader(true)}
        globalGetService(`technical/workorder/${params.workOrderSlug}/forms/`,{form:params.formSlug})
        .then(response => {
            if(initialLoad){setInitialLoader(false)}
            if(loaderType){setFormLoader(false)}
            if(checkApiStatus(response)){
                setCurrentFormDetail(response.data.data)
            }
        })
    }
    const onChangeFormStatus = (data) => {
        setLoading(true)
        globalPostService(`technical/workorder/${params.workOrderSlug}/form/${params.formSlug}/status-update/`,data)
        .then(response => {
            setLoading(false)
            if(checkApiStatus(response)){
                getCurrentFormDetail('formLoader');
                enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
                trackActivity('Project Management', {
                    event_type: 'Form Status Updated',
                    page_title: 'T009 Form',
                    form_slug: params.formSlug ? params.formSlug : '',
                    workOrder_slug: params.workOrderSlug ? params.workOrderSlug : '',
                    status: data.status ? data.status: '',
                    request_body: data ? data : '',
                    success_msg: response.data.message
                });
            }else{
                getCurrentFormDetail('formLoader');
                enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
                trackActivity('Project Management', {
                    event_type: 'Form Status Update Failed',
                    page_title: 'T009 Form',
                    form_slug: params.formSlug ? params.formSlug : '',
                    workOrder_slug: params.workOrderSlug ? params.workOrderSlug : '',
                    status: data.status ? data.status: '',
                    request_body: data ? data : '',
                    error_source: 'Backend',
                    error_msg: response.data.message
                });
            }
        });
    }
    const getRobberies = (query, loaderType) => {
        if(loaderType === 'skeletonLoader'){setSkeletonLoader(true)}else{setLoading(true)}
        globalGetService(`technical/workorder/${params.workOrderSlug}/robbery-list/`, query)
        .then(response => {
            if(loaderType === 'skeletonLoader'){setSkeletonLoader(false)}else{setLoading(false)}
            if(checkApiStatus(response)){
                delete query.sort;
                delete query.sort_by;
                delete query.page;
                delete query.per_page;
                setRobberies(response.data.data);
                setFilter(query)
            }
        })
    }
    const onDeleteRobberies = () => {
        setLoading(true);
        globalDeleteService(`technical/workorder/${params.workOrderSlug}/robbery-list/`, {ids:deleteRobberies.robberies})
        .then(response => {
            setLoading(false);
            if(checkApiStatus(response)){
                enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
                setDeleteRobberies({modal:false, robberies:[]})
                getRobberies({page:1, per_page:robberies.pagination.per_page, ...filter}, 'pageLoader');
                trackActivity('Project Management', {
                    event_type: 'Robbery Deleted',
                    page_title: 'T009 Form',
                    workOrder_slug: params.workOrderSlug ? params.workOrderSlug : '',
                    ids: deleteRobberies.contacts ? deleteRobberies.contacts : '',
                    success_msg: response.data.message
                });
            }else{
                enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
                trackActivity('Project Management', {
                    event_type: 'Robbery Delete Failed',
                    page_title: 'T009 Form',
                    workOrder_slug: params.workOrderSlug ? params.workOrderSlug : '',
                    ids: deleteRobberies.contacts ? deleteRobberies.contacts : '',
                    error_source: 'Backend',
                    error_msg: response.data.message
                });
            }
        })
    }
    const exportFormReport = (query={},file) => {
        enqueueSnackbar('T009 Report Downloading ...', { variant: 'info',anchorOrigin:{horizontal:'right', vertical:'bottom'}})
        globalStaticExportService(`technical/workorder/${params.workOrderSlug}/robbery-list/`, {...query,download:file.extension})
        .then(response => {
            downloadFileType(response, (`T009_${getLocalStorageInfo().defaultLessor.name}_${moment().format('YYYY-MM-DD')}.`), file.extension);
            closeSnackbar();
            trackActivity('Project Management', {
                event_type: 'Report Exported',
                page_title: 'T009 Form',
                workorder_slug: params.workOrderSlug? params.workOrderSlug : '',
                file_name: `T009_${getLocalStorageInfo().defaultLessor.name}_${moment().format('YYYY-MM-DD')}.`,
                file_extension: file.extension,
                success_msg: 'T009 Report Downloaded'
            })
        });
    }
    const toggleBulkOps = (flag) => {
        if(flag){
            setDeleteRobberies({modal:false, robberies:robberies.list.map(item=>item.id)})
        }else{
            setDeleteRobberies({modal:false, robberies:[]})
        }
    }
    const onSelectRow = (flag, id) => {
        if(flag){
            setDeleteRobberies({...deleteRobberies, robberies:[...deleteRobberies.robberies,id]})
        }else{
            setDeleteRobberies({...deleteRobberies, robberies:deleteRobberies.robberies.filter(item => item !==id)})
        }
    }
    const createSortHandler = (sortField) => {
        let sort_order = 'asc';
        if(sortField === sort){
            sort_order = sort_by === 'asc' ? 'desc':'asc'
        }
        getRobberies({page:1, per_page: robberies.pagination.per_page, sort_by:sortField, sort:sort_order},'pageLoader')
        setSort(sortField);
        setSortBy(sortField === sort ? sort_by === 'asc' ? 'desc':'asc' :'asc')
    }
    let formViewPermission = currentFormDetail?.list?.length && currentFormDetail?.list?.find(form => form.slug == currentFormDetail?.current_form?.slug)
    let formType = window.location?.pathname?.toUpperCase()?.includes(currentFormDetail?.current_form?.name)

    return(
        <section className='project-forms-section'>
            { currentFormDetail && Object.keys(currentFormDetail).length && formViewPermission && formType?
                <FormWrapper 
                    formInfo={currentFormDetail} 
                    exportReportBtn={<ExportManu disabled={robberies?.list?.length ? false: true} exportReportFn={(file) => exportFormReport({download:file.extension, ...removeEmptyKey(filter)}, file)} title="Export Report" files={[{title:'PDF', extension: 'pdf', key:''},{title:'Doc', extension: 'docx', key:''}]} />}
                    updateFormStatusFn={(data) => onChangeFormStatus(data)}
                />:formLoader ? <FormSkeletonLoader />:null
            }
            <div className='contacts'>
            { skeletonLoader || initialLoader ? (<STableLoader count={6} />) :
            formType?
            formViewPermission ?
                (<Fragment>
                    <Paper square className="plt-filter-comp">
                        <Grid container spacing={1} alignItems='center'>
                            <Grid item md={9}>
                               <FilterComponent 
                                    filter={filter}
                                    filterMenu={robberyFilterOps}
                                    getResponseBack={(applyFilter) => {getRobberies({...applyFilter, page:1, per_page:robberies.pagination.per_page}, 'pageLoader'); browserHistory.push({...location,query:applyFilter})}}
                                  />
                            </Grid>
                            <Grid item md={3}>
                                <ul className='list-inline' style={{float:'right'}}>
                                    { deleteRobberies.robberies.length ?
                                        <li className='list-inline-item'>
                                            <Button onClick={() => setDeleteRobberies({...deleteRobberies, modal:true})} size='small' variant='outlined' color='secondary'>Delete ({deleteRobberies.robberies.length})</Button>
                                        </li>:null
                                    }
                                    
                                    { currentFormDetail && currentFormDetail.current_form && currentFormDetail.current_form.permissions && currentFormDetail.current_form.permissions.can_save ?
                                        <li className='list-inline-item'><Button onClick={() => setAddEditRobbery({modal:true, mode:'add', robbery:null})} color="primary" variant="contained" size="small" style={{marginRight:'8px'}}>Add Robbery</Button></li>:null
                                    }
                                </ul>
                            </Grid>
                        </Grid>
                    </Paper>
                    <TableListComp
                        heads={robberyHd}
                        sort={sort}
                        sort_by={sort_by}
                        bulkOperation={currentFormDetail?.current_form?.permissions?.can_save ? true : false} 
                        bulkFlag={robberies.list.length ? deleteRobberies.robberies.length === robberies.list.length:false}
                        toggleBulkOps={toggleBulkOps}
                        data={robberies.list.map((item,index) =>
                            <List
                                key={index}
                                item={item}
                                deleteRobberies={deleteRobberies}
                                onEdit={() => setAddEditRobbery({modal:true, mode:'edit', robbery:item})}
                                onDelete={() => setDeleteRobberies({modal:true, robberies:[item.id]})}
                                onSelectRow={(flag) => onSelectRow(flag, item.id)}
                                currentFormDetail={currentFormDetail}
                            />
                        )}
                        onChangePage={(event, newPage) => {getRobberies({ page:newPage+1, per_page: robberies.pagination.per_page, ...filter}, 'pageLoader')}}
                        onChangeRowsPerPage={(event) => getRobberies({ page:1, per_page: event.target.value, ...filter}, 'pageLoader')}
                        noRecord={ robberies.list.length ? null:
                            <EmptyCollection title="No records found" />
                        }
                        createSortHandler={createSortHandler}
                        pagination={robberies.pagination}
                    />
                </Fragment>
                )
            : isLoading? null:
            <RestrictedAccess />
            :
            <InvalidUrl
            getResponseBack={()=>browserHistory.push(`/technical/project/view/${currentFormDetail?.project?.slug}`) }
            />
            }
            </div>
            { addEditRobbery.modal ?
                <RobberyCRU
                    addEditRobbery={addEditRobbery}
                    toggleModalFn={() => setAddEditRobbery({modal:false, mode:'', robbery:null})}
                    getResponseBack={() => getRobberies({ page:1, per_page: robberies.pagination.per_page, ...filter}, 'pageLoader')}
                />:null
            }
            { deleteRobberies.modal ?
                <DeletePopUp
                    modal={deleteRobberies.modal}
                    toggleModalFn={() => setDeleteRobberies({modal:false, robberies:[]})}
                    title="Delete Robbery(s)"
                    content={<h4>Are you sure you want to delete?</h4>}
                    deleteRecordFn={() => onDeleteRobberies()}
                />:null
            }
            { isLoading ? <PageLoader />:null }
        </section>
    )
}
export default Robberies;