import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { useSnackbar } from 'notistack';
import { read, utils } from 'xlsx';
import {DropzoneArea} from 'material-ui-dropzone';
import Alert from '@material-ui/lab/Alert';
import { Button, TableRow, TableCell, TextField, Paper, Table, TableHead, TableBody, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Checkbox, CircularProgress, Tooltip, IconButton } from '@material-ui/core';
import { EmptyCollection, DeletePopUp, PageLoader, Pagination, STableLoader } from '../../../shared_elements';
import { regexConstants } from '../../../constants/regEx';
import StatsWidget from './StatsWidget';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import { globalDeleteService, globalGetService, globalPostService, globalPutService } from '../../../utils_v2/globalApiServices';
import { checkApiStatus, getPltConstantsList } from '../../../utils_v2';
import { statusColorCode } from '../'
const llpObj = {
    description:'',
    part_number:'',
    serial_number:'',
    cycles_remaining:null,
    status:'',
    remarks:'',
    comments:'',
    operator_comments:'',
    ref_document:''
}
const status = [
    {
        "value": 2,
        "label": "Closed",
    },
    {
        "value": 1,
        "label": "Open",
    },
    {
        "value": 3,
        "label": "Unresolved",
    }
]
const ImportLLP = ({params, getResponseBack}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [modal, setModal] = useState(false)
    const [importLLPs, setImportLLPs] = useState([]);
    const onFieldChange = (key, value, index) => {
        setImportLLPs(importLLPs.map((item, itemIndex) => itemIndex === index ? {...item, [key]:value}:item))
    }
    const onFilesDrop = (files) => {
        if (files && files.length > 0) {
            setLoading(true);
            const file = files[0];
            if (file instanceof Blob) {
                const reader = new FileReader();
                reader.onload = function (e) {
                    const buffer = e.target.result;
                    const wb = read(new Uint8Array(buffer));
                    const ws = wb.Sheets[wb.SheetNames[0]];
                    const data = utils.sheet_to_json(ws, { blankRows: false });
                    setLoading(false);
                    if(data.length === 0){
                        enqueueSnackbar("Invalid Data", { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
                        return
                    }
                    let updatedData = data.map(item=>{
                        return {
                            description: item['Description']?item['Description']:'',
                            remarks: item['Remarks']?item['Remarks']:'',
                            status: {value:1, label:'Open'},
                            comments: item['Comments/Notes']?item['Comments/Notes']:'',
                            ref_document: item['Ref Documents']?item['Ref Documents']:'',
                            operator_comments: item['Operator Comments']?item['Operator Comments']:'',
                            engine_gap_report_section: null,
                            engine_work_order: null,
                            engine_gap_report_section:2,
                            serial_number: item['S/N']?item['S/N']:'',
                            part_number: item['P/N']?item['P/N']:'',
                            cycles_remaining: item['Cycle Remaining']?item['Cycle Remaining']:'',
                        }
                     })
                    setImportLLPs(updatedData);
                };
                reader.readAsArrayBuffer(file);
            } else {
                console.error("Invalid file object. Expected a Blob.");
            }
        }
    };
    const onRemoveImportRow = (index) => {
        setImportLLPs(importLLPs.filter((item, itemIndex) => itemIndex !== index));
    }
    const onImportLLP = (item) => {
        setLoading(true);
        globalPostService(`technical/v2/workorder/${params.workOrderSlug}/engine-gap-report/llp-details/`,{llps:importLLPs})
        .then(response => {
            if(checkApiStatus(response)){
                setModal(false); setImportLLPs([]);
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                getResponseBack();
            }
            setLoading(false);
        })
    }
    return(
        <>
            <Button onClick={() => setModal(true)} color='primary' size='small' variant='contained'>Import</Button>
            { modal ?
                <Dialog
                    open={modal}
                    onClose={() => {setModal(false);setImportLLPs([])}}
                    className='project-management-modal'
                    fullScreen
                >
                    <DialogTitle id="scroll-dialog-title">
                        Import LLP Details
                    </DialogTitle>
                    <DialogContent dividers={true}>
                        <div style={{minWidth:'440px'}}>
                            <div>
                                <p style={{textAlign:'right'}}>
                                    <a style={{textDecoration:'underline'}} href='https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/templates/TE003_LLP_Details_Template.xlsx' download>Download Sample Template</a>
                                </p>
                                <DropzoneArea
                                    acceptedFiles={['.xls, .xlsx']}
                                    filesLimit={1}
                                    maxFileSize={10428800}
                                    showPreviewsInDropzone={false}
                                    useChipsForPreview={false}
                                    showPreviews={false}
                                    dropzoneText={<p style={{fontSize:'13px'}}>Drag & Drop Documents<br/> OR <br/> Click Here<br/> </p>}
                                    dropzoneClass="drag-drop-cnt"
                                    maxWidth="sm"
                                    showAlerts={['info','error']}
                                    alertSnackbarProps={{anchorOrigin: {vertical:'top', horizontal:'right'}}}
                                    clearOnUnmount={true}
                                    onChange={(files) => onFilesDrop(files)}
                                />
                                <Alert severity="info">Note: Please download "Sample Template", edit the template in Excel and upload the same for further customization.</Alert>
                            </div>
                            { importLLPs?.length ?
                                <div>
                                    <Table className='mui-table-format'>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Description</TableCell>
                                                <TableCell>P/N</TableCell>
                                                <TableCell>S/N</TableCell>
                                                <TableCell>Cycle Remaining</TableCell>
                                                <TableCell>Remarks</TableCell>
                                                <TableCell>Comment/Notes</TableCell>
                                                <TableCell>Ref Documents</TableCell>
                                                <TableCell>Operator Comments</TableCell>
                                                <TableCell align='right'>Action(s)</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            { importLLPs.map((item, index) => 
                                                <TableRow>
                                                    <TableCell>
                                                        <TextField
                                                            name={`description_${index}`}
                                                            fullWidth
                                                            margin="none"
                                                            inputProps={{maxLength: 250}}
                                                            value={item?.description||''}
                                                            onChange={(e) => onFieldChange('description', e.target.value, index)}
                                                            InputLabelProps={{shrink: true}}
                                                            variant='outlined'
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            name={`part_number_${index}`}
                                                            fullWidth
                                                            margin="none"
                                                            inputProps={{maxLength: 20}}
                                                            value={item?.part_number||''}
                                                            onChange={(e) => onFieldChange('part_number', e.target.value, index)}
                                                            InputLabelProps={{shrink: true}}
                                                            variant='outlined'
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            name={`serial_number_${index}`}
                                                            fullWidth
                                                            margin="none"
                                                            inputProps={{maxLength: 20}}
                                                            value={item?.serial_number||''}
                                                            onChange={(e) => onFieldChange('serial_number', e.target.value, index)}
                                                            InputLabelProps={{shrink: true}}
                                                            variant='outlined'
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            name={`cycles_remaining_${index}`}
                                                            fullWidth
                                                            margin="none"
                                                            inputProps={{maxLength: 8}}
                                                            value={item?.cycles_remaining||''}
                                                            onChange={(e, value) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange('cycles_remaining', e.target.value, index) : e.preventDefault() }}
                                                            InputLabelProps={{shrink: true}}
                                                            variant='outlined'
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            name={`remarks_${index}`}
                                                            fullWidth
                                                            margin="none"
                                                            inputProps={{maxLength: 250}}
                                                            value={item?.remarks||''}
                                                            onChange={(e) => onFieldChange('remarks', e.target.value, index)}
                                                            InputLabelProps={{shrink: true}}
                                                            variant='outlined'
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            name={`comments_${index}`}
                                                            fullWidth
                                                            margin="none"
                                                            inputProps={{maxLength: 250}}
                                                            value={item?.comments||''}
                                                            onChange={(e) => onFieldChange('comments', e.target.value, index)}
                                                            InputLabelProps={{shrink: true}}
                                                            variant='outlined'
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            name={`ref_document_${index}`}
                                                            fullWidth
                                                            margin="none"
                                                            inputProps={{maxLength: 250}}
                                                            value={item?.ref_document||''}
                                                            onChange={(e) => onFieldChange('ref_document', e.target.value, index)}
                                                            InputLabelProps={{shrink: true}}
                                                            variant='outlined'
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            name={`operator_comments_${index}`}
                                                            fullWidth
                                                            margin="none"
                                                            inputProps={{maxLength: 250}}
                                                            value={item?.operator_comments||''}
                                                            onChange={(e) => onFieldChange('operator_comments', e.target.value, index)}
                                                            InputLabelProps={{shrink: true}}
                                                            variant='outlined'
                                                        />
                                                    </TableCell>
                                                    <TableCell align='right'>
                                                        <Tooltip title='Delete' arrow>
                                                            <IconButton size="small"> <DeleteOutlineIcon onClick={() => onRemoveImportRow(index)} fontSize='small' color='secondary' /> </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </div>:null
                            }
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={isLoading} onClick={() => {setModal(false); setImportLLPs([])}} color="primary" size="small">Cancel</Button>
                        <Button disabled={isLoading || !importLLPs?.length} onClick={onImportLLP} variant="contained" color="primary" size="small">
                            {isLoading ? <CircularProgress size={20} /> : 'SAVE' }
                        </Button>
                    </DialogActions>
                </Dialog>:null
            }
        </>
    )
}
const AddEditLLP = ({params, toggleModalFn, addEditLLP, getResponseBack}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false)
    const [error, setError] = useState({});
    const [llp, setLLP] = useState(addEditLLP.data);
    const [pltConstants, setPltConstants] = useState([]);
    useEffect(() => {
        getPltConstants();
    },[]);

    const getPltConstants = () => {
        globalPostService(`console/get-constants/`, {constant_types:["engine_gap_report_status"]})
        .then(response => {
            if(checkApiStatus(response)){
                setPltConstants(response.data.data)
            }
        })
    }
    const onFieldChange = (key, value) => {
      setLLP({...llp, [key]:value})
    }
    const onAddEditLLP = () => {
        let validationInputs = {
            status: llp?.status?.label ? '':'Please select Status',
            description: llp?.description?.trim()?.length ? '':'Please enter Description'
        }
        if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
            setLoading(true);
            if(llp.id){
                globalPutService(`technical/v2/workorder/${params.workOrderSlug}/engine-gap-report/llp-details/${llp.id}/`, llp)
                .then(response => {
                    if(checkApiStatus(response)){
                        enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                        toggleModalFn(); getResponseBack();
                    }else{
                        enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
                    }
                    setLoading(false);
                })
            }else{
                globalPostService(`technical/v2/workorder/${params.workOrderSlug}/engine-gap-report/llp-details/`, {llps:[llp]})
                .then(response => {
                    if(checkApiStatus(response)){
                        enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                        toggleModalFn(); getResponseBack();
                    }else{
                        enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
                    }
                    setLoading(false);
                })
            }
        }else{
            setError(validationInputs);
        }
    }
    return(
        <Dialog
            open={addEditLLP.modal}
            onClose={toggleModalFn}
            className='project-management-modal'
            maxWidth='md'
        >
            <DialogTitle id="scroll-dialog-title">
                {llp.id ? 'Edit':'Add'} LLP Detail
            </DialogTitle>
            <DialogContent dividers={true}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <TextField
                            required
                            name="description"
                            label="Description"
                            fullWidth
                            margin="normal"
                            inputProps={{maxLength: 250}}
                            value={llp?.description||''}
                            onChange={(e) => onFieldChange('description', e.target.value)}
                            error={error?.description}
                            multiline
                            rows={3}
                            helperText={error?.description||''}
                            InputLabelProps={{shrink: true}}
                            onFocus={(e) => setError({...error,'description':''})}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <Autocomplete
                            options = {pltConstants}
                            getOptionLabel={option => option.label}
                            name='status'
                            value={llp?.status||null}
                            onChange={(e, data) => onFieldChange('status', data)}
                            renderInput={params => <TextField required error={error?.status} helperText={error?.status||''} onFocus={() => setError({...error, 'status':''})} {...params} label="Status" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined'/>}
                        />
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <TextField
                            name="part_number"
                            label="Part Number"
                            fullWidth
                            margin="normal"
                            inputProps={{maxLength: 20}}
                            value={llp?.part_number||''}
                            onChange={(e) => onFieldChange('part_number', e.target.value)}
                            error={error?.part_number}
                            helperText={error?.part_number||''}
                            InputLabelProps={{shrink: true}}
                            onFocus={(e) => setError({...error,'part_number':''})}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <TextField
                            name="serial_number"
                            label="Serial Number"
                            fullWidth
                            margin="normal"
                            inputProps={{maxLength: 20}}
                            value={llp?.serial_number||''}
                            onChange={(e) => onFieldChange('serial_number', e.target.value)}
                            error={error?.serial_number}
                            helperText={error?.serial_number||''}
                            InputLabelProps={{shrink: true}}
                            onFocus={(e) => setError({...error,'serial_number':''})}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <TextField
                            name="cycles_remaining"
                            label="Cycles Remaining"
                            fullWidth
                            margin="normal"
                            inputProps={{maxLength: 8}}
                            value={llp?.cycles_remaining||''}
                            onChange={(e, value) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange('cycles_remaining', e.target.value) : e.preventDefault() }}
                            error={error?.cycles_remaining}
                            helperText={error?.cycles_remaining||''}
                            InputLabelProps={{shrink: true}}
                            onFocus={(e) => setError({...error,'cycles_remaining':''})}
                            variant='outlined'
                        />
                    </Grid>
                    
                    <Grid item xs={12}>
                        <TextField
                            name="remarks"
                            label="Remarks"
                            fullWidth
                            margin="normal"
                            inputProps={{maxLength: 250}}
                            value={llp?.remarks||''}
                            onChange={(e) => onFieldChange('remarks', e.target.value)}
                            multiline
                            rows={3}
                            InputLabelProps={{shrink: true}}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="comments"
                            label="Comments/Notes"
                            fullWidth
                            margin="normal"
                            inputProps={{maxLength: 250}}
                            value={llp?.comments||''}
                            onChange={(e) => onFieldChange('comments', e.target.value)}
                            multiline
                            rows={3}
                            InputLabelProps={{shrink: true}}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="ref_document"
                            label="Ref Documents"
                            fullWidth
                            margin="normal"
                            inputProps={{maxLength: 250}}
                            value={llp?.ref_document||''}
                            onChange={(e) => onFieldChange('ref_document', e.target.value)}
                            multiline
                            rows={3}
                            InputLabelProps={{shrink: true}}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="operator_comments"
                            label="Operator Comments"
                            fullWidth
                            margin="normal"
                            inputProps={{maxLength: 250}}
                            value={llp?.operator_comments||''}
                            onChange={(e) => onFieldChange('operator_comments', e.target.value)}
                            multiline
                            rows={3}
                            InputLabelProps={{shrink: true}}
                            variant='outlined'
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} onClick={toggleModalFn} color="primary" size="small">Cancel</Button>
                <Button disabled={isLoading} onClick={onAddEditLLP} variant="contained" color="primary" size="small">
                    {isLoading ? <CircularProgress size={20} /> : 'SAVE' }
                </Button>
            </DialogActions>
        </Dialog>
    )
}
const LLPDetail = ({params, currentFormDetail, getCurrentFormDetail}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [filter, setFilter] = useState({});
    const [llpInfo, setLlpInfo] = useState({list:[], pagination:null});
    const [addEditLLP, setAddEditLLP] = useState({data:null, modal:false});
    const [deleteLLP, setDeleteLLP] = useState({data:[], modal:false});
    const [statsSummary, setStatsSummary] = useState({});
    useEffect(() => {
        getLLPDetails({},'skeletonLoader');
        getStatsSummary()
    },[]);
    const getStatsSummary = () => {
        globalGetService(`technical/v2/workorder/${params.workOrderSlug}/engine-gap-report/summary/`, {section:2})
        .then(response => {
          if(checkApiStatus(response)){
            setStatsSummary(response.data.data);
          }
        })
    }
    const getLLPDetails = (query, loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true):setLoading(true);
        globalGetService(`technical/v2/workorder/${params.workOrderSlug}/engine-gap-report/llp-details/`, query)
        .then(response => {
            if(checkApiStatus(response)){
                setLlpInfo(response.data.data)
            }
            loaderType === 'skeletonLoader' ? setSkeletonLoader(false):setLoading(false);
        })
    }
    const handleChange = (type, flag, id) => {
        if(type === 'single'){
            setDeleteLLP({
                ...deleteLLP,
                data: flag ? [...deleteLLP.data, id]:deleteLLP.data.filter(item =>  item !== id)
            })
        }else{
            let ids = llpInfo.list.map(item => item.id)
            setDeleteLLP({
                ...deleteLLP,
                data: flag ? ids:[]
            })
        }
    }
    const onDeleteLLP = () => {
        setLoading(true);
        globalDeleteService(`technical/v2/workorder/${params.workOrderSlug}/engine-gap-report/llp-details/`, {ids:deleteLLP.data})
        .then(response => {
            if(checkApiStatus(response)){
                setDeleteLLP({modal:false, data:[]});
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                getLLPDetails({}, 'pageLoader');
                getCurrentFormDetail('pageLoader');
                getStatsSummary()
            }
            setLoading(false);
        })
    }
    const canEdit = currentFormDetail?.current_form?.permissions?.can_save;
    return(
        <div className='forms-table-layout'>
            { skeletonLoader ? <STableLoader count={5} />: 
                <>
                    <StatsWidget statsSummary={statsSummary} />
                    <Paper className='forms-table-cta' square>
                        <Grid container spacing={1}>
                            <Grid item md={6}></Grid>
                            <Grid item md={6}>
                                { canEdit ? 
                                    <ul className='list-inline'>
                                        { deleteLLP?.data?.length ?
                                            <li className='list-inline-item'>
                                                <Button onClick={() => setDeleteLLP({...deleteLLP, modal:true})} color='secondary' size='small' variant='contained'>Delete</Button>
                                            </li>:null
                                        }
                                        <li className='list-inline-item'>
                                            <Button onClick={() => setAddEditLLP({data:llpObj, modal:true})} color='primary' size='small' variant='contained'>Add LLP Detail</Button>
                                        </li>
                                        <li className='list-inline-item'>
                                            <ImportLLP params={params} getResponseBack={() => {getLLPDetails({}, 'pageLoader'); getCurrentFormDetail('pageLoader'); getStatsSummary()}} />
                                        </li>
                                    </ul>:null
                                }
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper className='forms-table-content'>
                    <div style={{height: !llpInfo.list.length ? 'auto':`${window.innerHeight-337}px`,overflow:'auto', paddingRight:'5px'}}>
                            <Table className='mui-table-format' stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        { canEdit ?
                                            <TableCell style={{width:'40px'}}>
                                                <div style={{width:'40px'}}>
                                                    <Checkbox
                                                        size='small'
                                                        checked={deleteLLP?.data?.length ? deleteLLP?.data?.length === llpInfo?.list.length :false}
                                                        onChange={(e) => handleChange('bulk', e.target.checked)}
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                        disabled={!llpInfo?.list?.length}
                                                    />
                                                </div>
                                            </TableCell>:null
                                        }
                                        <TableCell>Item No</TableCell>
                                        <TableCell>Description</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>P/N</TableCell>
                                        <TableCell>S/N</TableCell>
                                        <TableCell>Cycle Remaining</TableCell>
                                        <TableCell>Remarks</TableCell>
                                        <TableCell>Comment/Notes</TableCell>
                                        <TableCell>Ref Documents</TableCell>
                                        <TableCell>Operator Comments</TableCell>
                                        <TableCell align='right'>Action(s)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    { llpInfo.list.map((item, index) =>
                                        <TableRow>
                                            { canEdit ?
                                                <TableCell>
                                                    <div style={{width:'40px'}}>
                                                        <Checkbox
                                                            size='small'
                                                            checked={deleteLLP.data.includes(item.id)}
                                                            onChange={(e) => handleChange('single', e.target.checked, item.id)}
                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                        />
                                                    </div>
                                                </TableCell>:null
                                            }
                                            <TableCell>
                                                <div style={{width:'80px'}}>{ (llpInfo?.pagination?.per_page||10) *(llpInfo.pagination.page-1) + (index+1)}</div>
                                            </TableCell>
                                            <TableCell>
                                                <div style={{width:'220px'}}>{item?.description||'--'}</div>
                                            </TableCell>
                                            <TableCell>
                                                <div style={{width:'100px'}}>
                                                    {item?.status?.label ?
                                                        <span className={`gap-report-status`} style={{background: statusColorCode[item.status.value]}}>{item.status.label}</span>:null
                                                    }
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div style={{width:'100px'}}>{item?.part_number||'--'}</div>
                                            </TableCell>
                                            <TableCell>
                                                <div style={{width:'100px'}}>{item?.serial_number||'--'}</div>
                                            </TableCell>
                                            <TableCell>
                                                <div style={{width:'100px'}}>{item?.cycles_remaining||'--'}</div>
                                            </TableCell>
                                            <TableCell>
                                                <div style={{width:'220px'}}>{item?.remarks||'--'}</div>
                                            </TableCell>
                                            
                                            <TableCell>
                                                <div style={{width:'220px'}}>{item?.comments||'--'}</div>
                                            </TableCell>
                                            <TableCell>
                                                <div style={{width:'220px'}}>{item?.ref_document||'--'}</div>
                                            </TableCell>
                                            <TableCell>
                                                <div style={{width:'220px'}}>{item?.operator_comments||'--'}</div>
                                            </TableCell>
                                            <TableCell align='right'>
                                                { canEdit ?
                                                    <ul className='list-inline' style={{width:'80px', float:'right'}}>
                                                        <li className='list-inline-item'>
                                                            <Tooltip title='Edit' arrow>
                                                                <IconButton size="small"><EditIcon onClick={() => setAddEditLLP({modal:true, data:item})} fontSize='small' color='primary' /> </IconButton>
                                                            </Tooltip>
                                                        </li>
                                                        <li className='list-inline-item'>
                                                            <Tooltip title='Delete' arrow>
                                                                <IconButton size="small"> <DeleteOutlineIcon onClick={() => setDeleteLLP({modal:true, data:[item.id]})} fontSize='small' color='secondary' /> </IconButton>
                                                            </Tooltip>
                                                        </li>
                                                    </ul>:null
                                                }
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </div>
                        <Pagination 
                            pagination={llpInfo.pagination}
                            onChangePage={(event, newPage) => getLLPDetails({...filter, page:newPage+1, per_page: llpInfo.pagination.per_page}, 'pageLoader')}
                            onChangeRowsPerPage={(event) => getLLPDetails({...filter, page:1, per_page: event.target.value}, 'pageLoader')}
                        />
                        { !llpInfo.list.length ? <EmptyCollection title="No records found" />:null }
                    </Paper>
                </>
            }
            { addEditLLP.modal ?
                <AddEditLLP
                    params={params}
                    addEditLLP={addEditLLP}
                    toggleModalFn={() => setAddEditLLP({data:null, modal:false})}
                    getResponseBack={() => {getLLPDetails({}, 'pageLoader'); getCurrentFormDetail('pageLoader');getStatsSummary()}}
                />:null
            }
            { deleteLLP.modal ?
                <DeletePopUp
                    modal={deleteLLP.modal}
                    toggleModalFn={() => setDeleteLLP({data:[], modal:false})}
                    title="Delete LLP Detail(s)"
                    content={<h4>Are you sure you want to delete?</h4>}
                    deleteRecordFn={onDeleteLLP}
                />:null
            }
            { isLoading ? <PageLoader />:null }
        </div>
    )
}
export default withRouter(LLPDetail);