import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { fieldDateFormat, backendDateFormat } from '../../../constants';
const AssetInspectionDate = ({workOrder, onFieldChange, error, resetErrorKey}) => {
  return(
    <Grid item xs={12} md={12}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <div className="work-order-inspection-date">
            <h4>Record Inspection</h4>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                     margin="normal"
                     id="records_inspection_on_start_date"
                     label="Start Date"
                     format={fieldDateFormat}
                     fullWidth
                     disableFuture
                     InputLabelProps={{shrink: true}}
                     error={error.records_inspection_on_start_date?true:false}
                     helperText={error.records_inspection_on_start_date?error.records_inspection_on_start_date:''}
                     value={workOrder.records_inspection_on_start_date ? workOrder.records_inspection_on_start_date:null}
                     onChange={(data, value) => {onFieldChange(value, 'records_inspection_on_start_date', data ? moment(data).format(backendDateFormat):data);resetErrorKey('records_inspection_on_start_date')}}
                     inputVariant='outlined'
                   />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} md={6}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="records_inspection_on_end_date"
                    label="End Date"
                    format={fieldDateFormat}
                    fullWidth
                    clearable={true}
                    InputLabelProps={{shrink: true}}
                    error={error.records_inspection_on_end_date?true:false}
                    helperText={error.records_inspection_on_end_date?error.records_inspection_on_end_date:''}
                    value={workOrder.records_inspection_on_end_date ? workOrder.records_inspection_on_end_date:null}
                    onChange={(data, value) => {onFieldChange(value, 'records_inspection_on_end_date', data ? moment(data).format(backendDateFormat):data);resetErrorKey('records_inspection_on_end_date')}}
                    inputVariant='outlined'
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className="work-order-inspection-date">
            <h4>Physical Inspection</h4>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="physical_inspection_on_start_date"
                    label="Start Date"
                    format={fieldDateFormat}
                    fullWidth
                    disableFuture={true}
                    clearable={true}
                    InputLabelProps={{shrink: true}}
                    error={error.physical_inspection_on_start_date?true:false}
                    helperText={error.physical_inspection_on_start_date?error.physical_inspection_on_start_date:''}
                    value={workOrder.physical_inspection_on_start_date ? workOrder.physical_inspection_on_start_date:null}
                    onChange={(data, value) => {onFieldChange(value, 'physical_inspection_on_start_date', data ? moment(data).format(backendDateFormat):data);resetErrorKey('physical_inspection_on_start_date')}}
                    inputVariant='outlined'
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} md={6}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="physical_inspection_on_end_date"
                    label="End Date"
                    format={fieldDateFormat}
                    fullWidth
                    clearable={true}
                    InputLabelProps={{shrink: true}}
                    error={error.physical_inspection_on_end_date?true:false}
                    helperText={error.physical_inspection_on_end_date?error.physical_inspection_on_end_date:''}
                    value={workOrder.physical_inspection_on_end_date ? workOrder.physical_inspection_on_end_date:null}
                    onChange={(data, value) => {onFieldChange(value, 'physical_inspection_on_end_date', data ? moment(data).format(backendDateFormat):data);resetErrorKey('physical_inspection_on_end_date')}}
                    inputVariant='outlined'
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </Grid>
  )
}
export default AssetInspectionDate;
